import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Moment } from 'moment';

@Directive({
  selector: '[timeInput]',
  host: { '(blur)': 'blur($event)', '(keyup)': 'convertInput($event)' },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimeInputDirective),
      multi: true,
    },
  ],
})
export class TimeInputDirective implements ControlValueAccessor {
  private onChange = (_: any) => {};

  constructor(private input: ElementRef<HTMLInputElement>) {}

  blur(event: FocusEvent) {
    this.input.nativeElement.value = this.convertInput(event) ?? '';
  }

  convertInput(event: FocusEvent | KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const value = input?.value;
    const parsed = this.parseTime(value);
    this.onChange(parsed ? moment(parsed, 'hh:mm:ss') : null);
    return parsed;
  }

  //time parser - roh/mytools - 2017
  private parseTime(ntime: string) {
    if (!ntime) return null;
    const regex = /\d*/g;
    const matches = ntime.match(regex);
    const parts = matches.filter(Boolean);
    const end = parts.length;
    if (end === 0) return null;
    parts[0] = ~~parts[0] + '';
    let out = '';
    for (let i = 0; i < end; i++) {
      out += ':';
      let val = parts[i];
      let size = val.length;
      if (size > 4) {
        val = val.substring(0, 4);
        size = 4;
      }
      const intVal = ~~val;
      switch (size) {
        case 1:
          const xt = i < 1 || intVal > 5 ? '0' + val : val + '0';
          out += xt;
          break;
        case 2:
          if (intVal > 23 && i < 1) {
            let a = val.substring(0, 1);
            let b = val.substring(1, 2);
            a = '0' + a;
            b = ~~b > 5 ? ':0' + b : ':' + b + '0';
            out += a + b;
          } else if (intVal > 59) {
            out += '0' + val;
          } else {
            out += val;
          }
          break;
        case 3:
          if (intVal > 235) {
            let a = val.substring(0, 1);
            a = '0' + a;
            let b = val.substring(1, 3);
            b = ~~b > 59 ? ':0' + b.substring(0, 1) : ':' + b;
            out += a + b;
          } else {
            const a = val.substring(0, 2);
            let b = val.substring(2, 3);
            b = ~~b > 5 ? ':0' + b : ':' + b + '0';
            out += a + b;
          }
          break;
        case 4:
          if (intVal > 2359) {
            let a = val.substring(0, 1);
            a = '0' + a;
            let b = val.substring(1, 3);
            b = ~~b > 59 ? ':0' + b.substring(0, 1) : ':' + b;
            out += a + b;
          } else {
            const a = val.substring(0, 2);
            let b = val.substring(2, 4);
            b = ~~b > 59 ? ':0' + b.substring(0, 1) : ':' + b;
            out += a + b;
          }
          break;
        default:
          out += '';
          break;
      }
    }
    out += ':00';
    return out.substring(1, 6);
  }

  // ===== Reactive Forms =====

  writeValue(value: Date | Moment): void {
    const m = value ? moment(value) : null;
    this.input.nativeElement.value = m?.format('HH:mm') ?? '';
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}
}
