<div class="matrix table-grid-component" [style.grid-template-columns]="service.gridTemplateColumnsStyle">
	<div class="c4-table-grid-cell c4-table-grid-header-top c4-table-grid-header-start corner">
		{{ 'states.matrix.title' | translate }}
	</div>
	<div *ngFor="let role of service.roles" class="c4-table-grid-cell c4-table-grid-header-top centered"
			 [matTooltip]="role.description">
		{{ role.name }}
	</div>
	<div class="c4-table-grid-cell c4-table-grid-header-top centered"></div>

	<ng-container *ngFor="let state of service.states">
		<div class="c4-table-grid-cell c4-table-grid-group-header c4-table-grid-header-start small-gap">
			<app-state-circle [hexColor]="state.controls.color.value"></app-state-circle>
			<span class="no-shrink">{{ state.controls.code.value }}</span>
			<span>{{ state.controls.title.value }}</span>
		</div>
		<div *ngFor="let role of state.controls.roles.controls" [formGroup]="role"
				 class="c4-table-grid-cell c4-table-grid-group-header centered">
			<input class="b365-slim-input" formControlName="alias">
		</div>
		<div class="c4-table-grid-cell c4-table-grid-group-header c4-table-grid-header-end no-padding">
			<button mat-icon-button (click)="service.addOrEditState(state)" [disabled]="state.disabled">
				<span class="mdi mdi-pencil"></span>
			</button>
			<button mat-icon-button (click)="service.removeState(state)" [disabled]="state.disabled">
				<span class="mdi mdi-trash-can-outline"></span>
			</button>
		</div>

		<div class="c4-table-grid-cell c4-table-grid-header-start">
			<span class="c4-busy-content">
				{{ 'states.matrix.followingStates' | translate }}
			</span>
		</div>
		<div *ngFor="let role of state.controls.roles.controls" [formGroup]="role"
				 class="c4-table-grid-cell centered prevent-grow small-gap following-states"
				 [class.disabled]="!role.controls.followingStateIds?.enabled"
				 (click)="editFollowingStates(role.controls.followingStateIds, state.controls.key.value)">

			<ng-template #followingStatesTemplate let-followingStateIds [ngTemplateOutlet]="followingStatesTemplate"
									 [ngTemplateOutletContext]="{ $implicit: role.controls.followingStateIds.value }">
				<span *ngIf="followingStateIds?.length; else addFollowingStates" class="states has-states"
							[matTooltip]="followingStateIds | join:', ':null:service.stateData">
					{{ followingStateIds | join:', ':null:service.stateData }}
				</span>
				<span class="mdi mdi-layers-edit" [class.has-states]="followingStateIds?.length"></span>
				<ng-template #addFollowingStates>
					<span>{{ 'states.addFollowing' | translate }}</span>
				</ng-template>
			</ng-template>
		</div>
		<div class="c4-table-grid-cell"></div>

		<ng-container *ngFor="let permission of service.permissions; let i = index">
			<div class="c4-table-grid-cell c4-table-grid-header-start">
				<span class="c4-busy-content" [matTooltip]="'states.' + permission.scope | translate">
					{{ 'states.' + permission.scope + '.title' | translate }}
				</span>
			</div>
			<div *ngFor="let role of state.controls.roles.controls" class="c4-table-grid-cell centered"
					 [formGroup]="role.controls.permissions.controls[i]">
				<app-ternary-checkbox class="c4-busy-content" formControlName="isSet"></app-ternary-checkbox>
			</div>
			<div class="c4-table-grid-cell"></div>
		</ng-container>
	</ng-container>
</div>