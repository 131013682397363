import { Component, Inject, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import {
  ConfigType,
  PlanFieldModel,
  PlanOptionModel,
  PlanSchemaDefinition,
  PlanSchemaMetadata,
  PlanSchemaModel,
  PlanSchemaModelWithState,
} from '@app/api';
import { PlanSchemaDialogComponent } from '@app/shared/components/planschema-list/plan-schema-dialog/plan-schema-dialog.component';
import { _planSeparator } from '@app/shared/components/planschema-list/schema-list.interfaces';
import { ApiService, EmptyGuid, LogService, Utils } from '@app/core';
import { DialogBase } from '@app/shared/components/dialogs/dialog-base';
import { UserNotificationService } from '@app/shared/services';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';

enum PlanCode {
  None = '',
  State = 'state',
}

class DlgPlanOptionModel extends PlanOptionModel {
  isNew?: boolean;
  isSelected?: boolean;
  errors?: { [key: string]: string } = {};
}

class DlgBasicFieldModel extends PlanFieldModel {
  edit?: boolean;
  selected?: boolean;
  markForDeletion?: boolean;
  valid?: boolean;
}

class DlgPlanFieldModel extends DlgBasicFieldModel {
  hasOptions?: boolean;
  options?: DlgPlanOptionModel[];
}

@Component({
  selector: 'app-schema-edit-dialog',
  templateUrl: './schema-edit-dialog.component.html',
  styleUrls: ['./schema-edit-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SchemaEditDialogComponent extends DialogBase<SchemaEditDialogComponent> implements Busy, OnInit {
  isBusy: boolean = false;
  fileType = 'Filetype';

  planSchemaId: string;
  planSchema: PlanSchemaModelWithState = new PlanSchemaModel({
    metadata: new PlanSchemaMetadata({
      name: '',
      description: '',
    }),
    definition: new PlanSchemaDefinition({
      planFields: [
        new PlanFieldModel({
          field: 'Filetype',
          displayName: 'Suffix',
          description: 'Unterstützte Dateiformate',
          isKey: true,
          isReadOnly: false,
          isVersion: false,
          order: null,
          width: null,
          code: null,
          maxLength: null,
        }),
      ],
    }),
  });

  schemaType: ConfigType;
  fields: DlgPlanFieldModel[] = [];
  seperator: string = _planSeparator;
  planCode = PlanCode;
  usedPlancodes: any;
  isRestricted: boolean;
  projectId: string;
  invalidOptions: any[];
  versionField: DlgPlanFieldModel = null;
  schemaAttribute: string = 'planschema';
  @ViewChild('invalidOptionsTmpl') invalidConfirmTmpl: TemplateRef<any>;
  appBody: any;
  bodyAttribute: string;

  public editSchemaForm: UntypedFormGroup;
  get f() {
    return this.editSchemaForm?.controls;
  }

  get validFields() {
    const fcount = this.fields.length;
    const fieldNames: any = {};
    for (let i = 0; i < fcount; i++) {
      if (!this.fields[i].valid) return false;
      if (!this.fields[i].field || (fieldNames[this.fields[i].field] && !this.fields[i].isSeparator)) {
        return false;
      } else {
        fieldNames[this.fields[i].field] = true;
      }
      if (!this.validateOptions()) return false;
    }
    return true;
  }

  constructor(
    private apiService: ApiService,
    private userNotification: UserNotificationService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private logService: LogService,
    public dialogRef: MatDialogRef<SchemaEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super(dialogRef, data);
    this.planSchemaId = data.planSchemaId;
    this.projectId = data.projectId;
    if (this.isSchemaForProject) {
      this.schemaType = data.schemaType;

      if (!this.schemaType) {
        this.logService.error('Schema type is missing!');
        dialogRef.close(false);
      }
    }
  }

  get isNew() {
    return !this.planSchemaId;
  }

  get isSchemaForProject() {
    return !!this.projectId;
  }

  async ngOnInit() {
    this.editSchemaForm = this.formBuilder.group({
      schemaNameInput: [{ value: '', disabled: this.isSchemaForProject }, [Validators.required]],
      schemaSeparatorInput: [{ value: '_', disabled: this.isRestricted }, [Validators.required]],
      schemaDescriptionInput: [''],
    });

    if (!this.isNew || this.isSchemaForProject) {
      const success = await using(new BusyScope(this), async _ => {
        this.planSchema = this.isSchemaForProject
          ? await this.apiService.getProjectPlanSchema(this.projectId, this.schemaType)
          : await this.apiService.getPlanSchema(this.planSchemaId);

        this.planSchemaId = this.planSchema.metadata.id;

        return true;
      }).catch(e => {
        this.userNotification.notify('schemata.error.planEditFailed', { error: e });
        return false;
      });

      if (!success) {
        this.dialogRef.close(false);
        return;
      }
    }

    this.isRestricted = !!this.planSchema.updateIsRestricted;
    this.seperator = this.planSchema.definition.seperator ?? _planSeparator;

    this.fields = this.planSchema.definition.planFields ?? [];
    this.fields.forEach(f => {
      f.edit = false;
      f.markForDeletion = false;
      f.selected = false;
      f.hasOptions = true;
      f.valid = true;
      if (f.options && f.options.length > 0) {
        const fo = f.options[0];
        f.hasOptions = fo.value ? true : false;
      }
      if (f.isVersion) this.versionField = f;
    });

    this.updateUsedPlanCodes();
    this.editSchemaForm.setValue({
      schemaNameInput: this.planSchema?.metadata?.name ?? '',
      schemaSeparatorInput: this.seperator,
      schemaDescriptionInput: this.planSchema?.metadata?.description ?? '',
    });
  }

  canAddSeparator(): boolean {
    return this.fields.length > 2;
  }

  updateUsedPlanCodes() {
    this.usedPlancodes = {};
    this.fields.forEach(f => {
      if (f.code) {
        this.usedPlancodes[f.code] = true;
      }
    });
  }

  setPlanCode(field: DlgPlanFieldModel, value) {
    field.code = value;
    this.updateUsedPlanCodes();
  }

  selectField(field: DlgBasicFieldModel) {
    this.fields.forEach(f => (f.selected = false));
    field.selected = true;
  }

  editField(field: DlgPlanFieldModel) {
    const toggleVal = !field.edit;
    if (this.fields.some(f => f.edit && !f.valid)) return;
    this.fields.forEach(f => {
      f.edit = false;
      f.selected = false;
    });
    field.selected = true;
    field.edit = toggleVal;
  }

  checkSeparator() {
    this.fields.forEach((f, i) => {
      if (f.isSeparator) {
        f.valid = !this.fields[i + 1]?.isSeparator && !this.fields[i - 1]?.isSeparator;
      }
    });
  }

  sortFields(offset: number) {
    const index = this.fields.findIndex(f => f.selected);
    if (index === -1 || index === null || index === undefined) return;
    const fieldCount = this.fields[index].isSeparator ? this.fields.length - 2 : this.fields.length - 1;
    if (index >= fieldCount) return;
    const newIndex = index + offset;
    if (newIndex >= fieldCount || newIndex < 0 || (this.fields[index].isSeparator && newIndex < 1)) return;
    const tmpField = this.fields[index];
    this.fields[index] = this.fields[newIndex];
    this.fields[newIndex] = tmpField;

    this.checkSeparator();
  }

  sortOptions(field: DlgPlanFieldModel, offset: number = null) {
    if (offset == null) {
      const valueCompare = Utils.propertySort<DlgPlanOptionModel>(option => option.value);

      if (Utils.isSorted(field.options, valueCompare)) {
        field.options.sort((a, b) => valueCompare(a, b) * -1);
      } else {
        field.options.sort(valueCompare);
      }
    } else {
      const index = field.options.findIndex(f => f.isSelected);
      if (index === -1 || index === null || index === undefined) return;
      const fieldCount = field.options.length;
      if (index >= fieldCount) return;
      const newIndex = index + offset;
      if (newIndex >= fieldCount || newIndex < 0) return;
      const tmpOption = field.options[index];
      field.options[index] = field.options[newIndex];
      field.options[newIndex] = tmpOption;
    }
  }

  addNewField() {
    if (this.fields.some(f => f.edit && !f.valid)) return;
    this.fields.forEach(f => (f.edit = false));
    const planfield = new DlgPlanFieldModel({
      field: '',
      displayName: '',
      description: null,
      order: null,
      maxLength: null,
      width: null,
      code: null,
    });
    planfield.edit = true;
    planfield.hasOptions = true;
    this.fields.unshift(planfield);
    this.selectField(planfield);
    this.checkSeparator();

    planfield.valid = false;
  }

  addNewSeparator() {
    if (this.fields.some(f => f.edit && !f.valid)) return;
    if (this.fields.length < 3) return;
    this.fields.forEach(f => (f.edit = false));
    const seperatorField = new DlgBasicFieldModel({
      field: '',
      displayName: 'Separator',
      description: null,
      order: null,
      maxLength: 1,
      width: null,
      code: null,
      isSeparator: true,
    });
    seperatorField.selected = true;
    seperatorField.edit = true;
    this.fields.splice(1, 0, seperatorField);
    this.selectField(seperatorField);
    this.checkSeparator();

    seperatorField.valid = false;
  }

  selectOption(field: DlgPlanFieldModel, option: DlgPlanOptionModel) {
    field.options.forEach(f => (f.isSelected = false));
    option.isSelected = true;
  }

  addOptionBelow(clickEvent: Event, field: DlgPlanFieldModel, index: number) {
    clickEvent.stopPropagation();
    const option = new DlgPlanOptionModel({
      value: '',
      label: '',
      range: [null, null],
      isLocked: false,
    });
    field.options.forEach(f => (f.isSelected = false));
    option.isNew = true;
    option.isSelected = true;
    field.options.splice(index + 1, 0, option);
  }

  addNewOption(field: DlgPlanFieldModel) {
    const option = new DlgPlanOptionModel({
      value: '',
      label: '',
      range: [null, null],
      isLocked: false,
    });
    if (!field.options) field.options = [];
    field.options.forEach(f => (f.isSelected = false));
    option.isNew = true;
    option.isSelected = true;
    field.options.unshift(option);
  }

  toggleVersionField(clickEvent: Event, field: DlgPlanFieldModel) {
    clickEvent.stopPropagation();
    this.versionField = null;
    const isVersion = !field.isVersion;
    this.fields.forEach(f => (f.isVersion = false));
    field.isVersion = isVersion;
    if (isVersion) this.versionField = field;
  }

  toggleField(field: DlgPlanFieldModel, name: string) {
    field[name] = !field[name];
  }

  toggleLock(toggleEvent: any, option: PlanOptionModel) {
    option.isLocked = !option.isLocked;
  }

  updateSeperator(inputEvent: any) {
    const value = inputEvent.target.value;
    this.seperator = value;
    this.validateOptions();
  }

  updateInputValue(inputEvent: any, field: DlgPlanFieldModel, name: string) {
    const value = inputEvent.target.value;
    this.updateValue(value, field, name);
  }

  updateValue(value: any, field: DlgPlanFieldModel, name: string) {
    if (name === 'field') {
      // remove periods (conflicts with primeng table filter aka. grid-component (resolveFieldData()))
      value = value?.replace(/\./g, '');

      // field validation: required|| doublette
      if (!value) {
        field.valid = false;
      } else {
        field.valid = !this.fields.some(f => f.field === value && f !== field && !f.isSeparator);
      }

      if (field.isSeparator) {
        const fieldIndex = this.fields.indexOf(field);
        field.valid = !this.fields[fieldIndex + 1]?.isSeparator && !this.fields[fieldIndex - 1]?.isSeparator;
      }
    }
    field[name] = value;
  }

  validateOptions(fields: DlgPlanFieldModel[] = null) {
    let isValid = true;
    (fields ?? this.fields).forEach(f => {
      let fieldIsValid = true;
      if (!f.options) return;
      f.options.forEach(o => {
        if (!this.validateOption(o)) {
          isValid = false;
        }
      });
      if (!fieldIsValid) f.valid = false;
    });
    return isValid;
  }

  updateNumberValue(inputEvent: any, field: DlgPlanFieldModel, name: string) {
    const value = inputEvent.target.value.replace(/\D/g, '');
    field[name] = value;
    inputEvent.target.value = value;
  }

  updateOption(value: any, option: any, name: string, index: number = null) {
    if (index === null || index === undefined) {
      option[name] = value;
    } else {
      option[name][index] = ~~value;
    }

    this.validateOption(option);
  }

  validateOption(option: DlgPlanOptionModel) {
    if (!option) return true;
    option.errors = {};
    if (!option.value) return true;

    if (!Utils.isNullOrWhitespace(this.seperator) && option.value.indexOf(this.seperator) > -1) {
      if (!option.errors) option.errors = {};

      option.errors['separator-in-value'] = 'dialogs.editSchema.error.separator-in-value';
    }

    return !this.hasError(option);
  }

  hasError(option) {
    if (!option) return false;
    return option.errors !== undefined && Object.keys(option.errors).length > 0;
  }

  getErr(option) {
    if (!option?.errors) return null;
    return option.errors[Object.keys(option.errors)[0]];
  }

  convertOptions(field: DlgPlanFieldModel) {
    field.hasOptions = !field.hasOptions;
    if (field.options && field.options.length > 0) {
      field.options.forEach(o => {
        o.range = o.range ?? [null, null];
        o.value = o.value ?? null;
      });
    }
  }

  removeField(field: DlgPlanFieldModel) {
    field.edit = false;
    field.code = this.planCode.None;
    field.isVersion = false;
    field.markForDeletion = true;
  }

  revokeField(field: DlgPlanFieldModel) {
    field.markForDeletion = false;
  }

  removeOption(field: DlgPlanFieldModel, index: number) {
    field.options.splice(index, 1);
  }

  async viewSchema() {
    this.appBody = document.getElementById('APP_Body');

    this.bodyAttribute = this.appBody.getAttribute('page');
    const viewFields = this.copyDefinition(); //this.fields.filter((f) => !f.markForDeletion);
    this.appBody.setAttribute('page', this.schemaAttribute);
    const dialogResult = await this.dialog
      .open(PlanSchemaDialogComponent, {
        data: { plan: viewFields, seperator: this.seperator },
      })
      .afterClosed()
      .toPromise();
    if (dialogResult) {
    }
    this.appBody.setAttribute('page', this.bodyAttribute);
  }

  copyDefinition() {
    // deep copy + reduce
    this.invalidOptions = [];
    const defCopy: DlgPlanFieldModel[] = [];
    this.fields.forEach(f => {
      if (!f.markForDeletion && f.field) {
        const fieldCopy = new DlgPlanFieldModel();
        fieldCopy.field = f.field;
        fieldCopy.displayName = !f.displayName ? f.field : f.displayName;
        fieldCopy.code = f.code;
        fieldCopy.description = f.description;
        fieldCopy.hasOptions = f.hasOptions;
        fieldCopy.isKey = f.isKey;
        fieldCopy.isReadOnly = f.isReadOnly;
        fieldCopy.isVersion = f.isVersion;
        fieldCopy.isSeparator = f.isSeparator;
        fieldCopy.isLogicalGrouping = f.isLogicalGrouping;
        fieldCopy.maxLength = f.maxLength;
        fieldCopy.order = f.order;
        fieldCopy.width = f.width;
        if (f.options && f.options.length > 0 && (!f.maxLength || ~~f.maxLength === 0)) {
          fieldCopy.options = [];
          f.options.forEach(o => {
            if ((f.hasOptions && o.value) || (!f.hasOptions && o.range) || o.label) {
              const optionCopy = new PlanOptionModel();
              optionCopy.label = o.label;
              optionCopy.isLocked = !!o.isLocked;
              if (f.hasOptions) {
                if (o.value) {
                  optionCopy.value = o.value;
                  if (!o.label)
                    this.invalidOptions.push({
                      fieldName: f.field,
                      type: 'option',
                      value: o.value,
                      label: o.label,
                    });
                } else {
                  this.invalidOptions.push({
                    fieldName: f.field,
                    type: 'option',
                    value: o.value,
                    label: o.label,
                  });
                  optionCopy.value = null;
                }
              } else {
                if (
                  o.range &&
                  o.range.length === 2 &&
                  o.range[0] !== null &&
                  o.range[0] !== undefined &&
                  o.range[1] !== null &&
                  o.range[1] !== undefined
                ) {
                  optionCopy.range = o.range;
                  if (!o.label)
                    this.invalidOptions.push({
                      fieldName: f.field,
                      type: 'range',
                      range: o.range,
                      label: o.label,
                    });
                } else {
                  this.invalidOptions.push({
                    fieldName: f.field,
                    type: 'range',
                    range: o.range,
                    label: o.label,
                  });
                  optionCopy.range = o.range ?? [null, null];
                }
              }
              fieldCopy.options.push(optionCopy);
            }
          });
        }
        if ((!fieldCopy.options || fieldCopy.options.length <= 0) && !fieldCopy.maxLength) fieldCopy.maxLength = 25;
        defCopy.push(fieldCopy);
      }
    });
    return defCopy;
  }

  public async confirm(createNew: boolean = false, createCopy: boolean = false) {
    const submitData = new PlanSchemaModel();
    submitData.definition = new PlanSchemaDefinition();
    submitData.metadata = new PlanSchemaMetadata();
    submitData.metadata.id = createNew ? EmptyGuid : this.planSchemaId;
    submitData.metadata.name = this.isSchemaForProject ? this.planSchema.metadata.name : this.f.schemaNameInput.value;
    submitData.metadata.description = this.f.schemaDescriptionInput.value;
    submitData.definition.planFields = this.copyDefinition();
    submitData.definition.seperator = this.seperator;

    if (createCopy && submitData.metadata.name == this.planSchema?.metadata?.name) {
      submitData.metadata.name += ` (${this.translateService.instant('general.copyNoun')})`;
    }

    if (this.invalidOptions && this.invalidOptions.length > 0) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'dialogs.editSchema.invalidOptionsConfirm',
          dlgClass: 'schema-options-confirm',
          contentTemplate: this.invalidConfirmTmpl,
          contentData: this.invalidOptions,
        },
        panelClass: 'confirm-options-dlg',
      });
      const dialogResult = await dialogRef.afterClosed().toPromise();
      if (dialogResult) {
        this.saveSchema(submitData);
      } else {
        return;
      }
    } else {
      this.saveSchema(submitData);
    }
  }

  public async saveSchema(submitData: PlanSchemaModel) {
    //remove invalid options!
    submitData.definition.planFields.forEach(f => {
      if (f.options) {
        f.options = f.options.filter(
          o =>
            (o.value && o.label) ||
            (o.range &&
              o.range.length === 2 &&
              o.range[0] !== null &&
              o.range[0] !== undefined &&
              o.range[1] !== null &&
              o.range[1] !== undefined)
        );
      }
    });

    await using(new BusyScope(this), async _ => {
      if (this.isSchemaForProject) await this.apiService.saveOrUpdateProjectSchema(this.projectId, this.schemaType, submitData);
      else await this.apiService.saveOrUpdateSchema(submitData);

      await this.userNotification.notify('dialogs.editSchema.success.save');

      this.dialogRef.close(true);
    }).catch(error => {
      this.isBusy = false;
      if (error.status === 409) {
        this.userNotification.notify('dialogs.editSchema.error.conflict', { error: error });
      } else {
        this.userNotification.notify('dialogs.editSchema.error.saveFailed', { error: error });
      }
    });
  }

  isFieldValid(field: PlanFieldModel) {
    if (field['valid'] === undefined) {
      return true;
    }

    const f = field as DlgPlanFieldModel;
    if (!f.valid) return false;

    if (f.options) {
      return f.options.every(o => this.validateOption(o));
    }

    return true;
  }
}
