import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import { BehaviorSubject } from 'rxjs';

type ProjectStatus = Record<string, ProjectStatusInfo>;

export enum OfflineState {
  online = 'online',
  offlineBySystem = 'offlineBySystem',
  offlineByUser = 'offlineByUser',
}

interface ProjectStatusInfoUpdate {
  offlineState?: OfflineState;
  isSyncing?: boolean;
  lastSynced?: Date;
}

export class ProjectStatusInfo {
  constructor(public offlineState: OfflineState, public isSyncing: boolean = false, public lastSynced?: Date) {}

  get isOffline() {
    return this.offlineState === OfflineState.offlineByUser || this.offlineState === OfflineState.offlineBySystem;
  }
}

export class OfflineStatusManager {
  isOffline$ = new BehaviorSubject(false);
  projectStatus$ = new BehaviorSubject<ProjectStatus>({});

  get projectStatus() {
    return this.projectStatus$.value;
  }

  get offlineProjectIds() {
    return Object.entries(this.projectStatus$.value).reduce(
      (projectIds, [projectId, isOffline]) => (isOffline.isOffline ? [...projectIds, projectId] : projectIds),
      new Array<string>()
    );
  }

  get autoOfflineProjectIds() {
    return Object.entries(this.projectStatus).reduce(
      (projectIds, [projectId, statusInfo]) =>
        statusInfo.offlineState == OfflineState.offlineBySystem ? [...projectIds, projectId] : projectIds,
      new Array<string>()
    );
  }

  isOffline(projectId: string): boolean {
    return this.getProjectStatusInfo(projectId)?.isOffline ?? false;
  }

  updateProjectStatus(projectId: string, { offlineState, isSyncing, lastSynced }: ProjectStatusInfoUpdate) {
    if (!projectId) throw new Error('ProjectId is missing');

    const projectStatus = this.projectStatus;

    if (offlineState == null || isSyncing == null || lastSynced === undefined) {
      const projectStatusInfo = this.getProjectStatusInfo(projectId);

      if (!offlineState) {
        if (!projectStatusInfo) throw new Error('Project Info is missing'); // mandatory
        offlineState = projectStatusInfo.offlineState;
      }

      if (isSyncing == null) isSyncing = projectStatusInfo.isSyncing;

      // check for undefined so it can be resetted with null
      if (lastSynced === undefined) lastSynced = projectStatusInfo?.lastSynced;
    }

    projectStatus[projectId] = new ProjectStatusInfo(offlineState, isSyncing, lastSynced);

    this.publishProjectStatus(projectStatus);
  }

  private publishProjectStatus(projectStatus: ProjectStatus) {
    this.projectStatus$.next(projectStatus);
  }

  private getProjectStatusInfo(projectId: string): ProjectStatusInfo {
    return CapacitorUtils.isApp() ? this.projectStatus[projectId] ?? new ProjectStatusInfo(OfflineState.online) : null;
  }
}
