import { IMigration, Statement } from '../definitions';
import { SQLiteTables } from '../utils';

export const Migration: IMigration = {
  name: 'M240229_Zones',
  getStatements() {
    const createZoneGroups = Statement`
      CREATE TABLE ZoneGroups (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createZones = Statement`
      CREATE TABLE Zones (
        id TEXT NOT NULL,
        project TEXT NOT NULL,
        zoneGroupId TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    return [createZoneGroups, createZones];
  },
};
