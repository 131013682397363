import { Component, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { AddressModel, CraftModel, OrganizationModel } from '@app/api';
import { ApiService, Utils } from '@app/core';
import { FormComponent } from '@app/core/utils/form-component';

function required(control: AbstractControl): ValidationErrors {
  const group = control as UntypedFormGroup;

  if (
    !group.controls.name.value ||
    !group.controls.street.value ||
    !group.controls.zipCode.value ||
    !group.controls.city.value
  ) {
    return { required: true };
  }

  return null;
}

@Component({
  selector: 'app-organization-input',
  templateUrl: './organization-input.component.html',
  styleUrls: ['./organization-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: OrganizationInputComponent,
    },
  ],
})
export class OrganizationInputComponent extends FormComponent implements OnInit, ControlValueAccessor {
  @Input() existingOrganizations: OrganizationModel[] = [];
  @Input() assignedOrganizations: OrganizationModel[] = [];
  @Input() existingCrafts: CraftModel[] = [];
  @Input() isAddOrEdit: boolean;
  @Input() canEditCrafts: boolean;

  assignedCrafts: CraftModel[] = [];
  addOrEditDisabled: boolean;
  isAssigned: boolean;

  onChange: (org: OrganizationModel) => void = _ => {};
  onTouched: () => void = () => {};

  constructor(private apiService: ApiService, private formBuilder: UntypedFormBuilder) {
    super();
  }

  private parseForm(): OrganizationModel {
    return new OrganizationModel({
      id: this.f.id.value,
      name: this.f.name.value,
      code: this.f.code.value,
      address: new AddressModel({
        street: this.f.street.value,
        zipCode: this.f.zipCode.value,
        city: this.f.city.value,
        country: this.f.country.value,
      }),
      externalNumber: this.f.externalNumber.value,
      crafts: this.f.crafts.value,
    });
  }

  enableAddOrEdit() {
    this.f.name.enable();
    this.f.code.enable();
    this.f.street.enable();
    this.f.zipCode.enable();
    this.f.city.enable();
    this.f.country.enable();
    this.f.externalNumber.enable();
    this.addOrEditDisabled = false;
  }

  disableAddOrEdit() {
    this.f.name.disable();
    this.f.code.disable();
    this.f.street.disable();
    this.f.zipCode.disable();
    this.f.city.disable();
    this.f.country.disable();
    this.f.externalNumber.disable();
    this.addOrEditDisabled = true;
  }

  async ngOnInit() {
    this.form = this.formBuilder.group(
      {
        id: [''],
        selectedOrganization: [null],
        code: ['', [Validators.pattern(/^[\wÄÖÜäöü]+$/)]],
        name: ['', [Validators.required]],
        street: ['', [Validators.required]],
        zipCode: ['', [Validators.required]],
        city: ['', [Validators.required, Validators.pattern(Utils.getTextWithSeperatorsRegex([' ', '-'], true))]],
        country: [''],
        crafts: [[]],
        externalNumber: [''],
      },
      { validators: required }
    );

    this.form.valueChanges.subscribe(_ => {
      this.onChange(this.parseForm());
    });

    this.f.selectedOrganization.valueChanges.subscribe(selectedOrg => {
      this.isAssigned = selectedOrg && this.assignedOrganizations.some(org => org.id == selectedOrg?.id);
      this.updateFormControls(selectedOrg);
      if (this.form.enabled) {
        if (!selectedOrg?.name) {
          this.enableAddOrEdit();
        } else {
          this.disableAddOrEdit();
        }
      }
    });

    if (!this.isAddOrEdit) {
      this.disableAddOrEdit();
    }
  }

  get isValid(): boolean {
    return this.form?.valid && !this.isAssigned;
  }

  addOrganization() {
    this.f.selectedOrganization.setValue(new OrganizationModel());
  }

  updateFormControls(organization: OrganizationModel) {
    this.f.id.setValue(organization?.id);
    this.f.code.setValue(organization?.code);
    this.f.name.setValue(organization?.name);
    this.f.street.setValue(organization?.address?.street);
    this.f.zipCode.setValue(organization?.address?.zipCode);
    this.f.city.setValue(organization?.address?.city);
    this.f.country.setValue(organization?.address?.country);
    this.f.externalNumber.setValue(organization?.externalNumber);
  }

  async newCraft(name: string): Promise<CraftModel> {
    return new CraftModel({
      name: name.trim(),
    });
  }

  // ===== Reactive Forms =====

  writeValue(organization: OrganizationModel): void {
    const existingOrganization = this.existingOrganizations.find(o => o.id == organization?.id);

    if (this.f.selectedOrganization.value?.id != existingOrganization?.id) {
      this.f.selectedOrganization.setValue(existingOrganization);
    }

    if (!existingOrganization) {
      this.updateFormControls(organization);
    }
  }

  registerOnChange(fn: (organization: OrganizationModel) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
      if (this.addOrEditDisabled) {
        this.disableAddOrEdit();
      }
    }
  }
}
