import { Component, Input } from '@angular/core';
import { FallbackColorType } from '@app/shared/pipes';

@Component({
  selector: 'app-state-circle',
  templateUrl: './state-circle.component.html',
  styleUrls: ['./state-circle.component.scss'],
})
export class StateCircleComponent {
  @Input() hexColor: string;
  @Input() fallbackColor: FallbackColorType = FallbackColorType.white;
}
