import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProjectModel } from '@app/api';
import { ApiService } from '@app/core';

export enum ColorSchemeClass {
  app = 'app',
  main = 'main',
  accent = 'accent',
}

@Component({
  selector: 'app-label-icon',
  templateUrl: './label-icon.component.html',
  styleUrls: ['./label-icon.component.scss'],
})
export class LabelIconComponent {
  @Input() colorSchemeClass: ColorSchemeClass = ColorSchemeClass.main;
  @Input() name: string;
  @Input() letterCount: number = 1;
  @Input() sizeRem: number = 2.4;

  constructor() {}
}
