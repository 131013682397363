import { Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { ConfigState, StateSchemaService } from './state-schema.service';
import { UntypedFormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MultiSelectDialogComponent } from '../dialogs';
import { UserNotificationService } from '@app/shared/services';

@Component({
  selector: 'app-state-matrix',
  templateUrl: './state-matrix.component.html',
  styleUrls: ['./state-matrix.component.scss'],
})
export class StateMatrixComponent<TState> {
  @ViewChildren('rowHeader') rowHeaders: QueryList<ElementRef<HTMLDivElement>>;

  @Input() service: StateSchemaService<TState>;

  constructor(private dialog: MatDialog, private userNotification: UserNotificationService) {}

  async editFollowingStates(followingStateIdsControl: UntypedFormGroup, stateId: string) {
    if (followingStateIdsControl.disabled) return;

    const otherStates = this.service.stateValues.filter(s => s.key != stateId);
    if (otherStates.length == 0) {
      this.userNotification.notify('states.error.noOtherStates');
      return;
    }

    const existingStateIds: string[] = followingStateIdsControl.value ?? [];
    const data = {
      items: otherStates.map(state => {
        var selectableState: Selectable = { ...state };
        selectableState.displayName = `${state.code} ${state.title}`;
        selectableState.isSelected = existingStateIds.includes(state.key);
        return selectableState;
      }),
      canCancel: true,
      title: 'states.' + (existingStateIds.length ? 'editFollowing' : 'addFollowing'),
      description: 'states.editFollowingDescription',
    };

    const newStates: ConfigState<TState>[] = await this.dialog
      .open(MultiSelectDialogComponent, {
        data: data,
        disableClose: true,
      })
      .afterClosed()
      .toPromise();

    if (newStates) {
      followingStateIdsControl.setValue(newStates.map(s => s.key));
      followingStateIdsControl.markAsDirty();
    }
  }
}
