import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PWAService {
  private ask = true;

  constructor(private swUpdate: SwUpdate, private translationService: TranslateService) {}

  startWatcher() {
    const swUpdate = this.swUpdate;

    if (swUpdate.isEnabled) {
      const subscription = swUpdate.versionUpdates.subscribe(_ => {
        subscription.unsubscribe();

        this.askUserToUpdate();
      });

      swUpdate.checkForUpdate().then(() => {
        interval(1000 * 60 * 30).forEach(async _ => {
          await swUpdate.checkForUpdate();
        });
      });
    }
  }

  private askUserToUpdate() {
    if (!this.ask) {
      return;
    }
    const message = this.translationService.instant('pwa.update.title');

    if (confirm(message)) {
      window.location.reload();
    } else {
      this.ask = false;
    }
  }
}
