import { Directive, ElementRef, Input } from '@angular/core';
import { CustomizationService, ThemeColorType } from '../services';
import { ContrastColorPipe } from '../pipes';

export type IconAnimation = 'rotate' | '';
export type IconPosition =
  | 'above after'
  | 'above before'
  | 'below before'
  | 'below after'
  | 'above'
  | 'below'
  | 'before'
  | 'after';

@Directive({
  selector: '[iconBadge]',
})
export class IconBadgeDirective {
  @Input('iconBadge') set icon(icon: string) {
    if (this.iconElement) this.iconContainer.removeChild(this.iconElement);

    this.iconContainer.style.display = icon ? 'flex' : 'none';

    const iconElement = document.createElement('span');
    iconElement.classList.add('mdi', `mdi-${icon}`);
    iconElement.style.fontSize = '1.4rem';
    iconElement.style.height = '1.4rem';
    iconElement.style.width = '1.4rem';

    this.iconElement = iconElement;
    this.updateAnimationStyle();
    this.updateColorStyle();

    this.iconContainer.appendChild(this.iconElement);
  }

  @Input('iconBadgeAnimation') set animation(animation: IconAnimation) {
    this.iconAnimation = animation;
    this.updateAnimationStyle();
  }

  @Input('iconBadgePosition') set position(position: IconPosition) {
    this.iconPosition = position;
    this.updatePositionStyle();
  }

  @Input('iconBadgeColor') set color(color: ThemeColorType) {
    this.backgroundColor = color ?? ThemeColorType.primary;
    this.updateColorStyle();
  }

  private iconContainer: HTMLSpanElement;
  private iconElement: HTMLSpanElement;
  private iconPosition: IconPosition = 'above after';
  private iconAnimation: IconAnimation = '';
  private backgroundColor: ThemeColorType = ThemeColorType.primary;

  constructor(
    private el: ElementRef,
    private customizationService: CustomizationService,
    private contrastColorPipe: ContrastColorPipe
  ) {
    const iconContainer = document.createElement('span');
    iconContainer.style.display = 'flex';
    iconContainer.style.justifyContent = 'center';
    iconContainer.style.alignItems = 'center';
    iconContainer.style.position = 'absolute';
    iconContainer.style.borderRadius = '50%';
    iconContainer.style.padding = '0.2rem';
    this.iconContainer = iconContainer;
    this.updatePositionStyle();
    this.updateColorStyle();

    this.el.nativeElement.style.position = 'relative';
    this.el.nativeElement.appendChild(iconContainer);
  }

  private updatePositionStyle() {
    const isAbove = this.iconPosition.indexOf('below') === -1;
    const isAfter = this.iconPosition.indexOf('before') === -1;

    this.iconContainer.style.top = isAbove ? '0' : '';
    this.iconContainer.style.bottom = isAbove ? '' : '0';
    this.iconContainer.style.right = isAfter ? '0' : '';
    this.iconContainer.style.left = isAfter ? '' : '0';

    this.iconContainer.style.transform = `translate(${isAfter ? 50 : -50}%, ${isAbove ? -50 : 50}%)`;
  }

  private updateAnimationStyle() {
    if (!this.iconElement) return;

    this.iconElement.style.animation = !this.iconAnimation ? '' : `${this.iconAnimation} 1.5s linear infinite`;
  }

  private updateColorStyle() {
    const backgroundHexColor = this.customizationService.getColor(this.backgroundColor);
    this.iconContainer.style.backgroundColor = `#${backgroundHexColor}`;
    if (this.iconElement) this.iconElement.style.color = this.contrastColorPipe.transform(backgroundHexColor, 'color');
  }
}
