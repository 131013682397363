import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { ProblemDetails, ProblemDetailsErrorType, ProjectModel, TenantInfoModel } from '@app/api';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { BridgeService } from './bridge-service';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  private tenantSubject = new BehaviorSubject<TenantInfoModel>(null);
  private projectIdSubject = new BehaviorSubject<string>(null);

  constructor(private bridge: BridgeService, private router: Router, activatedRoute: ActivatedRoute) {
    router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      const leaf = this.traverseRoute(activatedRoute.snapshot);
      const projectId = leaf.pathFromRoot[2]?.params.projectId;

      this.projectChanged(projectId);
    });
  }

  public get tenant$(): BehaviorSubject<TenantInfoModel> {
    return this.tenantSubject;
  }

  public get projectId(): string {
    return this.projectId$.value;
  }

  public get projectId$(): BehaviorSubject<string> {
    return this.projectIdSubject;
  }

  /**
   * Request the current tenant info from the api.
   *
   * @param unset If true, the tenant will be set to null avoiding an api call.
   */
  refreshTenant(unset: boolean = false) {
    if (unset) {
      this.tenant$.next(null);
    } else {
      this.bridge.invoke(api => {
        return api
          .getTenantInfo()
          .then(tenant => {
            this.tenant$.next(tenant);
          })
          .catch(e => {
            if (e instanceof ProblemDetails) {
              if (e.type == ProblemDetailsErrorType.UnknownTenant) {
                this.router.navigate(['errorPage'], {
                  queryParams: { title: 'errorPage.unknownTenant.title', description: 'errorPage.unknownTenant.description' },
                });
                return;
              }
            }

            console.error("Couldn't get tenant info.", e);
            this.tenant$.next(null);
          });
      });
    }
  }

  /**
   * The used project of the current user. Should only be set by login/switch-project.
   *
   * @param project Current project used by user (necessary for navigation)
   */
  projectChanged(projectId: string) {
    if (this.projectIdSubject.value != projectId) {
      this.projectIdSubject.next(projectId);
    }
  }

  private traverseRoute(route: ActivatedRouteSnapshot) {
    return route?.firstChild ? this.traverseRoute(route.firstChild) : route;
  }
}
