<div class="c4-dialog-wrapper">
	<div class="multi-image-capture-dialog">
		<!-- <input #fileInput class="hide" type="file" multiple (click)="fileInput.value = null" (change)="fileChanged($event.target.files)"
					 [accept]="imageTypes" /> -->
		<input #cameraInput class="hide" type="file" capture="camera" (click)="cameraInput.value = null"
					 (change)="fileChanged($event.target.files)" [accept]="imageTypes" />
		<div class="header-actions" #header>
			<span class="mdi" [class.mdi-trash-can-outline]="selectedImageIndex >= 0"
						(click)="removeFileAt(selectedImageIndex)"></span>
			<span class="mdi mdi-window-close" (click)="close()"></span>
		</div>
		<div class="image-preview">
			<div class="image-surface" id="imageSurface" #imageSurface>
				<ng-container *ngIf="allowDrawing; else previewOnly">
					<app-geojson-editor #editor [markers]="selectedImageGeoJson" (markersChange)="fireMarkersChange($event)"
															*ngIf="selectedImageIndex >= 0" [drawableContext]="{}">
						<app-geojson-editor-img-layer [url]="selectedImageUrl">
						</app-geojson-editor-img-layer>
					</app-geojson-editor>
				</ng-container>
				<ng-template #previewOnly>
					<img class="image-picture" [src]="selectedImageUrl" />
				</ng-template>
			</div>
			<div class="bottom-actions">
				<button type="button" mat-fab color="primary" (click)="capture()" [disabled]="!(selectedImageIndex < 0)">
					<span class="mdi mdi-camera-plus-outline"></span>
				</button>
				<button type="button" mat-fab color="primary" (click)="confirm()" [disabled]="!hasFilesAdded">
					<span class="mdi mdi-check"></span>
				</button>
			</div>
		</div>
		<div>
			<div #imageContainer *ngIf="hasFilesAdded; else emptyTemplate" class="added-images">
				<div class="image" *ngFor="let image of imageUrls | async; let i = index" [id]="i"
						 [style.background-image]="image | cssUrl" [style.border-width.px]="selectedImageIndex == i ? 2 : 0"
						 (click)="selectImage(i);"></div>
				<div class="capture-button" (click)="selectImage(-1);">
					<span class="mdi mdi-camera-plus-outline"></span>
				</div>
			</div>
			<ng-template #emptyTemplate>
				<div class="empty">{{ 'dialogs.multiImageCapture.noDataText' | translate }} {{debugText}}</div>
			</ng-template>
		</div>
	</div>
</div>