import { Component, forwardRef, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CustomValidators } from '@app/core';
import { Subscription } from 'rxjs';
import { GeoLocationValue } from './interfaces';
import { MapsLinkDecodeDialogComponent } from './maps-link-decode-dialog/maps-link-decode-dialog.component';

@Component({
  selector: 'app-geo-location-form',
  templateUrl: './geo-location-form.component.html',
  styleUrls: ['./geo-location-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GeoLocationFormComponent),
      multi: true,
    },
  ],
})
export class GeoLocationFormComponent implements OnInit, ControlValueAccessor {
  geoLocationForm: UntypedFormGroup;

  private onChange = (_: GeoLocationValue) => {};
  private onTouched = () => {};

  private subscriptions: Subscription[] = [];

  constructor(private dialog: MatDialog, private formBuilder: UntypedFormBuilder) {}

  get f() {
    return this.geoLocationForm.controls;
  }

  get valid() {
    return this.geoLocationForm.valid;
  }

  ngOnInit(): void {
    this.geoLocationForm = this.formBuilder.group({
      latitude: ['', [CustomValidators.decimal]],
      longitude: ['', [CustomValidators.decimal]],
    });

    this.subscriptions.push(
      this.geoLocationForm.valueChanges.subscribe((value: GeoLocationValue) => {
        this.onChange(value);
        this.onTouched();
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  async selectLocation() {
    window.open('https://www.google.at/maps', '_blank');

    const dialogResult = await this.dialog
      .open(MapsLinkDecodeDialogComponent)
      .afterClosed()
      .toPromise();

    if (dialogResult) {
      this.geoLocationForm.patchValue(dialogResult);
      this.geoLocationForm.markAllAsTouched();
    }
  }

  // ===== Reactive Forms =====

  writeValue(value: GeoLocationValue) {
    this.geoLocationForm.patchValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean) {
    if (isDisabled) {
      this.geoLocationForm.disable({ emitEvent: false });
    } else {
      this.geoLocationForm.enable({ emitEvent: false });
    }
  }

  getError(fieldName: string) {
    const errors = this.f[fieldName].errors;
    return Object.keys(errors)[0];
  }
}
