import { Component, Host, Input, OnDestroy, OnInit } from '@angular/core';
import { GeojsonEditorComponent } from './geojson-editor.component';

import * as L from 'leaflet';
import { MaximumBounds } from './interfaces';

@Component({
  selector: 'app-geojson-editor-map-layer',
  template: ``,
})
export class GeojsonEditorMapLayerComponent implements OnInit, OnDestroy {
  private m_url: string | null = null;
  private m_zoom: number = 0;
  private m_layer: L.TileLayer | null = null;

  constructor(@Host() private parent: GeojsonEditorComponent) {
    if ((L.tileLayer as any).showAsDiv === undefined) {
      (L.TileLayer as any).ShowAsDiv = L.TileLayer.extend({
        createTile: function (coords, done) {
          var tile = document.createElement('div');

          if (this.options.crossOrigin) {
            (tile as any).crossOrigin = '';
          }

          tile.style.backgroundImage = "url('" + this.getTileUrl(coords) + "')";
          tile.style.visibility = 'inherit';

          return tile;
        },
      });

      (L.tileLayer as any).showAsDiv = function (args) {
        return new (L.TileLayer as any).ShowAsDiv(args);
      };
    }

    const subscription = parent.onready.subscribe(x => {
      subscription.unsubscribe();

      this.attach();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.detach();
  }

  @Input() renderAsDiv: boolean = false;

  @Input('zoom')
  public set zoom(value: number) {
    if (this.zoom == value) return;

    this.detach();

    this.m_zoom = value;

    this.attach();
  }

  public get zoom() {
    return this.m_zoom;
  }

  @Input('url')
  public set url(value: string | null) {
    if (this.m_url == value) return;

    this.detach();

    this.m_url = value;

    this.attach();
  }

  public get url() {
    return this.m_url;
  }

  private detach() {
    if (this.m_layer) {
      this.m_layer.remove();
      this.m_layer = null;
    }
  }

  private attach() {
    const map = this.parent.map!;

    if (this.m_url && map) {
      this.m_layer = this.renderAsDiv ? this.getLayerAsDiv() : this.getLayer();
      this.m_layer.addTo(map);
    }
  }

  private getLayer() {
    return L.tileLayer(this.m_url, {
      bounds: MaximumBounds,
      maxZoom: this.m_zoom,
      noWrap: true,
    });
  }

  private getLayerAsDiv() {
    return (L.tileLayer as any).showAsDiv(this.m_url, {
      bounds: MaximumBounds,
      maxZoom: this.m_zoom,
      noWrap: true,
    });
  }
}
