import { Component, OnInit, Input } from '@angular/core';
import { AppConfigService, C4CardsSetting, GlobalsService } from '@app/core';

@Component({
  selector: 'c4-cards',
  templateUrl: './cards.component.html',
  styleUrls: ['./cards.component.scss']
})

export class CardsComponent implements OnInit {
  @Input() identifier: any;
  Setting: C4CardsSetting;
  responseKey: string = 'desk';

  constructor(public globals: GlobalsService) { }

  ngOnInit() {
    const settings = AppConfigService.settings.cardsSettings.concat(AppConfigService.settings.admin.cardsSettings);
    if (!settings) return;
    if (settings.length > 0) {
      const current = settings.filter(l => l.id === this.identifier.settings.id);
      if (current.length > 0) this.Setting = current[0];
    }
  }
}
