<div class="tasklike-files-content">
	<div class="expansion-panel-header-like">{{ 'roomBook.attachmentList' | translate }}
	</div>
	<div *ngIf="!filesFolderExists; else docList" class="upload-container">
		<div *ngIf="attachedFiles?.length; else uploadPlaceholder" class="upload-list">
			<div class="upload-item-container" *ngFor="let file of attachedFiles; let i = index">
				<div class="upload-item">
					<span class="mdi mdi-file-document-outline"></span>
					<span class="item-name">{{ file.name }}</span>
				</div>
				<button mat-icon-button (click)="remove(i)">
					<span class="mdi mdi-close"></span>
				</button>
			</div>
		</div>
		<app-upload (onFileUpload)="fileAdded($event)" [multiple]="true"></app-upload>
	</div>
</div>

<ng-template #uploadPlaceholder>
	<div class="upload-placeholder">
		<span>
			{{ 'general.noAttachments' | translate }}
		</span>
	</div>
</ng-template>

<ng-template #docList>
	<div class="integrated-button-container">
		<div class="c4-upload-ctrl" *ngIf="canAddFile">
			<button mat-flat-button color="primary" (click)="fileInput.click()">
				<span class="mdi mdi-plus-box upload-ico"></span>
				<span class="c4-ctrl-label">{{ 'documents.upload' | translate }}</span>
			</button>
			<input type="file" (click)="fileInput.value=null" (change)="uploadFiles($event.target.files)"
						 style="display: none" multiple #fileInput />
		</div>
	</div>
	<app-document-list selectionMode="none" enablePreview="true"></app-document-list>
</ng-template>