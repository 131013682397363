import { MultiStatusResult, ReceiverModel, ReceiverType } from '@app/api';

export interface SelectedUsers {
  label: string;
  id?: string;
  type?: ReceiverType;
}

export type ApiNotificationCall = (notificationModel: NotificationModel) => Promise<void | MultiStatusResult>;

export interface NotificationModel {
  to: ReceiverModel[];
  message?: string;
  subject?: string;
  //   itemIds: string[];
  //   resource?:string;
  //   userEvent?: boolean;
  //   sendNotification: ApiNotificationCall;
}

export interface NotificationDialogData {
  isCustomMailAddressSupported?: boolean;
  isCustomMessageSupported?: boolean;
  receiverIds?: string[];
  message?: string;
  subject?: string;
  itemNames: string[];
  userEvent?: boolean;
  sendNotification: ApiNotificationCall;
}
