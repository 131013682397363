import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ModuleType, ResourceTemplateType } from '@app/api';
import { FormUtils } from '@app/core';
import { PcfChannel, PcfDirectory, ProjectConfigService } from '@app/shared/services/project-config';
import { TreeNode } from 'primeng/api';
import { Subscription } from 'rxjs';
import { IndividualPrivilege, IndividualPrivilegeService } from '../../privilege-matrix';

export class StructureNode {
  private _isChannel: boolean = true;
  private _canRemove: boolean = true;
  private _isPortalNode: boolean = false;

  private subscriptions: Subscription[] = [];

  constructor(
    private _individualPrivilegeService: IndividualPrivilegeService,
    private configService: ProjectConfigService,
    private node: TreeNode<UntypedFormGroup>
  ) {
    const module = this.getNodeModule(node);
    this._isPortalNode = module == ModuleType.Document;

    this._isChannel = StructureNode.isChannelForm(this.form);
    this.setCanRemove();

    this._individualPrivilegeService.setData(
      configService.singleFolderPrivileges,
      configService.multiFolderPrivileges,
      FormUtils.getFormControl<PcfDirectory, UntypedFormArray>(this.form, 'individualPrivileges'),
      this.traverseParentsForDistinctIndividualPrivileges(this.form),
      this.configService.getCurrentRoles(),
      this.configService.projectUsers
      // this.configService.roleAndUserData
    );
  }

  get individualPrivilegeService(): IndividualPrivilegeService {
    return this._individualPrivilegeService;
  }

  get treeNode(): TreeNode<UntypedFormGroup> {
    return this.node;
  }

  get form(): UntypedFormGroup {
    return this.node.data;
  }

  get isChannel(): boolean {
    return this._isChannel;
  }

  get canRemove(): boolean {
    return this._canRemove;
  }

  get isPortalNode(): boolean {
    return this._isPortalNode;
  }

  dispose() {
    this.individualPrivilegeService.dispose();
    for (const subscription of this.subscriptions) subscription.unsubscribe();
  }

  private getNodeModule(node: TreeNode<UntypedFormGroup | ModuleType>): ModuleType {
    if (!node) return null;

    return node.data instanceof UntypedFormGroup ? this.getNodeModule(node.parent) : node.data;
  }

  private setCanRemove() {
    const isRequired = StructureNode.isRequiredForm(this.form, !this.configService.isTemplate);
    const pathFormControl = FormUtils.getFormControl<PcfDirectory>(this.form, 'path');
    const canEditPath = pathFormControl.enabled;

    this._canRemove = canEditPath && !isRequired;

    this.subscriptions.push(
      pathFormControl.statusChanges.subscribe(_ => {
        const canEditPath = pathFormControl.enabled;
        this._canRemove = canEditPath && !isRequired;
      })
    );
  }

  private traverseParentsForDistinctIndividualPrivileges(
    directoryForm: UntypedFormGroup,
    parentValues: Record<string, number> = {}
  ) {
    if (StructureNode.isChannelForm(directoryForm)) return parentValues;

    const parentForm = directoryForm.parent.parent as UntypedFormGroup;
    const individualPrivilegeOfParent = FormUtils.getFormControl<PcfDirectory, UntypedFormArray>(
      parentForm,
      'individualPrivileges'
    );

    for (const parentForm of individualPrivilegeOfParent.controls as UntypedFormGroup[]) {
      const parentId = FormUtils.getFormValue<IndividualPrivilege>(parentForm, 'id');
      const parentPrivilege = FormUtils.getFormValue<IndividualPrivilege>(parentForm, 'number');

      parentValues[parentId] = parentValues[parentId] ?? parentPrivilege;
    }

    this.traverseParentsForDistinctIndividualPrivileges(parentForm, parentValues);

    return parentValues;
  }

  static isChannelForm(form: UntypedFormGroup) {
    return !!FormUtils.getFormControl<PcfChannel>(form, 'id');
  }

  static isRequiredForm(form: UntypedFormGroup, isInProject: boolean) {
    const isRequiredTemplateType =
      this.isChannelForm(form) && FormUtils.getFormValue<PcfChannel>(form, 'templateType') == ResourceTemplateType.Required;

    return isRequiredTemplateType && isInProject;
  }
}
