import { TemplateRef } from "@angular/core";

export interface Node {
  label: string;  
  context: any;
  type: any;
  supportsAddChildren?: boolean;
  children?: Node[];
  template?: TemplateRef<any>;
}

export interface AddedNode {
  node: Node;
  parent: Node;
}

export interface pNode {
  label: string;  
  data: any;
  key:string;
  type: any;
  children?: pNode[];
}

