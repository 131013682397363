import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  HostBinding,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { SidebarNavigationItemComponent } from './sidebar-navigation-item/sidebar-navigation-item.component';
import { BaseSubscriptionComponent } from '@app/core';
import { Subject, debounceTime, map, merge, startWith, takeUntil } from 'rxjs';
import { CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';

@Component({
  selector: 'app-sidebar-navigation-item-group',
  templateUrl: './sidebar-navigation-item-group.component.html',
  styleUrls: ['./sidebar-navigation-item-group.component.scss'],
  hostDirectives: [CdkOverlayOrigin],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarNavigationItemGroupComponent extends BaseSubscriptionComponent implements AfterContentInit {
  @ContentChildren(SidebarNavigationItemComponent) items: QueryList<SidebarNavigationItemComponent>;

  @HostBinding('class.open')
  isOpen = false;

  @Output() opened = new EventEmitter<void>();

  popupPositions: ConnectionPositionPair[] = [
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'top',
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'bottom',
    },
  ];

  private itemsChanged$ = new Subject<void>();

  constructor(public cdkOverlayOrigin: CdkOverlayOrigin) {
    super();
  }

  ngAfterContentInit() {
    this.subscribe(
      this.items.changes.pipe(
        startWith(this.items),
        map((items: QueryList<SidebarNavigationItemComponent>) => items.toArray())
      ),
      items => {
        this.itemsChanged$.next();

        const [firstItem, secondItem] = items;
        if (firstItem && secondItem) {
          firstItem.disableLinkActive = true;
          firstItem.link = secondItem.link;
        }

        this.subscribe(
          merge(...items.map(i => i.isRouterLinkActive$)).pipe(takeUntil(this.itemsChanged$), debounceTime(50)),
          () => {
            const areSomeItemsActive = this.items.some(i => i.isActive);
            this.toggle(areSomeItemsActive);
          }
        );
      }
    );
  }

  toggle(open: boolean = !this.isOpen) {
    if (open) this.open();
    else this.close();
  }

  private open() {
    if (this.isOpen) return;
    this.isOpen = true;
    this.opened.emit();
  }

  private close() {
    if (!this.isOpen) return;
    this.isOpen = false;
  }
}
