export interface CanLeave {
  canLeave: () => Promise<boolean>;
}

export interface FormState extends CanLeave {
  isDirty: boolean;
}

export abstract class FormStateTracker {
  private formStateComponents: FormState[] = [];

  constructor() {}

  isDirty() {
    for (const component of this.formStateComponents) {
      if (component.isDirty) return true;
    }

    return false;
  }

  async canLeave() {
    for (const component of this.formStateComponents) {
      const canLeave = await component.canLeave();
      if (!canLeave) return false;
    }

    return true;
  }

  trackFormState(component: FormState) {
    if (this.formStateComponents.indexOf(component) < 0) {
      this.formStateComponents.push(component);
    }
  }

  untrackFormState(component: FormState) {
    const i = this.formStateComponents.indexOf(component);
    if (i >= 0) this.formStateComponents.splice(i, 1);
  }
}
