<div class="c4-dialog-wrapper shared-info">
	<h1 mat-dialog-title>{{'sharedInfo.title' | translate}}</h1>
	<mat-dialog-content class="dialog-content">
		<div class="c4-docgrid-container" [class.c4-busy]="isBusy">
			<c4-grid *ngIf=" gridDef" [gridDef]="gridDef" [source]="sharedInfos" [noDataText]="'sharedInfo.noEntries'">
			</c4-grid>
		</div>

	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
	</mat-dialog-actions>
</div>

<ng-template #copyToClipboard let-row='row'>
	<div class="c4-no-focus">
		<button mat-icon-button class="c4-grid-btn" [cdkCopyToClipboard]="row.link"
						[matTooltip]="'sharedInfo.copyToClipboard' | translate">
			<span class="mdi mdi-clipboard-text-outline"></span>
		</button>
	</div>
</ng-template>

<ng-template #itemAction let-col='col' let-row='row'>
	<div class="item-action">
		<button mat-icon-button (click)="showReceivers(row)" [matTooltip]="'log.receivers.tooltip' | translate">
			<span *ngIf="row.notificationState" [ngClass]="getNotificationIcon(row)"></span>
		</button>
	</div>
</ng-template>

<ng-template #emptyTemplate>
</ng-template>