import { Injectable, Optional, Inject } from '@angular/core';
import { NotificationClient, API_BASE_URL, AddHtmlNotificationModel, FileParameter } from './api';
import { HttpClient, HttpResponseBase, HttpHeaders } from '@angular/common/http';
import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';

@Injectable()
export class NotificationClientExtended extends NotificationClient {
  private httpEx: HttpClient;
  private baseUrlEx: string;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    super(http, baseUrl);
    this.httpEx = http;
    this.baseUrlEx = baseUrl ? baseUrl : '';
  }

  addHtmlNotificationEx(model?: AddHtmlNotificationModel | undefined, files?: FileParameter[]): Observable<void> {
    let url = this.baseUrlEx + '/notification';
    url = url.replace(/[?&]$/, '');

    const content = new FormData();
    content.append('Model', JSON.stringify(model));

    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      if (file !== null && file !== undefined) {
        content.append('files', file.data, file.fileName ? file.fileName : 'file');
      }
    }

    let options_: any = {
      body: content,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({}),
    };

    return this.httpEx
      .request('post', url, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processAddHtmlNotification(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processAddHtmlNotification(<any>response_);
            } catch (e) {
              return <Observable<void>>(<any>_observableThrow(e));
            }
          } else return <Observable<void>>(<any>_observableThrow(response_));
        })
      );
  }
}
