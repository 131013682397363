<div id="dialogScrollContentPhone" class="entity-detail-dialog">
	<div class="title-bar">
		<div class="title-container">
			<span *ngIf="title.icon" class="mdi" [class]="title.icon"></span>
			<a *ngIf="title.routerLink; else plainTitleTemplate" class="dialog-header-label-link"
				 [routerLink]="title.routerLink" [queryParams]="title.queryParams">
				{{ title.labelKey | translate }}
			</a>
			<ng-template #plainTitleTemplate>
				<span>
					{{ title.labelKey | translate}}
				</span>
			</ng-template>

			<div class="error" *ngIf="fieldError">
				<span class="mdi mdi-information"></span>
				<span>{{ 'general.hintCaption.atField' | translate }}</span>
				<span>&nbsp;'{{ fieldError.fieldName | translate }}':&nbsp;</span>
				<span>{{ 'general.errorForms.' + fieldError.error | translate }}</span>
			</div>
		</div>
		<div class="title-bar-button-container">
			<div matRipple class="flat-button c4-mobile-hidden" (click)="toggleFullscreen()">
				<span class="mdi mdi-arrow-expand"></span>
			</div>
			<div matRipple class="flat-button" (click)="cancelClicked.emit()">
				<span class="mdi mdi-close"></span>
			</div>
		</div>
	</div>

	<div class="dialog-header">
		<div class="header-content">
			<ng-content select="[header]"></ng-content>
		</div>
		<div class="header-buttons">
			<!-- [disabled]="!defectForm?.valid || !defectForm?.dirty" -->
			<button mat-flat-button color="primary" (click)="saveClicked.emit()" type="button" [disabled]="!canSave">
				{{'general.saveAndClose' | translate}}
			</button>
			<!-- [disabled]="!defectForm?.dirty" -->
			<button mat-icon-button class="c4-no-focus" (click)="resetClicked.emit()">
				<span class="mdi mdi-refresh action-ico"></span>
			</button>
		</div>
	</div>

	<div class="dialog-sub-header">
		<ng-content select="[subHeader]"></ng-content>
	</div>

	<div class="dialog-detail">
		<ng-content></ng-content>
	</div>
</div>