<div class="c4-dialog-wrapper">
  <h1 mat-dialog-title>{{ 'dialogs.changePassword.caption' | translate }}</h1>
  <form [formGroup]="changePasswordForm" (ngSubmit)="changePassword(changePasswordForm.value)">
    <mat-dialog-content>
      <p>{{ 'dialogs.changePassword.oldPasswordDescription' | translate }}</p>
      <mat-form-field>
        <input matInput [type]="hideOld ? 'password' : 'text'"
               placeholder="{{ 'dialogs.changePassword.oldPassword' | translate }}" formControlName="oldPassword">
        <span matSuffix class="mdi" [ngClass]="hideOld ? 'mdi-eye-off' : 'mdi-eye'" (click)="hideOld = !hideOld"></span>
      </mat-form-field>
      <ng-container formGroupName="newPassword">
        <p>{{ 'dialogs.changePassword.newPasswordDescription' | translate }}</p>
        <mat-form-field>
          <input matInput [type]="hideNew ? 'password' : 'text'"
                 placeholder="{{ 'dialogs.changePassword.newPassword' | translate }}" formControlName="password"
                 [matTooltip]="errorPasswordInvalid">
          <span matSuffix class="mdi" [ngClass]="hideNew ? 'mdi-eye-off' : 'mdi-eye'"
                (click)="hideNew = !hideNew"></span>
          <mat-error *ngIf="changePasswordForm.controls.newPassword['controls'].password.hasError('passwordInvalid')">{{
            errorPasswordInvalid }}</mat-error>
        </mat-form-field>
        <p>{{ 'dialogs.changePassword.newPasswordConfirmDescription' | translate }}</p>
        <mat-form-field>
          <input matInput [type]="hideConfirm ? 'password' : 'text'"
                 placeholder="{{ 'dialogs.changePassword.newPasswordConfirm' | translate }}"
                 formControlName="passwordConfirm" [errorStateMatcher]="confirmNewPasswordErrrorMatcher">
          <span matSuffix class="mdi" [ngClass]="hideConfirm ? 'mdi-eye-off' : 'mdi-eye'"
                (click)="hideConfirm = !hideConfirm"></span>
          <mat-error *ngIf="changePasswordForm.controls.newPassword.hasError('passwordsNotEqual')">{{
            'dialogs.changePassword.errorNewPasswordConfirmDoesNotMatch' | translate }}</mat-error>
        </mat-form-field>
      </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-flat-button type="button" (click)="abort()">{{ 'general.cancel' | translate }}</button>
      <button mat-flat-button color="primary" type="submit" [disabled]="!changePasswordForm.valid">{{
        'dialogs.changePassword.changePassword' | translate }}</button>
    </mat-dialog-actions>
  </form>
</div>