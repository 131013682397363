import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'planoptions' })
export class PlanOptions implements PipeTransform {
  constructor() {}
  transform(option: any, seperator: string = ' | ', inFront: boolean = true) {
    const valResult = option.range ? option.range.join(' - ') : option.value;
    const result = inFront ? valResult + seperator + option.label : option.label + seperator + valResult;
    return result;
  }
}
