<div class="c4-dialog-wrapper">
  <h1 mat-dialog-title>{{ title | translate:params }}</h1>
  <mat-dialog-content>
    <div *ngIf="assignUsers">
      <div [translate]="'dialogs.assignCompanyUser.descriptionCompany'"
           [translateParams]="{companyName: projectFilter.name}">
      </div>
    </div>
    <div *ngIf="assignCompanies">
      <div [translate]="'dialogs.assignCompanyUser.descriptionUser'"
           [translateParams]="{username: userFilter.username}">
      </div>
    </div>
    <div class="available-items-container" [class.c4-busy]="busyAssignments.isBusy">
      <c4-autocomplete class="c4-busy-content" [displayFunc]="getDisplayValue.bind(this)" [options]="availableItems"
                       [ngModel]="selectedItem" [translateOptions]="false" [allowAutofill]="false"
                       (selectedOptionChanged)="addSelectedItem($event)"
                       placeholder="{{(assignCompanies?'dialogs.assignCompanyUser.placeholder.selectCompany':'dialogs.assignCompanyUser.placeholder.selectUser') | translate}}">
      </c4-autocomplete>
    </div>
    <div class="assignment-container" [class.c4-busy]="busyAssignments.isBusy">
      <div class="spacing-container">
        <div class="assigned-items">
          <div class="item c4-busy-content" *ngFor="let item of assignedItems">
            <span>{{ getDisplayValue(item) }}</span>
            <div>
              <mat-slide-toggle *ngIf="item.canInvite" [checked]="item.tryInvite" (change)="fireToggle($event, item)"
                                [matTooltip]="'dialogs.teamAssign.inviteToTeam' | translate"></mat-slide-toggle>
              <span class="mdi mdi-trash-can-outline button" (click)="move(item)"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
    <button mat-flat-button color="primary" (click)="confirm()"
            [disabled]="isBusy || busyAssignments.isBusy || !hasChanged" type="button">{{ 'general.save' | translate
      }}</button>
  </mat-dialog-actions>
</div>
<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:false }"></ngx-loading>