<div class="standard-text-root" [formGroup]="form">
	<mat-form-field [class.without-title]="!title" [floatLabel]="!title ? 'never' : 'auto'">
		<mat-label *ngIf="title">{{ title | translate }}</mat-label>
		<textarea #text matInput formControlName="text" (blur)="onTouched()" [placeholder]="placeholder | translate"
							[style.resize]="resize" cdkTextareaAutosize [cdkAutosizeMinRows]="minRows"
							[cdkAutosizeMaxRows]="maxRows"></textarea>
	</mat-form-field>
	<span class="standard-text-button mdi mdi-plus" (click)="addStandardText(text)"
				[matTooltip]="'standardTexts.select' | translate"
				[class.disabled]="form.disabled || disableStandardTextButton"></span>
</div>
<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: true }"></ngx-loading>