<a class="project-card" [routerLink]="route">
	<div class="grid-container" [ngClass]="cardStyle">
		<div class="card-logo">
			<app-project-image [projectId]="id" [sizeRem]="logoSizeRem">
			</app-project-image>
		</div>
		<div class="card-content">
			<div class="card-header-title c4-busy-content">
				{{ name }}
			</div>
			<div *ngIf="!isDescriptionAddress; else addressDescription" class="card-description multiline">
				<span class="description-entry c4-busy-content">{{ description }}</span>
			</div>
			<ng-template #addressDescription>
				<div class="card-description address">
					<div class="description-entry">{{ description }}</div>
				</div>
			</ng-template>
		</div>
	</div>
</a>