export class AsyncRequestMutex<T> {
  private currentRequest: Promise<T>;
  async execute(request: () => Promise<T>) {
    if (this.currentRequest) {
      try {
        await this.currentRequest;
      } catch {}
    }

    try {
      this.currentRequest = request();
      await this.currentRequest;
    } finally {
      this.currentRequest = null;
    }
  }
}
