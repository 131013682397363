import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';


@Pipe({ name: 'plannameformat' })
export class PlanNameFormat implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value: string, seperatorString:string) {
        const fileExtensionIndex = value.lastIndexOf('.');
        const presuffix = [value.substring(0, fileExtensionIndex), value.substring(fileExtensionIndex+1)]
        const nameparts = presuffix[0].split(seperatorString);
        const response: string[] = [];
        nameparts.forEach((part, index) => {
            const seperator = (index > 0) ? '<span class="filenamepart sep' + index + '">'+ seperatorString +'</span>' : '';
            const typ = (part + 'T0000').substring(0, 5);
            const partformat = seperator + '<span class="filenamepart part' + index + ' t-' + typ + '">' + part + '</span>';
            response.push(partformat);
        });
        response.push('.<span class="filenamepart part-type">' + presuffix[1] + '</span>');
        return this.sanitized.bypassSecurityTrustHtml(response.join(''));
    }
}