import { catchError, filter, map, Observable, of, pairwise, switchMap, tap } from 'rxjs';
import { ApiUrlService } from '../services/api/api-url.service';
import { BaseSubscriptionComponent } from './base-subscription-component';
import { CapacitorUtils } from './capacitor-utils';
import { HttpClient } from '@angular/common/http';
import { LogService } from '../services/log/log.service';

export interface HybridImageComponent<T> extends BaseSubscriptionComponent {
  apiUrlService: ApiUrlService;
  httpClient: HttpClient;
  log: LogService;
  canCreateImage: (e: T) => boolean;
  getImageUrl: (e: T) => string;
}

export function createHybridImageUrl<T>(entity$: Observable<T>, component: HybridImageComponent<T>): Observable<string> {
  const useXhrRequest = CapacitorUtils.isApp();
  const hybridImageUrl$ = entity$.pipe(
    switchMap(entity => {
      if (!component.canCreateImage(entity)) return of(null);

      const imageUrl = component.getImageUrl(entity);

      return !useXhrRequest
        ? of(component.apiUrlService.replaceApiFQDN(imageUrl))
        : component.httpClient.get(imageUrl, { responseType: 'blob' }).pipe(
            map(blob => URL.createObjectURL(blob)),
            catchError(e => {
              component.log.warn('Failed to load image', e);
              return of(null);
            })
          );
    })
  );

  if (useXhrRequest) {
    let lastUrl: string = null;

    component.subscribe(
      hybridImageUrl$.pipe(
        pairwise(),
        map(([oldUrl, newUrl]) => {
          lastUrl = newUrl;
          return oldUrl;
        }),
        filter(oldUrl => oldUrl != null)
      ),
      {
        next: oldUrl => URL.revokeObjectURL(oldUrl),
        complete: () => URL.revokeObjectURL(lastUrl),
      }
    );
  }

  return hybridImageUrl$;
}
