import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-app-open-camera-dialog',
  templateUrl: './app-open-camera-dialog.component.html',
  styleUrls: ['./app-open-camera-dialog.component.scss'],
})
export class AppOpenCameraDialogComponent implements OnInit {
  title: string;
  description: string;
  params: any;
  dlgClass: string;
  contentTemplate?: TemplateRef<any>;
  contentData?: any[];
  confirmButton: string;
  cancelButton: string;

  constructor(public confirmRef: MatDialogRef<AppOpenCameraDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.title = data.title ?? 'TITLE_NOT_SET';
    this.description = data.description ?? null;
    this.params = data.params ?? undefined;
    this.dlgClass = data.dlgClass ?? 'unset';
    this.contentTemplate = data.contentTemplate ?? null;
    this.contentData = data.contentData ?? null;
    this.confirmButton = data.confirmButton ?? 'general.ok';
    this.cancelButton = data.cancelButton ?? 'general.cancel';
  }

  ngOnInit() {}

  fileChanged(files: FileList) {
    this.confirmRef.close(files);
  }

  ok() {
    this.confirmRef.close(true);
  }

  cancel() {
    this.confirmRef.close(false);
  }
}
