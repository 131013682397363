import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ClientConsentModel } from '@app/api';
import { ApiService, BaseSubscriptionComponent, LogService } from '@app/core';
import { ISnapshotLoginState } from '@app/core/ISnapshotLoginState';
import { UserNotificationService } from '@app/shared/services';
import { BusyScope, using } from '@app/shared/utils/busy';

interface ExtendedConsentModel extends ClientConsentModel {
  scopes: string[];
  isRevoked?: boolean;
  isBusy: boolean;
}

@Component({
  selector: 'app-user-settings-dialog',
  templateUrl: './user-settings-dialog.component.html',
  styleUrls: ['./user-settings-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserSettingsDialogComponent extends BaseSubscriptionComponent implements OnInit {
  consents: ClientConsentModel[] = [];
  openConsent: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private apiService: ApiService,
    private log: LogService,
    private userNotification: UserNotificationService,
    public dialogRef: MatDialogRef<UserSettingsDialogComponent>
  ) {
    super();
  }

  async ngOnInit() {
    const consents = await this.apiService.getAcceptedConsents();
    this.consents = consents.map(c => {
      const extended = c as ExtendedConsentModel;
      extended.scopes = c.scope?.split(' ') ?? [];
      return extended;
    });
  }

  async revokeConsent(event: MouseEvent, model: ExtendedConsentModel) {
    // prevent card toggle
    event.stopPropagation();

    if (!model.isRevoked) {
      let success = false;

      await using(new BusyScope(model), async busy => {
        await this.apiService.revokeConsent(model.clientId, model.scope);
        success = true;
      }).catch(e => {
        this.log.error('Could not revoke consent', e);
        this.userNotification.notify('settings.user.consent.errorRevoke', { error: e });
      });

      model.isRevoked = success;
    }
  }
}
