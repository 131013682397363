import { Injectable } from '@angular/core';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  async getItem(key: string) {
    const { value } = CapacitorUtils.isApp() ? await Preferences.get({ key }) : { value: localStorage.getItem(key) };

    return value;
  }

  async setItem(key: string, value: string) {
    if (CapacitorUtils.isApp()) await Preferences.set({ key, value });
    else localStorage.setItem(key, value);
  }

  async removeItem(key: string) {
    if (CapacitorUtils.isApp()) await Preferences.remove({ key });
    else localStorage.removeItem(key);
  }

  async clear() {
    if (CapacitorUtils.isApp()) await Preferences.clear();
    else localStorage.clear();
  }
}
