import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ZoneGroupModel, ZoneModel } from '@app/api';
import { Observable, Subject, debounceTime, filter, map, mergeWith, startWith, takeUntil } from 'rxjs';

interface RoomBookZoneData {
  groupId: string;
  groupName: string;
  name: string;
}

@Component({
  selector: 'app-zone-assignment',
  templateUrl: './zone-assignment.component.html',
  styleUrls: ['./zone-assignment.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ZoneAssignmentComponent,
    },
  ],
})
export class ZoneAssignmentComponent implements ControlValueAccessor, OnDestroy {
  private _readonlyZoneIds: string[] = [];
  get readonlyZoneIds(): string[] {
    return this._readonlyZoneIds;
  }

  @Input() set readonlyZoneIds(value: string[]) {
    this._readonlyZoneIds = value ?? [];
    this.updateFilter$.next(this.filterControl.value);
  }

  private _zoneGroups: ZoneGroupModel[] = [];
  get zoneGroups(): ZoneGroupModel[] {
    return this._zoneGroups;
  }

  @Input() set zoneGroups(value: ZoneGroupModel[]) {
    this._zoneGroups = value ?? [];
    this.buildZoneData();
    this.updateFilter$.next(this.filterControl.value);
  }

  zoneIds: string[] = [];
  filteredZoneGroups: Observable<ZoneGroupModel[]>;
  zoneData: Record<string, RoomBookZoneData> = {};

  filterControl = new FormControl('');

  private destroyed = new Subject<void>();
  private updateFilter$ = new Subject<string>();

  constructor() {
    this.filteredZoneGroups = this.filterControl.valueChanges.pipe(
      takeUntil(this.destroyed),
      startWith(''),
      debounceTime(300),
      mergeWith(this.updateFilter$),
      filter(x => typeof x === 'string'),
      map(this.getFilteredZoneGroups.bind(this))
    );
  }

  ngOnDestroy() {
    this.destroyed.next();
  }

  private getFilteredZoneGroups(filter: string) {
    const addedZoneGroupIds = this.zoneIds.concat(this.readonlyZoneIds).flatMap(id => this.zoneData[id]?.groupId ?? []);

    filter = filter?.toLowerCase() ?? '';
    return this.zoneGroups
      .filter(group => !addedZoneGroupIds.includes(group.id))
      .map(
        group =>
          new ZoneGroupModel({
            ...group,
            zones: group.zones.filter(
              zone => group.name.toLowerCase().includes(filter) || zone.name.toLowerCase().includes(filter)
            ),
          })
      )
      .filter(group => group.zones.length > 0);
  }

  addZone(zone: ZoneModel) {
    this.zoneIds.push(zone.id);
    this.onChange(this.zoneIds);
  }

  removeZone(zoneId: string) {
    this.zoneIds = this.zoneIds.filter(id => id != zoneId);
    this.onChange(this.zoneIds);
    this.updateFilter();
  }

  private updateFilter() {
    this.updateFilter$.next(this.filterControl.value);
  }

  private buildZoneData() {
    this.zoneData = this.zoneGroups.reduce((zoneData, group) => {
      for (const zone of group.zones) {
        zoneData[zone.id] = {
          groupId: group.id,
          groupName: group.name,
          name: zone.name,
        };
      }

      return zoneData;
    }, {} as Record<string, RoomBookZoneData>);
  }

  // ----- Reactive Forms Methods -----

  isDisabled: boolean = false;
  private isTouched: boolean = false;
  onChange: Func<string[]> = (_: string[]) => {};
  private onTouched: EmptyCallback = () => {};

  writeValue(zoneIds: string[]): void {
    this.zoneIds = zoneIds;
    this.updateFilter$.next(this.filterControl.value);
  }

  registerOnChange(fn: Func<string[]>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: EmptyCallback): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (isDisabled) this.filterControl.disable();
    else this.filterControl.enable();
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
  }
}
