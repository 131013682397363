<div class="c4-carousel-wrapper" (mouseover)="pauseAnim()" (mouseout)="runAnim()">
  <div class="c4-carousel-inner">
    <ng-container *ngFor="let entry of slideModels; let i = index">
      <a class="c4-slide" [ngStyle]="{'background-image': ('url(' + entry.slide.img + ')')}" [attr.data-index]="i" [ngClass]="entry.animClass? entry.animClass:null" href="{{ entry.slide.link}}" target="_blank" rel="noopener noreferrer">
        <div class="c4-slide-content">
          <div class="c4-slide-header">{{ entry.slide.header | translate }}</div>
          <div class="c4-slide-subheader">{{ entry.slide.subheader | translate }}</div>
        </div>
      </a>
    </ng-container>
  </div>
  <button (click)="next()" class="c4-btnNext" [ngClass]="{'c4-btnInside':buttonsInside}"><span [ngClass]="nextButtonIcon"></span></button>
  <button (click)="prev()" class="c4-btnPrev" [ngClass]="{'c4-btnInside':buttonsInside}"><span [ngClass]="prevButtonIcon"></span></button>
</div>