<div *ngIf="data" class="app-notification-card" [ngClass]="[busyClass,highlightClass]" [routerLink]="data.routerLink"
     [queryParams]="data.queryParams">
  <div class="app-notification-card-main" *ngIf="data" [ngClass]="[busyClass]">
    <a class="app-notification-link" [class.hasSender]="data.sender">
      <div class="app-notification-card-subject" [ngClass]="busyContentClass">
        <span class="mdi" [ngClass]="data.icon"></span>
        <div class="app-notification-card-subject-data">
          <div class="app-notification-card-subject-message">
            {{data.translate ? (data.subject | translate) : data.subject}}</div>
          <div class="app-notification-card-subject-date">{{ data.date | localizedDate:'relative' }}</div>
        </div>
      </div>
      <div *ngIf="notificationType == NotificationSendType.Input" class="sender-container">
        <div *ngIf="data.sender" class="sender" [ngClass]="busyContentClass"
             [matTooltip]="data.sender.firstname + ' ' + data.sender.lastname + ': ' + data.sender.emailAddress "
             matTooltipPosition="above">
          <span *ngIf="data.sender.firstname">{{ data.sender.firstname }}</span>
          <span *ngIf="data.sender.lastname">{{ data.sender.lastname }}</span>
          <span *ngIf="data.sender.emailAddress">{{ data.sender.emailAddress }}</span>
        </div>
      </div>

      <div class="app-notification-card-date" [ngClass]="busyContentClass">
        {{ data.date | localizedDate:'relative' }}</div>
    </a>
    <div class="app-notification-delete c4-no-focus" [ngClass]="busyContentClass">
      <button *ngIf="notificationType == NotificationSendType.Input" mat-icon-button class="c4-grid-btn"
              (click)="delete($event)" [matTooltip]="'general.notifications.deleteTooltip' | translate">
        <span class="mdi mdi-trash-can-outline"></span>
      </button>
    </div>
  </div>

  <div class="app-notification-card-body" [class.split]="rawHtmlBody">
    <div class="app-notification-card-body-firstline">
      <div *ngIf="notificationType == NotificationSendType.Input" class="sender-container-mobile"
           [ngClass]="busyContentClass">
        <h3>{{ 'general.notifications.from' | translate }}:</h3>
        <div *ngIf="data.sender" class="sender" [matTooltip]="data.sender.emailAddress" matTooltipPosition="above">
          <span>{{ data.sender.firstname }} {{ data.sender.lastname }}</span>
        </div>
      </div>

      <div *ngIf="!hideReceiver && data.notificationStateList?.length" class="receiver-container">
        <h3>{{ 'general.notifications.to' | translate }}:</h3>
        <div class="receiver-list">
          <div class="receiver-list-entry receiver" *ngFor="let entry of data.notificationStateList">
            <span>{{ entry.receiverDisplayName }}</span>
            <span [ngClass]="[getNotificationIcon(entry)]"
                  [matTooltip]="'general.notifications.' + entry.notificationState | translate"></span>
          </div>
        </div>
      </div>

      <div *ngIf="showActionButtons" class="app-notification-card-body-actions">
        <div *ngIf="showResendButton" class="app-notification-resend c4-no-focus" [ngClass]="busyContentClass">
          <button mat-icon-button class="c4-grid-btn" (click)="resend($event)"
                  [matTooltip]="'general.notifications.resendNotification' | translate">
            <span class="mdi mdi-email-sync-outline"></span>
          </button>
        </div>
        <div *ngIf="showForwardButton" class="app-notification-forward c4-no-focus" [ngClass]="busyContentClass">
          <button mat-icon-button class="c4-grid-btn" (click)="forward($event)"
                  [matTooltip]="'general.notifications.forwardNotification' | translate">
            <span class="mdi mdi-email-arrow-right-outline"></span>
          </button>
        </div>
      </div>
    </div>

    <div *ngIf="rawHtmlBody">
      <app-notification [notificationText]="rawHtmlBody" [showNotification]="true" [attachments]="data.attachments">
      </app-notification>
    </div>
  </div>
</div>