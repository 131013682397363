import { Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export enum DocumentPosition {
  topLeft = 'topLeft',
  topRight = 'topRight',
  bottomLeft = 'bottomLeft',
  bottomRight = 'bottomRight',
}

@Component({
  selector: 'app-document-position',
  templateUrl: './document-position.component.html',
  styleUrls: ['./document-position.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DocumentPositionComponent,
    },
  ],
})
export class DocumentPositionComponent implements ControlValueAccessor {
  @ViewChildren('input') inputs: QueryList<ElementRef<HTMLInputElement>>;

  selected = DocumentPosition.topLeft;
  documentPositions = Object.keys(DocumentPosition);

  constructor() {}

  positionClicked(documentPosition: DocumentPosition, input: HTMLInputElement) {
    input.focus();

    if (!this.isDisabled && this.selected != documentPosition) {
      this.selected = documentPosition;
      this.onChange(documentPosition);
    }
  }

  onKeydown(event: KeyboardEvent, documentPosition: DocumentPosition) {
    const positions = Object.values(DocumentPosition);

    let index = positions.indexOf(documentPosition);
    switch (event.key) {
      case 'ArrowUp':
        index -= 2;
        break;
      case 'ArrowDown':
        index += 2;
        break;
      case 'ArrowLeft':
        --index;
        break;
      case 'ArrowRight':
        ++index;
        break;
      default:
        return;
    }

    event.preventDefault();
    index = this.mod(index, positions.length);

    const newPosition = Object.values(DocumentPosition)[index];
    this.positionClicked(newPosition, this.inputs.get(index).nativeElement);
  }

  private mod(number: number, modulo: number) {
    return ((number % modulo) + modulo) % modulo;
  }

  // ----- Reactive Forms Methods -----

  isDisabled: boolean = false;
  private isTouched: boolean = false;
  onChange: Func<DocumentPosition> = _ => {};
  private onTouched: EmptyCallback = () => {};

  writeValue(documentPosition: DocumentPosition): void {
    this.selected = documentPosition;
  }

  registerOnChange(fn: Func<DocumentPosition>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: EmptyCallback): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
  }
}
