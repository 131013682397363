import { Component, OnInit } from '@angular/core';
import { ApiService, DataHolder } from '@app/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { UserNotificationService } from '@app/shared/services/user-notification/user-notification.service';
import { ProjectWithUserSettingsModel, UpdateUserSettingsModel, UserSettingsModel } from '@app/api';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-notification-settings',
  templateUrl: './notification-settings.component.html',
  styleUrls: ['./notification-settings.component.scss'],
})
export class NotificationSettingsComponent implements OnInit {
  companies: DataHolder<ProjectWithUserSettingsModel[]>;
  isExternalNotificationDisabledByDefault: boolean;

  constructor(
    public dialogRef: MatDialogRef<NotificationSettingsComponent>,
    private userNotification: UserNotificationService,
    private apiService: ApiService
  ) {
    this.companies = new DataHolder<ProjectWithUserSettingsModel[]>(this.fakeCompanies());
  }

  async ngOnInit() {
    this.companies
      .updateData(async () => {
        //clone objects, as we perform changes in here
        const user = await this.apiService.getUserSession(true);
        this.isExternalNotificationDisabledByDefault = user.isExternalNotificationDisabledByDefault;

        const companies = await firstValueFrom(this.apiService.getAvailableProjects());
        const result: ProjectWithUserSettingsModel[] = [];
        for (const company of companies) {
          const clone = new ProjectWithUserSettingsModel();
          clone.init(company);
          result.push(clone);
        }
        return result;
      }, true)
      .catch(e => {
        this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.companyData', e);
        this.dialogRef.close();
      });
  }

  async updateGlobalSettings(event: MatSlideToggleChange) {
    try {
      await this.apiService.updateUserSettings(
        new UpdateUserSettingsModel({
          userSettings: new UserSettingsModel({
            isExternalNotificationDisabled: !event.checked,
          }),
        })
      );
    } catch (e) {
      this.isExternalNotificationDisabledByDefault = !event.checked;
      await this.userNotification.notify('dialogs.notificationSettings.errorUpdateFailed', { error: e });
    }
  }

  async updateProject(event: MatSlideToggleChange, project: ProjectWithUserSettingsModel) {
    try {
      project.userSettings.isExternalNotificationDisabled = !event.checked;
      await this.apiService.updateUserSettings(
        new UpdateUserSettingsModel({
          projectId: project.id,
          userSettings: project.userSettings,
        })
      );
    } catch (e) {
      project.userSettings.isExternalNotificationDisabled = !project.userSettings.isExternalNotificationDisabled;
      await this.userNotification.notify('dialogs.notificationSettings.errorUpdateFailed', { error: e });
    }
  }

  close() {
    this.dialogRef.close();
  }

  private fakeCompanies(): ProjectWithUserSettingsModel[] {
    const result: ProjectWithUserSettingsModel[] = [];
    for (let i = 0; i < 2; i++) {
      result.push(
        new ProjectWithUserSettingsModel({
          id: '?',
          name: '##########',
          isExternalNotificationDisabled: true,
          userSettings: new UserSettingsModel({
            isExternalNotificationDisabled: true,
          }),
        })
      );
    }
    return result;
  }
}
