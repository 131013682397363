<div class="c4-dialog-wrapper tree-select-dialog">
	<h1 mat-dialog-title>{{ title | translate }}</h1>
	<mat-dialog-content class="content">
		<div class="description" *ngIf='description'>{{ description | translate }}</div>
		<p-tree class="tree" [value]="tree" [selectionMode]="selectionMode" filter="true" [(selection)]="selection"
						[filterPlaceholder]="'general.actions.filter' | translate" [emptyMessage]="'general.noDataText' | translate"
						scrollHeight="30em" [propagateSelectionDown]="propagateSelection">
		</p-tree>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button *ngIf="canCancel" mat-flat-button (click)="cancel()" type="button">
			{{ 'general.cancel' | translate }}
		</button>
		<button *ngIf="tree.length" mat-flat-button color="primary" (click)="confirm()"
						[disabled]="selectedNodes.length == 0">
			{{ 'general.ok' | translate }}
		</button>
	</mat-dialog-actions>
</div>