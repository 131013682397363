import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-multi-select-overlay',
  templateUrl: './multi-select-overlay.component.html',
  styleUrls: ['./multi-select-overlay.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiSelectOverlayComponent implements OnInit {
  @Input() checked: boolean;
  @Input() preventClick: boolean = false;
  @Output() change = new EventEmitter<any>();
  @Output() ownClick = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}
}
