<div class="document-position-container">
	<ng-container *ngFor="let position of documentPositions" [ngTemplateOutlet]="documentPositionTemplate"
								[ngTemplateOutletContext]="{ $implicit: position }"></ng-container>
</div>

<ng-template #documentPositionTemplate let-documentPosition>
	<div class="document-position" [class.disabled]="isDisabled" [class]="documentPosition"
			 [class.active]="selected === documentPosition" (click)="positionClicked(documentPosition, input)">
		<input #input type="radio" [disabled]="isDisabled" [checked]="selected === documentPosition"
					 (keydown)="onKeydown($event, documentPosition)" [tabindex]="selected === documentPosition ? 0 : -1">
	</div>
</ng-template>