import { Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NotificationStateModel } from '@app/api';
import { BaseSubscriptionComponent } from '@app/core';
import { NotificationStateIcon } from '@app/shared/components/notification-card/notification-card.component';
import { Busy } from '@app/shared/utils/busy';

@Component({
  selector: 'app-notification-state-viewer-dialog',
  templateUrl: './notification-state-viewer-dialog.component.html',
  styleUrls: ['./notification-state-viewer-dialog.component.scss'],
})
export class NotificationStateViewerDialogComponent extends BaseSubscriptionComponent implements Busy {
  isBusy: boolean;
  logId: string;
  resource: string;
  notificationStateModels: NotificationStateModel[];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: NotificationStateViewerDialogComponentData,
    public dialogRef: MatDialogRef<NotificationStateViewerDialogComponent>
  ) {
    super();
    this.notificationStateModels = data.notificationStateModels;
  }

  getNotificationIcon(receiver: NotificationStateModel): string {
    return NotificationStateIcon[receiver.notificationState];
  }

  close() {
    this.dialogRef.close();
  }
}

export interface NotificationStateViewerDialogComponentData {
  notificationStateModels: NotificationStateModel[];
}
