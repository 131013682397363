import { Component, OnInit, Input } from '@angular/core';
import { NotificationAttachmentModel } from '@app/api';
import { DocumentDownloadService } from '@app/shared/services';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {
  @Input() attachments : NotificationAttachmentModel[];
  @Input() showNotification : boolean;
  @Input() notificationText : string;
  @Input() content : string;

  constructor(private downloadService: DocumentDownloadService) { }

  ngOnInit() {
  }

  async downloadFile(file: NotificationAttachmentModel) {
    var id = file.attachmentId;
    var fileName = file.fileName;

    await this.downloadService.downloadAttachment({
      id,
      fileName
    });
  }
}
