import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';


@Component({
  selector: 'c4-ok-dialog',
  templateUrl: './ok-dialog.component.html',
  styleUrls: ['./ok-dialog.component.scss']
})
export class OkDialogComponent implements OnInit {
  title: string;
  description: string;
  params: any;

  constructor(public confirmRef: MatDialogRef<OkDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.title = (data.title) ? data.title : 'TITLE_NOT_SET';
    this.description = (data.description) ? data.description : 'DESCRIPTION_NOT_SET';
    this.params = (data.params) ? data.params : undefined;
  }

  ngOnInit() { }

  ok() {
    this.confirmRef.close();
  }
}
