import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NgControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { TranslateService } from '@ngx-translate/core';
import { Utils } from '@app/core';
import { ErrorStateMatcher } from '@angular/material/core';

@Component({
  selector: 'c4-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
})
export class AutocompleteComponent implements OnInit, ControlValueAccessor {
  private _allowNull: boolean = true;
  private _valueMember: string;
  private _displayMember: string;
  private _displayFunc: any;
  private _options: any[];
  private _filterFunc: any;
  private _selectedOption: any;
  private _selectedValue: any;
  private _forceSelection: boolean;
  private _filteredOptions: any[];
  private _required: boolean;
  private _placeholder: string;
  private _showClearButton: boolean;
  private _formControl: UntypedFormControl;
  private _translateOptions: boolean = true;
  private _allowAutofill = true;

  public isDisabled: boolean = false;
  public filteredOptions: Observable<any[]>;

  onTouched = () => {};
  errorStateMatcher: ErrorStateMatcher = {
    isErrorState: () => this.showError,
  };

  @ViewChild(MatAutocomplete, { static: true }) autoComplete: MatAutocomplete;
  @ViewChild(MatAutocompleteTrigger, { static: true }) autocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('input', { static: true }) input;

  @Output()
  public selectedOptionChanged = new EventEmitter();
  @Output()
  public selectedValueChanged = new EventEmitter();

  public get formControl(): UntypedFormControl {
    if (Utils.isNullOrUndefined(this._formControl)) {
      this._formControl = new UntypedFormControl();
    }
    return this._formControl;
  }
  public set formControl(v: UntypedFormControl) {
    this._formControl = v;
  }
  public get allowNull(): boolean {
    return this._allowNull;
  }

  @Input()
  public set translateOptions(value) {
    this._translateOptions = value;
  }

  public get translateOptions() {
    return this._translateOptions;
  }

  @Input()
  public set allowAutofill(value) {
    this._allowAutofill = value;
  }

  public get allowAutofill() {
    return this._allowAutofill;
  }

  @Input()
  public set allowNull(v: boolean) {
    this._allowNull = v;
  }
  public get showClearButton(): boolean {
    return this._showClearButton;
  }
  @Input()
  public set showClearButton(v: boolean) {
    this._showClearButton = v;
  }
  public get placeholder(): string {
    return this._placeholder;
  }
  @Input()
  public set placeholder(v: string) {
    this._placeholder = v;
  }
  public get required(): boolean {
    return this._required;
  }
  @Input()
  public set required(v: boolean) {
    this._required = v;
  }
  public get forceSelection(): boolean {
    return this._forceSelection;
  }
  @Input()
  public set forceSelection(v: boolean) {
    this._forceSelection = v;
  }
  public get valueMember(): string {
    return this._valueMember;
  }
  @Input()
  public set valueMember(v: string) {
    this._valueMember = v;
  }
  public get displayMember(): string {
    return this._displayMember;
  }
  @Input()
  public set displayMember(v: string) {
    this._displayMember = v;
  }
  public get displayFunc(): any {
    return this._displayFunc;
  }
  @Input()
  public set displayFunc(v: any) {
    this._displayFunc = v;
  }
  public get options(): any[] {
    return this._options;
  }
  @Input()
  public set options(v: any[]) {
    this._options = v;
    this.formControl.updateValueAndValidity({ onlySelf: false, emitEvent: true }); // update filtered(~shown) values on options change
  }
  public get filterFunc(): any {
    return this._filterFunc;
  }
  @Input()
  public set filterFunc(v: any) {
    this._filterFunc = v;
  }
  public get selectedOption(): any {
    return this._selectedOption;
  }
  @Input()
  public set selectedOption(v: any) {
    if (this._selectedOption !== v) {
      this._selectedOption = v;
      if (Utils.isNullOrUndefined(v)) {
        this.selectedValue = null;
      } else if (this.useValueMember() && v.hasOwnProperty(this.valueMember)) {
        this.selectedValue = v[this.valueMember];
      }
      this.selectedOptionChanged.emit(v);
    }
  }
  public get selectedValue(): any {
    return this._selectedValue;
  }
  @Input()
  public set selectedValue(v: any) {
    if (this._selectedOption !== v) {
      this._selectedValue = v;
      this.selectedValueChanged.emit(v);
    }
  }

  constructor(public control: NgControl, private translationService: TranslateService) {
    this.control.valueAccessor = this;
  }

  public get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }

  public get showError(): boolean {
    if (!this.control) {
      return false;
    }

    const { dirty, touched } = this.control;

    return this.invalid ? dirty || touched : false;
  }

  ngOnInit() {
    let selectedOption = null;
    if (!Utils.isNullOrUndefined(this.selectedValue)) {
      selectedOption = this.getOption(this.selectedValue);
    }
    if (!Utils.isNullOrUndefined(this.selectedOption) && selectedOption === null) {
      if (this.options.indexOf(this.selectedOption) >= 0) {
        selectedOption = this.selectedOption;
      }
    }
    if (!this.allowNull && selectedOption === null && this.options && this.options.length > 0) {
      selectedOption = this.options[0];
    }
    this.filteredOptions = this.formControl.valueChanges.pipe(
      startWith(''),
      map(option => (typeof option === 'string' ? option : this.getOptionDisplayValue(option))),
      map(value => this.filterOptions(value))
    );
    this.filteredOptions.subscribe(filtered => {
      this._filteredOptions = filtered;
    });
    if (!Utils.isNullOrUndefined(selectedOption)) {
      this.selectOption(selectedOption);
    }
  }

  getOptionDisplayValue(option: any) {
    if (Utils.isNullOrUndefined(option) || option === '') {
      return '';
    }
    if (!Utils.isNullOrUndefined(this.displayFunc) && Utils.isFunction(this.displayFunc)) {
      return this.displayFunc(option) + '';
    }
    if (
      !Utils.isNullOrUndefined(option) &&
      !Utils.isNullOrUndefined(this.displayMember) &&
      option.hasOwnProperty(this.displayMember)
    ) {
      return option[this.displayMember] + '';
    }
    return option + '';
  }

  static counter = 0;

  getTranslatedOptionDisplayValue(option: any) {
    const key = this.getOptionDisplayValue(option);

    if (this.translateOptions == false || Utils.isNullOrWhitespace(key)) {
      return key;
    }

    const translation = this.translationService.instant(key);

    return translation;
  }

  filterOptions(value: string): any[] {
    if (Utils.isNullOrUndefined(this.options)) {
      return [];
    }

    if (Utils.isNullOrUndefined(value)) {
      return [...this.options];
    }

    const result = [];

    const filterValue = value.toLowerCase();
    for (const option of this.options) {
      if (!Utils.isNullOrUndefined(this.filterFunc) && Utils.isFunction(this.filterFunc)) {
        if (this.filterFunc(option, filterValue)) {
          result.push(option);
        }
      } else {
        const test = this.getTranslatedOptionDisplayValue(option);
        if (test.toLowerCase().indexOf(filterValue) >= 0) {
          result.push(option);
        }
      }
    }
    return result;
  }

  useValueMember(): boolean {
    return this.valueMember !== undefined && this.valueMember !== null && this.valueMember.length > 0;
  }

  getOption(value: any) {
    if (this.useValueMember()) {
      for (const option of this.options) {
        if (option.hasOwnProperty(this.valueMember) && option[this.valueMember] === value) {
          return option;
        }
      }
    }
    return null;
  }

  afterSelection(action: string) {
    this.onTouched();
    if (action === 'blur' && this.autoComplete.isOpen) {
      return;
    }
    let val = this.formControl.value;
    let isFilterString = false;
    if (typeof val === 'string') {
      val = val.trim();
      isFilterString = val.length > 0;
    }
    const exactlyOne = !Utils.isNullOrUndefined(this._filteredOptions) && this._filteredOptions.length === 1;
    if (Utils.isNullOrUndefined(this.options)) {
      this.selectOption(null);
    } else if (this.options.indexOf(val) >= 0) {
      this.selectOption(val);
      if (!this.allowAutofill) this.clear();
    } else if ((isFilterString || this.forceSelection) && exactlyOne) {
      if (this.allowAutofill) this.selectOption(this._filteredOptions[0]);
    } else if (this.forceSelection) {
      if (!Utils.isNullOrUndefined(this.selectedOption) && (this.required || val !== '')) {
        // reset to last selected option
        this.selectOption(this.selectedOption, false);
      } else if (this.allowNull) {
        this.selectOption(null);
      } else {
        this.selectOption(this.options[0]);
      }
    } else {
      if (this.allowAutofill) this.selectOption(null);
    }
  }

  clear() {
    this.selectOption(null);
  }

  private selectOption(option: any, setSelectedOption: boolean = true) {
    if (Utils.isNullOrUndefined(option)) {
      this.formControl.setValue('');
      this.selectedOption = null;
    } else {
      this.formControl.setValue(option);
    }
    if (setSelectedOption) {
      this.selectedOption = option;
    }
  }

  // ControlValueAccessor
  writeValue(obj: any): void {
    if (this.useValueMember()) {
      const option = this.getOption(obj);
      this.selectOption(option);
    } else {
      this.selectOption(obj);
    }
  }

  registerOnChange(fn: any): void {
    if (this.useValueMember()) {
      this.selectedValueChanged.subscribe(fn);
    } else {
      this.selectedOptionChanged.subscribe(fn);
    }
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (this.isDisabled) {
      this.formControl.disable();
    } else {
      this.formControl.enable();
    }
  }

  getError() {
    const errors = this.control?.errors ?? {};
    return Object.keys(errors)[0];
  }
}
