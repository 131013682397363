import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-active-toggle',
  templateUrl: './active-toggle.component.html',
  styleUrls: ['./active-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ActiveToggleComponent,
    },
  ],
})
export class ActiveToggleComponent implements ControlValueAccessor {
  @Input() icon: string;
  @Input() label: string;
  @Input() key: string;

  @Input() set disabled(isDisabled: boolean | string) {
    this.isDisabled = isDisabled === true || isDisabled === '';
  }

  @Input() isActive = false;
  @Output() readonly isActiveChange = new EventEmitter<boolean>();

  toggle() {
    if (this.isDisabled) return;
    this.isActive = !this.isActive;
    this.onChange(this.isActive);
    this.isActiveChange.emit(this.isActive);
  }

  // ----- Reactive Forms Methods -----

  isDisabled: boolean = false;
  private isTouched: boolean = false;
  onChange: Func<boolean> = _ => {};
  private onTouched: EmptyCallback = () => {};

  writeValue(isActive: boolean): void {
    this.isActive = isActive;
  }

  registerOnChange(fn: Func<boolean>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: EmptyCallback): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
  }
}
