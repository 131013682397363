<div class="c4-dialog-wrapper">
  <h1 mat-dialog-title>{{ 'dialogs.notificationSettings.caption' | translate }}</h1>
  <mat-dialog-content>
    <div>{{ 'dialogs.notificationSettings.description' | translate }}</div>
    <br>
    <div>{{ 'dialogs.notificationSettings.descriptionInfo' | translate }}</div>
    <br>
    <div *ngIf="companies.dataReady" class="default-toggle-container">
      <span>{{ 'dialogs.notificationSettings.defaultSettings' | translate }}</span>
      <mat-slide-toggle class="toggle" [checked]="!isExternalNotificationDisabledByDefault"
                        (change)="updateGlobalSettings($event)">
      </mat-slide-toggle>
    </div>
    <div *ngIf="companies" class="app-company-list" [ngClass]="companies.busyClass">
      <div *ngFor="let company of companies.data" class="app-company-list-entry c4-busy-content">
        <div>{{company.name}}</div>
        <!--can not use [(ngModel)]="!company.userSettings.isExternalNotificationDisabled" - because of the '!' !!! -->
        <mat-slide-toggle *ngIf="companies.dataReady" [checked]="!company.userSettings.isExternalNotificationDisabled"
                          (change)="updateProject($event, company)" [disabled]="company.isExternalNotificationDisabled">
        </mat-slide-toggle>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="close()" type="button">{{ 'general.cancel' | translate }}</button>
  </mat-dialog-actions>
</div>