import { Component, Input } from '@angular/core';
import { DriveItemModel, DriveItemType } from '@app/api';
import { FileType } from '@app/shared/pipes';

@Component({
  selector: 'app-drive-item-icon',
  templateUrl: './drive-item-icon.component.html',
  styleUrls: ['./drive-item-icon.component.scss'],
})
export class DriveItemIconComponent {
  @Input() set driveItem(driveItem: DriveItemModel) {
    this.isFolder = driveItem?.type === DriveItemType.Folder;
    this.extension = this.isFolder ? '' : this.filetype.transform(driveItem.name?.toLowerCase() ?? '');
  }

  extension: string = '';
  isFolder = false;

  constructor(private filetype: FileType) {}
}
