import { Component, Input } from '@angular/core';
import { Orientation } from '@app/core/enumerations';

@Component({
  selector: 'app-acrylic-container',
  templateUrl: './acrylic-container.component.html',
  styleUrls: ['./acrylic-container.component.scss'],
})
export class AcrylicContainerComponent {
  @Input() orientation: Orientation = Orientation.horizontal;
}
