const blackLum = luminanceFactor(0xff, 0xff, 0xff); // precalc?
const whiteLum = luminanceFactor(0x00, 0x00, 0x00); // precalc?

export function contrast(color: string, mode: 'class' | 'color' = 'class') {
  const [r, g, b] = extractRGB(color);

  const colorLum = luminanceFactor(r, g, b);

  const blackRatio = luminanceRatio(colorLum, blackLum);
  const whiteRatio = luminanceRatio(colorLum, whiteLum);

  if (mode == 'class') {
    return blackRatio > whiteRatio ? 'color-black' : 'color-white';
  }

  return blackRatio > whiteRatio ? 'black' : 'white';
}

function extractRGB(color: string) {
  if (color.startsWith('#')) {
    color = color.slice(1);
  }

  const r = parseInt(color.slice(0, 2), 16);
  const g = parseInt(color.slice(2, 4), 16);
  const b = parseInt(color.slice(4, 6), 16);

  return [r, g, b];
}

function luminanceRatio(color1luminance: number, color2luminance: number) {
  return color1luminance > color2luminance
    ? (color2luminance + 0.05) / (color1luminance + 0.05)
    : (color1luminance + 0.05) / (color2luminance + 0.05);
}

function luminanceFactor(r: number, g: number, b: number) {
  var a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });

  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722; // luminance factor for red, green, blue
}
