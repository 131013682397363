import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { LogService, ApiService } from '@app/core';
import { UserNotificationService } from '@app/shared/services';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import { Busy, using, BusyScope } from '@app/shared/utils/busy';

@Component({
  selector: 'app-atrust-sign',
  templateUrl: './atrust-sign.component.html',
  styleUrls: ['./atrust-sign.component.scss'],
})
export class ATrustSignComponent implements OnInit {
  @Input() documentId: string;
  @Input() resource: string;
  @Output() finished: EventEmitter<any> = new EventEmitter();

  loadingBusy: Busy = <Busy>{
    isBusy: false,
  };
  resultUrl: SafeUrl;

  constructor(
    private apiservice: ApiService,
    private userNotification: UserNotificationService,
    private log: LogService,
    private sanitizer: DomSanitizer
  ) {}

  async ngOnInit() {
    try {
      await using(new BusyScope(this.loadingBusy), async () => {
        const signResult = await this.apiservice.startATrustSignDocument(this.documentId, this.resource);
        if (signResult == null || !signResult.sessionId || !signResult.resultUrl) {
          this.userNotification.notify('documents.signError');
          return;
        }

        this.resultUrl = this.sanitizer.bypassSecurityTrustResourceUrl(signResult.resultUrl);
      });
    } catch (e) {
      this.log.error(`Failed to sign document ${this.documentId}`, e);
      this.userNotification.notify('documents.signError', { error: e });
    }
  }
}
