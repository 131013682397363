import { HttpHeaders, HttpRequest } from '@angular/common/http';
import { SyncClient, SyncModel, SyncResourceCategory } from '@app/api';
import { OfflineService } from '@app/core/services';
import { capSQLiteSet, SQLiteDBConnection } from '@capacitor-community/sqlite';
import { Directory } from '@capacitor/filesystem';
import { IPSProject, IPSResource } from '../../definitions';
import { IQueryWrapper } from '../../offline-query-wrapper';
import { OfflineServiceFileHandler } from '../../OfflineServiceFileHandler';
import { SQLStatements } from '../../SQLStatements';
import { IMigration, Statement } from '../definitions';
import { SQLiteTables } from '../utils';

export const Migration: IMigration = {
  name: 'M220906_ResourceIdentifiers',
  getStatements() {
    const createResources = Statement`
      CREATE TABLE IF NOT EXISTS resources (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        json TEXT NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    return [createResources];
  },

  async updateData(connection: SQLiteDBConnection, syncClient: SyncClient) {
    const projectsQuery = await connection.query(`SELECT * FROM ${SQLiteTables.Projects}`);
    const existingProjectIds = projectsQuery?.values?.map((p: IPSProject) => p.id) ?? [];

    const changeRequest = new SyncModel({
      resources: new SyncResourceCategory(),
    });

    const fileHandler = new OfflineServiceFileHandler();

    for (const projectId of existingProjectIds) {
      const changes = await syncClient.getChanges(projectId, changeRequest).toPromise();
      const statements = new SQLStatements(connection);

      if (changes.resources) {
        try {
          statements.remove(SQLiteTables.Resources, { project: projectId });

          for (const resource of changes.resources.entries) {
            statements.insertOrReplace<IPSResource>(SQLiteTables.Resources, {
              id: resource.key.name,
              project: projectId,
              json: JSON.stringify(resource),
            });
          }

          const existingDriveItems = await connection.query(`SELECT * FROM ${SQLiteTables.DriveItems} WHERE project = ?`, [
            projectId,
          ]);
          for (const driveItem of existingDriveItems?.values ?? []) {
            const oldResource = driveItem.resource;
            const newResource = changes.resources.entries.find(r => r.moduleType == driveItem.resource)?.key?.name;
            if (newResource) {
              driveItem.resource = newResource;
              driveItem.json = JSON.stringify(driveItem);

              statements.update(SQLiteTables.DriveItems, driveItem, {
                id: driveItem.id,
                project: driveItem.project,
                resource: oldResource,
              });

              let oldPath = [projectId, oldResource].join('/');
              let newPath = [projectId, newResource].join('/');

              if (fileHandler.checkFileExists(oldPath, Directory.Data)) {
                fileHandler.rename(oldPath, newPath, Directory.Data);
              }
            } else {
              // remove if resource couln't be found - this should not happen but lets the client fail if not removed
              statements.remove(SQLiteTables.DriveItems, {
                id: driveItem.id,
                project: driveItem.project,
                resource: oldResource,
              });
            }
          }

          await statements.execute();
        } catch (e) {
          console.error(e);
        }
      }
    }
  },
};
