import { Component, OnInit, Input } from '@angular/core';

export interface IHeader {
  label: string;
  icon: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements IHeader, OnInit {
  @Input() label: string;
  @Input() icon: string;

  constructor() {}

  ngOnInit() {}
}
