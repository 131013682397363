import { Component, OnInit, Inject, ViewChild, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ApiService, LogService } from '@app/core';
import { CallbackResponse, CallbackResult, DrawToSignDataModel, SignDocumentResultModel } from '@app/api';
import { UserNotificationService } from '@app/shared/services';
import { using, BusyScope, Busy } from '@app/shared/utils/busy';
import { SignaturePadComponent } from '@almothafar/angular-signature-pad';

@Component({
  selector: 'app-draw-to-sign',
  templateUrl: './draw-to-sign.component.html',
  styleUrls: ['./draw-to-sign.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DrawToSignComponent implements OnInit, Busy {
  @Input() documentId: string;
  @Input() resource: string;
  @Output() finished: EventEmitter<CallbackResult> = new EventEmitter();

  @ViewChild('signature') signaturePad: SignaturePadComponent;

  isBusy: boolean;
  loadingBusy: Busy = <Busy>{
    isBusy: false,
  };
  public form: UntypedFormGroup;
  isEmpty: boolean = true;
  startSignResult: SignDocumentResultModel;

  public signaturePadOptions: Object = {
    minWidth: 2,
    canvasWidth: 442 - 2 * 2,
    canvasHeight: 150,
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private apiservice: ApiService,
    private userNotification: UserNotificationService,
    private log: LogService
  ) {}

  async getSignResult(): Promise<CallbackResponse> {
    return await using(new BusyScope(this), async () => {
      return await this.apiservice.dataUrlDrawToSign(
        this.startSignResult.sessionId,
        new DrawToSignDataModel({
          firstname: this.f.firstnameInput.value,
          lastname: this.f.lastnameInput.value,
          signatureImage: this.signaturePad.toDataURL('image/png'),
        }),
        this.resource
      );
    }).catch(e => {
      this.log.error(`Failed to sign document ${this.documentId}`, e);
      this.userNotification.notify('documents.signError', { error: e });
      return null;
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      firstnameInput: '',
      lastnameInput: ['', [Validators.required]],
    });

    //TODO: Skeleton loading placeholder

    try {
      let signResult: SignDocumentResultModel;
      await using(new BusyScope(this.loadingBusy), async () => {
        signResult = await this.apiservice.startDrawToSignDocument(this.documentId, this.resource);
        if (signResult == null || !signResult.sessionId) {
          this.userNotification.notify('documents.signError');
          return;
        }

        if (signResult.signee) {
          this.f.firstnameInput.setValue(signResult.signee.firstname);
          this.f.lastnameInput.setValue(signResult.signee.lastname);
        }
      });

      this.startSignResult = signResult;
    } catch (e) {
      this.log.error(`Failed to sign document ${this.documentId}`, e);
      this.userNotification.notify('documents.signError', { error: e });
      this.finished.emit(null);
    }
  }

  drawComplete() {
    this.isEmpty = this.signaturePad.isEmpty();
  }

  clear() {
    this.signaturePad.clear();
    this.isEmpty = true;
  }

  get isValid(): boolean {
    return this.form.valid && !this.isEmpty && !this.loadingBusy.isBusy;
  }
}

export class SignPadResult {
  firstname: string;
  lastname: string;
  signatureImage: string;
}
