import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-checkbox-round',
  templateUrl: './checkbox-round.component.html',
  styleUrls: ['./checkbox-round.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxRoundComponent implements OnInit {
  @Input() color: string = 'accent';
  @Input() checked: boolean;
  @Input() preventClick: boolean = false;
  @Output() change = new EventEmitter<any>();
  @Output() ownClick = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  click(event: any) {
    //support own logic for gallery selection (checkbox)
    if (this.preventClick) {
      event.preventDefault();
      event.stopPropagation();
      this.ownClick.emit(event);
    }
  }
}
