import { EventEmitter } from '@angular/core';
import { Utils } from '@app/core';
import * as L from 'leaflet';
import { setupFeatureInformations } from './setupFeatureInformations';
import { ShapeTypes } from './interfaces';

export class LeafletFreedraw {
  private context: L.corner4.MappingType | null = null;
  private color: string | null = null;
  private polyline: L.Polyline | null = null;
  private polylines: L.Polyline[] = [];

  constructor(private map: L.Map) {}

  onchange = new EventEmitter<void>();

  get drawing() {
    return this.context != null;
  }

  start(context: L.corner4.MappingType, color: string | null) {
    this.context = context;
    this.color = color;

    // this.map.tap.disable();
    this.map.dragging.disable();

    this.map.on('mouseup', this.mouseup, this);
    this.map.on('mousedown', this.mousedown, this);
    this.map.on('mousemove', this.mousemove, this);
    this.map.on('contextmenu', this.contextmenu, this);

    L.DomEvent.on(this.map.getContainer(), 'touchstart', this.touchstart, this);
  }

  complete() {
    const madeChanges = this.polylines.length > 0;

    this.polyline = null;
    this.polylines = [];

    // this.map.tap.enable();
    this.map.dragging.enable();

    if (this.context) {
      this.map.off('mouseup', this.mouseup, this);
      this.map.off('mousedown', this.mousedown, this);
      this.map.off('mousemove', this.mousemove, this);
      this.map.off('contextmenu', this.contextmenu, this);

      const container = this.map.getContainer();

      L.DomEvent.off(container, 'touchend', this.touchend, this);
      L.DomEvent.off(container, 'touchmove', this.touchmove, this);
      L.DomEvent.off(container, 'touchstart', this.touchstart, this);
      L.DomEvent.off(container, 'touchcancel', this.touchcancel, this);
    }

    this.context = null;

    return madeChanges;
  }

  cancel() {
    for (const polyline of this.polylines) {
      polyline.remove();
    }

    this.complete();
  }

  private contextmenu(e: L.LeafletMouseEvent) {
    e.originalEvent.preventDefault();
    e.originalEvent.stopPropagation();
  }

  private touchcancel(e: TouchEvent) {
    e.preventDefault();
    e.stopPropagation();

    this.completeDraw();

    L.DomEvent.off(this.map.getContainer(), 'touchend', this.touchend, this);
    L.DomEvent.off(this.map.getContainer(), 'touchmove', this.touchmove, this);
    L.DomEvent.off(this.map.getContainer(), 'touchcancel', this.touchcancel, this);
  }

  private touchstart(e: TouchEvent) {
    L.DomEvent.preventDefault(e);
    L.DomEvent.stopPropagation(e);

    this.startDraw();

    L.DomEvent.on(this.map.getContainer(), 'touchend', this.touchend, this);
    L.DomEvent.on(this.map.getContainer(), 'touchmove', this.touchmove, this);
    L.DomEvent.on(this.map.getContainer(), 'touchcancel', this.touchcancel, this);
  }

  private touchmove(e: TouchEvent) {
    L.DomEvent.preventDefault(e);
    L.DomEvent.stopPropagation(e);

    const top = e.touches.item(e.touches.length - 1);
    const point = L.point([top.clientX, top.clientY]);

    const latlng = this.map.containerPointToLatLng(point);

    this.advanceDraw(latlng);
  }

  private touchend(e: TouchEvent) {
    L.DomEvent.preventDefault(e);
    L.DomEvent.stopPropagation(e);

    this.completeDraw();

    const container = this.map.getContainer();

    L.DomEvent.off(container, 'touchend', this.touchend, this);
    L.DomEvent.off(container, 'touchmove', this.touchmove, this);
    L.DomEvent.off(container, 'touchcancel', this.touchcancel, this);
  }

  private mouseup(e: L.LeafletMouseEvent) {
    L.DomEvent.stopPropagation(e);

    this.completeDraw();
  }

  private mousedown(e: L.LeafletMouseEvent) {
    L.DomEvent.stopPropagation(e);

    const evt = e.originalEvent;

    if (evt.buttons == 1) {
      this.startDraw();
    }
  }

  private mousemove(e: L.LeafletMouseEvent) {
    L.DomEvent.stopPropagation(e);

    this.advanceDraw(e.latlng);
  }

  private startDraw() {
    const color = (this.context.color || this.color) as string;

    this.polyline = L.polyline([]).addTo(this.map);
    this.polylines.push(this.polyline);

    if (typeof color == 'string') {
      this.polyline.setStyle({
        color: color,
      });
    }

    setupFeatureInformations(this.polyline, ShapeTypes.freehand, color, this.context);
  }

  private advanceDraw(latlng: L.LatLngExpression) {
    if (this.polyline) {
      this.polyline.addLatLng(latlng);
    }
  }

  private completeDraw() {
    this.polyline = null;

    if (this.polylines.length > 0) {
      this.onchange.emit();
    }
  }
}
