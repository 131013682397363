import { Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { Component, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { DefectModel, DefectState, DefectStatePermission, DefectStateProjectEntityState, UserSessionModel } from '@app/api';
import {
  ApiService,
  AppRoutingData,
  BaseSubscriptionComponent,
  DataHolder,
  GlobalsService,
  nameof,
  pathFragmentsTo,
  ProjectService,
} from '@app/core';
import { LocalizedDatePipe } from '@app/shared/pipes';
import { Busy } from '@app/shared/utils/busy';
import { TranslateService } from '@ngx-translate/core';
import { C4GridDef, C4GridFilterType, C4GridMatchMode, C4GridSelectOptions, GridComponent, GridSelectionMode } from '../grid';
import { FilterMetadata } from 'primeng/api';

export class GridDefectModel extends DefectModel {
  selected?: boolean;
  rowClass?: string;
  organizationId?: string;
  organizationName?: string;
}

export enum DefectsListMode {
  large = 'large',
  small = 'small',
  singleSelectIntegrated = 'singleSelectIntegrated',
}

@Component({
  selector: 'app-defects-list',
  templateUrl: './defects-list.component.html',
  styleUrls: ['./defects-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DefectsListComponent extends BaseSubscriptionComponent implements OnInit, OnDestroy, Busy {
  @ViewChild('state', { static: true }) stateTemplate: TemplateRef<any>;
  @ViewChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('contextMenu', { static: true }) contextTemplate: TemplateRef<any>;
  @ViewChild('selectAll') selectAll: TemplateRef<any>;
  @ViewChild('selectRow') selectRow: TemplateRef<any>;
  @ViewChild('nameCol') nameCol: TemplateRef<any>;
  @ViewChild('mailSent') notified: TemplateRef<any>;
  @ViewChild('overTime') overTime: TemplateRef<any>;
  @ViewChild(GridComponent) grid: GridComponent;

  @Input() isBusy: boolean;
  @Input() rows: number = 10;
  @Input() rowsOptions: number[] = [5, 10, 20, 50, 100];
  @Input() mode: DefectsListMode = DefectsListMode.large;

  @Input() set defects(value: DefectModel[]) {
    this.allDefects = value;
    if (this.isInitialized) this.updateData();
  }
  @Input() showMine: boolean = false;
  @Input() showOvertime: boolean = false;
  @Input() canCreateDefect: boolean;

  // Grid Selection in Item Action Dialog
  private _selection: any;
  get selection(): any {
    return this._selection;
  }
  @Input() set selection(value: any) {
    this._selection = value;
    this.selectionChange.emit(value);
  }
  @Output() selectionChange = new EventEmitter<any>();

  @Output() shareItemEvent = new EventEmitter<string>();
  @Output() copyItemEvent = new EventEmitter<string>();
  @Output() reportItemEvent = new EventEmitter<string>();
  // Selection via Checkmarks
  @Output() customSelectionChange = new EventEmitter<DefectModel[]>();
  @Output() gridFilterChange = new EventEmitter<Record<string, FilterMetadata>>();
  visibleOverviewFields: string[] = [];
  pageStart: number = 0;
  today: Date;
  gridDef: C4GridDef;
  defectsHolder: DataHolder<GridDefectModel[]>;
  states = DefectState;
  selectionMode: GridSelectionMode = GridSelectionMode.none;

  private user: UserSessionModel;
  private allDefects: GridDefectModel[];
  private myDefects: GridDefectModel[];
  private isInitialized: boolean = false;
  private defectStates: C4GridSelectOptions[] = [];
  private assignedCrafts: C4GridSelectOptions[] = [];
  private defectTypes: C4GridSelectOptions[] = [];
  private defectReasons: C4GridSelectOptions[] = [];
  private organizations: C4GridSelectOptions[] = [];
  private defectRegions: C4GridSelectOptions[] = [];
  private defectAreas: C4GridSelectOptions[] = [];
  private defectFloors: C4GridSelectOptions[] = [];
  private defectRooms: C4GridSelectOptions[] = [];

  constructor(
    private apiService: ApiService,
    private datePipe: LocalizedDatePipe,
    private projectService: ProjectService,
    private router: Router,
    private translate: TranslateService,
    public globals: GlobalsService
  ) {
    super();
    this.today = new Date();
  }

  get responsiveColumns(): boolean {
    return this.gridDef.grid.mobileExpanded;
  }

  set responsiveColumns(value: boolean) {
    this.grid.responsiveColumns = value;
  }

  async ngOnInit() {
    this.selectionMode =
      this.mode == DefectsListMode.singleSelectIntegrated ? GridSelectionMode.single : GridSelectionMode.none;
    this.user = await this.apiService.getUserSession();
    this.defectsHolder = new DataHolder(this.initData());
    this.gridDef = this.getGridDef();
    this.visibleOverviewFields = [];
    this.gridDef.cols.forEach(c => {
      if (!c.hidden) this.visibleOverviewFields.push(c.field);
    });
    if (this.allDefects) this.updateData();
    this.isInitialized = true;
  }

  getSortedDefects(): GridDefectModel[] {
    let sortedDefects: GridDefectModel[];
    this.grid.dataTable.filteredValue && this.grid.dataTable.filteredValue.length > 0
      ? (sortedDefects = this.grid.dataTable.filteredValue)
      : (sortedDefects = this.grid.dataTable.value);
    return sortedDefects;
  }

  gridFilterChanged(filter: Record<string, FilterMetadata>) {
    this.deselectAll();
    this.gridFilterChange.emit(filter);
  }

  ngOnDestroy() {
    return super.ngOnDestroy();
  }

  toggleOwn(showMine: boolean) {
    this.showMine = showMine;
    this.updateData();
  }

  toggleOverTime(overtime: boolean) {
    this.showOvertime = overtime;
    this.updateData();
  }

  createItem(roomId: string) {
    if (!this.canCreateDefect) return;

    this.router.navigate(pathFragmentsTo(this.projectService.projectId, AppRoutingData.defects.path), {
      queryParams: { create: true, roomId: roomId },
    });
  }

  editItem(defect: DefectModel) {
    this.router.navigate(pathFragmentsTo(this.projectService.projectId, AppRoutingData.defects.path), {
      queryParams: { edit: true, id: defect.id },
    });
  }

  createCopyItem(id: string) {
    this.copyItemEvent.emit(id);
  }

  shareItem(id: string) {
    this.shareItemEvent.emit(id);
  }

  reportOfItem(id: string) {
    this.reportItemEvent.emit(id);
  }

  private initData(count: number = 5) {
    const defects: DefectModel[] = [];

    for (let i = 0; i < count; i++) {
      const model: GridDefectModel = new DefectModel({
        number: i,
        title: 'fakeData',
        craftName: 'fakeData',
        stateType: DefectState.Capture,
        currentState: new DefectStateProjectEntityState({
          title: 'fakeData',
        }),
        areaName: 'fakeData',
        regionName: 'fakeData',
        floorName: 'fakeData',
        roomName: 'fakeData',
        createdOn: new Date(),
        createdBy: 'fakeData',
        deadline: new Date(),
        grace: new Date(),
        hasNotifications: true,
      });

      model.organizationName = 'fakeData';

      defects.push(model);
    }

    return defects;
  }

  selectBar(): boolean {
    if (!this.defectsHolder.data) return false;
    return this.defectsHolder.data.some(s => s.selected);
  }

  rowSelect(row: any) {
    row.selected = !row.selected;
    const selectedDefects = this.defectsHolder.data.filter(s => s.selected);
    this.customSelectionChange.emit(selectedDefects);
  }

  //REDUNDANCY WARNING (plan-list, diary-list)
  allRowsSelect(e: Event) {
    e.stopImmediatePropagation();

    const data =
      this.grid.dataTable.filteredValue && this.grid.dataTable.filteredValue.length > 0
        ? this.grid.dataTable.filteredValue
        : this.defectsHolder.data.slice(this.grid.dataTable.first, this.grid.dataTable.first + this.grid.dataTable.rows) ?? [];

    let areAllSelected = data.every(r => r.selected);

    const select = !areAllSelected;
    data.forEach(r => (r.selected = select));

    this.customSelectionChange.emit(select ? data : []);
  }

  deselectAll() {
    this.defectsHolder.data.forEach(s => {
      s.selected = false;
    });
    this.customSelectionChange.emit([]);
  }

  stripHtml(input: string) {
    if (input) return input.replace(/(<([^>]+)>)/gi, '');

    return input;
  }

  getColorClass(colDate: Date, defect: GridDefectModel) {
    if (
      defect.stateType !== this.states.Deleted &&
      defect.stateType !== this.states.ApprovedByIntern &&
      defect.stateType !== this.states.ApprovedByClient &&
      colDate &&
      colDate instanceof Date
    ) {
      return colDate < this.today ? 'over-time' : 'in-time';
    } else {
      return 'no-time';
    }
  }

  public async updateData() {
    await this.defectsHolder?.updateData(async () => {
      if (this.allDefects) {
        this.myDefects = this.allDefects.filter(defect => defect.authorId === this.user.userId);
      }

      //mine toggle
      let result = this.showMine ? this.myDefects : this.allDefects;

      //overtimetoggle
      if (this.showOvertime) {
        result = result.filter(
          d =>
            d.stateType !== this.states.Deleted &&
            d.stateType !== this.states.ApprovedByIntern &&
            d.stateType !== this.states.ApprovedByClient &&
            ((d.grace && d.grace < this.today) || (d.deadline && d.deadline < this.today))
        );
      }

      const options = this.allDefects.reduce(
        (options, defect) => {
          options.states[defect.currentState.title] = defect.currentState;
          if (defect.craftName) options.crafts[defect.craftName] = defect.craftName;
          if (defect.typeName) options.types[defect.typeName] = defect.typeName;
          if (defect.reasonName) options.reasons[defect.reasonName] = defect.reasonName;
          if (defect.organizationName) options.organizations[defect.organizationName] = defect.organizationName;
          if (defect.regionName) options.regions[defect.regionName] = defect.regionName;
          if (defect.areaName) options.areas[defect.areaName] = defect.areaName;
          if (defect.floorName) options.floors[defect.floorName] = defect.floorName;
          if (defect.roomName) options.rooms[defect.roomName] = defect.roomName;
          return options;
        },
        { states: {}, crafts: {}, types: {}, reasons: {}, organizations: {}, regions: {}, areas: {}, floors: {}, rooms: {} }
      );

      const gridOptionMapper = ([label, value]) => ({
        label,
        value,
      });

      this.defectStates.splice(0, this.defectStates.length, ...Object.entries(options.states).map(gridOptionMapper));
      this.assignedCrafts.splice(0, this.assignedCrafts.length, ...Object.entries(options.crafts).map(gridOptionMapper));
      this.defectTypes.splice(0, this.defectTypes.length, ...Object.entries(options.types).map(gridOptionMapper));
      this.defectReasons.splice(0, this.defectReasons.length, ...Object.entries(options.reasons).map(gridOptionMapper));
      this.organizations.splice(0, this.organizations.length, ...Object.entries(options.organizations).map(gridOptionMapper));
      this.defectRegions.splice(0, this.defectRegions.length, ...Object.entries(options.regions).map(gridOptionMapper));
      this.defectAreas.splice(0, this.defectAreas.length, ...Object.entries(options.areas).map(gridOptionMapper));
      this.defectFloors.splice(0, this.defectFloors.length, ...Object.entries(options.floors).map(gridOptionMapper));
      this.defectRooms.splice(0, this.defectRooms.length, ...Object.entries(options.rooms).map(gridOptionMapper));

      this.gridDef.grid.rowCount = result?.length ?? 0;
      result?.forEach(r => {
        r.rowClass = r.stateType === this.states.Deleted ? 'fade-row' : '';
      });
      this.customSelectionChange.emit([]);
      return result;
    }, true);
  }

  getGridDef(): C4GridDef {
    const def: C4GridDef = {
      initialSorting: [
        {
          field: 'number',
          order: 1,
        },
      ],
      grid: {
        filterRow: true,
        lazy: false,
        lazyInit: false,
        paging: true,
        responsive: false,
        scrollable: true,
        rowExpand: true,
        rows: this.rows,
        rowsOptions: this.rowsOptions,
      },
      row: {
        link: false,
      },
      cols: [],
    };

    if (this.mode == DefectsListMode.large) {
      def.cols.push({
        field: 'select',
        header: 'none',
        width: '3.5em',
        minWidth: '3.5em',
        priority: 4,
        cssClass: 'planbutton',
        sortable: false,
        template: this.selectRow,
        headerTemplate: this.selectAll,
      });
    }

    def.cols.push(
      {
        field: nameof<DefectModel>('number'),
        header: 'defects.number',
        width: '5em',
        minWidth: '5em',
        priority: this.mode == DefectsListMode.small ? 3 : 1,
        cssClass: 'right-aligned',
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      },
      {
        field: nameof<DefectModel>('title'),
        header: 'defects.title',
        width: '1*',
        minWidth: this.mode == DefectsListMode.large ? '10em' : '9em',
        priority: 1,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
        template: this.mode != DefectsListMode.singleSelectIntegrated ? this.nameCol : null,
      }
    );

    if (this.mode == DefectsListMode.large) {
      def.cols.push({
        field: 'contextmenu',
        header: 'none',
        width: '4em',
        minWidth: '4em',
        priority: 1,
        sortable: false,
        template: this.contextTemplate,
        cssClass: 'defect-list-ico',
      });
    }

    def.cols.push(
      {
        field: nameof<DefectModel>('currentState'),
        header: 'defects.state',
        width: '15em',
        minWidth: '15em',
        priority: 1,
        sortable: true,
        options: this.defectStates,
        filterType: C4GridFilterType.multiselect,
        filterMatchMode: C4GridMatchMode.equals,
        template: this.stateTemplate,
      },
      {
        field: nameof<DefectModel>('createdOn'),
        header: 'defects.createdOn',
        width: '12em',
        minWidth: '12em',
        priority: 3,
        sortable: true,
        filterType: C4GridFilterType.date,
        pipe: this.datePipe,
      },
      {
        field: nameof<DefectModel>('createdBy'),
        header: 'defects.createdBy',
        width: '10em',
        minWidth: '10em',
        priority: 1,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      },

      {
        field: nameof<DefectModel>('deadline'),
        header: 'defects.deadline',
        width: '12em',
        minWidth: '12em',
        priority: 3,
        sortable: true,
        filterType: C4GridFilterType.date,
        template: this.overTime,
        pipe: this.datePipe,
      },
      {
        field: nameof<DefectModel>('grace'),
        header: 'defects.grace',
        width: '12em',
        minWidth: '12em',
        priority: 3,
        sortable: true,
        filterType: C4GridFilterType.date,
        template: this.overTime,
        pipe: this.datePipe,
      }
    );

    if (this.mode == DefectsListMode.small) {
      def.cols.push({
        field: '',
        header: 'defects.actions',
        width: '8em',
        minWidth: '8em',
        cssClass: 'action-flex-grid',
        priority: 1,
        sortable: false,
        template: this.actionsTemplate,
      });
    } else {
      def.cols.push(
        {
          field: nameof<DefectModel>('typeName'),
          header: 'defects.typeName',
          width: '10em',
          minWidth: '10',
          priority: 3,
          sortable: true,
          options: this.defectTypes,
          filterType: C4GridFilterType.multiselect,
          filterMatchMode: C4GridMatchMode.equals,
        },
        {
          field: nameof<DefectModel>('hasNotifications'),
          header: 'defects.hasNotifications',
          width: '5em',
          minWidth: '5em',
          priority: 3,
          sortable: true,
          cssClass: 'center-content',
          template: this.notified,
        },
        {
          field: nameof<GridDefectModel>('organizationName'),
          header: 'defects.organizationName',
          width: '14em',
          minWidth: '14em',
          priority: 3,
          sortable: true,
          options: this.organizations,
          filterType: C4GridFilterType.multiselect,
          filterMatchMode: C4GridMatchMode.contains,
        },
        {
          field: nameof<DefectModel>('craftName'),
          header: 'defects.craftName',
          width: '14em',
          minWidth: '14em',
          priority: 3,
          sortable: true,
          options: this.assignedCrafts,
          filterType: C4GridFilterType.multiselect,
          filterMatchMode: C4GridMatchMode.equals,
        },
        {
          field: nameof<DefectModel>('areaName'),
          header: 'defects.areaName',
          width: '12em',
          minWidth: '12em',
          priority: 3,
          sortable: true,
          options: this.defectAreas,
          filterType: C4GridFilterType.multiselect,
          filterMatchMode: C4GridMatchMode.contains,
        },
        {
          field: nameof<DefectModel>('regionName'),
          header: 'defects.regionName',
          width: '12em',
          minWidth: '12em',
          priority: 3,
          sortable: true,
          options: this.defectRegions,
          filterType: C4GridFilterType.multiselect,
          filterMatchMode: C4GridMatchMode.contains,
        },
        {
          field: nameof<DefectModel>('floorName'),
          header: 'defects.floorName',
          width: '12em',
          minWidth: '12em',
          priority: 3,
          sortable: true,
          options: this.defectFloors,
          filterType: C4GridFilterType.multiselect,
          filterMatchMode: C4GridMatchMode.contains,
        },
        {
          field: nameof<DefectModel>('roomNumber'),
          header: 'defects.internalNumber',
          width: '10em',
          minWidth: '10em',
          priority: 3,
          sortable: true,
          filterType: C4GridFilterType.text,
          filterMatchMode: C4GridMatchMode.contains,
        },
        {
          field: nameof<DefectModel>('roomName'),
          header: 'defects.roomName',
          width: '12em',
          minWidth: '12em',
          priority: 3,
          sortable: true,
          options: this.defectRooms,
          filterType: C4GridFilterType.multiselect,
          filterMatchMode: C4GridMatchMode.contains,
        },
        {
          field: nameof<DefectModel>('reasonName'),
          header: 'defects.reasonName',
          width: '12em',
          minWidth: '12em',
          priority: 3,
          sortable: true,
          options: this.defectReasons,
          filterType: C4GridFilterType.multiselect,
          filterMatchMode: C4GridMatchMode.equals,
        },
        {
          field: 'complainerOrganizationName',
          header: 'defects.complainerOrganizationName',
          width: '14em',
          minWidth: '14em',
          priority: 3,
          sortable: true,
          filterType: C4GridFilterType.text,
          filterMatchMode: C4GridMatchMode.contains,
        }
        // { // Prepared for future use
        //   field: 'complainerUserName',
        //   header: 'defects.complainerUserName',
        //   width: '14em',
        //   minWidth: '14em',
        //   priority: 3,
        //   sortable: true,
        //   filterType: C4GridFilterType.text,
        //   filterMatchMode: C4GridMatchMode.contains,
        // }
      );
    }
    return def;
  }
}
