import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GenerateZipDownloadUrlModel } from '@app/api';
import { ApiService, AppConfigService } from '@app/core';
import { UserNotificationService } from '@app/shared/services';
import { BusyScope, using } from '@app/shared/utils/busy';

@Component({
  selector: 'app-copy-info-dialog',
  templateUrl: './copy-info-dialog.component.html',
  styleUrls: ['./copy-info-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CopyInfoDialogComponent implements OnInit {
  isBusy: boolean;
  title: string;
  description: string;
  copyableString: string;

  constructor(
    private apiService: ApiService,
    private userNotification: UserNotificationService,
    public dialogRef: MatDialogRef<CopyInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data?.title ?? 'general.copy';
    this.description = data?.description;
    this.copyableString = data?.copyableString ?? '';
  }

  ngOnInit(): void {}

  notifyUser() {
    this.userNotification.notify('general.successMsg.copyToClipboard');
  }

  close() {
    this.dialogRef.close();
  }
}
