import { Component, Input, OnInit } from '@angular/core';
import { CustomizationService } from '@app/shared/services';

const hexColorRegex = /^#?([0-9A-Za-z]{3}|[0-9A-Za-z]{6})$/;

@Component({
  selector: 'app-color-pill',
  templateUrl: './color-pill.component.html',
  styleUrls: ['./color-pill.component.scss'],
})
export class ColorPillComponent implements OnInit {
  private _hexColorString: string;

  @Input() showFallback = true;
  @Input() label: string;
  @Input() flat: boolean = false;

  @Input()
  public set color(color: string) {
    if (!hexColorRegex.test(color)) {
      if (!this.showFallback) {
        this._hexColorString = null;
        return;
      }

      color = this.customizationService.primaryHexColor;
    }

    if (!color.startsWith('#')) color = '#' + color;

    this._hexColorString = color;
  }

  constructor(private customizationService: CustomizationService) {}

  ngOnInit() {
    if (this.showFallback && !this.hexColorString) this.color = this.customizationService.primaryHexColor;
  }

  public get hexColorString() {
    return this._hexColorString;
  }
}
