import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { using } from '@app/shared/utils/busy';
import { BusyScope } from '@app/shared/utils/busy/busy-scope';

export enum ChangePropertyType {
  date = 'date',
  select = 'select',
}

export interface IBulkChangeProperty<T> {
  label: string;
  required?: boolean;
  template?: TemplateRef<any>;
  triggerTemplate?: TemplateRef<any>;
  selectedValue?: T;
  update: (newValue: T) => Promise<boolean>;
}

abstract class BulkChangeProperty<T> implements IBulkChangeProperty<T> {
  protected _selectedValue: T;

  constructor(protected property: IBulkChangeProperty<T>) {}

  abstract get type(): ChangePropertyType;

  get label(): string {
    return this.property.label;
  }

  get required(): boolean {
    return !!this.property.required;
  }

  get template(): TemplateRef<any> {
    return this.property.template;
  }

  get triggerTemplate(): TemplateRef<any> {
    return this.property.triggerTemplate;
  }

  get selectedValue() {
    return this._selectedValue;
  }

  set selectedValue(value: T) {
    this._selectedValue = value;
  }

  async update(): Promise<boolean> {
    return !this.selectedValue ? true : await this.property.update(this.selectedValue);
  }
}

export class BulkChangeDateProperty extends BulkChangeProperty<Date> {
  constructor(protected property: IBulkChangeProperty<Date>) {
    super(property);
  }

  get type(): ChangePropertyType {
    return ChangePropertyType.date;
  }
}

export class BulkChangeSelectProperty<T> extends BulkChangeProperty<T> {
  constructor(protected property: IBulkChangeProperty<T>, public options: T[], private nameField: string = null) {
    super(property);
  }

  get type(): ChangePropertyType {
    return ChangePropertyType.select;
  }

  getLabel(value: T): string {
    return value && this.nameField ? value[this.nameField] : '';
  }
}

export type BulkChangeDialogProperty = BulkChangeDateProperty | BulkChangeSelectProperty<any>;

@Component({
  selector: 'app-bulk-change-dialog',
  templateUrl: './bulk-change-dialog.component.html',
  styleUrls: ['./bulk-change-dialog.component.scss'],
})
export class BulkChangeDialog {
  ChangePropertyType = ChangePropertyType;

  title: string;
  isBusy: boolean;
  properties: BulkChangeDialogProperty[];
  description: string;
  selectedProperty: BulkChangeDialogProperty;

  constructor(public confirmRef: MatDialogRef<BulkChangeDialog>, @Inject(MAT_DIALOG_DATA) data) {
    if (!data?.properties) {
      this.cancel();
      throw 'No properties provided for bulk change dialog';
    }

    this.title = data.title ?? 'general.bulkChange';
    this.description = data.description;
    this.properties = data.properties;
    this.selectedProperty = data.properties[0];
  }

  async ok() {
    const success = await using(new BusyScope(this), async _ => {
      return await this.selectedProperty.update();
    });

    if (success) this.confirmRef.close(true);
  }

  cancel() {
    this.confirmRef.close(false);
  }

  onDateChange(value: Date) {
    if (this.selectedProperty.type !== ChangePropertyType.date) throw 'Selected property cannot have a date value';
    const dateProperty = this.selectedProperty as BulkChangeDateProperty;
    dateProperty.selectedValue = value;
  }
}
