<ng-container *ngIf="Setting.cards.length>0">
  <div class="c4-link-section" [ngClass]="{'horizontal': Setting.horizontalDir}">
    <ng-container *ngFor="let item of Setting.cards">
      <div class="c4-link-box" [attr.data-width]="Setting[globals.viewport].itemWidth? Setting[globals.viewport].itemWidth:null"
        [ngClass]="item.cssClass? item.cssClass:''">
        <mat-card [style.backgroundImage]="item.image |translate" [style.paddingTop.%]="Setting[globals.viewport].itemRatio? Setting[globals.viewport].itemRatio:null" [ngClass]="{'c4-ratio': Setting[globals.viewport].itemRatio}" >

          <div class="c4-card-content-holder"  [ngClass]="{'c4-card-light-text': item.lightText}" [ngStyle]="item.style?item.style:null">
            <div class="c4-link-link" *ngIf="item.link.length === 0">
              <ng-template *ngTemplateOutlet="headerTemplate; context{item:item}"></ng-template>
            </div>
            <ng-container *ngIf="item.link.length > 0 && item.linkIsRouterLink">
                <a class="c4-link-link" [routerLink]="item.link  | translate" [queryParams]="item.queryParams" [target]="item.target? item.target : '_self'">
                    <ng-template *ngTemplateOutlet="headerTemplate; context{item:item}"></ng-template>
                    <div class="c4-link-icon c4-link-nav-icon">
                      <span [ngClass]="item.navIconClass  | translate"></span>
                    </div>
                  </a>
                  <a class="c4-full-link" *ngIf="item.fullLink" [routerLink]="item.link | translate" [queryParams]="item.queryParams" [target]="item.target? item.target : '_self'"></a>
            </ng-container>
            <ng-container *ngIf="item.link.length > 0 && !item.linkIsRouterLink">
                <a class="c4-link-link" [href]="item.link | translate" [target]="item.target? item.target : '_self'">
                    <ng-template *ngTemplateOutlet="headerTemplate; context{item:item}"></ng-template>
                    <div class="c4-link-icon c4-link-nav-icon">
                      <span [ngClass]="item.navIconClass |translate"></span>
                    </div>
                  </a>
                  <a class="c4-full-link" *ngIf="item.fullLink" [href]="item.link  | translate" [target]="item.target? item.target : '_self'"></a>
            </ng-container>
            <div class="c4-link-content" >
              <div [innerHTML]="item.body | translate | safeHtml"></div>
            </div>
          </div>

        </mat-card>
      </div>
    </ng-container>
  </div>
</ng-container>


<ng-template #headerTemplate let-item="item">
  <div *ngIf="item.iconClass" class="c4-link-icon">
    <span [ngClass]="item.iconClass | translate"></span>
  </div>
  <div class="c4-link-header-text" >
    <span class="c4-link-header c4-busy-content" >{{item.header | translate}}</span>
    <span *ngIf="item.subHeader" class="c4-link-subheader">{{item.subHeader | translate}}</span>
  </div>
</ng-template>
