import { CategoryModel, DriveItemModel, FileResponse } from '@app/api';

export interface RecentFiles {
  totalCount: number;
  files: DriveItemModel[];
}

export interface RecentEntry {
  icon: string;
  subject: string;
  from: string;
  date: Date;
}

export interface DriveItemsResult {
  addFileAllowed?: boolean;
  addFolderAllowed?: boolean;
  items: DriveItemModel[];
}

export interface NotificationStatusUpdate {
  id: string;
  deleted: boolean;
  confirmed: boolean;
}

export interface ExtendedCategoryModel extends CategoryModel {
  parent?: ExtendedCategoryModel;
}

export enum StructureType {
  flat,
  tree,
  treeWithParents,
}
