<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:true }"></ngx-loading>
<div class="page-part attributes">
	<app-header [label]="'attributes.title' | translate" [icon]="icon"></app-header>

	<app-header-bar>
		<div class="header-container">
			<div class="button-container">
				<button *ngIf="isInProject" mat-button (click)="addFromGlobal()"
								[matTooltip]="'general.actions.addFromGlobalTooltip' | translate">
					<span class="mdi mdi-database-export"></span>
					<span>{{ 'general.actions.addFromGlobal' | translate }}</span>
				</button>
				<button *ngIf="isInProject" mat-button (click)="fileInput.click()"
								[matTooltip]="'general.importFormatTooltip' | translate:{ format: 'CSV' }">
					<span class="mdi mdi-import"></span>
					<span>{{ 'general.importFormat' | translate:{ format: 'CSV' } }}</span>
				</button>
				<button *ngIf="isInProject" mat-button (click)="export()"
								[matTooltip]="'general.exportFormatTooltip' | translate:{ format: 'CSV' }">
					<span class="mdi mdi-export"></span>
					<span>{{ 'general.exportFormat' | translate:{ format: 'CSV' } }}</span>
				</button>

				<button mat-button (click)="addOrEditAttribute()" [matTooltip]="'attributes.addTooltip' | translate">
					<span class="mdi mdi-plus-box"></span>
					<span>{{ 'attributes.add' | translate }}</span>
				</button>
			</div>
		</div>
	</app-header-bar>

	<!-- if you want to reuse this, don't copy it but unify it with attribute categories into a component -->
	<!-- or at least unify the css (this file + categories) -->
	<div class="table-container c4-grid-container" [class]="attributesHolder?.busyClass">
		<div class="c4-p-table-container">
			<p-treeTable [value]="attributesHolder.data" [scrollable]="true" scrollHeight="flex">
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th>{{ 'attributes.fieldName.name' | translate }}</th>
						<th *ngIf="!globals.isMobile">{{ 'attributes.fieldName.description' | translate }}</th>
						<th *ngIf="!globals.isMobile">{{ 'attributes.fieldName.valueType' | translate }}</th>
						<th *ngIf="!globals.isMobile">{{ 'attributes.fieldName.defaultValue' | translate }}</th>
						<th class="actions-column"></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
					<tr>
						<td>
							<div class="toggler-container">
								<p-treeTableToggler *ngIf="!attributesHolder.busyClass" [rowNode]="rowNode"></p-treeTableToggler>
								<span class="attribute-name c4-busy-content" [matTooltip]="rowData?.name">
									<ng-container *ngIf="globals.isMobile && rowData?.number != null">{{ rowData?.number }} -
									</ng-container>
									{{ rowData?.name }}
								</span>
							</div>
						</td>
						<td *ngIf="!globals.isMobile">
							<span class="c4-busy-content">{{ rowData?.description }}</span>
						</td>
						<td *ngIf="!globals.isMobile">
							<div *ngIf="rowData?.valueType">
								{{ 'attributes.valueTypes.' + rowData?.valueType | translate }}
							</div>
						</td>
						<td *ngIf="!globals.isMobile">
							<span class="c4-busy-content">
								<div *ngIf="rowData?.valueType == attributeValueType.Checkbox; else plainValue">
									<mat-checkbox [checked]="rowData?.castedDefaultValue" disabled></mat-checkbox>
								</div>
								<ng-template #plainValue>
									<div>
										{{ rowData?.castedDefaultValue }}
									</div>
								</ng-template>
							</span>
						</td>
						<td class="no-side-padding">
							<div class="c4-grid-cell">
								<div *ngIf="rowData?.nodeType == attributeTreeNodeType.attribute" class="actions-column">
									<button type="button" mat-icon-button class="c4-grid-btn" aria-label="Edit"
													(click)="addOrEditAttribute(rowData)" [matTooltip]="'general.edit' | translate">
										<span class="mdi mdi-square-edit-outline"></span>
									</button>
									<button type="button" mat-icon-button class="c4-grid-btn" aria-label="Delete"
													(click)="deleteAttribute(rowData.id)" [matTooltip]="'general.delete' | translate">
										<span class="mdi mdi-trash-can-outline"></span>
									</button>
								</div>
							</div>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage" let-columns>
					<tr class="c4-grid-empty-warning c4-main-row">
						<td [attr.colspan]="5">
							<div>
								<span class="c4-grid-empty-ico mdi mdi-blur-off"></span>
								<span>
									{{ 'attributes.noDataText' | translate }}
								</span>
							</div>
						</td>
					</tr>
				</ng-template>
			</p-treeTable>
		</div>
	</div>

	<input #fileInput class="hide" type="file" accept=".csv" [multiple]="false" (change)="import($event.target.files[0])"
				 (click)="fileInput.value = null" />
</div>