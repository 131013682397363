import { TimeSpanModel } from '@app/api';
import * as moment from 'moment';

export class TimeSpanHelper {
  static getStartupDate() {
    return moment(new Date(1900, 0, 1, 0, 0, 0));
  }

  static getDateFromModel(ts: TimeSpanModel): moment.Moment {
    let d = this.getStartupDate();

    d.add(ts.days, 'days');
    d.add(ts.hours, 'hours');
    d.add(ts.minutes, 'minutes');
    d.add(ts.seconds, 'seconds');
    return d;
  }

  static getTimeSpanFromDate(date: Date): TimeSpanModel {
    return this.getTimeSpanFromMoment(moment(date));
  }

  static getTimeSpanFromMoment(date: moment.Moment): TimeSpanModel {
    let ts = new TimeSpanModel();
    const startupDate = this.getStartupDate();

    ts.days = date.diff(startupDate, 'days');
    ts.hours = date.diff(startupDate, 'hours') % 24;
    ts.minutes = date.diff(startupDate, 'minutes') % 60;
    ts.seconds = date.diff(startupDate, 'seconds') % 60;
    return ts;
  }

  static getTotalSecondsFromTimeSpan(td: TimeSpanModel) {
    return ((td.days * 24 /*h*/ + td.hours) * 60 /*min'*/ + td.minutes) * 60 /*s*/ + td.seconds;
  }

  static addToMoment(date: moment.Moment, ts: TimeSpanModel) {
    const result = moment(date);

    result.add(ts.days, 'days');
    result.add(ts.hours, 'hours');
    result.add(ts.minutes, 'minutes');
    result.add(ts.seconds, 'seconds');

    return result;
  }
}
