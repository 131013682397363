<div class="c4-dialog-wrapper progress-dialog">
	<h1 mat-dialog-title>{{ title | translate }}</h1>
	<span *ngIf="subTitle" class="sub-title">{{ subTitle }}</span>
	<mat-dialog-content>
		<div class="description" *ngIf='description'>{{ description | translate }}</div>
		<div class="step-list">
			<div class="step" *ngFor="let step of steps.items">
				<ng-template #stepTemplate let-currentState [ngTemplateOutlet]="stepTemplate" [ngTemplateOutletContext]="{
					$implicit: step.currentState$ | async
				}">
					<span class="state" [ngClass]="currentState">
						<span class="mdi" [ngClass]="getStateIcon(currentState)"></span>
					</span>
					<span class="title" [matTooltip]="step.title | translate">{{ step.title | translate }}</span>
					<div *ngIf="currentState != stepState.waiting && step.maxProgress > 0">
						<span>{{ step.currentProgress$ | async | number: '1.0-2' }}</span>
						<span> / {{ step.maxProgress }} {{ step.progressDescription | translate }}</span>
					</div>
					<div *ngIf="currentState != stepState.waiting" class="elapsedTime">
						<span *ngIf="step.elapsedMinutes; else elapsedSecondsTemplate">
							<span>&lt;</span>
							<span>{{ step.elapsedMinutes + 1 }}</span>
							<span>m</span>
						</span>
						<ng-template #elapsedSecondsTemplate>
							<span *ngIf="step.elapsedSeconds; else elapsedMsTemplate">
								<span>{{ step.elapsedSeconds + 1 }}</span>
								<span>s</span>
							</span>
						</ng-template>
						<ng-template #elapsedMsTemplate>
							<span>
								<span *ngIf="currentState != stepState.running">&lt;</span>
								<span>1s</span>
							</span>
						</ng-template>
					</div>
				</ng-template>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button *ngIf="canCancel || !hasStarted; else placeholderTemplate" mat-flat-button (click)="cancel()" type="button">
			{{ 'general.cancel' | translate }}
		</button>
		<ng-template #placeholderTemplate>
			<span>
				<!-- Needed for justify-content: space-between -->
			</span>
		</ng-template>

		<div class="proceed-buttons">

			<button *ngIf="hasAnyFailed && hasAnyWarnings" mat-flat-button color="primary" (click)="showErrors()"
							type="button">
				{{ 'general.showErrorsAndWarnings' | translate }}
			</button>
			<button *ngIf="hasAnyFailed && !hasAnyWarnings" mat-flat-button color="primary" (click)="showErrors()"
							type="button">
				{{ 'general.showErrors' | translate }}
			</button>
			<button *ngIf="!hasAnyFailed && hasAnyWarnings" mat-stroked-button (click)="showErrors()" type="button">
				{{ 'general.showWarnings' | translate }}
			</button>

			<button *ngIf="hasStarted; else startButton" mat-flat-button color="primary" (click)="ok()" type="button"
							[disabled]="!areAllSuccessful">
				{{ 'general.ok' | translate }}
			</button>
			<ng-template #startButton>
				<button mat-flat-button color="primary" (click)="start()" type="button">
					{{ 'general.start' | translate }}
				</button>
			</ng-template>
		</div>
	</mat-dialog-actions>
</div>