import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-icon-placeholder',
  template: '<ng-content></ng-content>',
  styleUrls: ['./icon-placeholder.component.scss'],
})
export class IconPlaceholderComponent {
  @HostBinding('style.font-size.rem') @Input() fontSizeRem = 10;
}
