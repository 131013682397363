import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filesize' })
export class FileSize implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: string, locale: string = 'en-US') {
    if (!value) return '';
    const fSize = parseInt(value);
    if (fSize < 1) return '';
    const sizes = ['Byte', 'KB', 'MB', 'GB', 'TB'];
    const base = 1024;
    const sI = Math.floor(Math.log(fSize) / Math.log(base));
    const localizedValue = this.decimalPipe.transform(fSize / Math.pow(base, sI), '1.0-2', locale);
    return `${localizedValue} ${sizes[sI]}`;
  }
}
