import { AbstractControl, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export class FormUtils {
  static getFormControl<T, R extends AbstractControl = UntypedFormControl>(form: UntypedFormGroup, formControlName: keyof T): R {
    return form.controls[formControlName as string] as R;
  }

  static getFormValue<T, R = any>(form: UntypedFormGroup, formControlName: keyof T): R {
    return form.controls[formControlName as string].value;
  }
}
