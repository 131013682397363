import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface EditGridColumn {
  field?: string;
  header?: string;
  hidden?: boolean;
}

@Component({
  selector: 'app-edit-grid-dialog',
  templateUrl: './edit-grid-dialog.component.html',
  styleUrls: ['./edit-grid-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditGridDialogComponent implements OnInit {
  public title: string;
  public columns: EditGridColumn[];

  constructor(public confirmRef: MatDialogRef<EditGridDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.title = data.title ? data.title : 'TITLE_NOT_SET';
    this.columns = data.columns ? data.columns : [];
  }

  ngOnInit(): void {}

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
  }

  ok() {
    this.confirmRef.close(this.columns);
  }

  cancel() {
    this.confirmRef.close(false);
  }
}
