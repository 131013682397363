import {
  NotificationType,
  NotificationModel,
  NotificationAttachmentModel,
  UserModel,
  NotificationSendType,
  ShareDriveModel,
  NotificationState,
  NotificationStateModel,
} from '@app/api';
import { AppRoutingData } from '@app/core';

const supportedResources = ['planning'];

export enum notifyIcon {
  Default = 'mdi-alert',
  Mulitple = 'mdi-file-multiple',
  Outline = 'mdi-information-outline',
}

export interface NotificationCardData {
  isSkeleton: boolean;
  id: string;
  icon: string;
  subject: string;
  translate: boolean;
  date: Date;
  routerLink: string;
  queryParams: any;
  highlight?: boolean;
  distinctResources?: string[];
  attachments: NotificationAttachmentModel[];
  sender?: UserModel;
  receiver?: string[];
  sendType: NotificationSendType;
  notificationState: NotificationState;
  notificationType?: NotificationType;
  notificationStateList?: NotificationStateModel[];
}

export class NotificationCardDataFactory {
  static buildFromNotification(
    notification: NotificationModel,
    highlightUnconfirmed: boolean,
    sendType: NotificationSendType
  ): NotificationCardData {
    const result: NotificationCardData = {
      isSkeleton: false,
      id: notification.id,
      icon: notifyIcon.Default,
      subject: notification.data,
      translate: false,
      date: notification.createdOn,
      routerLink: `/${AppRoutingData.notifications.path}/${sendType}/details`,
      queryParams: {
        id: notification.id,
        type: notification.notificationType,
      },
      highlight: highlightUnconfirmed && !notification.confirmed,
      distinctResources: notification.distinctResources,
      attachments: notification.notificationAttachmentList,
      sender: notification.senderUser,
      sendType: sendType,
      notificationState: notification.notificationState,
      notificationType: notification.notificationType,
      notificationStateList: notification.notificationStateList,
    };

    if (NotificationType.ExternalDriveChangeNotification === notification.notificationType) {
      result.icon = notifyIcon.Mulitple;
      result.subject = !notification.data
        ? 'general.notifications.externalDriveChangeNotification.infoText'
        : notification.data;
      result.translate = true;
    } else if (NotificationType.Html === notification.notificationType) {
      result.icon = notifyIcon.Outline;
      result.subject = notification.data;
      result.translate = true;
    } else if (NotificationType.Defect === notification.notificationType) {
      result.icon = AppRoutingData.defects.icon;
      result.subject = !notification.data ? 'general.notifications.defects.infoText' : notification.data;
      result.translate = true;
    } else if (NotificationType.Issue === notification.notificationType) {
      result.icon = AppRoutingData.bim.icon;
      result.subject = !notification.data ? 'general.notifications.issues.infoText' : notification.data;
      result.translate = true;
    }

    if (notification.distinctResources && notification.distinctResources.length > 0) {
      // TODO - icon logic for mulitple resources
      supportedResources.forEach(sR => {
        if (notification.distinctResources.indexOf(sR) > -1) {
          result.icon = AppRoutingData[sR]?.icon ? AppRoutingData[sR].icon : result.icon;
        }
      });
    }

    return result;
  }
}
