<div class="c4-dialog-wrapper add-tab-dialog">
	<h1 mat-dialog-title>{{ title | translate }}</h1>

	<form [formGroup]="form">
		<mat-dialog-content class="content">
			<mat-form-field>
				<mat-label>{{ 'projectConfig.structure.tab.displayName' | translate }}</mat-label>
				<input matInput formControlName="displayName">
				<mat-error *ngIf="f.displayName.invalid">
					{{ 'general.errorForms.' + getError('displayName') | translate }}
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<mat-label>{{ 'projectConfig.structure.tab.entityId' | translate }}</mat-label>
				<input matInput formControlName="entityId">
				<mat-error *ngIf="f.entityId.invalid">
					{{ 'general.errorForms.' + getError('entityId') | translate }}
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<mat-label>{{ 'projectConfig.structure.tab.appId' | translate }}</mat-label>
				<input matInput formControlName="appId">
				<mat-error *ngIf="f.appId.invalid">
					{{ 'general.errorForms.' + getError('appId') | translate }}
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<mat-label>{{ 'projectConfig.structure.tab.webSiteUrl' | translate }}</mat-label>
				<input matInput formControlName="webSiteUrl">
				<mat-error *ngIf="f.webSiteUrl.invalid">
					{{ 'general.errorForms.' + getError('webSiteUrl') | translate }}
				</mat-error>
			</mat-form-field>
			<mat-form-field>
				<mat-label>{{ 'projectConfig.structure.tab.contentUrl' | translate }}</mat-label>
				<input matInput formControlName="contentUrl">
				<mat-error *ngIf="f.contentUrl.invalid">
					{{ 'general.errorForms.' + getError('contentUrl') | translate }}
				</mat-error>
			</mat-form-field>
			<app-toggle-field formControlName="hideNavigation"
												[label]="'projectConfig.structure.tab.hideNavigation' | translate"
												[description]="'projectConfig.structure.tab.hideNavigationDescription' | translate">
			</app-toggle-field>
		</mat-dialog-content>

		<mat-dialog-actions>
			<button mat-flat-button type="button" (click)="cancel()">{{ 'general.cancel' | translate }}</button>
			<button mat-flat-button type="button" color="primary" [disabled]="form.invalid" (click)="confirm()">
				{{ 'general.ok' | translate }}
			</button>
		</mat-dialog-actions>
	</form>
</div>