import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyChip as MatChip } from '@angular/material/legacy-chips';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  AttributeModel,
  AttributeValueType,
  CategoryModel,
  CommentModel,
  ControlType,
  RoomModel,
  UserSessionModel,
  ZoneGroupModel,
} from '@app/api';
import { ApiService, BaseSubscriptionComponent, GlobalsService, StructureType } from '@app/core';
import { IFormCategory } from '@app/shared/pipes/form-category-contains.pipe';
import { ICommentService, UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { debounceTime } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../../../dialogs/confirm-dialog/confirm-dialog.component';
import { RoomBookReportService } from '../../room-book-report.service';
import {
  AttributeTreeNode,
  AttributeTreeNodeType,
  ExtendedAttributeModel,
  buildAttributesTreeRec,
} from '@app/shared/components/attributes';
import {
  SelectionMode,
  TreeSelectDialogComponent,
} from '@app/shared/components/dialogs/tree-select-dialog/tree-select-dialog.component';
import { MatTooltip } from '@angular/material/tooltip';

interface EditableComment extends CommentModel {
  isEdit: boolean;
  oldText: string;
}

@Component({
  selector: 'app-room-edit-detail',
  templateUrl: './room-edit-detail.component.html',
  styleUrls: ['./room-edit-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoomEditDetailComponent extends BaseSubscriptionComponent implements OnInit, OnDestroy, Busy {
  @Input() user: UserSessionModel;
  @Input() room: RoomModel;
  @Input() implicitZoneIds: string[] = [];
  @Input() zoneGroups: ZoneGroupModel[] = [];
  @Input() form: UntypedFormGroup;
  @Input() isInProject: boolean;
  @Input() isTemplate: boolean;
  @Input() commentsService: ICommentService;

  @Output() attributeAdded = new EventEmitter<AttributeModel>();
  public fieldError?: FieldError;
  isBusy: boolean;
  filteredCategories: IFormCategory[];
  controlTypes = ControlType;
  attributeValueType = AttributeValueType;

  private allCategories: IFormCategory[];

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private userNotification: UserNotificationService,
    private roomBookReport: RoomBookReportService,
    public globals: GlobalsService
  ) {
    super();

    this.allCategories = [];
    this.filteredCategories = [];
  }

  get f() {
    return this.form.controls;
  }

  get categories(): UntypedFormArray {
    return this.form.controls.categories as UntypedFormArray;
  }

  ngOnInit() {
    this.subscribe(this.form.valueChanges.pipe(debounceTime(300)), form => {
      if (this.form.invalid) {
        this.fieldError = this.getFirstAttributeError();
      } else {
        this.fieldError = null;
      }
      const oldCategories = [...this.allCategories];
      this.flatList(this.categories, oldCategories, []);

      for (const category of oldCategories) {
        this.allCategories.remove(category);
        this.filteredCategories.remove(category);
      }

      this.filteredCategories = [...this.filteredCategories];
    });
  }

  async createReport() {
    const categoryIds: string[] = this.filteredCategories.map(x => {
      const data = x.target;

      return data.controls.categoryId.value;
    });

    if (categoryIds.length > 0 && categoryIds.length < this.allCategories.length) {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'roomBook.dialogs.dynamic-reports.caption',
          description: 'roomBook.dialogs.dynamic-reports.description',
        },
      });
      const dialogResult: boolean = await dialogRef.afterClosed().toPromise();
      if (!dialogResult) {
        categoryIds.splice(0);
      }
    }

    this.roomBookReport.createReport({
      roomsIds: [this.room.id],
      categoryIds: categoryIds,
    });
  }

  flatList(container: UntypedFormArray, oldCategories: IFormCategory[], oldNavigation: UntypedFormGroup[]) {
    for (const category of container.controls as UntypedFormGroup[]) {
      const index = oldCategories.findIndex(item => item.target == category);
      const groups = [...oldNavigation, category];

      if (index < 0) {
        const item: IFormCategory = {
          groups: groups,
          target: category,
          name: category.controls.categoryLabel.value,
        };
        this.allCategories.push(item);

        if (oldCategories.length > 0) {
          this.filteredCategories.push(item);
        }
      } else {
        oldCategories.splice(index, 1);
      }

      this.flatList(category.controls.categories as UntypedFormArray, oldCategories, groups);
    }
  }

  ngOnDestroy() {
    this.room?.comments?.forEach((c: EditableComment) => (c.isEdit = false));
    return super.ngOnDestroy();
  }

  chipClick(chip: MatChip) {
    const category: IFormCategory = chip.value;
    const others = this.allCategories.filter(
      x => this.filteredCategories.contains(x) == chip.selected && x.groups.contains(category.target)
    );

    if (!chip.selected) {
      this.filteredCategories.push(...others);
    } else {
      for (const other of others) {
        this.filteredCategories.remove(other);
      }
    }

    this.filteredCategories = [...this.filteredCategories]; // trigger change detection
  }

  updateFieldType(value: ControlType, attribute: UntypedFormGroup) {
    attribute.controls.value.clearValidators();
    const typeValues: string[] = Object.keys(ControlType).map(key => ControlType[key]); // get values of enum
    const typeLength: number = typeValues.length; // get length of enum
    const index: number = (Object.keys(ControlType).indexOf(value) + 1) % typeLength; // get index of next value
    const nextValue: string = typeValues[index] as ControlType; // get next value
    attribute.controls.type.patchValue(nextValue);
    if (nextValue === ControlType.Readonly) {
      attribute.controls.value.setValidators([Validators.required]);
    }
    attribute.controls.value.updateValueAndValidity();
  }

  getFirstAttributeError(categories: UntypedFormArray = this.categories): FieldError {
    if (!categories?.invalid) return null;

    for (const category of categories.controls as UntypedFormGroup[]) {
      const attributes = category.controls.attributes as UntypedFormArray;
      if (attributes.invalid) {
        for (const attribute of attributes.controls as UntypedFormGroup[]) {
          if (attribute.invalid) {
            const errorKey = Object.keys(attribute.controls).find(k => attribute.controls[k].errors);
            const fieldName = attribute.controls.name.value ?? 'field';
            if (errorKey) {
              return {
                fieldName: fieldName,
                error: Object.keys(attribute.controls[errorKey].errors)[0],
              };
            }
          }
        }
      } else {
        return this.getFirstAttributeError(category.controls.categories as UntypedFormArray);
      }
    }

    return null;
  }

  async addAttribute() {
    let attributes: AttributeModel[], categories: CategoryModel[];
    await using(new BusyScope(this), async _ => {
      categories = await this.apiService.getCategories(StructureType.tree);
      attributes = await this.apiService.getAttributes();
    }).catch(error => {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.attributes', error);
    });

    if (!attributes) return;

    const tree = buildAttributesTreeRec(categories, attributes);

    const data = {
      title: 'roomBook.selectGlobal.attributeTitle',
      description: 'roomBook.selectGlobal.attributeDescription',
      selectionMode: SelectionMode.checkbox,
      canCancel: true,
      items: tree,
    };

    const dialogResult: AttributeTreeNode[] = await this.dialog
      .open(TreeSelectDialogComponent, {
        data: data,
        disableClose: true,
      })
      .afterClosed()
      .toPromise();

    const selectedAttributes = dialogResult.map(node => node.data).filter(a => a.nodeType == AttributeTreeNodeType.attribute);

    for (const a of selectedAttributes as ExtendedAttributeModel[]) {
      this.attributeAdded.emit(a);
    }
  }

  drop(array: UntypedFormArray, event: CdkDragDrop<any>, tooltip: MatTooltip) {
    let current = event.event.target as HTMLElement;
    const currentIndex = !current.dataset?.index ? current.parentElement?.dataset?.index : current.dataset?.index;
    if (!currentIndex) return;
    this.moveAttribute(array, event.previousIndex, ~~currentIndex);
    tooltip.hide();
  }

  moveAttribute(formArray: UntypedFormArray, previousIndex: number, currentIndex: number) {
    moveItemInArray(formArray.controls, previousIndex, currentIndex);
    this.enumeratePositions(formArray);
    this.form.markAsDirty();
  }

  deleteAttribute(formArray: UntypedFormArray, index: number) {
    formArray.removeAt(index);
    this.enumeratePositions(formArray);
    this.form.markAsDirty();
  }

  private enumeratePositions(formArray: UntypedFormArray) {
    for (let i = 0; i < formArray.controls.length; i++) {
      const group = formArray.controls[i] as UntypedFormGroup;
      group.controls.sequence.patchValue(i + 1);
    }
  }

  getAttributeDisplayFunction(definition: UntypedFormControl) {
    return (key: string) => definition.value[key];
  }
}
