import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BaseSubscriptionComponent } from '@app/core';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-base-layout',
  templateUrl: './base-layout.component.html',
  styleUrls: ['./base-layout.component.scss'],
})
export class BaseLayoutComponent extends BaseSubscriptionComponent implements OnInit {
  @ViewChild('pageWrapper', { static: true }) pageWrapper: ElementRef<HTMLDivElement>;

  constructor(private router: Router) {
    super();
  }

  ngOnInit() {
    this.subscribe(this.router.events.pipe(filter(event => event instanceof NavigationEnd)), _ => {
      this.pageWrapper.nativeElement.scrollTo(0, 0);
    });
  }
}
