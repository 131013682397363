<div class="selection-tree-container">
	<button *ngIf="supportsAddNode" mat-button class="full-size-button" (click)="addRootNode()">
		<span>{{ addNodeText | translate }}</span>
		<span class="mdi mdi-plus-box-outline"></span>
	</button>
	<div class="filter" [class.contrast]="supportsAddNode">
		<input [placeholder]="'general.actions.filter' | translate" (input)="filterTreeEvent($event)">
	</div>
	<div class="selection-tree">
		<div *ngFor="let node of nodes">
			<ng-template [ngTemplateOutlet]="roomTree" [ngTemplateOutletContext]="{
					parent: node,
					indent: 1
			}"></ng-template>
		</div>
	</div>
</div>

<ng-template #roomTree let-parent="parent" let-indent="indent">
	<div matRipple [id]="parent.selected ? 'selected': ''" [class.hidden]="parent.hidden || parent.filtered"
			 [class.selected]="parent.selected" [style.padding-left]="indent + 'em'" (click)="selectNode(parent)">
		<div class="arrow" (click)="toggleNode(parent, $event)">
			<span *ngIf="parent.children?.length" class="mdi"
						[ngClass]="parent.collapsed ? 'mdi-chevron-right' : 'mdi-chevron-down'"></span>
		</div>
		<div class="node-display">
			<span *ngIf="!parent.template; else templateContainer" class="node-name">{{ parent.label }}</span>
			<ng-template #templateContainer [ngTemplateOutlet]="parent.template" [ngTemplateOutletContext]="{
				node: parent
			}"></ng-template>
		</div>
		<button class="add-button" *ngIf="parent.supportsAddChildren" mat-icon-button (click)="addNode(parent, $event)">
			<span class="mdi mdi-plus-box-outline"></span>
		</button>
	</div>
	<ng-container *ngIf="parent.children?.length">
		<div *ngFor="let child of parent.children">
			<ng-template [ngTemplateOutlet]="roomTree" [ngTemplateOutletContext]="{
				parent: child,
				indent: indent + 1
			}"></ng-template>
		</div>
	</ng-container>
</ng-template>