<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: true }"></ngx-loading>
<ng-container *ngIf="fileURL$ | async as fileURL">
	<div *ngIf="fileURL.draw; else fallback" class="container" [formGroup]="form">
		<app-geojson-editor #editor [markers]="markers$ | async" (markersChange)="fireMarkersChange($event)"
												[drawableContext]="{}">
			<app-geojson-editor-img-layer [url]="fileURL.imageUrl" (load)="complete()" (error)="complete()">
			</app-geojson-editor-img-layer>
		</app-geojson-editor>
	</div>
	<div class="toolbar">
		<div class="filler"></div>
		<div class="content">
			<button mat-raised-button (click)="save()" [disabled]="this.form.pristine">{{ 'general.save'
				| translate
				}}</button>
			<button mat-raised-button (click)="reset()" [disabled]="this.form.pristine">{{ 'general.cancel' | translate
				}}</button>
			<button mat-raised-button (click)="close()">{{ 'general.close' | translate }}</button>
		</div>
	</div>
	<div class="arrows">
		<div class="arrow arrow-l" *ngIf="config.events.onprev" (click)="prev()">
			<button mat-fab color="primary" [matTooltip]="'dialogs.previewImage.prev' | translate">
				<span class="mdi mdi-arrow-left"></span>
			</button>
		</div>
		<div class="arrow arrow-r" *ngIf="config.events.onnext" (click)="next()">
			<button mat-fab color="primary" [matTooltip]="'dialogs.previewImage.next' | translate">
				<span class="mdi mdi-arrow-right"></span>
			</button>
		</div>
	</div>
	<ng-template #fallback>
		<div class="container" (click)="close()">
			<img [src]="fileURL.imageUrl" (load)="complete()" (error)="complete()">
		</div>
	</ng-template>
</ng-container>