<div class="c4-signpad" [class.c4-busy]="loadingBusy.isBusy">
  <div class="signature-pad">
    <signature-pad #signature [options]="signaturePadOptions" (drawEnd)="drawComplete()"></signature-pad>
    <span *ngIf="!isEmpty" class="mdi mdi-trash-can-outline" (click)="clear()"
          [matTooltip]="'drawToSign.clear' | translate"></span>
  </div>
  <form [formGroup]="form">
    <mat-form-field class="c4-busy-content">
      <input matInput type="text" #firstnameInput formControlName="firstnameInput"
             placeholder="{{ 'drawToSign.firstname' | translate}}">
    </mat-form-field>
    <mat-form-field class="c4-busy-content">
      <input matInput type="text" #lastnameInput formControlName="lastnameInput"
             placeholder="{{ 'drawToSign.lastname' | translate}}">
    </mat-form-field>
  </form>
</div>
<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:true }"></ngx-loading>