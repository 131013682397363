<ngx-loading [show]="mainLoader.isBusy"></ngx-loading>
<div class="sequence-editor">
	<div class="scheduler" [class.c4-busy]="mainLoader.isBusy">
		<bryntum-scheduler-pro #scheduler [config]="config" [presets]="presets"
													 [viewPreset]="presets[0]"></bryntum-scheduler-pro>
	</div>
	<div class="work-package-toolbar-panel" [class.visible]="isWorkpackageToolbarActive && !isDragging"
			 [class.c4-busy]="templatesLoader.isBusy">
		<app-workpackage-template-pane [templates]="workpackageTemplates" (close)="isWorkpackageToolbarActive = false"
																	 [showSequences]="false" [phases]="workpackagePhases"></app-workpackage-template-pane>
	</div>
</div>