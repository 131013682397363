import { UntypedFormBuilder, FormGroup, UntypedFormArray, UntypedFormGroup, FormArray } from '@angular/forms';
import {
  IProjectConfigTeamRole,
  ProjectConfigPermissionDescription,
  ProjectConfigStateBinding,
  ProjectConfigStateRoleType,
} from '@app/api';
import { EditStateDialogComponent } from './edit-state-dialog/edit-state-dialog.component';
import { BaseSubscriptionComponent, FormUtils, Utils } from '@app/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { CheckboxState } from '../ternary-checkbox/ternary-checkbox.component';
import { ConfirmDialogComponent } from '../dialogs';

export interface StateRolePermission {
  scope: string;
  isSet: CheckboxState;
}

export interface StateRole<T = string> {
  key: T;
  alias: string;
  followingStateIds: string[];
  permissions: StateRolePermission[];
}

export interface ConfigState<TState> {
  key: string;
  stateType: TState;
  title: string;
  code: string;
  color: string;
  roles: StateRole<ProjectConfigStateRoleType | string>[];
}

interface MergedStateSchemaRole {
  key: string;
  name: string;
  code: string;
  isPredefined: boolean;
}

export interface ProjectConfigState<T> {
  key?: string;
  code?: string;
  title?: string;
  color?: string;
  stateType?: T;
  roleBindings?: ProjectConfigStateBinding[];
}

export type StateSchemaRole = IProjectConfigTeamRole | ProjectConfigStateRoleType;

export class StateSchemaService<TState> extends BaseSubscriptionComponent {
  private _gridTemplateColumnsStyle: string;
  private _states: UntypedFormArray;
  private _stateData: Record<string, string>;
  private _roles: MergedStateSchemaRole[] = [];
  private _permissions: ProjectConfigPermissionDescription[] = [];
  private _stateTypes: TState[] = [];

  constructor(
    states: UntypedFormArray,
    stateTypes: TState[],
    roles: StateSchemaRole[],
    permissions: ProjectConfigPermissionDescription[],
    private translationPrefix: string,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {
    super();

    this._states = states;
    this._stateTypes = stateTypes ?? [];
    this._permissions = permissions ?? [];

    this.setRoles(roles);

    this.subscribe(states.valueChanges, states => {
      this.setStatesData(states);
    });

    this.setStatesData(states.value);
  }

  get gridTemplateColumnsStyle(): string {
    return this._gridTemplateColumnsStyle;
  }

  get rolesCount(): number {
    return this._roles.length;
  }

  get roles(): MergedStateSchemaRole[] {
    return this._roles;
  }

  get states(): UntypedFormGroup[] {
    const states = this._states?.controls ?? [];
    return states as UntypedFormGroup[];
  }

  get stateValues(): ConfigState<TState>[] {
    return this._states ? this._states.getRawValue() : [];
  }

  get stateData(): Record<string, string> {
    return this._stateData;
  }

  get permissions(): ProjectConfigPermissionDescription[] {
    return this._permissions;
  }

  set permissions(value: ProjectConfigPermissionDescription[]) {
    this._permissions = value ?? [];
  }

  get hasStates(): boolean {
    return !!this._states?.controls?.length;
  }

  setRoles(roles: StateSchemaRole[]) {
    this._roles = roles?.map(r => StateSchemaService.getStateSchemaRole(r, this.translateService)) ?? [];
    this.updateGridStyle();
  }

  async addOrEditState(stateGroup: UntypedFormGroup = null) {
    const originalState = stateGroup?.getRawValue();
    const updatedState: ConfigState<TState> = await this.dialog
      .open(EditStateDialogComponent<TState>, {
        data: {
          state: originalState,
          stateTypes: this._stateTypes,
          translationPrefix: this.translationPrefix,
        },
        disableClose: true,
      })
      .afterClosed()
      .toPromise();

    if (updatedState != null) {
      if (stateGroup != null) {
        FormUtils.getFormControl<ConfigState<TState>>(stateGroup, 'title').setValue(updatedState.title);
        FormUtils.getFormControl<ConfigState<TState>>(stateGroup, 'code').setValue(updatedState.code);
        FormUtils.getFormControl<ConfigState<TState>>(stateGroup, 'stateType').setValue(updatedState.stateType);
        FormUtils.getFormControl<ConfigState<TState>>(stateGroup, 'color').setValue(updatedState.color);
        stateGroup.markAsDirty();
      } else {
        const statesArray = this._states;

        const newStateGroup = StateSchemaService.getStateGroup<TState>(
          { ...updatedState, key: Utils.createUUID(), roleBindings: [] },
          this.roles,
          this.permissions
        );

        // Scaffold first state for author
        if (updatedState.stateType === this._stateTypes[0]) {
          this.scaffoldAuthorRole(newStateGroup);
        }

        const index = statesArray.controls.findIndex(
          (sg: UntypedFormGroup) => FormUtils.getFormValue<ConfigState<TState>>(sg, 'code') > updatedState.code
        );

        if (index < 0) statesArray.push(newStateGroup);
        else statesArray.insert(index, newStateGroup);

        statesArray.markAsDirty();
      }
    }
  }

  private scaffoldAuthorRole(newStateGroup: UntypedFormGroup) {
    const rolesArray = FormUtils.getFormControl<ConfigState<TState>, UntypedFormArray>(newStateGroup, 'roles');
    const authorRole = rolesArray.controls.find(
      (g: UntypedFormGroup) => FormUtils.getFormValue(g, 'key') === 'Author'
    ) as FormGroup;

    if (Utils.isNullOrUndefined(authorRole)) return;

    const authorPermissions = authorRole.controls.permissions as FormArray;
    authorPermissions.controls.forEach((c: UntypedFormGroup) => {
      const isSetControl = FormUtils.getFormControl(c, 'isSet');
      isSetControl.setValue(CheckboxState.checked);
    });
  }

  async removeState(stateGroup: UntypedFormGroup) {
    const formIndex = this.states.indexOf(stateGroup);
    if (formIndex < 0) throw 'State not found!';

    const name = FormUtils.getFormValue<ConfigState<TState>>(stateGroup, 'title');
    const hasConfirmed = await this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'general.confirmation.deleteCaption',
          description: 'general.confirmation.deleteDescription',
          params: {
            element: await this.translateService.get('states.title').toPromise(),
            name,
          },
        },
      })
      .afterClosed()
      .toPromise();

    if (!hasConfirmed) return;

    const key = FormUtils.getFormValue<ConfigState<TState>>(stateGroup, 'key');
    for (const stateGroup of this._states.controls as FormGroup[]) {
      const rolesControl = FormUtils.getFormControl<ConfigState<TState>, UntypedFormArray>(stateGroup, 'roles');

      for (const roleGroup of rolesControl.controls as FormGroup[]) {
        const followingStateIdsControl = FormUtils.getFormControl<StateRole<ProjectConfigStateRoleType>>(
          roleGroup,
          'followingStateIds'
        );
        const followingStateIds: string[] = followingStateIdsControl.value;
        const index = followingStateIds.indexOf(key);

        if (index >= 0) {
          followingStateIds.splice(index, 1);
          followingStateIdsControl.setValue(followingStateIds);
        }
      }
    }

    this._states.removeAt(formIndex);
    this._states.markAsDirty();
  }

  private updateGridStyle() {
    this._gridTemplateColumnsStyle = `24rem repeat(${this.rolesCount}, 1fr) auto`;
  }

  private setStatesData(states: ConfigState<TState>[]) {
    this._stateData = {};
    for (const state of states ?? []) {
      this.stateData[state.key] = state.title;
    }
  }

  static getStateGroup<TState>(
    state: ProjectConfigState<TState>,
    roles: MergedStateSchemaRole[],
    permissions: ProjectConfigPermissionDescription[]
  ) {
    const formBuilder = new UntypedFormBuilder();
    const stateConfig: DictWithKeysOf<ConfigState<TState>> = {
      key: [state.key],
      stateType: [state.stateType],
      title: [state.title],
      code: [state.code],
      color: [state.color],
      roles: formBuilder.array(
        roles.map(role =>
          this.getStateRoleGroup(
            role.key,
            permissions,
            state.roleBindings.find(binding => (role.isPredefined ? binding.roleType == role.key : binding.roleId == role.key))
          )
        )
      ),
    };

    return formBuilder.group(stateConfig);
  }

  static getStateRoleGroup(
    roleKey: string | ProjectConfigStateRoleType,
    permissions: ProjectConfigPermissionDescription[],
    roleBinding: ProjectConfigStateBinding = null
  ) {
    const formBuilder = new UntypedFormBuilder();
    const alias = roleBinding?.alias ?? '';
    const rolePermissions = roleBinding?.permissions ?? [];
    const followingStateIds = roleBinding?.allowedStates ?? [];

    const setPermissions: string[] = [];
    const permissionsForm: UntypedFormArray = formBuilder.array(
      permissions.map(permission => {
        const permissionConfig: DictWithKeysOf<StateRolePermission> = {
          scope: [permission.scope],
          isSet: [CheckboxState.unchecked],
        };

        if (rolePermissions.some(statePermission => statePermission === permission.scope))
          setPermissions.push(permission.scope);

        const permissionForm = formBuilder.group(permissionConfig);

        FormUtils.getFormControl<StateRolePermission>(permissionForm, 'isSet').valueChanges.subscribe(
          (isSet: CheckboxState) => {
            let parentPermissionScopes: string[] = [];
            let parentDependentPermissionScopes: string[] = [];
            let dependentCheckboxesState = CheckboxState.unchecked;
            if (isSet == CheckboxState.checked) {
              dependentCheckboxesState = CheckboxState.indeterminate;

              const parentPermissions = permissions.filter(p => p.includes.includes(permission.scope));
              parentPermissionScopes = parentPermissions.map(p => p.scope);
              const parentDependentPermissions = parentPermissions.flatMap(p => p.includes);
              parentDependentPermissionScopes = permissions
                .filter(p => p.scope != permission.scope && parentDependentPermissions.includes(p.scope))
                .map(p => p.scope);
              // for (const permissionToUncheck of parentPermissions.concat(siblingPermissions)) {
              //   if (permissionToUncheck.scope != permission.scope) permissionsToUncheck.push(permissionToUncheck.scope);
              // }
            }

            const permissionsFormValues: StateRolePermission[] = permissionsForm.value;
            for (const permissionFormValue of permissionsFormValues) {
              if (permissionFormValue.scope == permission.scope) {
                // current permission
                permissionFormValue.isSet = isSet;
              } else if (permission.includes.includes(permissionFormValue.scope)) {
                // dependent permissions
                let state = dependentCheckboxesState;
                if (state == CheckboxState.unchecked) {
                  const isDependentOnOther = permissions
                    .filter(p => p.scope != permission.scope && p.includes.includes(permissionFormValue.scope))
                    .some(otherDependentPermission => {
                      const otherDependentPermissionState = permissionsFormValues.find(
                        pfv => pfv.scope == otherDependentPermission.scope
                      ).isSet;

                      return otherDependentPermissionState == CheckboxState.checked;
                    });

                  if (isDependentOnOther) state = CheckboxState.indeterminate;
                }

                permissionFormValue.isSet = state;
              } else if (parentPermissionScopes.includes(permissionFormValue.scope)) {
                // parent permissions
                permissionFormValue.isSet = CheckboxState.unchecked;
              } else if (
                parentDependentPermissionScopes.includes(permissionFormValue.scope) &&
                permissionFormValue.isSet == CheckboxState.indeterminate
              ) {
                // sibling permissions
                permissionFormValue.isSet = CheckboxState.unchecked;
              }
            }

            permissionsForm.setValue(permissionsFormValues, { emitEvent: false });
          }
        );

        return permissionForm;
      })
    );

    // set checked privileges to trigger indeterminate for dependent
    for (const scope of setPermissions) {
      const permissionForm = permissionsForm.controls.find(
        (c: UntypedFormGroup) => FormUtils.getFormValue<StateRolePermission>(c, 'scope') == scope
      ) as UntypedFormGroup;

      FormUtils.getFormControl<StateRolePermission>(permissionForm, 'isSet').setValue(CheckboxState.checked);
    }

    const roleConfig: DictWithKeysOf<StateRole> = {
      key: [{ value: roleKey, disabled: true }],
      alias: [alias, { updateOn: 'blur' }],
      followingStateIds: [followingStateIds],
      permissions: permissionsForm,
    };

    const roleGroup = formBuilder.group(roleConfig);

    roleGroup.controls.alias.valueChanges.subscribe((value: string) => {
      roleGroup.controls.alias.setValue(value?.trim(), { emitEvent: false });
    });

    return roleGroup;
  }

  static getMergedStateSchemaRoles(roles: StateSchemaRole[], translateService: TranslateService): MergedStateSchemaRole[] {
    return roles?.map(r => StateSchemaService.getStateSchemaRole(r, translateService)) ?? [];
  }

  static parseStates<TState>(states: UntypedFormArray): ProjectConfigState<TState>[] {
    const parsed = states.controls.map((state: UntypedFormGroup) => {
      const roleControls = FormUtils.getFormControl<ConfigState<TState>, UntypedFormArray>(state, 'roles').controls;
      const parsedState: ProjectConfigState<TState> = {
        key: FormUtils.getFormValue<ConfigState<TState>>(state, 'key'),
        code: FormUtils.getFormValue<ConfigState<TState>>(state, 'code'),
        title: FormUtils.getFormValue<ConfigState<TState>>(state, 'title'),
        color: FormUtils.getFormValue<ConfigState<TState>>(state, 'color'),
        stateType: FormUtils.getFormValue<ConfigState<TState>>(state, 'stateType'),
        roleBindings: roleControls.map((role: UntypedFormGroup) => {
          const roleKey = FormUtils.getFormValue<StateRole<ProjectConfigStateRoleType>>(role, 'key');
          const isPredefined = !!ProjectConfigStateRoleType[roleKey];

          return new ProjectConfigStateBinding({
            roleType: isPredefined ? roleKey : ProjectConfigStateRoleType.InternalTeamRole,
            roleId: isPredefined ? null : roleKey,
            alias: FormUtils.getFormValue<StateRole<ProjectConfigStateRoleType>>(role, 'alias'),
            allowedStates: FormUtils.getFormValue<StateRole<ProjectConfigStateRoleType>>(role, 'followingStateIds'),
            permissions: FormUtils.getFormValue<StateRole<ProjectConfigStateRoleType>, StateRolePermission[]>(
              role,
              'permissions'
            )
              .filter(permission => permission.isSet == CheckboxState.checked)
              .map(permission => permission.scope),
          });
        }),
      };

      return parsedState;
    });

    return parsed;
  }

  private static getStateSchemaRole(role: StateSchemaRole, translateService: TranslateService): MergedStateSchemaRole {
    const isPredefined = typeof role === 'string';

    return {
      key: isPredefined ? role : role.id,
      name: isPredefined ? translateService.instant(`states.predefinedRoles.${role}`) : role.name,
      code: isPredefined ? role : role.description,
      isPredefined,
    };
  }
}
