import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-toggle-field',
  templateUrl: './toggle-field.component.html',
  styleUrls: ['./toggle-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ToggleFieldComponent,
    },
  ],
})
export class ToggleFieldComponent implements ControlValueAccessor {
  @Input() icon: string;
  @Input() iconUrl: string;
  @Input() classes: string;
  @Input() label: string;
  @Input() description: string;

  isChecked: boolean;
  isDisabled: boolean = false;

  onChange = (_: boolean) => {};
  private onTouched = () => {};
  private isTouched: boolean = false;

  constructor() {}

  toggleChanged(isChecked: boolean) {
    this.isChecked = isChecked;
    this.onChange(isChecked);
  }

  // ----- Reactive Forms Methods -----

  writeValue(isChecked: boolean): void {
    this.isChecked = isChecked;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
  }
}
