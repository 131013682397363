import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppConfigService, BaseSubscriptionComponent, FormUtils, GlobalsService } from '@app/core';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { EditRoleDialogComponent } from './edit-role-dialog/edit-role-dialog.component';
import { PcfDirectory, ProjectConfigService, PcfRole, PcfRoles, PcfStructure } from '@app/shared/services/project-config';
import { IProjectConfigTeamRole } from '@app/api';
import { IndividualPrivilege } from '../../privilege-matrix/individual-privilege.service';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';

enum RoleSubType {
  role = 'role',
  user = 'user',
}

interface RoleSubEntity extends Entity {
  description: string;
  type: RoleSubType;
  filterValue?: string;
}

@Component({
  selector: 'app-roles-config',
  templateUrl: './roles-config.component.html',
  styleUrls: ['./roles-config.component.scss'],
})
export class RolesConfigComponent extends BaseSubscriptionComponent implements OnInit {
  RoleSubType = RoleSubType;
  roleUsedInStructure: Record<string, boolean>;
  systemRoles: UntypedFormGroup[] = [];
  basicRoles: UntypedFormGroup[] = [];
  everyoneId = AppConfigService.settings.defaultRoleIds.everyone;

  filteredAutoValues: RoleSubEntity[];
  private autoValues: RoleSubEntity[];

  constructor(
    private configService: ProjectConfigService,
    private dialog: MatDialog,
    private translate: TranslateService,
    public globals: GlobalsService
  ) {
    super();
  }

  get roleAndUserData() {
    return this.configService.roleAndUserData;
  }

  get allRoles() {
    const rolesArray = FormUtils.getFormControl<PcfRoles, UntypedFormArray>(this.configService.rolesForm, 'roles');
    return rolesArray.controls as UntypedFormGroup[];
  }

  get projectUsers() {
    return this.configService.projectUsers;
  }

  ngOnInit() {
    this.subscribe(this.configService.initialized$, isInitialized => {
      if (!isInitialized) return;

      this.checkStructure();
      this.buildRoleGroups();
    });
  }

  closeBeforeBuild(event: FocusEvent, trigger: MatAutocompleteTrigger) {
    let tagName: string = null;
    if (event.relatedTarget instanceof Element) tagName = event.relatedTarget?.tagName;
    if (tagName?.toLowerCase() != 'mat-option') trigger.closePanel();
  }

  buildAutocomplete(role: UntypedFormGroup, currentFilter: string = null) {
    const id = FormUtils.getFormValue<PcfRole>(role, 'id');
    const subRolesControls = FormUtils.getFormControl<PcfRole, UntypedFormArray>(role, 'roles');
    const existingRoleIds: string[] = subRolesControls.value;
    const subUsersControls = FormUtils.getFormControl<PcfRole, UntypedFormArray>(role, 'users');
    const existingUserIds: string[] = subUsersControls.value;

    const autoValues: RoleSubEntity[] = [];
    if (!subRolesControls.disabled) {
      const roles: RoleSubEntity[] =
        existingUserIds.length > 0 || this.roleUsedInStructure[id]
          ? []
          : this.allRoles
              .filter(r => {
                const currentId = FormUtils.getFormValue<PcfRole>(r, 'id');
                const currentRoleIds = FormUtils.getFormValue<PcfRole>(r, 'roles') ?? [];

                return currentId != id && currentRoleIds.length == 0 && !existingRoleIds.includes(currentId);
              })
              .map(r => {
                const id = FormUtils.getFormValue<PcfRole>(r, 'id');

                return {
                  id,
                  name: this.roleAndUserData[id]?.name,
                  description: this.translate.instant('general.userRole'),
                  type: RoleSubType.role,
                };
              });

      autoValues.push(...roles);
    }

    if (!subUsersControls.disabled) {
      const users: RoleSubEntity[] =
        existingRoleIds.length > 0
          ? []
          : this.projectUsers
              .filter(user => !existingUserIds.includes(user.id))
              .map(user => ({
                id: user.id,
                name: user.username,
                description: user.emailAddress,
                type: RoleSubType.user,
                filterValue: `${user.firstname} ${user.lastname}`,
              }));

      autoValues.push(...users);
    }

    this.autoValues = autoValues;
    this.filterAutocomplete(currentFilter);
  }

  filterAutocomplete(filter: string = null) {
    filter = filter?.toLowerCase()?.trim() ?? '';
    this.filteredAutoValues = this.autoValues.filter(
      v =>
        v.name.toLowerCase().indexOf(filter) >= 0 ||
        v.description.toLowerCase().indexOf(filter) >= 0 ||
        (v.filterValue && v.filterValue?.toLowerCase().indexOf(filter) >= 0)
    );
  }

  autocompleteOptionSelected(selected: RoleSubEntity, role: UntypedFormGroup): void {
    const isUser = selected.type == RoleSubType.user;
    const existingIds = isUser
      ? this.projectUsers.map(u => u.id)
      : this.allRoles.map(r => FormUtils.getFormValue<PcfRole>(r, 'id'));

    if (!existingIds.includes(selected.id)) throw `${isUser ? 'User' : 'Role'} not found`;

    const subRolesOrUsersForm = FormUtils.getFormControl<PcfRole, UntypedFormArray>(role, isUser ? 'users' : 'roles');
    const id = FormUtils.getFormValue<PcfRole>(role, 'id');

    if (subRolesOrUsersForm.disabled || (!isUser && this.roleUsedInStructure[id]))
      throw 'Role is not allowed to have sub roles/users';

    const existingSubIds: string[] = subRolesOrUsersForm.value;
    existingSubIds.push(selected.id);
    subRolesOrUsersForm.setValue(existingSubIds);

    role.markAsDirty();
    this.buildAutocomplete(role);
  }

  removeSubRoleOrUserIfExists(id: string, role: UntypedFormGroup, type: RoleSubType): void {
    const isUser = type == RoleSubType.user;
    const subRolesOrUsersForm = FormUtils.getFormControl<PcfRole, UntypedFormArray>(role, isUser ? 'users' : 'roles');

    const existingIds: string[] = subRolesOrUsersForm.value;
    const index = existingIds.indexOf(id);
    if (index >= 0) {
      existingIds.splice(index, 1);
      subRolesOrUsersForm.setValue(existingIds);
      role.markAsDirty();
    }
  }

  async addOrEditRole(roleGroup: UntypedFormGroup = null) {
    const originalRole = roleGroup?.getRawValue();
    const updatedRole: IProjectConfigTeamRole = await this.dialog
      .open(EditRoleDialogComponent, {
        data: {
          role: originalRole,
        },
        disableClose: true,
      })
      .afterClosed()
      .toPromise();

    if (updatedRole != null) {
      if (roleGroup != null) {
        FormUtils.getFormControl<PcfRole>(roleGroup, 'name').setValue(updatedRole.name);
        FormUtils.getFormControl<PcfRole>(roleGroup, 'description').setValue(updatedRole.description);
        FormUtils.getFormControl<PcfRole>(roleGroup, 'isReadOnly').setValue(updatedRole.isReadOnly);
        FormUtils.getFormControl<PcfRole>(roleGroup, 'isHidden').setValue(updatedRole.isHidden);
        roleGroup.markAsDirty();
      } else {
        this.configService.addRole(updatedRole);

        this.buildRoleGroups();
      }
    }
  }

  async removeRole(roleToRemove: UntypedFormGroup) {
    const hasConfirmed = await this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'general.confirmation.deleteCaption',
          description: 'general.confirmation.deleteDescription',
          params: {
            element: await this.translate.get('projectConfig.roles.element').toPromise(),
            name: FormUtils.getFormValue<PcfRole>(roleToRemove, 'name'),
          },
        },
      })
      .afterClosed()
      .toPromise();

    if (hasConfirmed) {
      const id = FormUtils.getFormValue<PcfRole>(roleToRemove, 'id');
      this.configService.removeRole(id);

      this.buildRoleGroups();
    }
  }

  private getIndividualPrivilegeIdsUsedInStructureRecursive(directories: UntypedFormArray, usedIds: string[] = []) {
    for (const directory of directories.controls as UntypedFormGroup[]) {
      const individualPrivileges = FormUtils.getFormControl<PcfDirectory, UntypedFormArray>(directory, 'individualPrivileges');
      for (const individualPrivilege of individualPrivileges.controls as UntypedFormGroup[]) {
        const individualPrivilegeId = FormUtils.getFormValue<IndividualPrivilege>(individualPrivilege, 'id');
        if (!usedIds.includes(individualPrivilegeId)) usedIds.push(individualPrivilegeId);
      }

      const subFolder = FormUtils.getFormControl<PcfDirectory, UntypedFormArray>(directory, 'folders');
      usedIds = this.getIndividualPrivilegeIdsUsedInStructureRecursive(subFolder, usedIds);
    }

    return usedIds;
  }

  private checkStructure() {
    const individualPrivilegeIdsUsedInStructure = this.getIndividualPrivilegeIdsUsedInStructureRecursive(
      FormUtils.getFormControl<PcfStructure, UntypedFormArray>(this.configService.structureForm, 'portalChannels')
    );

    this.roleUsedInStructure = this.allRoles.reduce((roleUsedInStructure, role) => {
      const roleId = FormUtils.getFormValue<PcfRole>(role, 'id');

      roleUsedInStructure[roleId] = individualPrivilegeIdsUsedInStructure.includes(roleId);

      return roleUsedInStructure;
    }, {});
  }

  private buildRoleGroups() {
    const [systemRoles, roles] = this.allRoles.reduce(
      ([systemRoles, roles], role) => {
        const isSystemRole = !!FormUtils.getFormValue<PcfRole>(role, 'isSystemRole');

        if (isSystemRole) systemRoles.push(role);
        else roles.push(role);

        return [systemRoles, roles];
      },
      [[], []]
    );

    this.systemRoles = systemRoles;
    this.basicRoles = roles;
  }
}
