import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[consumeClick]',
})
export class ConsumeClickDirective {
  @HostListener('click', ['$event']) onClick(e: MouseEvent) {
    if (this.isEnabled) e.stopPropagation();
  }

  @Input() set consumeClick(isEnabled: boolean | string) {
    this.isEnabled = isEnabled === '' || isEnabled === true;
  }

  private isEnabled = true;
}
