<div class="user-role-chip-list">
  <mat-chip-list #chipList aria-label="User selection">
    <mat-chip *ngFor="let role of userData.roles; let i = index" class="c4-busy-content" [selectable]="true"
              [removable]="!role.isReadOnly" (removed)="removeRole(userData, i)">
      {{role.name}}
      <mat-icon *ngIf="!role.isReadOnly" matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input class="c4-busy-hidden" [placeholder]="'general.actions.add' | translate" #userInput
           #trigger="matAutocompleteTrigger" [matAutocomplete]="autoUser" [matChipInputFor]="chipList"
           (input)="rolefilter($event.target.value,userData)" (focus)="acFocus(userData)"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addRole(userData, $event)">
  </mat-chip-list>
  <mat-autocomplete #autoUser="matAutocomplete" (optionSelected)="selected(userData, $event)">
    <mat-option *ngFor="let role of autoRoles" [value]="role">
      <span>{{role.name}}</span>
    </mat-option>
  </mat-autocomplete>
</div>