<div class="c4-dialog-wrapper dialog-container">
	<h1 mat-dialog-title>{{ title | translate }}</h1>
	<mat-dialog-content>
		<div class="description" *ngIf='description'>{{ description | translate }}</div>
		<mat-form-field>
			<mat-label>{{ 'general.select' | translate }}</mat-label>
			<mat-select [ngModel]="selected" (selectionChange)="setSelection($event?.value)">
				<mat-option *ngFor="let option of options" [value]="option.value ? option.value : option.label"
										[disabled]="option.disabled">
					{{ option.label | translate }}
				</mat-option>
			</mat-select>
		</mat-form-field>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button *ngIf="setCancelBtn" mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate
			}}</button>
		<button *ngIf="setOkBtn" mat-flat-button color="primary" (click)="confirm()" [disabled]="!selected">{{ 'general.ok'
			| translate
			}}</button>
	</mat-dialog-actions>
</div>