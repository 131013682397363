<div class="c4-dialog-wrapper">
  <h1 mat-dialog-title>{{ 'dialogs.newMarker.caption' | translate }}</h1>

  <form [formGroup]="newMarkerForm" (ngSubmit)="confirm(newMarkerForm.value)">
    <mat-dialog-content>
      <mat-form-field class="c4-full-width">
        <input matInput placeholder="{{ 'dialogs.newMarker.name' | translate }}" formControlName="name">
      </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-flat-button type="button" (click)="abort()">{{ 'general.cancel' | translate }}</button>
      <button mat-flat-button color="primary" type="submit" [disabled]="!newMarkerForm.valid">{{
        'dialogs.newMarker.create' | translate }}</button>
    </mat-dialog-actions>
  </form>
</div>