import { ModuleType, PrivilegeEnum } from '@app/api';

enum AppRoutes {
  attributes = 'attributes',
  bim = 'bim',
  categories = 'categories',
  companies = 'companies',
  consent = 'consent',
  crafts = 'crafts',
  dashboard = 'dashboard',
  defectReasons = 'defectReasons',
  defectTypes = 'defectTypes',
  defects = 'defects',
  diary = 'diary',
  documents = 'documents',
  download = 'download',
  gallery = 'gallery',
  ifc = 'ifc',
  issueLabels = 'issueLabels',
  issueStages = 'issueStages',
  issueTypes = 'issueTypes',
  lean = 'lean',
  login = 'login',
  logout = 'logout',
  msteams = 'msteams',
  notifications = 'notifications',
  organizations = 'organizations',
  phases = 'phases',
  planning = 'planning',
  projectSettings = 'projectSettings',
  projects = 'projects',
  resetPassword = 'resetPassword',
  roomTemplates = 'roomTemplates',
  rooms = 'rooms',
  scheduler = 'scheduler',
  schemata = 'schemata',
  setup = 'setup',
  specialDates = 'specialDates',
  standardTexts = 'standardTexts',
  team = 'team',
  templates = 'templates',
  users = 'users',
  workpackages = 'workpackages',
  workpackageSequences = 'workpackageSequences',
  workpackageTemplates = 'workpackageTemplates',
}

export interface RoutingData {
  icon?: string;
  iconUrl?: string;
  reuseRoute?: boolean;
  privileges?: PrivilegeEnum[];
  adminPath?: string;
  path: string;
}

export const AppRoutingData: { [key in AppRoutes]: RoutingData } = {
  attributes: { icon: 'mdi-tag-text-outline', privileges: [PrivilegeEnum.ReadRoomBook], path: 'attributes' },
  bim: {
    icon: 'mdi-view-quilt-outline',
    iconUrl: 'assets/icons/build365-bim.svg',
    privileges: [PrivilegeEnum.ReadBim],
    path: 'bim',
  },
  categories: { icon: 'mdi-animation-outline', privileges: [PrivilegeEnum.ReadRoomBook], path: 'categories' },
  companies: { icon: 'mdi-notebook', path: 'projects' },
  consent: { icon: 'mdi-clipboard-account', path: 'consent' },
  crafts: { icon: 'mdi-hammer-wrench', path: 'crafts' },
  dashboard: { icon: 'mdi-view-dashboard', iconUrl: 'assets/icons/build365-module.svg', path: 'dashboard' },
  defectReasons: { icon: 'mdi-book-cog', path: 'defectReasons' },
  defectTypes: { icon: 'mdi-book-settings', path: 'defectTypes' },
  defects: {
    icon: 'mdi-bolt',
    iconUrl: 'assets/icons/build365-maengelmanagement.svg',
    privileges: [PrivilegeEnum.ReadDefects],
    path: 'defects',
  },
  diary: {
    icon: 'mdi-book-open-page-variant',
    iconUrl: 'assets/icons/build365-bautagebuch.svg',
    privileges: [PrivilegeEnum.ReadConstructionDiary],
    path: 'diary',
  },
  documents: { icon: 'mdi-folder-outline', iconUrl: 'assets/icons/build365-dokumentenmanagement.svg', path: 'documents' },
  download: { icon: 'mdi-download', path: 'download' },
  gallery: {
    icon: 'mdi-camera-burst',
    iconUrl: 'assets/icons/build365-galerie.svg',
    privileges: [PrivilegeEnum.ReadGallery],
    path: 'gallery',
  },
  ifc: { icon: 'mdi-cube-unfolded', privileges: [PrivilegeEnum.ReadBim], path: 'ifc' },
  issueLabels: { icon: 'mdi-label', path: 'issueLabels' },
  issueStages: { icon: 'mdi-alert', path: 'issueStages' },
  issueTypes: { icon: 'mdi-book-alert', path: 'issueTypes' },
  lean: {
    icon: 'mdi-briefcase-clock',
    iconUrl: 'assets/icons/build365-lean-prozesse.svg',
    path: 'scheduler',
    privileges: [PrivilegeEnum.ReadLean],
  },
  login: { icon: '', path: 'login' },
  logout: { icon: '', path: 'logout' },
  msteams: { icon: 'mdi-microsoft', path: 'msteams' },
  notifications: { icon: 'mdi-bell-outline', iconUrl: 'assets/icons/ringing-bell-notification.svg', path: 'notifications' },
  organizations: {
    icon: 'mdi-office-building',
    privileges: [PrivilegeEnum.TeamRestricted],
    adminPath: 'organizations',
    path: 'team/organizations',
  },
  phases: { icon: 'mdi-chart-gantt', privileges: [PrivilegeEnum.ReadLean], path: 'phases' },
  planning: {
    icon: 'mdi-map-legend',
    iconUrl: 'assets/icons/build365-planmanagement.svg',
    privileges: [PrivilegeEnum.ReadPlan],
    path: 'planning',
  },
  projectSettings: { icon: 'mdi-cog', privileges: [PrivilegeEnum.ReadWriteProjectSettings], path: 'settings' },
  projects: { icon: 'mdi-domain', path: 'projects' },
  resetPassword: { icon: 'mdi-transit-connection-variant', path: 'resetPassword' },
  roomTemplates: { icon: 'mdi-floor-plan', privileges: [PrivilegeEnum.ReadRoomBook], path: 'roomTemplates' },
  rooms: {
    icon: 'mdi-notebook-edit',
    iconUrl: 'assets/icons/build365-raumbuch.svg',
    privileges: [PrivilegeEnum.ReadRoomBookLight],
    path: 'rooms',
  },
  scheduler: {
    icon: 'mdi-chart-gantt',
    iconUrl: 'assets/icons/build365-lean-prozesse.svg',
    privileges: [PrivilegeEnum.ReadWriteLean],
    path: 'scheduler',
  },
  schemata: { icon: 'mdi-map', path: 'schemata' },
  setup: { icon: 'mdi-cog', path: 'setup' },
  specialDates: { icon: 'mdi-calendar-multiselect', privileges: [PrivilegeEnum.ReadWriteLean], path: 'specialDates' },
  standardTexts: { icon: 'mdi-text', path: 'standardTexts' },
  team: { icon: 'mdi-account-group', path: 'team', privileges: [PrivilegeEnum.TeamRestricted] },
  templates: { icon: 'mdi-clipboard-outline', path: 'templates' },
  users: { icon: 'mdi-account-group', path: 'users' },
  workpackageSequences: {
    icon: 'mdi-transit-connection-variant',
    privileges: [PrivilegeEnum.ReadWriteLean],
    path: 'workpackageSequences',
  },
  workpackageTemplates: {
    icon: 'mdi-clipboard-outline',
    privileges: [PrivilegeEnum.ReadWriteLean],
    path: 'workpackageTemplates',
  },
  workpackages: { icon: 'mdi-clipboard-clock-outline', privileges: [PrivilegeEnum.ReadWriteLean], path: 'workpackages' },
};

export const RoutingDataResourceMapping: Record<ModuleType, RoutingData> = {
  [ModuleType.MSTeams]: undefined,
  [ModuleType.Document]: AppRoutingData.documents,
  [ModuleType.Plan]: AppRoutingData.planning,
  [ModuleType.Defect]: AppRoutingData.defects,
  [ModuleType.ConstructionDiary]: AppRoutingData.diary,
  [ModuleType.Bim]: AppRoutingData.bim,
  [ModuleType.RoomBook]: AppRoutingData.rooms,
  [ModuleType.Gallery]: AppRoutingData.gallery,
  [ModuleType.Lean]: AppRoutingData.lean,
};

export function pathFragmentsTo(projectId: string = null, ...fragments: string[]) {
  const fragmentsToProject = [`/${AppRoutingData.projects.path}`];
  if (projectId) {
    fragmentsToProject.push(projectId);
    for (const fragment of fragments) if (!!fragment) fragmentsToProject.push(fragment);
  }
  return fragmentsToProject;
}

export function pathTo(projectId: string = null, path: string = null) {
  return pathFragmentsTo(projectId, path).join('/');
}

export function pathFragmentsToAdmin(...fragments: string[]) {
  const fragmentsToAdmin = ['/admin'];
  fragments = fragments.length ? fragments : [AppRoutingData.dashboard.path];
  for (const fragment of fragments) if (!!fragment) fragmentsToAdmin.push(fragment);
  return fragmentsToAdmin;
}

export function pathToAdmin(path: string = null) {
  return pathFragmentsToAdmin(path).join('/');
}
