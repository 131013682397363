import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { RoomModel } from '@app/api';
import { DataHolder } from '@app/core';
import { C4GridDef, C4GridFilterType, C4GridMatchMode, GridSelectionMode } from '../../grid';
import { LocationType } from '../room-book.interfaces';

export enum RoomListMode {
  default = 'default',
  singleSelectIntegrated = 'singleSelectIntegrated',
}

@Component({
  selector: 'app-room-list',
  templateUrl: './room-list.component.html',
  styleUrls: ['./room-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoomListComponent implements OnInit {
  @ViewChild('nameCol', { static: true }) nameCol: TemplateRef<any>;

  @Input() rows: number = 10;
  @Input() rowsOptions: number[] = [5, 10, 20, 50, 100];
  @Input() mode: RoomListMode = RoomListMode.default;
  @Input() locationType: LocationType = null;
  @Input() set rooms(value: RoomModel[]) {
    this.allRooms = value;
    if (this.isInitialized) this.updateData();
  }

  private _selection: any;
  get selection(): any {
    return this._selection;
  }
  @Input() set selection(value: any) {
    this._selection = value;
    this.selectionChange.emit(value);
  }
  @Output() selectionChange = new EventEmitter<any>();
  @Output() navigateToRoom = new EventEmitter<string>();

  selectionMode: GridSelectionMode = GridSelectionMode.none;
  roomsHolder: DataHolder<RoomModel[]>;
  gridDef: C4GridDef;
  gridDefArea: C4GridDef;
  gridDefFloor: C4GridDef;

  private allRooms: RoomModel[];
  private isInitialized: Boolean;

  constructor() {}

  get dynamicGridDef(): C4GridDef {
    switch (this.locationType) {
      case LocationType.Area:
        return this.gridDefArea;
      case LocationType.Floor:
        return this.gridDefFloor;
      default:
        return this.gridDef;
    }
  }

  async ngOnInit() {
    this.selectionMode = this.mode == RoomListMode.singleSelectIntegrated ? GridSelectionMode.single : GridSelectionMode.none;
    this.roomsHolder = new DataHolder(this.initData());
    this.gridDef = this.generateGridDef();
    this.gridDefArea = this.generateGridDef(false);
    this.gridDefFloor = this.generateGridDef(false, false);
    if (this.allRooms) this.updateData();
    this.isInitialized = true;
  }

  async updateData() {
    await this.roomsHolder?.updateData(async () => {
      this.gridDef.grid.rowCount = this.allRooms?.length ?? 0;
      return this.allRooms;
    }, true);
  }

  private initData(count: number = 5) {
    const rooms: RoomModel[] = [];

    for (let i = 0; i < count; i++) {
      rooms.push(
        new RoomModel({
          number: i,
          name: 'fakeData',
          internalNumber: 'fakeData',
        })
      );
    }

    return rooms;
  }

  private generateGridDef(withArea: boolean = true, withFloor: boolean = true) {
    const gridDef = {
      initialSorting: [
        {
          field: 'number',
          order: 1,
        },
      ],
      grid: {
        prefix: 'roomBook.fieldName.',
        filterRow: true,
        lazy: false,
        lazyInit: false,
        paging: true,
        responsive: true,
        scrollable: true,
        rowExpand: true,
        rows: this.rows,
        rowsOptions: this.rowsOptions,
      },
      row: {
        link: false,
      },
      cols: [
        {
          field: 'number',
          header: 'id',
          width: '5em',
          minWidth: '5em',
          priority: 3,
          cssClass: 'right-aligned',
          sortable: true,
          filterType: C4GridFilterType.text,
          filterMatchMode: C4GridMatchMode.contains,
        },
        {
          field: 'internalNumber',
          header: 'internalnumber',
          width: '1*',
          minWidth: '8em',
          priority: 1,
          sortable: true,
          filterType: C4GridFilterType.text,
          filterMatchMode: C4GridMatchMode.contains,
          template: this.mode != RoomListMode.singleSelectIntegrated ? this.nameCol : null,
        },
        {
          field: 'name',
          header: 'name',
          width: '2*',
          minWidth: '10em',
          priority: 1,
          sortable: true,
          filterType: C4GridFilterType.text,
          filterMatchMode: C4GridMatchMode.contains,
        },
      ],
    };

    if (withArea) {
      gridDef.cols.push({
        field: 'areaName',
        header: 'area',
        width: '10em',
        minWidth: '10em',
        priority: 2,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      });
    }

    if (withFloor) {
      gridDef.cols.push({
        field: 'floorName',
        header: 'floor',
        width: '10em',
        minWidth: '10em',
        priority: 2,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      });
    }

    gridDef.cols.splice(
      gridDef.cols.length,
      0,
      {
        field: 'roomTemplateName',
        header: 'roomtemplate',
        width: '10em',
        minWidth: '10em',
        priority: 2,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      },
      {
        field: 'type',
        header: 'type',
        width: '10em',
        minWidth: '10em',
        priority: 3,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      },
      {
        field: 'use',
        header: 'use',
        width: '10em',
        minWidth: '10em',
        priority: 3,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      },
      {
        field: 'floorArea',
        header: 'floorarea',
        width: '8em',
        minWidth: '8em',
        priority: 3,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      },
      {
        field: 'volume',
        header: 'volume',
        width: '8em',
        minWidth: '8em',
        priority: 3,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      }
    );

    return gridDef;
  }
}
