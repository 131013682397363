<div class="c4-dialog-wrapper organization-dialog-root">
	<h1 mat-dialog-title>{{ title | translate:params }}</h1>
	<mat-dialog-content [formGroup]="form">
		<app-organization-input #organizationInput formControlName="organization"
														[existingOrganizations]="existingOrganizations"
														[assignedOrganizations]="assignedOrganizations" [existingCrafts]="existingCrafts"
														[isAddOrEdit]="data.isAddOrEdit" [canEditCrafts]="data.canEditCrafts">
		</app-organization-input>
		<mat-expansion-panel *ngIf="showUser" hideToggle [expanded]="true">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<span class="mdi mdi-account-circle usericon"></span>
					{{ 'organizations.assignedUsers' | translate }}
				</mat-panel-title>
			</mat-expansion-panel-header>
			<span *ngIf="userLoadingScope.isBusy; else usersTemplate" class="mdi mdi-loading mdi-spin"></span>
			<ng-template #usersTemplate>
				<div [ngSwitch]="users?.length">
					<div class="error-message" *ngSwitchCase="null">{{ 'organizations.error.userLoad' | translate }}</div>
					<div class="error-message" *ngSwitchCase="0">{{ 'organizations.error.userNotFound' | translate }}</div>
					<ul *ngSwitchDefault>
						<li *ngFor="let user of users" (click)="goToUser(user)">
							<span class="username">{{ user.username }}</span>
							<span class="mdi mdi-exit-to-app"></span>
						</li>
					</ul>
				</div>
			</ng-template>
		</mat-expansion-panel>
	</mat-dialog-content>
	<mat-dialog-actions class="actions">
		<button mat-flat-button type="button" (click)="cancel()">{{ 'general.cancel' | translate }}</button>
		<button mat-flat-button color="primary" (click)="confirm()" [disabled]="!organizationInput.isValid">
			{{ 'general.save' | translate }}
		</button>
	</mat-dialog-actions>
</div>
<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:true }"></ngx-loading>