import { EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as L from 'leaflet';
import { NewMarkerDialogComponent } from '../../dialogs/new-marker-dialog/new-marker-dialog.component';
import { TranslationManagementService, Utils } from '@app/core';

export interface IMetadata {
  position: L.LatLng;
  properties: L.corner4.MappingType;
}

export class LeafletMarker {
  private color: string | null = null;
  private control: any;
  private context: L.corner4.MappingType | null = null;

  constructor(
    private map: L.Map,
    private translate: TranslationManagementService,
    private dialog: MatDialog,
    private cb: (metadata: IMetadata) => L.Marker
  ) {
    this.control = map.pm.Toolbar.createCustomControl({
      name: this.key,
      className: 'leaflet-cst-icon-marker',
      title: this.translate.instant('leaflet.marker.title'),
      actions: [
        {
          text: this.translate.instant('leaflet.finish'),
          onClick: () => {
            this.complete();
          },
        },
      ],
      toggle: true,
    }) as any;
  }

  onchange = new EventEmitter<L.Layer>();

  get key() {
    return 'cst-marker-button';
  }

  get drawing() {
    return this.context != null;
  }

  start(context: L.corner4.MappingType, color: string | null) {
    this.context = context;
    this.color = color;
    this.map.on('click', this.click, this);
  }

  complete() {
    this.map.dragging.enable();

    if (this.context) {
      this.map.off('click', this.click, this);
    }

    if (this.control.toggled()) {
      this.control.toggle();
    }

    this.context = null;
  }

  private async click(e: L.LeafletMouseEvent) {
    L.DomEvent.stopPropagation(e);

    if (await this.createMarker(e.latlng, this.context)) {
      this.complete();
    }
  }

  async createMarker(coords: L.LatLng, context: any) {
    const dialogRef = this.dialog.open(NewMarkerDialogComponent);

    const description = await dialogRef.afterClosed().toPromise();

    if (!description) {
      return false;
    }

    const key = Utils.createUUID();
    const props = Object.assign({}, context, {
      description: description,
      type: 'DefectMarker',
      key: key,
    });

    const marker: IMetadata = {
      position: coords,
      properties: props,
    };

    const layer = this.cb(marker);

    layer.addTo(this.map);

    this.onchange.emit(layer);

    return true;
  }
}
