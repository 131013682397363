import { Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface RadioGroupOption {
  key: string;
  title: string;
  description: string;
}

@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: ['./radio-group.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioGroupComponent,
    },
  ],
})
export class RadioGroupComponent implements ControlValueAccessor {
  @ViewChildren('input') inputs: QueryList<ElementRef<HTMLInputElement>>;

  @Input() options: RadioGroupOption[];

  isDisabled: boolean = false;

  selected: string;

  constructor() {}

  onChange = (_: string) => {};
  private onTouched = () => {};
  private isTouched: boolean = false;

  optionClicked(option: RadioGroupOption, input: HTMLInputElement) {
    input.focus();

    if (this.selected != option.key) {
      this.selected = option.key;
      this.onChange(option.key);
    }
  }

  onKeydown(event: KeyboardEvent, index: number) {
    switch (event.key) {
      case 'ArrowUp':
        index = index > 0 ? index - 1 : this.options.length - 1;
        break;
      case 'ArrowDown':
        index = index < this.options.length - 1 ? index + 1 : 0;
        break;
      default:
        return;
    }

    event.preventDefault();

    this.optionClicked(this.options[index], this.inputs.get(index).nativeElement);
  }

  // ----- Reactive Forms Methods -----

  writeValue(key: string): void {
    this.selected = key;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
  }
}
