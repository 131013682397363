<div class="c4-card-box">
  <mat-card>
    <div class="c4-card-link" *ngIf="linkButtonData">
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      <button mat-icon-button (click)="handleClick()" class="c4-card-icon c4-card-icon-button" [matTooltip]="linkButtonTooltip">
          <span [ngClass]="navIconClass"></span>
      </button>
    </div>
    <div class="c4-card-link" *ngIf="!linkButtonData && link.length === 0">
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </div>
    <a class="c4-card-link" [routerLink]="link" [queryParams]="queryParams" *ngIf="!linkButtonData && link.length > 0 && linkIsRouterLink">
      <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
      <div class="c4-card-icon c4-card-nav-icon">
        <span [ngClass]="navIconClass"></span>
      </div>
    </a>
    <a class="c4-card-link" [href]="link" *ngIf="!linkButtonData && link.length > 0 && !linkIsRouterLink">
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
        <div class="c4-card-icon c4-card-nav-icon">
          <span [ngClass]="navIconClass"></span>
        </div>
      </a>
    <div class="c4-card-content">
      <ng-content></ng-content>
    </div>
  </mat-card>
</div>

<ng-template #headerTemplate>
  <div *ngIf="iconClass" class="c4-card-icon">
    <span [ngClass]="iconClass"></span>
  </div>
  <div class="c4-card-header-text">
    <span class="c4-card-header c4-busy-content" [ngClass]="{'c4-card-header-darktext': darkHeaderText}">{{header | translate}}</span>
    <span *ngIf="subHeader" class="c4-card-subheader">{{subHeader | translate}}</span>
  </div>
</ng-template>