import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ChangeModel, ChangeSetModel } from '@app/api';
import { ApiService, LogService, Utils } from '@app/core';
import { GlobalsService } from '@app/core';
import * as moment from 'moment';

export enum HistoryStateTemplateType {
  default = 'default',
  color = 'color',
  icon = 'icon',
}

export interface HistoryStateChange {
  date: Date;
  labelKey: string;
  username: string;
  type: HistoryStateTemplateType;
  iconClass?: string;
  color?: string;
}

export enum HistoryChangeType {
  date = 'date',
  translate = 'translate',
  bool = 'bool',
  state = 'state',
  state_translate = 'state_translate',
}

export interface HistoryChangeModel extends ChangeModel {
  type?: HistoryChangeType;
  additionalInfo?: string;
}

export interface HistoryChangeSetModel extends ChangeSetModel {
  changes?: HistoryChangeModel[];
}

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HistoryComponent {
  @Input() set stateChanges(values: HistoryStateChange[]) {
    this.sortedStateChanges = values?.sort((a, b) => a.date.getTime() - b.date.getTime());
  }
  @Input() set changeSets(values: HistoryChangeSetModel[]) {
    this.processChangeSets(values);
  }

  private dateToday: Date = moment().startOf('day').toDate();
  private dateAWeekAgo: Date = moment().startOf('day').subtract(1, 'weeks').toDate();
  private dateThirtyDaysAgo: Date = moment().startOf('day').subtract(30, 'days').toDate();

  showMobileDetails: boolean = false;
  selectedListItem: HistoryChangeSetModel;
  changesToday: HistoryChangeSetModel[] = [];
  changesAWeekAgo: HistoryChangeSetModel[] = [];
  changesAMonthAgo: HistoryChangeSetModel[] = [];
  changesOlder: HistoryChangeSetModel[] = [];

  HistoryStateTemplateType = HistoryStateTemplateType;
  HistoryChangeType = HistoryChangeType;

  sortedStateChanges: HistoryStateChange[];
  hasChanges: boolean;

  constructor(public globals: GlobalsService) {}

  async processChangeSets(values: HistoryChangeSetModel[] = []) {
    this.hasChanges = values.length > 0;

    this.changesToday = values.filter(x => x.createdOn >= this.dateToday);
    this.changesAWeekAgo = values.filter(x => x.createdOn < this.dateToday && x.createdOn >= this.dateAWeekAgo);
    this.changesAMonthAgo = values.filter(x => x.createdOn >= this.dateThirtyDaysAgo && x.createdOn < this.dateAWeekAgo);
    this.changesOlder = values.filter(x => x.createdOn < this.dateThirtyDaysAgo);

    if (values.length > 0) {
      const sortedChangeSets = values.sort((a, b) => b.createdOn.getTime() - a.createdOn.getTime());
      this.selectedListItem = sortedChangeSets[0];
    }
  }

  public static setType(historyChange: HistoryChangeModel, type: HistoryChangeType) {
    historyChange.type = type;
  }

  public static setAdditionalInfo(historyChange: HistoryChangeModel, info: string) {
    historyChange.additionalInfo = info;
  }
}
