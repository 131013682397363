import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {
  ActivitySteps,
  ActivityStepsCancellationToken,
  ActivityStepState,
} from '../../../../core/services/offline/stepWithProgress';

export enum ProgressDialogResult {
  ok = 'ok',
  cancel = 'cancel',
  showErrors = 'showErrors',
}

@Component({
  selector: 'app-progress-dialog',
  templateUrl: './progress-dialog.component.html',
  styleUrls: ['./progress-dialog.component.scss'],
})
export class ProgressDialogComponent implements OnInit {
  title: string;
  subTitle: string;
  description: string;
  steps: ActivitySteps;
  stepState = ActivityStepState;
  cancellationToken = new ActivityStepsCancellationToken();
  areAllSuccessful: boolean = false;
  hasAnyFailed: boolean = false;
  hasAnyWarnings: boolean = false;
  hasStarted: boolean = false;
  canCancel: boolean = false;

  constructor(public dialogRef: MatDialogRef<ProgressDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.title = data?.title ?? 'TITLE_NOT_SET';
    this.subTitle = data?.subTitle;
    this.description = data?.description ?? null;
    this.steps = data?.steps ?? new ActivitySteps();
    this.canCancel = data?.canCancel;
  }

  ngOnInit() {
    for (const step of this.steps.items) {
      step.currentState$.subscribe(state => this.stateChanged(state));
    }
  }

  ok() {
    this.dialogRef.close(ProgressDialogResult.ok);
  }

  showErrors() {
    this.dialogRef.close(ProgressDialogResult.showErrors);
  }

  cancel() {
    this.cancellationToken.cancel();

    this.dialogRef.close(ProgressDialogResult.cancel);
  }

  async start() {
    this.hasStarted = true;
    await this.steps.start(this.cancellationToken);
  }

  getStateIcon(state: ActivityStepState) {
    switch (state) {
      case ActivityStepState.running:
        return 'mdi-play';
      case ActivityStepState.success:
        return 'mdi-check';
      case ActivityStepState.failure:
        return 'mdi-close';
      case ActivityStepState.completedWithWarnings:
        return 'mdi-exclamation';
      default:
        return '';
    }
  }

  private stateChanged(state: ActivityStepState) {
    switch (state) {
      case ActivityStepState.success:
        this.checkIfAllSuccessful();
        break;
      case ActivityStepState.failure:
        this.hasAnyFailed = true;
        break;
      case ActivityStepState.completedWithWarnings:
        this.hasAnyWarnings = true;
        this.checkIfAllSuccessful();
        break;
    }
  }
  private checkIfAllSuccessful() {
    if (
      this.steps.items.all(
        s => s.currentState === ActivityStepState.success || s.currentState === ActivityStepState.completedWithWarnings
      )
    ) {
      this.areAllSuccessful = true;
    }
  }
}
