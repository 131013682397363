<div class="c4-dialog-wrapper sync-error-dialog">
	<h1 mat-dialog-title>{{ 'offline.error.syncTitle' | translate }}</h1>
	<span *ngIf="companyName" class="sub-title">{{ companyName }}</span>
	<mat-dialog-content>
		<div class="errors-header">
			<span class="topic">{{ 'offline.dialogs.headers.typ' | translate }}</span>
			<div class="link">{{ 'offline.dialogs.headers.identification' | translate }}</div>
		</div>
		<div class="error-list" [@.disabled]="!viewInitialized">
			<mat-expansion-panel class="slim-expansion-panel" *ngFor="let error of errors" expanded="false">
				<mat-expansion-panel-header>
					<div class="error-expander-header">
						<span class="state">
							<span class="mdi mdi-close"></span>
						</span>
						<span class="topic" [matTooltip]="error.topic">{{ error.topic | translate }}</span>
						<a *ngIf="error.routerLink; else linkTextTemplate" [routerLink]="error.routerLink"
							 [queryParams]="error.queryParams" (click)="cancel()">
							{{ error.linkText ? error.linkText : error.routerLink }}
						</a>
						<ng-template #linkTextTemplate>
							<span *ngIf="error.linkText">{{ error.linkText | translate:error.translationParams }}</span>
						</ng-template>
					</div>
				</mat-expansion-panel-header>
				<div class="error-expander-content">
					<div class="description">
						{{ error.description | translate:error.translationParams }}
					</div>
				</div>
			</mat-expansion-panel>
			<mat-expansion-panel class="slim-expansion-panel" *ngFor="let warning of warnings" expanded="false">
				<mat-expansion-panel-header>
					<div class="warning-expander-header">
						<span class="state">
							<span class="mdi mdi-exclamation"></span>
						</span>
						<span class="topic" [matTooltip]="warning.topic">{{ warning.topic | translate }}</span>
						<a *ngIf="warning.routerLink; else linkTextTemplate" [routerLink]="warning.routerLink"
							 [queryParams]="warning.queryParams" (click)="cancel()">
							{{ warning.linkText ? warning.linkText : warning.routerLink }}
						</a>
						<ng-template #linkTextTemplate>
							<span *ngIf="warning.linkText">{{ warning.linkText | translate:warning.translationParams }}</span>
						</ng-template>
					</div>
				</mat-expansion-panel-header>
				<div class="warning-expander-content">
					<div class="description">
						{{ warning.description | translate:warning.translationParams }}
					</div>
				</div>
			</mat-expansion-panel>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
		<div class="proceed-buttons">
			<button mat-stroked-button (click)="deleteStorage()" type="button">
				{{ 'offline.dialogs.error.deleteStorage' | translate }}
			</button>
			<button *ngIf="!single && !allowContinue" mat-flat-button color="primary" (click)="rerun()" type="button">
				{{ 'offline.dialogs.error.rerun' | translate }}
			</button>
			<button *ngIf="allowContinue" mat-flat-button color="primary" (click)="continue()" type="button">
				{{ 'general.ok' | translate }}
			</button>
		</div>
	</mat-dialog-actions>
</div>