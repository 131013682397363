import { ProjectWithUserSettingsModel } from '@app/api';

export interface DecodedToken {
  token: string;
  expiration: Date;
}

export interface RequestOtpData {
  userName: string;
  primarySecret: string;
}

export interface LoginData extends RequestOtpData {
  secondarySecret?: string;
}

export interface AuthenticationCapabilites {
  impersonation?: boolean;
}

export interface AuthenticationChangedData {
  authenticated: boolean;
  passwordChangeRequired: boolean;
  userDataChangeRequired: boolean;
  availableCompanies?: ProjectWithUserSettingsModel[];

  with: AuthenticationCapabilites;
}
