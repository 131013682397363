import { Injectable, Optional, Inject } from '@angular/core';
import {
  API_BASE_URL,
  DriveUploadChunkResultModel,
  ConflictBehavior,
  FileParameter,
  DriveUploadResultModel,
  DriveActionMetadata,
  ProjectDriveClient,
} from './api';
import { HttpClient, HttpEvent, HttpRequest, HttpEventType, HttpHeaders } from '@angular/common/http';

@Injectable()
export class DriveClientExtended extends ProjectDriveClient {
  private httpEx: HttpClient;
  private baseUrlEx: string;

  constructor(@Inject(HttpClient) http: HttpClient, @Optional() @Inject(API_BASE_URL) baseUrl?: string) {
    super(http, baseUrl);
    this.httpEx = http;
    this.baseUrlEx = baseUrl ? baseUrl : '';
  }

  uploadWithProgress(
    projectId: string,
    file: FileParameter,
    path: string,
    resource: string,
    existingNameBehavior: ConflictBehavior,
    metadata: DriveActionMetadata,
    progressCallback: (progressPercent: number) => void
  ): Promise<DriveUploadResultModel> {
    return new Promise<DriveUploadResultModel>((resolve, reject) => {
      let url = this.baseUrlEx + '/projects/{projectId}/items/upload?';
      if (projectId === undefined || projectId === null) throw new Error("The parameter 'projectId' must be defined.");
      url = url.replace('{projectId}', encodeURIComponent('' + projectId));
      if (path !== undefined && path !== null) url += 'path=' + encodeURIComponent('' + path) + '&';
      if (existingNameBehavior === null) throw new Error("The parameter 'existingNameBehavior' cannot be null.");
      else if (existingNameBehavior !== undefined)
        url += 'existingNameBehavior=' + encodeURIComponent('' + existingNameBehavior) + '&';
      url = url.replace(/[?&]$/, '');

      const content = new FormData();
      content.append('Model', JSON.stringify(metadata ?? {}));
      if (file !== null && file !== undefined) content.append('File', file.data, file.fileName ? file.fileName : 'File');

      const request = this.httpEx.request(
        new HttpRequest('POST', url, content, {
          headers: new HttpHeaders({
            resource: resource ? resource : '',
            Accept: 'application/json',
          }),
          reportProgress: true,
        })
      );

      const subscription = request.subscribe(
        (evt: HttpEvent<any>) => {
          if (evt.type === HttpEventType.Response) {
            if (progressCallback) {
              progressCallback(1);
            }
            subscription.unsubscribe();
            resolve(evt.body as DriveUploadResultModel);
          } else if (evt.type === HttpEventType.UploadProgress) {
            if (progressCallback) {
              //because 100% here does not mean 100% of the call is done: 100% "uploadprogress" is reported as 75%
              const percentageScaleFactor = 0.75;
              progressCallback((evt.loaded / evt.total) * percentageScaleFactor);
            }
          }
        },
        error => {
          if (progressCallback) {
            progressCallback(1); //error: for total progress, report "completed" (100%)
          }
          subscription.unsubscribe();
          reject(error);
        }
      );
    });
  }

  uploadChunkWithProgress(
    uploadSessionId: string,
    projectId: string,
    offset: number,
    file: FileParameter,
    resource: string,
    metadata: DriveActionMetadata,
    progressCallback: (progressPercent: number) => void
  ): Promise<DriveUploadChunkResultModel> {
    return new Promise<DriveUploadChunkResultModel>((resolve, reject) => {
      let url = this.baseUrlEx + '/projects/{projectId}/items/uploadsession/{uploadSessionId}?';
      if (uploadSessionId === undefined || uploadSessionId === null)
        throw new Error("The parameter 'uploadSessionId' must be defined.");
      url = url.replace('{uploadSessionId}', encodeURIComponent('' + uploadSessionId));
      if (projectId === undefined || projectId === null) throw new Error("The parameter 'projectId' must be defined.");
      url = url.replace('{projectId}', encodeURIComponent('' + projectId));
      if (offset === null) throw new Error("The parameter 'offset' cannot be null.");
      else if (offset !== undefined) url += 'offset=' + encodeURIComponent('' + offset) + '&';
      url = url.replace(/[?&]$/, '');

      const content = new FormData();
      content.append('Model', JSON.stringify(metadata ?? {}));
      if (file !== null && file !== undefined) content.append('file', file.data, file.fileName ? file.fileName : 'file');

      const request = this.httpEx.request(
        new HttpRequest('POST', url, content, {
          headers: new HttpHeaders({ resource: resource ? resource : '', Accept: 'application/json' }),
          reportProgress: true,
        })
      );

      const subscription = request.subscribe(
        (evt: HttpEvent<any>) => {
          if (evt.type === HttpEventType.Response) {
            if (progressCallback) {
              progressCallback(1);
            }
            subscription.unsubscribe();
            resolve(evt.body as DriveUploadChunkResultModel);
          } else if (evt.type === HttpEventType.UploadProgress) {
            if (progressCallback) {
              //because 100% here does not mean 100% of the call is done: 100% "uploadprogress" is reported as 75%
              const percentageScaleFactor = 0.75;
              progressCallback((evt.loaded / evt.total) * percentageScaleFactor);
            }
          }
        },
        error => {
          if (progressCallback) {
            progressCallback(1); //error: for total progress, report "completed" (100%)
          }
          subscription.unsubscribe();
          reject(error);
        }
      );
    });
  }
}
