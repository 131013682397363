import { Component } from '@angular/core';

@Component({
  selector: 'app-edit-hover-overlay',
  templateUrl: './edit-hover-overlay.component.html',
  styleUrls: ['./edit-hover-overlay.component.scss'],
})
export class EditHoverOverlayComponent {
  constructor() {}
}
