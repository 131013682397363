import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  LeanPhaseModel,
  LeanSwimlaneModel,
  GeneralProjectSettingsModel,
  PropertyBagType,
  PropertyBagModel,
  LeanSpecialDateModel,
  LeanWorkpackageModel,
  ProjectModel,
} from '@app/api';
import { ApiService, LogService, rgbToHex, Utils } from '@app/core';
import {
  LeanBryntumRowResponseModel,
  SelectionMode,
  SplitPosition,
  StoreType,
  TreeSelectDialogComponent,
} from '@app/shared/components';
import { Model } from '@bryntum/schedulerpro';
import * as moment from 'moment';
import { Moment } from 'moment';
import { BehaviorSubject } from 'rxjs';
import { UserNotificationService } from '../user-notification/user-notification.service';
import { SchedulerFilter, SchedulerState, Week } from './scheduler.interfaces';
import { SchedulerService } from './scheduler.service';
import { TranslateService } from '@ngx-translate/core';
import { FileExtension } from '@app/core/enumerations';
import { WorkpackageTemplatesHelper } from '@app/core/utils/workpackage-templates-helper';
import { CustomizationService } from '../customization/customization.service';

class PhaseTreeModel extends LeanPhaseModel {
  constructor(model: LeanPhaseModel) {
    super();
    this.init(model);
  }

  get children(): LeanSwimlaneModel[] {
    return this.swimlanes ?? [];
  }
}

@Injectable()
export class ProjectSchedulerService extends SchedulerService {
  private schedulerStateViewId: string;
  private projectSettings: GeneralProjectSettingsModel;
  private currentProject: ProjectModel;

  get projectId(): string {
    return this.currentProject?.id;
  }

  set projectId(id: string) {
    this.currentProject = new ProjectModel({
      id,
    });
  }

  constructor(
    apiService: ApiService,
    log: LogService,
    private customizationService: CustomizationService,
    private dialog: MatDialog,
    protected translate: TranslateService,
    userNotification: UserNotificationService
  ) {
    super(log, apiService, translate, userNotification);

    this.specialDatesSubject = new BehaviorSubject<LeanSpecialDateModel[]>([]);
    this.selectedWeekSubject = new BehaviorSubject<Week>(Utils.getWeek(moment()));
    this.arePreviewWeeksActiveSubject = new BehaviorSubject<boolean>(false);
  }

  async loadData() {
    this.reset();

    this.mainColorHex = this.customizationService.primaryHexColor;

    try {
      const [project, settings, leanSettings, phases, workpackages, specialDates, views] = await Promise.all([
        Utils.enhanceException(this.apiService.getProject(this.projectId), 'project'),
        Utils.enhanceException(this.apiService.getGeneralSettingsForProject(), 'settings'),
        Utils.enhanceException(this.apiService.getLeanSettingsForProject(), 'settings'),
        Utils.enhanceException(this.apiService.getPhases(), 'phases'),
        Utils.enhanceException(this.apiService.getWorkpackages(), 'workpackages'),
        Utils.enhanceException(this.apiService.getSpecialDates(), 'specialDates'),
        Utils.enhanceException(this.apiService.getViews(PropertyBagType.SchedulerState, this.projectId), 'view'),
      ]);

      this.currentProject = project;
      this.projectSettings = settings;
      this._previewWeeks = leanSettings.previewWeeks && leanSettings.previewWeeks > 0 ? leanSettings.previewWeeks : 6;
      this.phasesChanged(phases, false);
      this.workpackagesChanged(workpackages);
      this.storeChanged(StoreType.resources, StoreType.events, StoreType.assignments, StoreType.dependencies);
      this.specialDatesSubject.next(specialDates);

      if (views.length > 0) {
        // there should only be one view / project for scheduler
        this.schedulerStateViewId = views[0].id;
        this.applySchedulerState(JSON.parse(views[0].content));
      } else {
        this.applySchedulerState({});
      }
    } catch (e) {
      this.userNotification.notifyFailedToLoadDataAndLog(
        'general.errorFailedToLoadDataKeys.' + (e.translationKey ?? 'settings'),
        e
      );
    }
  }

  protected override get startOfProject(): Date {
    return this.projectSettings?.projectStart ?? super.startOfProject;
  }

  protected override get endOfProject(): Date {
    return this.projectSettings?.projectEnd ?? super.endOfProject;
  }

  getExportFileName(fileExtension: FileExtension): string {
    return Utils.getFileName(this.currentProject?.name, 'Scheduler', fileExtension);
  }

  async addFromGlobal() {
    let globalPhases: LeanPhaseModel[];
    try {
      globalPhases = await this.apiService.getGlobalPhases();

      if (!globalPhases) return;

      const data = {
        title: 'scheduler.selectGlobal.phaseTitle',
        description: 'scheduler.selectGlobal.phaseDescription',
        selectionMode: SelectionMode.checkbox,
        canCancel: true,
        items: globalPhases.map(p => new PhaseTreeModel(p)),
      };

      const selected: any[] = await this.dialog
        .open(TreeSelectDialogComponent, {
          data: data,
          disableClose: true,
        })
        .afterClosed()
        .toPromise();

      if (selected.length) {
        const swimlanesToImport: LeanSwimlaneModel[] = selected.filter(selected => !!selected.phaseId);
        const autoImportedPhaseIds = swimlanesToImport.map(swimlane => swimlane.phaseId);
        // request improvement - phases of swimlanes are autoincluded
        const phasesToImport = selected.filter(selected => !selected.phaseId && !autoImportedPhaseIds.contains(selected.id));

        try {
          if (phasesToImport.length) await this.apiService.transferPhasesToProject(phasesToImport.map(phase => phase.id));

          if (swimlanesToImport.length)
            await this.apiService.transferSwimlanesToProject(swimlanesToImport.map(swimlane => swimlane.id));

          const phases = await this.apiService.getPhases();
          this.phasesChanged(phases);

          this.userNotification.notify('general.successMsg.transfer');
        } catch (e) {
          this.userNotification.notifyFailedToLoadDataAndLog('general.errorMsg.transfer', e);
        }
      }
    } catch (error) {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.phases', error);
    }
  }

  async loadTemplatesAndSequences() {
    try {
      const [templates, sequences] = await Promise.all([
        Utils.enhanceException(this.apiService.getWorkpackageTemplates(), 'workpackageTemplates'),
        Utils.enhanceException(this.apiService.getSequences(), 'workpackageSequences'),
      ]);

      this._templates = WorkpackageTemplatesHelper.getSortedTemplates(templates);
      this._sequences = sequences
        .filter(sequence => sequence.templates.length > 0)
        .sort(Utils.propertySort(sequence => sequence.name));
    } catch (e) {
      this.userNotification.notifyFailedToLoadDataAndLog(
        'general.errorFailedToLoadDataKeys.' + (e.translationKey ?? 'workpackageTemplates'),
        e
      );
    }

    return { templates: this.templates, sequences: this.sequences };
  }

  override setSelectedWeek(momentInWeek: Moment, saveState: boolean = true) {
    const weekSet = super.setSelectedWeek(momentInWeek);
    if (weekSet && saveState) this.saveSchedulerState();
    return weekSet;
  }

  setPreviewWeeks(areActive: boolean, saveState: boolean = true) {
    this.arePreviewWeeksActiveSubject.next(areActive);
    if (saveState) this.saveSchedulerState();
  }

  filterData(filter: SchedulerFilter, saveState: boolean = true) {
    super.filterData(filter);
    if (saveState) this.saveSchedulerState();
  }

  updateMainSplit(position: SplitPosition) {
    const split = this.split$.value ?? {};
    split.main = position;
    super.updateSplit(split);
    this.saveSchedulerState();
  }

  updateSideSplit(position: SplitPosition) {
    const split = this.split$.value ?? {};
    split.side = position;
    super.updateSplit(split);
    this.saveSchedulerState();
  }

  updateWorkpackages(updatedWorkpackages: LeanWorkpackageModel[]) {
    // needs immediate execution or workpackages for comparison will be overwritten
    this.updateBryntumEvents(updatedWorkpackages);

    const workpackages = this.workpackages$.getValue();
    const changedWorkpackageIds = [];
    for (const workpackage of updatedWorkpackages) {
      const oldWorkpackage = workpackages.find(wp => wp.id == workpackage.id);

      if (oldWorkpackage != null) {
        oldWorkpackage.init(workpackage);
        changedWorkpackageIds.push(workpackage.id);
      }
    }

    if (changedWorkpackageIds.length > 0) {
      this.workpackagesChanged(workpackages);
    }
  }

  protected loadStore(store: string): LeanBryntumRowResponseModel<Model> {
    return super.loadStore(store);
  }

  private saveSchedulerState() {
    // if (!this.isServiceInitialized) return;

    const schedulerState: SchedulerState = {
      filter: this.filter$.value,
      selectedCalenderWeekStart: this.selectedWeek$.value.startOf,
      arePreviewWeeksActive: this.arePreviewWeeksActive$.value,
      // selectedPlan: null, // ToDo
      splitConfig: this.split$.value,
    };

    try {
      this.apiService.createOrUpdateView(
        new PropertyBagModel({
          id: this.schedulerStateViewId,
          key: this.projectId,
          type: PropertyBagType.SchedulerState,
          content: JSON.stringify(schedulerState),
        })
      );
    } catch {
      this.log.error('Could not save current scheduler state');
    }
  }

  private applySchedulerState(state: SchedulerState) {
    super.updateSplit(state.splitConfig ?? {});

    const calendarWeekStart = state.selectedCalenderWeekStart ? moment(state.selectedCalenderWeekStart) : moment();
    const selectedCalendarWeekStart = this.isWithinProjectRang(calendarWeekStart)
      ? calendarWeekStart
      : calendarWeekStart.isAfter(this.endOfProject)
      ? moment(this.endOfProject)
      : moment(this.startOfProject);

    this.setSelectedWeek(selectedCalendarWeekStart, false);
    this.setPreviewWeeks(state.arePreviewWeeksActive, false);
    this.filterData(state.filter, false);
  }
}
