import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/capacitor';
import * as SentryAngular from '@sentry/angular';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';

if (environment.sentryUrl) {
  Sentry.init(
    {
      dsn: environment.sentryUrl,
      environment: environment.sentryEnvironment,
    },
    SentryAngular.init
  );
}

if (environment.production) {
  enableProdMode();
}
// else {
//   console.log('platform', Capacitor.getPlatform());
//   console.log('platform:isNative', Capacitor.isNativePlatform());
// }

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // tslint:disable-next-line:no-console
  .catch(err => {
    console.error('error', err);
  });
