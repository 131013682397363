import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-simple-paginator',
  templateUrl: './simple-paginator.component.html',
  styleUrls: ['./simple-paginator.component.scss'],
})
export class SimplePaginatorComponent {
  @Output() public changePage = new EventEmitter<number>();

  @Input() public isPreviewWeeks = false;
  @Input() public isPreviewWeeksActive = false;
}
