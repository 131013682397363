import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { LegacyFloatLabelType as FloatLabelType } from '@angular/material/legacy-form-field';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { SelectItemGroup } from './select-item-group.model';
import { Utils } from '@app/core';

type FilterList<T extends {}> = T & {
  hidden?: boolean;
  selected?: boolean;
};

function defaultCompareWith(item: any, other: any) {
  return item === other;
}

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchSelectComponent implements OnInit {
  @ViewChild('matSelectSearch', { static: true }) matSelectSearch: MatSelect;
  @ViewChild('matSelectSearchInput', { static: true }) matSelectSearchInput: ElementRef<HTMLInputElement>;

  private _selectList: FilterList<any>[] | SelectItemGroup<FilterList<any>>[] = [];
  get selectList() {
    return this._selectList;
  }

  @Input() title: string;
  @Input() floatLabel: FloatLabelType = 'auto';
  @Input() set selectList(value: FilterList<any>[] | SelectItemGroup<FilterList<any>>[]) {
    this._selectList = value;
    const searchStr = this.matSelectSearchInput.nativeElement.value;
    if (value && !Utils.isNullOrWhitespace(searchStr)) {
      this.filterInput(searchStr.toLowerCase(), value);
    }

    if (this.selectListIsGroup) {
      this.hasItems = value && value.some(x => this.hasVisibleElements(x));
    } else {
      this.hasItems = !!value?.length;
    }
  }
  @Input() selectFormGroup: UntypedFormGroup;
  @Input() selectFormControlName: string;
  @Input() addSelectNone: boolean = false;
  @Input() addSelectNew: boolean = false;
  @Input() optionTemplate?: TemplateRef<any>;
  @Input() triggerTemplate?: TemplateRef<any>;
  @Input() searchFields: string[];
  @Input() displayFields: string[] = ['name'];
  @Input() error: string;
  @Input() multiple: boolean = false;
  @Input() selectListIsGroup: boolean = false;
  @Input() set compareProperty(value: string) {
    this.compareWith = !value ? defaultCompareWith : (item: any, other: any) => item[value] === other[value];
  }
  @Input() addShowAll: boolean = false;
  @Output() loadAllClicked = new EventEmitter();
  @Output() addClicked = new EventEmitter();

  hasItems: boolean = false;
  areAllHidden: boolean = false;

  constructor() {}

  get formControl() {
    return this.selectFormGroup.controls[this.selectFormControlName];
  }

  ngOnInit(): void {}

  compareWith = defaultCompareWith;

  addNew() {
    this.addClicked.emit();
  }

  clearValue() {
    this.formControl.setValue(this.multiple ? [] : null);
    this.formControl.markAsDirty();
  }

  selectionKeyEvent(e: any, elem: HTMLInputElement) {
    elem.focus();
  }

  handleKeyEvent(e: any, list: FilterList<any>[], selectRef: any) {
    if (e.key === 'Enter') {
      const newVal = list.find(l => l.selected);
      if (newVal) {
        this.formControl.patchValue(newVal);
        selectRef.close();
      }
    } else if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      list.forEach(l => (l.selected = false));
    }
  }
  resetSearchSelect(elem: HTMLInputElement, list: FilterList<any>[]) {
    elem.value = null;
    list.forEach(l => {
      l.hidden = false;
      l.selected = false;
    });
    this.areAllHidden = false;
  }

  searchInput(e: any, source: FilterList<any>[] | SelectItemGroup<FilterList<any>>[]) {
    this.filterInput(e.target.value.toLowerCase(), source);
  }

  clearFilter() {
    this.filterInput('', this.selectList);
  }

  filterInput(filterInput: string, source: FilterList<any>[] | SelectItemGroup<FilterList<any>>[]) {
    let noPreselect = true;
    const searchFields = this.searchFields ?? this.displayFields;
    let lists = [];
    if (this.selectListIsGroup) {
      lists = source.map(x => x.items);
    } else {
      lists = [source];
    }

    lists.forEach(list =>
      list.forEach(option => {
        option.hidden =
          !!filterInput &&
          !searchFields.some(field => option[field] && option[field].toString().toLowerCase().indexOf(filterInput) >= 0);
        option.selected = false;
        if (noPreselect && !option.hidden) {
          option.selected = true;
          noPreselect = false;
        }
      })
    );
    this.areAllHidden = lists.every(x => x.every(e => !!e.hidden));
  }

  hasVisibleElements(group: SelectItemGroup<FilterList<any>>): boolean {
    if (!group.items) return false;
    return group.items.some(x => !x.hidden);
  }

  toggle(open: boolean) {
    if (open) this.matSelectSearch.open();
    else this.matSelectSearch.close();
  }

  showAll(e: Event) {
    this.matSelectSearch.open();
    this.loadAllClicked.emit();
  }
}
