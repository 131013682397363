import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { ActiveToggleComponent } from '../active-toggle.component';
import { Subject, filter, map, startWith, takeUntil } from 'rxjs';
import { BaseSubscriptionComponent } from '@app/core';

@Component({
  selector: 'app-active-toggle-group',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./active-toggle-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActiveToggleGroupComponent extends BaseSubscriptionComponent implements AfterContentInit {
  @ContentChildren(ActiveToggleComponent) toggles: QueryList<ActiveToggleComponent>;

  private isRequired: boolean;
  @Input() set required(isRequired: boolean | string) {
    this.isRequired = isRequired === true || isRequired === '';
    this.verifyRequired();
  }

  private _activeToggleIndex: number = null;
  @Input() set activeToggleIndex(activeToggleIndex: number) {
    this._activeToggleIndex = activeToggleIndex;
    this.setToggleActive(this._activeToggleIndex);
    this.verifyRequired();
  }

  private _activeToggleKey: string = null;
  @Input() set activeToggleKey(activeToggleKey: string) {
    this._activeToggleKey = activeToggleKey;
    this.setToggleActive(this._activeToggleKey);
    this.verifyRequired();
  }

  @Output() activeToggleIndexChange = new EventEmitter<number>();
  @Output() activeToggleKeyChange = new EventEmitter<string>();

  private togglesChanged$ = new Subject<void>();

  ngAfterContentInit(): void {
    this.subscribe(
      this.toggles.changes.pipe(
        startWith(this.toggles),
        map((toggles: QueryList<ActiveToggleComponent>) => toggles.toArray())
      ),
      toggles => {
        this.togglesChanged$.next();

        let isOwnEvent = false; // trigger others but not ourselves
        const isKeyChangeSupported = toggles.every(toggle => toggle.key !== undefined);
        for (const toggle of toggles) {
          this.subscribe(
            toggle.isActiveChange.pipe(
              takeUntil(this.togglesChanged$),
              filter(() => !isOwnEvent)
            ),
            isActive => {
              isOwnEvent = true;

              if (!isActive) {
                if (this.isRequired) {
                  // revert since required
                  toggle.toggle();
                } else {
                  this.activeToggleIndexChange.next(-1);
                  if (isKeyChangeSupported) this.activeToggleKeyChange.next(null);
                }
              } else {
                for (const other of toggles) {
                  if (other !== toggle) {
                    if (other.isActive) other.toggle();
                  } else {
                    this.activeToggleIndexChange.next(toggles.indexOf(toggle));
                    if (isKeyChangeSupported) this.activeToggleKeyChange.next(toggle.key);
                  }
                }
              }

              isOwnEvent = false;
            }
          );
        }

        this.setToggleActive(this._activeToggleKey ?? this._activeToggleIndex);
        this.verifyRequired();
      }
    );
  }

  private setToggleActive(identifier: number | string) {
    const toggles = this.toggles?.toArray() ?? [];
    if (toggles.length > 0) {
      if (identifier == null) {
        const toggle = toggles.find(toggle => toggle.isActive);
        toggle?.toggle();
      } else {
        const toggle = typeof identifier === 'number' ? toggles[identifier] : toggles.find(toggle => toggle.key === identifier);
        if (toggle && !toggle.isActive) toggle.toggle();
      }
    }
  }

  private verifyRequired() {
    const toggles = this.toggles?.toArray() ?? [];
    if (this.isRequired && toggles.every(toggle => !toggle.isActive)) {
      const availableToggle = toggles.find(toggle => !toggle.isDisabled);
      if (availableToggle) availableToggle.toggle();
    }
  }
}
