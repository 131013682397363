import { SnapshotAreaModel } from '@app/api';
import * as L from 'leaflet';
import { Observable, Subject } from 'rxjs';

const PrintMinWidth = 120;

interface Snapshot {
  a: L.LatLngLiteral;
  b: L.LatLngLiteral;
}

export class LeafletSnapshotHandler {
  private _isOpen = false;
  private _isDrawing = false;
  private _currentBounds: Snapshot = null;
  private _originalBounds: Snapshot = null;

  private _rectangle = L.rectangle(
    [
      [0, 0],
      [0, 0],
    ],
    { color: 'orange', pmIgnore: true, interactive: false }
  );

  private readonly _onchange = new Subject<Snapshot>();

  constructor(private map: L.Map) {}

  get isOpen() {
    return this._isOpen;
  }

  get bounds(): Snapshot {
    return this._currentBounds;
  }

  get onchange(): Observable<Snapshot> {
    return this._onchange;
  }

  get hasSnapshot() {
    return this.bounds != null && !this._isDrawing;
  }

  setSnapshotArea(bounds: SnapshotAreaModel, emitEvent: boolean = true) {
    if (bounds?.a?.lat && bounds?.a?.lng && bounds?.b?.lat && bounds?.b?.lng) this._currentBounds = bounds as Snapshot;
    else this._currentBounds = null;

    if (this._isOpen) this.drawRect();
    if (emitEvent) this._onchange.next(this._currentBounds);
  }

  open() {
    if (this._isOpen) return;

    this.map.on('click', this.click, this);
    this.map.on('mousemove', this.mousemove, this);
    this.map.on('contextmenu', this.contextmenu, this);

    this.map.dragging.disable();
    this.map.selection.disable();

    this.drawRect();

    this._isOpen = true;
  }

  close() {
    if (!this._isOpen) return;

    this.map.off('click', this.click, this);
    this.map.off('mousemove', this.mousemove, this);
    this.map.off('contextmenu', this.contextmenu, this);

    this.map.dragging.enable();
    this.map.selection.enable();

    if (this.map.hasLayer(this._rectangle)) this._rectangle.remove();

    this._isOpen = false;
  }

  private click(ev: L.LeafletMouseEvent) {
    L.DomEvent.stopPropagation(ev);

    if (!this._isDrawing) {
      this._isDrawing = true;

      this.backupRect();

      this.updateRect({ a: ev.latlng, b: ev.latlng });
    } else if (this._isDrawing) {
      this._isDrawing = false;

      this.updateRect({ b: ev.latlng });

      this._onchange.next(this._currentBounds);
    }
  }

  private mousemove(ev: L.LeafletMouseEvent) {
    L.DomEvent.stopPropagation(ev);

    if (this._isDrawing) {
      this.updateRect({ b: ev.latlng });
    }
  }

  private contextmenu(ev: L.LeafletMouseEvent) {
    L.DomEvent.stopPropagation(ev);

    if (this._isDrawing) {
      this._isDrawing = false;
      this.restoreRect();
    }
  }

  private updateRect({ a, b }: { a?: L.LatLngLiteral; b?: L.LatLngLiteral }) {
    this._currentBounds = this._currentBounds ?? { a: null, b: null };
    this._currentBounds.a = a ?? this._currentBounds.a ?? L.latLng([0, 0]);
    this._currentBounds.b = b ?? this._currentBounds.b ?? L.latLng([0, 0]);
    this.drawRect();
  }

  private backupRect() {
    this._originalBounds = this._currentBounds ? Object.assign({}, this._currentBounds) : null;
  }

  private restoreRect() {
    this._currentBounds = this._originalBounds;
    this.drawRect();
  }

  private drawRect() {
    const isRectVisible = this.map.hasLayer(this._rectangle);
    if (this._currentBounds != null) {
      if (!isRectVisible) this._rectangle.addTo(this.map);
      const { a, b } = this._currentBounds;
      const bounds = L.latLngBounds(a, b);
      this._rectangle.setBounds(bounds);
    } else {
      if (isRectVisible) this._rectangle.remove();
    }
  }
}
