import { Busy } from './busy';
import { BusyScope } from './busy-scope';

import { Disposable } from './disposable';

export * from './busy';
export * from './busy-scope';
export * from './busy-context';

export async function usingScope<T, TResult>(owner: Busy, func: (scope: BusyScope) => Promise<TResult>) {
  const scope = new BusyScope(owner);

  try {
    return await func(scope);
  } finally {
    await scope.dispose();
  }
}

export async function using<T extends Disposable, TResult>(resource: T, func: (resource: T) => Promise<TResult>) {
  try {
    return await func(resource);
  } finally {
    await resource.dispose();
  }
}
