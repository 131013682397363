<mat-form-field class="zone-assignment-form-field" [class.disabled]="isDisabled">
	<mat-chip-list #chipList>
		<mat-chip *ngFor="let zoneId of readonlyZoneIds" color="primary" [selectable]="false" [removable]="false">
			<ng-template [ngTemplateOutlet]="chipLabelTemplate"
									 [ngTemplateOutletContext]="{ $implicit: zoneId }"></ng-template>
		</mat-chip>
		<mat-chip *ngFor="let zoneId of zoneIds" color="primary" [selectable]="false" [removable]="!isDisabled"
							(removed)="removeZone(zoneId)">
			<ng-template [ngTemplateOutlet]="chipLabelTemplate"
									 [ngTemplateOutletContext]="{ $implicit: zoneId }"></ng-template>
			<mat-icon matChipRemove *ngIf="!isDisabled">cancel</mat-icon>
		</mat-chip>
		<input class="chip-list-input" #autoInput [placeholder]="'roomBook.zone.add' | translate"
					 #trigger="matAutocompleteTrigger" [matAutocomplete]="autocomplete" [matChipInputFor]="chipList"
					 [formControl]="filterControl" [disabled]="filterControl.disabled">
	</mat-chip-list>
</mat-form-field>
<div *ngIf="isDisabled" class="c4-disabled-formfield-underline"></div>

<mat-autocomplete autoActiveFirstOption #autocomplete="matAutocomplete"
									(optionSelected)="filterControl.setValue(''); addZone($event.option.value)" [panelWidth]="'24rem'">
	<mat-optgroup *ngFor="let group of filteredZoneGroups | async" [label]="group.name">
		<mat-option *ngFor="let zone of group.zones" [value]="zone">
			{{ zone.name }}
		</mat-option>
	</mat-optgroup>
</mat-autocomplete>

<ng-template #chipLabelTemplate let-zoneId>
	<span>{{ zoneData[zoneId]?.groupName }} / {{ zoneData[zoneId]?.name }}</span>
</ng-template>