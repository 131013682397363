import { Component, Inject, OnInit } from '@angular/core';
import { ConfigState } from '../state-schema.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FormComponent } from '@app/core/utils/form-component';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CustomizationService } from '@app/shared/services';

@Component({
  selector: 'app-edit-state-dialog',
  templateUrl: './edit-state-dialog.component.html',
  styleUrls: ['./edit-state-dialog.component.scss'],
})
export class EditStateDialogComponent<TState> extends FormComponent implements OnInit {
  title = 'states.';
  state: ConfigState<TState>;
  stateTypes: TState[];
  translationPrefix: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private customizationService: CustomizationService,
    private dialogRef: MatDialogRef<EditStateDialogComponent<TState>>,
    private formBuilder: UntypedFormBuilder
  ) {
    super();

    this.title += data?.state ? 'edit' : 'add';
    this.state = data?.state;
    this.stateTypes = data?.stateTypes ?? [];
    this.translationPrefix = data?.translationPrefix ?? '';
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      hexColor: [this.state?.color ?? this.customizationService.primaryHexColor],
      code: [this.state?.code ?? '', [Validators.required, Validators.pattern(/\S/)]],
      title: [this.state?.title ?? '', [Validators.required, Validators.pattern(/\S/)]],
      stateType: [this.state?.stateType ?? this.stateTypes[0], [Validators.required]],
    });
  }

  confirm() {
    if (this.form.valid) {
      const state = this.parseForm();
      this.dialogRef.close(state);
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  private parseForm(): ConfigState<TState> {
    return {
      ...this.state,
      color: this.f.hexColor.value,
      code: this.f.code.value.trim(),
      title: this.f.title.value.trim(),
      stateType: this.f.stateType.value,
    };
  }
}
