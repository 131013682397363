import { Component, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { PcfDefect, PcfRole, PcfRoles, ProjectConfigService } from '@app/shared/services/project-config';
import { StateSchemaRole, StateSchemaService } from '../../state-matrix';
import { BaseSubscriptionComponent, FormUtils } from '@app/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DefectState } from '@app/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-defect-config',
  templateUrl: './defect-config.component.html',
  styleUrls: ['./defect-config.component.scss'],
})
export class DefectConfigComponent extends BaseSubscriptionComponent implements OnInit {
  stateSchemaService: StateSchemaService<DefectState>;

  constructor(
    private configService: ProjectConfigService,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {
    super();
  }

  get form() {
    return this.configService.defectForm;
  }

  get canProvision() {
    return this.configService.canProvision;
  }

  get states() {
    return FormUtils.getFormControl<PcfDefect, UntypedFormArray>(this.form, 'states');
  }

  ngOnInit() {
    const defectStates = Object.values(DefectState);
    const mapRoles = (roles: PcfRole[]) => roles.map(r => this.configService.roleAndUserData[r.id] ?? r);
    const rolesForm = FormUtils.getFormControl<PcfRoles, UntypedFormArray>(this.configService.rolesForm, 'roles');
    const roles = rolesForm.value ?? [];
    this.stateSchemaService = new StateSchemaService<DefectState>(
      this.states,
      defectStates,
      this.configService.defectStatePredefinedRoles.concat(mapRoles(roles)),
      this.configService.defectStatePermissions,
      'defects.state.',
      this.dialog,
      this.translateService
    );

    this.subscribe(rolesForm.valueChanges, (updatedRoles: PcfRole[]) => {
      this.stateSchemaService.setRoles(this.configService.defectStatePredefinedRoles.concat(mapRoles(updatedRoles)));
      this.stateSchemaService.permissions = this.configService.defectStatePermissions;
    });
  }
}
