import { Component, OnInit, Inject, TemplateRef } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit {
  title: string;
  description: string;
  params: any;
  dlgClass: string;
  contentTemplate?: TemplateRef<any>;
  contentData?: any[];
  acceptButtonLabel: string = 'general.ok';
  cancelButtonLabel: string = 'general.cancel';

  constructor(public confirmRef: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.title = data.title ?? 'TITLE_NOT_SET';
    this.description = data.description ?? null;
    this.params = data.params ?? undefined;
    this.dlgClass = data.dlgClass ?? 'unset';
    this.contentTemplate = data.contentTemplate ?? null;
    this.contentData = data.contentData ?? null;
    this.acceptButtonLabel = data.acceptButtonLabel ?? 'general.ok';
    this.cancelButtonLabel = data.cancelButtonLabel ?? 'general.cancel';
  }

  ngOnInit() {}

  ok() {
    this.confirmRef.close(true);
  }

  cancel() {
    this.confirmRef.close(false);
  }
}
