import { NgxMatColorPickerComponent } from '@angular-material-components/color-picker';
import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { GlobalsService, getColorHexString, hexStringToColor } from '@app/core';
import { FormComponent } from '@app/core/utils/form-component';

@Component({
  selector: 'app-color-picker-form-field',
  templateUrl: './color-picker-form-field.component.html',
  styleUrls: ['./color-picker-form-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ColorPickerFormFieldComponent,
    },
  ],
})
export class ColorPickerFormFieldComponent extends FormComponent implements ControlValueAccessor, OnInit {
  @Input() label: string;
  @Input() showFallback = true;
  @Input() set isRequired(isRequired: boolean) {
    this.f.color.setValidators(isRequired ? [Validators.required] : []);
  }

  hexColor: string;
  touchUi = this.globals.isMobile;

  constructor(formBuilder: UntypedFormBuilder, private globals: GlobalsService) {
    super();

    this.form = formBuilder.group({
      color: [null],
    });
  }

  ngOnInit() {
    this.f.color.valueChanges.subscribe(color => {
      this.hexColor = getColorHexString(color);
      this.onChange(this.hexColor);
    });
  }

  openColorPicker(picker: NgxMatColorPickerComponent) {
    this.markAsTouched();
    picker.open();
  }

  removeColor() {
    this.markAsTouched();
    this.f.color.setValue(null);
  }

  // ----- Reactive Forms Methods -----

  onChange = (_: string) => {};
  onTouched: EmptyCallback = () => {};
  private isTouched: boolean = false;

  writeValue(hexColor: string): void {
    this.f.color.setValue(hexStringToColor(hexColor));
  }

  registerOnChange(fn: (hexColor: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: EmptyCallback): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) this.f.color.disable();
    else this.f.color.enable();
  }

  private markAsTouched() {
    if (!this.isTouched) {
      this.form.markAllAsTouched();
      this.onTouched();
      this.isTouched = true;
    }
  }
}
