import { Component, EventEmitter, Host, Input, OnInit, Output } from '@angular/core';
import { GeojsonEditorComponent } from './geojson-editor.component';

import * as L from 'leaflet';

import { MaximumBounds, TILE_SIZE } from './interfaces';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-geojson-editor-img-layer',
  template: ``,
})
export class GeojsonEditorImgLayerComponent implements OnInit {
  private m_url: SafeUrl | null = null;
  private m_layer: L.ImageOverlay | null = null;
  private m_opacity: number = 1.0;
  private m_zoomBounds: number = 1.25;

  constructor(@Host() private parent: GeojsonEditorComponent) {
    const subscription = parent.onready.subscribe(x => {
      subscription.unsubscribe();

      this.attach();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.detach();
  }

  @Output() load = new EventEmitter();
  @Output() error = new EventEmitter();

  @Input('url')
  public set url(value: SafeUrl | null) {
    if (this.m_url == value) return;

    this.detach();

    this.m_url = value;

    this.attach();
  }

  public get url() {
    return this.m_url;
  }

  @Input('opacity')
  public set opacity(value: number) {
    if (this.m_opacity == value) return;

    this.m_opacity = value;

    if (this.m_layer) {
      this.m_layer.setOpacity(value);
    }
  }

  public get opacity() {
    return this.m_opacity;
  }

  private detach() {
    if (this.m_layer) {
      this.m_layer.remove();
      this.m_layer = null;
    }
  }

  private attach() {
    const map = this.parent.map!;

    if (this.m_url && map) {
      const bounds = L.latLngBounds([
        [0, 0],
        [0, 0],
      ]);
      const { changingThisBreaksApplicationSecurity } = this.m_url as any;
      this.m_layer = L.imageOverlay(changingThisBreaksApplicationSecurity || (this.m_url as string), bounds, {
        pmIgnore: true,
        opacity: this.m_opacity,
      });

      this.load.emit();

      this.m_layer.on('error', x => {
        this.error.emit();
      });

      this.m_layer.on('load', x => {
        if (this.m_layer) {
          this.load.emit();

          const element = this.m_layer.getElement();
          if (element?.naturalWidth && element?.naturalHeight) {
            const w = element.naturalWidth;
            const h = element.naturalHeight;

            let [x, y] = w < h ? [TILE_SIZE, TILE_SIZE * (w / h)] : [TILE_SIZE * (h / w), TILE_SIZE];

            x *= this.m_zoomBounds;
            y *= this.m_zoomBounds;

            const bounds = L.latLngBounds([
              [-x, -y],
              [+x, +y],
            ]);

            this.m_layer.setBounds(bounds);
          }
        }
      });

      this.m_layer.addTo(map);
    }
  }
}
