import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import '@geoman-io/leaflet-geoman-free';
import { IMetadata, MaximumBounds, ShapeTypes, Toggleable } from './interfaces';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslationManagementService, Utils } from '@app/core';
import { NgxMatColorPickerComponent } from '@angular-material-components/color-picker';
import { LeafletFreedraw } from './LeafletFreedraw';
import { GeoJSONFeature, GeoJSONType, IGeoJSONFeature, IGeoJSONFeatureCollection, SnapshotAreaModel } from '@app/api';
import { UserNotificationService } from '@app/shared/services';
import { setupFeatureInformations } from './setupFeatureInformations';
import { contrast } from '@app/shared/utils/color-helper';
import 'leaflet-arrowheads';
import { ARROW_HEAD_OPTIONS, LeafletArrow } from './LeafletArrow';
import { LeafletMarker } from './LeafletMarker';

import { LeafletSnapshotHandler } from './LeafletSnapshotHandler';

const DEFAULT_GEOSJON_PADDING: number = 3;
const DEFAULT_GEOJSON_FONTSIZE: number = 13;

export interface IGeoJsonChange {
  features: IGeoJSONFeature[];
  oldFeatures: IGeoJSONFeature[];
}

@Component({
  selector: 'app-geojson-editor',
  template: `
    <div *ngIf="isSnapshotModeEnabled" class="snapshot-controls">
      <button *ngIf="hasSnapshot" mat-raised-button (click)="removeSnapshot()">
        {{ 'lean.simulation.snapshot.remove' | translate }}
      </button>
      <button mat-raised-button (click)="toggleSnapshotEdit(false)">
        {{ 'lean.simulation.snapshot.leaveEdit' | translate }}
      </button>
    </div>
    <div class="leaflet-container"></div>
    <input matInput [ngxMatColorPicker]="colorPicker" #colorInput value="#3388ff" style="display: none" />
    <ngx-mat-color-picker #colorPicker></ngx-mat-color-picker>
  `,
  styleUrls: ['./geojson-editor.component.scss'],
})
export class GeojsonEditorComponent implements OnInit, OnDestroy {
  private _allowEditing = true;
  @Input() set allowEditing(allowEditing: boolean) {
    this._allowEditing = allowEditing;

    if (this.map) {
      if (allowEditing && this.drawableContext) {
        this.toggleControls(true);
      } else {
        this.disableShapeEditModesIfActive();
        this.map.pm.disableDraw();
        this.toggleControls(false);
      }
    }
  }
  @Input() set drawableContext(value: L.corner4.MappingType | null) {
    this._drawableContext = value;

    if (this.map) {
      this.map.pm.disableDraw();

      this.patchColorsByContext();
      if (value && this._allowEditing) {
        this.toggleControls(true);
      } else {
        this.map.pm.disableDraw();
        this.toggleControls(false);
      }
    }
  }

  @Input() set markers(value: IGeoJSONFeatureCollection | null) {
    const featureCollection = value;

    this._markers = featureCollection;

    if (this.map) {
      this.zone.runOutsideAngular(() => {
        this.restore(featureCollection);
      });
    }
  }

  @Input() public showColorPicker = true;
  @Input() public initialLevel: number | null = null;

  @Output() public readonly onready = new EventEmitter<GeojsonEditorComponent>();
  @Output() public readonly markersChange = new EventEmitter<IGeoJsonChange>();
  @Output() public readonly snapshotChange = new EventEmitter<SnapshotAreaModel>();
  @Output() public readonly selectionChange = new EventEmitter<L.corner4.SelectionChangeEvent>();

  private markerClusterGroup: L.MarkerClusterGroup;

  private _snapshotArea: SnapshotAreaModel | null = null;
  private _matchingContext: L.corner4.MappingType | null = null;
  private _drawableContext: L.corner4.MappingType | null = null;

  private _zoomPercentage: number = 100;
  get zoomPercentage() {
    return this._zoomPercentage;
  }

  set zoomPercentage(v: number) {
    this._zoomPercentage = v;
    if (this.zoomElement) this.zoomElement.innerText = `${v}%`;
  }

  private zoomElement: HTMLElement;

  get drawableContext() {
    return this._drawableContext;
  }

  private _markers: IGeoJSONFeatureCollection | null;

  get markers() {
    return this._markers;
  }

  get isRemovalModeEnabled(): boolean {
    return this.map?.pm?.globalRemovalModeEnabled() ?? false;
  }

  get isEditModeEnabled(): boolean {
    return this.map?.pm?.globalEditModeEnabled() ?? false;
  }

  get isDragModeEnabled(): boolean {
    return this.map?.pm?.globalDragModeEnabled() ?? false;
  }

  get isSnapshotModeEnabled() {
    return this.snapshot?.isOpen ?? false;
  }

  get hasSnapshot() {
    return this.snapshot?.hasSnapshot ?? false;
  }

  get bounds() {
    return this.map?.getBounds();
  }

  get snapshotArea() {
    return this._snapshotArea;
  }

  @Input() set snapshotArea(value: SnapshotAreaModel) {
    this._snapshotArea = Object.assign({}, value);
    this.snapshot?.setSnapshotArea(value, false);
  }

  @ViewChild('colorPicker')
  colorPicker: NgxMatColorPickerComponent;

  @ViewChild('colorInput')
  colorInput: ElementRef<HTMLInputElement>;

  map: L.Map | null = null;
  transform;

  constructor(
    private elementRef: ElementRef,
    private zone: NgZone,
    private dialog: MatDialog,
    private translate: TranslationManagementService,
    private userNotificationService: UserNotificationService
  ) {
    this.markerClusterGroup = L.markerClusterGroup({
      pmIgnore: true,
    });
  }

  ngOnInit(): void {
    this.zone.runOutsideAngular(() => {
      this.createMap();
    });

    this.setLang(this.translate.language);
    this.translate.onLangChange.subscribe(event => {
      this.setLang(event.lang);
    });
  }

  selectShapes(shapes: L.Path[]) {
    this.map.selection.select(shapes, { silent: true });
  }

  private disableShapeEditModesIfActive() {
    if (this.isRemovalModeEnabled) this.map.pm.disableGlobalRemovalMode();
    if (this.isEditModeEnabled) this.map.pm.disableGlobalEditMode();
    if (this.isDragModeEnabled) this.map.pm.disableGlobalDragMode();
  }

  selectMatchingElements(data: L.corner4.MappingType | null) {
    this._matchingContext = data;

    if (!data) {
      this.disableShapeEditModesIfActive();

      this.map.selection.select([], { silent: true });

      return;
    }

    if (this.isSnapshotModeEnabled) this.snapshot?.close();

    const shapes: L.Path[] = [];
    const entries = Object.entries(data);

    this.map.eachLayer(shape => {
      if (L.corner4.isLayerWithFeature(shape) && shape instanceof L.Path) {
        const { properties } = shape.feature || {};

        if (!properties) {
          return;
        }

        for (const [key, value] of entries) {
          if (key in properties && properties[key] == value) {
            shapes.push(shape);
          }
        }
      }
    });

    this.map.selection.select(shapes, { silent: true });
  }

  fitBounds(a: L.LatLngBounds) {
    this.map?.fitBounds(a, {
      padding: [0, 0],
      duration: 0,
      animate: false,
      noMoveStart: true,
      easeLinearity: 0,
    });
  }

  private setLang(language: string) {
    switch (language) {
      case 'de-DE':
        this.map.pm.setLang(
          'de',
          {
            buttonTitles: {
              drawLineButton: this.translate.instant('leaflet.line'),
              drawTextButton: this.translate.instant('leaflet.text'),
            },
          },
          'de'
        );
        break;
      case 'en-US':
        this.map.pm.setLang(
          'en',
          {
            buttonTitles: {
              drawLineButton: this.translate.instant('leaflet.line'),
              drawTextButton: this.translate.instant('leaflet.text'),
            },
          },
          'en'
        );
        break;
    }
  }

  private toggleControls(areActive: boolean) {
    if (areActive) {
      this.map.pm.addControls({
        drawMarker: false,
        cutPolygon: false,
        rotateMode: false,
      });
    } else {
      this.map.pm.removeControls();
    }
  }

  ngOnDestroy() {
    this.map.remove();
  }

  toggleSnapshotEdit(open: boolean = null) {
    if (open === null) open = !this.isSnapshotModeEnabled;
    if (open) {
      this.disableShapeEditModesIfActive();
      this.map.selection.select([]);
      this.snapshot?.open();
    } else {
      this.snapshot?.close();
    }
  }

  removeSnapshot() {
    this.snapshot?.setSnapshotArea(null);
  }

  private snapshot?: LeafletSnapshotHandler;
  private createMap() {
    const host = this.elementRef.nativeElement;

    const container = host.querySelector('.leaflet-container');

    const map = (this.map = L.map(container, {
      attributionControl: false,
      maxBounds: MaximumBounds,
      pmIgnore: false,
      center: [0, 0],
      minZoom: 0,
      maxZoom: 4,
      zoomDelta: 0.25,
      zoomSnap: 0,
      wheelPxPerZoomLevel: 256,
      zoom: this.initialLevel || 0,
      crs: L.CRS.Simple,
    }));

    this.markerClusterGroup.addTo(this.map);

    const { arrow, marker, freedraw, snapshot } = this.addCustomControls();

    this.snapshot = snapshot;

    snapshot.onchange.subscribe(bounds => {
      if (Utils.isNullOrUndefined(bounds)) {
        this._snapshotArea = null;
        this.snapshotChange.emit(null);
      } else {
        const value = SnapshotAreaModel.fromJS(bounds);
        this._snapshotArea = value;
        this.snapshotChange.emit(value);
      }
    });

    map.whenReady(() => {
      const c = document.querySelector('.leaflet-control-zoom.leaflet-bar.leaflet-control');
      this.zoomElement = document.createElement('span');
      this.zoomElement.classList.add('zoom-indicator');
      this.zoomElement.innerText = `${this.zoomPercentage}%`;
      c.appendChild(this.zoomElement);
    });

    map.on('pm:remove', args => {
      this.persist();
    });

    map.on('pm:drawstart', e => {
      map.selection.disable();
    });

    map.on('pm:drawend', () => {
      map.selection.enable();
    });

    let layers, height, width, originalZoom, textArea, fontSize, padding;
    map.on('zoomanim', ev => {
      this.zoomPercentage = Math.floor(ev.zoom * 100) + 100;
      layers = ev.target._layers;
      Object.values(layers).forEach((layer: any) => {
        if (layer instanceof L.Marker && layer.feature && layer.feature.properties.type == ShapeTypes.text) {
          height = layer.feature.properties.originalHeight;
          width = layer.feature.properties.originalWidth;
          textArea = layer.pm.getElement();

          originalZoom = layer.feature.properties.originalZoom;
          textArea.style.width = width * Math.pow(2, ev.zoom - originalZoom) + 'px';
          textArea.style.height = height * Math.pow(2, ev.zoom - originalZoom) + 'px';
          fontSize =
            (layer.feature.properties.fontSizeInPx ? layer.feature.properties.fontSizeInPx : DEFAULT_GEOJSON_FONTSIZE) *
              Math.pow(2, ev.zoom - originalZoom) +
            'px';
          padding =
            (layer.feature.properties.paddingInPx ? layer.feature.properties.paddingInPx : DEFAULT_GEOSJON_PADDING) *
              Math.pow(2, ev.zoom - originalZoom) +
            'px';

          textArea.style.setProperty('font-size', fontSize);
          textArea.style.setProperty('padding', padding);
        }
      });
    });

    map.on('selection:change', ev => {
      const canChangeSelection =
        !this.isEditModeEnabled && !this.isDragModeEnabled && !this.isRemovalModeEnabled && !this.isSnapshotModeEnabled;
      if (canChangeSelection) this.selectionChange.emit(ev);
      else this.selectShapes(ev.previousSelection);
    });

    map.on('pm:create', args => {
      const addedLayer = args.layer;

      if (addedLayer instanceof L.Marker) {
        setupFeatureInformations(addedLayer, args.shape, this.colorInput.nativeElement.value, this._drawableContext);
      }

      if (addedLayer instanceof L.CircleMarker) {
        setupFeatureInformations(addedLayer, args.shape, this.colorInput.nativeElement.value, this._drawableContext);
      }

      if (addedLayer instanceof L.Polyline) {
        setupFeatureInformations(addedLayer, args.shape, this.colorInput.nativeElement.value, this._drawableContext);
      }

      if (addedLayer instanceof L.Path && L.corner4.isLayerWithFeature(addedLayer)) {
        const { color } = addedLayer.feature.properties;

        if (typeof color == 'string') {
          addedLayer.setStyle({
            color: color,
          });
        }
      } else if (addedLayer instanceof L.Marker) {
        const { color, type }: { color: string; type: ShapeTypes } = addedLayer.feature.properties;
        if (type == ShapeTypes.text) {
          const container = addedLayer.pm.getElement();

          addedLayer.feature.properties.originalZoom = map.getZoom();
          addedLayer.pm.setText((this._drawableContext?.description ?? '') as string);

          addedLayer.feature.properties.originalHeight = parseFloat(container.style.getPropertyValue('height'));
          addedLayer.feature.properties.originalWidth = parseFloat(container.style.getPropertyValue('width'));

          container.style.setProperty('font-size', DEFAULT_GEOJSON_FONTSIZE + 'px');
          container.style.setProperty('padding', DEFAULT_GEOSJON_PADDING + 'px');
          addedLayer.feature.properties.fontSizeInPx = parseFloat(container.style.getPropertyValue('font-size'));
          addedLayer.feature.properties.paddingInPx = parseFloat(container.style.getPropertyValue('padding'));

          container.style.setProperty('--pm-background-color', color);
          container.style.setProperty(
            '--pm-foreground-color',
            contrast(Utils.cssEvalCssValue(color, this.elementRef.nativeElement), 'color')
          );
        }
      }

      this.attachChangeDetection(addedLayer);

      if (args.shape == 'Text') {
        const element = (<any>addedLayer).pm.getElement() as HTMLTextAreaElement;
        element.addEventListener('keydown', e => {
          e.stopPropagation();
        });
        element.addEventListener('blur', e => {
          this.persist();
        });
      } else {
        this.persist();
      }
    });

    this.resize();
    this.onready.emit(this);

    if (this._markers) {
      this.zone.runOutsideAngular(() => {
        this.restore(this._markers);
      });
    }
    if (this._drawableContext) {
      this.toggleControls(this._allowEditing);
      this.patchColorsByContext();
    }
  }

  private patchColorsByContext() {
    const color = this._drawableContext?.color;
    if (typeof color == 'string') {
      this.patchColorsCore(color);
    } else {
      this.patchColorsCore(null);
    }
  }

  private patchColorsCore(color: string | null) {
    this.map.pm.setPathOptions({
      color: color,
      fillColor: color,
    });
    this.map.pm.Draw.setPathOptions({
      color: color,
      fillColor: color,
    });
    this.map.pm.Draw.setOptions({
      pathOptions: {
        color: color,
        fillColor: color,
      },
      templineStyle: {
        radius: 1,
        color: color,
        fillColor: color,
      },
      hintlineStyle: {
        color: color,
        fillColor: color,
      },
    });
  }

  @HostListener('window:resize')
  resize() {
    const map = this.map;
    if (map) {
      this.zone.runOutsideAngular(() => {
        window.setTimeout(() => {
          map.invalidateSize({
            animate: false,
          });
        }, 25);
      });
    }
  }

  // Temporary fix for snapping problem
  // Remove when this is resolved: https://github.com/geoman-io/leaflet-geoman/issues/1378
  @HostListener('keyup.Alt', ['$event'])
  onKeyUpAlt(e) {
    e.preventDefault();
  }

  private persist() {
    const features = new Array<GeoJSONFeature>();
    const oldFeatures = this._markers?.features || [];

    this.map?.eachLayer(function (customLayer: L.Layer) {
      if (L.corner4.isLayerWithFeature(customLayer) && customLayer.feature) {
        const { type } = customLayer.feature.properties;

        if (Object.values(ShapeTypes).includes(type)) {
          const data = customLayer.toGeoJSON();
          if (customLayer instanceof L.Marker) {
            data.properties.text = customLayer.options.text;
          }

          features.push(data);
          return;
        } else {
          // console.warn('drop feature (by type)', customLayer, type);
        }
      } else {
        // console.warn('drop feature', customLayer);
      }
    });

    this._markers = {
      type: GeoJSONType.FeatureCollection,
      features: features,
    };

    this.markersChange.emit({
      oldFeatures,
      features,
    });
  }

  private restore(featureCollection: IGeoJSONFeatureCollection) {
    this.map.eachLayer(function (foundLayer) {
      if (L.corner4.isLayerWithFeature(foundLayer)) {
        foundLayer.remove();
      }
    });

    if (!featureCollection || !featureCollection.type || !featureCollection.features) {
      return;
    }

    L.geoJSON({ type: featureCollection.type, features: featureCollection.features.map(x => Object.assign({}, x)) } as any, {
      pointToLayer: (feature, coordinates) => {
        const { type, description } = feature.properties;

        if (type == ShapeTypes.text) {
          const { text } = feature.properties;
          let marker = L.marker(coordinates, {
            text: text,
            textMarker: true,
          });

          let originalZoom = feature.properties.originalZoom;
          let container = marker.pm.getElement();
          let width = feature.properties.originalWidth;
          let height = feature.properties.originalHeight;

          setTimeout(() => {
            container.style.setProperty('width', width * Math.pow(2, this.map.getZoom() - originalZoom) + 'px');
            container.style.setProperty('height', height * Math.pow(2, this.map.getZoom() - originalZoom) + 'px');

            let fontSize =
              (feature.properties.fontSizeInPx ? feature.properties.fontSizeInPx : DEFAULT_GEOJSON_FONTSIZE) *
                Math.pow(2, this.map.getZoom() - originalZoom) +
              'px';
            let padding =
              (feature.properties.paddingInPx ? feature.properties.paddingInPx : DEFAULT_GEOSJON_PADDING) *
                Math.pow(2, this.map.getZoom() - originalZoom) +
              'px';
            container.style.setProperty('font-size', fontSize);
            container.style.setProperty('padding', padding);
          }, 0);

          return marker;
        } else if ((type == ShapeTypes.point || type == ShapeTypes.defectMarker) && description) {
          return this.createMarker({
            properties: feature.properties,
            position: coordinates,
          });
        } else if (type == ShapeTypes.circle) {
          const { radius } = feature.properties;

          return L.circle(coordinates, {
            radius: radius,
          });
        } else if (type == ShapeTypes.circleMarker) {
          const { radius } = feature.properties;

          return L.circleMarker(coordinates, {
            radius: radius,
          });
        }

        return L.marker(coordinates);
      },
      onEachFeature: (feature, addedLayer) => {
        const { color } = feature.properties;
        if (typeof color == 'string') {
          if (addedLayer instanceof L.Path) {
            addedLayer.setStyle({
              color: color,
            });

            if (feature.properties.type == ShapeTypes.arrow && addedLayer instanceof L.Polyline) {
              addedLayer.arrowheads(ARROW_HEAD_OPTIONS);
            }
          } else if (addedLayer instanceof L.Marker) {
            const { type }: { type: ShapeTypes } = addedLayer.feature.properties;
            if (type == ShapeTypes.text) {
              const container = addedLayer.pm.getElement();

              container.style.setProperty('--pm-background-color', color);
              container.style.setProperty(
                '--pm-foreground-color',
                contrast(Utils.cssEvalCssValue(color, this.elementRef.nativeElement), 'color')
              );
            }
          }
        }

        this.attachChangeDetection(addedLayer);
      },
    })
      .once('add', () => {
        if (this._matchingContext) {
          this.selectMatchingElements(this._matchingContext);
        }
      })
      .addTo(this.map);
  }

  private attachChangeDetection(layer: L.Layer) {
    layer.on('pm:markerdragend', () => {
      this.updateLayer(layer);
    });

    layer.on('pm:dragend', () => {
      this.updateLayer(layer);
    });

    layer.on('pm:textblur', () => {
      this.updateLayer(layer);
    });
  }

  private updateLayer(layer: L.Layer) {
    if (layer instanceof L.Marker && layer.feature.properties.type == ShapeTypes.text) {
      let container = layer.pm.getElement();
      layer.feature.properties.originalZoom = this.map.getZoom();
      layer.feature.properties.originalHeight = parseFloat(container.style.getPropertyValue('height'));
      layer.feature.properties.originalWidth = parseFloat(container.style.getPropertyValue('width'));
      layer.feature.properties.fontSizeInPx = parseFloat(container.style.getPropertyValue('font-size'));
      layer.feature.properties.paddingInPx = parseFloat(container.style.getPropertyValue('padding'));
    }

    if (L.corner4.isLayerWithFeature(layer)) {
      layer.feature.properties = {
        ...layer.feature.properties,

        modifiedOn: new Date(),
      };
    }

    this.persist();
  }

  private createMarker(metadata: IMetadata) {
    const properties = metadata.properties;

    const p = document.createElement('p');
    const div = document.createElement('div');
    const button = document.createElement('button');

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

    svg.setAttribute('width', '50');
    svg.setAttribute('height', '50');
    svg.setAttribute('class', 'marker-icon');
    svg.setAttribute('version', '1.1');
    svg.setAttribute('viewBox', '0 0 100 100');
    svg.setAttribute('preserveAspectRatio', 'none');

    const circle = document.createElementNS('http://www.w3.org/2000/svg', 'circle');

    circle.style.strokeWidth = '6';

    circle.setAttribute('r', '40');
    circle.setAttribute('cx', '50');
    circle.setAttribute('cy', '50');

    svg.append(circle);

    const icon = L.divIcon({
      html: svg as any,
      className: 'geojson-marker-icon',
      iconSize: [48, 48],
      iconAnchor: [24, 24],
    });

    const description = typeof properties.description == 'string' ? properties.description : '<Missing Description>';
    const marker = L.marker(metadata.position, {
      text: description,
      icon,
    });

    setupFeatureInformations(marker, 'DefectMarker', this.colorInput?.nativeElement?.value, metadata.properties);

    div.append(p);
    div.append(button);

    p.innerText = description;

    button.classList.add(...'mat-focus-indicator mat-flat-button mat-button-base mat-primary'.split(' '));
    button.innerText = this.translate.instant('general.delete');
    button.onclick = () => {
      button.onclick = null;

      marker.remove();

      this.persist();
    };

    circle.style.stroke = marker.feature.properties.color;

    const popup = L.popup({
      closeButton: false,
    });

    popup.setContent(div);

    marker.bindPopup(popup);

    return marker;
  }

  private addCustomControls() {
    const map = this.map;

    const arrowHandler = new LeafletArrow(map);
    const markerHandler = new LeafletMarker(map, this.translate, this.dialog, (metadata: IMetadata) =>
      this.createMarker(metadata)
    );
    const freedrawHandler = new LeafletFreedraw(map);

    let arrowControl: Toggleable;
    let markerControl: Toggleable;

    arrowHandler.onchange.subscribe(arrow => {
      arrowControl.toggle(false);

      this.persist();
      this.attachChangeDetection(arrow);
    });

    markerHandler.onchange.subscribe(marker => {
      markerControl?.toggle(false);

      this.persist();
      this.attachChangeDetection(marker);
    });

    freedrawHandler.onchange.subscribe(() => {
      this.persist();
    });

    const arrowKey = 'cst-arrow';
    const colorKey = 'cst-color';
    const freeDrawKey = 'cst-freedraw-button';

    const snapshotHandler = new LeafletSnapshotHandler(map);
    snapshotHandler.setSnapshotArea(this._snapshotArea, false);

    map.pm.Toolbar.createCustomControl({
      name: freeDrawKey,
      className: 'leaflet-cst-icon-freedraw',
      title: this.translate.instant('leaflet.freedraw.title'),
      actions: [
        {
          text: this.translate.instant('leaflet.finish'),
          onClick: function () {
            freedrawHandler.complete();
            this.toggle(false);
          },
        },
      ],
      toggle: true,
    });

    arrowControl = map.pm.Toolbar.createCustomControl({
      name: arrowKey,
      title: this.translate.instant('leaflet.arrow.title'),
      className: 'leaflet-cst-icon-arrow',
      actions: [
        {
          text: this.translate.instant('leaflet.cancel'),
          onClick: function () {
            arrowHandler.cancel();
            this.toggle(false);
          },
        },
      ],
      toggle: true,
    }) as any;

    if (this.showColorPicker) {
      map.pm.Toolbar.createCustomControl({
        name: colorKey,
        title: this.translate.instant('leaflet.color-picker.title'),
        toggle: false,
        className: 'leaflet-cst-icon-colorpicker',
      });
    }

    /* map.pm.Toolbar.createCustomControl({
      name: 'cst-clear',
      title: this.translate.instant('leaflet.eraser.title'),
      toggle: false,
      className: 'leaflet-cst-icon-eraser',
      onClick: () => {
        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
          data: {
            title: 'leaflet.eraser.confirmCaption',
            description: 'leaflet.eraser.confirmDescription',
            params: {},
          },
        });
        dialogRef.afterClosed().forEach(result => {
          if (!result) {
            return;
          }

          for (const layer of map.pm.getGeomanLayers(false) as L.Layer[]) {
            layer.remove();
          }
          this.persist();
        });
      },
    }); */

    // map.on('pm:actionclick', e => {
    //   use e.btnName eg. if (e.btnName == freeDrawKey) ...
    // });

    map.on('pm:buttonclick', e => {
      let startFreedraw = false;
      if (freedrawHandler?.drawing) {
        freedrawHandler.complete();
      } else if (e.btnName == freeDrawKey) {
        // cannot start here because arrowHandler.cancel would enable dragging of map
        startFreedraw = true;
      }

      let startMarker = false;
      if (markerHandler?.drawing) {
        markerHandler.complete();
      } else if (e.btnName == markerHandler.key) {
        startMarker = true;
      }

      let startArrow = false;
      if (arrowHandler?.drawing) {
        arrowHandler.cancel();
      } else if (e.btnName == arrowKey) {
        startArrow = true;
      }

      if (startFreedraw) freedrawHandler.start(this.drawableContext, this.colorInput.nativeElement.value);
      else if (startArrow) arrowHandler.start(this.drawableContext, this.colorInput.nativeElement.value);
      else if (startMarker) markerHandler.start(this.drawableContext, this.colorInput.nativeElement.value);

      if (e.btnName == colorKey) {
        if ('color' in this.drawableContext) {
          this.userNotificationService.notify('planning.notifications.customColorNotSupported');
        } else {
          this.colorPicker.open();
        }
      }
    });

    return {
      arrow: arrowHandler,
      marker: markerHandler,
      freedraw: freedrawHandler,
      snapshot: snapshotHandler,
    };
  }
}
