import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { TreeNode } from 'primeng/api';

export enum TreeSelectSelectionMode {
  single = 'single',
  multiple = 'multiple',
  checkbox = 'checkbox',
}

@Component({
  selector: 'app-tree-selection',
  templateUrl: './tree-selection.component.html',
  styleUrls: ['./tree-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TreeSelectionComponent,
    },
  ],
})
export class TreeSelectionComponent<T extends ParentModel<T>> implements ControlValueAccessor {
  @Input() title: string;
  @Input() selectKey = (model: T) => model.id;
  @Input() selectLabel = (model: T) => model.name;
  @Input() selectionMode: TreeSelectSelectionMode = TreeSelectSelectionMode.single;
  @Input() childrenSelector: string = 'children';
  @Input() canSelectLeafsOnly: boolean = false;
  @Input() set models(models: T[]) {
    this.tree = this.createTree(models);
    this.setSelectedNode(this.initialValue);
  }
  @Input() errors: ValidationErrors;
  @Input() propagateSelection: boolean = false;
  /** sets the minimal level at which nodes can be selected */
  // @Input() minSelectionLevel: number = 0;

  isDisabled: boolean;
  tree: TreeNode<T>[];

  onChange = (keys: string | string[]) => {};
  onTouched = () => {};

  private initialValue: string | string[];
  private selectedNode: TreeNode<T>;
  private selectedNodes: TreeNode<T>[] = [];

  constructor() {}

  get isSingle(): boolean {
    return this.selectionMode === TreeSelectSelectionMode.single;
  }

  get hasValue(): boolean {
    return this.isSingle ? !!this.selectedNode : !!this.selectedNodes?.length;
  }

  get model(): TreeNode<T> | TreeNode<T>[] {
    return this.isSingle ? this.selectedNode : this.selectedNodes;
  }

  set model(value: TreeNode<T> | TreeNode<T>[]) {
    if (this.isSingle) {
      this.selectedNode = value as TreeNode<T>;
      this.onChange(this.selectedNode?.key);
    } else {
      this.selectedNodes = value as TreeNode<T>[];
      this.onChange(this.selectedNodes.map(n => n.key));
    }
  }

  get selected(): string | string[] {
    return this.isSingle ? this.selectedNode?.key : this.selectedNodes.map(n => n.key);
  }

  get hasErrors(): boolean {
    return !!this.errors;
  }

  get error(): string {
    return this.hasErrors ? Object.keys(this.errors)[0] : '';
  }

  private setSelectedNode(key: string | string[]) {
    if (this.isSingle) {
      this.selectedNode = !key
        ? null
        : this.getTreeNodeByKeyRecursive(key as string, {
            children: this.tree,
          });
    } else {
      this.selectedNodes = !key?.length
        ? []
        : (key as string[])
            .map(k =>
              this.getTreeNodeByKeyRecursive(k as string, {
                children: this.tree,
              })
            )
            .filter(n => !!n);
    }
  }

  private getTreeNodeByKeyRecursive(key: string, parent: TreeNode) {
    if (parent.key === key) return parent;

    for (const node of parent.children ?? []) {
      const foundNode = this.getTreeNodeByKeyRecursive(key, node);
      if (foundNode) return foundNode;
    }

    return null;
  }

  private createTree(models: T[]) {
    const tree: TreeNode<T>[] = [];
    for (const model of models ?? []) {
      tree.push(this.createTreeNodeRecursive(model));
    }
    return tree;
  }

  private createTreeNodeRecursive(model: T, currentLevel: number = 0) {
    const children = model[this.childrenSelector] ?? [];
    const node: TreeNode<T> = {
      key: this.selectKey(model),
      label: this.selectLabel(model),
      data: model,
      // selectable: currentLevel >= this.minSelectionLevel,
      selectable: !this.canSelectLeafsOnly || children.length == 0,
      children: [],
    };

    for (const child of children) {
      node.children.push(this.createTreeNodeRecursive(child, currentLevel + 1));
    }

    return node;
  }

  // ===== Control Value Accessor =====

  writeValue(value: string | string[]): void {
    this.initialValue = value;
    this.setSelectedNode(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
