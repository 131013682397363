<div class="c4-dialog-wrapper">
  <h1 mat-dialog-title>{{ 'dialogs.changeProject.caption' | translate }}</h1>
  <mat-dialog-content>
    <div>{{ 'dialogs.changeProject.description' | translate }}</div>
    <mat-form-field class="filter">
      <mat-label>{{ 'general.actions.filter' | translate }}</mat-label>
      <input matInput (input)="filter$.next($event.target.value)">
    </mat-form-field>
    <div *ngIf="filteredProjects$ | async as projects" class="app-project-list" [class.c4-busy]="isBusy">
      <div *ngFor="let project of projects" class="app-project-list-entry c4-busy-content"
           [matTooltip]="'dialogs.changeProject.tooltip' | translate:{project:project.name}"
           (click)="selectProject(project)">
        <ng-template #projectNameTemplate let-isCurrentProject [ngTemplateOutlet]="projectNameTemplate"
                     [ngTemplateOutletContext]="{
          $implicit: project.id === currentProjectId
        }">
          <div [class.current]="isCurrentProject">
            <span>{{ project.name }}</span>
            <span *ngIf="isCurrentProject"> {{ 'dialogs.changeProject.current' | translate }}</span>
          </div>
          <span class="mdi mdi-chevron-right"></span>
        </ng-template>
      </div>
      <div *ngIf="projects.length == 0" class="c4-no-data-container">
        <span class="mdi mdi-blur-off"></span>
        <span>{{ 'dialogs.changeProject.noDataText' | translate }}</span>
      </div>
    </div>
    <div>{{ 'dialogs.changeProject.subdescription' | translate }}</div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="close()" type="button">
      {{ 'general.cancel' | translate }}
    </button>
  </mat-dialog-actions>
</div>