import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'letterRange',
})
export class LetterRangePipe implements PipeTransform {
  transform(value: string, from: number = 0, to: number = 1) {
    if (!value) return '';
    const response = value.substring(from, to);
    return response;
  }
}
