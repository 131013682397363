import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { PrivilegeEnum } from '@app/api';
import { ApiService } from '@app/core/services';

@Injectable()
export class PrivilegesGuard {
  constructor(private apiService: ApiService, private router: Router) {}

  async canActivateChild(route: ActivatedRouteSnapshot) {
    return await this.checkPrivileges(route.pathFromRoot[2].params?.projectId, route.data?.privileges ?? []);
  }

  private async checkPrivileges(projectId: string, neededPrivileges: PrivilegeEnum[]) {
    const privileges = await this.apiService.getUserPrivileges(projectId);

    if (neededPrivileges.any(c => !privileges.includes(c))) {
      const urlTree = ['projects'];

      if (projectId) urlTree.push(projectId, 'dashboard');

      return this.router.createUrlTree(urlTree);
    }

    return true;
  }
}
