import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Busy } from '@app/shared/utils/busy';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { C4GridDef } from '../../grid';
import { LocalizedDateFormat, LocalizedDatePipe } from '@app/shared/pipes';
import { DriveItemLogType, SharingInfoModel } from '@app/api';
import { NotificationStateViewerDialogComponent, NotificationStateViewerDialogComponentData } from '../../dialogs';
import { NotificationStateIcon } from '../../notification-card/notification-card.component';
import { ApiService } from '@app/core';
import { UserNotificationService } from '@app/shared/services';
import { BusyScope, using } from '@app/shared/utils/busy';

@Component({
  selector: 'app-file-sharing-info-dialog',
  templateUrl: './file-sharing-info-dialog.component.html',
  styleUrls: ['./file-sharing-info-dialog.component.scss'],
})
export class FileSharingInfoDialogComponent implements Busy, OnInit {
  @ViewChild('copyToClipboard', { static: true }) copyToClipboard: TemplateRef<any>;
  @ViewChild('itemAction', { static: true }) itemAction: TemplateRef<any>;
  @ViewChild('emptyTemplate', { static: true }) emptyTemplate: TemplateRef<any>;

  gridDef: C4GridDef;
  isBusy: boolean = false;
  sharedInfos: SharingInfoModel[];
  resource: string;
  logTypes = DriveItemLogType;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: FileSharingInfoDialogData,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<FileSharingInfoDialogComponent>,
    private datePipe: LocalizedDatePipe,
    private apiService: ApiService,
    private userNotification: UserNotificationService
  ) {
    this.sharedInfos = data.sharingInfos;
    this.resource = data.resource;
  }

  async ngOnInit() {
    this.gridDef = this.getGridDef();
  }

  private getGridDef(): C4GridDef {
    const def: C4GridDef = {
      initialSorting: [
        {
          field: 'sharedOn',
          order: 0,
        },
      ],
      grid: {
        filterRow: false,
        lazy: false,
        lazyInit: false,
        paging: true,
        scrollable: false,
        rowExpand: true,
        rows: 10,
        rowsOptions: [5, 10, 20, 50, 100],
      },
      row: {
        link: false,
      },
      cols: [
        {
          field: 'sharedBy',
          header: 'sharedInfo.sharedBy',
          width: '3*',
          minWidth: '4em',
          sortable: true,
          priority: 1,
        },
        {
          field: 'sharedOn',
          header: 'sharedInfo.createdOn',
          width: '3*',
          minWidth: '4em',
          sortable: true,
          priority: 1,
          pipe: this.datePipe,
          pipeArg: LocalizedDateFormat.relative,
        },
        {
          field: 'receiver',
          header: 'sharedInfo.receiver',
          width: '1*',
          minWidth: '3em',
          priority: 2,
          sortable: false,
          template: this.itemAction,
          mobileTemplate: this.emptyTemplate,
        },
      ],
    };

    return def;
  }

  async showReceivers(item: SharingInfoModel) {
    try {
      const notificationStateModels = await using(new BusyScope(this), async _ => {
        return await this.apiService.getReceiversForTransaction(item.transactionId, this.resource);
      });

      await this.dialog
        .open(NotificationStateViewerDialogComponent, {
          data: {
            notificationStateModels: notificationStateModels,
          } as NotificationStateViewerDialogComponentData,
        })
        .afterClosed()
        .toPromise();
    } catch (e) {
      this.userNotification.notify('general.errorMsg.getReceiversFailed', { error: e });
    }
  }

  getNotificationIcon(item: SharingInfoModel): string {
    return NotificationStateIcon[item.notificationState];
  }

  cancel() {
    this.dialogRef.close();
  }
}

export interface FileSharingInfoDialogData {
  sharingInfos?: SharingInfoModel[];
  resource?: string;
}
