import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService, BaseSubscriptionComponent, OfflineService, ProjectService, ProjectStatusInfo } from '@app/core';
import { ProgressDialogComponent, ProgressDialogResult } from '../../dialogs/progress-dialog/progress-dialog.component';
import { ActivityStepState, ActivityStepWithProgress } from '../../../../core/services/offline/stepWithProgress';
import { SyncErrorAction, SyncErrorDialogComponent } from '../sync-error-dialog/sync-error-dialog.component';
import { CopyInfoDialogComponent } from '../../dialogs/copy-info-dialog/copy-info-dialog.component';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { ProjectModel } from '@app/api';
import { UserNotificationService } from '@app/shared/services';
import { combineLatest } from 'rxjs';
import { IconAnimation } from '@app/shared/directives';

@Component({
  selector: 'app-offline-state-button',
  templateUrl: './offline-state-button.component.html',
  styleUrls: ['./offline-state-button.component.scss'],
})
export class OfflineStateButtonComponent extends BaseSubscriptionComponent implements Busy {
  isBusy = false;
  isProjectOffline: boolean = false;
  isAutoSyncEnabled: boolean = false;
  hasErrors: boolean = false;

  private project: ProjectModel;
  private projectInfo: ProjectStatusInfo;

  constructor(
    private apiService: ApiService,
    private offlineService: OfflineService,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private userNotification: UserNotificationService
  ) {
    super();
  }

  get isAppOffline$() {
    return this.offlineService.isOffline$;
  }

  get projectName() {
    return this.project?.name;
  }

  get projectId() {
    return this.project?.id;
  }

  get isSyncing(): boolean {
    return this.projectInfo?.isSyncing ?? false;
  }

  get lastSynced(): Date {
    return this.projectInfo?.lastSynced;
  }

  get syncAnimation(): IconAnimation {
    return this.isSyncing ? 'rotate' : '';
  }

  async ngOnInit() {
    this.subscribe(
      combineLatest([this.projectService.projectId$, this.offlineService.projectStatus$]),
      async ([projectId, projectStatus]) => {
        this.project = projectId ? await this.apiService.getProject(projectId) : null;
        this.projectInfo = projectStatus[projectId];
        await this.update();
      }
    );
  }

  async goOnline() {
    do {
      const steps = this.offlineService.goOnline();

      const proceed = await this.dialog
        .open(ProgressDialogComponent, {
          data: {
            title: 'offline.dialogs.online.caption',
            subTitle: this.projectName,
            description: 'offline.dialogs.online.description',
            steps,
          },
          disableClose: true,
        })
        .afterClosed()
        .toPromise();

      await this.update();
      this.apiService.removeCachedDriveItems(this.projectService.projectId$.value);

      if (proceed) {
        let action: SyncErrorAction;
        do {
          action = await this.showErrorsIfAny(steps.items);

          if (action == SyncErrorAction.deleteStorage) {
            const success = await this.startRecovery();
            if (success) action = SyncErrorAction.continue;
          }
        } while (action == SyncErrorAction.deleteStorage);
      }

      return;
    } while (true);
  }

  async goOffline() {
    do {
      const steps = this.offlineService.goOffline();
      const result = await this.dialog
        .open(ProgressDialogComponent, {
          data: {
            title: 'offline.dialogs.offline.caption',
            subTitle: this.projectName,
            description: 'offline.dialogs.offline.description',
            steps,
          },
          disableClose: true,
        })
        .afterClosed()
        .toPromise();

      await this.update();

      if (result === ProgressDialogResult.showErrors) {
        let action: SyncErrorAction;
        do {
          action = await this.showErrorsIfAny(steps.items);

          if (action == SyncErrorAction.deleteStorage) {
            const recoveryKey = await this.offlineService.recovery();
            if (recoveryKey != null) action = SyncErrorAction.continue;
          }
        } while (action == SyncErrorAction.deleteStorage);

        if (action == SyncErrorAction.rerun) {
          continue;
        }
      }

      return;
    } while (true);
  }

  async sync() {
    do {
      const steps = this.offlineService.createStepsForProjectSync();
      const result = await this.dialog
        .open(ProgressDialogComponent, {
          data: {
            title: 'offline.dialogs.sync.caption',
            subTitle: this.projectName,
            description: 'offline.dialogs.sync.description',
            steps,
          },
        })
        .afterClosed()
        .toPromise();

      await this.update();

      if (result === ProgressDialogResult.showErrors) {
        let action: SyncErrorAction;
        do {
          action = await this.showErrorsIfAny(steps.items);

          if (action == SyncErrorAction.deleteStorage) {
            const recoveryKey = await this.offlineService.recovery();
            if (recoveryKey != null) action = SyncErrorAction.continue;
          }
        } while (action == SyncErrorAction.deleteStorage);

        if (action == SyncErrorAction.rerun) {
          continue;
        }
      }

      return;
    } while (true);
  }

  async showSyncError() {
    const errors = await this.offlineService.getSyncErrors();

    do {
      const action = await this.dialog
        .open(SyncErrorDialogComponent, {
          data: {
            companyName: this.projectName,
            errors,
            single: true,
          },
        })
        .afterClosed()
        .toPromise();

      if (action != SyncErrorAction.deleteStorage) return;

      const success = await this.startRecovery();
      if (success) return;
    } while (true);
  }

  async triggerBackgroundSync() {
    const success = await this.offlineService.syncProject(this.projectId);
    if (!success) this.userNotification.notify('offline.sync.error');
  }

  async toggleSync(enable = !this.isAutoSyncEnabled) {
    const translationPrefix = `offline.sync.${enable ? 'enable' : 'disable'}`;
    if (!enable) this.userNotification.notify(`${translationPrefix}.start`);

    const success = enable
      ? await this.offlineService.enableSync(this.projectId)
      : await this.offlineService.disableSync(this.projectId);

    if (success) this.isAutoSyncEnabled = enable;

    this.userNotification.notify(`${translationPrefix}.${success ? 'success' : 'error'}`);
  }

  private async update() {
    const errors = await this.offlineService.getSyncErrors();
    this.isProjectOffline = this.projectInfo?.isOffline ?? false;
    this.isAutoSyncEnabled = await this.offlineService.isAutoSyncEnabled(this.projectId);
    this.hasErrors = errors.length > 0;
  }

  private async startRecovery() {
    const data = await using(new BusyScope(this), () => {
      return this.offlineService.recovery();
    });

    if (!data) return false;

    await this.dialog
      .open(CopyInfoDialogComponent, {
        data: {
          title: 'offline.dialogs.recovery.caption',
          description: 'offline.dialogs.recovery.description',
          copyableString: data,
        },
        disableClose: true,
      })
      .afterClosed()
      .toPromise();

    return true;
  }

  private async showErrorsIfAny(steps: ActivityStepWithProgress[]) {
    const errors = steps
      .filter(s => s.currentState == ActivityStepState.failure)
      // flat map
      .reduce((previous, step) => [...previous, ...step.errors], []);

    const warnings = steps
      .filter(s => s.currentState == ActivityStepState.completedWithWarnings)
      // flat map
      .reduce((previous, step) => [...previous, ...step.warnings], []);

    if (errors.length > 0 || warnings.length > 0) {
      return await this.dialog
        .open(SyncErrorDialogComponent, {
          data: {
            companyName: this.projectName,
            errors,
            warnings,
          },
        })
        .afterClosed()
        .toPromise();
    }

    return null;
  }
}
