import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { PluginListenerHandle } from '@capacitor/core';
import { BarcodeFormat, BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';

@Component({
  selector: 'app-scan-qr-dialog',
  templateUrl: './scan-qr-dialog.component.html',
  styleUrls: ['./scan-qr-dialog.component.scss'],
})
export class ScanQrDialogComponent implements OnInit, OnDestroy {
  barcodeScannerListener: PluginListenerHandle | undefined = undefined;

  constructor(private dialogRef: MatDialogRef<ScanQrDialogComponent>) {
    dialogRef.addPanelClass('fullscreen');
    document.body.classList.add('camera-preview-active');
  }

  async ngOnInit() {
    if (!CapacitorUtils.isApp()) this.dialogRef.close(false);

    const permission = await BarcodeScanner.checkPermissions();
    if (permission.camera === 'denied') this.dialogRef.close(false);

    await this.scanQr();
  }

  ngOnDestroy(): void {
    this.barcodeScannerListener.remove();
    BarcodeScanner.stopScan();
    document.body.classList.remove('camera-preview-active');
  }

  cancel() {
    BarcodeScanner.stopScan();

    this.dialogRef.close(false);
  }

  private async scanQr() {
    this.barcodeScannerListener = await BarcodeScanner.addListener('barcodeScanned', async result => {
      this.dialogRef.close(result.barcode.displayValue);
    });
    await BarcodeScanner.startScan({ formats: [BarcodeFormat.QrCode] });
  }
}
