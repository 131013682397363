// THIS CODE IS FOR V2 MIGRATION MANAGEMENT
// export interface IMigrationStep {
// }

import { SyncClient } from '@app/api';
import { SQLiteDBConnection } from '@capacitor-community/sqlite';
import { IQueryWrapper } from '../offline-query-wrapper';

// export interface ICreateTableOptions {
//     ROWID:boolean = true;
// }

// export class CreateTableMigrationStep implements IMigrationStep {
//     constructor(public table : string, options? : ICreateTableOptions) {
//     }
// }

// export class DeleteTableMigrationStep implements IMigrationStep {
//     constructor(public table : string, options? : ICreateTableOptions) {
//     }
// }

// export const Steps = {
//     createTable(name, options) {

//     }
// }

export interface IMigrationStatement {
  args: any[];
  query: string;
}

export interface IMigration {
  name: string;
  getStatements(): IMigrationStatement[];
  updateData?(connection: SQLiteDBConnection, syncClient: SyncClient): Promise<void>;
}

export function Statement(query, ...args: any[]): IMigrationStatement {
  if (Array.isArray(query)) {
    query = query.join('?');
  }

  return { query: query, args: args };
}

export function StatementParameters(statement: IMigrationStatement) {
  return statement.args.map(value => {
    if (typeof value == 'function') {
      return value();
    } else {
      return value;
    }
  });
}
