import { Params } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { CancellationToken } from 'typescript';

export enum ActivityStepState {
  waiting = 'waiting',
  running = 'running',
  success = 'success',
  failure = 'failure',
  completedWithWarnings = 'completedWithWarnings',
}

export interface ActiviyStepTask {
  (canellationToken: CancellationToken): Promise<any>;
}

interface ActivityStepInfoBase {
  topic: string;
  routerLink?: string | any[];
  queryParams?: Params;
  linkText?: string;
  description: string;
  translationParams?: { [key: string]: any };
}

export interface ActivityStepError extends ActivityStepInfoBase {}

export interface ActivityStepWarning extends ActivityStepInfoBase {}

export type CancelableAsyncTask = (cancellationToken: CancellationToken) => Promise<void>;

export class ActivityStepsCancellationToken implements CancellationToken {
  private stop = false;

  cancel() {
    this.stop = true;
  }

  isCancellationRequested() {
    return this.stop;
  }

  throwIfCancellationRequested() {
    throw new Error('Cancellation requested');
  }
}

export class ActivitySteps {
  public items: ActivityStepWithProgress[];
  public start: CancelableAsyncTask = async _ => {};

  constructor(...items: ActivityStepWithProgress[]) {
    this.items = items;
  }

  addItem(task: ActivityStepWithProgress) {
    this.items.push(task);
  }

  get success() {
    for (const step of this.items) {
      if (step.currentState != ActivityStepState.success) {
        return false;
      }

      return true;
    }
  }
}

export class ActivityStepWithProgress {
  public currentProgress$ = new BehaviorSubject(0);
  public currentState$ = new BehaviorSubject(ActivityStepState.waiting);
  public elapsedSeconds: number = 0;
  public elapsedMinutes: number = 0;
  public errors: ActivityStepError[] = [];
  public warnings: ActivityStepWarning[] = [];

  private startDate: number;
  private elapsedTimeInterval: any;

  constructor(public title: string, public progressDescription: string = null, public maxProgress: number = 0) {}

  public get currentProgress() {
    return this.currentProgress$.getValue();
  }

  public get currentState() {
    return this.currentState$.getValue();
  }

  public addError(error: ActivityStepError) {
    this.errors.push(error);
  }

  public addWarning(warning: ActivityStepWarning) {
    this.warnings.push(warning);
  }

  public async start() {
    if (this.currentState$.value != ActivityStepState.waiting) {
      throw new Error('Step was already started once or is running');
    }

    this.startDate = Date.now();

    this.elapsedTimeInterval = setInterval(_ => {
      this.setElapsedTime();
    }, 1000);

    this.currentState$.next(ActivityStepState.running);
  }

  public advance(i: number | null = null) {
    if (i == null) {
      i = this.currentProgress + 1;
    }

    this.currentProgress$.next(i);
  }

  public complete() {
    clearInterval(this.elapsedTimeInterval);

    this.setElapsedTime();

    if (this.errors.length > 0) {
      this.currentState$.next(ActivityStepState.failure);
    } else {
      if (this.warnings.length > 0) {
        this.currentState$.next(ActivityStepState.completedWithWarnings);
      } else {
        this.currentState$.next(ActivityStepState.success);
      }
      // just to be safe
      this.currentProgress$.next(this.maxProgress);
    }
  }

  private setElapsedTime() {
    const elapsedMs = Date.now() - this.startDate;
    this.elapsedSeconds = Math.floor(elapsedMs / 1000);
    this.elapsedMinutes = Math.floor(this.elapsedSeconds / 60);
  }
}
