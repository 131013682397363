<div class="c4-dialog-wrapper c4-plan-upload">
  <div class="c4-plan-upload-title-container">
    <h1 mat-dialog-title>{{ title | translate }}</h1>
    <div class="error-message" *ngIf="firstErrorOfSelected">
      <span class="mdi mdi-information-outline"></span>
      <span>{{ 'planning.dialogs.planUpload.error.' + firstErrorOfSelected | translate }}</span>
    </div>
  </div>
  <div class="c4-drop-area" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragenter)="onDragEnter($event)"
       (dragleave)="onDragLeave($event)" (dragend)="onDragEnd($event)" [ngClass]="dragDropClass"
       (click)="fileInput.click()">
    <span class="mdi mdi-plus c4-drop-label">{{'planning.dialogs.planUpload.addFiles' | translate }}</span>
  </div>
  <input type="file" (click)="fileInput.value=null" (change)="onFileChange($event)" style="display: none" multiple
         #fileInput />
  <mat-dialog-content>
    <ng-container *ngTemplateOutlet="dialogTableView"></ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button type="button" (click)="abort()">{{ 'general.cancel' | translate }}</button>
    <button mat-flat-button color="primary" type="button" (click)="confirm()"
            [disabled]="!oneValid">{{'planning.dialogs.planUpload.upload' | translate }}</button>
  </mat-dialog-actions>
</div>

<ng-template #dialogTableView>
  <div class="table-container">
    <table class="dlg-table">
      <thead>
        <tr>
          <th>{{'planning.dialogs.planUpload.delete' | translate }}</th>
          <th>{{'planning.dialogs.planUpload.valid' | translate }}</th>
          <th>{{'planning.dialogs.planUpload.fileName' | translate }}</th>
          <ng-container *ngFor="let meta of filenameDef;index as i">
            <th *ngIf="!meta.isSeparator" [matTooltip]="meta.displayName">
              {{meta.displayName}}
            </th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let file of uploadFiles; index as fi">
          <tr class="dlg-file-row" [ngClass]="{'selected': file.selected}" (click)="selectFile(file)">
            <td class="dlg-file-col dlg-del ctrl-col">
              <div class="dlg-cell c4-no-focus">
                <button mat-icon-button class="c4-grid-btn" [matTooltip]="'delete' | translate"
                        (click)="removeFile($event,file,fi)">
                  <span class="mdi mdi-delete icon-size"></span>
                </button>
              </div>
            </td>
            <td class="dlg-file-col dlg-del ctrl-col">
              <div class="dlg-cell" [matTooltip]="'select' | translate">
                <span class="mdi icon-size accent" [ngClass]="{'mdi-check': file.valid}"></span>
              </div>
            </td>
            <td class="dlg-file-col dlg-colA">
              <div class="dlg-cell" [matTooltip]="file.sizeInBytes | filesize ">
                <div class="validated-filemane" [innerHTML]="file.validatedName | plannameformat:seperator"></div>
                <div class="source-filename">{{file.fileName}}</div>
              </div>
            </td>
            <ng-container *ngFor="let meta of filenameDef;index as i">
              <td *ngIf="!meta.isSeparator" class="dlg-file-col dlg-col-{{i}} meta-col key-{{file.schema[i].field}}"
                  [ngClass]="[file.schema[i].valid?'':'invalid',meta.maxLength?'freitext':'',meta.width?'width-'+meta.width:'',file.schema[i].autoIndexed?'corrected':'']">
                <div class="dlg-cell" [matTooltip]="file.schema[i].field | translate">
                  <ng-container *ngIf="meta.isReadOnly">
                    <span class="read-only-value">{{file.schema[i].value}}</span>
                  </ng-container>
                  <ng-container *ngIf="!meta.isReadOnly && !meta.acOptions">
                    <input type="text" class="dlg-meta-input" value="{{file.schema[i].value}}"
                           (keypress)="restrictCharacters($event)" (change)="updateInput($event,file,i)"
                           (input)="validateInput($event.target.value,file, i,meta )" (focus)="selectFile(file)"
                           spellcheck="false" />
                  </ng-container>
                  <ng-container *ngIf="!meta.isReadOnly && meta.acOptions">
                    <input type="text" class="dlg-meta-input" value="{{file.schema[i].value}}"
                           (blur)="updateInput($event,file,i)"
                           (input)="validateInput($event.target.value,file, i,meta,true )"
                           (focus)="selectFile(file,true,i,meta)" spellcheck="false" [matAutocomplete]="auto" />
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" class='inline-auto-complete'
                                      (optionSelected)="validateAutoInput($event.option.value,file, i,meta)">
                      <mat-option *ngFor="let option of (meta.isVersion ? meta.acOptions.slice().reverse() : meta.acOptions)"
                                  [value]="option.value? option.value:option.range?option.range[0]:''"
                                  (mousedown)="setBlurState()">
                        {{option | planoptions}}
                      </mat-option>
                    </mat-autocomplete>
                  </ng-container>
                </div>
              </td>
            </ng-container>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div *ngIf="!selectedFile" class="empty-warning">
    <span class="c4-grid-empty-ico mdi mdi-blur-off"></span><span>{{ 'planning.dialogs.planUpload.noUploadDocs' |
      translate }}</span>
  </div>
  <mat-expansion-panel class="dlg-meta-form" [expanded]="false" *ngIf="selectedFile" #expanel1
                       [@.disabled]="disableAnimation">
    <mat-expansion-panel-header class="dlg-meta-form-head">
    </mat-expansion-panel-header>
    <div class="meta-data-selector meta-table">
      <ng-container *ngFor="let meta of filenameDef;index as i">
        <div *ngIf="!meta.isSeparator">
          <ng-container *ngIf="meta.options && !meta.isReadOnly">
            <app-search-select class="mat-form-field" [title]="meta.field"
                               [selectList]="meta.isVersion ? meta.options.slice().reverse() : meta.options"
                               [selectFormGroup]="fieldOptionsForm" [selectFormControlName]="meta.field"
                               [displayFields]="['label']" [optionTemplate]="planOptionTemplate">
            </app-search-select>
            <ng-template #planOptionTemplate let-option="item">
              <span class="c4-select-option">
                {{ option | planoptions}}
              </span>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="!meta.options && !meta.isReadOnly">
            <mat-form-field>
              <input matInput type="text" placeholder="{{meta.field}}" [value]="selectedFile.schema[i].value"
                     (keypress)="restrictCharacters($event)" (change)="metaTextChange($event, meta.field)">
            </mat-form-field>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </mat-expansion-panel>
</ng-template>