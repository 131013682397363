<div class="root-container">
	<div class="title-container">
		<div class="title">
			<div>{{ title | translate }}</div>
			<span *ngIf="tooltip" matSuffix class="mdi mdi-information info-icon" [matTooltipShowDelay]="1"
						[matTooltip]="tooltip | translate"></span>
		</div>
		<button mat-flat-button type="button" color="primary" (click)="addKeyValuePair()" [disabled]="array.disabled">
			<span class="mdi mdi-plus-box-outline"></span>
			<span>{{ 'general.add' | translate }}</span>
		</button>
	</div>
	<div class="form-array-container">
		<div class="form-array-group" [formGroup]="keyValPair" *ngFor="let keyValPair of array.controls; let i = index">
			<mat-form-field>
				<mat-label>{{ keyLabel | translate }}</mat-label>
				<input matInput formControlName="key">
				<mat-error *ngIf="keyValPair.controls.key?.invalid">
					{{ 'general.errorForms.' + getError(keyValPair.controls.key) | translate }}</mat-error>
			</mat-form-field>
			<mat-form-field>
				<mat-label>{{ valueLabel | translate }}</mat-label>
				<input matInput formControlName="value">
				<mat-error *ngIf="keyValPair.controls.value?.invalid">
					{{ 'general.errorForms.' + getError(keyValPair.controls.value) | translate }}</mat-error>
			</mat-form-field>
			<button mat-icon-button color="primary" (click)="removeKeyValuePair(i)" type="button">
				<mat-icon>delete</mat-icon>
			</button>
		</div>
	</div>
	<div *ngIf="noDataText && !array?.controls?.length" class="no-data">
		<span>{{ noDataText | translate }}</span>
	</div>
</div>