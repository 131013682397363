import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {
  NotificationModel,
  NotificationType,
  NotificationAttachmentModel,
  NotificationSendType,
} from '@app/api';
import {
  ApiService,
  TranslationManagementService,
  DataHolder,
} from '@app/core';
import {
  UserNotificationService,
  DocumentDownloadService,
} from '@app/shared/services';
import {
  NotificationCardDataFactory,
  NotificationCardData,
} from '../../notification-card/notification-card-data';

@Component({
  selector: 'app-notify-after-login-dialog',
  templateUrl: './notify-after-login-dialog.component.html',
  styleUrls: ['./notify-after-login-dialog.component.scss'],
})
export class NotifyAfterLoginDialogComponent implements OnInit {
  notification: NotificationModel;
  notificationCardData: NotificationCardData;
  notificationDataHolder: DataHolder<string>;

  constructor(
    public confirmRef: MatDialogRef<NotifyAfterLoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private apiService: ApiService,
    private userNotification: UserNotificationService,
    private downloadService: DocumentDownloadService,
    public translate: TranslationManagementService
  ) {
    this.notification = data.notification;
    this.notificationDataHolder = new DataHolder<string>('<br><br><br><br>');
  }

  async ngOnInit() {
    try {
      if (!this.notification) {
        throw new Error('no notification');
      }
      this.notificationCardData =
        NotificationCardDataFactory.buildFromNotification(
          this.notification,
          true,
          NotificationSendType.Input
        );
      await this.notificationDataHolder.updateData(async () => {
        if (!this.notification.confirmed) {
          await this.apiService.markNotificationAsConfirmed(
            this.notification.id
          );
        }
        return await this.apiService.getNotificationAdditionalData(
          this.notification.id
        );
      });
    } catch (e) {
      this.userNotification.notifyFailedToLoadDataAndLog(
        'general.errorFailedToLoadDataKeys.notification',
        e
      );
    }
  }

  ok() {
    this.confirmRef.close();
  }

  get isExternalDriveChangeNotification() {
    return this.isNotificationOfType(
      NotificationType.ExternalDriveChangeNotification
    );
  }

  get isHtmlNotification() {
    return this.isNotificationOfType(NotificationType.Html);
  }

  private isNotificationOfType(type: NotificationType) {
    if (!this.notification) {
      return false;
    }

    return this.notification.notificationType === type;
  }

  async downloadFile(file: NotificationAttachmentModel) {
    var id = file.attachmentId;
    var fileName = file.fileName;

    await this.downloadService.downloadAttachment({
      id,
      fileName,
    });
  }
}
