import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export enum CheckboxState {
  unchecked = 'unchecked',
  indeterminate = 'indeterminate',
  checked = 'checked',
}

@Component({
  selector: 'app-ternary-checkbox',
  templateUrl: './ternary-checkbox.component.html',
  styleUrls: ['./ternary-checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TernaryCheckboxComponent,
    },
  ],
})
export class TernaryCheckboxComponent implements ControlValueAccessor {
  @Input() label: string;

  isChecked: boolean = false;
  isIndeterminate: boolean = false;
  isDisabled: boolean = false;

  onChange = (_: CheckboxState) => {};
  private onTouched = () => {};
  private isTouched: boolean = false;

  constructor() {}

  checkboxChange(isChecked: boolean) {
    this.onChange(isChecked ? CheckboxState.checked : CheckboxState.unchecked);
  }

  // ----- Reactive Forms Methods -----

  writeValue(checkboxState: CheckboxState): void {
    this.isChecked = checkboxState == CheckboxState.checked;
    this.isIndeterminate = checkboxState == CheckboxState.indeterminate;
  }

  registerOnChange(fn: (_: CheckboxState) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
  }
}
