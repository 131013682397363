/* tslint:disable */
//TODO: re-enable tslint when continuing
import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { AppConfigService, C4BISetting, GlobalsService, LogService } from '@app/core';
import * as pbi from 'powerbi-client';
import { count } from 'rxjs/operators';

@Component({
  selector: 'app-power-bi',
  templateUrl: './power-bi.component.html',
  styleUrls: ['./power-bi.component.scss']
})
export class PowerBiComponent implements OnInit, AfterViewInit {
  @Input() identifier: any;
  BiSection: C4BISetting;
  responseKey: string = 'desk';
  constructor(
    private log : LogService,
    public globals: GlobalsService
    ) { }

  ngOnInit() {
    const settings = AppConfigService.settings.biSettings;
    if (!settings) return;
    this.log.debug("reports: ", settings);
    if (settings.length > 0) {
      const current = settings.filter(l => l.id === this.identifier.settings.id);
      this.log.debug("identifier: ", this.identifier);
      if (current.length > 0) this.BiSection = current[0];
    }
  }

  ngAfterViewInit() {
    if (this.BiSection) {
      let count = 0;
      const jsonTheme = { 
            "name": "C4Standard", 
            "background": "#ffffff",
            "foreground": "#282828",
            "tableAccent": "#cccc00"
        };
      this.BiSection.items.forEach(e => {

        const AccessToken = e.token;

        let config: { [k: string]: any } = {
          type: e.type,
          tokenType: 1,
          accessToken: AccessToken,
          embedUrl: e.url,
          theme : {themeJson: jsonTheme}
        };
        if (e.reportId) config.id = e.reportId;
        if (e.pageId) config.pageName = e.pageId;
        if (e.visualId) config.visualName = e.visualId;
        if (e.dashboardId) config.id = e.dashboardId;
        if (e.tileId) {
          config.id = e.tileId;
          config.dashboardId = e.dashboardId;
        }
        this.log.debug("currentconfig:",config);
        this.showBiTile(config, count);
        count++;
      });

    }
  }

  showBiTile(config: any, index: number) {
    let reportContainer = document.getElementById(this.identifier.settings.id + index);
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    let report = powerbi.embed(reportContainer, config);
    report.off("loaded");
    report.on("loaded", () => {
      this.log.debug("Loaded", index);
    });
  }
}