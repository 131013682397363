import { Pipe } from '@angular/core';
import { GalleryDriveItemModel, UserModel } from '@app/api';
import { Moment } from 'moment';
import { ImageTags } from './image-tag.service';

// export interface ImageDetailProperty {
//   label: string;
//   value: string;
//   pipe: Pipe;
// }

// export interface ImageDetail {

//   properties: ImageDetailProperty[];
// }

export interface DateFilter {
  from: Moment;
  to: Moment;
}

export interface ModuleFilter {
  displayName: string;
  type: string;
}

export interface ImageFilter {
  imageTitle: string;
  date: DateFilter;
  tags: ImageTags;
  users: UserModel[];
  modules: ModuleFilter[];
  roomBookIds: any[];
}
