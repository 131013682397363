import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalsService, Utils } from '..';

@Injectable()
export class OAuthFlowGuard {
  constructor(private globals: GlobalsService, private router: Router) {}

  async canActivateChild() {
    if (this.globals.isOAuth) return true;

    this.router.navigate(['/']);
    return false;
  }
}
