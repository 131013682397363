<div class="c4-dialog-wrapper">
	<h1 mat-dialog-title>{{ title | translate }}</h1>
	<mat-dialog-content class="dialog-content">
		<div *ngIf="description" class="description">{{ description | translate }}</div>

		<mat-form-field>
			<mat-select [(value)]="selectedProperty">
				<mat-option *ngFor="let property of properties" [value]="property">
					{{ property.label | translate }}
				</mat-option>
			</mat-select>
		</mat-form-field>

		<ng-container [ngSwitch]="selectedProperty.type">
			<mat-form-field *ngSwitchCase="ChangePropertyType.select">
				<mat-select [(value)]="selectedProperty.selectedValue">
					<mat-select-trigger>
						<ng-container *ngIf="selectedProperty.triggerTemplate; else defaultTriggerTemplate"
													[ngTemplateOutlet]="selectedProperty.triggerTemplate"
													[ngTemplateOutletContext]="{ item: selectedProperty.selectedValue }">
						</ng-container>
						<ng-template #defaultTriggerTemplate>
							<span>{{ selectedProperty.getLabel(selectedProperty.selectedValue) }}</span>
						</ng-template>
					</mat-select-trigger>
					<mat-option *ngFor="let entry of selectedProperty.options" [value]="entry">
						<ng-container *ngIf="selectedProperty.template; else defaultTemplate"
													[ngTemplateOutlet]="selectedProperty.template" [ngTemplateOutletContext]="{ item: entry }">
						</ng-container>
						<ng-template #defaultTemplate>
							<span>{{ selectedProperty.getLabel(entry) }}</span>
						</ng-template>
					</mat-option>
				</mat-select>
			</mat-form-field>
			<mat-form-field *ngSwitchCase="ChangePropertyType.date" class="date-form-field">
				<input matInput (dateInput)="onDateChange($event.value)" [matDatepicker]="datePicker">
				<mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
				<mat-datepicker #datePicker></mat-datepicker>
			</mat-form-field>
		</ng-container>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
		<button mat-flat-button color="primary" (click)="ok()" type="button"
						[disabled]="selectedProperty.required && !selectedProperty.selectedValue">
			{{ 'general.ok' | translate }}
		</button>
	</mat-dialog-actions>
</div>
<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:false }"></ngx-loading>