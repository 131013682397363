import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'join',
})
export class JoinPipe implements PipeTransform {
  /**
   *
   * @param values array of values to join
   * @param seperator seperator to use for join
   * @param displayField if values (or lookup values) are objects, this is the field to use for display
   * @param lookupMap if values are keys, this is the map to use for lookup
   * @returns single string of joined values
   */
  transform(values: any[], seperator: string, displayField: string = null, lookupMap: Record<string, any> = null): string {
    if (lookupMap != null) values = values.map(v => lookupMap[v]);
    if (displayField != null) values = values.map(v => v[displayField]);
    return values.join(seperator);
  }
}
