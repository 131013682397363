import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { FormComponent } from '@app/core/utils/form-component';
import { Busy } from '@app/shared/utils/busy';
import { Subject, of } from 'rxjs';
import { delay, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-async-search-autocomplete',
  templateUrl: './async-search-autocomplete.component.html',
  styleUrls: ['./async-search-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: AsyncSearchAutocompleteComponent,
    },
  ],
})
export class AsyncSearchAutocompleteComponent<T> extends FormComponent implements Busy, ControlValueAccessor, OnInit {
  @Input() displayField: string = 'name';
  @Input() placeholder: string;
  @Input() optionTemplate: TemplateRef<any>;
  @Input() fetchOptions: (text: string) => Promise<T[]> = async _ => [];
  @Input() set errors(validationErrors: ValidationErrors) {
    const errors = Object.entries(validationErrors ?? {}).reduce((errors, [key, value]) => {
      if (value) errors.push(key);
      return errors;
    }, []);
    this.error = errors[0];
  }

  @Output() blur = new EventEmitter<EventTarget>();

  isBusy: boolean = false;
  isTouched: boolean = false;

  inputSubject = new Subject<string>();
  inputControl = new UntypedFormControl();
  error: string;
  // needed to display error even if inputControl doesn't have any
  errorStateMatcher: ErrorStateMatcher = {
    isErrorState: () => {
      return this.isTouched && !!this.error;
    },
  };

  fetchedOptions: T[] = [];

  onChange = (_: T | string) => {};
  onTouched: EmptyCallback = () => {};

  private cancelSearch = new EventEmitter<string>();

  constructor() {
    super();
  }

  get displayFn() {
    return this.getDisplayValue.bind(this);
  }

  ngOnInit() {
    this.inputSubject.subscribe(_ => (this.isBusy = true));
    this.inputSubject.pipe(switchMap(val => of(val).pipe(delay(500), takeUntil(this.cancelSearch)))).subscribe(async value => {
      try {
        let cancel = false;
        this.cancelSearch.subscribe(_ => {
          cancel = true;
        });

        const options = await this.fetchOptions(value);

        if (cancel) return;

        this.tryMatchOption(options, value);

        this.fetchedOptions = options;
        this.isBusy = false;
      } catch {
        this.fetchedOptions = [];
        this.isBusy = false;
      }
    });

    this.cancelSearch.subscribe(latestInput => {
      if (this.isBusy) this.tryMatchOption(this.fetchedOptions, latestInput);

      this.isBusy = false;
    });
  }

  optionSelected(option: T) {
    this.fetchedOptions = [];
    this.onChange(option);
  }

  inputBlur(event: FocusEvent) {
    const input = event.target as HTMLInputElement;
    const currentValue = input.value;
    this.markAsTouched();
    this.cancelSearch.emit(currentValue);
    this.blur.emit(event.relatedTarget);
  }

  private getDisplayValue(value: T) {
    if (typeof value === 'string') return value;

    return value && value[this.displayField] ? value[this.displayField] : '';
  }

  private tryMatchOption(options: T[], value: string) {
    const matchedOption = options.find(option => option[this.displayField] == value);
    this.onChange(matchedOption ?? value);
  }

  // ----- Reactive Forms Methods -----

  writeValue(value: T | string): void {
    this.inputControl.setValue(value);
  }

  registerOnChange(fn: (selected: T | string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: EmptyCallback): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) this.inputControl.disable();
    else this.inputControl.enable();
  }

  private markAsTouched() {
    if (!this.isTouched) {
      this.onTouched();
      this.isTouched = true;
    }
  }
}
