<form *ngIf="form" class="config-content-container general-config" [formGroup]="form">
	<div class="config-content">
		<div class="config-group general-group">
			<div class="config-title">{{ 'projectConfig.general.generalTitle' | translate }}</div>

			<div class="flex-container wide-gap gap-top">
				<div class="config-group grow">
					<div class="flex-container">
						<mat-form-field *ngIf="!isTemplate && isNew" class="small">
							<mat-label>{{ 'projectConfig.general.number' | translate }}</mat-label>
							<input matInput formControlName="number">
						</mat-form-field>

						<mat-form-field class="grow">
							<mat-label>{{ 'projectConfig.general.' + (isTemplate ? 'nameTemplate' : 'name') | translate }}</mat-label>
							<input matInput formControlName="name">
							<mat-error *ngIf="f.name.invalid">
								{{ 'general.errorForms.' + getError(f.name) | translate }}
							</mat-error>
						</mat-form-field>
					</div>

					<mat-form-field *ngIf="isTemplate">
						<mat-label>{{ 'projectConfig.general.codeTemplate' | translate }}</mat-label>
						<input matInput formControlName="code">
					</mat-form-field>

					<ng-container *ngIf="!isTemplate && isNew">
						<c4-autocomplete [displayMember]="'name'" [options]="teamTemplates" formControlName="template"
														 placeholder="{{ 'projectConfig.titleTemplate' | translate }}">
						</c4-autocomplete>

						<app-search-select [selectList]="organizations" [selectFormGroup]="form"
															 [title]="'projectConfig.general.organization' | translate"
															 selectFormControlName="organization">
						</app-search-select>
					</ng-container>

					<mat-form-field>
						<mat-label>{{ 'projectConfig.general.description' | translate }}</mat-label>
						<textarea matInput formControlName="description" cdkTextareaAutosize cdkAutosizeMinRows="3"
											cdkAutosizeMaxRows="8"></textarea>
					</mat-form-field>

					<app-toggle-field formControlName="isNameComputed"
														[label]="'projectConfig.general.isNameComputed' | translate"
														[description]="'projectConfig.general.isNameComputedDescription' | translate">
					</app-toggle-field>
				</div>

				<app-edit-hover-overlay *ngIf="!isTemplate; else imagePlaceholder" (click)="editLogoSmall()">
					<app-drag-hover-overlay [validTypes]="validTypes" (filesDropped)="setLogoSmall($event[0])">
						<ng-template [ngTemplateOutlet]="projectLogoFallback"></ng-template>
						<div *ngIf="f.logoSmall.value?.length" class="logo-small"
								 [style.background-image]="'url(data:image/png;base64,'+ f.logoSmall.value +')'">
						</div>
					</app-drag-hover-overlay>
				</app-edit-hover-overlay>

				<ng-template #imagePlaceholder>
					<div [style.min-width]="'10rem'"></div>
				</ng-template>
			</div>
		</div>

		<div class="config-group slim gap">
			<div class="config-title">{{ 'projectConfig.general.modules' | translate }}</div>

			<app-toggle-field formControlName="isGalleryModuleEnabled" [iconUrl]="appRoutingData.gallery.iconUrl"
												[label]="'gallery.title' | translate" [description]="'gallery.description' | translate">
			</app-toggle-field>
			<app-toggle-field formControlName="isPlanModuleEnabled" [iconUrl]="appRoutingData.planning.iconUrl"
												[label]="'planning.title' | translate" [description]="'planning.description' | translate">
			</app-toggle-field>
			<app-toggle-field formControlName="isBimModuleEnabled" [iconUrl]="appRoutingData.bim.iconUrl"
												[label]="'ifc.group.title' | translate" [description]="'bim.description' | translate">
			</app-toggle-field>
			<app-toggle-field formControlName="isRoomBookModuleEnabled" [iconUrl]="appRoutingData.rooms.iconUrl"
												[label]="'roomBook.title' | translate" [description]="'roomBook.description' | translate">
			</app-toggle-field>
			<app-toggle-field formControlName="isLeanModuleEnabled" [iconUrl]="appRoutingData.lean.iconUrl"
												[label]="'lean.title' | translate" [description]="'lean.description' | translate">
			</app-toggle-field>
			<app-toggle-field formControlName="isDiaryModuleEnabled" [iconUrl]="appRoutingData.diary.iconUrl"
												[label]="'constructionDiary.title' | translate"
												[description]="'constructionDiary.description' | translate">
			</app-toggle-field>
			<app-toggle-field formControlName="isDefectModuleEnabled" [iconUrl]="appRoutingData.defects.iconUrl"
												[label]="'defects.title' | translate" [description]="'defects.description' | translate">
			</app-toggle-field>
		</div>

		<div *ngIf="!isTemplate" class="config-group slim gap">
			<div class="config-title">{{ 'projectConfig.general.uploadLogo' | translate }}</div>

			<app-upload class="logo-height" *ngIf="!f.logo.value?.length" [validTypes]="validTypes"
									(onFileUpload)="setLogo($event)" [clickAction]="editLogo.bind(this)"></app-upload>

			<app-edit-hover-overlay *ngIf="f.logo.value?.length" (click)="editLogo()">
				<app-drag-hover-overlay [validTypes]="validTypes" (filesDropped)="setLogo($event[0])">
					<div class="logo" [style.background-image]="'url(data:image/png;base64,'+ f.logo.value +')'">
					</div>
				</app-drag-hover-overlay>
			</app-edit-hover-overlay>
		</div>

		<div class="config-group archive-group slim separator-top" *ngIf="!isNew">
			<div class="config-title">{{ 'projectConfig.general.archive.title' | translate }}</div>
			<p class="config-hint">
				{{ 'projectConfig.general.archive.hint' | translate }}
			</p>

			<button mat-raised-button color="warn" (click)="archiveProject()" *ngIf="!isArchived">
				{{ 'projectConfig.general.archive.btnArchive' | translate }}
			</button>
			<button mat-raised-button color="warn" (click)="activateProject()" *ngIf="isArchived">
				{{ 'projectConfig.general.activate.btnActivate' | translate }}
			</button>
		</div>
	</div>
</form>

<ng-template #projectLogoFallback>
	<app-label-icon [name]="computedName" [sizeRem]="10">
	</app-label-icon>
</ng-template>


<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:true }"></ngx-loading>