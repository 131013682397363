<div class="c4-dialog-wrapper">
	<h1 mat-dialog-title>{{ title | translate }}</h1>
	<mat-dialog-content class="content">
		<div *ngIf="description">{{ description | translate }}</div>
		<div class="dialog-options">
			<mat-slide-toggle *ngFor="let key of optionKeys" [(ngModel)]="options[key].result">
				<span [matTooltip]="options[key].title | translate">
					{{ options[key].title | translate }}
				</span>
			</mat-slide-toggle>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
		<button mat-flat-button color="primary" (click)="ok()" type="button">{{ 'general.ok' | translate }}</button>
	</mat-dialog-actions>
</div>