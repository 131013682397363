<ng-container *ngIf="BiSection.items.length>0">
  <div class="c4-bi-section" [ngClass]="{'horizontal': BiSection.horizontalDir}">
    <ng-container *ngFor="let item of BiSection.items;  let i = index">
      <div class="c4-bi-box" [attr.data-width]="BiSection[globals.viewport].itemWidth? BiSection[globals.viewport].itemWidth:null">
        <mat-card  [style.paddingTop.%]="BiSection[globals.viewport].itemRatio? BiSection[globals.viewport].itemRatio:null" [ngClass]="{'c4-ratio': BiSection[globals.viewport].itemRatio}" >
          <div class="c4-bi-content" [attr.id]="identifier.settings.id + i ">
              POWER BI
          </div>
        </mat-card>
      </div>
    </ng-container>
  </div>
</ng-container>