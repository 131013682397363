import { Injectable } from '@angular/core';
import { Utils } from '@app/core';
import { C4LinqExtensions } from '@app/core/utils/c4-linq-extensions';
import { TranslateService } from '@ngx-translate/core';
import { PasswordComplexityModel } from '@app/api';

@Injectable({
  providedIn: 'root'
})
export class PasswordPolicyService {
  private charCodeZero = '0'.charCodeAt(0);
  private charCodeNine = '9'.charCodeAt(0);
  private charCodeLowerA = 'a'.charCodeAt(0);
  private charCodeUpperA = 'A'.charCodeAt(0);
  private charCodeLowerZ = 'z'.charCodeAt(0);
  private charCodeUpperZ = 'Z'.charCodeAt(0);

  constructor(private translaterService: TranslateService) {
    C4LinqExtensions.init();
  }

  async buildRequirementsString(options: PasswordComplexityModel) {
    const fragments: string[] = [];

    if (options.requiredLength > 0) {
      fragments.push(await this.translaterService.get('passwordPolicy.settings.requiredLength', options).toPromise() as string);
    }

    if (options.requireNonAlphanumeric) {
      fragments.push(await this.translaterService.get('passwordPolicy.settings.requireNonAlphanumeric', options).toPromise() as string);
    }

    if (options.requireDigit) {
      fragments.push(await this.translaterService.get('passwordPolicy.settings.requireDigit', options).toPromise() as string);
    }

    if (options.requireLowercase) {
      fragments.push(await this.translaterService.get('passwordPolicy.settings.requireLowercase', options).toPromise() as string);
    }

    if (options.requireUppercase) {
      fragments.push(await this.translaterService.get('passwordPolicy.settings.requireUppercase', options).toPromise() as string);
    }

    if (options.requiredUniqueChars > 0) {
      fragments.push(await this.translaterService.get('passwordPolicy.settings.requiredUniqueChars', options).toPromise() as string);
    }

    const base = await this.translaterService.get('passwordPolicy.base').toPromise() as string;
    return `${base}${fragments.join(', ')}`;
  }

  validate(password: string, options: PasswordComplexityModel) : boolean {
    if (Utils.isNullOrWhitespace(password)) {
      //PasswordTooShort
      return false;
    }

    const passwordAr = Array.from(password).select(s => s.charCodeAt(0));

    if (passwordAr.length < options.requiredLength) {
      //PasswordTooShort
      return false;
    }

    if (options.requireNonAlphanumeric && passwordAr.all(c => this.isLetterOrDigit(c))) {
      //PasswordRequiresNonAlphanumeric
      return false;
    }

    if (options.requireDigit && !passwordAr.any(c => this.isDigit(c))) {
      //PasswordRequiresDigit
      return false;
    }

    if (options.requireLowercase && !passwordAr.any(c => this.isLower(c))) {
      //PasswordRequiresLower
      return false;
    }

    if (options.requireUppercase && !passwordAr.any(c => this.isUpper(c))) {
      //PasswordRequiresUpper
      return false;
    }

    if (options.requiredUniqueChars >= 1 && passwordAr.distinct().length < options.requiredUniqueChars) {
      //PasswordRequiresUniqueChars
      return false;
    }

    return true;
  }

  private isDigit(c: number): boolean {
    return c >= this.charCodeZero && c <= this.charCodeNine;
  }

  private isLower(c: number): boolean {
    return c >= this.charCodeLowerA && c <= this.charCodeLowerZ;
  }

  private isUpper(c: number): boolean {
    return c >= this.charCodeUpperA && c <= this.charCodeUpperZ;
  }
  
  private isLetterOrDigit(c: number): boolean {
    return this.isUpper(c) || this.isLower(c) || this.isDigit(c);
  }
}
