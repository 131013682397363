import { ProjectModel } from '@app/api';
import { Week } from '@app/shared/services';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';

export class LeafletPlansPageCombiner {
  private _jspdf: jsPDF;
  private _page: number;

  private _planIndex = 0;
  private _stepIndex = 0;
  private _stepCount: number = 0;

  constructor(js: jsPDF) {
    this._jspdf = js;
    this._page = 0;
  }

  addPlan() {
    this._planIndex++;
  }

  addStep() {
    this._stepIndex++;
  }

  setStepCount(steps: number) {
    this._stepCount = steps;
  }

  getPlan() {
    return this._planIndex;
  }

  getStepProgress(): number {
    return Math.floor(100 * (this._stepIndex / this._stepCount));
  }

  async addPage(target: HTMLDivElement, week: Week, project: ProjectModel) {
    const jspdf = this._jspdf;
    await domtoimage.toPng(target).then(async dataUrl => {
      const a4ScaleFactor = 6;
      const a4W = 2480;
      const a4H = 1754;

      if (this._page > 0) {
        jspdf.addPage();
      }

      jspdf.addImage(dataUrl, 'JPEG', 3.5, 2.5, a4W / a4ScaleFactor, a4H / a4ScaleFactor, null, 'FAST');

      const parts = [week.number, project.name];

      if (project.externalId) {
        parts.push(project.externalId);
      }

      jspdf.text('KW ' + parts.join(' / '), 6, 10);

      this._page++;
    });
  }
}
