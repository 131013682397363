<div class="c4-dialog-wrapper">
  <h1 mat-dialog-title>{{ 'dialogs.sign.caption' | translate }}</h1>
  <mat-dialog-content>
    <mat-stepper #stepper class="stepper" orientation="horizontal" (selectionChange)="onStepperSelectionChange($event)">
      <mat-step>
        <ng-template matStepLabel>{{'dialogs.sign.title' | translate}}</ng-template>
        <section class="section-select-type">
          <div class="mat-card" [class.selected]="selectedSignType == SignType.DrawToSign"
               (click)="selectedSignType = SignType.DrawToSign">
            <div class="header">
              <span class="title">{{'dialogs.sign.touchSign' | translate}}</span>
              <div class="check">
                <div class="icon-content"><span class="icon mdi mdi-check"></span></div>
              </div>
            </div>
            <div class="content draw-to-sign">
              <div class="image" style="background-image: url(assets/customization/images/DrawToSign.jpg);">
              </div>
            </div>
          </div>
          <div class="mat-card" [class.selected]="selectedSignType == SignType.ATrustSign"
               (click)="selectedSignType = SignType.ATrustSign">
            <div class="header">
              <span class="title">{{'dialogs.sign.aTrustSign' | translate}}</span>
              <div class="check">
                <div class="icon-content"><span class="icon mdi mdi-check"></span></div>
              </div>
            </div>
            <div class="content atrust-sign">
              <div class="image" style="background-image: url(assets/customization/images/ATrustHandySignatur.jpg);">
              </div>
            </div>
          </div>
        </section>
      </mat-step>
      <mat-step #stepSign>
        <ng-template matStepLabel>{{'dialogs.sign.signDocument' | translate}}</ng-template>
        <div [ngSwitch]="activeSignType">
          <app-draw-to-sign #drawToSign *ngSwitchCase="SignType.DrawToSign" [documentId]="initArgs.documentId"
                            [resource]="initArgs.resource"></app-draw-to-sign>
          <app-atrust-sign #aTrustSign *ngSwitchCase="SignType.ATrustSign" [documentId]="initArgs.documentId"
                           [resource]="initArgs.resource"></app-atrust-sign>
        </div>
      </mat-step>
    </mat-stepper>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button (click)="onCancel()" type="button">{{ 'general.cancel' | translate }}</button>
    <button *ngIf="showOkButton" mat-flat-button color="primary" type="button" (click)="onClickNext()"
            [disabled]="!isOkButtonEnabled">
      {{(isLastStep ? 'general.ok' : 'dialogs.sign.next') | translate }}</button>
  </mat-dialog-actions>
</div>