<div class="c4-dialog-wrapper multi-select-dialog">
	<h1 mat-dialog-title>{{ title | translate }}</h1>
	<mat-dialog-content class="content">
		<div class="description" *ngIf='description'>{{ description | translate }}</div>
		<div class="filter">
			<mat-form-field>
				<mat-label>{{ 'general.actions.filter' | translate }}</mat-label>
				<input #filterInput matInput (input)="filter(filterInput.value)">
			</mat-form-field>
			<mat-checkbox [disabled]="!filteredItems?.length" [checked]="areAllItemsSelected"
										[indeterminate]="!(areAllItemsSelected || areNoItemsSelected)"
										[matTooltip]="'general.' + (areAllItemsSelected ? 'deselectAll' : 'selectAll') | translate"
										(change)="setSelectStateForItems($event.checked)">
			</mat-checkbox>
		</div>
		<div class="selection-list-container">
			<mat-selection-list #selectionList>
				<mat-list-option *ngFor="let item of filteredItems" [value]="item" [selected]="item.isSelected"
												 (click)="itemClicked(item)">
					<span class="item-text">
						{{ item.displayName }}
					</span>
				</mat-list-option>
			</mat-selection-list>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button *ngIf="canCancel" mat-flat-button (click)="cancel()" type="button">
			{{ 'general.cancel' | translate }}
		</button>
		<button *ngIf="filteredItems.length" mat-flat-button color="primary"
						(click)="confirm(selectionList.selectedOptions.selected)"
						[disabled]="mustSelect && selectionList.selectedOptions.selected.length == 0">
			{{ 'general.ok' | translate }} ({{selectionList.selectedOptions.selected.length}})
		</button>
	</mat-dialog-actions>
</div>