import { Pipe, PipeTransform } from '@angular/core';
import { IImageTag, setImageTagProperties } from './image-tag.service';

@Pipe({
  name: 'imageTagFromServer',
})
export class ImageTagFromServerPipe implements PipeTransform {
  transform(source: string[]): unknown {
    if (source) {
      return source.map(x => {
        const tag: IImageTag = {
          text: x,
          supportsPartial: false,
        };

        setImageTagProperties(tag, false);

        return tag;
      });
    }

    return [];
  }
}
