import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BaseSubscriptionComponent, ProjectService } from '@app/core';
import { ImageTags, ImageTagService } from '../../image/image-tag.service';
import { DialogBase } from '../dialog-base';

@Component({
  selector: 'app-image-tag-dialog',
  templateUrl: './image-tag-dialog.component.html',
  styleUrls: ['./image-tag-dialog.component.scss'],
})
export class ImageTagDialogComponent extends DialogBase<ImageTagDialogComponent> {
  public selectedTags: ImageTags = [];
  public onsave: (tags: ImageTags) => Promise<boolean>;

  public hasChanges = false;
  public readonly title = 'dialogs.image-tag-dialog.title';
  public params = { count: 0 };

  constructor(
    public dialogRef: MatDialogRef<ImageTagDialogComponent>,

    private svcImageTagService: ImageTagService,
    private svcProjectService: ProjectService,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    super(dialogRef, data);

    this.selectedTags = data.selectedTags;
    this.onsave = data.onsave;
    this.params = data.params;
  }

  async ngOnInit() {
    this.subscribe(this.svcProjectService.projectId$, x => {
      this.svcImageTagService.createWithProject();
    });
  }

  fireChange(tags: ImageTags) {
    this.selectedTags = tags;
    this.hasChanges = true;
  }

  async fireSubmit() {
    if (await this.onsave(this.selectedTags)) {
      this.dialogRef.close(true);
    }
  }

  fireCancel() {
    this.dialogRef.close(false);
  }
}
