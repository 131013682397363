import { DomSanitizer } from '@angular/platform-browser';
import { IssueCommentModel, IssueViewpointModel } from '@app/api';
import { AdditionalDataLoader, ApiService } from '@app/core';
import { ICommentWithViewpoint, UserNotificationService, ViewpointWithThumbnail } from '@app/shared/services';
import { catchError, map, of } from 'rxjs';

export class ViewpointUtils {
  static mapViewpoint(sanitizer: DomSanitizer, viewpoint: IssueViewpointModel) {
    return {
      ...viewpoint,
      thumbnail: sanitizer.bypassSecurityTrustResourceUrl(`url(data:image/png;base64,${viewpoint.snapshot})`),
    };
  }

  static getIssueCommentsMapper(sanitizer: DomSanitizer): (comment: IssueCommentModel) => ICommentWithViewpoint {
    return (comment: IssueCommentModel) => {
      if (!comment.viewpoint) return comment;

      const commentWithViewpoint = comment as ICommentWithViewpoint;

      commentWithViewpoint.viewpoint = ViewpointUtils.mapViewpoint(sanitizer, comment.viewpoint);

      return commentWithViewpoint;
    };
  }
}

export class ViewpointsLoaderFactory {
  static create(apiService: ApiService, sanitizer: DomSanitizer, userNotification: UserNotificationService, issueId: string) {
    const loadViewpointsRequest = () =>
      !issueId
        ? of([] as IssueViewpointModel[])
        : apiService.getViewpoints(issueId).pipe(
            map(viewpoints => {
              return viewpoints.map(viewpoint => {
                return ViewpointUtils.mapViewpoint(sanitizer, viewpoint);
              });
            }),
            catchError(e => {
              userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.viewpoints', e);
              return of([]);
            })
          );

    return new AdditionalDataLoader<ViewpointWithThumbnail[]>(loadViewpointsRequest);
  }
}
