<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:true }"></ngx-loading>
<div class="page-part room-templates">
	<app-header [label]="'roomTemplates.title' | translate" [icon]="icon"></app-header>

	<app-header-bar>
		<div class="header-container">
			<div class="button-container">
				<button *ngIf="isInProject" mat-button (click)="createFromRoom()"
								[matTooltip]="'roomBook.roomToTemplate.description' | translate">
					<span class="mdi mdi-door"></span>
					<span>{{ 'roomBook.roomToTemplate.title' | translate }}</span>
				</button>
				<button *ngIf="isInProject" mat-button (click)="addFromGlobal()"
								[matTooltip]="'general.actions.addFromGlobalTooltip' | translate">
					<span class="mdi mdi-database-export"></span>
					<span>{{ 'general.actions.addFromGlobal' | translate }}</span>
				</button>
				<button *ngIf="isInProject" mat-button (click)="fileInput.click()"
								[matTooltip]="'general.importFormatTooltip' | translate:{ format: 'CSV' }">
					<span class="mdi mdi-import"></span>
					<span>{{ 'general.importFormat' | translate:{ format: 'CSV' } }}</span>
				</button>
				<button *ngIf="isInProject" mat-button (click)="export()"
								[matTooltip]="'general.exportFormatTooltip' | translate:{ format: 'CSV' }">
					<span class="mdi mdi-export"></span>
					<span>{{ 'general.exportFormat' | translate:{ format: 'CSV' } }}</span>
				</button>

				<button mat-button (click)="addOrEditTemplate()" [matTooltip]="'roomTemplates.addTooltip' | translate">
					<span class="mdi mdi-plus-box"></span>
					<span>{{ 'roomTemplates.add' | translate }}</span>
				</button>
			</div>
		</div>
	</app-header-bar>

	<div class="c4-grid-container card word-break" [ngClass]="roomTemplatesHolder ? roomTemplatesHolder.busyClass : ''">
		<c4-grid *ngIf="gridDef" [gridDef]="gridDef" [source]="roomTemplatesHolder.data"
						 [noDataText]="'grid.roomTemplates.noDataText'">
		</c4-grid>
	</div>

	<input #fileInput class="hide" type="file" accept=".csv" [multiple]="false" (change)="import($event.target.files[0])"
				 (click)="fileInput.value = null" />
</div>

<ng-template #generalTranslate let-row="row">
	<div>
		{{ 'general.' + row.isDefault | translate }}
	</div>
</ng-template>

<ng-template #actions let-col='col' let-row='row'>
	<div class="c4-no-focus">
		<button type="button" mat-icon-button class="c4-grid-btn" aria-label="Edit" (click)="addOrEditTemplate(row.id)"
						[matTooltip]="'general.edit' | translate">
			<span class="mdi mdi-square-edit-outline"></span>
		</button>
		<button type="button" mat-icon-button class="c4-grid-btn" aria-label="Delete" (click)="deleteRoomTemplate(row)"
						[matTooltip]="'general.delete' | translate">
			<span class="mdi mdi-trash-can-outline"></span>
		</button>
	</div>
</ng-template>