import { LeanPhaseModel, LeanWorkpackageTemplateModel } from '@app/api';
import { Utils } from './utils';

interface LeanModelWithPhase {
  phaseId?: string | undefined;
}

export class WorkpackageTemplatesHelper {
  static getSortedTemplates(templates: LeanWorkpackageTemplateModel[]) {
    return templates.sort(Utils.propertySort(template => template.name));
  }

  static filterTemplates(templates: LeanWorkpackageTemplateModel[], phaseIds: string[], textFilter: string) {
    return templates.filter(
      template =>
        (!phaseIds?.length || phaseIds.includes(template.phaseId)) &&
        (!textFilter ||
          template.name?.toLowerCase().includes(textFilter) ||
          template.craftName?.toLowerCase().includes(textFilter))
    );
  }

  static getAvailablePhases(models: LeanModelWithPhase[], allPhases: LeanPhaseModel[]) {
    // distinct phases of workpackageTemplates
    const templatePhaseIds = Object.keys(
      models
        .filter(template => !!template.phaseId)
        .reduce(
          (phasesDictionary, template) => ({
            ...phasesDictionary,
            [template.phaseId]: true,
          }),
          {}
        )
    );

    return allPhases.filter(p => templatePhaseIds.contains(p.id));
  }
}
