import { HttpClient } from '@angular/common/http';
import { Component, Input, OnDestroy } from '@angular/core';
import {
  ApiService,
  ApiUrlService,
  AppConfigService,
  AuthenticationService,
  BaseSubscriptionComponent,
  LogService,
} from '@app/core';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import { ProjectModel } from '@app/api';
import { createHybridImageUrl, HybridImageComponent } from '@app/core/utils';
import { BehaviorSubject, defer, distinctUntilChanged, filter, from, map, Observable, share, switchMap } from 'rxjs';

@Component({
  selector: 'app-project-image',
  templateUrl: './project-image.component.html',
  styleUrls: ['./project-image.component.scss'],
})
export class ProjectImageComponent extends BaseSubscriptionComponent implements HybridImageComponent<ProjectModel>, OnDestroy {
  @Input() sizeRem: number = 2.4;
  @Input() set projectId(id: string) {
    this.projectId$.next(id);
  }

  projectName$: Observable<string>;
  projectImageUrl$: Observable<string>;

  private projectId$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    apiService: ApiService,
    authService: AuthenticationService,
    public apiUrlService: ApiUrlService,
    public httpClient: HttpClient,
    public log: LogService
  ) {
    super();

    authService.projectUpdated
      .pipe(filter(updatedProjectId => this.projectId$.value == updatedProjectId))
      .subscribe(this.projectId$); // reload project

    const project$ = this.projectId$.pipe(
      distinctUntilChanged(),
      filter(projectId => projectId != null),
      switchMap(projectId => defer(() => from(apiService.getProject(projectId)))),
      share()
    );

    this.projectName$ = project$.pipe(map(project => project.name));
    this.projectImageUrl$ = createHybridImageUrl(project$, this);
  }

  canCreateImage(project: ProjectModel) {
    return project.hasLogoSmall;
  }

  getImageUrl(project: ProjectModel) {
    return `${AppConfigService.settings.api.url}/project/${project.id}/logoSmall${
      project.lastModificationOfGeneralSettings ? `?ts=${project.lastModificationOfGeneralSettings.getTime()}` : ''
    }`;
  }
}
