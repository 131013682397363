<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: true }"></ngx-loading>
<div class="project-config" [ngClass]="[globals.isMobileMenu?'isphone':'',globals.isMobile?'wide-mobile':'']">
	<div class="navigation-bar" [ngClass]="hideNavBar?'hide-navbar':''" #navBar>
		<div class="navigation-bar-show-ctrl" [matTooltip]="'projectConfig.navBarCtrl_tt' | translate"
				 (click)="navBar.classList.toggle(navBarShowClass)"><span class="mdi mdi-chevron-left"></span></div>
		<div class="header indented">{{ 'projectConfig.' + (isTemplate ? 'titleTemplate' : 'title') | translate }}</div>
		<div class="sub-header indented">{{ projectName }}</div>

		<ng-template ngFor let-pageGroup [ngForOf]="settingPageGroups">
			<div class="page-group-header indented">{{ pageGroup.title | translate }}</div>

			<a mat-ripple *ngFor="let item of pageGroup.items" class="page-group-list-item indented"
				 [class.selected]="settingsPagePath == item.link" [routerLink]="item.link" (click)="hideNavBar=true"
				 [class.disabled]="isPageDisabled(item.key)">
				<span>{{ item.title | translate }}</span>
				<span *ngIf="!isBusy && isPageInvalid(item.key)" class="mdi mdi-information"></span>
			</a>
		</ng-template>

		<div class="filler"></div>

		<button *ngIf="showSaveAsTemplate && (!isTemplate || !isNew)" mat-stroked-button class="button"
						(click)="saveAsNewTemplate()" type="button" [disabled]="!canSaveAsTemplate">
			{{ 'projectConfig.saveAsNewTemplate' | translate }}
		</button>
		<button mat-flat-button color="primary" class="button" [disabled]="!canSave" (click)="save()">
			{{ 'general.save' | translate }}
		</button>
	</div>
	<div class="content content-grid">
		<app-return-menu-button (click)="hideNavBar = false" cssClass="mobile-return-menu-btn">
			<div>{{'projectConfig.title' | translate }}</div>
		</app-return-menu-button>
		<div class="content-outlet">
			<router-outlet></router-outlet>
		</div>
	</div>
</div>