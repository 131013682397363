import { Component, ElementRef, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { IsActiveMatchOptions } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sidebar-navigation-item',
  templateUrl: './sidebar-navigation-item.component.html',
  styleUrls: ['./sidebar-navigation-item.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarNavigationItemComponent {
  @ViewChild('root', { static: true }) root: ElementRef<HTMLLinkElement>;

  isActive = false;
  isDisabled = false;
  isExpanded = false;
  isRouterLinkActiveDisabled = false;

  options: IsActiveMatchOptions = {
    paths: 'exact',
    queryParams: 'ignored',
    fragment: 'ignored',
    matrixParams: 'ignored',
  };

  readonly isRouterLinkActive$ = new Subject<boolean>();

  @Input() set active(isActive: boolean | string) {
    this.isActive = isActive === '' || isActive === 'true' || isActive === true;
  }

  @Input() set disabled(isDisabled: boolean | string) {
    this.isDisabled = isDisabled === '' || isDisabled === 'true' || isDisabled === true;
  }

  @Input() set exact(exact: boolean | string) {
    this.options.paths = exact === '' || exact === 'true' || exact === true ? 'exact' : 'subset';
  }

  @Input() set expanded(isExpanded: boolean | string) {
    this.isExpanded = isExpanded === '' || isExpanded === 'true' || isExpanded === true;
  }

  @Input() set disableLinkActive(disableLinkActive: boolean | string) {
    this.isRouterLinkActiveDisabled = disableLinkActive === '' || disableLinkActive === 'true' || disableLinkActive === true;
  }

  @Input() link: string;

  onClick(event: MouseEvent) {
    if (this.isDisabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onRouterLinkActiveChange(isActive: boolean) {
    this.isActive = isActive;
    this.isRouterLinkActive$.next(isActive);
  }
}
