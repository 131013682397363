<div class="c4-dialog-wrapper">
  <h1 mat-dialog-title>{{ title | translate }}</h1>

  <form [formGroup]="form" (ngSubmit)="confirm()">
    <mat-dialog-content>
      <div class="input-container">
        <mat-form-field class="c4-full-width">
          <input matInput [placeholder]="placeholder | translate" formControlName="name">
          <mat-error *ngIf="f.name.invalid">
            {{ 'general.errorForms.' + getError('name') | translate }}
          </mat-error>
        </mat-form-field>

        <span *ngIf="suffix" class="suffix">{{ suffix }}</span>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button mat-flat-button type="button" (click)="abort()">{{ 'general.cancel' | translate }}</button>
      <button mat-flat-button color="primary" type="submit" [disabled]="form.invalid">
        {{ 'general.ok' | translate }}
      </button>
    </mat-dialog-actions>
  </form>
</div>

<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: false }"></ngx-loading>