import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[numberInput]',
  host: { '(blur)': 'convertInput($event)', '(keyup)': 'convertInput($event)' },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputDirective),
      multi: true,
    },
  ],
})
export class NumberInputDirective implements ControlValueAccessor {
  private oldValue = null;
  private onChange = (x: any) => {};
  private decimalSeperator: string;
  private thousandSeperator: string;

  constructor(
    private decimalPipe: DecimalPipe,
    private input: ElementRef<HTMLInputElement>,
    private translate: TranslateService
  ) {
    this.setLocale(translate.currentLang);
    translate.onLangChange.subscribe(event => this.setLocale(event.lang));
  }

  convertInput(event: FocusEvent) {
    const input = event.target as HTMLInputElement;
    const value = input?.value;
    if (value != this.oldValue) {
      this.oldValue = value;
      this.onChange(Number.isNaN(value) || value === '' || value === null ? value : this.convertToNumber(value));
    }
  }

  // private convertNumber(number: string, locale: string) {
  //   const { format } = new Intl.NumberFormat(locale);
  //   const [, decimalSign] = /^0(.)1$/.exec(format(0.1));
  //   return +number.replace(new RegExp(`[^${decimalSign}\\d]`, 'g'), '').replace(decimalSign, '.');
  // }

  private convertToNumber(value: string) {
    const sanitizedNumber = value
      .replace(new RegExp(`\\${this.thousandSeperator}`, 'g'), '')
      .replace(this.decimalSeperator, '.');
    return Number.parseFloat(sanitizedNumber);
  }

  private setLocale(locale: string) {
    this.decimalSeperator = this.decimalPipe.transform(1.1, '1.1-1', locale).charAt(1);
    this.thousandSeperator = this.decimalSeperator === '.' ? ',' : '.';
  }

  // ===== Reactive Forms =====

  writeValue(value: any): void {
    this.input.nativeElement.value = this.decimalPipe.transform(value, '1.0-2', this.translate.currentLang);
    this.oldValue = this.input.nativeElement.value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {}
}
