<form class="config-content-container diary-config" [formGroup]="form">
	<div *ngIf="!isTemplate" class="config-content content-sticky">
		<div class="config-group slim">
			<div class="config-title">{{ 'diary.title' | translate }}</div>

			<div class="config-group gap-top">
				<div>{{ 'projectConfig.diary.defaultReportPath.title' | translate }}</div>
				<div class="config-field-description">
					{{ 'projectConfig.diary.defaultReportPath.description' | translate }}
				</div>
			</div>
			<div class="default-report-path-container">
				<div class="default-report-path" [class.disabled]="isSetReportPathDisabled" [matTooltip]="fullReportPath">
					<span>{{ fullReportPath }}</span>
					<div class="c4-disabled-formfield-underline"></div>
				</div>
				<button mat-icon-button color="primary" (click)="setDefaultReportPath()" [disabled]="isSetReportPathDisabled">
					<span class="mdi mdi-folder-move-outline"></span>
				</button>
			</div>
		</div>
	</div>

	<div *ngIf="canProvision" class="config-content">
		<div class="config-group gap">
			<div class="config-title sticky with-action">
				<div class="config-title sticky">{{ 'projectConfig.diary.states' | translate }}</div>
				<button class="sticky-right" mat-icon-button (click)="stateSchemaService.addOrEditState()"
								[disabled]="states.disabled">
					<span class="mdi mdi-plus-box"></span>
				</button>
			</div>

			<div *ngIf="stateSchemaService.hasStates" class="table-grid-component-container">
				<app-state-matrix class="table-grid-component" [service]="stateSchemaService"></app-state-matrix>
			</div>
		</div>
	</div>
</form>