<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
<div class="safe-area"></div>
<div #rootDiv class="c4-dialog-wrapper dialog-wide room-template-dialog"
		 [ngClass]="[globals.isMobile?'fullscreen full-mobile':'', globals.isPhone?'phone':'']">
	<mat-dialog-content>
		<div class="tasklike-dialog-title">
			<div class="dialog-top">
				<div class="dialog-header">
					<div>
						<span class="mdi dialog-header-ico" [class]="icon"></span>
						<span class="dialog-header-label">
							{{'roomBook.roomTemplate.title' | translate}}
						</span>
					</div>
					<ng-container *ngIf="form">
						<ng-container *ngIf="form.touched && form.invalid" [ngTemplateOutlet]="error"
													[ngTemplateOutletContext]="{ fieldError: getFirstError() }">
						</ng-container>
					</ng-container>
				</div>
				<div class="dialog-controls">
					<button *ngIf="!globals.isMobile" mat-icon-button class="c4-no-focus" (click)="toggleFullscreen(rootDiv)">
						<span class="mdi mdi-arrow-expand ctrl-ico"></span>
					</button>
					<button mat-icon-button class="c4-no-focus" (click)="cancel()">
						<span class="mdi mdi-window-close ctrl-ico"></span>
					</button>
				</div>
			</div>
			<div class="dialog-actions">
				<button mat-flat-button color="primary" (click)="save()" [disabled]="!isValid" type="button">
					{{'general.saveAndClose' | translate}}
				</button>
				<button mat-icon-button class="c4-no-focus" (click)="reset()">
					<span class="mdi mdi-refresh action-ico"></span>
				</button>
			</div>
		</div>
		<ng-container *ngIf="roomTemplate">
			<app-room-edit #roomEdit [form]="form" [model]="roomTemplate" [isTemplate]="true" [isInProject]="isInProject"
										 [categories]="categories" [zoneGroups]="zoneGroups">
			</app-room-edit>
		</ng-container>
	</mat-dialog-content>
</div>

<ng-template #error let-fieldError="fieldError">
	<div class="error" *ngIf="fieldError">
		<span class="mdi mdi-information"></span>
		<span>{{ 'general.hintCaption.atField' | translate }}</span>
		<span>&nbsp;'{{ 'roomBook.fieldName.' + fieldError.fieldName | translate }}':&nbsp;</span>
		<span>{{ 'general.errorForms.' + fieldError.error | translate }}</span>
	</div>
</ng-template>