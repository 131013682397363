<div class="c4-dialog-wrapper wide-dlg schema-dlg">
  <h1 mat-dialog-title class="schema-edit-header">
    <span class="title-text">{{ title | translate: params }}</span>
    <button type="button" mat-icon-button class="c4-grid-btn c4-no-focus" aria-label="Preview" (click)="viewSchema()"
            [matTooltip]="'dialogs.editSchema.preview' | translate">
      <span class="mdi mdi-eye"></span>
    </button>
  </h1>
  <mat-dialog-content>
    <form [formGroup]="editSchemaForm">
      <div class="input-row multi-column">
        <mat-form-field class="c4-relative-width wide">
          <mat-error *ngIf="f.schemaNameInput.errors?.required" class="validation-error"
                     translate="general.validation.required"
                     [translateParams]="{ fieldName: 'dialogs.editSchema.form.name' | translate }">
          </mat-error>
          <input matInput type="text" #schemaNameInput formControlName="schemaNameInput"
                 placeholder="{{ 'dialogs.editSchema.form.name' | translate }}" />
        </mat-form-field>
        <mat-form-field class="c4-relative-width small">
          <mat-label>{{ 'dialogs.editSchema.form.defaultSeparator' | translate }}</mat-label>
          <input matInput type="text" [value]="seperator" required formControlName="schemaSeparatorInput"
                 (input)="updateSeperator($event)" />
          <mat-error class="validation-error">{{ 'general.required' | translate }}</mat-error>
        </mat-form-field>
      </div>
      <mat-form-field class="c4-full-width">
        <input matInput type="text" #schemaDescriptionInput formControlName="schemaDescriptionInput"
               placeholder="{{ 'dialogs.editSchema.form.description' | translate }}" />
      </mat-form-field>
      <div class="dlg-form-header">
        <span> {{ 'dialogs.editSchema.form.fieldsHeader' | translate }} </span>
        <span *ngIf="!isRestricted" [matTooltip]="'dialogs.editSchema.form.addFields' | translate"
              class="mdi mdi-plus-box add-field-btn" [matMenuTriggerFor]="addFieldMenu"></span>
        <mat-menu #addFieldMenu="matMenu">
          <button mat-menu-item (click)="addNewField()">
            <span>{{'dialogs.editSchema.form.addFields' | translate}}</span>
          </button>
          <button mat-menu-item (click)="addNewSeparator()" [disabled]="fields.length < 3">
            <span>{{'dialogs.editSchema.form.addSeparator' | translate}}</span>
          </button>
        </mat-menu>
        <!-- <span *ngIf="!isRestricted" [matTooltip]="'dialogs.editSchema.form.addFields' | translate"
              class="mdi mdi-plus-box add-field-btn" (click)="addNewField()"></span>
        <span *ngIf="!isRestricted" [matTooltip]="'dialogs.editSchema.form.addSeparator' | translate"
              class="mdi mdi-plus-box add-field-btn" (click)="addNewSeparator()"></span> -->
      </div>
      <ng-template *ngTemplateOutlet="Schemafields"></ng-template>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
    <button *ngIf="!isNew && !isSchemaForProject" mat-stroked-button (click)="confirm(true, true)" type="button"
            [disabled]="isRestricted || !validFields">
      {{ 'schemata.saveAsNewSchema' | translate }}
    </button>
    <button mat-flat-button (click)="confirm(false)" color="primary" type="button"
            [disabled]="editSchemaForm.invalid || !validFields">
      {{ 'general.save' | translate }}
    </button>
  </mat-dialog-actions>
</div>
<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: true }"></ngx-loading>

<ng-template #Schemafields>
  <div class="fields-container" [ngClass]="isRestricted ? 'restricted' : ''">
    <div class="field-sort-ctrl hide-onrestricted">
      <div class="button-container">
        <div [matTooltip]="'dialogs.editSchema.form.moveUp' | translate" class="mdi mdi-chevron-up-box-outline"
             (click)="sortFields(-1)"></div>
        <div [matTooltip]="'dialogs.editSchema.form.moveDown' | translate" class="mdi mdi-chevron-down-box-outline"
             (click)="sortFields(1)"></div>
      </div>
    </div>
    <div class="field-list">
      <div class="list-item" *ngFor="let field of fields; let i = index" [ngClass]="[
          field.markForDeletion ? 'deleted' : '',
          field.isKey ? 'isKey' : '',
          field.isReadOnly ? 'read-only' : '',
          field.isVersion ? 'isVersion' : '',
          field.edit ? 'edit' : ''
        ]">
        <ng-template *ngTemplateOutlet="fieldTemplate; context: { field: field, index: i }"></ng-template>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #fieldTemplate let-field="field" let-index="index">
  <div class="field-row">
    <div class="field-row-header" (click)="selectField(field)">
      <ng-container *ngIf="field.selected">
        <div class="hex-container">
          <span class="field-ico active mdi mdi-hexagon-slice-6 hexall"></span>
          <!-- <span  class="field-ico active mdi mdi-hexagon-slice-4 hexleft"></span>
                  <span  class="field-ico active mdi mdi-hexagon-slice-2 hexleft"></span> -->
        </div>
      </ng-container>
      <span *ngIf="!field.selected" class="field-ico mdi mdi-hexagon-outline"></span>
      <span class="field-span field-value" [ngClass]="{
        'selected': field.selected,
        'field-error': !isFieldValid(field)
      }">
        {{ field.field }}
      </span>
    </div>

    <span *ngIf="!field.markForDeletion" [matTooltip]="'schemata.edit' | translate"
          class="mdi mdi-square-edit-outline ctrl-ico edit" (click)="editField(field)"></span>
    <span *ngIf="!field.markForDeletion && !isRestricted && field.field != fileType"
          [matTooltip]="'schemata.delete' | translate" class="mdi mdi-trash-can-outline ctrl-ico delete"
          (click)="removeField(field)"></span>
    <span *ngIf="field.markForDeletion" [matTooltip]="'schemata.redo' | translate"
          class="mdi mdi-undo-variant ctrl-ico recycle" (click)="revokeField(field)"></span>
  </div>
  <ng-container
                *ngTemplateOutlet="!field.isSeparator ? planFieldTemplate : seperatorFieldTemplate;  context: { field: field }">
  </ng-container>
</ng-template>


<ng-template #seperatorFieldTemplate let-field="field">
  <div class="field-edit-container" *ngIf="field.edit">
    <div class="channel-edit-frame">
      <div class="channel-ctrls_separator">
        <div class="channel-edit-div large" [ngClass]="field.valid ? 'valid' : 'invalid'">
          <mat-form-field>
            <mat-label>{{ 'dialogs.editSchema.form.separator' | translate }}*</mat-label>
            <input *ngIf="isRestricted" class="separator-input" matInput [disabled]="true" type="text" maxlength="1"
                   [value]="field.field" />
            <input *ngIf="!isRestricted" class="separator-input" matInput type="text" maxlength="1"
                   placeholder="{{ 'dialogs.editSchema.form.separator' | translate }}" [value]="field.field"
                   (change)="updateValue($event.target.value, field, 'field')"
                   (input)="updateInputValue($event, field, 'field')" appSetFocus />
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #planFieldTemplate let-field="field">
  <div class="field-edit-container" *ngIf="field.edit">
    <div class="channel-edit-frame">
      <div class="channel-check-ctrls edit-channel-controls" [ngClass]="isRestricted ? 'restricted' : ''">
        <div class="channel-edit-div clickable-div bool-ctrl" (click)="toggleField(field, 'isReadOnly')">
          <span class="mdi channel-check"
                [ngClass]="field.isReadOnly ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'"></span>
          <span class="disable-label">{{ 'schemata.isreadOnly' | translate }}</span>
        </div>
        <div class="channel-edit-div clickable-div bool-ctrl" (click)="toggleField(field, 'isKey')">
          <span class="mdi channel-check"
                [ngClass]="field.isKey ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'"></span>
          <span class="disable-label">{{ 'schemata.isKey' | translate }}</span>
        </div>
        <div class="channel-edit-div clickable-div bool-ctrl" (click)="toggleVersionField($event, field)"
             [ngClass]="field.isVersion || !versionField ? 'allowed' : 'forbidden'">
          <span class="mdi channel-check"
                [ngClass]="field.isVersion ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'"></span>
          <span class="disable-label">{{ 'schemata.isVersion' | translate }}</span>
        </div>
        <div class="channel-edit-div clickable-div bool-ctrl always-editable"
             (click)="toggleField(field, 'isLogicalGrouping')">
          <span class="mdi channel-check"
                [ngClass]="field.isLogicalGrouping ? 'mdi-checkbox-marked-outline' : 'mdi-checkbox-blank-outline'"></span>
          <span class="disable-label">{{ 'schemata.isLogicalGrouping' | translate }}</span>
        </div>
        <div class="channel-edit-div clickable-div bool-ctrl" (click)="convertOptions(field)">
          <div class="toggle-box">
            <span class="disable-label" [ngClass]="field.hasOptions ? 'inactive' : 'hilight'">{{
              'schemata.rangeHeader' | translate
              }}</span>
            <span class="mdi channel-check toggle-switch"
                  [ngClass]="field.hasOptions ? 'mdi-toggle-switch' : 'mdi-toggle-switch-off'"></span>
            <span class="disable-label" [ngClass]="field.hasOptions ? 'higlight' : 'inactive'">{{
              'schemata.optionHeader' | translate
              }}</span>
          </div>
        </div>
      </div>
      <div class="channel-ctrls_two">
        <div class="channel-edit-div" [ngClass]="field.valid ? 'valid' : 'invalid'">
          <mat-form-field>
            <mat-label>{{ 'dialogs.editSchema.form.fieldName' | translate }}*</mat-label>
            <input matInput *ngIf="isRestricted" [disabled]="true" type="text" [value]="field.field" />
            <input matInput *ngIf="!isRestricted" type="text" [disabled]="field.field == fileType"
                   placeholder="{{ 'dialogs.editSchema.form.fieldName' | translate }}" [value]="field.field"
                   (change)="updateValue($event.target.value, field, 'field')"
                   (input)="updateInputValue($event, field, 'field')" appSetFocus />
          </mat-form-field>
        </div>
        <div class="channel-edit-div">
          <mat-form-field>
            <mat-label>{{ 'dialogs.editSchema.form.fieldDisplayName' | translate }}</mat-label>
            <input matInput type="text" placeholder="{{ 'dialogs.editSchema.form.fieldDisplayName' | translate }}"
                   [value]="field.displayName" (change)="updateValue($event.target.value, field, 'displayName')" />
          </mat-form-field>
        </div>
        <div class="channel-edit-div plan-scheme">
          <mat-form-field>
            <mat-label>{{ 'dialogs.editSchema.form.fieldDescription' | translate }}</mat-label>
            <input matInput type="text" placeholder="{{ 'dialogs.editSchema.form.fieldDescription' | translate }}"
                   [value]="field.description" (change)="updateValue($event.target.value, field, 'description')" />
          </mat-form-field>
        </div>
        <div class="channel-edit-div small">
          <mat-form-field>
            <mat-label>{{ 'dialogs.editSchema.form.order' | translate }}</mat-label>
            <input matInput type="text" [value]="field.order" (input)="updateNumberValue($event, field, 'order')" />
          </mat-form-field>
        </div>
        <div class="channel-edit-div small">
          <mat-form-field>
            <mat-label>{{ 'dialogs.editSchema.form.code' | translate }}</mat-label>
            <mat-select [disabled]="isRestricted" [value]="field.code ? field.code : planCode.None"
                        (selectionChange)="setPlanCode(field, $event.value)">
              <ng-container *ngFor="let code of planCode | keyvalue">
                <mat-option [disabled]="usedPlancodes[code.value]" [value]="code.value">
                  {{ (code.value ? 'schemata.code.' + code.value : 'schemata.code.none') | translate }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="channel-edit-div small">
          <mat-form-field>
            <mat-label>{{ 'dialogs.editSchema.form.maxLength' | translate }}</mat-label>
            <input [disabled]="isRestricted" matInput type="text" [value]="field.maxLength"
                   (input)="updateNumberValue($event, field, 'maxLength')" />
          </mat-form-field>
        </div>
        <div class="channel-edit-div small">
          <mat-form-field>
            <mat-label>{{ 'dialogs.editSchema.form.width' | translate }}</mat-label>
            <input matInput type="text" [value]="field.width" (input)="updateNumberValue($event, field, 'width')" />
          </mat-form-field>
        </div>
      </div>
    </div>
    <ng-container *ngIf="!field.maxLength; else optionHintTemplate">
      <div class="dlg-form-header subheader">
        <div *ngIf="field.hasOptions" class="edit-ctrl-header">{{ 'schemata.optionHeader' | translate }}</div>
        <div *ngIf="!field.hasOptions" class="edit-ctrl-header">{{ 'schemata.rangeHeader' | translate }}</div>
        <span [matTooltip]="'dialogs.editSchema.form.addOptions' | translate" class="mdi mdi-plus-box add-field-btn"
              (click)="addNewOption(field)"></span>
      </div>
      <ng-container *ngIf="field.options && field.options.length > 0">
        <div class="option-list-container">
          <div class="field-sort-ctrl"
               [ngClass]="[field.options.length < 2 ? 'hide' : '', field.isVersion ? 'hide-onrestricted' : '']">
            <div class="button-container">
              <div [matTooltip]="'dialogs.editSchema.form.moveUp' | translate" class="mdi mdi-chevron-up-box-outline"
                   (click)="sortOptions(field, -1)"></div>
              <div [matTooltip]="'dialogs.editSchema.form.moveDown' | translate"
                   class="mdi mdi-chevron-down-box-outline" (click)="sortOptions(field, 1)"></div>
              <div [matTooltip]="'dialogs.editSchema.form.sort' | translate" class="mdi mdi-sort"
                   (click)="sortOptions(field)"></div>
            </div>
          </div>
          <div class="option-list">
            <ng-template *ngTemplateOutlet="optionTemplate; context: { field: field }"></ng-template>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #optionHintTemplate>
  <div class="channel-edit-frame">
    <div class="hint">
      <span [matTooltip]="'dialogs.editSchema.form.hints.options.tooltip' | translate"
            class="mdi mdi-information ctrl-ico lock"></span>
      <span class="hint">{{ 'dialogs.editSchema.form.hints.options.fallback' | translate }}</span>
    </div>
  </div>
</ng-template>

<ng-template #optionTemplate let-field="field">
  <div class="option-item" *ngFor="let option of field.options; let o = index"
       [ngClass]="option.isSelected ? 'selected' : ''" (click)="selectOption(field, option)">
    <div class="option-ctrls simple-option">
      <ng-container *ngIf="field.hasOptions">
        <div class="option-edit-div option-value" [ngClass]="{
          'c4-error-option': hasError(option),
        }">
          <mat-form-field style="width: 100%">
            <mat-label>{{ 'dialogs.editSchema.form.optionName' | translate }}</mat-label>
            <ng-container *ngIf="isRestricted && !option.isNew">
              <input disabled matInput type="text" [value]="option.value" />
            </ng-container>
            <ng-container *ngIf="!isRestricted || option.isNew">
              <input *ngIf="!option.isSelected || option.value" matInput type="text" [value]="option.value"
                     (change)="updateOption($event.target.value, option, 'value')" />
              <input *ngIf="option.isSelected && !option.value" matInput type="text" [value]="option.value"
                     (change)="updateOption($event.target.value, option, 'value')" appSetFocus />
            </ng-container>
            <mat-error>{{ getErr(option) | translate:{separator: seperator} }}</mat-error>
          </mat-form-field>
          <div class="c4-error-option__item" *ngIf="hasError(option)">
            {{ getErr(option) | translate:{separator: seperator} }}
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!field.hasOptions">
        <div class="option-edit-div option-range">
          <mat-form-field>
            <mat-label>{{ 'dialogs.editSchema.form.rangeFrom' | translate }}</mat-label>
            <input [disabled]="isRestricted" matInput type="text" [value]="option.range[0]"
                   (change)="updateOption($event.target.value, option, 'range', 0)" />
          </mat-form-field>
        </div>
        <div class="option-edit-div option-range">
          <mat-form-field>
            <mat-label>{{ 'dialogs.editSchema.form.rangeTo' | translate }}</mat-label>
            <input [disabled]="isRestricted" matInput type="text" [value]="option.range[1]"
                   (change)="updateOption($event.target.value, option, 'range', 1)" />
          </mat-form-field>
        </div>
      </ng-container>
      <div class="option-edit-div option-label">
        <mat-form-field>
          <mat-label>{{ 'dialogs.editSchema.form.optionDisplayname' | translate }}</mat-label>
          <input matInput type="text" [value]="option.label"
                 (change)="updateOption($event.target.value, option, 'label')" />
        </mat-form-field>
      </div>
      <div class="option-edit-div option-ctrl hover-only">
        <span [matTooltip]="'schemata.addBelow' | translate" class="mdi mdi-layers-plus ctrl-ico add"
              (click)="addOptionBelow($event, field, o)"></span>
      </div>
      <div class="option-edit-div option-ctrl" *ngIf="isRestricted">
        <span *ngIf="!option.isLocked" [matTooltip]="'dialogs.editSchema.form.lock' | translate"
              class="mdi mdi-lock-open-variant-outline ctrl-ico lock" (click)="toggleLock($event, option)"></span>
        <span *ngIf="option.isLocked" [matTooltip]="'dialogs.editSchema.form.unlock' | translate"
              class="mdi mdi-lock ctrl-ico lock delete" (click)="toggleLock($event, option)"></span>
      </div>
      <div class="option-edit-div option-ctrl" *ngIf="!isRestricted">
        <span [matTooltip]="'schemata.delete' | translate" class="mdi mdi-trash-can-outline ctrl-ico delete"
              (click)="removeOption(field, o)"></span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #invalidOptionsTmpl let-contentData="contentData">
  <div class="invalid-options-description">
    {{ 'dialogs.editSchema.invalidOptionsHeader' | translate }}
  </div>
  <div class="invalid-options-list">
    <div class="invalid-options-row header-row">
      <span class="options-field">{{ 'dialogs.editSchema.optionsField' | translate }}</span>
      <span class="options-type">{{ 'dialogs.editSchema.optionsType' | translate }}</span>
      <span class="options-value">{{ 'dialogs.editSchema.optionsValue' | translate }}</span>
      <span class="options-label">{{ 'dialogs.editSchema.optionsLabel' | translate }}</span>
    </div>
    <div *ngFor="let row of contentData" class="invalid-options-row sub-row">
      <span class="options-field">{{ row.fieldName }}</span>
      <span class="options-type">{{ 'dialogs.editSchema.t' + row.type | translate }}</span>
      <span *ngIf="row.value" class="options-value">{{ row.value }}</span>
      <span *ngIf="row.range" class="options-range">
        <span *ngFor="let r of row.range">{{ r }}</span>
      </span>
      <span *ngIf="!row.value && !row.range" class="options-value">-</span>
      <span *ngIf="row.label" class="options-label">{{ row.label }}</span>
      <span *ngIf="!row.label" class="options-label">-</span>
    </div>
  </div>
</ng-template>