import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { DriveItemModel, PrivilegeEnum } from '@app/api';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ApiService, FormUtils, ProjectService } from '@app/core';
import { UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { FormComponent } from '@app/core/utils/form-component';
import { IndividualPrivilege, IndividualPrivilegeService, Role } from '../../privilege-matrix';
import { TranslateService } from '@ngx-translate/core';

interface IndividualPrivilegeEntity {
  individualPrivileges: IndividualPrivilege[];
}

@Component({
  selector: 'app-drive-item-privileges',
  templateUrl: './drive-item-privileges.component.html',
  styleUrls: ['./drive-item-privileges.component.scss'],
})
export class DriveItemPrivilegesComponent extends FormComponent implements OnInit, Busy {
  static async openForDriveItem(dialog: MatDialog, driveItem: DriveItemModel) {
    const dialogRef = dialog.open(DriveItemPrivilegesComponent, {
      disableClose: true,
      data: {
        driveItem,
      },
    });

    return await dialogRef.afterClosed().toPromise();
  }

  isBusy: boolean = false;

  individualPrivilegeService: IndividualPrivilegeService;
  canSave: boolean = false;

  private projectId: string;
  private driveItem: DriveItemModel;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    dialog: MatDialog,
    private apiService: ApiService,
    private dialogRef: MatDialogRef<DriveItemPrivilegesComponent>,
    private formBuilder: UntypedFormBuilder,
    private projectService: ProjectService,
    private userNotification: UserNotificationService,
    translateService: TranslateService
  ) {
    super();
    this.individualPrivilegeService = new IndividualPrivilegeService(dialog, formBuilder, translateService);
    this.driveItem = data.driveItem;
  }

  get title(): string {
    return this.driveItem.name;
  }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      individualPrivileges: this.formBuilder.array([]),
    });

    this.subscribe(this.projectService.projectId$, async projectId => {
      if (!projectId) {
        this.fireClose();
        throw 'Project id is not set!';
      }

      this.projectId = projectId;

      try {
        await this.loadData();
      } catch (e) {
        this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.privilege', e);
        this.fireClose();
      }
    });
  }

  private async loadData() {
    const [userPrivileges, roles, assignedUsers, privilegeResult] = await Promise.all([
      this.apiService.getUserPrivileges(),
      this.apiService.getRoles(true, true),
      this.apiService.getAssignedUsersForProject(this.projectId),
      this.apiService.getItemPrivileges(this.driveItem.id, this.driveItem.resourceIdentifier.key.name),
    ]);

    const privileges = privilegeResult.privileges;
    const driveItemPrivileges = privilegeResult.driveItemPrivileges;

    const singlePrivileges = privileges.filter(p => p.single);
    const multiPrivileges = privileges.filter(p => !p.single);

    const parentValues: Record<string, number> = {};
    const individualPrivileges = FormUtils.getFormControl<IndividualPrivilegeEntity, UntypedFormArray>(
      this.form,
      'individualPrivileges'
    );

    const roleAndUserIds = roles.map(r => r.id).concat(assignedUsers.map(u => u.id));
    for (const individualPrivilegeId of Object.keys(driveItemPrivileges)) {
      if (!roleAndUserIds.includes(individualPrivilegeId)) continue;

      const privilege = driveItemPrivileges[individualPrivilegeId];
      if (!privilege.isImplicit) {
        const privilegeForm = IndividualPrivilegeService.getIndividualPrivilegeGroup(
          this.formBuilder,
          individualPrivilegeId,
          privilege.number,
          singlePrivileges
        );

        individualPrivileges.push(privilegeForm);
      }

      if (privilege.isImplicit || privilege.implicit)
        parentValues[individualPrivilegeId] = privilege.implicit?.number ?? privilege.number;
    }

    if (privilegeResult.isReadOnly) individualPrivileges.disable({ emitEvent: false });

    this.individualPrivilegeService.setData(
      singlePrivileges,
      multiPrivileges,
      individualPrivileges,
      parentValues,
      roles as Role[],
      assignedUsers
    );

    this.canSave = userPrivileges.some(x => x === PrivilegeEnum.ReadWriteDriveItemPrivileges);
    if (!this.canSave) this.individualPrivilegeService.disable();
  }

  async fireClose() {
    this.dialogRef.close(false);
  }

  async fireSave() {
    using(new BusyScope(this), async _ => {
      const mappings = this.parseForm();

      await this.apiService.setItemPrivileges(
        this.driveItem.id,
        this.driveItem.resourceIdentifier.key.name,
        this.driveItem.path,
        mappings
      );

      this.dialogRef.close(true);
    }).catch(e => {
      this.userNotification.notify('dialogs.privileges.error.save', { error: e });
    });
  }

  private parseForm() {
    const form = FormUtils.getFormControl<IndividualPrivilegeEntity, UntypedFormArray>(this.form, 'individualPrivileges');
    return IndividualPrivilegeService.parseIndividualPrivileges(form);
  }
}
