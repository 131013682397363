export enum FileType {
  CSV_EXCEL = 'application/vnd.ms-excel',
  CSV_TEXT = 'text/csv',
}

export enum FileExtension {
  PDF = '.pdf',
  CSV = '.csv',
}

export enum TaskLikeDialogTabType {
  details = 'details',
  history = 'history',
  files = 'files',
}

export enum EntityDetailDialogTabType {
  details = 'details',
  history = 'history',
  files = 'files',
}

export enum TextareaResizeType {
  none = 'none',
  horizontal = 'horizontal',
  vertical = 'vertical',
  both = 'both',
}

export enum RoomFilterType {
  room = 'room',
  floor = 'floor',
  area = 'area',
}

export enum FileGroup {
  audio = 'audio/*',
  video = 'video/*',
  image = 'image/*',
}

export enum Orientation {
  vertical = 'vertical',
  horizontal = 'horizontal',
}
