import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-info-sidebar',
  templateUrl: './info-sidebar.component.html',
  styleUrls: ['./info-sidebar.component.scss'],
})
export class InfoSidebarComponent {
  @Input() isOpen = false;
  @Input() isHidden = false;
  @Output() isHiddenChange = new EventEmitter<boolean>();

  hide() {
    this.isHidden = true;
    this.isHiddenChange.emit(true);
  }

  unhide() {
    this.isHidden = false;
    this.isHiddenChange.emit(false);
  }
}
