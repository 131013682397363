import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { GlobalsService } from './globals.service';

const sidebarClosed = 'sidenav-closed';
const sidebarMinifiedClass = 'minified-nav';

@Injectable({
  providedIn: 'root',
})
export class SideBarService {
  sideBarOpenState$ = new BehaviorSubject<boolean>(true); //.pipe(distinctUntilChanged());
  sideBarMinifiedState$ = new BehaviorSubject<boolean>(false); //.pipe(distinctUntilChanged());

  private latestSideBarMinifiedStateByUser: boolean = false;

  constructor(private globals: GlobalsService) {}

  open() {
    this.sideBarOpenState$.next(true);
    document.body.classList.remove(sidebarClosed);
  }

  close() {
    this.sideBarOpenState$.next(false);
    document.body.classList.add(sidebarClosed);
  }

  toggleOpenState() {
    const isSidebarOpen = this.sideBarOpenState$.value;
    if (isSidebarOpen) this.close();
    else this.open();
  }

  enlarge(isUserAction: boolean = false) {
    if (isUserAction) this.latestSideBarMinifiedStateByUser = false;
    this.sideBarMinifiedState$.next(false);
    document.body.classList.remove(sidebarMinifiedClass);
  }

  minify(isUserAction: boolean = false) {
    if (this.globals.isMobileMenu) return;
    if (isUserAction) this.latestSideBarMinifiedStateByUser = true;
    this.sideBarMinifiedState$.next(true);
    document.body.classList.add(sidebarMinifiedClass);
  }

  toggleMinifiedState(isUserAction: boolean = false) {
    const isSidebarMinified = this.sideBarMinifiedState$.value;
    if (isSidebarMinified) this.enlarge(isUserAction);
    else this.minify(isUserAction);
  }

  restoreUserSidebarMinifiedState() {
    if (this.latestSideBarMinifiedStateByUser) this.minify();
    else this.enlarge();
  }
}
