<div class="mail-notification-dialog c4-dialog-wrapper medium-width">
  <h1 mat-dialog-title>{{ titleLabel | translate }}</h1>
  <mat-dialog-content class="mail-notification-dialog-content" [formGroup]="form">
    <mat-expansion-panel class="slim-expansion-panel no-shrink" expanded>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="panel-title">
            {{ 'dialogs.fileNotification.header' | translate }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="item-list-holder">
        <ul class="item-list">
          <li class='item' *ngFor="let name of dialogParams.itemNames">{{name}}</li>
        </ul>
      </div>
    </mat-expansion-panel>
    <div class="overflow-container user-chip-list-container">
      <mat-form-field class="user-chip-list">
        <mat-chip-list #chipList aria-label="User selection">
          <mat-chip *ngFor="let user of selectedUsers; let i = index" [selectable]="true" [removable]="true"
                    (removed)="removeUser(i)" [matTooltip]="user.emailAddress">
            <button *ngIf="user.type === receiverType.Role" class="custom-icon-button" mat-icon-button>
              <mat-icon (click)="splitGroup(user)" [matTooltip]="'dialogs.fileNotification.splitGroup' | translate"
                        matTooltipPosition="above" matChipAdd>add</mat-icon>
            </button>
            <span class="chip-label">
              {{user.label}}
            </span>
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input (keydown)="onKeyDownChipListInput($event)"
                 placeholder="{{'dialogs.fileNotification.recipients' | translate}}" #userInput
                 #trigger="matAutocompleteTrigger" [matAutocomplete]="autoUser" [matChipInputFor]="chipList"
                 (focus)="autofilter()" (blur)="checkForChiplistError()" (input)="autofilter($event.target.value)"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addCustomEmail($event)">
        </mat-chip-list>
        <mat-autocomplete autoActiveFirstOption #autoUser="matAutocomplete" (optionSelected)="selected($event)">
          <mat-option class="detailed-option" *ngFor="let user of autoUsers" [value]="user">
            <span class="option-title">{{user.label}}</span>
            <span *ngIf="user.type === receiverType.User" class="option-subtitle">{{user.emailAddress}}</span>
            <span *ngIf="user.type === receiverType.Role" class="option-subtitle">{{'general.userRole' |
              translate}}</span>
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="f.to.invalid">
          {{ 'general.errorForms.' + getError('to') | translate }}
        </mat-error>
      </mat-form-field>
    </div>
    <mat-form-field *ngIf="isCustomMessageSupported" class="notify-subject">
      <input formControlName="subject" matInput type="text"
             placeholder="{{'dialogs.fileNotification.subject' | translate}}">
      <mat-error *ngIf="f.subject.invalid">
        {{ 'general.errorForms.' + getError('subject') | translate }}
      </mat-error>
    </mat-form-field>
    <div *ngIf="isCustomMessageSupported" class="overflow-container">
      <mat-form-field class="text-input">
        <mat-label>{{'dialogs.fileNotification.message' | translate}}</mat-label>
        <textarea (focus)="onFocusTextInput()" formControlName="message" matInput #messageText cdkTextareaAutosize
                  cdkAutosizeMinRows="5"></textarea>
        <span class="standard-text-button mdi mdi-plus" (click)="addStandardText()"
              [matTooltip]="'standardTexts.select' | translate" [class.disabled]="form.disabled"></span>
      </mat-form-field>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button (click)="cancel()" type="button">{{ cancelLabel | translate }}</button>
    <button mat-flat-button color="primary" (click)="ok()" type="button" [disabled]="form.invalid">{{
      'dialogs.fileNotification.send' | translate
      }}</button>
  </mat-dialog-actions>
</div>
<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: true }"></ngx-loading>