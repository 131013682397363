<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:true }"></ngx-loading>
<div class="c4-dialog-wrapper permission-dlg">
  <div class="mat-dialog-title" cdkFocusInitial>
    <h1 class="mat-dialog-title">{{ 'documents.getPrivileges' | translate }}: {{ title }}</h1>
  </div>
  <mat-dialog-content class="dialog-content">
    <app-privilege-matrix class="matrix" [service]="individualPrivilegeService">
    </app-privilege-matrix>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button type="button" (click)="fireClose()">
      {{ 'general.cancel' | translate }}
    </button>
    <button *ngIf="canSave" mat-flat-button color="primary" (click)="fireSave()" color="primary"
            [disabled]="!form.dirty">
      {{ 'general.saveAndClose' | translate }}
    </button>
  </mat-dialog-actions>
</div>