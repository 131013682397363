<div class="dlg-header">
	<h1 mat-dialog-title>{{ title | translate}}</h1>
	<button mat-icon-button class="c4-no-focus dlg-ctrl-icon" (click)="cancel()">
		<span class="mdi mdi-window-close ctrl-ico"></span>
	</button>
</div>

<mat-dialog-content>
	<div class="dlg-description">{{ 'grid.edit.description' | translate }}</div>
	<div cdkDropList class="col-list" (cdkDropListDropped)="drop($event)">
		<div class="example-box col-field" *ngFor="let col of columns" cdkDrag cdkDragLockAxis="y"
				 [ngClass]="col.hidden?'disabled':'enabled'">
			<span class="drag-label" *ngIf="col.header">{{col.header|translate}}</span>
			<div class="system-column" *ngIf="!col.header" matTooltip="{{ 'grid.edit.systemFieldTooltip' | translate }}">
				<i class="system-field mdi mdi-chip"></i>
				<span class="drag-label">{{'grid.sys.' + col.field | translate}}</span>
			</div>
			<span class="btn-like" *ngIf="!col.hidden && col.header" (click)="col.hidden=true"><i
					 class="mdi mdi-minus-box-outline"></i></span>
			<span class="btn-like" *ngIf="col.hidden" (click)="col.hidden=false"><i class="mdi mdi-plus-box"></i></span>
		</div>
	</div>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
	<button mat-flat-button color="primary" (click)="ok()" type="button">{{ 'general.ok' | translate }}</button>
</mat-dialog-actions>