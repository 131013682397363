import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray } from '@angular/forms';
import { ConfigType, IssueState } from '@app/api';
import { BaseSubscriptionComponent, FormUtils } from '@app/core';
import { PcfBim, PcfRole, PcfRoles, ProjectConfigService } from '@app/shared/services/project-config';
import { TranslateService } from '@ngx-translate/core';
import { StateSchemaService } from '../../state-matrix';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-bim-config',
  templateUrl: './bim-config.component.html',
  styleUrls: ['./bim-config.component.scss'],
})
export class BimConfigComponent extends BaseSubscriptionComponent implements OnInit {
  stateSchemaService: StateSchemaService<IssueState>;

  constructor(
    private configService: ProjectConfigService,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {
    super();
  }

  get form() {
    return this.configService.bimForm;
  }

  get f() {
    return this.form.controls;
  }

  get planSchemas() {
    return this.configService.planSchemes;
  }

  get canProvision() {
    return this.configService.canProvision;
  }

  get canEditSchema() {
    return this.configService.canEditBimSchema;
  }

  get isTemplate() {
    return this.configService.isTemplate;
  }

  get states() {
    return FormUtils.getFormControl<PcfBim, UntypedFormArray>(this.form, 'states');
  }

  ngOnInit() {
    const issueStates = Object.values(IssueState);
    const mapRoles = (roles: PcfRole[]) => roles.map(r => this.configService.roleAndUserData[r.id] ?? r);
    const rolesForm = FormUtils.getFormControl<PcfRoles, UntypedFormArray>(this.configService.rolesForm, 'roles');
    const roles = rolesForm.value ?? [];

    this.stateSchemaService = new StateSchemaService<IssueState>(
      this.states,
      issueStates,
      this.configService.issueStatePredefinedRoles.concat(mapRoles(roles)),
      this.configService.issueStatePermissions,
      'issues.state.',
      this.dialog,
      this.translateService
    );

    this.subscribe(rolesForm.valueChanges, (updatedRoles: PcfRole[]) => {
      this.stateSchemaService.setRoles(this.configService.issueStatePredefinedRoles.concat(mapRoles(updatedRoles)));
      this.stateSchemaService.permissions = this.configService.issueStatePermissions;
    });
  }

  getError(control: AbstractControl) {
    return Object.keys(control.errors)[0];
  }

  async editPlanSchema() {
    this.configService.editPlanSchema(ConfigType.BimSchema);
  }
}
