<div class="c4-dialog-wrapper">
	<h1 mat-dialog-title>{{ title | translate }}</h1>
	<mat-dialog-content class="content-container" [class.column-layout]="isColumnLayout">
		<div class="image-container">
			<div class="overlay-container">
				<app-drag-hover-overlay [validTypes]="validTypes" (filesDropped)="selectImage($event[0])">
					<div *ngIf="!fallbackTemplate; else fallbackTemplate" class="c4-bg-dashed"
							 [style.width.rem]="previewImageWidth" [style.height.rem]="previewImageHeight"></div>
					<div *ngIf="image && image.length > 0" class="image"
							 [style.background-image]="'url(data:image/png;base64,'+ image +')'">
					</div>
				</app-drag-hover-overlay>
			</div>
			<a class="c4-href-like" (click)="image = null">{{ 'general.reset' | translate }}</a>
		</div>

		<div class="upload-container" [style.max-width.rem]="isColumnLayout ? previewImageWidth : 36">
			<div class="action-container">
				<button mat-stroked-button color="primary" (click)="fileInput.click()" cdkFocusInitial>
					{{ 'general.actions.uploadImage' | translate }}
				</button>
				<mat-slide-toggle color="primary" [checked]="shouldImageCover" (change)="onImageCoverChange($event)"
													[disabled]="!imageFile">
					{{ 'setup.customization.logoCut' | translate }}
				</mat-slide-toggle>
			</div>
			<span *ngIf="description">
				{{ description | translate }}
			</span>
		</div>

		<input #fileInput style="display: none;" type="file" (click)="fileInput.value = null"
					 (change)="selectImage($event.target.files[0])" [accept]="validTypes" />
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
		<button mat-flat-button color="primary" (click)="confirm()" type="button">
			{{ 'general.confirm' | translate }}
		</button>
	</mat-dialog-actions>
</div>