import { Pipe, PipeTransform } from '@angular/core';
import { CustomizationService } from '../services';

export enum FallbackColorType {
  none = 'none',
  primary = 'primary',
  accent = 'accent',
  white = 'white',
}

@Pipe({
  name: 'hexColor',
})
export class HexColorPipe implements PipeTransform {
  private previousTransformed: string;

  constructor(private customizationService: CustomizationService) {}

  transform(value: number | string, fallbackColorType: FallbackColorType = FallbackColorType.none): string {
    let color = '';
    if (!!value) {
      color = value.toString().trim();
      color = color[0] == '#' ? color : `#${color}`;
      color = color.substring(0, 7);
    }

    let fallbackColor = null;
    switch (fallbackColorType) {
      case FallbackColorType.primary:
        fallbackColor = this.customizationService.primaryHexColor;
        break;
      case FallbackColorType.accent:
        fallbackColor = this.customizationService.accentHexColor;
        break;
      case FallbackColorType.white:
        fallbackColor = 'ffffff';
        break;
    }

    const isValidHex = color.match(/^#(([0-9a-fA-F]{2}){3}|([0-9a-fA-F]){3})$/);
    this.previousTransformed = isValidHex ? color : `#${fallbackColor}`;
    return this.previousTransformed;
  }
}
