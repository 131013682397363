import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { ApiService } from '@app/core/services';
import { UserNotificationService } from '@app/shared/services';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class ProjectGuard {
  constructor(private apiService: ApiService, private router: Router, private userNotification: UserNotificationService) {}

  async canActivateChild(next: ActivatedRouteSnapshot) {
    return await this.checkRoute(next);
  }

  async canActivate(next: ActivatedRouteSnapshot) {
    return await this.checkRoute(next);
  }

  private async checkRoute(next: ActivatedRouteSnapshot) {
    const projectId = next?.params.projectId as string;
    const hasAccess = await firstValueFrom(this.apiService.hasAccessToProject(projectId));

    if (!hasAccess) {
      await this.userNotification.notify('login.companyNotAllowed');
      return this.router.createUrlTree(['projects']);
    }

    return true;
  }
}
