import { Injectable } from '@angular/core';
import { ApiService } from '../api';

@Injectable({
  providedIn: 'root',
})
export class BridgeService {
  private api: ApiService | null;

  registerApiService(api: ApiService) {
    this.api = api;
  }

  invoke<T>(fn: (api: ApiService) => T) {
    if (this.api) {
      return fn(this.api);
    }

    throw new Error('Api Service was not ready in time');
  }
}
