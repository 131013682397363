export enum LocationType {
  Area = 'Area',
  Region = 'Region',
  Floor = 'Floor',
  Room = 'Room',
  ZoneGroup = 'ZoneGroup',
  Zone = 'Zone',
}

export interface NamedEntity {
  id?: string;
  name: string;
}

export interface SelectedRoom {
  id: string;
  floorId: string;
}

interface NamedEntityWithNumber extends NamedEntity {
  internalNumber?: string;
}

export interface IReportOptions {
  categoryIds?: string[];
  roomsIds: string[];
}
