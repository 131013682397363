import { Injectable } from '@angular/core';
import { UserNotificationService } from '../user-notification/user-notification.service';
import { LogService, ApiService, AppConfigService, Utils } from '@app/core';
import { DocumentUploadData } from './document-upload-interfaces';
import { ConflictBehavior, DriveActionMetadata, DriveUploadResultModel, ProblemDetailsErrorType } from '@app/api';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { NameConflictDialogComponent, ConflictResolveAction } from './name-conflict-dialog/name-conflict-dialog.component';
import { FileNameFormat } from '@app/core/utils/file-name-format';
import imageCompression, { Options } from 'browser-image-compression';
import { ConfirmDialogComponent } from '@app/shared/components';

interface UploadDocumentOptions {
  warnFileSizeInMb?: number;
  customFileSizeWarnMessage?: string;
  metadata?: DriveActionMetadata;
}

@Injectable({
  providedIn: 'root',
})
export class DocumentUploadService {
  constructor(
    private apiService: ApiService,
    private userNotification: UserNotificationService,
    private log: LogService,
    private dialog: MatDialog
  ) {}

  async decodeFiles(
    files: File[],
    uploadPath: string,
    resource: string,
    compressFile: boolean = false,
    format: FileNameFormat = null,
    renameCondition: FileValidationFunction = _ => false
  ): Promise<DocumentUploadData[]> {
    return await this.processFiles(files, uploadPath, resource, compressFile, format, renameCondition);
  }

  async decodeDropEvent(
    event: any,
    uploadPath: string,
    resource: string,
    compressFile: boolean = false,
    format: FileNameFormat = null,
    renameCondition: FileValidationFunction = _ => false
  ): Promise<DocumentUploadData[]> {
    const files = await Utils.decodeDropEvent(event, this.userNotification);
    return await this.processFiles(files, uploadPath, resource, compressFile, format, renameCondition);
  }

  private fileToUploadData(
    file: File,
    uploadPath: string,
    resource: string,
    format: FileNameFormat,
    renameCondition: FileValidationFunction,
    index: number
  ): DocumentUploadData {
    let formattedfileName = file.name;

    if (format && renameCondition(file)) {
      let parameter = [];
      if (index != 0) parameter.push(` (${index})`);
      formattedfileName = format.getFormattedString(parameter, formattedfileName.split('.').pop());
    }

    return {
      lastModified: file.lastModified,
      fileName: formattedfileName,
      sizeInBytes: file.size,
      data: file,
      uploadPath,
      resource,
    };
  }

  async uploadDocuments(
    documents: DocumentUploadData[],
    progressCallback?: (currentFile: number, totalFiles: number, progressPercent?: number) => void,
    options: UploadDocumentOptions = null
  ) {
    if (!documents) {
      return;
    }

    //check doc count limit
    if (documents.length > AppConfigService.settings.api.upload.maxFilesAtOnce) {
      await this.userNotification.notify('documents.errorMaxFilesAtOnceExceeded', {
        params: {
          maxFilesAtOnce: AppConfigService.settings.api.upload.maxFilesAtOnce,
        },
      });
      return;
    }

    //check file size limit, calculate total file size
    let totalFileSizeInBytes: number = 0;
    const maxFileSizeInBytes = AppConfigService.settings.api.upload.maxFileSizeInMb * 1024 * 1024;
    const warnFileSizeInBytes = (options?.warnFileSizeInMb ?? 0) * 1024 * 1024;
    for (const document of documents) {
      if (document.sizeInBytes > maxFileSizeInBytes) {
        await this.userNotification.notify('documents.errorMaxFileSizeInMbExceeded', {
          params: {
            file: document.fileName,
            maxFileSizeInMb: AppConfigService.settings.api.upload.maxFileSizeInMb,
          },
        });
        return;
      }

      totalFileSizeInBytes += document.sizeInBytes;
    }

    if (warnFileSizeInBytes > 0 && documents.some(d => d.sizeInBytes > warnFileSizeInBytes)) {
      const hasConfirmed = await this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            title: 'general.warning',
            description: options.customFileSizeWarnMessage ?? 'documents.warningLargeFiles',
          },
        })
        .afterClosed()
        .toPromise();

      if (!hasConfirmed) return;
    }

    const response: DriveUploadResultModel[] = [];
    //upload files, report progress
    let processedFileSizeInBytes: number = 0;
    for (let i = 0; i < documents.length; i++) {
      const document = documents[i];
      const currentFileSizeInBytes: number = document.sizeInBytes;
      const result = await this.uploadDocument(
        document,
        (progressPercent: number) => {
          if (progressCallback) {
            progressCallback(
              i + 1,
              documents.length,
              (processedFileSizeInBytes + currentFileSizeInBytes * progressPercent) / totalFileSizeInBytes
            );
          }
        },
        options?.metadata
      );
      processedFileSizeInBytes += currentFileSizeInBytes;
      if (result) response.push(result);
    }
    return response;
  }

  private async uploadDocument(
    document: DocumentUploadData,
    progressCallback: (progressPercent: number) => void,
    metadata: DriveActionMetadata = null,
    nameExistsBehavior: ConflictBehavior = ConflictBehavior.Fail
  ): Promise<DriveUploadResultModel> {
    let uploadPath = document.uploadPath;
    if (!uploadPath.startsWith('/')) {
      uploadPath = `/${uploadPath}`;
    }
    let result: DriveUploadResultModel;
    const useChunkedUploadThresholdInBytes = AppConfigService.settings.api.upload.useChunkedUploadThresholdInMb * 1024 * 1024;
    this.log.debug(`Uploading ${document.fileName} to folder ${uploadPath}`, event);
    try {
      if (!metadata) metadata = new DriveActionMetadata();
      metadata.lastModified = new Date(document.lastModified);

      if (useChunkedUploadThresholdInBytes <= document.sizeInBytes) {
        result = await this.apiService.uploadDriveItemFileInChunks(
          document.fileName,
          document.sizeInBytes,
          nameExistsBehavior,
          document.data,
          uploadPath,
          document.resource,
          metadata,
          progressCallback
        );
      } else {
        result = await this.apiService.uploadDriveItemFile(
          document.fileName,
          nameExistsBehavior,
          document.data,
          uploadPath,
          document.resource,
          metadata,
          progressCallback
        );
      }
      await this.userNotification.notify('documents.uploadSuccess', {
        params: {
          file: result.fileName,
        },
      });
      return result;
    } catch (e) {
      const error = e.error ?? e;
      if (error?.type) {
        switch (error.type) {
          case ProblemDetailsErrorType.Conflict:
            if (nameExistsBehavior !== ConflictBehavior.Fail) break;

            this.log.info(`Failed to upload ${document.fileName} - name is in use`, e);

            //show dialog with ABORT (-> continue with next file) OVERWRITE (-> call self with replace) KEEP BOTH (-> call self with )
            const dialogRef = this.dialog.open(NameConflictDialogComponent, {
              data: {
                fileName: document.fileName,
              },
            });
            const resolveAction = await dialogRef.afterClosed().toPromise();

            if (ConflictResolveAction.KeepBoth === resolveAction) {
              return await this.uploadDocument(document, progressCallback, metadata, ConflictBehavior.Rename);
            } else if (ConflictResolveAction.Replace === resolveAction) {
              return await this.uploadDocument(document, progressCallback, metadata, ConflictBehavior.Replace);
            } else {
              return;
            }
          case ProblemDetailsErrorType.BlocklistedName:
            this.log.error(`Failed to upload ${document.fileName} - name is invalid`, e);
            await this.userNotification.notify('documents.errorUploadFailedBlacklistedFileName', {
              params: {
                file: document.fileName,
              },
            });
            return;
        }
      }

      this.log.error(`Failed to upload ${document.fileName}`, e);
      await this.userNotification.notify('documents.errorUploadFailed', {
        params: {
          file: document.fileName,
        },
      });
    }
  }

  private async processFiles(
    files: File[],
    uploadPath: string,
    resource: string,
    compressFile: boolean,
    format: FileNameFormat,
    renameCondition: FileValidationFunction
  ) {
    const uploadItems: DocumentUploadData[] = [];
    const options: Options = { useWebWorker: true };

    if (compressFile) {
      const tenantSettings = await this.apiService.getTenantSettings();
      const maxWidthOrHeight = tenantSettings.maxImageResolution;
      const maxImageSize = tenantSettings.maxImageSizeInMB;

      if (!!maxImageSize) options.maxSizeMB = maxImageSize;
      else if (!!maxWidthOrHeight) options.maxWidthOrHeight = maxWidthOrHeight;
    }

    for (let i = 0; i < files.length; i++) {
      try {
        const processedFile = compressFile ? await imageCompression(files[i], options) : files[i];
        uploadItems.push(this.fileToUploadData(processedFile, uploadPath, resource, format, renameCondition, i));
      } catch (error) {
        this.userNotification.notify('general.errorMsg.compressFailed', { error: error });
        return null;
      }
    }

    return uploadItems;
  }
}
