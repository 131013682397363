import { PlanFieldModel, PlanOptionModel } from '@app/api';

export const _planSeparator: string = '_';

export enum PlanListHeaderActions {
  SaveView = 'saveView',
  EditGrid = 'editGrid',
  ShowColumns = 'showColumns',
  DownloadSelected = 'downloadSelected',
  TogglePlanView = 'togglePlanView',
  Upload = 'upload',
  Close = 'close',
  CSV = 'csv',
  Schema = 'viewSchema',
  PDF = 'pdf',
  Reset = 'reset',
  Share = 'share',
  ZipUrl = 'zipUrl',
  Logs = 'logs',
}

export enum PlanCodes {
  Approval = 'state',
}

export enum PlanApproval {
  ApprovedCode = 'F',
  ApprovedLabel = 'Freigegeben',
  AuditCode = 'P',
  AuditLabel = 'Zur Prüfung',
  PreliminaryCode = 'V',
  PreliminaryLabel = 'Vorabzug',
}

export interface ExtendedPlanFieldModel extends PlanFieldModel {
  isRange?: boolean;
  acOptions?: PlanOptionModel[];
}

export enum PlanValidationError {
  indexTooLow = 'indexTooLow',
  inputTooLong = 'inputTooLong',
  valueNotFound = 'valueNotFound',
}

export interface PlanValidation {
  field: string;
  value: string;
  valid: boolean;
  error?: PlanValidationError;
  autoIndexed?: boolean;
  isSeparator: boolean;
}
