import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserSessionRequiredActionType } from '@app/api';
import { ApiService, LogService } from '@app/core/services';
import { Observable } from 'rxjs';

@Injectable()
export class SetupGuard {
  constructor(private log: LogService, private router: Router, private apiService: ApiService) {}

  canActivate() {
    return this.checkRoute();
  }

  canActivateChild() {
    return this.checkRoute();
  }

  async checkRoute() {
    const session = await this.apiService.getUserSession();
    const actions = session.requiredActions;

    if (actions && actions.length > 0) {
      this.log.debug('RequiredActions', session.requiredActions);
    }

    if (session.requiredActions) {
      for (let requiredAction of session.requiredActions || []) {
        if (requiredAction.key == UserSessionRequiredActionType.TenantSetup) {
          await this.router.navigate(['admin', 'setup']);

          return false;
        }
      }
    }

    return true;
  }
}
