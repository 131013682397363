<div class="c4-dialog-wrapper edit-state-dialog">
	<h1 mat-dialog-title>{{ title | translate }}</h1>

	<form [formGroup]="form">
		<mat-dialog-content class="content">
			<mat-form-field>
				<input matInput [placeholder]="'general.code' | translate" formControlName="code">
				<mat-error *ngIf="f.code.invalid">
					{{ 'general.errorForms.' + getError('code') | translate }}
				</mat-error>
			</mat-form-field>

			<mat-form-field>
				<input matInput [placeholder]="'general.name' | translate" formControlName="title">
				<mat-error *ngIf="f.title.invalid">
					{{ 'general.errorForms.' + getError('title') | translate }}
				</mat-error>
			</mat-form-field>

			<mat-form-field>
				<mat-label>{{ 'states.type' | translate }}</mat-label>
				<mat-select formControlName="stateType">
					<mat-select-trigger>
						<!-- class="ico-state-select color mdi mdi-checkbox-blank-circle" -->
						<span [class]="f.stateType.value"></span>
						<!-- {{ 'dialogs.defects.state.' + f.state.value | translate }} -->
						{{ (translationPrefix + f.stateType.value) | translate }}
						<!-- <span>{{ selectLabel }}</span> -->
					</mat-select-trigger>
					<!-- <mat-option *ngFor="let state of stateTypes" [value]="state.value" [disabled]="state.isDisabled"> -->
					<mat-option *ngFor="let stateType of stateTypes" [value]="stateType">
						<!-- <span class="defect">
						</span> -->
						<!-- class="ico-select color mdi mdi-checkbox-blank-circle" -->
						<span [class]="stateType"></span>
						<!-- {{ 'dialogs.defects.state.' + state.value | translate }} -->
						{{ (translationPrefix + stateType) | translate }}
					</mat-option>
				</mat-select>
				<mat-error *ngIf="f.title.invalid">
					{{ 'general.errorForms.' + getError('title') | translate }}
				</mat-error>
			</mat-form-field>

			<app-color-picker-form-field [showFallback]="false" [isRequired]="true"
																	 formControlName="hexColor"></app-color-picker-form-field>

		</mat-dialog-content>

		<mat-dialog-actions>
			<button mat-flat-button type="button" (click)="cancel()">{{ 'general.cancel' | translate }}</button>
			<button mat-flat-button type="button" color="primary" [disabled]="form.invalid" (click)="confirm()">
				{{ 'general.ok' | translate }}
			</button>
		</mat-dialog-actions>
	</form>
</div>