import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-expandable-header',
  templateUrl: './expandable-header.component.html',
  styleUrls: ['./expandable-header.component.scss'],
})
export class ExpandableHeaderComponent {
  @Input() isExpanded = false;
  @Output() close = new EventEmitter<void>();
}
