import { Component, OnInit } from '@angular/core';
import { ProjectConfigService } from '@app/shared/services/project-config';

@Component({
  selector: 'app-ms-teams-config',
  templateUrl: './ms-teams-config.component.html',
  styleUrls: ['./ms-teams-config.component.scss'],
})
export class MsTeamsConfigComponent {
  constructor(private configService: ProjectConfigService) {}

  get form() {
    return this.configService.msTeamsForm;
  }
}
