<mat-form-field class="date-range-form-field" [floatLabel]="floatLabel">
	<mat-date-range-input [formGroup]="form" [rangePicker]="picker">
		<input matStartDate formControlName="from" [placeholder]="'general.date.from' | translate">
		<input matEndDate formControlName="to" [placeholder]="'general.date.to' | translate">
	</mat-date-range-input>
	<mat-date-range-picker #picker></mat-date-range-picker>
	<button mat-icon-button matSuffix (click)="picker.open()">
		<span class="mdi mdi-calendar-weekend"></span>
	</button>
	<button mat-icon-button matSuffix [matMenuTriggerFor]="calendarMenu" (click)="$event.stopPropagation()">
		<span class="mdi mdi-calendar-star"></span>
	</button>
	<mat-menu #calendarMenu="matMenu">
		<button mat-menu-item *ngFor="let filter of dateFilterPresets" (click)="setDateFilter(filter)">
			{{ ('general.date.' + filter) | translate }}
		</button>
	</mat-menu>
</mat-form-field>