<form [formGroup]="form">
  <div class="form">
    <div *ngIf="isAssigned" class="organization-assigned-msg">
      {{ 'dialogs.organization.alreadyAssigned' | translate }}
    </div>

    <div *ngIf="!isAddOrEdit" class="organization-selection">
      <app-search-select [title]="'dialogs.organization.select'" [selectList]="existingOrganizations"
                         [selectFormGroup]="form" [selectFormControlName]="'selectedOrganization'" [addSelectNew]="true"
                         (addClicked)="addOrganization(); nameInput.focus()"
                         [optionTemplate]="searchSelectOptionTemplate">
      </app-search-select>
    </div>

    <div class="form optional" [style.max-height]="!f.selectedOrganization.value && !isAddOrEdit ? 0 : '100vh'">
      <mat-form-field>
        <mat-label>
          <span>{{ 'dialogs.organization.name' | translate }}</span>
        </mat-label>
        <input matInput formControlName="name" #nameInput>
        <mat-error *ngIf="f.name.invalid">
          {{ 'general.errorForms.' + getError('name') | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <mat-label>
          <span>{{ 'dialogs.organization.code' | translate }}</span>
        </mat-label>
        <input matInput formControlName="code" #nameInput>
        <mat-error *ngIf="f.code.invalid">
          {{ 'general.errorForms.' + getError('code') | translate }}
        </mat-error>
      </mat-form-field>

      <ng-container [ngTemplateOutlet]="addressFieldTemplate" [ngTemplateOutletContext]="{
        fieldName: 'street'
      }"></ng-container>

      <div class="split-container">
        <ng-container [ngTemplateOutlet]="addressFieldTemplate" [ngTemplateOutletContext]="{
          fieldName: 'zipCode'
        }"></ng-container>
        <ng-container [ngTemplateOutlet]="addressFieldTemplate" [ngTemplateOutletContext]="{
          fieldName: 'city'
        }"></ng-container>
      </div>

      <ng-container [ngTemplateOutlet]="addressFieldTemplate" [ngTemplateOutletContext]="{
        fieldName: 'country'
      }"></ng-container>

      <mat-form-field>
        <mat-label>
          <span>{{ 'dialogs.organization.externalNumber' | translate }}</span>
        </mat-label>
        <input matInput formControlName="externalNumber" #nameInput>
        <mat-error *ngIf="f.externalNumber.invalid">
          {{ 'general.errorForms.' + getError('externalNumber') | translate }}
        </mat-error>
      </mat-form-field>

      <app-chips-autocomplete *ngIf="canEditCrafts" formControlName="crafts" [values]="existingCrafts"
                              [fromString]="newCraft" [label]="'dialogs.organization.crafts'" [isRequired]="false"
                              [isDisabled]="initialDisabled">
      </app-chips-autocomplete>
    </div>
  </div>
</form>

<ng-template #addressFieldTemplate let-fieldName="fieldName" [formGroup]="form">
  <mat-form-field>
    <mat-label>
      <span>{{ 'general.address.' + fieldName | translate }}</span>
    </mat-label>
    <input matInput [formControlName]="fieldName">
    <mat-error *ngIf="f[fieldName].invalid">
      {{ 'general.errorForms.' + getError(fieldName) | translate }}
    </mat-error>
  </mat-form-field>
</ng-template>

<ng-template #searchSelectOptionTemplate let-organization="item">
  <div class="organization-select-option">
    <span class="name">{{ organization.name }}</span>
    <span class="city">{{ organization.address.zipCode }} {{ organization.address.city }}</span>
  </div>
</ng-template>