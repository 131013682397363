import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FileUtils } from '@app/core/utils/file-utils';
import { UserNotificationService } from '../services/user-notification/user-notification.service';

@Directive({
  selector: '[appDragFiles]',
})
export class DragFilesDirective {
  @Input() appDragFiles: string[] = [];
  @Input() multiple: boolean = true;

  @Output() isDragOver = new EventEmitter<boolean>();
  @Output() filesDropped = new EventEmitter<File[]>();

  constructor(private userNotification: UserNotificationService) {}

  private get validTypes(): string[] {
    return this.appDragFiles;
  }

  @HostListener('drop', ['$event']) onDrop(event: DragEvent) {
    this.enableDragDrop(event, false);
    this.emitEvent(event.dataTransfer.files);
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
    this.enableDragDrop(event, false);
  }

  @HostListener('dragend', ['$event']) onDragEnd(event: DragEvent) {
    this.enableDragDrop(event, false);
  }

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    this.enableDragDrop(event, true);
  }

  @HostListener('dragenter', ['$event']) onDragEnter(event: DragEvent) {
    this.enableDragDrop(event, true);
  }

  emitEvent(fileList: FileList) {
    const files: File[] = [];

    const dropped = this.multiple ? fileList : [fileList[0]];
    for (let i = 0; i < dropped.length; i++) {
      const file = dropped[i];

      if (this.isValidFileType(file.type)) {
        files.push(file);
      }
    }

    if (dropped.length > files.length)
      this.userNotification.notify(`general.${dropped.length > 1 ? 'errorFileTypeOneOf' : 'errorFileType'}`);

    if (files.length > 0) this.filesDropped.emit(files);
  }

  private enableDragDrop(event: DragEvent, isDragOver: boolean) {
    this.isDragOver.emit(isDragOver);

    const files = event.dataTransfer.items;
    if (isDragOver && files.length > 0) {
      let areValid = this.multiple || files.length === 1;
      for (let i = 0; i < files.length; i++) {
        if (areValid && !this.isValidFileType(files[i].type)) {
          areValid = false;
          i = files.length;
        }
      }

      if (areValid) {
        event.dataTransfer.dropEffect = 'copy';
        event.dataTransfer.effectAllowed = 'copy';
      } else {
        event.dataTransfer.dropEffect = 'none';
        event.dataTransfer.effectAllowed = 'none';
      }
    }

    event.stopPropagation();
    event.preventDefault();
  }

  private isValidFileType(type: string) {
    return FileUtils.isValidType(type, this.validTypes);
  }
}
