import * as moment from "moment";

export class FileNameFormat {
    protected formatRegex: RegExp = /\{([0-9]+)\}/g;
    protected dateRegex: RegExp = /\{((?:[dmyhopsltDMYHSLTWZ]|[^a-zA-Z\d\s:])+)\}/g; //https://momentjscom.readthedocs.io/en/latest/moment/04-displaying/01-format/
    protected format: string;

    constructor(format: string) {
        this.format = format;
    }

    public getFormattedString(parameter: string[], extension: string): string {
        let formatResult = this.format.replace(this.formatRegex, (match, group) => {
            if (group in parameter)
                return parameter[group];
            else
                return ""; //Missing parameter should not result in undefined
        });

        let dateResult = formatResult.replace(this.dateRegex, (match, group) => {
            return moment().format(group);
        });

        return `${dateResult}.${extension}`;
    }
}