import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyChip as MatChip, MatLegacyChipList as MatChipList } from '@angular/material/legacy-chips';

@Component({
  selector: 'app-chips-filter',
  templateUrl: './chips-filter.component.html',
  styleUrls: ['./chips-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChipsFilterComponent implements OnInit {
  @ViewChild('chipList') chipList: MatChipList;
  @Input() preventWrap: boolean = false;
  @Input() displayField: string = 'name';
  @Input() set values(values: any[]) {
    this.isListOfStrings = typeof values[0] === 'string';
    this.chips =
      values?.map((value: any) =>
        !this.isListOfStrings
          ? value
          : {
              [this.displayField]: value,
            }
      ) ?? [];
  }

  @Input() set selectedValues(values: any[]) {
    // let chipList initialize
    setTimeout(() => {
      // set via matChip.selected not possible since matChipList doesn't recognize it, hence the next selection is
      // missing the initial selected elements
      this.chipList?.chips?.forEach(c => {
        if (values.contains(!this.isListOfStrings ? c.value : c.value[this.displayField])) c.select();
        else c.deselect();
      });
    }, 0);
  }
  @Output() selectedValuesChange = new EventEmitter<any[]>();

  chips: any[] = [];

  private isListOfStrings: boolean;

  constructor() {}

  ngOnInit(): void {}

  chipFilterChanged(chips: any[]) {
    const selectedValues = !this.isListOfStrings ? chips : chips.map(c => c[this.displayField]);
    this.selectedValuesChange.emit(selectedValues);
  }

  toggleChipSelection(chip: MatChip, event: MouseEvent) {
    if (chip.selectable && !chip.disabled) {
      chip.toggleSelected(true);
    }
    event.stopPropagation();
  }
}
