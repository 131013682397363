<div class="c4-dialog-wrapper app-notify-after-login-dialog">
  <h1 mat-dialog-title>
    <div class="app-notify-after-login-subject">
      <span class="mdi" [ngClass]="notificationCardData.icon"></span>
      <div class="app-notify-after-login-subject-data">
        <div class="app-notify-after-login-subject-message">
          {{notificationCardData.translate ? (notificationCardData.subject | translate) : notificationCardData.subject}}
        </div>
        <div class="app-notify-after-login-subject-date">
          {{ notificationCardData.date | localizedDate:'relative' }}</div>
      </div>
    </div>
    <div class="app-notify-after-login-date">{{ notificationCardData.date | localizedDate:'relative' }}</div>
  </h1>

  <mat-dialog-content [ngClass]="notificationDataHolder.busyClass">
    <app-notification [content]="notificationDataHolder.busyContentClass"
                      [notificationText]="notificationDataHolder.data" [showNotification]="isHtmlNotification"
                      [attachments]="notificationCardData.attachments"></app-notification>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button color="primary" (click)="ok()" type="button">{{ 'general.ok' | translate }}</button>
  </mat-dialog-actions>
</div>