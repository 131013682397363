<mat-form-field class="form" floatLabel="always" [formGroup]="form">
	<mat-label>{{ label ? label : ('general.hexColor' | translate) }}</mat-label>
	<div class="form-field-content">
		<input matInput [ngxMatColorPicker]="colorPicker" formControlName="color">
		<app-color-pill [showFallback]="showFallback" [color]="hexColor"
										(click)="openColorPicker(colorPicker)"></app-color-pill>
		<div class="filler"></div>
		<button *ngIf="hexColor" mat-icon-button (click)="removeColor()">
			<span class="mdi mdi-close"></span>
		</button>
		<button mat-icon-button color="primary" (click)="openColorPicker(colorPicker)">
			<span class="mdi mdi-palette"></span>
		</button>
		<ngx-mat-color-picker #colorPicker [touchUi]="true"></ngx-mat-color-picker>
	</div>
	<mat-error *ngIf="f.color.errors?.required">
		<span>{{ 'general.errorForms.required' | translate }}</span>
	</mat-error>
</mat-form-field>