import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'tryTranslate',
})
export class TranslateFallbackPipe implements PipeTransform {
  private lastTranslationKey: string;
  private lastTranslation: string;

  constructor(private translateService: TranslateService) {}

  transform(key: string, fallback: string = ''): string {
    if (key == this.lastTranslationKey) return this.lastTranslation;

    this.lastTranslationKey = key;

    const translation = this.translateService.instant(key);
    const hasTranlsation = translation !== key; // && translation !== '';

    this.lastTranslation = hasTranlsation ? translation : fallback;

    return this.lastTranslation;
  }

  hasTranslation(key: string): boolean {
    const translation = this.translateService.instant(key);
    return translation !== key && translation !== '';
  }
}
