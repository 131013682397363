<ng-container *ngIf="selectedPlanConversion$ | async as status; else selectionTemplate">
	<ng-container *ngIf="status.projection as projection">
		<div class="panel-plan" [formGroup]="form">
			<div class="panel-subtitle">
				<span class="caption">{{projection.name}}</span>
				<div class="buttons">
					<span *ngIf="canToggleFullscreen" (click)="togglePlanFullscreen($event, canvasWithMarkers)"
								class="mdi mdi-arrow-expand" matTooltip="{{ 'dialogs.defects.fullscreen' | translate}}"></span>
					<span (click)="deselectPlan()" class="mdi mdi-image-off-outline" [class.disabled]="f.plan.disabled"
								matTooltip="{{ 'dialogs.defects.reset-plan' | translate}}"></span>
				</div>
			</div>
			<app-geojson-editor class="panel-image" #canvasWithMarkers [markers]="f.featureCollection.value"
													(markersChange)="fireMarkersChange($event)" [drawableContext]="drawingContext"
													[snapshotArea]="snapshotArea">
				<app-geojson-editor-map-layer [zoom]="projection.depth - 1"
																			[url]="projection.url"></app-geojson-editor-map-layer>
			</app-geojson-editor>
		</div>
	</ng-container>

	<app-estimation-progress *ngIf="status.worker as worker" [estimate]="worker.estimate" [progress]="worker.progress"
													 pendingProgress="defects.conversion.plan.download"
													 description="defects.conversion.plan.progress"></app-estimation-progress>
</ng-container>

<ng-template #selectionTemplate>
	<div *ngIf="!planImage; else geojsonViewerTemplate" class="plan-select-wrapper" #planSelect>
		<span class="panel-header">
			{{ (!isDisabled ? 'dialogs.defects.plan-selection' : 'dialogs.defects.plan-unavailable') | translate }}
		</span>
		<div class="select-row">
			<span class="mdi mdi-map-legend"></span>
			<app-search-select class="select" [selectList]="plans" [selectFormGroup]="form" selectFormControlName="plan">
			</app-search-select>
			<button mat-flat-button color="primary" (click)="selectPlan()"
							[disabled]="f.plan.value == null || f.plan.disabled">
				{{ 'dialogs.defects.plan-selection-button' | translate }}
			</button>
		</div>
	</div>
</ng-template>

<ng-template #statusRowTemplate let-description="description" let-value="value">
	<div class="status-row">
		<span class="cut" [matTooltip]="description">{{ description }}</span>
		<span>:&nbsp;</span>
		<span>{{ value }}</span>
	</div>
</ng-template>