import { BcfViewpointModel, CommentModel, ICommentModel, IIssueViewpointModel, UserSessionModel } from '@app/api';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { UserNotificationService } from '../user-notification/user-notification.service';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface ViewpointWithThumbnail extends IIssueViewpointModel {
  thumbnail?: SafeResourceUrl;
}

export interface ICommentWithViewpoint extends CommentModel {
  viewpoint?: ViewpointWithThumbnail;
}

export interface ICommentService {
  added: Observable<ICommentWithViewpoint>;
  updated: Observable<ICommentWithViewpoint>;
  removed: Observable<string>;
  viewpointSelected: Observable<ViewpointWithThumbnail>;
  selectViewpoint(viewpoint: ViewpointWithThumbnail): void;
  update(comment: ICommentWithViewpoint): Promise<boolean>;
  delete(id: string): Promise<boolean>;
  disposeObservables(): Promise<void>;
}

export interface ICommentsService extends Busy, ICommentService {
  entityId: string;
  comments: ReadonlyArray<ICommentWithViewpoint>;
  add(text: string, viewpoint?: ViewpointWithThumbnail): Promise<boolean>;
}

export interface ICommentsApiService {
  getUserSession(): Promise<UserSessionModel>;
  add(entityId: string, comment: ICommentWithViewpoint): Promise<string>;
  update(entityId: string, comment: ICommentWithViewpoint): Promise<string>;
  delete(entityId: string, commentId: string): Promise<void>;
}

export class CommentsService implements ICommentsService {
  isBusy: boolean;

  private _comments: ICommentWithViewpoint[] = [];
  private _fakeComments: ICommentWithViewpoint[] = [];
  private _added = new Subject<ICommentWithViewpoint>();
  private _updated = new Subject<ICommentWithViewpoint>();
  private _removed = new Subject<string>();
  private _viewpointSelected = new Subject<ViewpointWithThumbnail>();
  private _destroyObservables = new Subject<void>();
  private _requests: Promise<any>[] = [];

  constructor(
    private api: ICommentsApiService,
    private userNotification: UserNotificationService,
    public entityId: string = null
  ) {
    this._fakeComments = this.getFakeComments();
  }

  get added(): Observable<ICommentWithViewpoint> {
    return this._added.pipe(takeUntil(this._destroyObservables));
  }

  get updated(): Observable<ICommentWithViewpoint> {
    return this._updated.pipe(takeUntil(this._destroyObservables));
  }

  get removed(): Observable<string> {
    return this._removed.pipe(takeUntil(this._destroyObservables));
  }

  get viewpointSelected(): Observable<ViewpointWithThumbnail> {
    return this._viewpointSelected.pipe(takeUntil(this._destroyObservables));
  }

  async disposeObservables() {
    await this.waitForRequests();
    this._destroyObservables.next();
  }

  async add(text: string, viewpoint?: ViewpointWithThumbnail): Promise<boolean> {
    if (!this.entityId) throw 'Cannot add comment - missing entity id';

    return await using(new BusyScope(this), async _ => {
      const commentModel: ICommentWithViewpoint = new CommentModel({
        text,
      });

      if (viewpoint) commentModel.viewpoint = viewpoint;

      const userSession = await this.api.getUserSession();
      commentModel.id = await this.api.add(this.entityId, commentModel);

      this.addComment(commentModel, userSession);

      return true;
    }).catch(e => {
      this.userNotification.notify('general.errorMsg.saveComment', { error: e });
      return false;
    });
  }

  async update(comment: ICommentWithViewpoint): Promise<boolean> {
    if (!this.entityId) throw 'Cannot update comment - missing entity id';

    try {
      await this.enqueueRequest(
        this.api.update(this.entityId, comment).then(() => {
          this._updated.next(comment);
        })
      );

      return true;
    } catch (e) {
      this.userNotification.notify('general.errorMsg.saveComment', { error: e });
      return false;
    }
  }

  async delete(commentId: string): Promise<boolean> {
    if (!this.entityId) throw 'Cannot delete comment - missing entity id';

    try {
      await this.enqueueRequest(
        this.api.delete(this.entityId, commentId).then(() => {
          this.removeComment(commentId);

          this._removed.next(commentId);
        })
      );

      return true;
    } catch (e) {
      this.userNotification.notify('general.errorMsg.deleteComment', { error: e });
      return false;
    }
  }

  selectViewpoint(viewpoint: ViewpointWithThumbnail) {
    this._viewpointSelected.next(viewpoint);
  }

  get comments(): ReadonlyArray<ICommentWithViewpoint> {
    return this.isBusy ? this._fakeComments : this._comments;
  }

  set comments(comments: ReadonlyArray<ICommentWithViewpoint>) {
    comments ??= [];
    this._comments = [...comments];
  }

  private getFakeComments(count: number = 3): CommentModel[] {
    const comments = [];

    for (let i = 0; i < count; i++) {
      const date = new Date();
      comments.push(
        new CommentModel({
          text: 'fakeData',
          createdBy: 'fakeData',
          createdOn: date,
          modifiedOn: date,
        })
      );
    }

    return comments;
  }

  private addComment(comment: ICommentWithViewpoint, userSession: UserSessionModel) {
    const now = new Date();

    comment.editorId = userSession.userId;
    comment.createdOn = now;
    comment.modifiedOn = now;
    comment.createdBy = userSession.displayUsername;

    this._comments.unshift(comment);
    this._added.next(comment);
  }

  private removeComment(id: string) {
    const index = this.comments.findIndex(c => c.id === id);
    if (index >= 0) this._comments.splice(index, 1);
  }

  private enqueueRequest<T>(request: Promise<T>) {
    this._requests.push(request);

    request.then(() => {
      this._requests = this._requests.filter(r => r != request);
    });

    return request;
  }

  private async waitForRequests() {
    await Promise.all(this._requests);
  }
}
