import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CanLeave } from '../utils/form-state-tracker';

@Injectable({
  providedIn: 'root',
})
export class CanLeaveGuard {
  async canDeactivate(
    component: CanLeave,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    return await component.canLeave();
  }
}
