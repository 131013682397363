import { Component, ElementRef, Input, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { IUserModel } from '@app/api';
import { IndividualPrivilegeService, Role } from './individual-privilege.service';

@Component({
  selector: 'app-privilege-matrix',
  templateUrl: './privilege-matrix.component.html',
  styleUrls: ['./privilege-matrix.component.scss'],
})
export class PrivilegeMatrixComponent {
  @ViewChildren('rowHeader') rowHeaders: QueryList<ElementRef<HTMLDivElement>>;

  @Input() service: IndividualPrivilegeService;

  addToGroupForm: UntypedFormGroup;

  constructor(private formBuilder: UntypedFormBuilder) {
    this.addToGroupForm = this.formBuilder.group({
      systemRoles: [null],
      roles: [null],
      users: [null],
    });

    this.addToGroupForm.controls.roles.valueChanges.subscribe(this.addIndividualPrivilege.bind(this));
    this.addToGroupForm.controls.users.valueChanges.subscribe(this.addIndividualPrivilege.bind(this));
  }

  private addIndividualPrivilege(individualPrivilegeEntity: Role | IUserModel) {
    const id = individualPrivilegeEntity.id;

    this.service.addIndividualPrivilege(id);
    this.scrollToId(id);
  }

  private scrollToId(id: string) {
    // wait for matrix to be rendered
    setTimeout(() => {
      const rowHeader = this.rowHeaders.find(rowHeader => rowHeader.nativeElement.id == id);
      if (rowHeader) rowHeader.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 0);
  }
}
