import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-select-dialog',
  templateUrl: './select-dialog.component.html',
  styleUrls: ['./select-dialog.component.scss'],
})
export class SelectDialogComponent implements OnInit {
  title: string;
  description: string;
  selected: any;
  options: SelectOption[];
  setOkBtn: boolean = true;
  setCancelBtn: boolean = false;
  confirmOnSelection: boolean = false;

  constructor(public dialogRef: MatDialogRef<SelectDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.setCancelBtn = data.canCancel ? data.canCancel : false;
    this.confirmOnSelection = data.selectConfirm ? data.selectConfirm : false;
    this.setOkBtn = data.showConfirm ? data.showConfirm : !this.confirmOnSelection;
    this.title = data?.title ?? 'general.select';
    this.description = data?.description ?? '';
    this.options = data?.options ?? [];
    this.selected = data?.selected;
  }

  ngOnInit() {}

  setSelection(selectedValue) {
    this.selected = selectedValue;
    if (this.confirmOnSelection) this.confirm();
  }

  confirm() {
    if (this.selected) {
      this.dialogRef.close(this.selected);
    }
  }

  cancel() {
    this.dialogRef.close(false);
  }
}
