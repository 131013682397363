import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-overlay-header-bar',
  templateUrl: './overlay-header-bar.component.html',
  styleUrls: ['./overlay-header-bar.component.scss'],
})
export class OverlayHeaderBarComponent implements OnInit {
  @Input() isOverlayVisible: boolean;
  @Input() isOverlayClosable: boolean = true;
  @Output() isOverlayVisibleChange = new EventEmitter<boolean>();
  @Output() overlayClosed = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  closeOverlay() {
    this.isOverlayVisible = false;
    this.isOverlayVisibleChange.emit(this.isOverlayVisible);
    this.overlayClosed.emit();
  }
}
