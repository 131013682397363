<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:false }"></ngx-loading>


<div class="sequences" [class.c4-busy]="isBusy">
	<div class="sequences-header">
		<app-header label="workpackageSequences.title" [icon]="icon"></app-header>
		<div class="sequences-header-buttons">
			<button *ngIf="projectId != null && allowGlobalImport" mat-button (click)="addFromGlobal()">
				<span class="mdi mdi-database-export"></span>
				<span>{{ 'general.actions.addFromGlobal' | translate }}</span>
			</button>
			<button mat-button (click)="editor.toggleTemplatePane()">
				<span class="mdi mdi-drawing-box"></span>
				<span>{{ 'workpackageSequences.showTemplates' | translate }}</span>
			</button>

			<div class="seperator"></div>

			<button mat-flat-button color="primary" (click)="save()">
				<span>{{ 'general.save' | translate }}</span>
			</button>
		</div>
	</div>

	<div class="sequences-content card word-break">
		<app-sequence-editor #editor [projectId]="projectId"></app-sequence-editor>
	</div>
</div>