import { Component, OnInit } from '@angular/core';
import { CustomizationModel, HyperlinkModel, TenantInfoModel } from '@app/api';
import { ApiService, LogService } from '@app/core';
import { TranslateService } from '@ngx-translate/core';

interface FooterLink {
  caption: string;
  url: string;
}

@Component({
  selector: 'c4-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  footerLinks: HyperlinkModel[];
  highlightedFooterLink: HyperlinkModel;
  baseTranslationRoute = 'layout.footer.links.';
  keys: string[] = [
    'custom.footer.link1.caption',
    'custom.footer.link1.link',
    'custom.footer.link2.caption',
    'custom.footer.link2.link',
    'custom.footer.link3.caption',
    'custom.footer.link3.link',
    'custom.footer.link4.caption',
    'custom.footer.link4.link',
  ];

  tenant: TenantInfoModel = new TenantInfoModel({
    name: '',
  });

  constructor(private apiService: ApiService, private log: LogService, private translateService: TranslateService) {}

  async ngOnInit() {
    try {
      this.tenant = await this.apiService.getTenantInfo();

      let customization: CustomizationModel;
      try {
        customization = await this.apiService.getCustomization();
      } catch {}

      const footerLinks = customization?.pageFooterLinks ?? [];
      this.highlightedFooterLink = footerLinks.shift();
      this.footerLinks = footerLinks.slice(0, 3);
    } catch (e) {
      this.log.error('Get request failed.', e);
    }
  }
}
