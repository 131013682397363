<div class="c4-dialog-wrapper add-role-dialog">
	<h1 mat-dialog-title>{{ title | translate }}</h1>

	<form [formGroup]="form">
		<mat-dialog-content class="content">
			<mat-form-field>
				<input matInput [placeholder]="'general.name' | translate" formControlName="name">
				<mat-error *ngIf="f.name.invalid">
					{{ 'general.errorForms.' + getError('name') | translate }}
				</mat-error>
			</mat-form-field>

			<mat-form-field>
				<input matInput [placeholder]="'general.description' | translate" formControlName="description">
			</mat-form-field>

			<app-toggle-field formControlName="isReadOnly" [label]="'projectConfig.roles.readOnly' | translate"
												[description]="'projectConfig.roles.readOnlyDescription' | translate">
			</app-toggle-field>

			<app-toggle-field class="gap-top" formControlName="isHidden" [label]="'projectConfig.roles.hidden' | translate"
												[description]="'projectConfig.roles.hiddenDescription' | translate">
			</app-toggle-field>
		</mat-dialog-content>

		<mat-dialog-actions>
			<button mat-flat-button type="button" (click)="cancel()">{{ 'general.cancel' | translate }}</button>
			<button mat-flat-button type="button" color="primary" [disabled]="form.invalid" (click)="confirm()">
				{{ 'general.ok' | translate }}
			</button>
		</mat-dialog-actions>
	</form>
</div>