import { Component, Input, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-slim-search',
  templateUrl: './slim-search.component.html',
  styleUrls: ['./slim-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SlimSearchComponent,
    },
  ],
})
export class SlimSearchComponent implements ControlValueAccessor {
  @Input() placeholder: string = 'general.placeholder.search';

  value: string = '';

  constructor() {}

  // ----- Reactive Forms Methods -----

  isDisabled: boolean = false;
  private isTouched: boolean = false;
  onChange: Func<string> = (_: string) => {};
  private onTouched: EmptyCallback = () => {};

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: Func<string>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: EmptyCallback): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
  }
}
