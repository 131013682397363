<div class="c4-grid-container room" [ngClass]="roomsHolder ? roomsHolder.busyClass : ''">
	<c4-grid #rooms *ngIf="dynamicGridDef" [gridDef]="dynamicGridDef" [source]="roomsHolder.data"
					 [noDataText]="'grid.rooms.noDataText'" [selectionMode]="selectionMode" [(selection)]="selection">
	</c4-grid>
</div>

<ng-template #nameCol let-col='col' let-row='row'>
	<div class="c4-name-column" (click)="navigateToRoom.emit(row.id)">
		<div class="header">{{ row.internalNumber }}</div>
	</div>
</ng-template>