<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:true }"></ngx-loading>
<div class="page-part categories-root">
	<app-header [label]="'categories.titleLong' | translate" [icon]="icon"></app-header>

	<app-header-bar>
		<div class="header-container">
			<div class="button-container">
				<button *ngIf="isInProject" mat-button (click)="addFromGlobal()"
								[matTooltip]="'general.actions.addFromGlobalTooltip' | translate">
					<span class="mdi mdi-database-export"></span>
					<span>{{ 'general.actions.addFromGlobal' | translate }}</span>
				</button>
				<button *ngIf="isInProject" mat-button (click)="fileInput.click()"
								[matTooltip]="'general.importFormatTooltip' | translate:{ format: 'CSV' }">
					<span class="mdi mdi-import"></span>
					<span>{{ 'general.importFormat' | translate:{ format: 'CSV' } }}</span>
				</button>
				<button *ngIf="isInProject" mat-button (click)="export()"
								[matTooltip]="'general.exportFormatTooltip' | translate:{ format: 'CSV' }">
					<span class="mdi mdi-export"></span>
					<span>{{ 'general.exportFormat' | translate:{ format: 'CSV' } }}</span>
				</button>

				<button mat-button (click)="createCategory()" [matTooltip]="'categories.addTooltip' | translate">
					<span class="mdi mdi-plus-box"></span>
					<span>{{ 'categories.add' | translate }}</span>
				</button>
			</div>
		</div>
	</app-header-bar>

	<!-- if you want to reuse this, don't copy it but unify it with attributes into a component -->
	<!-- or at least unify the css (this file + attributes) -->
	<div class="table-container c4-grid-container" [class]="categoriesHolder?.busyClass">
		<div class="c4-p-table-container">
			<p-treeTable [value]="categoriesHolder.data" [scrollable]="true" scrollHeight="flex">
				<ng-template pTemplate="header" let-columns>
					<tr>
						<th>{{ 'categories.fieldNames.name' | translate }}</th>
						<th *ngIf="!globals.isMobile">{{ 'categories.fieldNames.number' | translate }}</th>
						<th *ngIf="!globals.isMobile">{{ 'categories.fieldNames.sequence' | translate }}</th>
						<th class="actions-column"></th>
					</tr>
				</ng-template>
				<ng-template pTemplate="body" let-rowNode let-rowData="rowData">
					<tr>
						<td>
							<div class="toggler-container">
								<p-treeTableToggler *ngIf="!categoriesHolder.busyClass" [rowNode]="rowNode"></p-treeTableToggler>
								<span class="category-name c4-busy-content" [matTooltip]="rowData.name">
									<ng-container *ngIf="globals.isMobile && rowData.number != null">{{ rowData.number }} -
									</ng-container>
									{{ rowData.name }}
								</span>
							</div>
						</td>
						<td *ngIf="!globals.isMobile">
							<span class="c4-busy-content">{{ rowData.number }}</span>
						</td>
						<td *ngIf="!globals.isMobile">
							<span class="c4-busy-content">{{ rowData.sequence }}</span>
						</td>
						<td class="no-side-padding">
							<div class="c4-grid-cell">
								<div class="actions-column">
									<button *ngIf="rowNode.level < 2" mat-icon-button [matTooltip]="'categories.addTooltip' | translate"
													(click)="createCategory(rowData.id)">
										<span class="mdi mdi-plus"></span>
									</button>
									<button mat-icon-button [matTooltip]="'categories.edit' | translate"
													(click)="editCategory(rowData.id)">
										<span class="mdi mdi-square-edit-outline"></span>
									</button>
									<button mat-icon-button [matTooltip]="'categories.delete' | translate"
													(click)="deleteCategory(rowData.id)">
										<span class="mdi mdi-trash-can-outline"></span>
									</button>
								</div>
							</div>
						</td>
					</tr>
				</ng-template>
				<ng-template pTemplate="emptymessage" let-columns>
					<tr class="c4-grid-empty-warning c4-main-row">
						<td [attr.colspan]="4">
							<div>
								<span class="c4-grid-empty-ico mdi mdi-blur-off"></span>
								<span>
									{{ 'grid.categories.noDataText' | translate }}
								</span>
							</div>
						</td>
					</tr>
				</ng-template>
			</p-treeTable>
		</div>
	</div>

	<input #fileInput class="hide" type="file" accept=".csv" [multiple]="false" (change)="import($event.target.files[0])"
				 (click)="fileInput.value = null" />
</div>