<h3 *ngIf="attachments.length > 0">{{'general.notifications.attachment' | translate}}:</h3>

<div class="attachments-container" *ngIf="attachments && attachments.length>0">
  <mat-chip-list>
    <mat-chip *ngFor="let file of attachments" class="c4-chip" (click)="downloadFile(file)"
              [matTooltip]="'general.notifications.download' | translate:{file:file.fileName}">
      {{file.fileName}}
    </mat-chip>
  </mat-chip-list>
</div>

<h3 *ngIf="showNotification">{{ 'general.notifications.text' | translate }}:</h3>

<div *ngIf="showNotification" class="notification-message" [innerHTML]="notificationText | safeHtml"
     [ngClass]="content"></div>