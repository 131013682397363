<div class="c4-dialog-wrapper">
	<h1 mat-dialog-title>{{ title | translate }}</h1>
	<mat-dialog-content class="input-action-content">
		<mat-form-field>
			<mat-label>{{ 'general.name' | translate }}</mat-label>
			<input matInput [(ngModel)]="newName" (input)="validateInput($event.target.value)"
						 [placeholder]="'general.newName' | translate" [disabled]="isInputDisabled">
		</mat-form-field>

		<div class="wrapper">
			<span>{{ toggleLabel | translate }}</span>
			<mat-slide-toggle *ngIf="showToggle" [(ngModel)]="toggleValue" [labelPosition]="'after'">
				<!-- {{ toggleLabel | translate }} -->
			</mat-slide-toggle>
		</div>
		<div *ngIf="description">{{ description | translate }}</div>
		<!-- </div> -->
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
		<div class="sub-actions">
			<button *ngIf="alternativeAction && alternativeActionLabel" mat-stroked-button (click)="performAction(true)"
							type="button">
				{{ alternativeActionLabel | translate }}
			</button>
			<button mat-flat-button color="primary" (click)="performAction()" type="button" [disabled]="!isValid">
				{{ 'general.ok' | translate }}
			</button>
		</div>
	</mat-dialog-actions>
</div>