<div class="plan-schema-dialog c4-dialog-wrapper">
  <h1 mat-dialog-title>{{'planning.schema.title' | translate}}</h1>
  <mat-dialog-content>
    <div class="schema-wrapper" *ngIf="initReady">
      <table>

        <thead>
          <tr>
            <th *ngFor="let field of shownSchema; let i = index"
                [ngClass]="{'maxLength': field.maxLength, 'isversion': field.isVersion}">
              <div class="header-cell">
                <ng-container *ngFor="let char of field.chars">
                  <div class="single-char" [ngClass]="field.field" [attr.part]="i">{{char}}</div>
                </ng-container>
                <ng-container *ngIf="field.seperator">
                  <div class="single-char seperator" [attr.part]="i">{{field.seperator}}</div>
                </ng-container>
              </div>
            </th>
          </tr>
          <tr>
            <th *ngFor="let field of shownSchema; let i = index"
                [ngClass]="{'maxLength': field.maxLength, 'isversion': field.isVersion}">
              <div class="header-cell">
                <div class="field-name" [attr.part]="i"
                     [ngClass]="{'iskey': field.isKey, 'isversion': field.isVersion}">{{field.displayName}}</div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td *ngFor="let field of shownSchema; let i = index"
                [ngClass]="{'maxLength': field.maxLength, 'isversion': field.isVersion}">
              <div *ngIf="field.isKey" class="field-iskey" [attr.part]="i"><span class="mdi mdi-key"></span></div>
              <div *ngIf="field.isVersion" class="field-isversion" [attr.part]="i"><span class="mdi mdi-counter"></span>
              </div>
              <div *ngIf="field.maxLength" class="field-maxlength" [attr.part]="i"><span>{{'planning.field.maxLength'
                  |
                  translate}}</span>
                <span>{{field.maxLength}}</span>
              </div>
            </td>
          </tr>
          <tr *ngFor="let row of printSchema;  let irow = index">
            <ng-container *ngIf="irow===0">
              <td *ngFor="let col of row ; let icol = index" [ngClass]="printCol[icol]?'':'absolut-info'">
                <div class="field-info">
                  <div class="info">{{col}}</div>
                </div>
              </td>
            </ng-container>
            <ng-container *ngIf="irow===1">
              <td *ngFor="let col of row ; let icol = index">
                <div *ngIf="col" class="field-options">
                  <div class="options-header">{{col.header | translate}}</div>
                </div>
              </td>
            </ng-container>
            <ng-container *ngIf="irow>1">
              <td *ngFor="let col of row">
                <div *ngIf="col" class="field-options">
                  <div class="option">
                    <span class="option-val" *ngIf="col.value">{{col.value}} | </span>
                    <span class="option-rng"
                          *ngIf="col.range"><span>{{col.range[0]}}</span>-<span>{{col.range[1]}}</span> | </span>
                    <span class="option-label">{{col.label}}</span>
                  </div>
                </div>

              </td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
    <button mat-flat-button color="primary" (click)="ok()" type="button">{{ 'general.print' | translate }}</button>
  </mat-dialog-actions>
</div>