<mat-error *ngIf="f.searchText.invalid" class="search-validation-error">
	<div class="search-validation-error-text">
		<span>{{ 'general.validation.charsNotAllowed' | translate:{charSet: invalidChars.join(' ')} }}</span>
	</div>
	<div class="search-validation-error-icon icon-button" (click)="tooltip.show(0)">
		<span class="mdi mdi-information-outline" #tooltip="matTooltip"
					[matTooltip]="'general.validation.charsNotAllowed' | translate:{charSet: invalidChars.join(' ')}"></span>
	</div>
</mat-error>

<form [formGroup]="form" (submit)="searchTextChanged()" class="searchbar"
			[class.collapsed]="!isExpanded && !f.searchText?.value" (click)="topSearchField.focus()">
	<span class="mdi mdi-magnify search-ico" (click)="topSearchField.focus()"></span>
	<input #topSearchField formControlName="searchText" [placeholder]="placeholder | translate"
				 (input)="searchTextInput()" (blur)="searchTextBlur()" (focus)="isExpanded = true" />
	<div *ngIf="f.searchText?.value.length != 0" class="clear-input-button" (click)="resetFilter()">
		<span class="mdi mdi-close"></span>
	</div>
</form>