import { I } from '@angular/cdk/keycodes';
import { Filesystem, Directory, GetUriOptions } from '@capacitor/filesystem';

export class OfflineServiceFileHandler {
  public async readFile(filePath: string, fileDirectory: Directory) {
    const file = await Filesystem.readFile({
      path: filePath,
      directory: fileDirectory,
    });

    const array = this.fileToBlob(file.data);

    return array;
  }

  public async checkFileExists(filePath: string, fileDirectory: Directory): Promise<boolean> {
    try {
      await Filesystem.stat({
        path: filePath,
        directory: fileDirectory,
      });
      return true;
    } catch (checkDirException) {
      return false;
    }
  }

  public async rename(oldFilePath: string, newFilePath: string, fileDirectory: Directory) {
    await Filesystem.rename({
      from: oldFilePath,
      to: newFilePath,
      directory: fileDirectory,
    });
  }

  public async saveFile(filePath: string, fileDirectory: Directory, buffer: Uint8Array) {
    const array = new Uint8Array(buffer);
    const data = new Array<String>();

    for (var i = 0; i < array.length; ++i) {
      data.push(String.fromCharCode(array[i]));
    }

    const result = await Filesystem.writeFile({
      data: btoa(data.join('')),
      path: filePath,
      recursive: true,
      directory: fileDirectory,
    });

    return result;
  }

  private fileToBlob(content) {
    const raw = atob(content);
    const bytes = new Array(raw.length);

    for (var x = 0; x < raw.length; x++) {
      bytes[x] = raw.charCodeAt(x);
    }

    const array = new Uint8Array(bytes);

    return array;
  }
}
