<div class="radio-group-container">
	<div *ngFor="let option of options; let i = index" class="radio-group" [class.active]="selected === option.key"
			 (click)="optionClicked(option, input)">
		<input #input type="radio" [disabled]="isDisabled" [checked]="selected === option.key"
					 (keydown)="onKeydown($event, i)" [tabindex]="selected === option.key ? 0 : -1">
		<div class="label-container">
			<span>{{ option.title | translate }}</span>
			<span class="description">{{ option.description | translate }}</span>
		</div>
	</div>
</div>