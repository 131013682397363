import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Utils } from '@app/core';

interface NewMarkerFormData {
  name: string;
}

@Component({
  selector: 'app-new-marker-dialog',
  templateUrl: './new-marker-dialog.component.html',
  styleUrls: ['./new-marker-dialog.component.scss'],
})
export class NewMarkerDialogComponent implements OnInit {
  public newMarkerForm: UntypedFormGroup;

  constructor(private dialogRef: MatDialogRef<NewMarkerDialogComponent>, private formBuilder: UntypedFormBuilder) {}

  async ngOnInit() {
    this.newMarkerForm = this.formBuilder.group({
      name: ['', [Validators.required, this.notEmptyValidator()]],
    });
  }

  async confirm(formData: NewMarkerFormData) {
    this.dialogRef.close(formData.name.trim());
  }

  abort() {
    this.dialogRef.close();
  }

  private notEmptyValidator() {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const ok: boolean = !Utils.isNullOrWhitespace(control.value as string);
      return ok ? null : { onlyWhitespaces: true };
    };
  }
}
