import { ElementRef, Directive, AfterContentChecked, Input } from '@angular/core';

@Directive({
  selector: '[appSetFocus]',
})
export class SetFocusDirective implements AfterContentChecked {
  focusIsSet: boolean = false;

  @Input()
  public appSetFocus: 'end' | 'all' = 'end';

  constructor(private el: ElementRef) {}

  ngAfterContentChecked() {
    if (this.focusIsSet) return;
    this.setFocus();
  }

  setFocus() {
    const input: HTMLInputElement = this.el.nativeElement as HTMLInputElement;
    //workaround for caret bug #16892
    setTimeout(() => {
      input.focus();
      if (this.appSetFocus == 'all') {
        input.setSelectionRange(0, input.value.length);
        return;
      }
      if (this.appSetFocus == 'end') {
        input.setSelectionRange(input.value.length, input.value.length);
        return;
      }
    }, 0);
    this.focusIsSet = true;
  }
}
