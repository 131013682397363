<div class="page-part stretched planning-module" #planningModule>
  <app-header [label]="label" [icon]="icon"></app-header>

  <app-overlay-header-bar #overlayHeader [isOverlayVisible]="arePlansSelected" (overlayClosed)="list.deselectAll()">
    <div class="header-container with-tabs">
      <app-header-tabs></app-header-tabs>

      <div class="button-container">
        <button *ngIf="addFileAllowed" mat-button class="c4-phone-hidden" (click)="list.uploadDialog()"
                [matTooltip]="'planning.header.tool_upload' | translate">
          <span class="mdi mdi-plus-box"></span>
          <span>{{ 'planning.header.upload' | translate }}</span>
        </button>
        <button *ngIf="isFilterSet" mat-button class="c4-phone-only" (click)="clearFilter()"
                [matTooltip]="'general.actions.clearFilter' | translate">
          <span class="mdi mdi-filter-off"></span>
          <span>{{ 'general.actions.clearFilter' | translate }}</span>
        </button>
        <ng-template [ngTemplateOutlet]="toggleAllTemplate" [ngTemplateOutletContext]="{
          class: 'c4-phone-hidden'
        }"></ng-template>

        <button mat-icon-button [matMenuTriggerFor]="menu">
          <span class="mdi mdi-dots-vertical"></span>
        </button>
        <mat-menu #menu="matMenu">
          <button *ngIf="addFileAllowed" mat-menu-item class="c4-phone-only" (click)="list.uploadDialog()"
                  [matTooltip]="'planning.header.tool_upload' | translate">
            <span class="mdi mdi-plus-box"></span>
            <span>{{ 'planning.header.upload' | translate }}</span>
          </button>
          <div mat-menu-item class="c4-phone-only">
            <ng-template [ngTemplateOutlet]="toggleAllTemplate"></ng-template>
          </div>
          <div mat-menu-item class="c4-phone-only">
            <mat-slide-toggle (change)="list.responsiveColumns = $event.checked"
                              [matTooltip]="'general.actions.showColumns' | translate">
              <span>{{ 'general.actions.allColumns' | translate }}</span>
            </mat-slide-toggle>
          </div>
          <button mat-menu-item (click)="saveView()" [matTooltip]="'planning.header.tool-newView' | translate">
            <span class="mdi mdi-apps"></span>
            <span>{{ 'general.actions.saveView' | translate }}</span>
          </button>
          <button mat-menu-item (click)="list.list.editGridColumns('grid.edit.title')"
                  [matTooltip]="'general.actions.editGridTooltip' | translate">
            <span class="mdi mdi-table-edit"></span>
            <span>{{ 'general.actions.editGrid' | translate }}</span>
          </button>
          <button mat-menu-item (click)="showSchema()" [matTooltip]="'planning.header.tool_schema' | translate">
            <span class="mdi mdi-map"></span>
            <span>{{ 'planning.header.schema' | translate }}</span>
          </button>
          <button mat-menu-item (click)="exportCsv()" [matTooltip]="'general.actions.exportCsvTooltip' | translate">
            <span class="mdi mdi-file-excel"></span>
            <span>{{ 'general.actions.exportCsv' | translate }}</span>
          </button>
          <button mat-menu-item (click)="exportPdf()" [matTooltip]="'general.actions.exportPdfTooltip' | translate">
            <span class="mdi mdi-file-pdf-box"></span>
            <span>{{ 'general.actions.exportPdf' | translate }}</span>
          </button>
          <button mat-menu-item (click)="list.getLog()" [matTooltip]="'planning.header.tool_log' | translate">
            <span class="mdi mdi-history"></span>
            <span>{{ 'documents.getLog' | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div overlay>
      <button *ngIf="canSharePrivilege" mat-button (click)="shareSelected()">
        <span class="mdi mdi-email-outline"></span>
        <span>{{ 'general.notify' | translate }}</span>
      </button>
      <button *ngIf="canDownload" mat-button (click)="list.downloadDocs()">
        <span class="mdi mdi-download"></span>
        <span>{{ 'general.download' | translate }}</span>
      </button>
      <button *ngIf="canDownload" mat-button (click)="zipUrl()">
        <span class="mdi mdi-link"></span>
        <span>{{ 'planning.header.generateUrl' | translate }}</span>
      </button>
      <button *ngIf="canCompare" mat-button (click)="compareSelected()">
        <span class="mdi mdi-compare"></span>
        <span>{{ 'planning.header.compare' | translate }}</span>
      </button>
    </div>
  </app-overlay-header-bar>

  <app-plan-list #list class="plan-list" (itemsChanged)="updateHeaderState($event)" [moduleType]="moduleType"
                 (toggleViewState)="syncToggleBtn($event)" (gridFilterChange)="gridFilterChanged($event)"
                 (customSelectionChange)="selectedItems = $event"></app-plan-list>
</div>

<ng-template #toggleAllTemplate let-class="class">
  <mat-slide-toggle [ngSwitch]="moduleType" [class]="class" (change)="toggleView(!$event.checked)" #viewToggle
                    [matTooltip]="'planning.header.tool_view' | translate" [checked]="toggleState">
    <span *ngSwitchCase="ModuleType.Bim">{{ 'ifc.header.latestView' | translate }}</span>
    <span *ngSwitchDefault>{{ 'planning.header.latestView' | translate }}</span>
  </mat-slide-toggle>
</ng-template>