import { Component, OnInit, Inject, ElementRef, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ExtendedPlanFieldModel, _planSeparator } from '../schema-list.interfaces';
import { PrintService, PrintSize } from '@app/shared/services';

interface ViewPlanSchema extends ExtendedPlanFieldModel {
  example?: string;
  chars?: string[];
  seperator?: string;
  charLength?: number;
}

const freitext = ['.....'];

@Component({
  selector: 'app-plan-schema-dialog',
  templateUrl: './plan-schema-dialog.component.html',
  styleUrls: ['./plan-schema-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlanSchemaDialogComponent implements OnInit {
  fullSchema: ViewPlanSchema[];
  shownSchema: ViewPlanSchema[];
  seperator: string = _planSeparator;
  initReady: boolean = false;
  printSchema: any[];
  printCol: any[];
  maxOptions: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private confirmRef: MatDialogRef<PlanSchemaDialogComponent>,
    private printService: PrintService
  ) {
    this.fullSchema = JSON.parse(JSON.stringify(data.plan)) as ViewPlanSchema[];
    this.seperator = data.seperator ?? _planSeparator;
  }

  ngOnInit() {
    this.initDefinition();
    this.refineSchemaForPrint();
    this.initReady = true;
  }

  ok() {
    this.printService.print({
      size: PrintSize.landscapeA3,
      margin: '10mm',
    });
  }

  cancel() {
    this.confirmRef.close(false);
  }

  refineSchemaForPrint() {
    this.printCol = [];
    this.printSchema = [];
    const topRow = [];
    const optionsRow = [];
    this.maxOptions = 0;
    this.shownSchema.forEach(f => {
      topRow.push(f.description);
      const hasOptions = f.options && f.options.length > 0;
      this.maxOptions = hasOptions ? Math.max(this.maxOptions, f.options.length) : this.maxOptions;
      this.printCol.push(hasOptions);
      optionsRow.push(hasOptions ? { header: 'planning.field.optionsHeader' } : null);
    });
    this.printSchema.push(topRow);
    this.printSchema.push(optionsRow);
    for (let i = 0; i < this.maxOptions; i++) {
      const bodyRow = [];
      this.shownSchema.forEach(f => {
        const val = f.options && f.options[i] ? f.options[i] : null;
        bodyRow.push(val);
      });
      this.printSchema.push(bodyRow);
    }
  }

  initDefinition() {
    const maxIndex = this.fullSchema.length - 1;

    this.shownSchema = [];
    let shownIndex = 0;

    for (let i = 0; i < this.fullSchema.length; i++) {
      const d = this.fullSchema[i];

      //Visualisation of seperator happens on the previous element
      if (d.isSeparator) continue;

      this.shownSchema[shownIndex++] = d;
      d.chars = [];
      d.example = '';
      d.charLength = 1;

      if (maxIndex - 1 > i) {
        if (this.fullSchema[i + 1]?.isSeparator) d.seperator = this.fullSchema[i + 1].field;
        else d.seperator = this.seperator;
      } else if (maxIndex > i) {
        d.seperator = '.';
      }

      if (d.maxLength) {
        d.charLength = d.maxLength;
        d.example = freitext[0];
        d.chars.push(d.example);
      } else if (d.isVersion) {
        if (d.options && d.options.length > 0) {
          d.example = d.options[0].value;
        } else {
          d.example = 'A';
        }
        d.options.reverse();
      }

      if (d.options && d.options.length > 0) {
        const indexRnd = Math.floor(Math.random() * d.options.length);
        if (d.options[indexRnd].value) {
          d.example = d.options[indexRnd].value;
        } else if (d.options[indexRnd].range) {
          const r = d.options[indexRnd].range;
          const nr = Math.floor(Math.random() * (r[1] - r[0])) + r[0];
          d.example = ('000' + nr).slice(-3);
        }
        d.charLength = d.example.length;
      }
      d.chars = d.chars.length <= 0 ? Array.from(d.example) : d.chars;
    }
  }
}
