import {
  LeanBryntumSyncModel,
  LeanBryntumSyncResponseModel,
  LeanWorkpackageModel,
  LeanWorkpackageSequenceWorkpackageModel,
  LeanWorkpackageTemplateModel,
  WorkpackageState,
  WorkpackageStateProjectEntityState,
} from '@app/api';
import { LOCAL_DATE_FORMAT, Utils, nameof } from '@app/core';
import { ISchedulerService } from '@app/shared/services';
import { TimeSpanHelper } from '@app/shared/utils/time-span-helper';
import {
  AssignmentModel,
  AssignmentModelConfig,
  EventModel,
  EventModelConfig,
  ProjectCrudManagerClass,
  ProjectModel,
  ProjectModelConfig,
  ResourceModel,
  ResourceModelConfig,
  SchedulerProjectCrudManagerClass,
  TimeSpan,
} from '@bryntum/schedulerpro';
import * as moment from 'moment';

export enum StoreType {
  resources = 'resources',
  timeRanges = 'timeRanges',
  events = 'events',
  assignments = 'assignments',
  dependencies = 'dependencies',
}

//https://forum.bryntum.com/viewtopic.php?p=95183
const EmptyEncoder = (Target: typeof ProjectCrudManagerClass) =>
  class EmptyEncoder extends Target {
    // JsonEncoder will covert data to JSON leaving data set empty of any instances. However,
    // if you're saving task, it may send an array of baselines which contain record instances.
    // Those record instances should either be cleared or converted to regular objects to
    // avoid infinite loop on response apply
    encode(data) {
      return data;
    }
    decode(data) {
      return data;
    }
  };

let counter = 0;

//https://forum.bryntum.com/viewtopic.php?p=95183
const ApiTransporter = (Target: typeof ProjectCrudManagerClass) =>
  class ApiTransporter extends Target {
    constructor(private schedulerService: ISchedulerService, config?: Partial<ProjectModelConfig>) {
      super(config);
    }

    async sendRequest(request: ApiTransporterRequestModel) {
      // Need to move these actions to next microtask to give crud manager some time to store the request id
      await Promise.resolve();

      const { thisObj, type, data, success } = request;

      let response: LeanBryntumLoadResponseModel | LeanBryntumSyncResponseModel = null;
      switch (type) {
        case 'sync': {
          response = await this.schedulerService.syncBryntum(data as LeanBryntumSyncModel);
          break;
        }
        case 'load': {
          response = await this.schedulerService.loadBryntum(data as LeanBryntumLoadModel);
          break;
        }
      }

      // Trigger event as this handles unmasking in the scheduler
      // Normally done by default ApiTransporter - but we replaced it...
      (thisObj as ApiProjectModel).trigger('responseReceived', { success: response.success });

      // Emulate Response instance, Project will try to read text property
      const responseRaw = { text: async () => response };

      success?.call(request.thisObj || this, responseRaw, {}, request);
    }
  };

export class ApiProjectModel extends ApiTransporter(EmptyEncoder(ProjectModel)) {
  constructor(schedulerService: ISchedulerService, config?: Partial<ProjectModelConfig>) {
    super(schedulerService, config);
  }

  static get defaultConfig(): Partial<ProjectModelConfig> {
    return {
      autoSync: true,
      autoLoad: false,
      supportShortSyncResponse: false,
      validateResponse: false,
      transport: {
        load: { url: 'load' },
        sync: { url: 'sync' },
      },
    };
  }
}

export interface LeanBryntumRequestModel {
  requestId?: string;
  revision?: number;
  type?: string;
}

export interface ApiTransporterRequestModel {
  type: 'load' | 'sync';
  url: string;
  data: string | LeanBryntumSyncModel | LeanBryntumLoadModel;
  params: object;
  success: Function;
  failure: Function;
  thisObj: object;
}

// Current no load model on server - if this ever happens replace this
export interface LeanBryntumLoadModel extends LeanBryntumRequestModel {
  stores?: string[];
}

export interface LeanBryntumLoadResponseModel extends LeanBryntumRequestModel {
  success: boolean;
  resources?: LeanBryntumRowResponseModel<LaneModel>;
  timeRanges?: LeanBryntumRowResponseModel<TimeRangeColorModel>;
}

export interface LeanBryntumRowResponseModel<Type> {
  rows: Type[];
}

export interface LaneModelConfig extends ResourceModelConfig {
  area?: string;
  floor?: string;
  sequence?: number;
  isTemporary?: boolean;
}

export class LaneModel extends ResourceModel {
  constructor(config?: Partial<LaneModelConfig>) {
    super(config);

    this.area = config.area;
    this.floor = config.floor;
    this.isTemporary = config.isTemporary;
  }

  area?: string;
  floor?: string;
  isTemporary?: boolean;

  static get fields() {
    return [
      {
        name: nameof<LaneModel>('name'),
        type: 'string',
        persist: true,
      },
      {
        name: nameof<LaneModel>('area'),
        type: 'string',
        persist: true,
      },
      {
        name: nameof<LaneModel>('floor'),
        type: 'string',
        persist: true,
      },
      {
        name: nameof<LaneModel>('parentId'),
        type: 'string',
        persist: true,
      },
      {
        name: 'orderedParentIndex',
        type: 'number',
        persist: true,
      },
      {
        name: nameof<LaneModel>('isTemporary'),
        type: 'boolean',
        persist: true,
      },
    ];
  }
}

export interface EventAssignmentModelConfig extends AssignmentModelConfig {
  isMain?: boolean;
}

export class WorkpackageAssignmentModel extends AssignmentModel {
  constructor(config?: Partial<EventAssignmentModelConfig>) {
    super(config);

    this.isMain = config.isMain ?? false;
    this.drawDependencies = this.isMain;
  }

  isMain?: boolean;

  static get fields() {
    return [
      {
        name: nameof<WorkpackageAssignmentModel>('isMain'),
        type: 'boolean',
        persist: true,
      },
      {
        name: 'drawDependencies',
        type: 'boolean',
        persist: false,
      },
    ];
  }
}

export interface TimeRangeColorModelConfig extends TimeSpan {
  hexColor?: string;
}

export class TimeRangeColorModel extends TimeSpan {
  constructor(config?: Partial<TimeRangeColorModelConfig>) {
    super(config);

    this.hexColor = config.hexColor;
  }

  hexColor?: string;

  static get fields() {
    return [nameof<TimeRangeColorModel>('hexColor')];
  }
}

export interface WorkpackageModelConfig extends EventModelConfig {
  phaseId?: string | undefined;
  organizationId?: string | undefined;
  phaseName?: string | undefined;
  craftId?: string | undefined;
  craftName?: string | undefined;
  templateId?: string | undefined;
  addFromTemplate?: boolean;
  title?: string | undefined;
  code?: string | undefined;
  description?: string | undefined;
  cost?: number | undefined;
  costValue?: number | undefined;
  costFactor?: number | undefined;
  capacity?: number | undefined;
  constraintLabor?: boolean | undefined;
  constraintInformation?: boolean | undefined;
  constraintEquipment?: boolean | undefined;
  constraintMaterial?: boolean | undefined;
  constraintPreliminary?: boolean | undefined;
  constraintSafety?: boolean | undefined;
  constraintExternalFactors?: boolean | undefined;
  constraintClarificationNeeded?: boolean | undefined;
  stateId?: string | undefined;
  stateType?: WorkpackageState;
  currentState?: WorkpackageStateProjectEntityState;
  allowedStates?: WorkpackageStateProjectEntityState[];
}

const workpackageStateIcon: Record<WorkpackageState, string> = {
  [WorkpackageState.Open]: '',
  [WorkpackageState.Ready]: 'mdi mdi-play',
  [WorkpackageState.Processing]: 'mdi mdi-sync',
  [WorkpackageState.Done]: 'mdi mdi-check',
  [WorkpackageState.Checked]: 'mdi mdi-check-all',
  [WorkpackageState.Stopped]: 'mdi mdi-pause',
};

interface StoreChanges {
  added?: EntityWithId[];
  updated?: EntityWithId[];
  removed?: EntityWithId[];
}

export type BryntumProjectChanges = Partial<Record<StoreType, StoreChanges>>;

export class WorkpackageModel extends EventModel {
  constructor(config?: Partial<WorkpackageModelConfig>) {
    super(config);

    this.organizationId = config.organizationId;
    this.phaseName = config.phaseName;
    this.craftId = config.craftId;
    this.craftName = config.craftName;
    this.templateId = config.templateId;
    this.addFromTemplate = config.addFromTemplate;

    this.name = config.name;
    this.code = config.code;
    this.description = config.description;
    this.cost = config.cost;
    this.costValue = config.costValue;
    this.costFactor = config.costFactor;
    this.capacity = config.capacity;
    this.constraintLabor = config.constraintLabor;
    this.constraintInformation = config.constraintInformation;
    this.constraintEquipment = config.constraintEquipment;
    this.constraintMaterial = config.constraintMaterial;
    this.constraintPreliminary = config.constraintPreliminary;
    this.constraintSafety = config.constraintSafety;
    this.constraintExternalFactors = config.constraintExternalFactors;
    this.constraintClarificationNeeded = config.constraintClarificationNeeded;
    this.stateId = config.stateId;
    this.stateType = config.stateType;
    this.currentState = config.currentState;
    this.allowedStates = config.allowedStates;
    this.allDay = true;
  }

  organizationId?: string | undefined;
  phaseName?: string | undefined;
  craftId?: string | undefined;
  craftName?: string | undefined;
  templateId?: string | undefined;
  addFromTemplate?: boolean;
  code?: string | undefined;
  description?: string | undefined;
  cost?: number | undefined;
  costValue?: number | undefined;
  costFactor?: number | undefined;
  capacity?: number | undefined;
  constraintLabor?: boolean | undefined;
  constraintInformation?: boolean | undefined;
  constraintEquipment?: boolean | undefined;
  constraintMaterial?: boolean | undefined;
  constraintPreliminary?: boolean | undefined;
  constraintSafety?: boolean | undefined;
  constraintExternalFactors?: boolean | undefined;
  constraintClarificationNeeded?: boolean | undefined;
  disturbanceOverestimationPerformance?: boolean | undefined;
  disturbanceReductionEmployees?: boolean | undefined;
  disturbanceIncorrectInfo?: boolean | undefined;
  disturbanceDelayedMaterial?: boolean | undefined;
  disturbanceMissingPreliminary?: boolean | undefined;
  disturbanceInterfaceWorkpackages?: boolean | undefined;
  disturbanceExternalConditions?: boolean | undefined;
  disturbancePriorityChange?: boolean | undefined;
  disturbanceChangeWorkScope?: boolean | undefined;
  disturbanceRemainingWork?: boolean | undefined;
  disturbanceOther?: boolean | undefined;
  disturbanceDelayedEquipment?: boolean | undefined;
  stateId?: string | undefined;
  stateType?: WorkpackageState;
  currentState?: WorkpackageStateProjectEntityState;
  allowedStates?: WorkpackageStateProjectEntityState[];

  static get fields() {
    return [
      nameof<WorkpackageModel>('id'),
      nameof<WorkpackageModel>('name'),
      {
        name: nameof<WorkpackageModel>('startDate'),
        type: 'date',
        format: LOCAL_DATE_FORMAT,
      },
      {
        name: nameof<WorkpackageModel>('endDate'),
        type: 'date',
        format: LOCAL_DATE_FORMAT,
      },
      nameof<WorkpackageModel>('organizationId'),
      nameof<WorkpackageModel>('phaseName'),
      nameof<WorkpackageModel>('craftId'),
      nameof<WorkpackageModel>('craftName'),
      nameof<WorkpackageModel>('templateId'),
      nameof<WorkpackageModel>('addFromTemplate'),
      nameof<WorkpackageModel>('code'),
      nameof<WorkpackageModel>('description'),
      nameof<WorkpackageModel>('cost'),
      nameof<WorkpackageModel>('costValue'),
      nameof<WorkpackageModel>('costFactor'),
      nameof<WorkpackageModel>('capacity'),
      nameof<WorkpackageModel>('constraintLabor'),
      nameof<WorkpackageModel>('constraintInformation'),
      nameof<WorkpackageModel>('constraintEquipment'),
      nameof<WorkpackageModel>('constraintMaterial'),
      nameof<WorkpackageModel>('constraintPreliminary'),
      nameof<WorkpackageModel>('constraintSafety'),
      nameof<WorkpackageModel>('constraintExternalFactors'),
      nameof<WorkpackageModel>('constraintClarificationNeeded'),
      nameof<WorkpackageModel>('disturbanceOverestimationPerformance'),
      nameof<WorkpackageModel>('disturbanceReductionEmployees'),
      nameof<WorkpackageModel>('disturbanceIncorrectInfo'),
      nameof<WorkpackageModel>('disturbanceDelayedMaterial'),
      nameof<WorkpackageModel>('disturbanceMissingPreliminary'),
      nameof<WorkpackageModel>('disturbanceInterfaceWorkpackages'),
      nameof<WorkpackageModel>('disturbanceExternalConditions'),
      nameof<WorkpackageModel>('disturbancePriorityChange'),
      nameof<WorkpackageModel>('disturbanceChangeWorkScope'),
      nameof<WorkpackageModel>('disturbanceRemainingWork'),
      nameof<WorkpackageModel>('disturbanceOther'),
      nameof<WorkpackageModel>('disturbanceDelayedEquipment'),
      nameof<WorkpackageModel>('stateId'),
      nameof<WorkpackageModel>('stateType'),
      nameof<WorkpackageModel>('currentState'),
      nameof<WorkpackageModel>('allowedStates'),
    ];
  }

  get hasConstraint() {
    return (
      this.constraintClarificationNeeded ||
      this.constraintEquipment ||
      this.constraintExternalFactors ||
      this.constraintInformation ||
      this.constraintLabor ||
      this.constraintMaterial ||
      this.constraintPreliminary ||
      this.constraintSafety
    );
  }

  get mainAssignment() {
    return this.assignments.find((a: WorkpackageAssignmentModel) => a.isMain);
  }

  // The default implementation takes the end date and rounds it up to the end of that day
  static getAllDayEndDate(date) {
    return date;
  }

  /**
   * Get changes to update bryntum scheduler
   * Ignores resources, deleted workpackages and updated dependencies since that's not possible in workpackage edit dialog
   * @param fallbackColor fallback color for workpackage if craft color is undefined
   * @returns changes for each store used in store.applyChangeset(...)
   */
  static getChanges(
    updatedWorkpackages: LeanWorkpackageModel[],
    existingWorkpackages: LeanWorkpackageModel[],
    fallbackColor: string = null
  ): BryntumProjectChanges {
    const events = {
      updated: [],
    };
    const assignments = {
      added: [],
      updated: [],
      removed: [],
    };
    const dependencies = {
      added: [],
      removed: [],
    };

    for (const workpackage of updatedWorkpackages) {
      const existingWorkpackage = existingWorkpackages.find(wp => wp.id == workpackage.id);

      // assignments
      const removedAssignmentIds = existingWorkpackage.swimlanes.map(swimlane => swimlane.workpackageSwimlaneId);

      for (const swimlane of workpackage.swimlanes) {
        const existingAssignment = existingWorkpackage.swimlanes.find(
          s => s.workpackageSwimlaneId == swimlane.workpackageSwimlaneId
        );
        const newAssignment = {
          id: swimlane.workpackageSwimlaneId,
          eventId: workpackage.id,
          resourceId: swimlane.swimlaneId,
          isMain: swimlane.isMain,
          drawDependencies: swimlane.isMain,
        };

        if (existingAssignment != null) {
          removedAssignmentIds.remove(swimlane.workpackageSwimlaneId);

          if (existingAssignment.swimlaneId != swimlane.swimlaneId) {
            assignments.updated.push(newAssignment);
          }
        } else {
          assignments.added.push(newAssignment);
        }
      }

      assignments.removed = removedAssignmentIds.map(id => ({
        id,
      }));

      // dependencies
      const removedDependencieIds = existingWorkpackage.precedingDependencies
        .concat(existingWorkpackage.successiveDependencies)
        .map(dependency => dependency.workpackageDependencyId);

      for (const dependency of workpackage.precedingDependencies) {
        if (removedDependencieIds.contains(dependency.workpackageDependencyId))
          removedDependencieIds.remove(dependency.workpackageDependencyId);
        else
          dependencies.added.push({
            id: dependency.workpackageDependencyId,
            from: dependency.otherId,
            fromEvent: dependency.otherId,
            to: workpackage.id,
            toEvent: workpackage.id,
            fromSide: dependency.fromSide,
            toSide: dependency.toSide,
          });
      }

      for (const dependency of workpackage.successiveDependencies) {
        if (removedDependencieIds.contains(dependency.workpackageDependencyId))
          removedDependencieIds.remove(dependency.workpackageDependencyId);
        else
          dependencies.added.push({
            id: dependency.workpackageDependencyId,
            from: workpackage.id,
            fromEvent: workpackage.id,
            to: dependency.otherId,
            toEvent: dependency.otherId,
            fromSide: dependency.fromSide,
            toSide: dependency.toSide,
          });
      }

      dependencies.removed = removedDependencieIds.map(id => ({ id }));

      const purgedWorkpackge = this.purgeWorkpackage(workpackage);
      events.updated.push({
        ...purgedWorkpackge,
        eventColor: this.toHex(workpackage.craftHexColor, fallbackColor),
      });
    }

    return {
      [StoreType.events]: events,
      [StoreType.assignments]: assignments,
      [StoreType.dependencies]: dependencies,
    };
  }

  static fromWorkpackage(workpackage: LeanWorkpackageModel, fallbackColor: string = null): WorkpackageModel {
    const purgedWorkpackge = this.purgeWorkpackage(workpackage);

    return new WorkpackageModel({
      ...purgedWorkpackge,
      eventColor: this.toHex(workpackage.craftHexColor, fallbackColor),
    });
  }

  static fromTemplate(template: LeanWorkpackageTemplateModel, startDate: moment.Moment, fallbackColor: string = null) {
    const endDate = startDate.clone().add(1, 'week').startOf('day');
    return new WorkpackageModel({
      startDate: startDate.toLocalDate(),
      endDate: endDate.toLocalDate(),
      name: template.name,
      templateId: template.id,
      addFromTemplate: true,
      eventColor: this.toHex(template.craftHexColor, fallbackColor),
    });
  }

  static fromSequenceTemplate(
    template: LeanWorkpackageTemplateModel,
    sequence: LeanWorkpackageSequenceWorkpackageModel,
    startDate: moment.Moment,
    fallbackColor: string = null
  ) {
    return new WorkpackageModel({
      startDate: TimeSpanHelper.addToMoment(startDate, sequence.start).toLocalDate(),
      endDate: TimeSpanHelper.addToMoment(startDate, sequence.end).toLocalDate(),
      name: template.name,
      templateId: template.id,
      addFromTemplate: true,
      eventColor: this.toHex(template.craftHexColor, fallbackColor),
    });
  }

  static getIconClass(state: WorkpackageState): string {
    return workpackageStateIcon[state];
  }

  private static toHex(...args: string[]) {
    for (const arg of args) if (!!arg) return `#${arg}`;
    return null;
  }

  private static purgeWorkpackage(workpackage: LeanWorkpackageModel) {
    const validProperties = WorkpackageModel.fields;
    const shallowCopy = { ...workpackage };

    // do not init workpackage model with dependencies, otherwise it will include
    // it in its updates and run into a recursion error
    const invalidProperties: string[] = [];
    for (const property of Object.keys(shallowCopy)) {
      const isValidProperty = validProperties.includes(property) || validProperties.any(p => p.name == property);
      if (!isValidProperty) invalidProperties.push(property);
    }

    for (const invalidProperty of invalidProperties) delete shallowCopy[invalidProperty];

    return shallowCopy;
  }
}

export interface WorkpackageStateOption {
  iconClass: string;
  state: WorkpackageStateProjectEntityState;
}

export function overlappingEventSorter(workPackage: WorkpackageModel, other: WorkpackageModel) {
  const workpackageStart = moment(workPackage.startDate),
    workpackageCraft = workPackage.craftName;
  const otherStart = moment(other.startDate),
    otherCraft = other.craftName;

  const sameStart = workpackageStart.isSame(otherStart);
  const sameCraft = workpackageCraft === otherCraft;

  if (sameStart) {
    if (workpackageCraft === null) return 1;
    if (otherCraft === null) return -1;

    if (sameCraft) {
      return workPackage.name < other.name ? -1 : 1;
    }
    return workpackageCraft < otherCraft ? -1 : 1;
  } else {
    return workpackageStart < otherStart ? -1 : 1;
  }
}
