<a #root matRipple [matRippleDisabled]="isDisabled" class="sidebar-navigation-item" [class.active]="isActive"
	 [class.disabled]="isDisabled" [class.expanded]="isExpanded" (click)="onClick($event)" [routerLink]="link"
	 routerLinkActive [routerLinkActiveOptions]="options" [class.active-disabled]="isRouterLinkActiveDisabled"
	 (isActiveChange)="onRouterLinkActiveChange($event)">
	<div class="thumbnail">
		<ng-content></ng-content>
	</div>
	<div class="label">
		<ng-content select="[label]"></ng-content>
	</div>
	<div class="border"></div>
</a>