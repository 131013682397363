<ng-container *ngIf="selectedOption"
							[ngTemplateOutlet]="toggleStyle == 'icon' ? iconTemplate : buttonTemplate"></ng-container>

<ng-template #buttonTemplate>
	<button mat-icon-button [disabled]="isDisabled">
		<ng-container [ngTemplateOutlet]="iconTemplate"></ng-container>
	</button>
</ng-template>

<ng-template #iconTemplate>
	<span class="mdi" [class.disabled]="isDisabled" [class]="selectedOption.icon" [matTooltip]="tooltip | translate"
				(click)="selectNextOption($event)">
	</span>
</ng-template>