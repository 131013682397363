import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {
  AttributeDefinitionModel,
  AttributeModel,
  AttributeValueType,
  CategoryModel,
  ProblemDetails,
  ProblemDetailsErrorType,
  SwaggerException,
  UserSessionModel,
} from '@app/api';
import { ApiService, CustomValidators, doubleRegex, intRegex, LogService, StructureType } from '@app/core';
import { ISnapshotLoginState } from '@app/core/ISnapshotLoginState';
import { FormComponent } from '@app/core/utils/form-component';
import { UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { TreeNode } from 'primeng/api';

const typeWithDefinition = [AttributeValueType.Dropdown, AttributeValueType.Autocomplete];

interface KnownAttribute {
  name: string;
  categoryId: string;
}

@Component({
  selector: 'app-attribute-dialog',
  templateUrl: './attribute-dialog.component.html',
  styleUrls: ['./attribute-dialog.component.scss'],
})
export class AttributeDialogComponent extends FormComponent implements OnInit, Busy {
  isBusy: boolean;
  title: string;
  attributeValueType = AttributeValueType;
  attributeValueTypes = Object.keys(AttributeValueType);
  selectedValueType = AttributeValueType.Text;
  categories: CategoryModel[];
  selectedCategory: TreeNode<CategoryModel>;
  editCategory: boolean;

  private attribute: AttributeModel;
  private attributeId: string;
  private existingAttributeNames: KnownAttribute[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private apiService: ApiService,
    private dialogRef: MatDialogRef<AttributeDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private log: LogService,
    private userNotification: UserNotificationService
  ) {
    super();

    this.attributeId = data?.id;
    this.title = 'attributes.' + (this.isNew ? 'add' : 'edit');
  }

  get categoryName(): string {
    return this.selectedCategory?.label ?? '';
  }

  get isNew(): boolean {
    return !this.attributeId;
  }

  async ngOnInit() {
    this.initForm();

    let errorKey = 'userData';
    await using(new BusyScope(this), async _ => {
      errorKey = 'categories';
      const categories = await this.apiService.getCategories(StructureType.tree);
      // ToDo: Get Project Categories
      this.categories = categories;

      // ToDo: Based on Project Id
      errorKey = 'attributes';
      const attributes = await this.apiService.getAttributes();
      for (const attribute of attributes) {
        if (attribute.id != this.attributeId)
          this.existingAttributeNames.push({
            categoryId: attribute.category.id,
            name: attribute.name,
          });
      }

      errorKey = 'attribute';
      if (this.attributeId) {
        this.attribute = await this.apiService.getAttribute(this.attributeId);

        this.resetForm();
      }
    }).catch(e => {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.' + errorKey, e);
    });
  }

  async confirm() {
    if (this.form.dirty) {
      const attribute = this.parseForm();
      await using(new BusyScope(this), async () => {
        try {
          attribute.id = await this.apiService.saveAttribute(attribute);
          this.userNotification.notify('attributes.success.save');
          this.dialogRef.close(attribute);
        } catch (e) {
          this.log.error('Attribute save failed', e);
          if (e instanceof ProblemDetails && e.type == ProblemDetailsErrorType.DeletedInUse) {
            this.userNotification.notify('attributes.error.E_VALUE_USED', { error: e });
          } else {
            this.userNotification.notify('attributes.error.' + (this.isNew ? 'add' : 'edit'), { error: e });
          }
        }
      });
    } else {
      this.cancel();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  categoryLabel(model: CategoryModel) {
    if (model.number) {
      return `${model.name} (${model.number})`;
    }

    return `${model.name}`;
  }

  private initForm() {
    this.form = this.formBuilder.group(
      {
        name: [null, [Validators.required]],
        description: [null],
        valueType: [AttributeValueType.Text, [Validators.required]],
        definition: [{ value: null, disabled: true }, [Validators.required, CustomValidators.valuesRequired]],
        defaultValue: [null],
        categoryId: [null, [Validators.required]],

        valueOptions: [[], CustomValidators.uniqueItems()],
      },
      { validators: [this.attributeUniqueCheck.bind(this)] as ValidatorFn[] }
    );

    this.f.valueType.valueChanges.subscribe(type => {
      // fixed values: true, false (checkbox), dropdown options etc.
      const typeWithFixedValues = [AttributeValueType.Checkbox, ...typeWithDefinition];
      const wasFixedValueType = typeWithFixedValues.includes(this.selectedValueType);
      const isFixedValueType = typeWithFixedValues.includes(type);
      const hasDefinition = typeWithDefinition.includes(type);

      this.selectedValueType = type;
      this.f.defaultValue.setValidators(this.getValidatorsForValueType(type));

      if (isFixedValueType) this.f.defaultValue.setValue(type == AttributeValueType.Checkbox ? false : 0);
      else if (wasFixedValueType) this.f.defaultValue.setValue(null);
      else this.f.defaultValue.updateValueAndValidity();

      if (hasDefinition) this.f.definition.enable();
      else this.f.definition.disable();
    });

    this.f.valueOptions.valueChanges.subscribe(options => {
      const items = options.map(x => x.name);
      const definition = items.length > 0 ? new AttributeDefinitionModel({ listItems: items }) : null;

      this.f.definition.setValue(definition);
      this.f.defaultValue.setValue(null);
    });
  }

  private attributeUniqueCheck(control: UntypedFormGroup): ValidationErrors | null {
    if (!this.form) {
      return null;
    }

    const { name, categoryId } = this.form.value || {};

    const exists = this.existingAttributeNames.some(x => x.name == name && (x.categoryId || null) == categoryId);

    return exists ? { unique: true } : null;
  }

  private resetForm() {
    this.f.name.setValue(this.attribute?.name);
    this.f.description.setValue(this.attribute?.description);
    this.f.valueType.setValue(this.attribute?.valueType);
    this.f.defaultValue.setValue(
      this.attribute?.valueType != AttributeValueType.Checkbox ? this.attribute?.value : this.attribute.value == 'true'
    );
    this.f.categoryId.setValue(this.attribute?.category.id);

    const hasDefinition = typeWithDefinition.includes(this.attribute?.valueType);
    if (hasDefinition) {
      const definition = this.attribute.definition;
      this.f.definition.setValue(definition);
      if (
        this.attribute?.valueType == AttributeValueType.Dropdown ||
        this.attribute?.valueType == AttributeValueType.Autocomplete
      ) {
        const map = (definition?.listItems || []).map(x => ({ name: x, value: x }));
        this.f.valueOptions.setValue(map, { emitEvent: false });
      }
    }

    this.form.markAsUntouched();
  }

  private parseForm() {
    const model = new AttributeModel({
      id: this.attributeId,
      name: this.f.name.value,
      description: this.f.description.value,
      valueType: this.f.valueType.value,
      definition: this.f.definition.enabled ? this.f.definition.value : null,
      value: this.f.defaultValue.value,
      category: new CategoryModel({
        id: this.f.categoryId.value,
      }),
    });

    // replace comma by period
    if (model.value && model.valueType == AttributeValueType.Double) {
      model.value = model.value.replace(',', '.');
    }

    return model;
  }

  private getValidatorsForValueType(type: AttributeValueType): ValidatorFn | ValidatorFn[] {
    switch (type) {
      case AttributeValueType.Integer:
        return [Validators.pattern(intRegex)];
      case AttributeValueType.Double:
        return [Validators.pattern(doubleRegex)];
      default:
        return [];
    }
  }
}
