<div class="info-sidebar-container" [class.open]="isOpen && !isHidden">
	<ng-content></ng-content>

	<div class="info-sidebar">
		<div mat-ripple class="unhide-button" [class.visible]="isHidden" (click)="unhide()">
			<span class="mdi mdi-arrow-left-bold-box-outline"></span>
		</div>

		<ng-content select="[sidebar]"></ng-content>
	</div>
</div>