export enum ZohoEvent {
  close = 'onAppClose',
  open = 'onAppOpen',
}

export interface ZohoSettings {
  hideLauncherIcon: boolean;
}

export interface Zoho {
  Actions: {
    Open(): void;
    Close(): void;
  };
  Events: {
    Subscribe(event: ZohoEvent, callback: () => void): void;
    UnSubscribe(event: ZohoEvent): void;
  };
}

declare global {
  interface Window {
    ZohoHCAsapSettings: ZohoSettings;
    ZohoHCAsapReady(): void;
    ZohoHCAsap: Zoho;
  }
}
