import { Component, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConstructionDiaryState, ModuleType } from '@app/api';
import { BaseSubscriptionComponent, FormUtils } from '@app/core';
import {
  DialogResultModelStatus,
  DriveItemAction,
  DriveItemActionDialogComponent,
  DriveItemActionDialogResultModel,
  DriveItemActionTarget,
  StateSchemaService,
} from '@app/shared/components';
import { PcfDiary, PcfRole, PcfRoles, ProjectConfigService } from '@app/shared/services/project-config';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-diary-config',
  templateUrl: './diary-config.component.html',
  styleUrls: ['./diary-config.component.scss'],
})
export class DiaryConfigComponent extends BaseSubscriptionComponent implements OnInit {
  stateSchemaService: StateSchemaService<ConstructionDiaryState>;
  fullReportPath: string = '';

  constructor(
    private dialog: MatDialog,
    private configService: ProjectConfigService,
    private translateService: TranslateService
  ) {
    super();
  }

  get form() {
    return this.configService.diaryForm;
  }

  get resourceControl() {
    return FormUtils.getFormControl<PcfDiary>(this.form, 'defaultReportResource');
  }

  get pathControl() {
    return FormUtils.getFormControl<PcfDiary>(this.form, 'defaultReportPath');
  }

  get isSetReportPathDisabled(): boolean {
    return this.resourceControl.disabled || this.pathControl.disabled;
  }

  get canProvision() {
    return this.configService.canProvision;
  }

  get isTemplate() {
    return this.configService.isTemplate;
  }

  get states() {
    return FormUtils.getFormControl<PcfDiary, UntypedFormArray>(this.form, 'states');
  }

  ngOnInit() {
    this.subscribe(
      combineLatest([this.resourceControl.valueChanges, this.pathControl.valueChanges])
        .pipe(debounceTime(100))
        .pipe(startWith([this.resourceControl.value, this.pathControl.value])),
      ([resource, path]) => {
        let fullReportPath = resource ?? '';
        if (path) fullReportPath += path;
        this.fullReportPath = fullReportPath;
      }
    );

    const diaryStates = Object.values(ConstructionDiaryState);
    const mapRoles = (roles: PcfRole[]) => roles.map(r => this.configService.roleAndUserData[r.id] ?? r);
    const rolesForm = FormUtils.getFormControl<PcfRoles, UntypedFormArray>(this.configService.rolesForm, 'roles');
    const roles = rolesForm.value ?? [];

    this.stateSchemaService = new StateSchemaService<ConstructionDiaryState>(
      this.states,
      diaryStates,
      this.configService.diaryStatePredefinedRoles.concat(mapRoles(roles)),
      this.configService.diaryStatePermissions,
      'constructionDiary.state.',
      this.dialog,
      this.translateService
    );

    this.subscribe(rolesForm.valueChanges, (updatedRoles: PcfRole[]) => {
      this.stateSchemaService.setRoles(this.configService.diaryStatePredefinedRoles.concat(mapRoles(updatedRoles)));
      this.stateSchemaService.permissions = this.configService.diaryStatePermissions;
    });
  }

  private get defaultReportResource(): string {
    return this.resourceControl.value;
  }

  private set defaultReportResource(value: string) {
    this.resourceControl.setValue(value);
  }

  private get defaultReportPath(): string {
    return this.pathControl.value;
  }

  private set defaultReportPath(value: string) {
    this.pathControl.setValue(value);
  }

  async setDefaultReportPath() {
    const returnLocation: DriveItemAction<DriveItemActionTarget> = async target => {
      return target;
    };

    const result: DriveItemActionDialogResultModel<DriveItemActionTarget> = await this.dialog
      .open(DriveItemActionDialogComponent<DriveItemActionTarget>, {
        disableClose: true,
        panelClass: 'full-content',
        data: {
          title: 'general.chooseStorageLocation',
          icon: 'mdi-folder-move-outline',
          modules: [ModuleType.Document],
          location: {
            resource: this.defaultReportResource,
            path: this.defaultReportPath,
          },
          action: returnLocation,
        },
      })
      .afterClosed()
      .toPromise();

    const folder = result.actionResult;
    if (result.status === DialogResultModelStatus.success) {
      this.defaultReportResource = folder.resource;
      this.defaultReportPath = folder.path;
      this.form.markAsDirty();
    }
  }
}
