import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export enum PlanVerificationState {
  latest = 'latest',
  latestButUnapproved = 'latestButUnapproved',
  latestApproved = 'latestApproved',
  outdated = 'outdated',
}

@Component({
  selector: 'app-plan-verify-dialog',
  templateUrl: './plan-verify-dialog.component.html',
  styleUrls: ['./plan-verify-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlanVerifyDialogComponent {
  title: string = 'planning.dialogs.planVerify.title';
  description: string;
  iconClasses: string[] = [];

  constructor(public planVerifyRef: MatDialogRef<PlanVerifyDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    planVerifyRef.addPanelClass('verify-dialog-panel');
    this.setState(data?.state);
  }

  ok() {
    this.planVerifyRef.close();
  }

  private setState(state: PlanVerificationState) {
    this.description = 'planning.dialogs.planVerify.' + state;

    switch (state) {
      case PlanVerificationState.latest:
        this.iconClasses = ['mdi-check', 'ok'];
        break;
      case PlanVerificationState.latestButUnapproved:
      case PlanVerificationState.latestApproved:
        this.iconClasses = ['mdi-check', 'warn'];
        break;
      case PlanVerificationState.outdated:
        this.iconClasses = ['mdi-close', 'error'];
        break;
    }
  }
}
