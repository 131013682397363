import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-return-menu-button',
  templateUrl: './return-menu-button.component.html',
  styleUrls: ['./return-menu-button.component.scss'],
})
export class ReturnMenuButtonComponent {
  @Input() public cssClass: string;
}
