import { PipeTransform, Pipe } from '@angular/core';
import { FilePreviewCacheService, ImagePreviewOptions } from '@app/core';
import { FilePreviewSpec } from '@app/api';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Pipe({ name: 'imagePreview' })
export class ImagePreviewPipe implements PipeTransform {
  constructor(private filePreviewCacheService: FilePreviewCacheService) {}

  transform(
    signedUrl: string,
    id: string,
    resource: string,
    spec = FilePreviewSpec.Thumbnail,
    modifiedOn?: Date,
    options?: ImagePreviewOptions
  ) {
    if (id) {
      return this.filePreviewCacheService.getPreview(signedUrl, id, spec, resource, modifiedOn, options.unsafe).pipe(
        catchError($err => {
          console.error($err);
          return of(this.filePreviewCacheService.getErrorImageUrl(options));
        })
      );
    }

    return null;
  }
}
