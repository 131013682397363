import { Component,  Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'c4-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {

  @Input() header: string;
  @Input() subHeader: string;
  @Input() iconClass: string;
  @Input() link = '';
  @Input() linkIsRouterLink = true;
  @Input() queryParams: any = {};
  @Input() navIconClass = 'mdi mdi-launch';
  @Input() darkHeaderText = false;
  //if linkButtonData is set: nav link is a button, and will trigger linkButtonClicked
  @Input() linkButtonData:any;
  @Input() linkButtonTooltip:string;
  @Output() linkButtonClicked: EventEmitter<any> = new EventEmitter();

  constructor() { }


  handleClick() {
    this.linkButtonClicked.emit(this.linkButtonData);
  }
}
