import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'startLower',
})
export class StartLowerPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): string | undefined {
    const strVal = value as string;

    return strVal ? strVal[0].toLowerCase() + strVal.slice(1) : null;
  }
}
