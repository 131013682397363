import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'queryParameter',
})
export class QueryParameterPipe implements PipeTransform {
  transform(value: string, params: Record<string, any>): unknown {
    if (!value) return value;

    let paramStart = value.indexOf('?');
    paramStart = paramStart === -1 ? value.length : paramStart;

    const [url, originalParams] = value.split('?');
    const newParams = new URLSearchParams(
      Object.entries(params).reduce((params, [key, value]) => (value ? { ...params, [key]: value } : params), {})
    );

    return `${url}?${newParams.toString()}${!originalParams ? '' : `&${originalParams}`}`;
  }
}
