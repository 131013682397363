import { Injectable } from '@angular/core';
import { ApiService, LogService } from '@app/core';
import { LeanBryntumRowResponseModel, StoreType } from '@app/shared/components';
import { Model } from '@bryntum/schedulerpro';
import { UserNotificationService } from '../user-notification/user-notification.service';
import { SchedulerService } from './scheduler.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class GlobalSchedulerService extends SchedulerService {
  constructor(
    protected log: LogService,
    protected apiService: ApiService,
    protected translate: TranslateService,
    protected userNotification: UserNotificationService
  ) {
    super(log, apiService, translate, userNotification);
  }

  async loadData() {
    try {
      const phases = await this.apiService.getPhases();
      this.phasesChanged(phases);
    } catch (e) {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.phases', e);
    }
  }

  protected loadStore(store: string): LeanBryntumRowResponseModel<Model> {
    // ignore other stores
    if (store == StoreType.resources) return super.loadStore(store);
  }
}
