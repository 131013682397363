import * as L from 'leaflet';
import { Utils } from '@app/core';

export function setupFeatureInformations(
  addedLayer: L.Polyline | L.CircleMarker | L.Marker,
  shape: string,
  color?: string,
  additionalMetadata?: object
) {
  const mt: any = {
    key: Utils.createUUID(),
    type: shape,
    modifiedOn: new Date(),
  };

  if (color && !('color' in additionalMetadata)) {
    mt.color = color;
  }

  if (addedLayer instanceof L.CircleMarker) {
    mt.radius = addedLayer.getRadius();
  }

  Object.assign(addedLayer, {
    feature: Object.assign(addedLayer.feature || {}, {
      type: 'Feature',
      properties: Object.assign(addedLayer.feature?.properties || {}, additionalMetadata, mt),
    }),
  });
}
