export class DataHolder<T> {
  private hasDefaultData: boolean;
  private busy: boolean;
  public cachedData: T;
  private defaultData: T;

  constructor(defaultData: T) {
    this.defaultData = defaultData;
    this.reset();
  }

  reset(fakeData?: T) {
    this.busy = false;
    this.hasDefaultData = true;
    this.cachedData = fakeData ? fakeData : this.defaultData;
  }

  get data() {
    return this.cachedData;
  }

  get dataReady(): boolean {
    return !this.hasDefaultData && !this.busy;
  }

  get busyClass(): string {
    return this.dataReady ? '' : 'c4-busy';
  }

  get busyContentClass(): string {
    return this.dataReady ? '' : 'c4-busy-content';
  }

  async updateData(fetchDataFunction: () => Promise<T>, resetOnError: boolean = false): Promise<T> {
    this.busy = true;
    const minLoadingTimePromise = this.getMinLoadingTime();
    try {
      this.cachedData = await fetchDataFunction();
      await minLoadingTimePromise;
      this.hasDefaultData = false;
    } catch (e) {
      if (resetOnError) {
        this.reset();
      } else {
        this.cachedData = null;
      }
      throw e;
    } finally {
      this.busy = false;
    }
    return this.cachedData;
  }

  getMinLoadingTime(duration: number = 250) {
    return new Promise<void>(resolve => {
      setTimeout(_ => {
        resolve();
      }, duration);
    });
  }
}
