interface State {
  key?: string;
  code?: string;
}

export interface ModelWithStates<T extends State> {
  currentState?: T;
  allowedStates?: T[];
}

export const stateComparer = (a, b) => (a.code < b.code ? -1 : a.code > b.code ? 1 : 0);

export function getStatesFromModel<T extends State>(model: ModelWithStates<T>): T[] {
  const states = model.allowedStates?.slice() ?? [];

  if (model.currentState) states.push(model.currentState);

  states.sort(stateComparer);

  return states;
}

export function getIntersectingStatesFromModels<T extends State>(models: ModelWithStates<T>[]): T[] {
  if (!models?.length) return [];

  return models
    .map(entity => entity.allowedStates.concat(entity.currentState))
    .reduce((intersectingStates, states) => intersectingStates.filter(state => states.some(s => s.key === state.key)))
    .sort(stateComparer);
}
