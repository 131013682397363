import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { FileOpener } from '@capacitor-community/file-opener';
import { HttpClient } from '@angular/common/http';

export class CapacitorUtils {
  static async urlFileDownload(absoluteUrl: string, httpClient: HttpClient, fileName: string, openFile: boolean) {
    let response = await httpClient.get(absoluteUrl, { responseType: 'blob' }).toPromise();
    this.blobFileHandler(response, fileName, openFile);
  }

  static async blobFileHandler(data: Blob, fileName: string, openFile: boolean) {
    await this.fileWrite(data, fileName, async path => {
      if (openFile && path) {
        await this.openFile(path, data.type);
      }
    });
  }

  static async fileWrite(data: Blob, fileName: string, callback: Function) {
    const isCsv = data.type.includes('text/csv');
    if (isCsv) {
      await this.writeCSV(await data.text(), fileName, callback);
    } else {
      await this.writeOther(data, fileName, callback);
    }
  }

  private static async writeCSV(data: string, fileName: string, callback: Function) {
    try {
      const result = await Filesystem.writeFile({
        path: fileName,
        data: data,
        directory: Directory.Data,
        encoding: Encoding.UTF8,
        recursive: true,
      });
      await callback(result.uri);
    } catch (e) {
      console.error('Unable to write file', e);
    }
  }

  private static async writeOther(data: Blob, fileName: string, callback: Function) {
    var reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onloadend = async function () {
      var base64data = reader.result;
      try {
        const result = await Filesystem.writeFile({
          path: fileName,
          data: <string>base64data,
          directory: Directory.Data,
          recursive: true,
        });
        await callback(result.uri);
      } catch (e) {
        console.error('Unable to write file', e);
      }
    };
  }

  static async openFile(path: string, mimeType: string) {
    if (mimeType.startsWith('text/csv')) {
      if (Capacitor.getPlatform() == 'ios') mimeType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      else mimeType = 'application/vnd.oasis.opendocument.spreadsheet';
    }

    await FileOpener.open({ filePath: path, contentType: mimeType }).catch(e => {
      console.error('Error opening file', e);
    });
  }

  static isApp(): boolean {
    return Capacitor.isNativePlatform();
  }
}
