import { Component, Input, ViewEncapsulation } from '@angular/core';

interface BaseLeanWorkpackageTemplate {
  name?: string;
  description?: string;
  craftHexColor?: string;
}

@Component({
  selector: 'app-workpackage-template-tile',
  templateUrl: './workpackage-template-tile.component.html',
  styleUrls: ['./workpackage-template-tile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WorkpackageTemplateTileComponent {
  @Input() set template(template: BaseLeanWorkpackageTemplate) {
    this.name = template.name;
    this.description = template.description;
    this.craftHexColor = template.craftHexColor ? `#${template.craftHexColor}` : null;
  }

  name: string;
  description: string;
  craftHexColor: string;
}
