<div class="c4-dialog-wrapper view-log">
  <h1 mat-dialog-title>{{'log.title' | translate}}</h1>
  <mat-dialog-content class="dialog-content">

    <div class="c4-docgrid-container" [class.c4-busy]="isBusy || !itemLogs">
      <c4-grid *ngIf="gridDef" [gridDef]="gridDef" [source]="itemLogs" [noDataText]="'log.noEntries'">
      </c4-grid>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
  </mat-dialog-actions>
</div>

<ng-template #itemType let-col='col' let-row='row'>
  <div *ngIf="row.driveItemType === 'Folder'" class="mdi c4-file-icon c4_icon_Folder"></div>
  <div *ngIf="row.driveItemType !== 'Folder'" class="mdi c4-file-icon"
       [ngClass]="['documents.icon.'+ (row.name| filetype|lowercase) |translate]"></div>
</ng-template>

<ng-template #itemDescription let-col='col' let-row='row'>
  <div class="item-description">
    <span class="c4-topline cell-header">
      <span>{{row.name}}</span>
      <span *ngIf="row.version"> ({{ row.version }})</span>
    </span>
    <span *ngIf="row.path" class="sub-desc">{{row.path}}</span>
    <span *ngIf="!row.path" class="sub-desc">{{row.resource}}</span>
  </div>
</ng-template>

<ng-template #itemAction let-col='col' let-row='row'>
  <div class="item-action">
    <button *ngIf="row.driveItemLogType === logTypes.Share" mat-icon-button (click)="showReceivers(row)"
            [matTooltip]="'log.receivers.tooltip' | translate">
      <span *ngIf="row.notificationState" [ngClass]="getNotificationIcon(row)"></span>
    </button>
  </div>
</ng-template>

<ng-template #logType let-col='col' let-row='row'>
  <div class="item-driveItemLogType">
    {{'log.type.' + row.driveItemLogType | translate}}
  </div>
</ng-template>

<ng-template #mobileLogType let-col='col' let-row='row'>
  <div class="item-log-type-mobile">
    <span class="header">{{ 'grid.log.logtype' | translate }}</span>
    <span>{{ 'log.type.' + row.driveItemLogType | translate }}</span>
  </div>
</ng-template>

<ng-template #emptyTemplate>
</ng-template>