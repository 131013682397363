import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ApiService, ProjectService, Utils } from '..';
import { ApiUrlService } from '../services/api/api-url.service';

@Injectable()
export class TenantSelectionGuard {
  constructor(
    private apiService: ApiService,
    private apiUrlService: ApiUrlService,
    private router: Router,
    private route: ActivatedRoute,
    private projectService: ProjectService
  ) {}

  async canActivateChild(childRoute: ActivatedRouteSnapshot) {
    if (childRoute.queryParamMap.has('logout')) {
      this.apiService.removeSavedTenant();
      return this.router.createUrlTree(['tenant'], {
        queryParams: { ...childRoute.queryParams, logout: null },
      });
    }

    let tenantUrl = await this.apiService.getTenantUrl();

    if (!tenantUrl) return true;

    this.apiUrlService.fqdn = tenantUrl;
    this.projectService.refreshTenant();

    Utils.routeToExternalUrl(tenantUrl);

    return false;
  }
}
