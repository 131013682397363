import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IProjectConfigTeamRole } from '@app/api';
import { sharepointNameRegex } from '@app/core';
import { FormComponent } from '@app/core/utils/form-component';

@Component({
  selector: 'app-edit-role-dialog',
  templateUrl: './edit-role-dialog.component.html',
  styleUrls: ['./edit-role-dialog.component.scss'],
})
export class EditRoleDialogComponent extends FormComponent implements OnInit {
  title = 'projectConfig.roles.';
  role: IProjectConfigTeamRole;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<EditRoleDialogComponent>,
    private formBuilder: UntypedFormBuilder
  ) {
    super();

    this.title += data?.role ? 'edit' : 'add';
    this.role = data?.role;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.role?.name ?? '', [Validators.required, Validators.pattern(sharepointNameRegex)]],
      description: [{ value: this.role?.description ?? '', disabled: !!this.role?.isSystemRole }],
      isReadOnly: [this.role?.isReadOnly ?? false],
      isHidden: [this.role?.isHidden ?? false],
    });
  }

  confirm() {
    if (this.form.valid) {
      const role = this.parseForm();
      this.dialogRef.close(role);
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  private parseForm(): IProjectConfigTeamRole {
    return {
      name: this.f.name.value,
      description: this.f.description.value,
      isReadOnly: this.f.isReadOnly.value,
      isHidden: this.f.isHidden.value,
    };
  }
}
