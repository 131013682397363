<div class="c4-dialog-wrapper">
	<div class="dialog-header">
		<h1 mat-dialog-title>{{ title | translate }}</h1>
		<div class="filler"></div>
		<span *ngIf="form.errors?.unique" class="mdi mdi-information-outline"
					[matTooltip]="'general.errorForms.unique' | translate"></span>
	</div>
	<mat-dialog-content>
		<form *ngIf="form" class="category-form" [formGroup]="form">
			<ng-container [ngTemplateOutlet]="inputFieldTemplate" [ngTemplateOutletContext]="{
				fieldName: 'name'
			}"></ng-container>

			<ng-container [ngTemplateOutlet]="inputFieldTemplate" [ngTemplateOutletContext]="{
				fieldName: 'number'
			}"></ng-container>

			<ng-container [ngTemplateOutlet]="inputFieldTemplate" [ngTemplateOutletContext]="{
				fieldName: 'sequence'
			}"></ng-container>

			<app-tree-selection formControlName="parentId" [title]="'categories.fieldNames.parent'" [models]="categories"
													[errors]="f.parentId.errors">
			</app-tree-selection>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
		<button mat-flat-button color="primary" (click)="confirm()" [disabled]="isBusy || form.invalid" type="button">{{
			'general.save' | translate }}</button>
	</mat-dialog-actions>
</div>
<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:false }"></ngx-loading>

<ng-template #inputFieldTemplate [formGroup]="form" let-fieldName="fieldName">
	<mat-form-field>
		<mat-label>
			<span>{{ 'categories.fieldNames.' + fieldName | translate }}</span>
		</mat-label>
		<input matInput [formControlName]="fieldName">
		<mat-error *ngIf="f[fieldName].invalid">
			{{ 'general.errorForms.' + getError(fieldName) | translate }}
		</mat-error>
	</mat-form-field>
</ng-template>