<div class="c4-dialog-wrapper">
  <h1 mat-dialog-title>{{ 'dialogs.nameConflict.caption' | translate }}</h1>
  <mat-dialog-content>
    <div class="app-nameconflict-filename">
      <span class="mdi mdi-file"></span>
      <span>{{fileName}}</span>
    </div>
    <div>{{ 'dialogs.nameConflict.description' | translate }}</div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button (click)="abort()" type="button">{{ 'general.cancel' | translate }}</button>
    <div class="button-group">
      <button mat-flat-button (click)="keepBoth()"
        type="button">{{ 'dialogs.nameConflict.keepBoth' | translate }}</button>
      <button mat-flat-button color="primary" (click)="replace()"
      type="button">{{ 'dialogs.nameConflict.replace' | translate }}</button>
    </div>
  </mat-dialog-actions>
</div>