<!-- [class.visible]="isWorkpackageToolbarActive && !isDragging" -->
<div class="work-package-toolbar" [class.c4-busy]="templatesBusy.isBusy">
	<div class="work-package-toolbar-header">
		<div class="title-container">
			<span class="title">{{ (selectedLeanTemplateType === leanTemplateType.sequence ? 'scheduler.sequences.schedule' :
				'scheduler.workpackages.schedule') | translate }}</span>
			<span class="sub-title">{{ 'scheduler.workpackages.scheduleDescription' | translate }}</span>
		</div>

		<div class="searchbar-container">
			<app-searchbar [(searchText)]="filter" [emitOnInput]="true" [disabled]="templatesBusy.isBusy"
										 [placeholder]="(selectedLeanTemplateType == leanTemplateType.sequence ? 'workpackageSequences' : 'workpackages') + '.filter'">
			</app-searchbar>
		</div>

		<span class="chips">
			<app-chips-filter preventWrap="true" [values]="workpackagePhases"
												[(selectedValues)]="selectedWorkpackagePhases"></app-chips-filter>
		</span>

		<div class="toggle" *ngIf="showSequences">
			<mat-button-toggle-group [(ngModel)]="selectedLeanTemplateType" (change)="onChangeSelectedTemplateType()">
				<mat-button-toggle [value]="leanTemplateType.workpackage">
					{{ 'scheduler.workpackages.title' | translate }}
				</mat-button-toggle>
				<mat-button-toggle [value]="leanTemplateType.sequence">
					{{ 'workpackageSequences.title' | translate }}
				</mat-button-toggle>
			</mat-button-toggle-group>
		</div>

		<button mat-icon-button (click)="close.emit()">
			<span class="mdi mdi-close"></span>
		</button>
	</div>
	<div class="work-package-toolbar-body">
		<ng-container [ngSwitch]="selectedLeanTemplateType">
			<ng-container *ngSwitchCase="leanTemplateType.sequence">
				<span *ngIf="!filteredSequences?.length; else sequencesTemplate">
					{{ 'grid.workpackageSequences.noDataText' | translate }}
				</span>

				<ng-template #sequencesTemplate>
					<app-workpackage-sequence *ngFor="let sequence of filteredSequences" class="drag-item-for-scheduler sequence"
																		[title]="sequence.name" [workpackageTemplates]="sequence.templates"
																		[attr.data-entity-id]="sequence.id" [attr.data-type]="leanTemplateType.sequence">
					</app-workpackage-sequence>
				</ng-template>
			</ng-container>
			<ng-container *ngSwitchDefault>
				<span *ngIf="!filteredWorkpackageTemplates?.length; else templatesTemplate">
					{{ 'grid.workpackageTemplates.noDataText' | translate }}
				</span>

				<ng-template #templatesTemplate>
					<div #templateContainer class="templates-container">
						<app-workpackage-template-tile *ngFor="let template of filteredWorkpackageTemplates"
																					 class="drag-item-for-scheduler snappable" [template]="template"
																					 [attr.data-entity-id]="template.id"
																					 [attr.data-type]="leanTemplateType.workpackage">
						</app-workpackage-template-tile>
					</div>
				</ng-template>
			</ng-container>
		</ng-container>
	</div>
</div>