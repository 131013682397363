import { IAuthProtocolInfoModel, PublicAuthenticationType, PasswordDeliveryType } from '@app/api';

export enum ApiAuthenticationOtpType {
    None,
    Sms,
    EMail
}

export class ApiAuthenticationSettings implements IAuthProtocolInfoModel {
    primaryAuthenticationType?: PublicAuthenticationType;
    primaryAuthenticationSupportsPasswordChange?: boolean;
    secondaryAuthenticationType?: PublicAuthenticationType;
    passwordDeliveryType?: PasswordDeliveryType;

    constructor(authModel: IAuthProtocolInfoModel) {
        this.primaryAuthenticationType = authModel.primaryAuthenticationType;
        this.primaryAuthenticationSupportsPasswordChange = authModel.primaryAuthenticationSupportsPasswordChange;
        this.secondaryAuthenticationType = authModel.secondaryAuthenticationType;
        this.passwordDeliveryType = authModel.passwordDeliveryType;
    }

    get primarySecretIsPassword(): boolean {
        if (!this.primaryAuthenticationType) {
            return true;
        }
        return PublicAuthenticationType.Password === this.primaryAuthenticationType;
    }

    get secondarySecretEnabled(): boolean {
        return this.secondaryAuthenticationType !== undefined && this.secondaryAuthenticationType !== null;
    }

    get otpType(): ApiAuthenticationOtpType {
        if (!this.primarySecretIsPassword) {
            //we use an OTP if the primary secret is not a password
            if (PublicAuthenticationType.Email === this.primaryAuthenticationType) {
                return ApiAuthenticationOtpType.EMail;
            } else {
                return ApiAuthenticationOtpType.Sms;
            }
        }

        if (this.secondarySecretEnabled) {
            //we use an OTP if the primary is password, but the secondary is enabled
            if (PublicAuthenticationType.Email === this.secondaryAuthenticationType) {
                return ApiAuthenticationOtpType.EMail;
            } else {
                return ApiAuthenticationOtpType.Sms;
            }
        }

        return ApiAuthenticationOtpType.None;
    }
}
