import { Injectable, Output, EventEmitter } from '@angular/core';
import { C4EventHandler } from '@app/core/utils/c4-event-handler';
import { DragDropItem } from './drag-drop-item';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription, Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalsService {
  isTeams = false;
  isMobile = true;
  isMobileMenu = false;
  isPhone = false;

  public showProjectMenu$: Subject<boolean> = new Subject<boolean>();

  private windowResizeSubject = new Subject<void>();
  private eventHandler: C4EventHandler;
  private dragDropItems: DragDropItem[];
  private startupProgressIsHiddenSubscription: Subscription;

  isOAuth = false;
  oAuthQueryParams = ['client_id', 'state', 'redirect_uri', 'scope'];

  currentTeamsUserId: string;
  currentTeamsGroupId: string;
  currentTeamsTenantId: string;
  currentTeamsContentUrl: string;
  frillWidget: FrillWidget;

  public get viewport(): string {
    return this.isPhone ? 'phone' : this.isMobileMenu ? 'tablet' : 'desk';
  }

  get isDragDrop(): boolean {
    return this.dragDropItems.findIndex(item => item.isDragDrop) >= 0;
  }

  get windowResize$(): Observable<void> {
    return this.windowResizeSubject;
  }

  constructor(private router: Router) {
    this.eventHandler = new C4EventHandler('GlobalsService', ['sidebarChanged', 'sidebarMinifiedChanged']);
    this.dragDropItems = [];
    this.startupProgressIsHiddenSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (
          !event.urlAfterRedirects.startsWith('/login') &&
          !event.urlAfterRedirects.startsWith('/logout') &&
          !event.urlAfterRedirects.startsWith('/resetPassword')
        ) {
          //Login component and reset password component handle hiding of loading spinner themself
          this.hideLoadingSpinner();
        }
      }
    });
    window.addEventListener('resize', (e: UIEvent) => {
      this.windowResizeSubject.next();
    });
  }

  on(event: string, callback: () => void) {
    this.eventHandler.on(event, callback);
  }

  registerDragDropItem(item: DragDropItem) {
    if (!this.dragDropItems.contains(item)) {
      this.dragDropItems.push(item);
    }
  }

  unregisterDragDropItem(item: DragDropItem) {
    this.dragDropItems.remove(item);
  }

  hideLoadingSpinner() {
    if (!this.startupProgressIsHiddenSubscription) {
      return;
    }
    const element = document.getElementById('startupProgress');
    if (element.remove) {
      element.remove();
    } else {
      //IE
      element.parentElement.removeChild(element);
    }

    if (this.startupProgressIsHiddenSubscription) {
      this.startupProgressIsHiddenSubscription.unsubscribe();
      this.startupProgressIsHiddenSubscription = undefined;
    }
  }
}
