import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { CommentModel } from '@app/api';
import { BaseCommentsApi, ICommentsApiService } from './comments-api.service';

@Injectable({
  providedIn: 'root',
})
export class DefectCommentsApiService extends BaseCommentsApi implements ICommentsApiService {
  constructor(protected apiService: ApiService) {
    super(apiService);
  }

  update = (defectId: string, model: CommentModel) => this.apiService.editDefectComment(defectId, model);
  delete = (defectId: string, commentId: string) => this.apiService.deleteDefectComment(defectId, commentId);
}
