<mat-form-field class="search-select-form" [formGroup]="selectFormGroup" [floatLabel]="floatLabel">
    <mat-label *ngIf="title">{{ title | translate }}</mat-label>
    <mat-select #matSelectSearch [formControlName]="selectFormControlName" class="search-select-mat-select"
                [multiple]="multiple" [compareWith]="compareWith"
                (keydown)="selectionKeyEvent($event,matSelectSearchInput)"
                (openedChange)="resetSearchSelect(matSelectSearchInput, selectList)"
                (closed)="clearFilter()">
        <mat-select-trigger>
            <ng-container *ngIf="triggerTemplate; else multiple ? multiTrigger : defaultTrigger"
                          [ngTemplateOutlet]="triggerTemplate"
                          [ngTemplateOutletContext]="{ value: formControl?.value }">
            </ng-container>
            <ng-template #defaultTrigger>
                <ng-container *ngIf="formControl.value">
                    <span *ngFor="let field of displayFields; let i = index">
                        <span *ngIf="i > 0">&nbsp;</span>
                        <span>{{ formControl.value[field] }}</span>
                    </span>
                </ng-container>
            </ng-template>
            <ng-template #multiTrigger>
                <ng-container *ngIf="formControl.value">
                    <span *ngFor="let value of formControl.value; let j = index">
                        <span *ngIf="j > 0">,&nbsp;</span>
                        <span *ngFor="let field of displayFields; let i = index">
                            <span *ngIf="i > 0">&nbsp;</span>
                            <span *ngIf="value">{{ value[field] }}</span>
                        </span>
                    </span>
                </ng-container>
            </ng-template>
        </mat-select-trigger>
        <div class="search-select-custom-option option-filter" [class.multi-select]="this.multiple">
            <span class="mdi mdi-magnify"></span>
            <input class="custom-option-search" type="text" val="" #matSelectSearchInput
                   (input)="searchInput($event, selectList)"
                   (keyup)="handleKeyEvent($event,selectList,matSelectSearch)">
        </div>
        <div *ngIf="addSelectNew" matRipple class="search-select-custom-option option-click"
             [class.single-select]="!this.multiple" (click)="matSelectSearch.close(); addNew()">
            <span class="mdi mdi-plus"></span>
            <span>{{ 'general.addEntry' | translate }}</span>
        </div>
        <div *ngIf="addSelectNone" matRipple class="search-select-custom-option option-click"
             [class.single-select]="!this.multiple" (click)="matSelectSearch.close(); clearValue()">
            <span class="mdi mdi-close"></span>
            <span>{{ 'general.selectNone' | translate }}</span>
        </div>
        <ng-container *ngIf="selectListIsGroup; else noGroup">
            <ng-template [ngIf]="!areAllHidden && selectList?.length && hasItems" [ngIfElse]="noDataOption">
                <ng-container *ngFor="let group of selectList; let first = first">
                    <mat-optgroup *ngIf="hasVisibleElements(group)" class="search-select-optgroup"
                                  [ngClass]="{'search-select-optgroup-separator': !first}">
                        <ng-container *ngFor="let item of group.items" [ngTemplateOutlet]="option"
                                      [ngTemplateOutletContext]="{$implicit: item}"></ng-container>
                    </mat-optgroup>
                </ng-container>
            </ng-template>
        </ng-container>

        <ng-template #noGroup>
            <ng-template [ngIf]="!areAllHidden && selectList?.length" [ngIfElse]="noDataOption">
                <ng-container *ngFor="let item of selectList" [ngTemplateOutlet]="option"
                              [ngTemplateOutletContext]="{$implicit: item}">
                </ng-container>
            </ng-template>
        </ng-template>
        <ng-template #option let-item>
            <mat-option [value]="item" [disabled]="item.hidden" [ngClass]="[item.hidden?'hidden':'visible']"
                        [class.mat-active]="item.selected" [class.custom]="optionTemplate"
                        [class.single-option]="!this.multiple">
                <ng-container *ngIf="optionTemplate; else defaultTemplate" [ngTemplateOutlet]="optionTemplate"
                              [ngTemplateOutletContext]="{ item: item }">
                </ng-container>
                <ng-template #defaultTemplate>
                    <span *ngFor="let field of displayFields; let i = index">
                        <span *ngIf="i > 0">&nbsp;</span>
                        <span *ngIf="item" class="option-label" [ngClass]="field">{{ item[field] }}</span>
                    </span>
                </ng-template>
            </mat-option>
        </ng-template>
        <ng-template #noDataOption>
            <mat-option class="no-data-option" [disabled]="true">{{ 'general.noDataText' | translate }}</mat-option>
        </ng-template>
        <mat-optgroup *ngIf="addShowAll" class="search-select-optgroup search-select-optgroup-separator">
            <mat-option (click)="showAll($event)" [class.custom]="optionTemplate">
                <span>&nbsp;</span>
                <span class="option-label" [ngClass]="field">{{ 'general.show-all' | translate }}</span>
            </mat-option>
        </mat-optgroup>
    </mat-select>
    <mat-error *ngIf="error">{{ error | translate }}</mat-error>
</mat-form-field>