<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: true }"></ngx-loading>
<ng-container *ngIf="!globals.isPhone">
  <div class="tasklike-detail-content room-detail-content" [formGroup]="form">
    <div class="content-container content-a">
      <!-- Custom Attributes -->
      <div class="expansion-panel-header-like">
        <span>{{ 'roomBook.attributes.custom' | translate }}</span>
        <ng-container *ngIf="fieldError" [ngTemplateOutlet]="error"
                      [ngTemplateOutletContext]="{ fieldError: fieldError }">
        </ng-container>
      </div>
      <button class="add-attribute-button" mat-flat-button color="primary" (click)="addAttribute()">
        <span>{{ 'roomBook.attributes.add' | translate }}</span>
      </button>
      <mat-chip-list *ngIf="allCategories?.length" multiple>
        <mat-chip *ngFor="let category of allCategories" [selectable]="false" [removable]="false" #chip="matChip"
                  (click)="chipClick(chip, $event)" [value]="category"
                  [selected]="([category.target] | formCategoryContains:filteredCategories:'Filter').length > 0">
          {{ category.name }}
        </mat-chip>
      </mat-chip-list>

      <ng-container [ngTemplateOutlet]="categoriesTemplate" [ngTemplateOutletContext]="{
        categories: categories,
        indent: 0,device:'desktop'}">
      </ng-container>
      <app-comments *ngIf="!isTemplate" formControlName="comment" [service]="commentsService"></app-comments>

    </div>
    <!-- Fixed Attributes -->
    <div class="content-container content-b ">
      <mat-expansion-panel *ngIf="isInProject" class="content-container slim-expansion-panel" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="panel-title">
              {{ 'dialogs.defects.zones' | translate }}
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-container">
          <app-zone-assignment formControlName="zones" [zoneGroups]="zoneGroups"
                               [readonlyZoneIds]="implicitZoneIds"></app-zone-assignment>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel class="content-container slim-expansion-panel" expanded>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="panel-title">
              {{ 'roomBook.attributes.fixed' | translate }}
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-container fixed-attributes">
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.externalnumber' | translate }}</mat-label>
            <input matInput formControlName="externalNumber">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.type' | translate }}</mat-label>
            <input matInput formControlName="type">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.use' | translate }}</mat-label>
            <input matInput formControlName="use">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.use2' | translate }}</mat-label>
            <input matInput formControlName="use2">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.shellheight' | translate }}</mat-label>
            <input matInput formControlName="shellHeight">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.clearanceheight' | translate }}</mat-label>
            <input matInput formControlName="clearanceHeight">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.floorarea' | translate }}</mat-label>
            <input matInput formControlName="floorArea">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.volume' | translate }}</mat-label>
            <input matInput formControlName="volume">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.perimeter' | translate }}</mat-label>
            <input matInput formControlName="perimeter">
          </mat-form-field>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="globals.isPhone">
  <div class="tasklike-detail-content room-detail-content mobile" [formGroup]="form">
    <mat-expansion-panel *ngIf="isInProject" class="content-container slim-expansion-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="panel-title">
            {{ 'dialogs.defects.zones' | translate }}
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="content-container">
        <app-zone-assignment formControlName="zones" [zoneGroups]="zoneGroups"
                             [readonlyZoneIds]="implicitZoneIds"></app-zone-assignment>
      </div>
    </mat-expansion-panel>
    <div class="intern-container intern-form">
      <mat-expansion-panel class="content-container slim-expansion-panel">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="panel-title">
              {{ 'roomBook.attributes.fixed' | translate }}
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="content-container">
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.type' | translate }}</mat-label>
            <input matInput formControlName="type">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.use' | translate }}</mat-label>
            <input matInput formControlName="use">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.use2' | translate }}</mat-label>
            <input matInput formControlName="use2">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.shellheight' | translate }}</mat-label>
            <input matInput numberInput formControlName="shellHeight">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.clearanceheight' | translate }}</mat-label>
            <input matInput numberInput formControlName="clearanceHeight">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.floorarea' | translate }}</mat-label>
            <input matInput numberInput formControlName="floorArea">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.volume' | translate }}</mat-label>
            <input matInput numberInput formControlName="volume">
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'roomBook.fieldName.perimeter' | translate }}</mat-label>
            <input matInput numberInput formControlName="perimeter">
          </mat-form-field>
        </div>
      </mat-expansion-panel>
    </div>
    <div class="content-container content-a">
      <div class="expansion-panel-header-like">
        <span class="title">{{ 'roomBook.attributes.custom' | translate }}</span>
        <ng-container *ngIf="categories?.invalid" [ngTemplateOutlet]="error"
                      [ngTemplateOutletContext]="{ fieldError: getFirstAttributeError() }">
        </ng-container>
      </div>
      <button mat-flat-button color="primary" (click)="addAttribute()">
        <span>{{ 'roomBook.attributes.add' | translate }}</span>
      </button>

      <ng-container [ngTemplateOutlet]="categoriesTemplate" [ngTemplateOutletContext]="{
        categories: categories,
        indent: 0,
        device:'mobile'}">
      </ng-container>

      <app-comments *ngIf="!isTemplate" formControlName="comment" [service]="commentsService"></app-comments>

    </div>
  </div>
</ng-container>

<ng-template #error let-fieldError="fieldError">
  <div class="error" *ngIf="fieldError">
    <span class="mdi mdi-information"></span>
    <span>{{ 'general.hintCaption.atField' | translate }}</span>
    <span>&nbsp;'<i>{{fieldError.fieldName}}</i>&nbsp;': </span>
    <span>{{ 'general.errorForms.' + fieldError.error | translate }}</span>
  </div>
</ng-template>

<ng-template #categoriesTemplate let-categories="categories" let-indent="indent" let-cssDevice="device">
  <div class="category" *ngFor="let category of categories.controls | formCategoryContains:filteredCategories">
    <div class="category-header" [ngClass]="indent===0?'main-header':indent===1?'sub-header':'group-caption'">
      {{ category.controls.categoryLabel.value }}
    </div>
    <ng-container *ngIf="cssDevice==='desktop'">
      <div *ngIf="category.controls.attributes.controls.length" cdkDropList cdkDropListSortingDisabled="true"
           class="attributes" [ngClass]="cssDevice"
           (cdkDropListDropped)="drop(category.controls.attributes, $event, tooltip)">
        <div cdkDrag class="attribute" *ngFor="let attribute of category.controls.attributes.controls; let i = index"
             [class.disabled]="attribute.disabled" [attr.data-index]="i">
          <ng-container [ngTemplateOutlet]="roomAttributeCell"
                        [ngTemplateOutletContext]="{category: category, attribute: attribute, i:i}">
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="cssDevice==='mobile'">
      <div *ngIf="category.controls.attributes.controls.length" class="attributes" [ngClass]="cssDevice">
        <div class="attribute" *ngFor="let attribute of category.controls.attributes.controls; let i = index"
             [class.disabled]="attribute.disabled" [attr.data-index]="i">
          <ng-container [ngTemplateOutlet]="roomAttributeCell"
                        [ngTemplateOutletContext]="{category: category, attribute: attribute}">
          </ng-container>
        </div>
      </div>
    </ng-container>
    <ng-container [ngTemplateOutlet]="categoriesTemplate" [ngTemplateOutletContext]="{
      categories: category.controls.categories,
      indent: indent + 1, device: cssDevice}"></ng-container>
  </div>
</ng-template>

<ng-template #roomAttributeCell let-category="category" let-attribute="attribute" let-i="i">
  <ng-container [formGroup]="attribute">
    <div class="form-field" [attr.data-index]="i">
      <span class="value" [attr.data-index]="i" #tooltip="matTooltip" (mousedown)="tooltip.hide()"
            [matTooltip]="'roomBook.attributes.info' | translate:{type: 'attributes.valueTypes.' + attributeValueType[attribute.controls.valueType.value] | translate, description: attribute.controls.description.value ? attribute.controls.description.value : ''}">
        <span>{{attribute.controls.name.value }}
          <span class="required-sup" *ngIf="attribute.controls.type.value !== controlTypes.Optional">*</span>
        </span>
      </span>
    </div>
    <ng-template #typeOption *ngIf="isTemplate" let-type="type" [ngTemplateOutlet]="typeOption"
                 [ngTemplateOutletContext]="{type: attribute.controls.type.value}">
      <button mat-icon-button [matTooltip]="'roomBook.attributes.type.'+type|translate"
              (click)="updateFieldType(type, attribute)">
        <span *ngIf="type !== controlTypes.Readonly && type !== controlTypes.Required"
              class="mdi mdi-pencil-outline"></span>
        <span *ngIf="type === controlTypes.Readonly" class="mdi mdi-lock"></span>
        <span *ngIf="type === controlTypes.Required" class="mdi mdi-pencil-plus"></span>
      </button>
    </ng-template>
    <div class="form-field" [attr.data-index]="i">
      <ng-template #attributeValue let-description="description" let-valueType="valueType" let-type="type"
                   [ngTemplateOutlet]="attributeValue" [ngTemplateOutletContext]="{
                     description: attribute.controls.description.value,
                     valueType: attribute.controls.valueType.value,
                     type: attribute.controls.type.value
                   }">
        <input formControlName="value" (mousedown)="$event.stopPropagation()" class="input-field" [attr.data-index]="i"
               [ngClass]="!isTemplate && controlTypes.Readonly === type?'readonly-form-field':''"
               [readOnly]="!isTemplate && controlTypes.Readonly === type"
               *ngIf="valueType == attributeValueType.Text || valueType == attributeValueType.Integer || valueType == attributeValueType.Double">

        <mat-checkbox *ngIf="valueType == attributeValueType.Checkbox" formControlName="value" class="check-field"
                      [attr.data-index]="i"
                      [ngClass]="!isTemplate && controlTypes.Readonly === type?'readonly-form-field':''">
          {{ 'attributes.checkboxLabel' | translate }}
        </mat-checkbox>
        <mat-form-field *ngIf="valueType == attributeValueType.Dropdown" class="select-field"
                        [ngClass]="!isTemplate && controlTypes.Readonly === type?'readonly-form-field':''">
          <mat-select formControlName="value" [attr.data-index]="i">
            <mat-option [value]="null">{{ 'general.selectNone' | translate}}</mat-option>
            <mat-option *ngFor="let option of attribute.controls.definition.value.listItems" [value]="option">
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field *ngIf="valueType == attributeValueType.Autocomplete" class="autocomplete">
          <input (mousedown)="$event.stopPropagation()" type="text" matInput formControlName="value"
                 [attr.data-index]="i" [readOnly]="!isTemplate && controlTypes.Readonly === type" class="auto-field"
                 [ngClass]="!isTemplate && controlTypes.Readonly === type?'readonly-form-field':''"
                 [matAutocomplete]="valueAutocomplete">
          <mat-autocomplete autoActiveFirstOption #valueAutocomplete="matAutocomplete">
            <mat-option *ngFor="let option of attribute.controls.filteredDefinition.value.listItems" [value]="option">
              {{ option }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </ng-template>
    </div>
    <div class="actions">
      <span class="mdi mdi-trash-can-outline" (click)="deleteAttribute(category.controls.attributes, i)"
            [class.disabled]="attribute.disabled"></span>
    </div>
  </ng-container>
</ng-template>