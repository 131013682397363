<div *ngIf="service?.privilegeNames?.length" class="matrix"
		 [style.grid-template-columns]="service.gridTemplateColumnsStyle" [class.c4-busy]="service.isBusy">
	<div class="c4-table-grid-cell c4-table-grid-header-top corner"></div>
	<div *ngFor="let privilegeName of service.privilegeNames" class="c4-table-grid-cell c4-table-grid-header-top centered"
			 [matTooltip]="privilegeName + 'Tooltip' | translate">
		<span class="c4-busy-content">{{ privilegeName | translate }}</span>
	</div>
	<div class="c4-table-grid-cell c4-table-grid-header-top centered"></div>

	<ng-container [ngTemplateOutlet]="groupTemplate" [ngTemplateOutletContext]="{
		group: 'systemRoles',
		rows: service.systemRoleForms
	}"></ng-container>
	<ng-container [ngTemplateOutlet]="groupTemplate" [ngTemplateOutletContext]="{
		group: 'roles',
		rows: service.roleForms,
		addableRows: service.availableRoles
	}"></ng-container>
	<ng-container [ngTemplateOutlet]="groupTemplate" [ngTemplateOutletContext]="{
		group: 'users',
		rows: service.userForms,
		addableRows: service.availableUsers
	}"></ng-container>
</div>

<ng-template #groupTemplate let-group="group" let-rows="rows" let-addableRows="addableRows">
	<div class="c4-table-grid-cell c4-table-grid-group-header c4-table-grid-header-start">
		<span>{{ 'individualPrivileges.' + group | translate }}</span>
	</div>
	<div class="c4-table-grid-cell c4-table-grid-group-header full-row"></div>
	<div class="c4-table-grid-cell c4-table-grid-group-header c4-table-grid-header-end">
		<button *ngIf="addableRows" mat-icon-button [disabled]="!addableRows.length || service.readOnly"
						(click)="searchSelect.toggle(true)">
			<span class="mdi mdi-plus-box"></span>
		</button>
		<div class="hidden-control">
			<app-search-select #searchSelect [selectList]="addableRows" [selectFormGroup]="addToGroupForm"
												 [selectFormControlName]="group" [displayFields]="['displayName']">
			</app-search-select>
		</div>
	</div>

	<ng-container *ngFor="let row of rows">
		<ng-template #rowTemplate let-rowData [ngTemplateOutlet]="rowTemplate" [ngTemplateOutletContext]="{
			$implicit: service.roleAndUserData[row.controls.id.value]
		}">
			<div #rowHeader class="c4-table-grid-cell c4-table-grid-header-start" [id]="row.controls.id.value">
				<div *ngIf="rowData" class="label-stack-container" [class.disabled]="row.controls.isInherited?.value">
					<span class="title c4-busy-content" [matTooltip]="'systemRoles.' + rowData.name | tryTranslate:rowData.name">
						{{ 'systemRoles.' + rowData.name | tryTranslate:rowData.name }}
					</span>
					<ng-template #descriptionTemplate let-description [ngTemplateOutlet]="descriptionTemplate"
											 [ngTemplateOutletContext]="{
						$implicit: 'systemRoles.description.' + row.controls.id.value | tryTranslate:rowData.description
					}">
						<span *ngIf="description" class="subtitle c4-busy-content" [matTooltip]="description">
							{{ description }}
						</span>
					</ng-template>
				</div>
			</div>
			<div *ngFor="let privilege of row.controls.privileges.controls" class="c4-table-grid-cell centered"
					 [formGroup]="privilege">
				<mat-checkbox class="c4-busy-content" formControlName="isSet"></mat-checkbox>
			</div>
			<div [formGroup]="row" class="c4-table-grid-cell">
				<mat-form-field appearance="outline" class="slim c4-busy-content">
					<mat-label>{{ 'individualPrivileges.multiPrivilege' | translate }}</mat-label>
					<mat-select formControlName="number">
						<mat-option *ngFor="let privilege of service.multiPrivileges" [value]="privilege.number">
							{{ 'individualPrivileges.privilege.' + privilege.displayName | translate }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<button *ngIf="row.controls.isInherited == null; else inheritButton" mat-icon-button class="c4-busy-content"
								(click)="service.removeIndividualPrivilege(row)" [disabled]="row.disabled || rowData?.isSystemRole">
					<span class="mdi mdi-trash-can-outline" [matTooltip]="'general.delete' | translate"></span>
				</button>

				<ng-template #inheritButton>
					<button mat-icon-button (click)="row.controls.isInherited.setValue(!row.controls.isInherited.value)"
									[disabled]="row.disabled">
						<span class="mdi" [class]="row.controls.isInherited.value ? 'mdi-link-variant' : 'mdi-link-variant-off'"
									[matTooltip]="'individualPrivileges.' + (row.controls.isInherited.value ? 'breakInheritance' : 'restoreInheritance') | translate"></span>
					</button>
				</ng-template>
			</div>
		</ng-template>
	</ng-container>
</ng-template>