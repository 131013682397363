<div class="root viewpoint c4-busy-content" (click)="onViewpointClicked($event, mainViewpoint)">
  <div *ngIf="mainViewpoint; else fallbackIconTemplate" [style.background-image]="mainViewpoint.thumbnail"
       class="viewpoint-image c4-busy-content"></div>
  <div class="viewpoint-image-counter" *ngIf="showViewpointCount && viewpointCount > 1">
    <span class="mdi mdi-camera"></span>
    {{ viewpointCount }}
  </div>
  <div *ngIf="canDeleteMainViewpoint && mainViewpoint" class="viewpoint-delete"
       (click)="onDeleteViewpoint($event, viewpoint)">
    <span class="mdi mdi-close"></span>
  </div>
</div>

<ng-template #fallbackIconTemplate>
  <span class="fallback-icon mdi mdi-camera-off-outline"></span>
</ng-template>

<app-popup *ngIf="canAddViewpoint || canLoadViewpoints" transparent [CdkOverlayOrigin]="cdkOverlayOrigin"
           [positions]="popupPositions" (opened)="loadAdditionalViewpoints()"
           (closed)="cancelLoadAdditionalViewpoints()">
  <app-acrylic-container>
    <div class="viewpoints">
      <ng-container *ngIf="!isLoadingViewpoints; else loadingTemplate">
        <div class="viewpoint" *ngFor="let viewpoint of additionalViewpoints"
             (click)="onViewpointClicked($event, viewpoint)">
          <div [style.background-image]="viewpoint.thumbnail" class="viewpoint-image"></div>
          <div *ngIf="canDeleteAdditionalViewpoints" class="viewpoint-delete"
               (click)="onDeleteViewpoint($event, viewpoint)">
            <span class="mdi mdi-close"></span>
          </div>
        </div>
      </ng-container>
      <div *ngIf="canAddViewpoint" class="viewpoint create" (click)="onNewViewpoint()">
        <div class="viewpoint-create">
          <span class="mdi mdi-camera"></span>
        </div>
      </div>
    </div>
  </app-acrylic-container>
</app-popup>

<ng-template #loadingTemplate>
  <ng-container [ngTemplateOutlet]="loadingViewpointTemplate" *ngFor="let i of loadingViewpoints"></ng-container>
</ng-template>

<ng-template #loadingViewpointTemplate>
  <div class="viewpoint c4-busy">
    <div class="viewpoint-image c4-busy-content"></div>
  </div>
</ng-template>