<div class="overlay-header-container">
	<ng-content></ng-content>

	<div class="overlay-container" [class.visible]="isOverlayVisible">
		<div class="overlay-content">
			<ng-content select="[overlay]"></ng-content>
		</div>

		<button mat-button class="close-button" *ngIf="isOverlayClosable; else placeholderTemplate"
						(click)="closeOverlay()">
			<span class="mdi mdi-close"></span>
			<span>{{ 'general.selectNone' | translate }}</span>
		</button>
	</div>
</div>

<ng-template #placeholderTemplate>
	<span></span>
</ng-template>