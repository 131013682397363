<div class="toggle-field-container" [class]="classes">
	<span *ngIf="icon" class="mdi" [class]="icon"></span>
	<span *ngIf="!icon && iconUrl" class="toggle-field-icon">
		<img [src]="iconUrl" />
	</span>
	<div class="toggle-field-information">
		<span class="toggle-field-caption single-line" [matTooltip]="label">{{ label }}</span>
		<span *ngIf="description" class="toggle-field-description">
			{{ description }}
		</span>
	</div>
	<mat-slide-toggle [checked]="isChecked" (change)="toggleChanged($event.checked)" [disabled]="isDisabled">
	</mat-slide-toggle>
</div>