<div class="header-container">
	<ng-content></ng-content>
</div>

<div class="expander-container" [style.height.rem]="isExpanded ? 4.8 : 0"
		 [style.margin-bottom.rem]="isExpanded ? 1.2 : 0">
	<div class="expander-content">
		<ng-content select="[expander]"></ng-content>
	</div>

	<button mat-icon-button (click)="close.emit()">
		<span class="mdi mdi-close"></span>
	</button>
</div>