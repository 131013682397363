import { Component, OnInit, Inject, TemplateRef, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export interface ConfirmWithTogglesDialogOptionConfig {
  title?: string;
  default?: boolean;
}

export interface ConfirmWithTogglesDialogConfig<TOptions extends { [key: string]: ConfirmWithTogglesDialogOptionConfig }> {
  title?: string;
  description?: string;
  options?: TOptions;
}

export interface ConfirmWithTogglesDialogResult {
  result: boolean;
  options: {
    [key: string]: boolean;
  };
}

type DialogOptionWorkItem = ConfirmWithTogglesDialogOptionConfig & {
  result: boolean;
};

@Component({
  selector: 'app-confirm-with-toggles-dialog',
  templateUrl: './confirm-with-toggles-dialog.component.html',
  styleUrls: ['./confirm-with-toggles-dialog.component.scss'],
})
export class ConfirmWithTogglesDialogComponent {
  title: string;
  description: string;

  options: { [key: string]: DialogOptionWorkItem } = {};
  optionKeys: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<ConfirmWithTogglesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: ConfirmWithTogglesDialogConfig<any>
  ) {
    this.title = data.title ?? 'TITLE_NOT_SET';
    this.description = data.description ?? null;
    this.initOptions(data.options);
  }

  ok() {
    this.dialogRef.close({
      result: true,
      options: this.getOptions(),
    });
  }

  cancel() {
    this.dialogRef.close({
      result: false,
      options: this.getOptions(),
    });
  }

  initOptions(options: { [key: string]: ConfirmWithTogglesDialogOptionConfig }) {
    if (!options) {
      return;
    }

    const keys = [];

    for (const key in options) {
      keys.push(key);
      const value = options[key];
      this.options[key] = {
        ...value,
        result: value.default ?? false,
      };
    }

    this.optionKeys = keys;
  }

  getOptions(): { [key: string]: boolean } {
    const result = {};
    for (const key in this.options) {
      result[key] = this.options[key].result;
    }
    return result;
  }
}
