import { Component, AfterViewInit, Input, OnDestroy, OnInit } from '@angular/core';
import { C4CarouselSlide } from '.';

class SlideModel {
  slide: C4CarouselSlide;
  animClass: string;

  constructor(slide: C4CarouselSlide) {
    this.slide = slide;
  }
}

@Component({
  selector: 'c4-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() autoStepMilliseconds = 0;
  @Input() nextButtonIcon = 'mdi mdi-chevron-right';
  @Input() prevButtonIcon = 'mdi mdi-chevron-left';
  @Input() buttonsInside = false;

  @Input()
  set slides(slides: C4CarouselSlide[]) {
    this.slideModels = slides.select(slide => new SlideModel(slide));
  }
  slideModels: SlideModel[];

  private currentSlide = 0;
  private numberOfSlides = 0;
  private animRunning = true;
  private autoStepDirectionNext = true;
  private timer: any;

  constructor() {}

  ngOnInit() {
    this.numberOfSlides = this.slideModels.length;
    if (this.numberOfSlides > 0) this.slideModels[0].animClass = 'c4-slide-current';
  }

  ngAfterViewInit() {
    if (this.numberOfSlides > 0 && this.autoStepMilliseconds > 0 && this.numberOfSlides > 1) {
      if (this.animRunning) {
        this.timer = setTimeout(() => {
          this.autoStep();
        }, this.autoStepMilliseconds);
      }
    }
  }

  ngOnDestroy(): void {
    this.autoStepMilliseconds = 0;
  }

  next() {
    this.autoStepDirectionNext = true;
    this.slideModels[this.currentSlide].animClass = 'c4-slide-to-left';
    this.currentSlide = (this.currentSlide + 1) % this.slideModels.length;
    this.slideModels[this.currentSlide].animClass = 'c4-slide-from-right';
  }

  prev() {
    this.autoStepDirectionNext = false;
    this.slideModels[this.currentSlide].animClass = 'c4-slide-to-right';
    this.currentSlide = (this.currentSlide - 1 + this.slideModels.length) % this.slideModels.length;
    this.slideModels[this.currentSlide].animClass = 'c4-slide-from-left';
  }

  pauseAnim() {
    this.animRunning = false;
    clearTimeout(this.timer);
  }

  runAnim() {
    this.animRunning = true;
    clearTimeout(this.timer);
    if (this.animRunning) {
      this.timer = setTimeout(() => {
        this.autoStep();
      }, this.autoStepMilliseconds);
    }
  }

  private autoStep() {
    if (this.autoStepMilliseconds > 0 && this.numberOfSlides > 1) {
      if (this.autoStepDirectionNext) {
        this.next();
      } else {
        this.prev();
      }
      if (this.animRunning) {
        this.timer = setTimeout(() => {
          this.autoStep();
        }, this.autoStepMilliseconds);
      }
    }
  }
}
