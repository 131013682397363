import { LeanPhaseModel, LeanWorkpackageSequenceModel } from '@app/api';
import {
  EventModel,
  EventModelConfig,
  ResourceModel,
  ResourceModelConfig,
  SchedulerEventModel,
  SchedulerEventModelConfig,
} from '@bryntum/schedulerpro';
import { extend } from 'leaflet';

export enum SequenceEditorLaneType {
  Phase = 'phase',
  Sequence = 'sequence',
}

export abstract class TypedResourceModel extends ResourceModel {
  abstract get type(): SequenceEditorLaneType;
}

export interface PhaseLaneModelConfig extends ResourceModelConfig {
  phase: LeanPhaseModel;
}

export class PhaseLaneModel extends TypedResourceModel {
  get type(): SequenceEditorLaneType {
    return SequenceEditorLaneType.Phase;
  }

  phase: LeanPhaseModel;

  constructor(config?: Partial<PhaseLaneModelConfig>) {
    super(config);

    this.phase = config?.phase || null;
  }
}

export interface SequenceLaneModelConfig extends ResourceModelConfig {
  sequence: LeanWorkpackageSequenceModel;
}

export class SequenceLaneModel extends TypedResourceModel {
  get type(): SequenceEditorLaneType {
    return SequenceEditorLaneType.Sequence;
  }
  sequence: LeanWorkpackageSequenceModel;

  constructor(config?: Partial<SequenceLaneModelConfig>) {
    super(config);

    this.sequence = config?.sequence || null;
  }
}

export interface TemplateEventModelConfig extends EventModelConfig {
  templateId: string;
  craftName: string;
}

export class TemplateEventModel extends EventModel {
  templateId: string;
  craftName: string;

  constructor(config?: Partial<TemplateEventModelConfig>) {
    super(config);
    this.templateId = config?.templateId || null;
    this.craftName = config?.craftName || '';
  }
}
