import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CategoryModel, RmFileContent, TransferFileFormat } from '@app/api';
import {
  ApiService,
  AppRoutingData,
  AuthenticationService,
  BaseSubscriptionComponent,
  DataHolder,
  GlobalsService,
  ProjectService,
  StructureType,
} from '@app/core';
import { FileType } from '@app/core/enumerations';
import { UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { TreeNode } from 'primeng/api';
import { CategoryDialogComponent } from './category-dialog/category-dialog.component';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { SelectionMode, TreeSelectDialogComponent } from '../dialogs/tree-select-dialog/tree-select-dialog.component';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import * as saveAs from 'file-saver';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
  host: { class: 'c4-prevent-flex-height-overflow' },
  encapsulation: ViewEncapsulation.None,
})
export class CategoriesComponent extends BaseSubscriptionComponent implements OnInit, Busy {
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>;

  icon = AppRoutingData.categories.icon;
  isBusy: boolean;
  isInProject: boolean = false;
  categoriesHolder: DataHolder<TreeNode<CategoryModel>[]>;

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private userNotification: UserNotificationService,
    public globals: GlobalsService
  ) {
    super();
  }

  async ngOnInit() {
    this.categoriesHolder = new DataHolder(this.initData());

    this.subscribe(this.projectService.projectId$, async projectId => {
      this.isInProject = projectId != null;
      await this.updateData();
    });
  }

  async createCategory(parentId: string = null) {
    await this.addOrEditCategory({ parentId });
  }

  async editCategory(categoryId: string) {
    await this.addOrEditCategory({ categoryId });
  }

  async deleteCategory(categoryId: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'categories.deleteConfirmCaption',
        description: 'categories.deleteConfirmDescription',
      },
    });
    const dialogResult = await dialogRef.afterClosed().toPromise();
    if (dialogResult) {
      await using(new BusyScope(this), async busy => {
        await this.apiService.deleteCategory(categoryId);
        this.userNotification.notify('categories.success.delete');
        this.updateData(); // do not await to remove busy overlay (grid has its own)
      }).catch(error => {
        if (error.status == 409) {
          this.userNotification.notify('categories.error.isAssigned', { error: error });
        } else {
          this.userNotification.notify('categories.error.remove', { error: error });
        }
      });
    }
  }

  async export(format: TransferFileFormat = TransferFileFormat.CSV) {
    const fileResponse = await this.apiService.exportRoomBookFromProject(RmFileContent.Categories, format);

    if (CapacitorUtils.isApp()) {
      await CapacitorUtils.blobFileHandler(fileResponse.data, fileResponse.fileName, true);
    } else {
      saveAs(fileResponse.data, decodeURIComponent(fileResponse.fileName));
    }
  }

  async import(file: File) {
    if (file && (file.type === FileType.CSV_EXCEL || file.type == FileType.CSV_TEXT)) {
      let success = false;
      await using(new BusyScope(this), async _ => {
        const data = await file.arrayBuffer();
        const blob = new Blob([data], { type: 'text/csv' });

        await this.apiService.importRoomBookToProject(RmFileContent.Categories, TransferFileFormat.CSV, {
          data: blob,
          fileName: file.name,
        });

        success = true;
        this.userNotification.notify('general.successMsg.import');
      }).catch(e => {
        console.error(e);
        this.userNotification.notify('general.errorMsg.import', { error: e });
      });

      if (success) await this.updateData();
    } else {
      this.userNotification.notify('general.errorFileType');
    }
  }

  async addFromGlobal() {
    let globalCategories: CategoryModel[];
    await using(new BusyScope(this), async _ => {
      globalCategories = await this.apiService.getGlobalCategories(StructureType.tree);
    }).catch(error => {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.categories', error);
    });

    if (!globalCategories) return;

    const data = {
      title: 'roomBook.selectGlobal.categoryTitle',
      description: 'roomBook.selectGlobal.categoryDescription',
      selectionMode: SelectionMode.checkbox,
      canCancel: true,
      items: globalCategories,
    };

    const categories: CategoryModel[] = await this.dialog
      .open(TreeSelectDialogComponent, {
        data: data,
        disableClose: true,
      })
      .afterClosed()
      .toPromise();

    if (categories.length) {
      await using(new BusyScope(this), async _ => {
        await this.apiService.transferCategoriesToProject(categories.map(c => c.id));
        this.userNotification.notify('general.successMsg.transfer');
        this.updateData(); // do not await to remove busy overlay (grid has its own)
      }).catch(e => {
        this.userNotification.notify('general.errorMsg.transfer', { error: e });
      });
    }
  }

  private initData() {
    const fakeCategories: TreeNode[] = [];
    for (let idx = 0; idx < 3; idx++) {
      fakeCategories.push({
        data: new CategoryModel({
          name: 'fakeData',
          number: 1234,
          sequence: 1234,
        }),
        key: idx.toString(),
      });
    }
    return fakeCategories;
  }

  private async updateData() {
    await this.categoriesHolder
      ?.updateData(async () => {
        const categories = await this.apiService.getCategories(StructureType.tree);
        return this.buildTreeRecursive(categories, []);
      }, true)
      .catch(e => {
        this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.categories', e);
      });
  }

  private buildTreeRecursive(categories: CategoryModel[], currentTree: TreeNode<CategoryModel>[]) {
    for (const category of categories) {
      const node: TreeNode<CategoryModel> = {
        data: category,
        key: category.id,
        children: [],
      };

      this.buildTreeRecursive(category.children, node.children);

      currentTree.push(node);
    }

    return currentTree;
  }

  private async addOrEditCategory(data: any) {
    const result = await this.dialog
      .open(CategoryDialogComponent, {
        disableClose: true,
        data,
      })
      .afterClosed()
      .toPromise();

    if (result) await this.updateData();
  }
}
