<mat-form-field class="async-search-form-field">
	<input #input matInput [formControl]="inputControl" [placeholder]="placeholder" [matAutocomplete]="auto"
				 (input)="inputSubject.next($event.target.value)" (blur)="inputBlur($event)"
				 [errorStateMatcher]="errorStateMatcher">
	<mat-error>
		{{ 'general.errorForms.' + error | translate }}
	</mat-error>
	<mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
										(optionSelected)="optionSelected($event.option.value); input.blur();">
		<ng-container *ngIf="!isBusy">
			<mat-option *ngFor="let option of fetchedOptions" [value]="option" class="single-option"
									[class.custom]="optionTemplate != null" (mousedown)="$event.preventDefault()">
				<ng-container *ngIf="optionTemplate; else defaultTemplate" [ngTemplateOutlet]="optionTemplate"
											[ngTemplateOutletContext]="{ item: option }">
				</ng-container>
				<ng-template #defaultTemplate>
					<span>{{ option[displayField] }}</span>
				</ng-template>
			</mat-option>
		</ng-container>
	</mat-autocomplete>
	<mat-progress-bar *ngIf="isBusy" mode="indeterminate" class="form-field-input-loading"
										[color]="isTouched && error ? 'warn' : 'primary'"></mat-progress-bar>
</mat-form-field>