<div class="multi-value-container">
	<div class="multi-value-header">
		<h3>{{ title | translate }}</h3>
		<button *ngIf="!globals.isMobile; else mobileButton" mat-flat-button type="button" (click)="addValue()"
						[disabled]="isDisabled">
			<span class="mdi mdi-plus-box-outline"></span>
			<span class="add-button-caption">{{ 'general.add' | translate }}</span>
		</button>
		<ng-template #mobileButton>
			<button mat-icon-button type="button" (click)="addValue()" [disabled]="isDisabled">
				<span class="mdi mdi-plus-box-outline"></span>
			</button>
		</ng-template>
	</div>
	<div class="multi-value-list">
		<div class="multi-value-field" *ngFor="let value of values; let i = index">
			<mat-form-field>
				<mat-label>{{ fieldName | translate }}</mat-label>
				<input matInput type="text" (input)="valueChanged()" [(ngModel)]="value[valueAccessor]" [disabled]="isDisabled">
			</mat-form-field>
			<button mat-icon-button type="button" (click)="removeValue(i)" [disabled]="isDisabled">
				<span class="mdi mdi-trash-can-outline"></span>
			</button>
		</div>
	</div>
</div>