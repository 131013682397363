import { LeanBryntumSyncModel, LeanBryntumSyncResponseModel, WorkpackageStateProjectEntityState } from '@app/api';
import { SplitPosition } from '@app/shared/components';
import {
  LeanBryntumLoadModel,
  LeanBryntumLoadResponseModel,
} from '@app/shared/components/bryntum-scheduler/bryntum-scheduler.models';
import * as moment from 'moment';

export interface Drag {
  isDragging?: boolean;
}

export interface SchedulerFilter {
  phaseIds: string[];
  text: string;
  craftIds: string[];
  states: WorkpackageStateProjectEntityState[];
  areForeignPhaseWorkpackagesVisible: boolean;
}

export interface SplitConfig {
  main?: SplitPosition;
  side?: SplitPosition;
}

export interface SchedulerState {
  filter?: SchedulerFilter;
  selectedCalenderWeekStart?: moment.Moment;
  arePreviewWeeksActive?: boolean;
  // selectedPlan: any;
  splitConfig?: SplitConfig;
}

export interface Week {
  startOf: moment.Moment;
  endOf: moment.Moment;
  number: number;
}

export interface ISchedulerService {
  loadBryntum: (model: LeanBryntumLoadModel) => Promise<LeanBryntumLoadResponseModel>;
  syncBryntum: (model: LeanBryntumSyncModel) => Promise<LeanBryntumSyncResponseModel>;
}
