/* tslint:disable */
declare global {
    interface Array<T> {
        select<C>(expression: (obj: T) => any): Array<C>;
        each(func: Function): void;
        where(expression: Function): Array<T>;
        count(expression: Function): number;
        any(expression: Function): boolean;
        all(expression: Function): boolean;
        first(expression: Function): T;
        firstOrDefault(expression: Function): T;
        orderBy(expression: Function): Array<T>;
        orderByMultiple(...expressions: Function[]): Array<T>;
        orderByDescending(expression: Function): Array<T>;
        orderByMultipleDescending(...expressions: Function[]): Array<T>;
        skip(amount: number): Array<T>;
        take(amount: number): Array<T>;
        contains(element: T): boolean;
        remove(element: T): void;
        distinct(): Array<T>;
    }
}
export class C4LinqExtensions {

    public static initialized = false;
    public static init() {
        if (C4LinqExtensions.initialized) {
            return;
        }
        Array.prototype.select = function <T>(expression: Function): Array<T> {
            const result = [];
            for (let idx = 0; idx < this.length; idx++) {
                result.push(expression(this[idx]));
            }
            return result;
        };
        Array.prototype.each = function (expression: Function): void {
            for (let idx = 0; idx < this.length; idx++) {
                expression.call(this[idx], this[idx]);
            }
            return this;
        };
        Array.prototype.where = function <T>(expression: Function): Array<T> {
            const collection = this;
            const result = [];
            for (let idx = 0; idx < collection.length; idx++) {
                const item = collection[idx];
                if (expression(item)) {
                    result.push(item);
                }
            }
            return result;
        };
        Array.prototype.count = function (expression: Function): number {
            if (expression) {
                return this.where(expression).count();
            } else {
                return this.length;
            }
        };
        Array.prototype.any = function (expression: Function): boolean {
            const collection = this;
            for (let idx = 0; idx < collection.length; idx++) {
                const item = collection[idx];
                if (!expression || expression(item)) {
                    return true;
                }
            }
            return false;
        };
        Array.prototype.all = function (expression: Function): boolean {
            const collection = this;
            for (let idx = 0; idx < collection.length; idx++) {
                const item = collection[idx];
                if (!expression(item)) {
                    return false;
                }
            }
            return true;
        };
        Array.prototype.first = function <T>(expression: Function): T {
            const result = this.firstOrDefault(expression);
            if (result === null) {
                throw new Error('the array does not contain a matching object');
            }
            return result;
        };
        Array.prototype.firstOrDefault = function <T>(expression: Function): T {
            if (expression) {
                return this.where(expression)[0] || null;
            } else {
                return this.take(1)[0] || null;
            }
        };
        Array.prototype.orderBy = function <T>(expression: Function): Array<T> {
            const compare = function (a, b) {
                if (expression(a) < expression(b)) {
                    return -1;
                }
                if (expression(a) > expression(b)) {
                    return 1;
                }
                return 0;
            };
            return this.sort(compare);
        };
        Array.prototype.orderByMultiple = function <T>(...expressions: Function[]): Array<T> {
            const compare = function (a, b) {
                for (let idx = 0; idx < expressions.length; idx++) {
                    const expr = expressions[idx];
                    if (expr(a) < expr(b)) {
                        return -1;
                    }
                    if (expr(a) > expr(b)) {
                        return 1;
                    }
                }
                return 0;
            };
            return this.sort(compare);
        };
        Array.prototype.orderByDescending = function <T>(expression: Function): Array<T> {
            if (!expression) {
                expression = function (obj) {
                    return obj;
                };
            }
            const compare = function (a, b) {
                if (expression(a) < expression(b)) {
                    return 1;
                }
                if (expression(a) > expression(b)) {
                    return -1;
                }
                return 0;
            };
            return this.sort(compare);
        };
        Array.prototype.orderByMultipleDescending = function <T>(...expressions: Function[]): Array<T> {
            const compare = function (a, b) {
                for (let idx = 0; idx < expressions.length; idx++) {
                    const expr = expressions[idx];
                    if (expr(a) < expr(b)) {
                        return 1;
                    }
                    if (expr(a) > expr(b)) {
                        return -1;
                    }
                }
                return 0;
            };
            return this.sort(compare);
        };
        Array.prototype.skip = function <T>(amount: number): Array<T> {
            return this.slice(amount, this.length);
        };
        Array.prototype.take = function <T>(amount: number): Array<T> {
            return this.slice(0, amount);
        };
        Array.prototype.contains = function <T>(element: T): boolean {
            const collection = this;
            return collection.indexOf(element) >= 0;
        };
        Array.prototype.remove = function <T>(element: T): void {
            const index = this.indexOf(element);
            if (index > -1) {
                this.splice(index, 1);
            }
        };
        Array.prototype.distinct = function <T>(): Array<T> {
            //https://codeburst.io/javascript-array-distinct-5edc93501dc4
            const distinctFilter = (value, index, self) => {
                return self.indexOf(value) === index;
            };
            return this.filter(distinctFilter);
        };
        C4LinqExtensions.initialized = true;
    }
}
