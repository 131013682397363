import { delay } from '../functions';
import { Busy } from './busy';
import { Disposable } from './disposable';

export class BusyScope implements Disposable {
  busy: Busy;
  startTime: Date;
  endTime: Date;

  constructor(busy: Busy, private minDuration: number = 200) {
    this.busy = busy;
    this.busy.isBusy = true;
    this.startTime = new Date();
  }

  async dispose() {
    this.endTime = new Date();

    var waitDelay = this.minDuration - (this.endTime.getTime() - this.startTime.getTime());
    if (waitDelay > 0) await delay(waitDelay);

    this.busy.isBusy = false;
  }
}
