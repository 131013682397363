import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Utils } from '@app/core';

@Component({
  selector: 'app-enter-view-name-dialog',
  templateUrl: './enter-view-name-dialog.component.html',
  styleUrls: ['./enter-view-name-dialog.component.scss']
})
export class EnterViewNameDialogComponent implements OnInit {
  viewName: string = '';
  isEditModeActive: boolean;
  viewId: string = '';
  originalViewName: string;
  captionTranslationKey: string = 'dialogs.newView.caption';

  constructor(public confirmRef: MatDialogRef<EnterViewNameDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    if (data) {
      this.viewId = (data.tab && data.tab.id) ? data.tab.id : '';
      if (data.tab) {
        this.viewName = data.tab.label ? data.tab.label : '';
        this.originalViewName = this.viewName;
      }
      this.isEditModeActive = data.edit;
      if (this.isEditModeActive){
        this.captionTranslationKey = 'dialogs.newView.editCaption';
      }        
    }
  }

  ngOnInit() { }

  ok(del: boolean) {
    this.confirmRef.close({ viewName: this.viewName, viewId: this.viewId, delete: del } as ViewNameResult);
  }

  cancel() {
    this.confirmRef.close();
  }

  get viewNameValid() {
    return !Utils.isNullOrWhitespace(this.viewName);
  }
  get originalViewNameValid() {
    return !Utils.isNullOrWhitespace(this.originalViewName);
  }
}

export interface ViewNameResult {
  viewName: string;
  viewId: string;
  delete: boolean;
}