import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import {
  AuthUserClient,
  LoginModel,
  ExternalLoginModel,
  SwaggerException,
  RefreshTokenModel,
  RequestSecretTokenModel,
  LoginResultModel,
  RequestPasswordResetModel,
  PasswordResetTokenDataModel,
  RedeemPasswordResetTokenModel,
  SessionClient,
} from '@app/api';
import { LoginError } from './login-error';
import { LogService } from '../log/log.service';
import { LoginData, RequestOtpData } from '.';
import { MsalService } from '@azure/msal-angular';
import { LocalStorageService, ProjectService } from '../globals';
import { AuthenticationResult, BrowserAuthError, BrowserAuthErrorCodes } from '@azure/msal-browser';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import { AppConfigService } from '../app-config';

export const RETURN_URL_KEY = 'returnUrl';

@Injectable({
  providedIn: 'root',
})
export class ApiAuthenticationService {
  private tenantId: string;
  private resolveMsalRedirect: (result: LoginResultModel) => void;

  constructor(
    private authenticationClient: AuthUserClient,
    private authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService,
    private log: LogService,
    private msalAuthService: MsalService,
    private sessionClient: SessionClient,
    projectService: ProjectService
  ) {
    projectService.tenant$.subscribe(tenant => (this.tenantId = tenant?.publicId));
  }

  private get msalInstance() {
    return this.msalAuthService.instance;
  }

  async initMSLogin() {
    await this.msalInstance.initialize();
  }

  async handleMsalRedirect(hash?: string) {
    const msalResult = await this.msalInstance.handleRedirectPromise(hash);

    if (this.resolveMsalRedirect) {
      const loginResult = await this.handleMsalResult(msalResult);
      this.resolveMsalRedirect(loginResult);
    }
  }

  async login(loginData: LoginData, projectId: string): Promise<LoginResultModel> {
    let loginResultModel: LoginResultModel;
    try {
      //possibilites:
      //-) only primary secret (password)
      //-) only primary secret (one time token)
      //-) primary + secondary (password + one time token)
      loginResultModel = await this.authenticationClient
        .login(
          new LoginModel({
            userName: loginData.userName,
            primarySecret: loginData.primarySecret,
            secondarySecret: loginData.secondarySecret,
            projectId: projectId,
          })
        )
        .toPromise();
    } catch (e) {
      if (SwaggerException.isSwaggerException(e)) {
        e = e as SwaggerException;
        this.log.error(`Login failed: ${e.status}: ${e.message}`, e);
        switch (e.status) {
          case 401:
            throw LoginError.WrongUserOrPassword;
          case 423:
            throw LoginError.TenantConsent;
        }
      } else {
        this.log.error('Login failed', e);
      }
      throw LoginError.Generic;
    }

    await this.authenticationService.loginWithToken(
      loginResultModel.accessToken,
      loginResultModel.refreshToken,
      loginResultModel.enforcePasswordChange,
      loginResultModel.availableProjects,
      loginResultModel.enforceUserDataChange
    );

    return loginResultModel;
  }

  async loginWithAccessToken(accessToken: string, groupId: string = '') {
    let loginResultModel: LoginResultModel;
    try {
      loginResultModel = await this.authenticationClient
        .loginWithAccessToken(
          new ExternalLoginModel({
            accessToken,
            groupId,
          })
        )
        .toPromise();
    } catch (e) {
      if (SwaggerException.isSwaggerException(e)) {
        e = e as SwaggerException;
        this.log.error(`Login failed: ${e.status}: ${e.message}`, e);
        if (e.status === 423) {
          throw LoginError.TenantConsent;
        }
        if (e.status === 401) {
          throw LoginError.Generic;
        }
      } else {
        this.log.error('Login failed', e);
      }
      throw LoginError.Generic;
    }

    await this.authenticationService.loginWithToken(
      loginResultModel.accessToken,
      loginResultModel.refreshToken,
      loginResultModel.enforcePasswordChange,
      loginResultModel.availableProjects,
      loginResultModel.enforceUserDataChange
    );

    return loginResultModel;
  }

  async loginAsMS(returnUrl: string): Promise<LoginResultModel> {
    if (!this.tenantId) throw 'Tenant ID not set!';

    try {
      //Use the currently logged in user as the default user
      let activeAccount = this.msalInstance.getAllAccounts()[0];

      if (activeAccount) {
        this.msalInstance.setActiveAccount(activeAccount);
      }

      const msalResult = await this.msalInstance.acquireTokenSilent({
        authority: `https://login.microsoftonline.com/${this.tenantId}`,
        scopes: AppConfigService.settings.msal.consentScopes,
      });

      return await this.handleMsalResult(msalResult);
    } catch ($err) {
      return await this.handleMsalSilentError(returnUrl);
    }
  }

  async requestLoginOtp(requestOtpData: RequestOtpData) {
    try {
      await this.authenticationClient
        .requestSecretToken(
          new RequestSecretTokenModel({
            userName: requestOtpData.userName,
            primarySecret: requestOtpData.primarySecret,
          })
        )
        .toPromise();
    } catch (e) {
      if (SwaggerException.isSwaggerException(e)) {
        e = e as SwaggerException;
        this.log.error(`Request OTP failed: ${e.status}: ${e.message}`, e);
        if (e.status === 401) {
          throw LoginError.WrongUserOrPassword;
        }
      } else {
        this.log.error('Request OTP failed', e);
      }
      throw LoginError.GenericOtp;
    }
  }

  async requestPasswordResetToken(userName: string) {
    await this.authenticationClient
      .requestPasswordResetToken(
        new RequestPasswordResetModel({
          userName,
        })
      )
      .toPromise();
  }

  async getPasswordResetTokenData(passwordResetToken: string): Promise<PasswordResetTokenDataModel> {
    return this.authenticationClient.getPasswordResetTokenData(passwordResetToken).toPromise();
  }

  async redeemPasswordResetToken(passwordResetToken: string, password: string) {
    return this.authenticationClient
      .redeemPasswordResetToken(
        new RedeemPasswordResetTokenModel({
          password,
          passwordResetToken,
        })
      )
      .toPromise();
  }

  async logout(refreshToken: string) {
    await this.sessionClient.logout(refreshToken).toPromise();

    await this.msalInstance.initialize();

    const isMsalLoggedIn = !!this.msalInstance.getActiveAccount();
    if (isMsalLoggedIn) {
      await this.msalInstance.logoutRedirect(); //logged in via MS-Account
    }
  }

  async refreshToken(oldToken: string, refreshToken: string) {
    try {
      const newTokenResult = await this.authenticationClient
        .refresh(
          new RefreshTokenModel({
            accessToken: oldToken,
            refreshToken: refreshToken,
          })
        )
        .toPromise();
      return newTokenResult;
    } catch (e) {
      this.log.error('Token refresh failed');
      return null;
    }
  }

  private async handleMsalSilentError(returnUrl: string) {
    // prompt user for account as silent failed
    let userAgent = window.navigator.userAgent;

    const useRedirect =
      userAgent.indexOf('MSIE ') > -1 ||
      userAgent.indexOf('Trident/') > -1 ||
      !!navigator.vendor.match(/apple/i) ||
      CapacitorUtils.isApp();

    let loginRequest = {
      authority: `https://login.microsoftonline.com/${this.tenantId}`,
      scopes: AppConfigService.settings.msal.consentScopes, // Scope is ignored by initial msal config - thanks microsoft
      prompt: 'select_account',
    };

    await this.localStorageService.setItem(RETURN_URL_KEY, returnUrl);

    if (useRedirect) {
      try {
        await this.msalInstance.loginRedirect(loginRequest);
        return new Promise<LoginResultModel>(resolve => {
          this.resolveMsalRedirect = resolve;
        });
      } catch (error) {
        return await this.handleMsalAuthError(error, returnUrl);
      }
    } else {
      try {
        const msalResult = await this.msalInstance.loginPopup(loginRequest);
        return await this.handleMsalResult(msalResult);
      } catch (error) {
        return await this.handleMsalAuthError(error, returnUrl);
      }
    }
  }

  private async handleMsalResult(msalResult: AuthenticationResult) {
    if (msalResult) {
      this.msalInstance.setActiveAccount(msalResult.account);
      return await this.loginWithAccessToken(msalResult.idToken);
    }
  }

  private async handleMsalAuthError(error: BrowserAuthError, returnUrl: string) {
    if (error.errorCode == BrowserAuthErrorCodes.interactionInProgress) {
      sessionStorage.removeItem('msal.interaction.status');
      return await this.loginAsMS(returnUrl);
    } else {
      this.log.error('failed to login in via ms', error);
    }
  }
}
