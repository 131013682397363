<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
<div class="c4-dialog-wrapper">
	<h1 mat-dialog-title>{{ 'workpackages.addDependency' | translate }}</h1>
	<mat-dialog-content class="add-dependency-content" [formGroup]="form">
		<!-- <mat-form-field>
			<mat-label>{{ 'workpackages.dependencyType.title' | translate }}</mat-label>
			<mat-select formControlName="dependencyType">
				<mat-option *ngFor="let type of dependencyTypes" [value]="type.key">
					{{ type.value | translate }}
				</mat-option>
			</mat-select>
		</mat-form-field> -->

		<app-search-select *ngIf="f.dependencyType.value == DependencyType.predecessor || f.dependencyType.value == DependencyType.successor"
											 [selectList]="workpackages" [selectFormGroup]="form" selectFormControlName="dependency"
											 title="workpackages.element" [displayFields]="['title']" [searchFields]="['number', 'title']"
											 [optionTemplate]="dependencyOptionTemplate"
											 [error]="f.dependency.invalid ? 'general.errorForms.' + getError('dependency') : null">
		</app-search-select>

		<ng-template #dependencyOptionTemplate let-item="item">
			<span class="c4-select-option">
				<span>{{item?.title}}&nbsp;({{ item?.number }})</span>
			</span>
		</ng-template>

		<app-tree-selection *ngIf="f.dependencyType.value == DependencyType.foreignSwimlane" class="slim-tree-select"
												[title]="'scheduler.swimlane'" formControlName="dependency" [canSelectLeafsOnly]="true"
												childrenSelector="swimlanes" [models]="phasesWithSwimlanes"
												[errors]="f.dependency.touched ? f.dependency.errors : null">
		</app-tree-selection>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
		<button mat-flat-button color="primary" (click)="confirm()" type="button" [disabled]="!form.valid">
			{{ 'general.ok' | translate }}
		</button>
	</mat-dialog-actions>
</div>