import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ModuleType } from '@app/api';

@Component({
  selector: 'app-plan-delete-dialog',
  templateUrl: './plan-delete-dialog.component.html',
  styleUrls: ['./plan-delete-dialog.component.scss'],
})
export class PlanDeleteDialogComponent implements OnInit {
  title = 'planning.dialogs.planDelete.title';
  name: string;
  id: string;
  deleteAll: string = 'current';
  map: any[];

  constructor(public confirmRef: MatDialogRef<PlanDeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.name = data.doc.name ? data.doc.name : 'n/a';
    this.id = data.doc.id ? data.doc.id : '';
    this.map = data.map.files ? data.map.files : [];
    if (data.module == ModuleType.Bim) this.title = 'ifc.dialogs.delete.title';
  }

  ngOnInit() {}

  ok(id: string) {
    const result = this.deleteAll === 'all' ? this.map : [{ id: id, name: this.name }];
    this.confirmRef.close(result);
  }

  cancel() {
    this.confirmRef.close(false);
  }
}
