import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { IssueModel, IssueState, IssueStateProjectEntityState } from '@app/api';
import { ApiService, AppRoutingData, DataHolder, GlobalsService, nameof, pathFragmentsTo, ProjectService } from '@app/core';
import { LocalizedDatePipe } from '@app/shared/pipes';
import { Busy } from '@app/shared/utils/busy';
import { TranslateService } from '@ngx-translate/core';
import { C4GridDef, C4GridFilterType, C4GridMatchMode, C4GridSelectOptions, GridSelectionMode } from '../grid';

export enum IssueListMode {
  small = 'small',
  singleSelectIntegrated = 'singleSelectIntegrated',
}

@Component({
  selector: 'app-issue-list',
  templateUrl: './issue-list.component.html',
  styleUrls: ['./issue-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class IssueListComponent implements OnInit, Busy {
  @ViewChild('nameCol', { static: true }) nameCol: TemplateRef<any>;
  @ViewChild('state', { static: true }) stateTemplate: TemplateRef<any>;
  @ViewChild('overTime', { static: true }) overTime: TemplateRef<any>;
  @ViewChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;

  @Input() rows: number = 10;
  @Input() rowsOptions: number[] = [5, 10, 20, 50, 100];
  @Input() mode: IssueListMode = IssueListMode.small;

  @Input() set issues(value: IssueModel[]) {
    this.allIssues = value;
    if (this.isInitialized) this.updateData();
  }

  private _selection: any;
  get selection(): any {
    return this._selection;
  }
  @Input() set selection(value: any) {
    this._selection = value;
    this.selectionChange.emit(value);
  }
  @Output() selectionChange = new EventEmitter<any>();

  @Output() editItemEvent = new EventEmitter<string>();

  isBusy: boolean;
  issuesHolder: DataHolder<IssueModel[]>;
  gridDef: C4GridDef;
  selectionMode: GridSelectionMode = GridSelectionMode.none;

  private allIssues: IssueModel[];
  private today: Date;
  private isInitialized: boolean = false;
  private issueStates: C4GridSelectOptions[] = [];

  constructor(
    private globals: GlobalsService,
    private datePipe: LocalizedDatePipe,
    private projectService: ProjectService,
    private router: Router,
    private translate: TranslateService
  ) {
    this.today = new Date();
  }

  async ngOnInit() {
    this.selectionMode = this.mode == IssueListMode.singleSelectIntegrated ? GridSelectionMode.single : GridSelectionMode.none;
    this.issuesHolder = new DataHolder(this.initData());
    this.gridDef = this.getGridDef();
    if (this.allIssues) this.updateData();
    this.isInitialized = true;
  }

  editItem(id: string) {
    this.router.navigate(pathFragmentsTo(this.projectService.projectId, AppRoutingData.bim.path), {
      queryParams: { edit: true, id: id },
    });
  }

  getColorClass(colDate: Date, issue: IssueModel) {
    if (issue.stateType === IssueState.Open && colDate && colDate instanceof Date) {
      return colDate < this.today ? 'over-time' : 'in-time';
    } else {
      return 'no-time';
    }
  }

  stripHtml(input: string) {
    if (input) return input.replace(/(<([^>]+)>)/gi, '');

    return input;
  }

  public async updateData() {
    await this.issuesHolder?.updateData(async () => {
      const issueStates = this.allIssues.map(issue => ({ label: issue.currentState.title, value: issue.currentState }));
      this.issueStates.splice(0, this.issueStates.length, ...issueStates);
      this.gridDef.grid.rowCount = this.allIssues?.length ?? 0;
      // this.allIssues.forEach(i => {
      //   i.rowClass=r.state===this.states.Deleted?'fade-row':''
      // });
      return this.allIssues;
    }, true);
  }

  private initData(count: number = 5) {
    const issues: IssueModel[] = [];

    for (let i = 0; i < count; i++) {
      issues.push(
        new IssueModel({
          number: i,
          title: 'fakeData',
          stateType: IssueState.Open,
          currentState: new IssueStateProjectEntityState({
            title: 'fakeData',
          }),
          createdOn: new Date(),
          deadline: new Date(),
        })
      );
    }

    return issues;
  }

  private getGridDef(): C4GridDef {
    const def: C4GridDef = {
      initialSorting: [
        {
          field: 'number',
          order: 1,
        },
      ],
      grid: {
        filterRow: true,
        lazy: false,
        lazyInit: false,
        paging: true,
        responsive: true,
        scrollable: true,
        rowExpand: true,
        rows: this.rows,
        rowsOptions: this.rowsOptions,
      },
      row: {
        link: false,
      },
      cols: [
        {
          field: nameof<IssueModel>('number'),
          header: 'issues.number',
          width: '5em',
          minWidth: '5em',
          priority: 3,
          cssClass: 'right-aligned',
          sortable: true,
          filterType: C4GridFilterType.text,
          filterMatchMode: C4GridMatchMode.contains,
        },
        {
          field: nameof<IssueModel>('title'),
          header: 'issues.title',
          width: '1*',
          minWidth: '10em',
          priority: 1,
          sortable: true,
          filterType: C4GridFilterType.text,
          filterMatchMode: C4GridMatchMode.contains,
          template: this.mode != IssueListMode.singleSelectIntegrated ? this.nameCol : null,
        },
        {
          field: nameof<IssueModel>('currentState'),
          header: 'issues.state',
          width: '10em',
          minWidth: '10em',
          priority: 1,
          sortable: true,
          options: this.issueStates,
          filterType: C4GridFilterType.multiselect,
          filterMatchMode: C4GridMatchMode.equals,
          template: this.stateTemplate,
        },
        {
          field: nameof<IssueModel>('createdOn'),
          header: 'issues.createdOn',
          width: '12em',
          minWidth: '12em',
          priority: 3,
          sortable: true,
          pipe: this.datePipe,
        },
        {
          field: nameof<IssueModel>('deadline'),
          header: 'issues.deadline',
          width: '10em',
          minWidth: '10em',
          priority: 3,
          sortable: true,
          template: this.overTime,
        },
      ],
    };

    if (this.mode === IssueListMode.small) {
      def.cols.push({
        field: '',
        header: 'issues.actions',
        width: '4.2em',
        minWidth: '4.2em',
        cssClass: 'action-flex-grid',
        priority: 1,
        sortable: false,
        template: this.actionsTemplate,
      });
    }

    return def;
  }
}
