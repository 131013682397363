import { inject, InjectionToken } from '@angular/core';
import { CanActivateFn, CanDeactivateFn } from '@angular/router';
import { ModuleType } from '@app/api';
import { MODULE_TYPE$ } from '../services';

export const MODULE_TYPE = new InjectionToken<ModuleType>('MODULE_TYPE');

export const setModuleType: CanActivateFn = () => {
  publishModuleType(inject(MODULE_TYPE, { optional: true }));
  return true;
};

export const resetModuleType: CanDeactivateFn<unknown> = () => {
  publishModuleType(null);
  return true;
};

function publishModuleType(moduleType: ModuleType) {
  const moduleType$ = inject(MODULE_TYPE$);
  moduleType$.next(moduleType);
}
