import { Component, OnInit } from '@angular/core';
import { UntypedFormArray } from '@angular/forms';
import { PrivilegeEnumGroupData } from '@app/api';
import { FormUtils, GlobalsService } from '@app/core';
import { PcfGeneral, ProjectConfigService, PcfRole, PcfRoles } from '@app/shared/services/project-config';

@Component({
  selector: 'app-security-config',
  templateUrl: './security-config.component.html',
  styleUrls: ['./security-config.component.scss'],
})
export class SecurityConfigComponent implements OnInit {
  gridTemplateColumnsStyle: string;

  constructor(private configService: ProjectConfigService, public globals: GlobalsService) {}

  ngOnInit() {
    this.setGridStyle(this.roles.length);
    FormUtils.getFormControl<PcfRoles>(this.configService.rolesForm, 'roles').valueChanges.subscribe((roles: PcfRole[]) => {
      this.setGridStyle(roles.length);
    });
  }

  get roles() {
    const rolesArray = FormUtils.getFormControl<PcfRoles, UntypedFormArray>(this.configService.rolesForm, 'roles');
    return rolesArray.controls;
  }

  get functionalPrivilegeGroups() {
    return this.configService.functionalPrivilegeGroups;
  }

  get roleAndUserData() {
    return this.configService.roleAndUserData;
  }

  isPrivilegeGroupEnabled(groupType: PrivilegeEnumGroupData) {
    switch (groupType) {
      case PrivilegeEnumGroupData.Bim:
        return FormUtils.getFormValue<PcfGeneral>(this.configService.generalForm, 'isBimModuleEnabled');
      case PrivilegeEnumGroupData.ConstructionDiary:
        return FormUtils.getFormValue<PcfGeneral>(this.configService.generalForm, 'isDiaryModuleEnabled');
      case PrivilegeEnumGroupData.Defect:
        return FormUtils.getFormValue<PcfGeneral>(this.configService.generalForm, 'isDefectModuleEnabled');
      case PrivilegeEnumGroupData.Gallery:
        return FormUtils.getFormValue<PcfGeneral>(this.configService.generalForm, 'isGalleryModuleEnabled');
      case PrivilegeEnumGroupData.Lean:
        return FormUtils.getFormValue<PcfGeneral>(this.configService.generalForm, 'isLeanModuleEnabled');
      case PrivilegeEnumGroupData.Plan:
        return FormUtils.getFormValue<PcfGeneral>(this.configService.generalForm, 'isPlanModuleEnabled');
      case PrivilegeEnumGroupData.RoomBook:
        return FormUtils.getFormValue<PcfGeneral>(this.configService.generalForm, 'isRoomBookModuleEnabled');
      default:
        return true; // Portal / Team
    }
  }

  private setGridStyle(rolesCount: number) {
    const start = this.globals.isPhone ? '16rem' : '36rem';
    this.gridTemplateColumnsStyle = `${start} repeat(${rolesCount}, 1fr) auto`;
  }
}
