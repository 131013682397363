import { Component, Input, OnInit } from '@angular/core';
import { ChangeModel, ChangeSetModel } from '@app/api';
import { GlobalsService } from '@app/core/services/globals/globals.service';
import { TranslateService } from '@ngx-translate/core';
import { HistoryChangeType, HistoryComponent } from '../../../history/history.component';

const boolFieldNames: string[] = ['isindividual', 'attributevaluecheck'];

const attributeChange: string = 'attribute';
const roomTemplateChange: string = 'roomtemplatechange';

@Component({
  selector: 'app-room-edit-history',
  templateUrl: './room-edit-history.component.html',
  styleUrls: ['./room-edit-history.component.scss'],
})
export class RoomEditHistoryComponent implements OnInit {
  @Input() set changeSets(changeSets: ChangeSetModel[]) {
    this.processChangeSets(changeSets);
  }

  private _changeSets: ChangeSetModel[] = [];
  private colonSplit = /:(.+)/; // split once at first colon

  constructor(public globals: GlobalsService, private translate: TranslateService) {}

  get changeSets(): ChangeSetModel[] {
    return this._changeSets;
  }

  ngOnInit(): void {}

  private processChangeSets(changeSets: ChangeSetModel[]) {
    this._changeSets = [];

    if (!changeSets?.length) return;

    for (const changeSet of changeSets.reverse()) {
      const changeSetCopy = new ChangeSetModel({ ...changeSet });
      changeSetCopy.changes = [];
      for (const c of changeSet.changes) {
        const change = new ChangeModel({ ...c });
        // check if it is a RoomTemplateChange
        const split = change.fieldName.split(this.colonSplit);
        const isRoomTemplateChange = split[0].toLowerCase() == roomTemplateChange; // eg. RoomTemplateChange:Attribute:AttName, RoomTemplateChange:InternalNumber
        const roomTemplateChangeFieldName = isRoomTemplateChange ? split[1] : change.fieldName;
        // get first half of split (attribute:value) or value itself
        const attributeIdentifier = roomTemplateChangeFieldName.toLowerCase().startsWith(attributeChange)
          ? roomTemplateChangeFieldName.split(this.colonSplit)[0].toLowerCase()
          : null;
        const fieldName =
          attributeIdentifier != null
            ? roomTemplateChangeFieldName.split(this.colonSplit)[1]
            : roomTemplateChangeFieldName.toLowerCase();

        // custom format
        if (boolFieldNames.some(f => f === fieldName || f === attributeIdentifier)) {
          HistoryComponent.setType(change, HistoryChangeType.bool);
        } else if (attributeIdentifier == 'attributetype') {
          change.oldValue = change.oldValue ? 'roomBook.attributes.type.' + change.oldValue : null;
          change.newValue = change.newValue ? 'roomBook.attributes.type.' + change.newValue : null;
          HistoryComponent.setType(change, HistoryChangeType.translate);
        } else if (attributeIdentifier == attributeChange) {
          change.oldValue = change.oldValue ? 'general.removed' : null;
          change.newValue = change.newValue ? 'general.added' : null;
          HistoryComponent.setType(change, HistoryChangeType.translate);
        }

        change.fieldName =
          attributeIdentifier != null
            ? this.translate.instant('roomBook.attributeWithName', { name: fieldName })
            : 'roomBook.fieldName.' + fieldName;

        if (isRoomTemplateChange) {
          HistoryComponent.setAdditionalInfo(change, 'roomBook.roomTemplateChange');
        }

        changeSetCopy.changes.push(change);
      }
      this._changeSets.push(changeSetCopy);
    }
  }
}
