import { Component, Input, OnInit } from '@angular/core';
import { LeanWorkpackageTemplateModel } from '@app/api';

@Component({
  selector: 'app-workpackage-sequence',
  templateUrl: './workpackage-sequence.component.html',
  styleUrls: ['./workpackage-sequence.component.scss'],
})
export class WorkpackageSequenceComponent implements OnInit {
  @Input() title: string;
  @Input() workpackageTemplates: LeanWorkpackageTemplateModel[] = [];

  constructor() {}

  ngOnInit(): void {}
}
