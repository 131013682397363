<div class="c4-dialog-wrapper copy-info-dialog">
	<h1 mat-dialog-title>{{ title | translate }}</h1>
	<mat-dialog-content>
		<div *ngIf="description">{{ description | translate }}</div>
		<div class="row">
			<div class="copyable-string">{{ copyableString }}</div>
			<button mat-button class="copy-button" [disabled]="!copyableString" (click)="notifyUser()"
							[cdkCopyToClipboard]="copyableString" [matTooltip]="'dialogs.zipDownloadUrl.copyToClipboard' | translate">
				<span>{{ 'dialogs.zipDownloadUrl.copy' | translate }}</span>
				<span class="mdi mdi-content-copy"></span>
			</button>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="close()" type="button">{{ 'general.close' | translate }}</button>
	</mat-dialog-actions>
</div>
<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:false }"></ngx-loading>