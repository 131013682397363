import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Utils } from '@app/core';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-custom-searchbar',
  templateUrl: './custom-searchbar.component.html',
  styleUrls: ['./custom-searchbar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CustomSearchbarComponent,
    },
  ],
})
export class CustomSearchbarComponent implements ControlValueAccessor, OnDestroy, OnInit {
  @ViewChild('searchField', { static: true }) input: ElementRef<HTMLInputElement>;

  @Input() placeholder: string = 'general.placeholder.search';
  @Input() useGlobalSearch: boolean = false; // controls wheter or not the input listens to ctrl + F keydown

  filterControl = new FormControl('');

  private destroyed$ = new Subject<void>();

  constructor() {
    this.filterControl.valueChanges.pipe(debounceTime(300)).subscribe(value => {
      this.onChange(value);
    });
  }

  ngOnInit() {
    Utils.addDocListener(document, 'keydown', this.subscribeToGlobalSearch.bind(this), this.destroyed$);
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  private subscribeToGlobalSearch(event) {
    if (this.useGlobalSearch && event.ctrlKey && event.key === 'f') {
      event.preventDefault();
      this.input.nativeElement.focus();
    }
  }

  // ----- Reactive Forms Methods -----

  private isTouched: boolean = false;
  onChange: Func<string> = (_: string) => {};
  private onTouched: EmptyCallback = () => {};

  writeValue(value: string): void {
    this.filterControl.setValue(value, { emitEvent: false });
  }

  registerOnChange(fn: Func<string>): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: EmptyCallback): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.filterControl.disable();
    } else {
      this.filterControl.enable();
    }
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
  }
}
