import { EventEmitter } from '@angular/core';
import * as L from 'leaflet';
import { setupFeatureInformations } from './setupFeatureInformations';
import { ShapeTypes } from './interfaces';
import { ArrowheadOptions } from 'leaflet-arrowheads';

export const ARROW_HEAD_OPTIONS: ArrowheadOptions = { size: '20px', frequency: 'endonly', fill: true };

export class LeafletArrow {
  private context: L.corner4.MappingType | null = null;
  private polyline: L.Polyline | null = null;
  private color: string | null = null;

  constructor(private map: L.Map) {}

  onchange = new EventEmitter<L.Layer>();

  get drawing() {
    return this.context != null;
  }

  start(context: L.corner4.MappingType, color: string | null) {
    this.context = context;
    this.color = color;
    this.map.on('click', this.click, this);
    this.map.on('mousemove', this.mousemove, this);
  }

  complete() {
    this.polyline?.remove();
    this.polyline = null;

    this.map.dragging.enable();

    if (this.context) {
      this.map.off('click', this.click, this);
      this.map.off('mousemove', this.mousemove, this);
    }

    this.context = null;
  }

  cancel() {
    this.complete();
  }

  private click(e: L.LeafletMouseEvent) {
    L.DomEvent.stopPropagation(e);
    if (this.polyline != null) {
      this.completeDraw(e.latlng);
    } else {
      this.map.dragging.disable();
      this.startDraw(e.latlng);
    }
  }

  private mousemove(e: L.LeafletMouseEvent) {
    L.DomEvent.stopPropagation(e);
    this.advanceDraw(e.latlng);
  }

  private startDraw(latlng: L.LatLngExpression) {
    this.polyline = this.createPolyline(latlng, latlng, false);
  }

  private advanceDraw(latlng: L.LatLngExpression) {
    if (this.polyline) {
      const [from] = this.polyline.getLatLngs() as L.LatLng[];
      this.polyline.setLatLngs([from, latlng]);
    }
  }

  private completeDraw(latlng: L.LatLngExpression) {
    const [from] = this.polyline.getLatLngs() as L.LatLng[];
    const created = this.createPolyline(from, latlng);
    this.complete();
    this.onchange.emit(created);
  }

  private createPolyline(from: any, to: any, interactive: boolean = true) {
    const color = (this.context.color || this.color) as string;
    const line = L.polyline([from, to], { interactive, snapIgnore: true, color })
      .arrowheads(ARROW_HEAD_OPTIONS)
      .addTo(this.map);

    setupFeatureInformations(line, ShapeTypes.arrow, color, this.context);

    return line;
  }
}
