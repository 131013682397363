<div class="c4-dialog-wrapper">
  <h1 mat-dialog-title>{{ captionTranslationKey | translate }}</h1>
  <mat-dialog-content>
    <div>
      <mat-form-field>
        <mat-label>{{ 'dialogs.newView.name' |translate }}</mat-label>
        <input matInput [(ngModel)]="viewName" [placeholder]="'dialogs.newView.namePlaceholder' | translate">
      </mat-form-field>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
    <button *ngIf="originalViewNameValid && isEditModeActive" mat-stroked-button (click)="ok(true)" type="button">{{
      'general.delete' | translate }}</button>
    <button mat-flat-button color="primary" (click)="ok(false)" type="button" [disabled]="!viewNameValid">{{
      'general.ok' | translate }}</button>
  </mat-dialog-actions>
</div>