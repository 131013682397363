<ng-container *ngIf="!globals.isPhone">
  <div *ngIf="form" class="tasklike-body room">
    <div class="tasklike-edit-container">
      <form [formGroup]="form">
        <div class="tasklike-edit-header">
          <div class="header-row">
            <div class="header-row-title">
              <ng-container *ngIf="!isTemplate">
                <span *ngIf="!isNew" class="tasklike-header-id">{{ model.number }}</span>
                <span *ngIf="isNew" class="tasklike-header-id hide">2</span>
              </ng-container>
              <div class="tasklike-form-row">
                <div class="tasklike-input-wrapper">
                  <input *ngIf="!isNew" class="tasklike-input" [class.error]="f.name.errors" formControlName="name"
                         [placeholder]="'roomBook.fieldName.name' + (!isTemplate ? '' : 'Template') | translate">
                  <input *ngIf="isNew" class="tasklike-input" [class.error]="f.name.errors" formControlName="name"
                         [placeholder]="'roomBook.fieldName.name' + (!isTemplate ? '' : 'Template') | translate"
                         appSetFocus>
                  <div class="tasklike-form-hilight"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="header-row">
            <div class="header-row-subtitle">
              <div *ngIf="!isTemplate" class="room-template" [title]="'roomBook.fieldName.roomtemplate' | translate">
                <span class="ico prefix mdi" [class]="roomTemplateIcon"></span>
                <app-search-select class="select required"
                                   [class.isValid]="!f.roomTemplate.errors || f.roomTemplate.untouched"
                                   [selectList]="roomTemplates" [selectFormGroup]="form"
                                   selectFormControlName="roomTemplate" [addSelectNone]="true">
                </app-search-select>
              </div>
              <mat-checkbox formControlName="isTagged">
                {{ 'roomBook.fieldName.' + (!isTemplate ? 'isindividual' : 'isdefault') | translate }}
              </mat-checkbox>
            </div>
          </div>
        </div>


        <div class="tasklike-top-container">
          <div class="tasklike-top-info">
            <div class="top-info-col pad">
              <div class="top-info first">
                <span class="prefix-label">{{ 'roomBook.fieldName.internalnumber' | translate }}</span>
                <div class="select" [class.isValid]="!f.internalNumber.errors || f.internalNumber.untouched">
                  <mat-form-field>
                    <input matInput formControlName="internalNumber">
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="!isTemplate" class="top-info first">
                <span class="prefix-label">{{ 'roomBook.area.title' | translate }}</span>
                <app-search-select class="select required" [class.isValid]="!f.area.errors || f.area.untouched"
                                   [selectList]="areas" [selectFormGroup]="form" selectFormControlName="area"
                                   [addSelectNone]="true">
                </app-search-select>
              </div>
            </div>

            <ng-container *ngIf="!isTemplate">
              <div class="top-info-col">
                <div class="top-info">
                  <span class="prefix-label">{{ 'roomBook.floor.title' | translate }}</span>
                  <app-search-select class="select" [class.isValid]="!f.floor.errors || f.floor.untouched"
                                     [selectList]="floors" [selectFormGroup]="form" selectFormControlName="floor"
                                     [addSelectNone]="true">
                  </app-search-select>
                </div>
                <div class="top-info first">
                  <span class="prefix-label">{{ 'roomBook.region.title' | translate }}</span>
                  <app-search-select class="select required" [class.isValid]="!f.region.errors || f.region.untouched"
                                     [selectList]="regions" [selectFormGroup]="form" selectFormControlName="region"
                                     [addSelectNone]="true">
                  </app-search-select>
                </div>
              </div>
            </ng-container>
            <div class="top-info-created-by">
              <div class="latest-user" *ngIf="!isTemplate && !isNew">
                <span class="ico mdi mdi-account-circle" [matTooltip]="model.createdBy"></span>
                <span class="user">{{ model.createdBy }}</span>
                <span class="user"> - </span>
                <span>{{ model.createdOn | localizedDate:'short' }}</span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="tasklike-tab-container">
      <ng-container [ngTemplateOutlet]="tabs"></ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="globals.isPhone">
  <div *ngIf="form" class="tasklike-body room mobile">
    <div class="tasklike-edit-container">
      <form [formGroup]="form">
        <div class="tasklike-edit-header-phone">
          <div class="header-row-phone">
            <div class="header-row-title-phone">
              <div class="tasklike-input-wrapper">
                <textarea *ngIf="!isNew" class="tasklike-input" [class.error]="f.name.errors" formControlName="name"
                          [placeholder]="'roomBook.fieldName.name' + (!isTemplate ? '' : 'Template') | translate"></textarea>
                <textarea *ngIf="isNew" class="tasklike-input" [class.error]="f.name.errors" formControlName="name"
                          [placeholder]="'roomBook.fieldName.name' + (!isTemplate ? '' : 'Template') | translate"
                          appSetFocus></textarea>
                <div class="tasklike-form-hilight"></div>
              </div>
            </div>
          </div>
          <div class="tasklike-top-info-phone">
            <div class="latest-user" *ngIf="!isTemplate && !isNew">
              <span class="ico mdi mdi-account-circle"></span>
              <span>{{ model.createdBy }}</span>
              <span> - </span>
              <span>{{ model.createdOn | localizedDate:'short' }}</span>
            </div>
            <div class="top-info-like">
              <mat-checkbox formControlName="isTagged">
                {{ 'roomBook.fieldName.' + (!isTemplate ? 'isindividual' : 'isdefault') | translate }}
              </mat-checkbox>
            </div>
            <div *ngIf="!isTemplate" class="room-template top-info"
                 [title]="'roomBook.fieldName.roomtemplate' | translate">
              <span class="ico mdi" [class]="roomTemplateIcon"></span>
              <app-search-select class="select required"
                                 [class.isValid]="!f.roomTemplate.errors || f.roomTemplate.untouched"
                                 [selectList]="roomTemplates" [selectFormGroup]="form"
                                 selectFormControlName="roomTemplate" [addSelectNone]="true">
              </app-search-select>
            </div>

            <div class="top-info">
              <span class="prefix-label">{{ 'roomBook.fieldName.internalnumber' | translate }}</span>
              <div class="number-input input" [class.isValid]="!f.internalNumber.errors || f.internalNumber.untouched">
                <mat-form-field>
                  <input matInput formControlName="internalNumber">
                </mat-form-field>
              </div>
            </div>

            <ng-container *ngIf="!isTemplate">
              <div class="top-info">
                <span class="prefix-label">{{ 'roomBook.area.title' | translate }}</span>
                <app-search-select class="select required" [class.isValid]="!f.area.errors || f.area.untouched"
                                   [selectList]="areas" [selectFormGroup]="form" selectFormControlName="area"
                                   [addSelectNone]="true">
                </app-search-select>
              </div>
              <div class="top-info">
                <span class="prefix-label">{{ 'roomBook.floor.title' | translate }}</span>
                <app-search-select class="select" [class.isValid]="!f.floor.errors || f.floor.untouched"
                                   [selectList]="floors" [selectFormGroup]="form" selectFormControlName="floor"
                                   [addSelectNone]="true">
                </app-search-select>
              </div>
              <div class="top-info">
                <span class="prefix-label">{{ 'roomBook.region.title' | translate }}</span>
                <app-search-select class="select" [class.isValid]="!f.region.errors || f.region.untouched"
                                   [selectList]="regions" [selectFormGroup]="form" selectFormControlName="region"
                                   [addSelectNone]="true">
                </app-search-select>
              </div>
            </ng-container>
          </div>
        </div>
      </form>
    </div>
    <div class="tasklike-tab-container" [class.mobile]="globals.isPhone">
      <ng-container [ngTemplateOutlet]="tabs"></ng-container>
    </div>
  </div>
</ng-container>

<ng-template #tabs>
  <mat-tab-group [selectedIndex]="selectedTabIndex" animationDuration="0ms" mat-align-tabs="end" #tabGroup
                 (selectedTabChange)="tabChanged($event)">
    <mat-tab #detailsTab>
      <ng-template mat-tab-label>
        <span class="tab-label-text">{{ 'general.details' | translate }}</span>
      </ng-template>
      <app-room-edit-detail #roomEditDetail *ngIf="form" [user]="user" [room]="model" [zoneGroups]="zoneGroups"
                            [implicitZoneIds]="implicitZoneIds" [form]="form" [isInProject]="isInProject"
                            [isTemplate]="isTemplate" [commentsService]="commentsService"
                            (attributeAdded)="addAttribute($event)">
      </app-room-edit-detail>
    </mat-tab>
    <mat-tab *ngIf="!isTemplate && showHistory" #historyTab>
      <ng-template mat-tab-label>
        <span class="tab-label-ico mdi mdi-history"></span>
      </ng-template>
      <app-room-edit-history [changeSets]="model?.changes">
      </app-room-edit-history>
    </mat-tab>
    <mat-tab #fileTab *ngIf="!isTemplate">
      <ng-template mat-tab-label>
        <span class="tab-label-ico mdi mdi-attachment"></span>
      </ng-template>
      <app-room-edit-files *ngIf="isNew || wasFileTabActivatedWithRoom; else fileTabLoading" [room]="model"
                           [filesFolderExists]="filesFolderExists" [attachedFiles]="attachedFiles"
                           [cameraCheck]="cameraCheck" (cameraCheckChange)="cameraCheckChange.emit($event)"
                           (attachedFilesChanged)="attachedFilesChanged.emit($event)"
                           (tabSelectedChanged)="setSelectedTab($event)">
      </app-room-edit-files>
      <ng-template #fileTabLoading>
        <div class="loading-container">
          <ngx-loading [show]="true" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
        </div>
      </ng-template>
    </mat-tab>
  </mat-tab-group>
</ng-template>

<ng-template #roomNameTemplate>
  <span>{{ f.room.value?.name }} ({{f.room.value?.number}})</span>
  <span *ngIf="f.room.value && room?.regionName">&nbsp;- {{ room.regionName }}</span>
</ng-template>