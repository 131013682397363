import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { WorkpackageStateProjectEntityState } from '@app/api';
import { DataHolder, Utils, nameof } from '@app/core';
import { LocalizedDateFormat, LocalizedDatePipe } from '@app/shared/pipes';
import { TranslateService } from '@ngx-translate/core';
import { GridComponent } from '../../../grid';
import { C4GridColumn, C4GridDef, C4GridFilterType, C4GridMatchMode, C4GridSelectOptions } from '../../../grid/grid.interfaces';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { ExtendedLeanWorkpackageModel } from '@app/C4CustomerPortal/lean-workpackages/components/workpackages/workpackages.component';
import { SelectFieldPipe } from '@app/shared/pipes/select-field.pipe';

export interface WorkpackageListItem {
  number: number;
  name: string;
  phaseName?: string;
  regionName?: string;
  craftName?: string;
  craftHexColor?: string;
  lane?: string;
  currentState?: WorkpackageStateProjectEntityState;
  stateIcon?: string;
  startDate?: Date;
  endDate?: Date;
}

@Component({
  selector: 'app-workpackage-list',
  templateUrl: './workpackage-list.component.html',
  styleUrls: ['./workpackage-list.component.scss'],
})
export class WorkpackageListComponent implements OnInit {
  @ViewChild(GridComponent) grid: GridComponent;

  @ViewChild('nameTemplate', { static: true }) nameTemplate: TemplateRef<any>;
  @ViewChild('state', { static: true }) stateTemplate: TemplateRef<any>;
  @ViewChild('craft', { static: true }) craftTemplate: TemplateRef<any>;
  @ViewChild('actionsTemplate', { static: true }) actionsTemplate: TemplateRef<any>;

  @Input() isListForTemplates: boolean = false;
  @Input() workpackageStates: C4GridSelectOptions[] = [];
  @Input() dataHolder: DataHolder<ExtendedLeanWorkpackageModel[]>;
  @Input() set rowCount(count: number) {
    if (this.gridDef != null) this.gridDef.grid.rowCount = count;
  }

  @Input() canEditWorkpackage: boolean = true;

  @Output() edit = new EventEmitter<WorkpackageListItem>();
  @Output() remove = new EventEmitter<WorkpackageListItem>();

  gridDef: C4GridDef;
  noDataText = 'grid.workpackages.noDataText';
  visibleOverviewFields: string[] = [];

  constructor(
    private datePipe: LocalizedDatePipe,
    private translateService: TranslateService,
    private selectFieldPipe: SelectFieldPipe
  ) {}

  ngOnInit() {
    if (this.isListForTemplates) {
      this.noDataText = 'grid.workpackageTemplates.noDataText';
    }

    this.buildGridDef();

    this.gridDef.cols.forEach(c => {
      if (!c.hidden) this.visibleOverviewFields.push(c.field);
    });
  }

  private buildGridDef() {
    const cols: C4GridColumn[] = [];

    if (!this.isListForTemplates) {
      cols.push({
        field: nameof<WorkpackageListItem>('number'),
        header: 'workpackages.number',
        width: '5em',
        minWidth: '5em',
        priority: 1,
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      });
    }

    cols.push({
      field: nameof<WorkpackageListItem>('name'),
      header: 'workpackages.name',
      width: '9em',
      minWidth: '9em',
      priority: 1,
      sortable: true,
      filterType: C4GridFilterType.text,
      filterMatchMode: C4GridMatchMode.contains,
      template: this.nameTemplate,
    });

    cols.push({
      field: nameof<ExtendedLeanWorkpackageModel>('strippedDescription'),
      header: 'workpackages.description',
      width: '2*',
      minWidth: '14em',
      priority: 1,
      sortable: true,
      filterType: C4GridFilterType.text,
      filterMatchMode: C4GridMatchMode.contains,
    });

    if (!this.isListForTemplates) {
      cols.push(
        {
          field: nameof<WorkpackageListItem>('currentState'),
          header: 'workpackages.state',
          width: '10em',
          minWidth: '10em',
          priority: 3,
          sortable: true,
          options: this.workpackageStates,
          filterType: C4GridFilterType.multiselect,
          filterMatchMode: C4GridMatchMode.equals,
          pipe: this.selectFieldPipe,
          pipeArg: 'title',
          template: this.stateTemplate,
        },
        {
          field: nameof<WorkpackageListItem>('regionName'),
          header: 'workpackages.regionName',
          width: '1*',
          priority: 3,
          minWidth: '6em',
          sortable: true,
          filterType: C4GridFilterType.text,
          filterMatchMode: C4GridMatchMode.contains,
        },
        {
          field: nameof<ExtendedLeanWorkpackageModel>('floorName'),
          header: 'workpackages.floor',
          width: '2*',
          minWidth: '14em',
          priority: 1,
          sortable: true,
          filterType: C4GridFilterType.text,
          filterMatchMode: C4GridMatchMode.contains,
        },
        {
          field: nameof<ExtendedLeanWorkpackageModel>('areaName'),
          header: 'workpackages.area',
          width: '2*',
          minWidth: '14em',
          priority: 1,
          sortable: true,
          filterType: C4GridFilterType.text,
          filterMatchMode: C4GridMatchMode.contains,
        }
      );
    }

    cols.push(
      {
        field: nameof<WorkpackageListItem>('phaseName'),
        header: 'workpackages.' + (!this.isListForTemplates ? 'mainPhaseName' : 'phaseName'),
        width: '1*',
        priority: 3,
        minWidth: '6em',
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      },
      {
        field: nameof<WorkpackageListItem>('craftName'),
        header: 'workpackages.craftName',
        width: '1*',
        priority: 3,
        minWidth: '6em',
        sortable: true,
        filterType: C4GridFilterType.text,
        filterMatchMode: C4GridMatchMode.contains,
      }
    );

    if (!this.isListForTemplates) {
      cols.push(
        {
          field: nameof<WorkpackageListItem>('startDate'),
          header: 'workpackages.startDate',
          width: '1*',
          priority: 3,
          minWidth: '6em',
          sortable: true,
          filterType: C4GridFilterType.date,
          pipe: this.datePipe,
        },
        {
          field: nameof<WorkpackageListItem>('endDate'),
          header: 'workpackages.endDate',
          width: '1*',
          priority: 3,
          minWidth: '6em',
          sortable: true,
          filterType: C4GridFilterType.date,
          pipe: this.datePipe,
        }
      );
    }

    cols.push({
      field: '',
      header: 'workpackages.actions',
      width: '8em',
      minWidth: '8em',
      cssClass: 'action-flex-grid',
      priority: 1,
      sortable: false,
      template: this.actionsTemplate,
    });

    this.gridDef = {
      initialSorting: [
        {
          field: this.isListForTemplates ? nameof<WorkpackageListItem>('name') : nameof<WorkpackageListItem>('number'),
          order: 1,
        },
      ],
      grid: {
        filterRow: true,
        lazyInit: false,
        responsive: true,
        scrollable: true,
        rowExpand: true,
        paging: true,
        rows: 10,
        rowsOptions: [5, 10, 20, 50, 100],
        lazy: false,
      },
      row: {
        link: false,
      },
      cols,
    };
  }

  // #region Export

  // this should be refactored/unified in grid component by someone who understands this code (tech-stack upgrade)

  async generateCsv(filename: string) {
    const [listHead, listData] = this.getRefinedGridData();

    if (!listData) return;
    const csv = [];
    let row = [];
    listHead.forEach(h => {
      row.push(h.header);
    });

    csv.push(row);
    listData.forEach(d => {
      row = [];
      listHead.forEach(h => {
        row.push(d[h.dataKey]);
      });
      csv.push(row);
    });
    let csvresult = '';
    csv.forEach(r => {
      csvresult += r.join(';') + '\r\n';
    });

    await Utils.saveCSVLocal(csvresult, filename);
  }

  async generatePdf(filename: string) {
    const [listHead, listData] = this.getRefinedGridData();
    if (!listData) return;
    // header filename date etc.:
    const date = new Date().getTime();
    const headerDate = this.datePipe.transform(date, LocalizedDateFormat.short);
    const pdfTitle = this.translateService.instant('workpackages.titlePdf', { datetime: headerDate });
    const doc = new jsPDF({ orientation: 'l', unit: 'mm', format: [297, 210] });
    autoTable(doc, {
      margin: { top: 13, bottom: 10, left: 10, right: 10 },
      tableWidth: 277,
      body: listData,
      columns: listHead,
      styles: { cellPadding: 0.5, fontSize: 6, overflow: 'linebreak' },
      didDrawPage: function (data) {
        doc.setFontSize(8);
        doc.setTextColor(0);
        doc.text(pdfTitle, 10, 10);
      },
    });
    await Utils.saveJsPDFLocal(doc, filename);
  }

  private getRefinedGridData() {
    const hidecol = []; // ['select', 'contextmenu'];
    const usedCols = [];

    this.grid.gridDefModel.cols.forEach(c => {
      if (!c.hidden) usedCols.push(c);
    });
    const listHead = usedCols.map(col => ({
      header: col.noTranslate ? col.header : this.translateService.instant('grid.' + col.header),
      dataKey: col.field,
      cw: col.absWidth,
    }));

    let currentData = [];
    if (this.dataHolder.dataReady) {
      currentData =
        this.grid.dataTable.filteredValue && this.grid.dataTable.filteredValue.length > 0
          ? this.grid.dataTable.filteredValue
          : this.grid.dataTable.value;
    }

    return [
      listHead.filter(l => hidecol.indexOf(l.dataKey) === -1 && l.header),
      !currentData || currentData.length < 1 ? null : this.refineForPrint(currentData),
    ];
  }

  private refineForPrint(data: any[]) {
    const extendedModels = data.map(r => {
      return { ...r } as ExtendedLeanWorkpackageModel;
    });
    let result = JSON.parse(JSON.stringify(extendedModels));

    this.grid.gridDefModel.cols.forEach((col, index) => {
      if (col.pipe) {
        result.forEach(row => {
          row[col.field] = col.pipeArg ? col.pipe.transform(row[col.field], col.pipeArg) : col.pipe.transform(row[col.field]);
        });
      }
      if (col.isRange) {
        result.forEach(row => {
          row[col.field] = row[Utils.rangePrefix + col.field];
        });
      }
    });

    result.forEach(row => {
      // translate states
      row['state'] = this.translateService.instant('workpackages.state.' + row['state']);
    });
    return result;
  }

  // #endregion Export
}
