import { FileGroup } from '../enumerations';
import { Utils } from './utils';

export interface ResizeOptions {
  imageHeight: number;
  imageWidth: number;
  shouldImageCover?: boolean;
}

export class FileUtils {
  static isValidType(type: string, validTypes: string[] = []) {
    return (
      !validTypes?.length || validTypes.filter(valid => new RegExp(`^${valid.replace(/\*/g, '.*')}$`).test(type)).length > 0
    );
  }

  static getEncodedImage(imageFile: File, resizeOptions: ResizeOptions = null) {
    if (!imageFile || !this.isValidType(imageFile.type, [FileGroup.image])) return null;

    if (Utils.isIE() && imageFile.size > 20971520) {
      throw 'setup.customization.errorUploadIE';
    }

    return new Promise<string>(resolve => {
      let reader = new FileReader();
      reader.onload = evt => {
        if (resizeOptions != null) {
          let img = new Image();

          img.onload = async () => {
            let canvas = document.createElement('canvas');
            canvas.setAttribute('height', resizeOptions.imageHeight + 'px');
            canvas.setAttribute('width', resizeOptions.imageWidth + 'px');

            const canvasContext = canvas.getContext('2d');
            let dx: number = 0,
              dy: number = 0,
              imageHeight: number = (img.height / img.width) * canvas.width,
              imageWidth: number = canvas.width;

            const shouldFitToHeight =
              (resizeOptions.shouldImageCover && imageHeight < canvas.height) ||
              (!resizeOptions.shouldImageCover && imageHeight > canvas.height);

            if (shouldFitToHeight) {
              // fit to height, center horizontally
              imageWidth = (img.width / img.height) * canvas.height;
              imageHeight = canvas.height;
              dx = (canvas.width - imageWidth) / 2;
            } else {
              // fit to width, center vertically
              dy = (canvas.height - imageHeight) / 2;
            }

            canvasContext.drawImage(img, dx, dy, imageWidth, imageHeight);

            let dataUrl = canvas.toDataURL('image/png');
            let strippedDataUrl = dataUrl.replace(/^.*,/, '');

            resolve(strippedDataUrl);
          };

          img.src = <any>evt.target.result;
        } else {
          resolve((evt.target.result as string)?.replace(/^.*,/, ''));
        }
      };

      reader.readAsDataURL(imageFile);
    });
  }
}
