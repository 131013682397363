import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'filetype' })
export class FileType implements PipeTransform {
  constructor() { }
  transform(value: string) {
    const regex = /\.([A-Z,a-z,0-9]{2,5})$/i;
    const suffix = regex.exec(value);
    return (suffix)?(suffix.length > 1) ? suffix[1] : '':'';
  }
}

@Pipe({ name: 'filename' })
export class FileName implements PipeTransform {
  constructor() { }
  transform(value: string) {
    const regex = /(.*)(\..*)$/i;
    const suffix = regex.exec(value);
    return (suffix)? (suffix.length > 2) ? suffix[1] : value:value;
  }
}