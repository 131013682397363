import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export enum InputType {
  input = 'input',
  textarea = 'textarea',
}

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextInputComponent,
    },
  ],
})
export class TextInputComponent implements ControlValueAccessor {
  @Input() inputType: InputType = InputType.input;
  @Input() placeholder: string = '';
  @Input() isUnderlineVisible: boolean = true;
  @Input() minRows: number = 3;
  @Input() maxRows: number = 7;
  isDisabled: boolean = false;
  text: string = '';
  InputType = InputType;

  onChange = (_: string) => {};
  private onTouched = () => {};
  private isTouched: boolean = false;

  constructor() {}

  // ----- Reactive Forms Methods -----

  writeValue(text: string): void {
    this.text = text;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
  }
}
