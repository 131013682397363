<form class="config-content-container roles-config" [ngClass]="globals.isMobileMenu?'isphone':''">
	<div class="config-content">
		<div class="config-group gap">
			<div class="config-title sticky">{{ 'projectConfig.roles.title' | translate }}</div>

			<div class="table-grid-component-container">
				<div class="matrix table-grid-component">
					<div class="c4-table-grid-cell c4-table-grid-header-top c4-table-grid-header-start corner">
						{{ 'projectConfig.roles.title' | translate }}
					</div>
					<div class="c4-table-grid-cell c4-table-grid-header-top">
						{{ 'projectConfig.roles.subRoles' | translate }}
					</div>
					<div class="c4-table-grid-cell c4-table-grid-header-top centered"></div>

					<ng-container [ngTemplateOutlet]="groupHeaderRow" [ngTemplateOutletContext]="{
						name: 'projectConfig.roles.system' | translate
					}"></ng-container>
					<ng-container *ngFor="let role of systemRoles">
						<ng-container [ngTemplateOutlet]="roleRow" [ngTemplateOutletContext]="{
							role: role
						}"></ng-container>
					</ng-container>

					<ng-container [ngTemplateOutlet]="groupHeaderRow" [ngTemplateOutletContext]="{
						name: 'projectConfig.roles.custom' | translate,
						canAdd: true
					}"></ng-container>

					<ng-container *ngFor="let role of basicRoles">
						<ng-container [ngTemplateOutlet]="roleRow" [ngTemplateOutletContext]="{
							role: role
						}"></ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</form>

<ng-template #groupHeaderRow let-name="name" let-canAdd="canAdd">
	<div class="c4-table-grid-cell c4-table-grid-group-header c4-table-grid-header-start">
		{{ name }}
	</div>
	<div class="c4-table-grid-cell c4-table-grid-group-header full-row"></div>
	<div class="c4-table-grid-cell c4-table-grid-group-header c4-table-grid-header-end">
		<ng-container *ngIf="canAdd">
			<button mat-icon-button (click)="addOrEditRole()">
				<span class="mdi mdi-plus-box"></span>
			</button>
		</ng-container>
	</div>
</ng-template>

<ng-template #roleRow let-role="role">
	<div class="c4-table-grid-cell c4-table-grid-header-start" [formGroup]="role">
		<div class="label-stack-container">
			<span class="title role-name slim-icon-container c4-busy-content">
				<span [matTooltip]="roleAndUserData[role.controls.id.value]?.name">
					{{ roleAndUserData[role.controls.id.value]?.name }}
				</span>
				<span class="mdi" [class]="role.controls.isReadOnly.value ? 'mdi-lock' : 'mdi-lock-open-variant'"
							[matTooltip]="'projectConfig.roles.' + (role.controls.isReadOnly.value ? 'readOnly' : 'notReadOnly') | translate"></span>
				<span class="mdi" [class]="role.controls.isHidden.value ? 'mdi-eye-off' : 'mdi-eye'"
							[matTooltip]="'projectConfig.roles.' + (role.controls.isHidden.value ? 'hidden' : 'visible') | translate"></span>
			</span>
			<span *ngIf="role.controls.description.value" class="subtitle c4-busy-content"
						[matTooltip]="role.controls.description.value">
				{{ role.controls.description.value }}
			</span>
		</div>
	</div>

	<div class="c4-table-grid-cell" [formGroup]="role">
		<mat-chip-list #chipList (click)="$event.stopPropagation()">
			<ng-template *ngFor="let id of role.controls.roles.value" [ngTemplateOutlet]="roleChip" [ngTemplateOutletContext]="{
				role: role,
				subId: id,
				type: RoleSubType.role,
				showColor: true
			}"></ng-template>
			<ng-template *ngFor="let id of role.controls.users.value" [ngTemplateOutlet]="roleChip" [ngTemplateOutletContext]="{
				role: role,
				subId: id,
				type: RoleSubType.user
			}"></ng-template>
			<mat-chip *ngIf="role.controls.id.value == everyoneId" class="primary" [selected]="true" [selectable]="false"
								[removable]="false" [disabled]="true">
				<span class="chip-label">
					{{ 'projectConfig.roles.entireProjectTeam' | translate }}
				</span>
			</mat-chip>
			<input class="chip-list-input" #autoInput [placeholder]="'general.actions.add' | translate"
						 [class.hidden]="role.controls.isReadOnly.value || (role.controls.roles.disabled && role.controls.users.disabled)"
						 #trigger="matAutocompleteTrigger" [matAutocomplete]="autocomplete" [matChipInputFor]="chipList"
						 (blur)="closeBeforeBuild($event, trigger)" (focus)="buildAutocomplete(role, $event.target.value)"
						 (input)="filterAutocomplete($event.target.value)" [disabled]="role.controls.isReadOnly.value">
		</mat-chip-list>
		<mat-autocomplete autoActiveFirstOption #autocomplete="matAutocomplete"
											(optionSelected)="autoInput.value = ''; autocompleteOptionSelected($event.option.value, role)"
											[panelWidth]="'24rem'">
			<mat-option class="detailed-option" *ngFor="let value of filteredAutoValues" [value]="value">
				<span class="option-title">{{ value.name }}</span>
				<span class="option-subtitle">{{ value.description }}</span>
			</mat-option>
		</mat-autocomplete>
	</div>

	<div class="c4-table-grid-cell top end">
		<div class="action-container">
			<button mat-icon-button (click)="addOrEditRole(role)">
				<span class="mdi mdi-pencil"></span>
			</button>
			<button *ngIf="!role.controls.isSystemRole.value" mat-icon-button (click)="removeRole(role)">
				<span class="mdi mdi-trash-can-outline"></span>
			</button>
		</div>
	</div>
</ng-template>

<ng-template #roleChip let-role="role" let-subId="subId" let-type="type" let-showColor="showColor">
	<mat-chip color="primary" [selected]="showColor" [selectable]="false" [removable]="!role.controls.isReadOnly.value"
						(removed)="removeSubRoleOrUserIfExists(subId, role, type)">
		<span class="chip-label">
			{{ roleAndUserData[subId]?.name }}
		</span>
		<mat-icon *ngIf="!role.controls.isReadOnly.value" matChipRemove>cancel</mat-icon>
	</mat-chip>
</ng-template>