<mat-form-field>
  <input #input type="text" #trigger="matAutocompleteTrigger" matInput [formControl]="formControl"
         [matAutocomplete]="innerId" placeholder="{{placeholder}}" (blur)="afterSelection('blur')"
         [errorStateMatcher]="errorStateMatcher" />
  <div class="clear-button" *ngIf="showClearButton && selectedOption" (click)="clear()"></div>
  <mat-error *ngIf="showError">
    <span>{{ 'general.errorForms.' + getError() | translate }}</span>
  </mat-error>
  <mat-autocomplete #innerId="matAutocomplete" [displayWith]="getTranslatedOptionDisplayValue.bind(this)"
                    (closed)="afterSelection('closed')">
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{getTranslatedOptionDisplayValue(option)}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>