import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { LeanPhaseModel, LeanWorkpackageSequenceModel, LeanWorkpackageTemplateModel } from '@app/api';
import { BaseSubscriptionComponent } from '@app/core';
import { WorkpackageTemplatesHelper } from '@app/core/utils/workpackage-templates-helper';
import { TemplateType } from '@app/shared/components/bryntum-scheduler/bryntum-scheduler.component';
import { Busy } from '@app/shared/utils/busy';

@Component({
  selector: 'app-workpackage-template-pane',
  templateUrl: './workpackage-template-pane.component.html',
  styleUrls: ['./workpackage-template-pane.component.scss'],
})
export class WorkpackageTemplatePaneComponent extends BaseSubscriptionComponent {
  @ViewChild('templateContainer') templateContainer;

  @Input() showSequences = true;
  @Input() showCloseButton = true;
  @Output() close = new EventEmitter<void>();

  private _templates: LeanWorkpackageTemplateModel[] = [];
  @Input() get templates() {
    return this._templates;
  }
  set templates(value: LeanWorkpackageTemplateModel[]) {
    this._templates = value;
    this.updateWorkpackagePhases();
    this.filterToolbar();
  }

  private _sequences: LeanWorkpackageSequenceModel[] = [];
  @Input() get sequences(): LeanWorkpackageSequenceModel[] {
    return this._sequences;
  }
  set sequences(value: LeanWorkpackageSequenceModel[]) {
    this._sequences = value;
    this.updateWorkpackagePhases();
    this.filterToolbar();
  }

  private _phases: LeanPhaseModel[] = [];
  @Input() get phases() {
    return this._phases;
  }
  set phases(value: LeanPhaseModel[]) {
    this._phases = value;
    this.updateWorkpackagePhases();
  }

  @HostListener('wheel', ['$event'])
  onMouseWheel(event) {
    if (
      event.target.className.includes('workpackage-template-tile') ||
      event.target.className.includes('templates-container')
    ) {
      event.preventDefault();
      this.templateContainer.nativeElement.scrollTop += event.deltaY / 2;
    }
  }

  leanTemplateType = TemplateType;
  selectedLeanTemplateType = TemplateType.workpackage;
  workpackagePhases: LeanPhaseModel[] = [new LeanPhaseModel({ id: 'all', name: 'All' })];

  private _selectedWorkpackagePhases: Entity[] = [];
  get selectedWorkpackagePhases(): Entity[] {
    return this._selectedWorkpackagePhases;
  }

  set selectedWorkpackagePhases(phases: Entity[]) {
    this._selectedWorkpackagePhases = phases;
    this.filterToolbar();
  }

  private _filter = '';
  get filter(): string {
    return this._filter;
  }
  set filter(value: string) {
    this._filter = value;
    this.filterToolbar();
  }

  templatesBusy: Busy = { isBusy: false };

  filteredWorkpackageTemplates: LeanWorkpackageTemplateModel[] = [];
  filteredSequences: LeanWorkpackageSequenceModel[] = [];

  filterToolbar() {
    const phaseIds = this.selectedWorkpackagePhases.map(phase => phase.id);
    const textFilter = this.filter?.toLowerCase();

    this.filteredWorkpackageTemplates = WorkpackageTemplatesHelper.filterTemplates(this.templates, phaseIds, textFilter);

    this.filteredSequences = this.sequences.filter(
      sequence =>
        (!textFilter ||
          sequence.name.toLowerCase().includes(textFilter) ||
          sequence.templates.some(template => template.name.toLowerCase().includes(textFilter))) &&
        (phaseIds.length === 0 || phaseIds.includes(sequence.phaseId))
    );
  }

  onChangeSelectedTemplateType() {
    this.selectedWorkpackagePhases = [];
    this.updateWorkpackagePhases();
  }

  private updateWorkpackagePhases() {
    this.workpackagePhases = WorkpackageTemplatesHelper.getAvailablePhases(
      this.selectedLeanTemplateType == TemplateType.sequence ? this.sequences : this.templates,
      this.phases
    );
    this.selectedWorkpackagePhases = this.workpackagePhases.filter(
      phase => this.selectedWorkpackagePhases.find(selected => phase.id == selected.id) != null
    );
  }
}
