import { Component, ElementRef, Inject, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BehaviorSubject } from 'rxjs';
import { CameraPreview } from '@capacitor-community/camera-preview';
import { Capacitor } from '@capacitor/core';
import { FileGroup } from '@app/core/enumerations';
import { IGeoJsonChange } from '../../geojson';
import { GeoJSONFeatureCollection, GeoJSONType } from '@app/api';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';

export interface FileWithGeoJson extends File {
  geojson?: GeoJSONFeatureCollection;
}

export interface MultiImageCaptureDialogComponentConfig {
  allowDrawing?: boolean;
}

@Component({
  selector: 'app-multi-image-capture-dialog',
  templateUrl: './multi-image-capture-dialog.component.html',
  styleUrls: ['./multi-image-capture-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiImageCaptureDialogComponent implements OnInit {
  @ViewChild('imageSurface') imageSurface: ElementRef<HTMLDivElement>;
  @ViewChild('imageContainer') imageContainer: ElementRef<HTMLDivElement>;

  @ViewChild('header') header: ElementRef<HTMLDivElement>;

  @ViewChild('cameraInput', { static: true }) cameraInput: ElementRef<HTMLInputElement>;
  @Input() allowDrawing: boolean = false;
  debugText = '';

  imageTypes = [FileGroup.image];
  imageUrls = new BehaviorSubject<string[]>([]);
  selectedImageIndex: number = -1;

  private files: FileWithGeoJson[] = [];
  private geojsons: GeoJSONFeatureCollection[] = [];

  constructor(
    public dialogRef: MatDialogRef<MultiImageCaptureDialogComponent>,
    @Inject(MAT_LEGACY_DIALOG_DATA) private config: MultiImageCaptureDialogComponentConfig
  ) {}

  get selectedImageUrl(): string {
    return this.selectedImageIndex >= 0 ? this.imageUrls.getValue()[this.selectedImageIndex] : null;
  }
  get selectedImageGeoJson(): GeoJSONFeatureCollection {
    return this.selectedImageIndex >= 0 ? this.geojsons[this.selectedImageIndex] : null;
  }
  get hasFilesAdded(): boolean {
    return this.files.length > 0;
  }

  async ngOnInit() {
    this.allowDrawing = this.config.allowDrawing ?? false;
  }

  async ngAfterViewInit() {
    await this.classToggle(true);
  }

  async ngOnDestroy() {
    await this.classToggle(false);
  }

  close() {
    this.dialogRef.close([]);
  }

  confirm() {
    for (let i = 0; i < this.files.length; i++) {
      const file = this.files[i];
      const geojson = this.geojsons[i];

      if (geojson) {
        file.geojson = geojson;
      }
    }

    this.dialogRef.close(this.files);
  }

  async selectImage(index: number) {
    this.selectedImageIndex = index;
    if (index < 0) {
      await this.classToggle(true);
    } else {
      await this.classToggle(false);

      setTimeout(() => {
        const element = document.getElementById(index.toString());

        element.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
  }

  fileChanged(files: File[]) {
    this.addFiles(...files);
  }

  addFiles(...files: File[]) {
    if (files.length > 0) {
      const imageUrls = this.imageUrls.getValue();

      for (const file of files) {
        this.files.push(file);
        this.geojsons.push(null);

        imageUrls.push(URL.createObjectURL(file));
      }

      this.imageUrls.next(imageUrls);

      this.selectedImageIndex = -1;

      setTimeout(() => {
        this.imageContainer.nativeElement.scrollTo({ left: this.imageContainer.nativeElement.scrollWidth });
      }, 0);
    }
  }

  async capture() {
    let prom;
    let ignoreAdd = false;
    if (!CapacitorUtils.isApp()) {
      prom = this.captureDesktop();
      ignoreAdd = true;
    } else prom = CameraPreview.capture({ quality: 100 });

    await prom
      .then(result => {
        return fetch('data:image/jpeg;base64,' + result.value);
      })
      .then(result => result.blob())
      .then(result => {
        const file = new File([result], `image-${Date.now()}.jpeg`, {
          type: 'image/jpeg;base64',
        });
        if (!ignoreAdd) this.addFiles(file);
      });
  }

  private captureDesktop(): Promise<{ value: string }> {
    return new Promise((resolve, reject) => {
      this.cameraInput.nativeElement.click();
      this.cameraInput.nativeElement.onchange = () => {
        const reader = new FileReader();
        reader.readAsDataURL(this.cameraInput.nativeElement.files[0]);
        reader.onload = () => {
          resolve({ value: reader.result.toString().split(',')[1] });
        };
        reader.onerror = error => reject(error);
      };
    });
  }

  removeFileAt(index: number) {
    if (index >= 0) {
      const imageUrls = this.imageUrls.getValue();

      this.files.splice(index, 1);
      this.geojsons.splice(index, 1);
      imageUrls.splice(index, 1);

      if (this.selectedImageIndex == index) {
        this.selectedImageIndex = Math.max(index - 1, 0);
      } else if (this.selectedImageIndex > index) {
        this.selectedImageIndex = index - 1;
      }

      this.imageUrls.next(imageUrls);
    }
    if (this.files.length <= 0) {
      this.selectImage(-1);
    }
  }

  clearFiles() {
    this.files.splice(0);
    this.imageUrls.next([]);
    this.selectedImageIndex = -1;
  }

  public fireMarkersChange($evt: IGeoJsonChange) {
    this.geojsons[this.selectedImageIndex] = GeoJSONFeatureCollection.fromJS({
      features: $evt.features,
      type: GeoJSONType.FeatureCollection,
    });
  }

  private async classToggle(force: boolean) {
    if (CapacitorUtils.isApp()) {
      document.body.classList.toggle('camera-preview-active', force);

      if (force) {
        await this.start();
      } else {
        await this.stop();
      }
    }
  }

  private async stop() {
    try {
      await CameraPreview.stop();
    } catch {}
  }

  private async start() {
    try {
      let y = 0;
      let height = window.screen.height;
      if (Capacitor.getPlatform() === 'ios') {
        const IPHONE_STATUS_BAR_OFFSET = 35; // Somehow
        const IPHONE_BOTTOM_OFFSET = 10; // Somehow

        const r = await SafeArea.getSafeAreaInsets();
        y = r.insets.top + IPHONE_STATUS_BAR_OFFSET;
        height = window.screen.height - IPHONE_BOTTOM_OFFSET - r.insets.top - IPHONE_STATUS_BAR_OFFSET;
      }

      await CameraPreview.start({
        enableZoom: true,
        disableAudio: true,
        enableHighResolution: true,
        disableExifHeaderStripping: true,
        y: y,
        height: height,

        parent: 'imageSurface',
        position: 'rear',

        toBack: true,
      });
    } catch {}
  }
}
