import { IMigration, Statement } from '../definitions';

export const Migration: IMigration = {
  name: 'M220414_ProjectName',
  getStatements() {
    const createProjectPrivileges = Statement`
      CREATE TABLE projectPrivileges (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const alterProject = Statement`
      ALTER TABLE projects
      ADD COLUMN json TEXT;
    `;

    const alterCompanyUsers = Statement`
      ALTER TABLE companyUsers
      RENAME TO projectUsers;
    `;

    const alterCompanyOrganizations = Statement`
      ALTER TABLE companyOrganizations
      RENAME TO projectOrganizations;
    `;

    const alterCompanyOrganizationCrafts = Statement`
      ALTER TABLE companyOrganizationCrafts
      RENAME TO projectOrganizationCrafts;
    `;

    const alterCompanyOrganizationCraftsColumn = Statement`
      ALTER TABLE projectOrganizationCrafts
      RENAME COLUMN 'companyOrganizationId' TO 'projectOrganizationId';
    `;

    return [
      createProjectPrivileges,

      alterProject,
      alterCompanyUsers,
      alterCompanyOrganizations,
      alterCompanyOrganizationCrafts,
      alterCompanyOrganizationCraftsColumn,
    ];
  },
};
