<div class="bryntum-scheduler-component">
	<bryntum-scheduler-pro #schedulerpro [config]="schedulerProConfig" [project]="apiProject" [presets]="presets"
												 [viewPreset]="presets[0]" (onBeforeCellEditStart)="onEditCell($event)"
												 [displayDateFormat]="'general.date.patternMoment' | translate">
	</bryntum-scheduler-pro>

	<button *ngIf="showAddButton" class="add-button" [class.elevated]="!showGridOnly" mat-fab color="primary"
					[matTooltip]="'scheduler.actions.addPhase' | translate" (click)="addPhase()">
		<span class="mdi mdi-plus"></span>
	</button>

	<div *ngIf="!showGridOnly" class="figures-button" [matTooltip]="'scheduler.actions.selectFigures' | translate"
			 [matMenuTriggerFor]="figuresMenu">
		<span>{{ 'scheduler.figures.' + selectedFiguresType | translate }}</span>
		<span class="c4-mat-dropdown-arrow"></span>
	</div>
	<mat-menu #figuresMenu="matMenu">
		<button mat-menu-item (click)="figuresTypeChanged(figuresType.completed)">
			{{ 'scheduler.figures.completed' | translate }}
		</button>
		<button mat-menu-item (click)="figuresTypeChanged(figuresType.capacity)">
			{{ 'scheduler.figures.capacity' | translate }}
		</button>
		<button mat-menu-item (click)="figuresTypeChanged(figuresType.cost)">
			{{ 'scheduler.figures.cost' | translate }}
		</button>
	</mat-menu>
</div>

<div *ngIf="!showGridOnly && isDebugMode"
		 style="position: absolute; right: 2.4rem; bottom: 7.2rem; left: 2.4rem; display: flex; align-items: center; gap: 1.2rem">
	<button mat-fab color="primary" (click)="startStressTest()">
		<span style="font-size: 2.4rem;" class="mdi mdi-radioactive" matTooltip="Stress Test"></span>
	</button>

	<mat-progress-bar *ngIf="isStressTestActive" [mode]="stressTestProgress > 0 ? 'determinate' : 'indeterminate'"
										[value]="stressTestProgress"></mat-progress-bar>
</div>

<ng-template #dragTemplate>
	<div class="drag-template"></div>
</ng-template>