import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat',
})
export class NumberFormatPipe implements PipeTransform {
  transform(value: number, minimumIntegerDigits: number = 2, prefix: string = '#'): string {
    const extendedNumber = value.toLocaleString('en-US', {
      minimumIntegerDigits,
      useGrouping: false,
    });

    return `${prefix ?? ''}${extendedNumber}`;
  }
}
