import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { IProjectConfigResourceTab } from '@app/api';
import { FormComponent } from '@app/core/utils/form-component';
import { ProjectConfigService } from '@app/shared/services/project-config';
import { TranslateService } from '@ngx-translate/core';

export interface EditTabDialogResult {
  tab: IProjectConfigResourceTab;
  hideNavigation: boolean;
}

@Component({
  selector: 'app-edit-tab-dialog',
  templateUrl: './edit-tab-dialog.component.html',
  styleUrls: ['./edit-tab-dialog.component.scss'],
})
export class EditTabDialogComponent extends FormComponent implements OnInit {
  title = 'projectConfig.structure.tab.';
  tab: IProjectConfigResourceTab;
  isProvisioned: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private configService: ProjectConfigService,
    private dialogRef: MatDialogRef<EditTabDialogComponent>,
    private translate: TranslateService
  ) {
    super();

    this.title += data?.tab ? 'edit' : 'add';
    this.isProvisioned = data?.isProvisioned ?? false;
    this.tab = data?.tab ?? {
      displayName: this.translate.instant('projectConfig.structure.tab.new'),
    };
  }

  ngOnInit() {
    this.form = this.configService.getTabGroup(this.tab, this.isProvisioned);
  }

  confirm() {
    if (this.form.valid) {
      const tab = this.parseForm();
      this.dialogRef.close({
        tab,
        hideNavigation: this.f.hideNavigation.value,
      });
    }
  }

  cancel() {
    this.dialogRef.close(null);
  }

  private parseForm(): IProjectConfigResourceTab {
    return {
      displayName: this.f.displayName.value,
      entityId: this.f.entityId.value,
      appId: this.f.appId.value,
      webSiteUrl: this.f.webSiteUrl.value,
      contentUrl: this.f.contentUrl.value,
    };
  }
}
