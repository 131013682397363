import { Pipe, PipeTransform } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

enum FormCategoryContainsBehaviour {
  FilterOnlyWhenNotEmpty,
  Filter,
}

@Pipe({
  name: 'formCategoryContains',
})
export class FormCategoryContainsPipe implements PipeTransform {
  transform(
    groups: UntypedFormGroup[],
    categories: IFormCategory[],
    mode: FormCategoryContainsBehaviour = FormCategoryContainsBehaviour.FilterOnlyWhenNotEmpty
  ) {
    if (categories.length == 0 && mode == FormCategoryContainsBehaviour.FilterOnlyWhenNotEmpty) {
      return groups;
    }

    return groups.filter(group => categories.some(category => category.groups.some(categoryGroup => categoryGroup == group)));
  }
}

export interface IFormCategory {
  name: string;
  target: UntypedFormGroup;
  groups: UntypedFormGroup[];
}
