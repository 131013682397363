import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseSubscriptionComponent } from '@app/core';
import { DocumentsService } from '@app/shared/services';
import { Crumb } from '../../breadcrumb/breadcrumb.component';

@Component({
  selector: 'app-document-path',
  templateUrl: './document-path.component.html',
  styleUrls: ['./document-path.component.scss'],
})
export class DocumentPathComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() minCrumbsAtEnd: number = 2;
  @Input() asRouterLink: boolean;
  crumbs: Crumb[] = [];

  constructor(private documentsService: DocumentsService) {
    super();
  }

  get dataReady(): boolean {
    return this.documentsService.dataReady;
  }

  ngOnInit(): void {
    this.subscribe(this.documentsService.path$, path => {
      this.buildCrumbs(path);
    });
  }

  private buildCrumbs(path: string[]) {
    this.crumbs = [];

    if (path) {
      this.crumbs.push({
        icon: 'mdi-home',
        action: () => this.navigateToPath(''),
      });

      if (!path.length) this.crumbs[0].label = 'documents.title';

      const partPath = ['']; // so path starts with '/'
      for (const part of path) {
        partPath.push(part);
        const path = partPath.join('/');
        const crumb: Crumb = {
          label: part,
          action: () => this.navigateToPath(path),
        };

        if (this.asRouterLink) crumb.routerLink = path;

        this.crumbs.push(crumb);
      }
    }
  }

  private navigateToPath(path: string) {
    this.documentsService.navigateToPath(path);
  }
}
