import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { TemplateTooltipComponent } from '../components/template-tooltip/template-tooltip.component';

@Directive({
  selector: '[appTooltip]',
})
export class TemplateTooltipDirective implements OnInit, OnDestroy {
  private overlayRef: OverlayRef;

  @Input('appTooltip') template: TemplateRef<any>;

  constructor(private overlay: Overlay, private overlayBuilder: OverlayPositionBuilder, private elementRef: ElementRef) {}

  ngOnInit(): void {
    const positionStrategy = this.overlayBuilder.flexibleConnectedTo(this.elementRef).withPositions([
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: -6,
      },
    ]);

    this.overlayRef = this.overlay.create({ positionStrategy });
  }

  ngOnDestroy(): void {
    this.hideTooltip();
  }

  @HostListener('mouseenter')
  showTooltip() {
    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      const tooltipRef: ComponentRef<TemplateTooltipComponent> = this.overlayRef.attach(
        new ComponentPortal(TemplateTooltipComponent)
      );
      tooltipRef.instance.template = this.template;
    }
  }

  @HostListener('mouseleave')
  hideTooltip() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }
}
