import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PrivilegeEnum } from '@app/api';
import { ApiService } from '@app/core/services';

@Injectable()
export class AdminGuard  {
  constructor(private apiService: ApiService, private router: Router) {}

  async canActivateChild() {
    return await this.hasAdminPrivilege();
  }

  async canActivate() {
    return await this.hasAdminPrivilege();
  }

  private async hasAdminPrivilege() {
    const privileges = await this.apiService.getUserPrivileges();

    if (!privileges.includes(PrivilegeEnum.Admin)) {
      return this.router.createUrlTree(['projects']);
    }

    return true;
  }
}
