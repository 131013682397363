import { LocaleHelper } from '@bryntum/schedulerpro';
import BryntumLocaleDe from '@bryntum/schedulerpro/locales/schedulerpro.locale.De.js';

const locale = LocaleHelper.mergeLocales(BryntumLocaleDe, {
  DependencyEdit: {
    Lag: 'Zeitabstand',
  },

  EventCopyPaste: {
    copyEvent: 'Arbeitspaket kopieren',
    cutEvent: 'Arbeitspaket ausschneiden',
    pasteEvent: 'Arbeitspaket einfügen',
  },

  TaskEdit: {
    editEvent: 'Arbeitspaket bearbeiten',
  },

  NotesTab: {
    Notes: 'Beschreibung',
  },

  SchedulerGeneralTab: {
    Resources: 'Zeilen',
  },

  Column: {
    Region: 'Bereich',
    Floor: 'Bauwerksteil',
    Area: 'Bauwerk',
  },

  SchedulerExportDialog: {
    'Schedule range': 'Prozessplanbereich',
  },

  ExportDialog: {
    rows: 'Zeilenbereich',
  },

  MultiPageVerticalExporter: {
    multipagevertical: 'Alle Spalten auf einer Seite darstellen',
  },
});

export default locale;

// // This will be a truthy empty string so it can be used as a localized result.
// const emptyString = new String(); // eslint-disable-line no-new-wrappers

// const locale = {
//   localeName: 'De',
//   localeDesc: 'Deutsch',

//   //region Custom localization

//   //   App: {
//   //     'Localization demo': 'Lokalisierungs-Demo',
//   //   },

//   //endregion

//   //region Common

//   Object: {
//     Ok: 'Ok',
//     Yes: 'Ja',
//     No: 'Nein',
//     Cancel: 'Abbrechen',
//     newEvent: 'Neues Event',
//   },

//   //endregion

//   //region Shared localization

//   Button: {
//     'Add column': 'Spalte hinzufügen',
//     Apply: 'Anwenden',
//     'Display hints': 'Tipps anzeigen',
//     'Remove column': 'Spalte entfernen',
//   },

//   Checkbox: {
//     'Auto apply': 'Automatisch anwenden',
//     Automatically: 'Automatisch',
//     toggleRowSelect: 'Zeilenauswahl umschalten',
//     toggleSelection: 'Auswahl des gesamten Datensatzes umschalten',
//   },

//   CodeEditor: {
//     'Code editor': 'Code-Editor',
//     'Download code': 'Code herunterladen',
//   },

//   Combo: {
//     noResults: 'Keine Ergebnisse',
//     Theme: 'Thema wählen',
//     Language: 'Gebietsschema auswählen',
//     Size: 'Größe auswählen',
//     recordNotCommitted: 'Datensatz konnte nicht hinzugefügt werden',
//     addNewValue: value => `${value} hinzufügen`,
//   },

//   Tooltip: {
//     infoButton: 'Klicken Sie hier, um Informationen anzuzeigen und das Thema oder Gebietsschema zu wechseln',
//     codeButton: 'Klicken Sie hier, um den integrierten Code-Editor anzuzeigen',
//     hintCheck: 'Aktivieren Sie diese Option, um beim Laden des Beispiels automatisch Hinweise anzuzeigen',
//     fullscreenButton: 'Ganzer Bildschirm',
//   },

//   Shared: {
//     'Locale changed': 'Sprache geändert',
//     'Full size': 'Volle Größe',
//     'Phone size': 'Telefongröße',
//   },

//   UndoRedo: {
//     Undo: 'Rückgängig machen',
//     Redo: 'Wiederholen',
//     UndoLastAction: 'Letzte Aktion rückgängig machen',
//     RedoLastAction: 'Wiederholen Sie die letzte rückgängig gemachte Aktion',
//     NoActions: 'Keine Aktionen aufgezeichnet',
//   },

//   //endregion

//   //region SchedulerPro

//   DurationColumn: {
//     Duration: 'Dauer',
//   },

//   ConstraintTypePicker: {
//     none: 'Keiner',
//     muststarton: 'Ende nicht früher als',
//     mustfinishon: 'Ende nicht später als',
//     startnoearlierthan: 'Muss anfangen am',
//     startnolaterthan: 'Muss enden am',
//     finishnoearlierthan: 'Anfang nicht früher als',
//     finishnolaterthan: 'Anfang nicht später als',
//   },

//   CalendarField: {
//     'Default calendar': 'Standardkalender',
//   },

//   TaskEditorBase: {
//     Information: 'Informationen',
//     Save: 'Speichern',
//     Cancel: 'Abbrechen',
//     Delete: 'Löschen',
//     calculateMask: 'Aufgaben berechnen...',
//     saveError: 'Kann nicht speichern, bitte korrigieren Sie zuerst die Fehler',
//   },

//   SchedulerTaskEditor: {
//     editorWidth: '30em',
//   },

//   GanttTaskEditor: {
//     editorWidth: '54em',
//   },

//   SchedulerGeneralTab: {
//     labelWidth: '15em',
//     General: 'Generell',
//     Name: 'Name',
//     Resources: 'Bahnen',
//     '% complete': 'Abgeschlossen in Prozent',
//     Duration: 'Dauer',
//     Start: 'Start',
//     Finish: 'Ende',
//   },

//   SchedulerAdvancedTab: {
//     labelWidth: '15em',
//     Advanced: 'Fortgeschritten',
//     Calendar: 'Kalender',
//     'Manually scheduled': 'Manuell geplant',
//     'Constraint type': 'Einschränkungstyp',
//     'Constraint date': 'Datum der Einschränkung',
//   },

//   GeneralTab: {
//     labelWidth: '15em',
//     General: 'Generell',
//     Name: 'Name',
//     '% complete': 'Abgeschlossen in Prozent',
//     Duration: 'Dauer',
//     Start: 'Start',
//     Finish: 'Ende',
//     Effort: 'Anstrengung',
//     Dates: 'Datumsangaben',
//   },

//   AdvancedTab: {
//     labelWidth: '15em',
//     Advanced: 'Fortgeschritten',
//     Calendar: 'Kalender',
//     'Scheduling mode': 'Planungsmodus',
//     'Effort driven': 'Aufwandsgesteuert',
//     'Manually scheduled': 'Manuell geplant',
//     'Constraint type': 'Einschränkungstyp',
//     'Constraint date': 'Datum der Einschränkung',
//     Constraint: 'Einschränkung',
//     Rollup: 'Rollup',
//   },

//   DependencyTab: {
//     Predecessors: 'Vorgänger',
//     Successors: 'Nachfolger',
//     ID: 'ID',
//     Name: 'Name',
//     Type: 'Typ',
//     Lag: 'Verzögern',
//     'Cyclic dependency has been detected': 'Es wurde eine zyklische Abhängigkeit erkannt',
//     'Invalid dependency': 'Ungültige Abhängigkeit',
//   },

//   ResourcesTab: {
//     Resources: 'Ressourcen',
//     Resource: 'Ressource',
//     Units: 'Einheiten',
//     unitsTpl: function (value) {
//       return value.value ? value.value + '%' : '';
//     },
//   },

//   NotesTab: {
//     Notes: 'Notizen',
//   },

//   SchedulingModePicker: {
//     Normal: 'Normal',
//     'Fixed Duration': 'Fixierte Dauer',
//     'Fixed Units': 'Fixierte Einheiten',
//     'Fixed Effort': 'Fixierter Aufwand',
//   },

//   ResourceHistogram: {
//     barTipInRange: '<b>{resource}</b> {startDate} - {endDate}<br>{allocated} von {available} verfügbar',
//     barTipOnDate: '<b>{resource}</b> am {startDate}<br>{allocated} von {available} verfügbar',
//     groupBarTipAssignment: '<b>{resource}</b> - <span class="{cls}">{allocated} von {available}</span>',
//     groupBarTipInRange:
//       '{startDate} - {endDate}<br><span class="{cls}">{allocated} von {available}</span> vergeben:<br>{assignments}',
//     groupBarTipOnDate: 'Am {startDate}<br><span class="{cls}">{allocated} von {available}</span> vergeben:<br>{assignments}',
//     plusMore: '+{value} mehr',
//   },

//   ResourceUtilization: {
//     barTipInRange: '<b>{event}</b> {startDate} - {endDate}<br>{allocated} von {available} verfügbar',
//     barTipOnDate: '<b>{event}</b> am {startDate}<br>{allocated} von {available} verfügbar',
//     groupBarTipAssignment: '<b>{event}</b> - <span class="{cls}">{allocated} von {available}</span>',
//     groupBarTipInRange:
//       '{startDate} - {endDate}<br><span class="{cls}">{allocated} von {available}</span> vergeben:<br>{assignments}',
//     groupBarTipOnDate: 'Am {startDate}<br><span class="{cls}">{allocated} von {available}</span> vergeben:<br>{assignments}',
//     plusMore: '+{value} mehr',
//   },

//   SchedulerProBase: {
//     propagating: 'Berechnen',
//     storePopulation: 'Daten geladen',
//     finalizing: 'Finalisieren',
//   },

//   //endregion

//   //region Features

//   ColumnPicker: {
//     column: 'Splate',
//     columnsMenu: 'Spalten Menü',
//     hideColumn: 'Spalte verstecken',
//     hideColumnShort: 'Verstecken',
//     newColumns: 'Neue Spalten',
//   },

//   Filter: {
//     applyFilter: 'Filter anwenden',
//     filter: 'Filter',
//     editFilter: 'Filter bearbeiten',
//     on: 'Auf',
//     before: 'Vor',
//     after: 'Nach',
//     equals: 'Gleichen',
//     lessThan: 'Weniger als',
//     moreThan: 'Mehr als',
//     removeFilter: 'Filter entfernen',
//   },

//   FilterBar: {
//     enableFilterBar: 'Filterleiste anzeigen',
//     disableFilterBar: 'Filterleiste ausblenden',
//   },

//   Group: {
//     group: 'Gruppe',
//     groupAscending: 'Aufsteigend gruppieren',
//     groupDescending: 'Absteigend gruppieren',
//     groupAscendingShort: 'Aufsteigend',
//     groupDescendingShort: 'Absteigend',
//     stopGrouping: 'Gruppierung auflösen',
//     stopGroupingShort: 'Auflösen',
//   },

//   HeaderMenu: {
//     moveBefore: text => `Verschieben Sie vor "${text}"`,
//     moveAfter: text => `Verschieben Sie nach "${text}"`,
//   },

//   MergeCells: {
//     mergeCells: 'Zellen zusammenführen',
//     menuTooltip: 'Zellen mit gleichem Inhalt zusammenführen, wenn nach dieser Spalte sortiert',
//   },

//   Search: {
//     searchForValue: 'Suche nach Wert',
//   },

//   Sort: {
//     sort: 'Sorte',
//     sortAscending: 'Aufsteigend sortieren',
//     sortDescending: 'Absteigend sortieren',
//     multiSort: 'Multi sortieren',
//     removeSorter: 'Sortierung entfernen',
//     addSortAscending: 'Aufsteigende Sortierung hinzufügen',
//     addSortDescending: 'Absteigende Sortierung hinzufügen',
//     toggleSortAscending: 'Zu aufsteigender Sortierung wechseln',
//     toggleSortDescending: 'Zu absteigender Sortierung wechseln',
//     sortAscendingShort: 'Aufsteigend',
//     sortDescendingShort: 'Absteigend',
//     removeSorterShort: 'Entfernen',
//     addSortAscendingShort: '+ Aufsteigend',
//     addSortDescendingShort: '+ Absteigend',
//   },

//   //endregion

//   //region Trial

//   TrialButton: {
//     downloadTrial: 'Demo herunterladen',
//   },

//   TrialPanel: {
//     title: 'Bitte Felder ausfüllen',
//     name: 'Name',
//     email: 'Email',
//     company: 'Firma',
//     product: 'Produkt',
//     cancel: 'Abbrechen',
//     submit: 'Einreichen',
//     downloadStarting: 'Download startet, bitte warten...',
//   },

//   //endregion

//   //region Grid

//   RatingColumn: {
//     cellLabel: column =>
//       `${column.text} ${column.location?.record ? `Bewertung : ${column.location.record[column.field]}` : ''}`,
//   },

//   GridBase: {
//     loadFailedMessage: 'Wird geladen, bitte versuche es erneut!',
//     syncFailedMessage: 'Datensynchronisation fehlgeschlagen!',
//     unspecifiedFailure: 'Nicht spezifizierter Fehler',
//     networkFailure: 'Netzwerkfehler',
//     parseFailure: 'Serverantwort konnte nicht analysiert werden',
//     serverResponse: 'Serverantwort:',
//     loadMask: 'Laden...',
//     syncMask: 'Speichere Änderungen, bitte warten...',
//     noRows: 'Keine Zeilen zum Anzeigen',
//     moveColumnLeft: 'Spalte nach links bewegen',
//     moveColumnRight: 'Spalte nach rechts bewegen',
//     moveColumnTo: function (region) {
//       return 'Spalte verschieben nach ' + region;
//     },
//   },

//   CellMenu: {
//     removeRow: 'Löschen',
//   },

//   RowExpander: {
//     loading: 'Wird geladen',
//   },

//   //endregion

//   //region Widgets

//   FilePicker: {
//     file: 'Datei',
//   },

//   Field: {
//     badInput: 'Ungültiger Feldwert',
//     patternMismatch: 'Der Wert sollte einem bestimmten Muster entsprechen',
//     rangeOverflow: function (value) {
//       return 'Der Wert muss größer als oder gleich ' + value.max + ' sein';
//     },
//     rangeUnderflow: function (value) {
//       return 'Der Wert muss größer als oder gleich ' + value.min + ' sein';
//     },
//     stepMismatch: 'Der Wert sollte zum Schritt passen',
//     tooLong: 'Der Wert sollte kürzer sein',
//     tooShort: 'Der Wert sollte länger sein',
//     typeMismatch: 'Der Wert muss in einem speziellen Format vorliegen',
//     valueMissing: 'Dieses Feld wird benötigt',

//     fieldRequired: 'Dieses Feld wird benötigt',
//     invalidValue: 'Ungültiger Feldwert',
//     maximumValueViolation: 'Maximalwertverletzung',
//     minimumValueViolation: 'Mindestwertverletzung',
//     validateFilter: 'Der Wert muss aus der Liste ausgewählt werden',
//   },

//   DateField: {
//     invalidDate: 'Ungültige Datumseingabe',
//   },

//   DatePicker: {
//     gotoPrevYear: 'Zum Vorjahr',
//     gotoPrevMonth: 'Zum vorherigen Monat',
//     gotoNextMonth: 'Zum nächsten Monat',
//     gotoNextYear: 'Zum nächsten Jahr',
//   },

//   NumberFormat: {
//     locale: 'de',
//     currency: 'EUR',
//   },

//   DurationField: {
//     invalidUnit: 'Ungültige Einheit',
//   },

//   TimeField: {
//     invalidTime: 'Ungültige Zeitangabe',
//   },

//   TimePicker: {
//     hour: 'Stunde',
//     minute: 'Minute',
//   },

//   //endregion

//   //region Others

//   DateHelper: {
//     locale: 'de',
//     weekStartDay: 1,
//     // Non-working days which match weekends by default, but can be changed according to schedule needs
//     nonWorkingDays: {
//       0: true,
//       6: true,
//     },
//     // Days considered as weekends by the selected country, but could be working days in the schedule
//     weekends: {
//       0: true,
//       6: true,
//     },
//     unitNames: [
//       { single: 'Millisekunde', plural: 'Millisekunden', abbrev: 'ms' },
//       { single: 'Sekunde', plural: 'Sekunden', abbrev: 's' },
//       { single: 'Minute', plural: 'Minuten', abbrev: 'min' },
//       { single: 'Stunde', plural: 'Stunden', abbrev: 'std' },
//       { single: 'Tag', plural: 'Tage', abbrev: 'T' },
//       { single: 'Woche', plural: 'Wochen', abbrev: 'W' },
//       { single: 'Monat', plural: 'Monate', abbrev: 'Mon' },
//       { single: 'Quartal', plural: 'Quartal', abbrev: 'Qt' },
//       { single: 'Jahr', plural: 'Jahre', abbrev: 'J' },
//       { single: 'Jahrzehnt', plural: 'Jahrzehnte', abbrev: 'Jz' },
//     ],
//     // Used to build a RegExp for parsing time units.
//     // The full names from above are added into the generated Regexp.
//     // So you may type "2 w" or "2 wk" or "2 week" or "2 weeks" into a DurationField.
//     // When generating its display value though, it uses the full localized names above.
//     unitAbbreviations: [
//       ['mil'],
//       ['s', 'sec'],
//       ['m', 'min'],
//       ['h', 'hr'],
//       ['d'],
//       ['w', 'wk'],
//       ['mo', 'mon', 'mnt'],
//       ['q', 'quar', 'qrt'],
//       ['y', 'yr'],
//       ['dek'],
//     ],
//     parsers: {
//       L: 'DD.MM.YYYY',
//       LT: 'HH:mm',
//     },
//     ordinalSuffix: function (number) {
//       return number;
//     },
//   },

//   PagingToolbar: {
//     firstPage: 'Zur ersten Seite',
//     prevPage: 'Zur letzten Seite',
//     page: 'Seite',
//     nextPage: 'Zur nächsten Seite',
//     lastPage: 'Zur letzten Seite',
//     reload: 'Aktuelle Seite neu laden',
//     noRecords: 'Keine Zeilen zum Anzeigen',
//     pageCountTemplate: function (data) {
//       return 'von ' + data.lastPage;
//     },
//     summaryTemplate: function (data) {
//       return 'Ergebnisse ' + data.start + ' - ' + data.end + ' von ' + data.allCount;
//     },
//   },

//   PanelCollapser: {
//     Collapse: 'Einklappen',
//     Expand: 'Erweitern',
//   },

//   Popup: {
//     close: 'Popup schließen',
//   },

//   List: {
//     loading: 'Wird geladen...',
//   },

//   //region Export

//   PdfExport: {
//     'Waiting for response from server': 'Warten auf Antwort vom Server...',
//     'Export failed': 'Export fehlgeschlagen',
//     'Server error': 'Serverfehler',
//     'Generating pages': 'Seiten generieren ...',
//     'Click to abort': 'Abbrechen',
//   },

//   ExportDialog: {
//     width: '40em',
//     labelWidth: '12em',
//     exportSettings: 'Exporteinstellungen',
//     export: 'Export',
//     exporterType: 'Kontrolliere die Paginierung',
//     cancel: 'Abbrechen',
//     fileFormat: 'Datei Format',
//     rows: 'Reihen',
//     alignRows: 'Zeilen ausrichten',
//     columns: 'Spalten',
//     paperFormat: 'Papierformat',
//     orientation: 'Orientierung',
//     repeatHeader: 'Kopfzeile wiederholen',
//   },

//   ExportRowsCombo: {
//     all: 'Alle Zeilen',
//     visible: 'Sichtbare Zeilen',
//   },

//   ExportOrientationCombo: {
//     portrait: 'Porträt',
//     landscape: 'Landschaft',
//   },

//   EventCopyPaste: {
//     copyEvent: 'Kopieren',
//     cutEvent: 'Ausschneiden',
//     pasteEvent: 'Einfügen',
//   },

//   SinglePageExporter: {
//     singlepage: 'Einzelne Seite',
//   },

//   MultiPageExporter: {
//     multipage: 'Mehrere Seiten',
//     exportingPage: function (data) {
//       return 'Seite exportieren ' + data.currentPage + '/' + data.totalPages;
//     },
//   },

//   MultiPageVerticalExporter: {
//     multipagevertical: 'Mehrere Seiten (vertikal)',
//     exportingPage: function (data) {
//       return 'Seite exportieren ' + data.currentPage + '/' + data.totalPages;
//     },
//   },

//   //endregion

//   //endregion

//   //region Scheduler

//   ResourceInfoColumn: {
//     eventCountText: function (data) {
//       return data + ' Veranstaltung' + (data !== 1 ? 'en' : '');
//     },
//   },

//   Dependencies: {
//     from: 'Von',
//     to: 'Nach',
//     valid: 'Gültig',
//     invalid: 'Ungültig',
//   },

//   DependencyType: {
//     SS: 'AA',
//     SF: 'EA',
//     FS: 'AE',
//     FF: 'EE',
//     StartToStart: 'Anfang-Anfang',
//     StartToEnd: 'Anfang-Ende',
//     EndToStart: 'Enge-Anfang',
//     EndToEnd: 'Enge-Ende',
//     short: ['AA', 'EA', 'AE', 'EE'],
//     long: ['Anfang-Anfang', 'Anfang-Ende', 'Enge-Anfang', 'Enge-Ende'],
//   },

//   DependencyEdit: {
//     From: 'Von',
//     To: 'Zu',
//     Type: 'Typ',
//     Lag: 'Abstand',
//     'Edit dependency': 'Abhängigkeit bearbeiten',
//     Save: 'Speichern',
//     Delete: 'Löschen',
//     Cancel: 'Abbrechen',
//     StartToStart: 'Anfang-Anfang',
//     StartToEnd: 'Anfang-Ende',
//     EndToStart: 'Ende-Anfang',
//     EndToEnd: 'Ende-Ende',
//   },

//   EventEdit: {
//     Name: 'Name',
//     Resource: 'Ressource',
//     Start: 'Start',
//     End: 'Ende',
//     Save: 'Speichern',
//     Delete: 'Löschen',
//     Cancel: 'Abbrechen',
//     'Edit event': 'Bearbeiten',
//     Repeat: 'Wiederholen',
//   },

//   TaskEdit: {
//     'Edit task': 'Aufgabe bearbeiten',
//     ConfirmDeletionTitle: 'Löschung bestätigen',
//     ConfirmDeletionMessage: 'Möchten Sie das Ereignis wirklich löschen?',
//   },

//   EventDrag: {
//     eventOverlapsExisting: 'Ereignis überlappt vorhandenes Ereignis für diese Ressource',
//     noDropOutsideTimeline: 'Event wird möglicherweise nicht vollständig außerhalb der Timeline gelöscht',
//   },

//   SchedulerBase: {
//     'Add event': 'Ereignis hinzufügen',
//     'Delete event': 'Ereignis löschen',
//     'Unassign event': 'Zuordnung von Ereignis aufheben',
//   },

//   TimeAxisHeaderMenu: {
//     pickZoomLevel: 'Zoomen',
//     activeDateRange: 'Datumsbereich',
//     startText: 'Anfangsdatum',
//     endText: 'Endtermin',
//     todayText: 'Heute',
//   },

//   EventFilter: {
//     filterEvents: 'Aufgaben filtern',
//     byName: 'Namentlich',
//   },

//   TimeRanges: {
//     showCurrentTimeLine: 'Aktuelle Zeitleiste anzeigen',
//   },

//   PresetManager: {
//     minuteAndHour: {
//       topDateFormat: 'ddd DD.MM, HH:mm',
//     },
//     hourAndDay: {
//       topDateFormat: 'ddd DD.MM',
//     },
//     weekAndDay: {
//       displayDateFormat: 'HH:mm',
//     },
//   },

//   RecurrenceConfirmationPopup: {
//     'delete-title': 'Ereignis löschen',
//     'delete-all-message': 'Möchten Sie alle Vorkommnisse dieses Ereignisses löschen?',
//     'delete-further-message':
//       'Möchten Sie dieses und alle zukünftigen Vorkommnisse dieses Ereignisses oder nur das ausgewählte Vorkommen löschen?',
//     'delete-further-btn-text': 'Alle zukünftigen Ereignisse löschen',
//     'delete-only-this-btn-text': 'Nur dieses Ereignis löschen',

//     'update-title': 'Ereignis bearbeiten',
//     'update-all-message': 'Möchten Sie alle Vorkommnisse dieses Ereignisses ändern?',
//     'update-further-message':
//       'Möchten Sie dieses und alle zukünftigen Vorkommnisse dieses Ereignisses oder nur das ausgewählte Vorkommen ändern?',
//     'update-further-btn-text': 'Alle zukünftigen Ereignisse ändern',
//     'update-only-this-btn-text': 'Nur dieses Ereignis ändern',

//     Yes: 'Ja',
//     Cancel: 'Abbrechen',

//     width: 600,
//   },

//   RecurrenceLegend: {
//     // list delimiters
//     ' and ': ' und ',
//     // frequency patterns
//     Daily: 'Täglich',
//     'Weekly on {1}': function (data) {
//       return 'Wöchentlich am ' + data.days;
//     },
//     'Monthly on {1}': function (data) {
//       return 'Monatlich am ' + data.days;
//     },
//     'Yearly on {1} of {2}': function (data) {
//       return 'Jährlich am ' + data.days + ' von ' + data.months;
//     },
//     'Every {0} days': function (data) {
//       return 'Alle ' + data.interval + ' Tage';
//     },
//     'Every {0} weeks on {1}': function (data) {
//       return 'Alle ' + data.interval + ' Wochen am ' + data.days;
//     },
//     'Every {0} months on {1}': function (data) {
//       return 'Alle ' + data.interval + ' Monate auf ' + data.days;
//     },
//     'Every {0} years on {1} of {2}': function (data) {
//       return 'Alle ' + data.interval + ' Jahre auf ' + data.days + ' von ' + data.months;
//     },
//     // day position translations
//     position1: 'ersten',
//     position2: 'zweiten',
//     position3: 'dritten',
//     position4: 'vierten',
//     position5: 'fünften',
//     'position-1': 'letzten',
//     // day options
//     day: 'Tag',
//     weekday: 'Wochentag',
//     'weekend day': 'Wochenend-Tag',
//     // {0} - day position info ("the last"/"the first"/...)
//     // {1} - day info ("Sunday"/"Monday"/.../"day"/"weekday"/"weekend day")
//     // For example:
//     //  "the last Sunday"
//     //  "the first weekday"
//     //  "the second weekend day"
//     daysFormat: function (data) {
//       return data.position + ' ' + data.days;
//     },
//   },

//   RecurrenceEditor: {
//     'Repeat event': 'Ereignis wiederholen',
//     Cancel: 'Abbrechen',
//     Save: 'Speichern',
//     Frequency: 'Häufigkeit',
//     Every: 'Jede(n/r)',
//     DAILYintervalUnit: 'Tag',
//     WEEKLYintervalUnit: 'Woche',
//     MONTHLYintervalUnit: 'Monat',
//     YEARLYintervalUnit: 'Jahr',
//     Each: 'Jeder',
//     'On the': 'Am',
//     'End repeat': 'Ende',
//     'time(s)': 'Zeit',
//   },

//   RecurrenceDaysCombo: {
//     day: 'Tag',
//     weekday: 'Wochentag',
//     'weekend day': 'Wochenend-Tag',
//   },

//   RecurrencePositionsCombo: {
//     position1: 'ersten',
//     position2: 'zweiten',
//     position3: 'dritten',
//     position4: 'vierten',
//     position5: 'fünften',
//     'position-1': 'letzten',
//   },

//   RecurrenceStopConditionCombo: {
//     Never: 'Niemals',
//     After: 'Nach',
//     'On date': 'Am Tag',
//   },

//   RecurrenceFrequencyCombo: {
//     Daily: 'täglich',
//     Weekly: 'wöchentlich',
//     Monthly: 'monatlich',
//     Yearly: 'jährlich',
//   },

//   RecurrenceCombo: {
//     None: 'Nie',
//     Custom: 'Benutzerdefiniert...',
//   },

//   Summary: {
//     'Summary for': function (date) {
//       return 'Zusammenfassung für ' + date;
//     },
//   },

//   RowCopyPaste: {
//     copyRecord: 'Kopieren',
//     cutRecord: 'Ausschneiden',
//     pasteRecord: 'Einfügen',
//   },

//   //endregion

//   //region SchedulerPro

//   EventSegments: {
//     splitEvent: 'Ereignis teilen',
//     renameSegment: 'Umbenennen',
//   },

//   NestedEvents: {
//     deNestingNotAllowed: 'Ausbettung nicht erlaubt',
//     nestingNotAllowed: 'Einbettung nicht erlaubt',
//   },

//   //endregion

//   //region Export

//   ScheduleRangeCombo: {
//     completeview: 'Vollständiger Zeitplan',
//     currentview: 'Sichtbarer Zeitplan',
//     daterange: 'Datumsbereich',
//     completedata: 'Vollständiger Zeitplan (für alle Veranstaltungen)',
//   },

//   SchedulerExportDialog: {
//     'Schedule range': 'Zeitplanbereich ',
//     'Export from': 'Von',
//     'Export to': 'Zu',
//   },

//   ExcelExporter: {
//     'No resource assigned': 'Keine Ressource zugewiesen',
//   },

//   //endregion

//   //region Examples

//   Column: {
//     columnLabel: column =>
//       `${column.text ? `${column.text} spalte. ` : ''}SPACE für Kontextmenü${column.sortable ? ', ENTER zum Sortieren' : ''}`,
//     cellLabel: emptyString,
//     Name: 'Name',
//     Age: 'Alter',
//     City: 'Stadt',
//     Food: 'Essen',
//     Color: 'Farbe',
//     'First name': 'Vorname',
//     Surname: 'Nachname',
//     Team: 'Team',
//     Score: 'Ergebnis',
//     Rank: 'Rang',
//     Percent: 'Prozent',
//     Birthplace: 'Geburstort',
//     Start: 'Anfang',
//     Finish: 'Ende',
//     Template: 'Vorlage',
//     Date: 'Datum',
//     Check: 'Check',
//     Contact: 'Kontakt',
//     Favorites: 'Favoriten',
//     'Customer#': 'Kunde#',
//     When: 'Wann',
//     Brand: 'Marke',
//     Model: 'Modell',
//     'Personal best': 'Persönlicher rekord',
//     'Current rank': 'Aktueller rang',
//     Hometown: 'Heimatstadt',
//     Satisfaction: 'Zufriedenheit',
//     'Favorite color': 'Lieblingsfarbe',
//     Rating: 'Wertung',
//     Cooks: 'Zubereiten',
//     Birthday: 'Geburstag',
//     Staff: 'Personal',
//     Machines: 'Maschinen',
//     Type: 'Typ',
//     'Task color': 'Aufgabe farbe',
//     'Employment type': 'Beschäftigungsverhältnis',
//     Capacity: 'Kapazität',
//     'Production line': 'Fließband',
//     Company: 'Firma',
//     End: 'Ende',
//   },

//   //endregion

//   CrudManagerView: {
//     serverResponseLabel: 'Serverantwort:',
//   },
// };

// export default locale;
