import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: 'input',
})
export class NoAutocompleteDirective {
  constructor(private element: ElementRef) {
    element.nativeElement.setAttribute('autocomplete', 'off');
  }
}
