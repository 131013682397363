import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-multi-key-value-input',
  templateUrl: './multi-key-value-input.component.html',
  styleUrls: ['./multi-key-value-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultiKeyValueInputComponent,
    },
  ],
})
export class MultiKeyValueInputComponent {
  @Input() title: string;
  @Input() tooltip: string;
  @Input() noDataText: string;
  @Input() keyLabel: string = 'general.key';
  @Input() valueLabel: string = 'general.value';
  @Input() array: UntypedFormArray;

  @Output() addClicked = new EventEmitter<void>();

  constructor(private formBuilder: UntypedFormBuilder) {}

  addKeyValuePair() {
    this.addClicked.emit();
  }

  removeKeyValuePair(i: number) {
    this.array.removeAt(i);
  }

  getError(control: UntypedFormControl) {
    return Object.keys(control.errors)[0];
  }
}
