<form class="config-content-container defect-config" [formGroup]="form">
	<div class="config-content content-sticky">
		<div class="config-group slim gap">
			<div class="config-title">{{ 'defects.title' | translate }}</div>

			<app-toggle-field formControlName="deadlineNotifications"
												[label]="'projectConfig.defects.mailNotification.caption' | translate"
												[description]="'projectConfig.defects.mailNotification.description' | translate">
			</app-toggle-field>
		</div>
	</div>

	<div *ngIf="canProvision" class="config-content">
		<div class="config-group gap">
			<div class="config-title sticky with-action">
				<div class="config-title sticky">{{ 'projectConfig.defects.states' | translate }}</div>
				<button class="sticky-right" mat-icon-button (click)="stateSchemaService.addOrEditState()"
								[disabled]="states.disabled">
					<span class="mdi mdi-plus-box"></span>
				</button>
			</div>

			<div *ngIf="stateSchemaService.hasStates" class="table-grid-component-container">
				<app-state-matrix class="table-grid-component" [service]="stateSchemaService"></app-state-matrix>
			</div>
		</div>
	</div>
</form>