import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { FormComponent } from '@app/core/utils/form-component';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
})
export class SearchbarComponent extends FormComponent {
  @Input() emitOnInput: boolean = false;
  @Input() placeholder: string = 'documents.filterPlaceholder';

  @Input() set disabled(isDisabled: boolean) {
    if (isDisabled) this.form.disable();
    else this.form.enable();
  }

  @Input() set searchText(value: string | null) {
    this.f.searchText.patchValue(value ?? '');
    this.lastValue = value ?? '';
  }

  private _invalidChars: string[] = [];
  get invalidChars(): string[] {
    return this._invalidChars;
  }
  @Input() set invalidChars(invalidChars: string[]) {
    this._invalidChars = invalidChars ?? [];

    const validators = [];
    if (invalidChars != null) {
      let escapedChars = invalidChars.select(c => c.replace('\\', '\\\\'));
      let validatorPattern: RegExp = new RegExp(`^[^${escapedChars.join('')}]+$`);
      validators.push(Validators.pattern(validatorPattern));
    }

    this.f.searchText.setValidators(validators);
    this.f.searchText.updateValueAndValidity();
  }

  @Output() searchTextChange = new EventEmitter<string>();

  isExpanded = false;

  private lastValue: string;

  constructor(private formBuilder: UntypedFormBuilder) {
    super();

    this.form = this.formBuilder.group({
      searchText: [''],
    });
  }

  searchTextInput() {
    if (this.emitOnInput) this.searchTextChanged();
  }

  searchTextChanged() {
    if (this.f.searchText.valid && this.f.searchText.value != this.lastValue) {
      this.lastValue = this.f.searchText.value;
      this.searchTextChange.emit(this.f.searchText.value);
    }
  }

  searchTextBlur() {
    this.isExpanded = false;
    this.searchTextChanged();
  }

  resetFilter() {
    if (!this.f.searchText) return;
    this.f.searchText.patchValue('');
    this.lastValue = '';
    this.searchTextChange.emit('');
  }
}
