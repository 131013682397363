<div class="history-content-root" [class.history-mobile]="globals.isMobileMenu">
	<div class="history-container" *ngIf="hasChanges">
		<ng-container *ngIf="sortedStateChanges?.length > 0">
			<div class="history-header">{{'history.stateDiagramTitle' | translate}}</div>
			<div class="history-arrow-container">
				<div class="flow-list">
					<ng-container *ngFor="let stateChange of sortedStateChanges">
						<div class="history-seperator arrow-head c4-phone-hidden">
							<div class="history-date" [matTooltip]="stateChange.date | localizedDate:'short'">
								{{ stateChange.date | localizedDate }}
							</div>
						</div>
						<div class="history-block">
							<div class="history-state">
								<ng-container [ngSwitch]="stateChange.type">
									<ng-container *ngSwitchCase="HistoryStateTemplateType.color">
										<app-state-circle *ngIf="stateChange.color; else stateCircleDefault"
																			[hexColor]="stateChange.color"></app-state-circle>
										<ng-template #stateCircleDefault>
											<span class="color mdi mdi-checkbox-blank-circle" [class]="stateChange.iconClass"></span>
										</ng-template>
									</ng-container>
									<span *ngSwitchCase="HistoryStateTemplateType.icon" class="mdi"
												[class]="stateChange.iconClass"></span>
								</ng-container>

								<span>{{ stateChange.labelKey | translate }}</span>
							</div>

							<div class="history-username">
								<span>{{ stateChange.username }}</span>
							</div>

							<div class="history-username c4-phone-only" [matTooltip]="stateChange.date | localizedDate:'short'">
								{{ stateChange.date | localizedDate }}
							</div>
						</div>
						<div class="arrow-head c4-phone-only"></div>
					</ng-container>
				</div>
			</div>
		</ng-container>
		<div class="history-header">{{'history.title' | translate}}</div>
		<div class="history-content-container" [class.history-mobile]="globals.isMobileMenu">
			<div class="history-list-container" [ngClass]="showMobileDetails == true ? 'mobile-detail' : ''">
				<ng-template [ngTemplateOutlet]="extensionList" [ngTemplateOutletContext]="{
					list: changesToday, title: 'general.date.today' | translate}"></ng-template>
				<ng-template [ngTemplateOutlet]="extensionList" [ngTemplateOutletContext]="{
						list: changesAWeekAgo, title: 'general.date.lastSevenDays' | translate}"></ng-template>
				<ng-template [ngTemplateOutlet]="extensionList" [ngTemplateOutletContext]="{
							list: changesAMonthAgo, title: 'general.date.lastThirtyDays' | translate}"></ng-template>
				<ng-template [ngTemplateOutlet]="extensionList" [ngTemplateOutletContext]="{
					list: changesOlder, title: 'general.date.older' | translate}"></ng-template>
			</div>
			<div class="history-detail-container" *ngIf="selectedListItem"
					 [ngClass]="showMobileDetails == true ? 'mobile-detail' : ''">
				<div class="history-detail-header">
					<div class="history-detail-header-left">
						<button mat-icon-button class="history-back-icon mdi mdi-chevron-left" *ngIf="showMobileDetails"
										(click)="showMobileDetails = false">
						</button>
						<app-user-image class="history-user-image" [userId]="selectedListItem.createdById"
														[sizeRem]="globals.isMobileMenu ? 4.8 : 3.5">
						</app-user-image>
						<span class="history-detail-user" *ngIf="selectedListItem.createdBy">{{selectedListItem.createdBy}}</span>
						<span class="history-detail-user" *ngIf="!selectedListItem.createdBy">{{'history.userUndefined' |
							translate}}</span>
					</div>
					<div class="history-detail-header-right">
						<span class="history-detail-date">{{ selectedListItem.createdOn | localizedDate:'short' }}</span>
					</div>
				</div>
				<hr class="history-header-line">
				<div class="history-detail-list-container">
					<div class="grid">
						<ng-container *ngFor="let item of selectedListItem.changes">
							<div class="grid-history-state">
								{{ item.fieldName | translate }}
							</div>
							<div class="grid-history-value-container">
								<div class="grid-history-value" *ngIf="item.newValue">
									<ng-container [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{
										type: item.type,
										value: item.newValue
									}"></ng-container>
								</div>
								<div class="grid-history-value old" *ngIf="item.oldValue">
									<ng-container [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{
										type: item.type,
										value: item.oldValue
									}"></ng-container>
								</div>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="history-container-empty" *ngIf="!hasChanges">
		<div class="history-header">{{'history.title' | translate}}</div>
		<div class="history-empty-text">
			<span>
				{{ 'general.noDataText' | translate }}
			</span>
		</div>
	</div>
</div>

<ng-template #valueTemplate let-type="type" let-value="value">
	<ng-container [ngSwitch]="type">
		<ng-container *ngSwitchCase="HistoryChangeType.date">{{ value | localizedDate }}</ng-container>
		<ng-container *ngSwitchCase="HistoryChangeType.translate">{{ value | translate }}</ng-container>
		<ng-container *ngSwitchCase="HistoryChangeType.bool">
			<mat-checkbox [checked]="value.toLowerCase() == 'true'" disabled>
			</mat-checkbox>
		</ng-container>
		<ng-container *ngSwitchCase="HistoryChangeType.state_translate">{{ value?.Title | translate }}</ng-container>
		<ng-container *ngSwitchCase="HistoryChangeType.state">{{ value?.Title }}</ng-container>
		<ng-container *ngSwitchDefault>{{ value }}</ng-container>
	</ng-container>
</ng-template>

<ng-template #extensionList let-list="list" let-title="title">
	<mat-expansion-panel *ngIf="list && list.length > 0" [expanded]="true" [togglePosition]="'before'">
		<mat-expansion-panel-header>
			<mat-panel-title>
				{{title}}
			</mat-panel-title>
		</mat-expansion-panel-header>
		<div class="history-list" *ngFor="let item of list" [ngClass]="item == selectedListItem ? 'selected' : ''">
			<div class="history-list-item"
					 (click)="selectedListItem = item; globals.isMobileMenu == true ? showMobileDetails = true : null">
				<app-user-image class="history-user-image" [userId]="item.createdById"
												[sizeRem]="globals.isMobileMenu ? 4.8 : 3.5">
				</app-user-image>
				<div class="history-list-item-info">
					<div class="history-list-item-createdby" *ngIf="item.createdBy">
						{{item.createdBy}}
					</div>
					<div class="history-list-item-createdby" *ngIf="!item.createdBy">
						{{'history.userUndefined' | translate}}
					</div>
					<div class="history-list-item-end">
						<div class="history-list-item-createdon">
							{{item.createdOn | localizedDate:'relative' }}
						</div>
					</div>
				</div>
			</div>
		</div>
	</mat-expansion-panel>
</ng-template>