import { Component, ElementRef, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  ChannelType,
  IProjectConfigResourceTab,
  ModuleType,
  MSTeamsResource,
  DocumentResource,
  ResourceTemplateType,
} from '@app/api';
import {
  PcfChannel,
  PcfChannelTab,
  PcfDirectory,
  ProjectConfigService,
  PcfStructure,
} from '@app/shared/services/project-config';
import { TranslateService } from '@ngx-translate/core';
import { TreeNode } from 'primeng/api';
import { Tree } from 'primeng/tree';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { StructureNode } from './structure-node';
import { UserNotificationService } from '@app/shared/services';
import { RadioGroupOption } from '../../radio-group/radio-group.component';
import { FormUtils, GlobalsService, Utils } from '@app/core';
import { EditTabDialogComponent, EditTabDialogResult } from './edit-tab-dialog/edit-tab-dialog.component';
import { IndividualPrivilegeService } from '../../privilege-matrix/individual-privilege.service';

type StructureTreeNode = TreeNode<UntypedFormGroup | ModuleType>;

@Component({
  selector: 'app-structure-config',
  templateUrl: './structure-config.component.html',
  styleUrls: ['./structure-config.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StructureConfigComponent implements OnDestroy, OnInit {
  @ViewChild(Tree) tree: Tree;
  @ViewChild('pathInput') pathInput: ElementRef<HTMLInputElement>;
  @ViewChild('individualPrivilegesContainer') individualPrivilegesContainer: ElementRef<HTMLDivElement>;
  public hideTree = false;
  ModuleType = ModuleType;
  ChannelType = ChannelType;
  // channelTypeOptions: IconToggleOption<ChannelType>[] = [
  //   {
  //     value: ChannelType.Channel,
  //     icon: 'mdi-eye-outline',
  //     activeLabel: 'projectConfig.structure.channelType.channel',
  //     activateLabel: 'projectConfig.structure.channelType.show',
  //   },
  //   {
  //     value: ChannelType.Hidden,
  //     icon: 'mdi-eye-off-outline',
  //     activeLabel: 'projectConfig.structure.channelType.hidden',
  //     activateLabel: 'projectConfig.structure.channelType.hide',
  //   },
  // ];
  channelFolderOptions: RadioGroupOption[] = Object.keys(ResourceTemplateType).map(type => ({
    key: ResourceTemplateType[type],
    title: 'projectConfig.structure.channelTemplateType.' + type,
    description: 'projectConfig.structure.channelTemplateTypeDescription.' + type,
  }));

  systemRoleMappings: Record<string, boolean> = {};

  nodes: StructureTreeNode[] = [];
  selectedNode: StructureNode = null;

  constructor(
    private configService: ProjectConfigService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private userNotification: UserNotificationService,
    public globals: GlobalsService
  ) {}

  get form() {
    return this.configService.structureForm;
  }

  get channelHeadNodeType() {
    return 'channelHead';
  }

  get isTemplate(): boolean {
    return this.configService.isTemplate;
  }

  ngOnInit(): void {
    this.configService.initialized$.subscribe(isInitialized => {
      if (!isInitialized) return;

      this.treeNodeKey = 0;
      this.systemRoleMappings = this.configService.getCurrentRoles().reduce((mappings, role) => {
        mappings[role.id] = role.isSystemRole;
        return mappings;
      }, {});

      const msTeamsChannels = this.getChannelsForm(ModuleType.MSTeams).controls as UntypedFormGroup[];
      const portalChannels = this.getChannelsForm(ModuleType.Document).controls as UntypedFormGroup[];

      const channelHeadClass = 'channel-head';
      this.nodes = [
        {
          key: `${++this.treeNodeKey}`,
          data: ModuleType.Document,
          type: this.channelHeadNodeType,
          label: this.translate.instant('projectConfig.structure.portalChannelHead'),
          children: this.buildTreeRecursive(portalChannels),
          styleClass: channelHeadClass,
          expanded: true,
          selectable: false,
          draggable: false,
        },
        {
          key: `${++this.treeNodeKey}`,
          data: ModuleType.MSTeams,
          type: this.channelHeadNodeType,
          label: this.translate.instant('projectConfig.structure.teamsChannelHead'),
          children: this.buildTreeRecursive(msTeamsChannels),
          styleClass: channelHeadClass,
          expanded: true,
          selectable: false,
          draggable: false,
        },
      ];

      // let prime ng initialize and set the parents of the nodes
      setTimeout(() => {
        this.selectFirstNode();
      }, 0);
    });
  }

  ngOnDestroy() {
    this.deselectNode();
  }

  structureTreeBtnClicked() {
    this.globals.showProjectMenu$.next(true);
    this.hideTree = true;
  }

  nodeCollapse({ node }: { node: StructureTreeNode }) {
    if (node.type == this.channelHeadNodeType) node.expanded = true; // prevent collapse of channel heads
  }

  getChannelTemplates(resource: ModuleType) {
    switch (resource) {
      case ModuleType.Document:
        return this.configService.portalTemplates;
      case ModuleType.MSTeams:
        return this.configService.msTeamsTemplates;
      default:
        return [];
    }
  }

  getError(control: UntypedFormControl) {
    return Object.keys(control.errors)[0];
  }

  addChannelTemplate(parentNode: StructureTreeNode, resource: MSTeamsResource | DocumentResource) {
    let channelForm: UntypedFormGroup;

    switch (parentNode.data) {
      case ModuleType.MSTeams:
        channelForm = this.configService.addMsTeamsTemplate(resource);
        break;
      case ModuleType.Document:
        channelForm = this.configService.addPortalTemplate(resource);
        break;
      default:
        return;
    }

    this.addNode(parentNode, channelForm);
  }

  onNodeDrop({ dragNode, dropNode, accept }: { dragNode: StructureTreeNode; dropNode: StructureTreeNode; accept: () => void }) {
    const dragNodeForm = dragNode.data as UntypedFormGroup;
    const oldFormArray = dragNodeForm.parent as UntypedFormArray;
    const newFormArray = this.getFoldersForm(dropNode);

    if (oldFormArray == newFormArray) return;

    const isDragChannel = StructureNode.isChannelForm(dragNodeForm);
    const isDropOnResource = !dropNode.parent;

    if (!isDragChannel && isDropOnResource) {
      this.userNotification.notify('projectConfig.structure.errors.moveFolderToRoot');
      return;
    }

    if (isDragChannel) {
      if (!isDropOnResource) {
        this.userNotification.notify('projectConfig.structure.errors.moveChannelToChannel');
        return;
      }

      if (StructureNode.isRequiredForm(dragNodeForm, !this.configService.isTemplate)) {
        this.userNotification.notify('projectConfig.structure.errors.moveRequiredChannel');
        return;
      }

      const id = FormUtils.getFormValue<PcfChannel>(dragNodeForm, 'id');
      this.configService.handleChannelRemoved(id);

      this.configService.convertChannel(dragNodeForm, dropNode.data as ModuleType);
    }

    oldFormArray.removeAt(oldFormArray.controls.indexOf(dragNodeForm));
    oldFormArray.markAsDirty();

    newFormArray.push(dragNodeForm);
    newFormArray.markAsDirty();

    accept();

    dragNode.parent = dropNode;

    this.deselectNode();
    this.selectNode(dragNode);
  }

  async addChannelOrFolder(parentNode: StructureTreeNode, channelType?: ChannelType) {
    const addChannel = parentNode.type == this.channelHeadNodeType;
    const newfolderPath: string = await this.translate
      .get('projectConfig.structure.' + (addChannel ? 'newChannel' : 'newFolder'))
      .toPromise();

    const folderForm = addChannel
      ? this.configService.getChannelGroup(
          {
            id: addChannel ? Utils.createUUID() : null,
            name: newfolderPath,
            channelType,
          },
          parentNode.data != ModuleType.MSTeams
        )
      : this.configService.getFolderGroup(
          {
            name: newfolderPath,
          },
          true
        );

    this.addNode(parentNode, folderForm);
  }

  async addNode(parentNode: StructureTreeNode, folderForm: UntypedFormGroup) {
    const foldersForm = this.getFoldersForm(parentNode);
    const newFolderPath = FormUtils.getFormValue<PcfDirectory>(folderForm, 'path');
    const existingFolders = foldersForm.controls.map((folderGroup: UntypedFormGroup) =>
      FormUtils.getFormValue<PcfDirectory>(folderGroup, 'path')
    );

    if (parentNode.data === ModuleType.MSTeams || parentNode.data === ModuleType.Document) {
      this.getChannelsForm(parentNode.data === ModuleType.MSTeams ? ModuleType.Document : ModuleType.MSTeams).controls.forEach(
        (folderGroup: UntypedFormGroup) => {
          existingFolders.push(FormUtils.getFormValue<PcfDirectory>(folderGroup, 'path'));
        }
      );
    }

    let folderPath = newFolderPath;
    let i = 0;
    while (existingFolders.includes(folderPath)) folderPath = `${newFolderPath} (${++i})`;

    FormUtils.getFormControl<PcfDirectory>(folderForm, 'path').setValue(folderPath);

    foldersForm.push(folderForm);
    folderForm.markAsDirty();

    const subFolders = FormUtils.getFormControl<PcfDirectory, UntypedFormArray>(folderForm, 'folders');
    const children = this.buildTreeRecursive(subFolders.controls as UntypedFormGroup[]);
    const newNode = this.createNode(folderForm, children);

    parentNode.children.push(newNode);
    newNode.parent = parentNode;
    parentNode.expanded = true;

    this.selectNode(newNode, true);
  }

  async removeSelectedNode() {
    if (this.selectedNode == null) return;

    if (!this.selectedNode.canRemove) throw 'Cannot remove node!';

    const dialogResult = await this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'general.confirmation.deleteCaption',
          description: 'general.confirmation.deleteWithChildrenDescription',
          params: {
            element: await this.translate
              .get('projectConfig.structure.' + (this.selectedNode.isChannel ? 'channel' : 'folder'))
              .toPromise(),
            name: this.selectedNode.treeNode.label,
          },
        },
      })
      .afterClosed()
      .toPromise();

    if (dialogResult) {
      // from form
      const selectedNodeForm = this.selectedNode.form;
      const parentForm = selectedNodeForm.parent as UntypedFormArray;
      const formIndex = parentForm.controls.indexOf(selectedNodeForm);
      parentForm.removeAt(formIndex);
      parentForm.markAsDirty();

      if (this.selectedNode.isChannel) {
        this.configService.handleChannelRemoved(FormUtils.getFormValue<PcfChannel>(selectedNodeForm, 'id'));
      }

      // from tree
      const parent = this.selectedNode.treeNode.parent;
      const children = parent?.children ?? this.nodes;
      const index = children.indexOf(this.selectedNode.treeNode);
      children.splice(index, 1);

      this.deselectNode();
    }
  }

  selectNode(node: StructureTreeNode, selectText: boolean = false, initSelect = false) {
    this.hideTree = !initSelect;
    if (node == this.selectedNode?.treeNode) return;

    this.selectedNode?.dispose();

    const individualPrivilegeService = new IndividualPrivilegeService(this.dialog, this.formBuilder, this.translate);
    this.selectedNode = new StructureNode(individualPrivilegeService, this.configService, node as TreeNode<UntypedFormGroup>);

    // focus path input after it was rendered
    setTimeout(() => {
      // temporary fix for https://github.com/angular/angular/issues/35309
      if (this.selectedNode.isChannel) {
        const isHiddenControl = FormUtils.getFormControl<PcfChannel>(this.selectedNode.form, 'isHidden');
        if (isHiddenControl.enabled) isHiddenControl.enable();
        else isHiddenControl.disable();
      }

      if (this.pathInput?.nativeElement != null) {
        const input = this.pathInput.nativeElement;
        if (selectText) input.select();
        else input.focus();
      }
    }, 0);
  }

  ignoreDeselect() {
    this.hideTree = true;
  }

  deselectNode() {
    if (this.selectedNode != null) {
      this.selectedNode.dispose();
      this.selectedNode = null;
    }
  }

  async addOrEditTab(tabGroup: UntypedFormGroup = null) {
    const isAddTab = !tabGroup;
    if (isAddTab && !this.selectedNode.isChannel) throw 'Cannot add tab to folder';

    const result: EditTabDialogResult = await this.dialog
      .open(EditTabDialogComponent, {
        data: {
          isProvisioned: !tabGroup ? false : FormUtils.getFormControl<PcfChannelTab>(tabGroup, 'appId').disabled,
          tab: tabGroup?.getRawValue(),
        },
      })
      .afterClosed()
      .toPromise();

    const tab: IProjectConfigResourceTab = result?.tab;
    if (!tab) return;

    if (isAddTab) {
      const tabs = FormUtils.getFormControl<PcfChannel, UntypedFormArray>(this.selectedNode.form, 'tabs');
      tabs.push(this.configService.getTabGroup(tab, false));
      tabs.markAsDirty();
    } else {
      FormUtils.getFormControl<PcfChannelTab>(tabGroup, 'displayName').setValue(tab.displayName);
      FormUtils.getFormControl<PcfChannelTab>(tabGroup, 'entityId').setValue(tab.entityId);
      FormUtils.getFormControl<PcfChannelTab>(tabGroup, 'appId').setValue(tab.appId);
      FormUtils.getFormControl<PcfChannelTab>(tabGroup, 'webSiteUrl').setValue(tab.webSiteUrl);
      FormUtils.getFormControl<PcfChannelTab>(tabGroup, 'contentUrl').setValue(tab.contentUrl);
      tabGroup.controls.hideNavigation.setValue(result.hideNavigation);
      tabGroup.markAsDirty();
    }
  }

  async removeTab(tab: UntypedFormGroup) {
    const dialogResult = await this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'general.confirmation.deleteCaption',
          description: 'general.confirmation.deleteDescription',
          params: {
            element: await this.translate.get('projectConfig.structure.tab.title').toPromise(),
            name: FormUtils.getFormValue<PcfChannelTab>(tab, 'displayName'),
          },
        },
      })
      .afterClosed()
      .toPromise();

    if (dialogResult) {
      const tabs = FormUtils.getFormControl<PcfChannel, UntypedFormArray>(this.selectedNode.form, 'tabs');
      const i = tabs.controls.indexOf(tab);
      tabs.removeAt(i);
      tabs.markAsDirty();
    }
  }

  private selectFirstNode() {
    const firstChildNode = this.nodes[0].children[0] ?? this.nodes[1].children[0];
    if (firstChildNode != null) this.selectNode(firstChildNode, false, true);
  }

  // needed since selection wouldn't work when filtered
  private treeNodeKey: number;
  private createNode(directory: UntypedFormGroup, children: StructureTreeNode[] = []) {
    const pathControl = FormUtils.getFormControl<PcfDirectory>(directory, 'path');
    const node: StructureTreeNode = {
      data: directory,
      key: `${++this.treeNodeKey}`,
      label: pathControl.value,
      children,
      selectable: true,
    };

    pathControl.valueChanges.subscribe(path => {
      node.label = path;
      this.tree.resetFilter();
    });

    return node;
  }

  private buildTreeRecursive(directories: UntypedFormGroup[]) {
    const nodes: StructureTreeNode[] = [];

    for (const directory of directories) {
      const subFolders = FormUtils.getFormControl<PcfDirectory, UntypedFormArray>(directory, 'folders');
      const children = this.buildTreeRecursive(subFolders.controls as UntypedFormGroup[]);

      const node = this.createNode(directory, children);

      nodes.push(node);
    }

    return nodes;
  }

  private getChannelsForm(moduleType: ModuleType) {
    switch (moduleType) {
      case ModuleType.MSTeams:
        return FormUtils.getFormControl<PcfStructure, UntypedFormArray>(this.form, 'msTeamsChannels');
      case ModuleType.Document:
        return FormUtils.getFormControl<PcfStructure, UntypedFormArray>(this.form, 'portalChannels');
      default:
        throw 'Folders form for module type not found';
    }
  }

  private getFoldersForm(node: StructureTreeNode): UntypedFormArray {
    const folderForm = node.data as UntypedFormGroup;
    const isRootNode = node.type == this.channelHeadNodeType;
    return isRootNode
      ? this.getChannelsForm(node.data as ModuleType)
      : FormUtils.getFormControl<PcfDirectory, UntypedFormArray>(folderForm, 'folders');
  }
}
