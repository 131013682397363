import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserNotificationService } from '@app/shared/services';
import { TreeNode } from 'primeng/api';

export enum SelectionMode {
  single = 'single',
  multiple = 'multiple',
  checkbox = 'checkbox',
}

@Component({
  selector: 'app-tree-select-dialog',
  templateUrl: './tree-select-dialog.component.html',
  styleUrls: ['./tree-select-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TreeSelectDialogComponent<T extends ParentModel<T>> implements OnInit {
  title: string = 'general.select';
  description: string;
  canCancel: boolean;
  selectionMode: SelectionMode;
  propagateSelection: boolean;

  tree: TreeNode<T>[];
  selection: TreeNode<T> | TreeNode<T>[];
  get selectedNodes(): TreeNode<T>[] {
    return this.selectionMode == SelectionMode.single
      ? [this.selection as TreeNode<T>]
      : (this.selection as TreeNode<T>[]) ?? [];
  }

  constructor(
    public dialogRef: MatDialogRef<TreeSelectDialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA) data,
    private userNotification: UserNotificationService
  ) {
    this.tree = this.createTree(data.items);
    this.title = data?.title ?? 'general.select';
    this.description = data?.description;
    this.selectionMode = data?.selectionMode ?? SelectionMode.single;
    this.propagateSelection = data?.propagateSelection ?? true;
    this.canCancel = data?.canCancel;
    dialogRef.disableClose = !this.canCancel;
  }

  ngOnInit(): void {
    if (!this.tree.length) {
      this.userNotification.notify('general.errorMsg.noElements');
      this.cancel();
    }
  }

  confirm() {
    this.dialogRef.close(this.selectedNodes.map(n => n.data));
  }

  cancel() {
    this.dialogRef.close([]);
  }

  private createTree(models: T[]) {
    const tree: TreeNode<T>[] = [];
    for (const model of models ?? []) {
      tree.push(this.createTreeNodeRecursive(model));
    }
    return tree;
  }

  private createTreeNodeRecursive(model: T) {
    const node: TreeNode<T> = {
      key: model.id,
      label: model.name,
      data: model,
      children: [],
    };

    for (const child of model.children ?? []) {
      node.children.push(this.createTreeNodeRecursive(child));
    }

    return node;
  }
}
