import { CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { BaseSubscriptionComponent } from '@app/core';
import { fromEvent } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent extends BaseSubscriptionComponent implements OnDestroy, OnInit {
  @ViewChild('popup') popup: ElementRef<HTMLDivElement>;
  @ViewChildren('popup') popupQueryList: QueryList<ElementRef<HTMLDivElement>>;

  @Input() CdkOverlayOrigin: CdkOverlayOrigin;
  @Input() positions: ConnectionPositionPair[] = null;
  @Input() set transparent(transparent: boolean | string) {
    this._transparent = transparent === '' || transparent === 'true' || transparent === true;
  }

  @Output() closed = new EventEmitter<any>();
  @Output() opened = new EventEmitter<any>();

  isOpen = false;

  private resizeObserver: ResizeObserver;
  private latestMouseEvent: MouseEvent;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  private _transparent = false;
  get transparent(): boolean {
    return this._transparent;
  }

  ngOnInit(): void {
    const popupHost = this.CdkOverlayOrigin.elementRef.nativeElement as HTMLElement;

    this.subscribe(fromEvent(popupHost, 'mouseenter').pipe(filter(_ => !this.isOpen)), (event: MouseEvent) => {
      this.latestMouseEvent = event;
      this.changeState(true);
    });

    this.subscribe(this.opened, () => {
      this.subscribe(fromEvent(document, 'mousemove').pipe(takeUntil(this.closed)), {
        next: (event: MouseEvent) => {
          this.latestMouseEvent = event;
        },
        complete: () => {
          this.latestMouseEvent = null;
        },
      });

      this.subscribe(
        fromEvent(popupHost, 'mouseleave').pipe(
          takeUntil(this.closed),
          filter((event: MouseEvent) => this.isMovedOutside(popupHost, this.popup, event.relatedTarget))
        ),
        _ => {
          this.changeState(false);
        }
      );
    });

    this.resizeObserver = new ResizeObserver(() => {
      this.closeIfOutside(
        this.latestMouseEvent ? document.elementFromPoint(this.latestMouseEvent.x, this.latestMouseEvent.y) : null
      );
    });
  }

  ngAfterViewInit() {
    this.subscribe(this.popupQueryList.changes, list => {
      this.resizeObserver.disconnect();
      if (this.popup) this.resizeObserver.observe(this.popup.nativeElement);
    });
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
    return super.ngOnDestroy();
  }

  closeIfOutside(newTarget: EventTarget) {
    const popupHost = this.CdkOverlayOrigin.elementRef.nativeElement as HTMLElement;
    if (this.isMovedOutside(popupHost, this.popup, newTarget)) {
      this.changeState(false);
    }
  }

  private changeState(isOpened: boolean) {
    this.isOpen = isOpened;
    isOpened ? this.opened.emit() : this.closed.emit();
    this.changeDetectorRef.markForCheck();
  }

  private isMovedOutside(overlayHost: HTMLElement, popup: ElementRef<HTMLDivElement>, newTarget: EventTarget): boolean {
    return !(newTarget instanceof Node) || !(overlayHost.contains(newTarget) || popup?.nativeElement.contains(newTarget));
  }
}
