import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cssUrl',
})
export class CssUrlPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    return value ? `url(${value})` : null;
  }
}
