import { GeoJSONGeometryType, GeoJSONType } from '@app/api';
import * as L from 'leaflet';

export const TILE_SIZE = 256;
export const MaximumBounds = L.latLngBounds([
  [-TILE_SIZE, -TILE_SIZE],
  [+TILE_SIZE, +TILE_SIZE],
]);

export enum ShapeTypes {
  rectangle = 'Rectangle',
  polygon = 'Polygon',
  line = 'Line',
  text = 'Text',
  point = 'Point',
  marker = 'Marker',
  circle = 'Circle',
  circleMarker = 'CircleMarker',
  defectMarker = 'DefectMarker',
  freehand = 'Freehand',
  arrow = 'Arrow',
}

export interface Toggleable {
  toggle(toggle: boolean): void;
}

export interface IMetadata {
  position: L.LatLng;
  properties: L.corner4.MappingType;
}
