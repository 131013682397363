<div class="c4-dialog-wrapper" [ngClass]='dlgClass'>
  <h1 mat-dialog-title>{{ title | translate:params }}</h1>
  <mat-dialog-content>
    <div *ngIf='description'>{{ description | translate:params }}</div>
    <ng-container *ngIf='contentTemplate && contentData' [ngTemplateOutlet]="contentTemplate"
                  [ngTemplateOutletContext]="{contentData:contentData}"></ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button (click)="cancel()" type="button">{{ cancelButtonLabel | translate }}</button>
    <button mat-flat-button color="primary" (click)="ok()" type="button">{{ acceptButtonLabel | translate }}</button>
  </mat-dialog-actions>
</div>