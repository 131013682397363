import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { BehaviorSubject } from 'rxjs';

export interface IImageTag {
  text: string;
  cls?: string;
  tooltip?: string;
  partial?: boolean;
  supportsPartial?: boolean;
}

export function setImageTagProperties(imageTag: IImageTag, partial: boolean) {
  imageTag.partial = partial;

  imageTag.cls = partial ? 'half-tag' : 'full-tag';
  imageTag.tooltip = partial ? 'dialogs.image-tag-dialog.tooltips.half-tag' : 'dialogs.image-tag-dialog.tooltips.full-tag';

  return imageTag;
}

export type ImageTags = Array<IImageTag>;

@Injectable({
  providedIn: 'root',
})
export class ImageTagService {
  public allTags = new BehaviorSubject<ImageTags | null>(null);

  constructor(private apiService: ApiService) {}

  createWithDummy() {
    this.allTags.next([{ text: 'foo' }, { text: 'bar' }]);
  }

  async createWithProject() {
    const response = await this.apiService.selectTags();
    const imageTags: ImageTags = response.tags.map(text => {
      const imageTag: IImageTag = {
        text: text,
      };

      setImageTagProperties(imageTag, false);

      return imageTag;
    });

    this.allTags.next(imageTags);
  }

  purge() {
    this.allTags.next([]);
  }
}
