<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: true }"></ngx-loading>
<div class="c4-dialog-wrapper log-receivers-dialog">
	<h1 mat-dialog-title>{{ 'log.receivers.title' | translate }}</h1>
	<mat-dialog-content>
		<ul *ngIf="notificationStateModels?.length; else noModelFound">
			<li *ngFor="let model of notificationStateModels">
				<span [ngClass]="getNotificationIcon(model)"
							[matTooltip]="'general.notifications.' + model.notificationState | translate"></span>
				<span>{{ model.receiverDisplayName }}</span>
			</li>
		</ul>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="close()" type="button">{{ 'general.close' | translate }}</button>
	</mat-dialog-actions>
</div>

<ng-template #noModelFound>
	<span class="vertical-spacing">{{ 'log.receivers.notFound' | translate }}</span>
</ng-template>