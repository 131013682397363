<div class="c4-dialog-wrapper">
  <h1 mat-dialog-title>{{ username }}</h1>
  <mat-dialog-content *ngIf="user" class="app-userProfileContent">
    <div class="app-userProfileDetailTop">
      <div class="app-userProfileImage">
        <app-user-image [userSession]="user" (imageClicked)="fileInput.click()" [sizeRem]="11.2"
                        [matTooltip]="'dialogs.userprofile.uploadImageTooltip' | translate"></app-user-image>
        <input type="file" (click)="fileInput.value=null" (change)="onFileChange($event)" style="display: none"
               accept="image/*" #fileInput />
        <button mat-flat-button disableRipple class="c4-no-focus app-userProfileImageReset" (click)="resetImage()"
                type="button">{{ 'dialogs.userprofile.resetImage' | translate }}</button>
      </div>
      <div class="app-userProfileDetailList">
        <div class="app-userProfileDetail">
          <div class="app-userProfileDetailCaption">{{'dialogs.userprofile.username' | translate}}</div>
          <div class="app-userProfileDetailData">{{details.userName}}</div>
        </div>
        <div class="app-userProfileDetail">
          <div class="app-userProfileDetailCaption">{{'dialogs.userprofile.emailAddress' | translate}}</div>
          <div class="app-userProfileDetailData">{{details.email}}</div>
        </div>
        <div class="app-userProfileDetail">
          <div class="app-userProfileDetailCaption">{{'dialogs.userprofile.organization' | translate}}</div>
          <div class="app-userProfileDetailData">{{details.organization}}</div>
        </div>

        <div class="app-userProfileDetail">
          <mat-form-field class="c4-full-width">
            <input matInput type="text" placeholder="{{'dialogs.userprofile.phoneNumber' | translate}}" #userPhoneInput
                   [value]="details.phone">
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="app-userProfileDetailBottom">
      <div class="app-userProfileSignature">
        <div class="app-userProfileDetail">
          <mat-form-field class="signature-area c4-full-width">
            <textarea matInput placeholder="{{'dialogs.userprofile.signature' | translate}}" [value]="details.signature"
                      #userSignatureInput cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="6"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button (click)="close()" type="button" cdkFocusInitial>{{ 'general.cancel' | translate }}</button>
    <button mat-flat-button color="primary" (click)="saveUserData()" type="button" cdkFocusInitial>{{ 'general.save' |
      translate }}</button>
  </mat-dialog-actions>
</div>
<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:false }"></ngx-loading>