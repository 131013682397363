import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

const MS_PER_DAY = 1000 * 60 * 60 * 24;

export enum LocalizedDateFormat {
  relative = 'relative', // yesterday, today, tomorrow with time
  relativeDate = 'relativeDate', // yesterday, today, tomorrow without time
  short = 'short', // with time
  shortDate = 'shortDate', // without time
}

@Pipe({
  name: 'localizedDate',
  pure: false, // invoke also when language changed
})
export class LocalizedDatePipe implements PipeTransform {
  relativeDateTranslations: string[] = [];
  dateFormat: string;
  timeFormat: string;

  private lastDate: Date | string | number;
  private lastFormat: LocalizedDateFormat;
  private lastValue: string;

  constructor(private datePipe: DatePipe, private translateService: TranslateService) {
    this.updateTranslations();
    translateService.onLangChange.subscribe(options => {
      this.lastDate = null;
      this.updateTranslations();
    });
  }

  transform(date: Date | string | number, format: LocalizedDateFormat = LocalizedDateFormat.shortDate): string {
    // stop resource intense computation due to pure: false
    if (date == this.lastDate && format == this.lastFormat) return this.lastValue;

    this.lastDate = date;
    this.lastFormat = format;

    if (!date) {
      this.lastValue = '';
      return this.lastValue;
    }

    let dateFormat = '';
    switch (format) {
      case LocalizedDateFormat.relative:
        dateFormat = ' ' + this.timeFormat;
      case LocalizedDateFormat.relativeDate:
        const translationIndex = this.getTranslationIndex(date);

        let relativeDateString = '';
        if (0 <= translationIndex && translationIndex < 3) {
          relativeDateString = this.relativeDateTranslations[translationIndex];
        } else {
          dateFormat = this.dateFormat + dateFormat;
        }

        this.lastValue = relativeDateString + this.datePipe.transform(date, dateFormat);
        break;
      case LocalizedDateFormat.short:
        dateFormat = ' ' + this.timeFormat;
      case LocalizedDateFormat.shortDate:
        dateFormat = this.dateFormat + dateFormat;
        this.lastValue = this.datePipe.transform(date, `${dateFormat}`);
        break;
      default:
        return '';
    }

    return this.lastValue;
  }

  /**
   *
   * @returns Difference of days relative to yesterday (yesterday = 0, today = 1)
   */
  private getTranslationIndex(date: Date | string | number) {
    const m = moment(date);
    const yesterday = moment().startOf('day').add(-1, 'days');
    return Math.floor(m.diff(yesterday, 'days', true));
  }

  private updateTranslations() {
    this.dateFormat = this.translateService.instant('general.date.pattern');
    this.timeFormat = this.translateService.instant('general.time.pattern');

    this.relativeDateTranslations = [];
    [
      this.translateService.instant('general.date.yesterday'),
      this.translateService.instant('general.date.today'),
      this.translateService.instant('general.date.tomorrow'),
    ].forEach(translation => {
      this.relativeDateTranslations.push(translation);
    });
  }
}
