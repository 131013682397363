<form *ngIf="roles" class="config-content-container security-config">
	<div class="config-content">
		<div *ngIf="roles.length" class="config-group gap">
			<div class="config-title sticky">{{ 'projectConfig.security.title' | translate }}</div>

			<div class="table-grid-component-container">
				<div class="matrix table-grid-component" [style.grid-template-columns]="gridTemplateColumnsStyle">
					<div class="c4-table-grid-cell c4-table-grid-header-top c4-table-grid-header-start corner">
						{{ 'projectConfig.security.functionalPrivileges' | translate }}
					</div>
					<div *ngFor="let role of roles" class="c4-table-grid-cell c4-table-grid-header-top centered"
							 [matTooltip]="roleAndUserData[role.controls.id.value]?.description">
						{{ roleAndUserData[role.controls.id.value]?.name }}
					</div>
					<div class="c4-table-grid-cell c4-table-grid-header-top centered"></div>

					<ng-container *ngFor="let group of functionalPrivilegeGroups; let i = index">
						<ng-container *ngIf="isPrivilegeGroupEnabled(group.groupType)">
							<div class="c4-table-grid-cell c4-table-grid-group-header c4-table-grid-header-start">
								{{ 'projectConfig.security.functionalPrivilegeGroup.' + group.groupType | translate }}
							</div>
							<div class="c4-table-grid-cell c4-table-grid-group-header full-row"></div>
							<div class="c4-table-grid-cell c4-table-grid-group-header c4-table-grid-header-end"></div>

							<ng-container *ngFor="let privilege of group.privileges; let j = index">
								<div class="c4-table-grid-cell c4-table-grid-header-start">
									<div class="label-stack-container">
										<span class="title c4-busy-content"
													[matTooltip]="'projectConfig.security.functionalPrivilege.' + privilege.displayName | translate">
											{{ 'projectConfig.security.functionalPrivilege.' + privilege.displayName | translate }}
										</span>
										<span class="subtitle c4-busy-content"
													[matTooltip]="'projectConfig.security.functionalPrivilegeDescription.' + privilege.displayName | translate">
											{{ 'projectConfig.security.functionalPrivilegeDescription.' + privilege.displayName | translate }}
										</span>
									</div>
								</div>
								<div *ngFor="let role of roles" class="c4-table-grid-cell centered"
										 [formGroup]="role.controls.privilegeGroups.controls[i].controls.privileges.controls[j]">
									<app-ternary-checkbox class="c4-busy-content" formControlName="isSet"></app-ternary-checkbox>
								</div>
								<div class="c4-table-grid-cell"></div>
							</ng-container>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</div>
</form>