import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ApiService, AppConfigService, LogService, TranslationManagementService, Utils } from '@app/core';
import { ChangeUserDataModel, SwaggerException, UserSessionModel } from '@app/api';
import { UserNotificationService } from '@app/shared/services';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { Busy, using, BusyScope } from '@app/shared/utils/busy';

interface UserProfile {
  userName: string;
  email: string;
  phone: string;
  organization: string;
  signature?: string;
}

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.scss'],
})
export class UserProfileComponent implements OnInit, Busy {
  isBusy: boolean;
  user: UserSessionModel;
  username: string;
  details: UserProfile = null;
  @ViewChild('userPhoneInput') userPhoneInput: ElementRef;
  @ViewChild('userOrganisationInput') userOrganisationInput: ElementRef;
  @ViewChild('userSignatureInput') userSignatureInput: ElementRef;

  constructor(
    private apiService: ApiService,
    private log: LogService,
    private translationService: TranslationManagementService,
    private userNotification: UserNotificationService,
    public dialogRef: MatDialogRef<UserProfileComponent>
  ) {}

  async ngOnInit() {
    await this.loadUser();
  }

  async onFileChange(event) {
    if (event.target && event.target.files && event.target.files.length > 0) {
      let image = event.target.files[0] as File;

      if (this.isIE() && image.size > 20971520) {
        await this.userNotification.notify('dialogs.userprofile.errorUploadIE');
      }

      let reader = new FileReader();
      reader.onload = evt => {
        let img = new Image();

        img.onload = async () => {
          let canvas = document.createElement('canvas');
          canvas.setAttribute('height', '128');
          canvas.setAttribute('width', '128');

          let canvasContext = canvas.getContext('2d');

          let fixedPad = 0;
          let fixedSize = canvas.width - fixedPad;

          let size = img.width < img.height ? fixedSize / img.width : fixedSize / img.height;

          let w = img.width * size;
          let h = img.height * size;

          let dx = (fixedSize - w + fixedPad) / 2;
          let dy = (fixedSize - h + fixedPad) / 2;
          let dWidth = w;
          let dHeight = h;

          canvasContext.drawImage(img, dx, dy, dWidth, dHeight);

          let dataUrl = canvas.toDataURL('image/png');
          let strippedDataUrl = dataUrl.replace(/^.*,/, '');

          await this.uploadImage(strippedDataUrl);
        };

        img.src = <any>evt.target.result;
      };

      reader.readAsDataURL(image);
    }
  }

  isIE(): boolean {
    let ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
    return is_ie;
  }

  dataURLToBlob(dataURL: string) {
    var BASE64_MARKER = ';base64,';
    if (dataURL.indexOf(BASE64_MARKER) == -1) {
      var parts = dataURL.split(',');
      var contentType = parts[0].split(':')[1];
      var raw = parts[1];

      return new Blob([raw], { type: contentType });
    }

    var parts = dataURL.split(BASE64_MARKER);
    var contentType = parts[0].split(':')[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;

    var uInt8Array = new Uint8Array(rawLength);

    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  }

  async resetImage() {
    await this.uploadImage('');
  }

  private async uploadImage(image: string) {
    await using(new BusyScope(this), async () => {
      try {
        await this.apiService.uploadUserImage(image);
        await this.userNotification.notify('dialogs.userprofile.updateSuccess');
        await this.loadUser();
      } catch (e) {
        let uiMsgKey = this.translationService.instant('dialogs.userprofile.updateFailed');

        if (SwaggerException.isSwaggerException(e)) {
          e = e as SwaggerException;
          if (e.status === 400) {
            uiMsgKey = 'dialogs.userprofile.errorUserProfileTooBig';
          }
        }

        this.log.error('Failed to update user image', e);
        await this.userNotification.notify(uiMsgKey, { error: e });
      }
    });
  }

  private async loadUser() {
    try {
      this.user = await this.apiService.getUserSession();
    } catch (e) {
      await this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.userData', e);
      this.dialogRef.close();
      return;
    }
    if (Utils.isNullOrWhitespace(this.user.firstname) || Utils.isNullOrWhitespace(this.user.lastname)) {
      this.username = this.user.displayUsername ?? this.user.username;
    } else {
      this.username = `${this.user.firstname} ${this.user.lastname}`;
    }
    this.details = {
      userName: this.username,
      email: this.user.emailAddress,
      phone: this.user.phoneNumber,
      organization: this.user.organization?.name ?? '',
      signature: this.user.signature,
    };
  }

  async saveUserData() {
    const userData = new ChangeUserDataModel();
    userData.phoneNumber = this.userPhoneInput.nativeElement.value;
    userData.signature = this.userSignatureInput.nativeElement.value;

    await using(new BusyScope(this), async () => {
      try {
        await this.apiService.updateUserData(userData);
        await this.userNotification.notify('dialogs.userprofile.updateDataSuccess');
        this.dialogRef.close();
      } catch (e) {
        this.log.error('Failed to update userData', e);
        await this.userNotification.notify('dialogs.userprofile.updateDataFailed', { error: e });
      }
    });
  }

  close() {
    this.dialogRef.close();
  }
}
