import { Observable, Subject, Subscription, catchError, delayWhen, finalize, of, switchMap, takeUntil, tap, timer } from 'rxjs';

export class AdditionalDataLoader<T> {
  isBusy = false;

  private dataSubject = new Subject<T>();
  data$ = this.dataSubject.asObservable();

  private loadRequest = new Subject<void>();
  private cancelRequest = new Subject<void>();
  private requestSubscription: Subscription;

  constructor(request: () => Observable<T>, minRequestTime: number = 250) {
    this.requestSubscription = this.loadRequest
      .pipe(
        tap(() => (this.isBusy = true)),
        switchMap(() => {
          const startTime = Date.now();
          return request().pipe(
            // delay if request was too fast for loading animation
            delayWhen(() => timer(minRequestTime + startTime - Date.now())),
            takeUntil(this.cancelRequest),
            catchError(() => of(null)),
            finalize(() => (this.isBusy = false))
          );
        })
      )
      .subscribe(data => this.dataSubject.next(data));
  }

  load() {
    this.loadRequest.next();
  }

  cancel() {
    this.cancelRequest.next();
  }

  dispose() {
    this.cancel();
    this.requestSubscription?.unsubscribe();
    this.requestSubscription = null;
  }
}
