<div class="c4-grid-container card word-break" [ngClass]="dataHolder ? dataHolder.busyClass : ''">
	<c4-grid *ngIf="gridDef" [gridDef]="gridDef" [source]="dataHolder?.data" [noDataText]="noDataText">
	</c4-grid>
</div>

<ng-template #state let-row="row">
	<div class="state-container">
		<span class="mdi" [class]="row.stateIcon"></span>
		<div class="title">{{ row.currentState.title }}</div>
	</div>
</ng-template>

<ng-template #nameTemplate let-row="row">
	<app-workpackage-template-tile class="drag-item-for-scheduler" [template]="row">
	</app-workpackage-template-tile>
</ng-template>

<ng-template #actionsTemplate let-col="col" let-row="row">
	<div class="c4-no-focus">
		<button type="button" mat-icon-button class="c4-grid-btn" (click)="edit.emit(row)"
						[matTooltip]="'general.edit' | translate" [disabled]="!canEditWorkpackage">
			<span class="mdi mdi-square-edit-outline"></span>
		</button>
		<button type="button" mat-icon-button class="c4-grid-btn" (click)="remove.emit(row)"
						[matTooltip]="'general.delete' | translate" [disabled]="!canEditWorkpackage">
			<span class="mdi mdi-trash-can-outline"></span>
		</button>
	</div>
</ng-template>