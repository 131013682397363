import { Pipe, PipeTransform } from '@angular/core';
import { contrast } from '../utils/color-helper';

@Pipe({
  name: 'contrastColor',
})
export class ContrastColorPipe implements PipeTransform {
  transform(color: string | null, mode: 'class' | 'color' = 'class') {
    if (typeof color != 'string') {
      color = '000000';
    }

    return contrast(color, mode);
  }
}
