import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-drag-hover-overlay',
  templateUrl: './drag-hover-overlay.component.html',
  styleUrls: ['./drag-hover-overlay.component.scss'],
})
export class DragHoverOverlayComponent {
  @Input() validTypes = [];

  @Output() filesDropped = new EventEmitter<File[]>();

  isDragOver: boolean = false;

  constructor() {}
}
