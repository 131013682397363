<form *ngIf="form" class="config-content-container ms-teams-config" [formGroup]="form">
	<div class="config-content">
		<div class="config-group slim gap sticky">
			<div class="config-title">{{ 'projectConfig.msTeams.title' | translate }}</div>

			<app-toggle-field formControlName="isTeamPublic" [label]="'projectConfig.msTeams.isTeamPublic' | translate"
												[description]="'projectConfig.msTeams.isTeamPublicDescription' | translate">
			</app-toggle-field>

			<app-toggle-field formControlName="supportSharePointGroupPrivileges"
												[label]="'projectConfig.msTeams.supportSharePointGroupPrivileges' | translate"
												[description]="'projectConfig.msTeams.supportSharePointGroupPrivilegesDescription' | translate">
			</app-toggle-field>
		</div>
	</div>
</form>