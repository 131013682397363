<div class="add-diary-mobile" *ngIf="globals.isMobileMenu && canEditDiary">
  <button mat-flat-button [matTooltip]="'constructionDiary.header.newDiary' | translate" (click)="createItem()">
    <span class="mdi mdi-plus-box"></span>
    <span class="add-diary-label">{{ 'general.add' | translate }}</span>
  </button>
</div>
<div class="c4-grid-container diary" [class.c4-busy]="!diaryHolder.dataReady || isBusy">
  <c4-grid *ngIf="gridDef" [gridDef]="gridDef" [source]="diaryHolder.data"
           [noDataText]="'grid.constructionDiary.noDataText'" [selectionMode]="selectionMode" [(selection)]="selection"
           (filter)="deselectAll()">
  </c4-grid>
</div>

<ng-template #stateTemplate let-row='row'>
  <div class="state-container">
    <app-state-circle [hexColor]="row.currentState.color"></app-state-circle>
    <div class="title">{{ row.currentState.title }}</div>
  </div>
</ng-template>

<ng-template #selectAllTemplate let-col='col'>
  <div class="c4-no-focus offset-left">
    <button mat-icon-button class="c4-grid-btn" (click)="allRowsSelect($event)">
      <span class="mdi mdi-check-all"></span>
    </button>
  </div>
</ng-template>

<ng-template #selectRowTemplate let-col='col' let-row='row'>
  <div class="c4-no-focus offset-left">
    <button mat-icon-button class="c4-grid-btn" (click)="rowSelect(row)">
      <span class="mdi mdi-check"></span>
    </button>
  </div>
</ng-template>

<ng-template #assignedTemplate let-row='row'>
  <div class="c4-name-column" (click)="editItem(row.id)">
    <div class="header">
      {{ row.assigned | localizedDate }}
    </div>
  </div>
</ng-template>

<ng-template #actionsTemplate let-col='col' let-row='row'>
  <div class="c4-no-focus">
    <button type="button" mat-icon-button class="c4-grid-btn" aria-label="Edit" (click)="editItem(row.id)"
            [matTooltip]="'general.edit' | translate">
      <span class="mdi mdi-square-edit-outline"></span>
    </button>
  </div>
</ng-template>

<ng-template #contextMenuTemplate let-col='col' let-row='row'>
  <div class="c4-no-focus">
    <button mat-icon-button class="c4-grid-btn" [matMenuTriggerFor]="rowContext" [matMenuTriggerData]="{menudata:row}"
            [matTooltip]="'general.actions.title' | translate">
      <span class="mdi mdi-dots-horizontal"></span>
    </button>
  </div>
</ng-template>
<mat-menu #rowContext="matMenu">
  <ng-template matMenuContent let-menudata='menudata'>
    <button mat-menu-item class="c4-context-menu-item" (click)="editItem(menudata.id)">
      <span class="mdi mdi-square-edit-outline"></span>
      <span>{{ 'constructionDiary' + (canEditDiary ? '.edit' : '.view') | translate }}</span>
    </button>
    <button *ngIf="canEditDiary" mat-menu-item class="c4-context-menu-item" (click)="copyItem(menudata.id)">
      <span class="mdi mdi-content-copy"></span>
      <span>{{'constructionDiary.copy' | translate}}</span>
    </button>
    <button *ngIf="menudata.canWrite" mat-menu-item class="c4-context-menu-item" (click)="generateReport(menudata.id)">
      <span class="mdi mdi-file-pdf-box"></span>
      <span>{{'constructionDiary.header.report' |translate}}</span>
    </button>
  </ng-template>
</mat-menu>