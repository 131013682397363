import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService, LogService } from '@app/core/services';
import { ApiCallAbortedException } from '@app/core';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationService {
  private lastNotificationToken: number;

  constructor(private snackBar: MatSnackBar, private translateService: TranslateService, private log: LogService) {}

  async notifyFailedToLoadDataAndLog(dataNameKey: string, error: ErrorWithStatus): Promise<number> {
    if (ApiCallAbortedException.isApiCallAbortedException(error)) {
      this.log.debug(`Aborted loading data with key ${dataNameKey}`, error);
      return;
    }
    this.log.error(`Failed to load data with key ${dataNameKey}`, error);
    const dataName = await this.translateService.get(dataNameKey).toPromise();

    return await this.notify('general.errorFailedToLoadData', {
      params: {
        dataName,
      },
      error,
    });
  }

  async notify<T extends ErrorWithStatus>(messageKey: string, options?: NotificationOptions<T>): Promise<number> {
    const notificationToken = new Date().valueOf();
    const translationKeys: string[] = [messageKey, options?.actionKey ?? 'general.ok'];
    const translations: Record<string, any> = await this.translateService.get(translationKeys, options?.params).toPromise();

    const errorStatus = options?.error?.status;
    const translatedError = translations[translationKeys[0]];
    const errorMessage = errorStatus != null ? `[${errorStatus}] ${translatedError}` : translatedError;

    this.snackBar.open(errorMessage, translations[translationKeys[1]], {
      duration: AppConfigService.settings.snackbar.dismissAfterMilliseconds,
    });

    this.lastNotificationToken = notificationToken;

    return notificationToken;
  }

  dismiss() {
    this.snackBar.dismiss();
  }

  dismissIftokenMatches(notificationToken: number) {
    if (this.lastNotificationToken === notificationToken) {
      this.dismiss();
    }
  }
}

export class NotificationOptions<T extends ErrorWithStatus> {
  params?: any;
  actionKey? = 'general.ok';
  error?: T;
}

interface ErrorWithStatus {
  status?: number;
}
