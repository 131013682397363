<mat-toolbar class="c4-page-header">
  <div class="c4-page-header-wrapper">
    <ng-container>
      <div class="logowrapper minifiable-container">
        <div class="c4-page-header-toggle c4-no-focus" id="mainSidebarToggle" (click)="toggleSidebar()" matRipple
             [matRippleCentered]="true">
          <div class="menu-toggle-button mdi mdi-menu"></div>
        </div>
        <a class="logo-full" [routerLink]="projectsPathFragments">
          <div class="logo-icon"></div>
          <div class="logo-text hide-on-minified"></div>
        </a>
      </div>
    </ng-container>
    <div class="spacer">
      <!-- <c4-breadcrumb [homePath]="'dashboard'" [useHomePath]="false"> -->
      <c4-breadcrumb [crumbs]="crumbs">
      </c4-breadcrumb>
      <div class="header-buttons c4-portal-only">
        <app-offline-state-button *ngIf="isCapacitor && isInProject"></app-offline-state-button>
        <button *ngIf="isCapacitor" mat-icon-button class="top-button" (click)="scanQr()">
          <span class="mdi mdi-qrcode"></span>
        </button>
        <button *ngIf="zoho.isAvailable" mat-icon-button class="top-button" (click)="toggleZoho()">
          <span class="mdi mdi-help-circle-outline"></span>
        </button>
        <button mat-icon-button class="top-button" (click)="toggleFrill()">
          <span class="announcment-icon mdi mdi-bullhorn-outline"></span>
        </button>
      </div>
      <div class="admin-portal-label c4-admin-only">ADMINISTRATION</div>
    </div>
    <ng-container>
      <div class="c4-page-header-menu menu" *ngIf="user">
        <ng-container *ngTemplateOutlet="usermenu"></ng-container>
      </div>
    </ng-container>
  </div>
</mat-toolbar>
<ng-template #usermenu>
  <div class="user-dropdown-menu" [matMenuTriggerFor]="userdropdown"
       [matTooltip]="'layout.toolbar.userDropdownTooltip' | translate" matRipple>
    <div class="user-dropdown-menu-name" *ngIf="user">{{user.displayUsername}}</div>
    <app-user-image [userSession]="user" [sizeRem]="2.8"></app-user-image>
    <mat-menu #userdropdown="matMenu">
      <div class="c4-popup-holder c4-user-ctrl">
        <ul class="c4-popup-list">
          <li class="c4-list-item c4-small-item">
            <button mat-menu-item class="c4-menu-button" (click)="editUserProfile()">
              <app-user-image [userSession]="user" [sizeRem]="2.4"></app-user-image>
              <span>{{ 'layout.toolbar.userDropdown.editUserProfile' | translate }}</span>
            </button>
          </li>
          <li class="c4-list-item c4-small-item">
            <button mat-menu-item class="c4-menu-button" (click)="changeProject()">
              <span class="mdi mdi-logout"></span>
              <span>{{ 'layout.toolbar.userDropdown.changeProject' | translate }}</span>
            </button>
          </li>
          <li *ngIf="isAdmin && !isInAdminPortal" class="c4-list-item c4-small-item">
            <button mat-menu-item class="c4-menu-button" (click)="navigateToAdminPortal()"
                    [disabled]="isAppOffline$ | async">
              <span class="mdi mdi-cog"></span>
              <span>{{ 'layout.toolbar.userDropdown.switchToAdministration' | translate }}</span>
            </button>
          </li>
          <li *ngIf="!isInAdminPortal" class="c4-list-item c4-small-item">
            <button mat-menu-item class="c4-menu-button" (click)="editNotificationSettings()"
                    [disabled]="isAppOffline$ | async">
              <span class="mdi mdi-bell-outline"></span>
              <span>{{ 'layout.toolbar.userDropdown.editNotificationSettings' | translate }}</span>
            </button>
          </li>
          <li class="c4-list-item c4-small-item">
            <a mat-menu-item class="c4-menu-button" href="https://support.build365.de/portal" target="_blank">
              <span class="mdi mdi-lifebuoy"></span>
              <span>{{ 'layout.toolbar.userDropdown.helpCenter' | translate }}</span>
            </a>
          </li>
          <li *ngIf="isDebugMode" class="c4-list-item c4-small-item">
            <button mat-menu-item class="c4-menu-button" (click)="copyAccessToken()">
              <span class="mdi mdi-shield-key-outline"></span>
              <span>DEBUG - COPY AccessToken</span>
            </button>
          </li>
          <li *ngIf="useDebugOffline" class="c4-list-item c4-small-item">
            <ng-template #toggleDebugOfflineButton let-isDebugOffline [ngTemplateOutlet]="toggleDebugOfflineButton"
                         [ngTemplateOutletContext]="{
              $implicit: isDebugOffline$ | async
            }">
              <button mat-menu-item class="c4-menu-button" (click)="toggleDebugOffline()">
                <span class="mdi" [class]="isDebugOffline ? 'mdi-cloud-outline' : 'mdi-cloud-off-outline'"></span>
                <span>{{ isDebugOffline ? 'Go Online' : 'Go Offline' }}</span>
              </button>
            </ng-template>
          </li>
          <li *ngIf="isDebugMode && isCapacitor" class="c4-list-item c4-small-item">
            <button mat-menu-item class="c4-menu-button" (click)="dumpSQLiteStorage()">
              <span class="mdi mdi-dump-truck"></span>
              <span>DEBUG - Dump Offline Storage</span>
            </button>
          </li>
          <li class="c4-list-item c4-small-item">
            <button mat-menu-item [matMenuTriggerFor]="langSelect" class="c4-menu-button drop-icon-pad">
              <span class="mdi mdi-web"></span>
              <span>{{ 'layout.toolbar.userDropdown.changeLanguage' | translate }}</span>
            </button>
          </li>
          <li class="c4-list-item c4-small-item">
            <button mat-menu-item class="c4-menu-button" (click)="about()">
              <span class="mdi mdi-information-outline"></span>
              <span>{{ 'layout.toolbar.userDropdown.about' | translate:{'appName':appName} }}</span>
            </button>
          </li>
          <li *ngIf="canChangePassword" class="c4-list-item c4-small-item">
            <button mat-menu-item class="c4-menu-button" (click)="changePassword()" [disabled]="isAppOffline$ | async">
              <span class="mdi mdi-shield-key-outline"></span>
              <span>{{ 'layout.toolbar.userDropdown.changePassword' | translate }}</span>
            </button>
          </li>
          <li class="c4-list-item c4-small-item">
            <button mat-menu-item class="c4-menu-button" (click)="openUserSettings()">
              <span class="mdi mdi-cog"></span>
              <span>{{ 'settings.user.title' | translate }}</span>
            </button>
          </li>
          <li class="c4-list-item c4-small-item">
            <button mat-menu-item class="c4-menu-button" (click)="userLogout()" [disabled]="isAppOffline$ | async">
              <span class="mdi mdi-logout"></span>
              <span>{{ 'layout.toolbar.userDropdown.logout' | translate }}</span>
            </button>
          </li>
        </ul>
      </div>
    </mat-menu>
    <mat-menu #langSelect="matMenu">
      <ng-container *ngFor="let lang of languages">
        <button (click)="changeLang(lang)" mat-menu-item>{{ "general." + lang | translate}}</button>
      </ng-container>
    </mat-menu>
  </div>
</ng-template>