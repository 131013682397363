import { OnDestroy, Directive } from '@angular/core';
import { Observable, Observer, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

class CallSuper {}

@Directive()
export abstract class BaseSubscriptionComponent implements OnDestroy {
  private _isDestroyed = false;
  private destroy$ = new Subject<void>();

  constructor() {}

  get isDestroyed(): boolean {
    return this._isDestroyed;
  }

  subscribe<T>(observable: Observable<T>, next?: Partial<Observer<T>> | ((value: T) => void)) {
    if (this._isDestroyed) return;

    observable.pipe(takeUntil(this.destroy$)).subscribe(next);
  }

  ngOnDestroy(): CallSuper {
    this._isDestroyed = true;
    this.destroy$.next();
    this.destroy$.complete();
    return new CallSuper();
  }
}
