<div class="c4-dialog-wrapper" [ngClass]='dlgClass'>
    <h1 mat-dialog-title>{{ title | translate:params }}</h1>
    <mat-dialog-content>
        <div *ngIf='description'>{{ description | translate:params }}</div>
        <ng-container *ngIf='contentTemplate && contentData' [ngTemplateOutlet]="contentTemplate"
            [ngTemplateOutletContext]="{contentData:contentData}"></ng-container>
    </mat-dialog-content>
    <mat-dialog-actions>
        <button mat-flat-button (click)="cancel()" type="button">{{ cancelButton | translate }}</button>
        <button mat-flat-button color="primary" (click)="fileInputRef.click()" type="button">{{ confirmButton |
            translate }}</button>
    </mat-dialog-actions>
    <input #fileInputRef type="file" class="ios_only" accept="image/*" capture="camera"
        (change)="fileChanged($event.target.files)" style="display: none;">
</div>