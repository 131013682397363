import { Color } from '@angular-material-components/color-picker';

export function getColorHexString(color: Color): string {
  return color?.toHex(false);
}

export function hexStringToColor(hex: string): Color {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return null;
  return new Color(parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16));
}

function componentToHex(c: number) {
  var hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

export function rgbToHex(r: number, g: number, b: number, includeHash: boolean = false): string {
  const hash = includeHash ? '#' : '';
  return hash + componentToHex(r) + componentToHex(g) + componentToHex(b);
}

export function rgbStringToHex(rgbString: string, includeHash: boolean = false): string {
  if (!rgbString) return null;
  const [r, g, b] = rgbString.split(',').map(x => Number.parseInt(x));
  return rgbToHex(r, g, b, includeHash);
}

export function hexToRgbString(hexColor: string) {
  if (!hexColor) return null;
  const color = hexStringToColor(hexColor);
  return `${color.r}, ${color.g}, ${color.b}`;
}
