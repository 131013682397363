import { LocaleHelper } from '@bryntum/schedulerpro';
import BryntumLocaleEn from '@bryntum/schedulerpro/locales/schedulerpro.locale.EnGb.js';

const locale = LocaleHelper.mergeLocales(BryntumLocaleEn, {
  Column: {
    Region: 'Area',
    Floor: 'Building Part',
    Area: 'Building',
  },

  EventCopyPaste: {
    copyEvent: 'Copy work package',
    cutEvent: 'Cut work package',
    pasteEvent: 'Paste work package',
  },

  TaskEdit: {
    editEvent: 'Edit work package',
  },

  NotesTab: {
    Notes: 'Description',
  },

  SchedulerGeneralTab: {
    Resources: 'Lanes',
  },

  SchedulerExportDialog: {
    'Schedule range': 'Process plan area',
  },

  ExportDialog: {
    rows: 'Row area',
  },

  MultiPageVerticalExporter: {
    multipagevertical: 'Display all columns on one page',
  },
});

export default locale;
