import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { BaseSubscriptionComponent, GlobalsService } from '@app/core';
import { debounceTime } from 'rxjs/operators';

export interface Crumb {
  icon?: string;
  prefix?: string;
  label?: string;
  routerLink?: string;
  action?: () => void;
}

@Component({
  selector: 'c4-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbComponent extends BaseSubscriptionComponent implements OnInit {
  @ViewChild('rootContainer', { static: true }) rootContainer: ElementRef<HTMLElement>;

  @Input() responsive: boolean = false;
  @Input() disabled: boolean;
  @Input() set crumbs(value: Crumb[]) {
    this.allCrumbs = value;
    this.calculateCrumbs();
  }

  visibleCrumbs: Crumb[] = [];
  hiddenCrumbs: Crumb[] = [];

  private allCrumbs: Crumb[];

  constructor(private globals: GlobalsService) {
    super();
  }

  async ngOnInit() {
    this.subscribe(this.globals.windowResize$.pipe(debounceTime(250)), _ => {
      if (this.responsive) this.calculateCrumbs();
    });
  }

  crumbClicked(crumb: Crumb) {
    if (crumb.action) {
      crumb.action();
    }
  }

  private getCrumbWidthInPx(crumb: Crumb) {
    const seperatorWidth = 18;
    return !crumb.prefix && !crumb.label
      ? 40 + seperatorWidth // icon button
      : 120 + seperatorWidth; // mat button
  }

  private calculateCrumbs() {
    const visibleCrumbs = [...this.allCrumbs];
    const hiddenCrumbs = [];

    if (visibleCrumbs.length > 2 && this.responsive && this.rootContainer) {
      const containerWidthInPx = parseFloat(
        window.getComputedStyle(this.rootContainer.nativeElement, null).getPropertyValue('width')
      );

      const hiddenMenuWidthInPx = 48;
      let minCrumbsAtEnd = 1; // keep at least 1 crumb
      let currentWidthInPx =
        this.getCrumbWidthInPx(visibleCrumbs[0]) +
        hiddenMenuWidthInPx +
        this.getCrumbWidthInPx(visibleCrumbs[visibleCrumbs.length - 1]);
      for (let i = visibleCrumbs.length - 2; i > 0; i--) {
        currentWidthInPx += this.getCrumbWidthInPx(visibleCrumbs[i]);

        if (currentWidthInPx > containerWidthInPx) break;

        ++minCrumbsAtEnd;
      }

      const hiddenCrumbsCount = visibleCrumbs.length - minCrumbsAtEnd - 1; // first crumb

      if (hiddenCrumbsCount > 0) {
        for (const crumb of visibleCrumbs.splice(1, hiddenCrumbsCount)) {
          hiddenCrumbs.push(crumb);
        }
      }
    }

    this.visibleCrumbs = visibleCrumbs;
    this.hiddenCrumbs = hiddenCrumbs;
  }
}
