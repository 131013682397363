import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, merge, scheduled } from 'rxjs';
import { map, mapTo, mergeAll } from 'rxjs/operators';
import { IImageTag, ImageTags, ImageTagService, setImageTagProperties } from '../image-tag.service';

@Component({
  selector: 'app-image-tag-editor',
  templateUrl: './image-tag-editor.component.html',
  styleUrls: ['./image-tag-editor.component.scss'],
})
export class ImageTagEditorComponent {
  @Output()
  public tagsChanged = new EventEmitter<ImageTags>();

  public state$;
  public selectedTags$ = new BehaviorSubject<ImageTags>([]);

  @Input()
  public set selectedTags(value: ImageTags) {
    this.selectedTags$.next(value);
  }

  constructor(readonly svcImageService: ImageTagService) {
    this.state$ = combineLatest([svcImageService.allTags, this.selectedTags$]).pipe(
      map(([allTags, selectedTags]) => {
        return {
          allTags,
          selectedTags,
        };
      })
    );
  }

  get assignTags() {
    return async function (selectedTag: IImageTag, self: ImageTagEditorComponent) {
      const updatedTags = [...self.selectedTags$.value, selectedTag];

      self.publish(updatedTags);
      return true;
    };
  }

  get clickTag() {
    return async function (selectedTag: IImageTag, self: ImageTagEditorComponent) {
      if (selectedTag.partial) {
        setImageTagProperties(selectedTag, false);

        self.publish();
      } else if (selectedTag.supportsPartial) {
        setImageTagProperties(selectedTag, true);

        self.publish();
      }
    };
  }

  get removeTags() {
    return async function (selectedTag: IImageTag, self: ImageTagEditorComponent) {
      const updatedTags = [...self.selectedTags$.value].filter(x => x != selectedTag);

      self.publish(updatedTags);
      return true;
    };
  }

  get createTags() {
    return async function (text: string, self: ImageTagEditorComponent) {
      return setImageTagProperties(
        {
          text: text.toLowerCase(),
        },
        false
      );
    };
  }

  private publish(updatedTags?: IImageTag[]) {
    updatedTags = updatedTags || this.selectedTags$.value;

    this.selectedTags$.next(updatedTags);
    this.tagsChanged.emit(updatedTags);
  }
}
