import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as API from './api';

import { DriveClientExtended } from './drive-client-extended';
import { NotificationClientExtended } from './notification-client-extended';

const API_CLIENTS: any[] = Object.values(API).filter(x => typeof x == 'function' && x.name.endsWith('Client'));

API_CLIENTS.splice(API_CLIENTS.indexOf(API.ProjectDriveClient), 1);
API_CLIENTS.splice(API_CLIENTS.indexOf(API.NotificationClient), 1);
API_CLIENTS.push(DriveClientExtended);
API_CLIENTS.push(NotificationClientExtended);

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: API_CLIENTS,
})
export class ApiModule {
  constructor(@Optional() @SkipSelf() parentModule: ApiModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Data module in the AppModule only.`);
    }
  }
}
