<div class="c4-dialog-wrapper">
  <h1 mat-dialog-title>{{ title | translate }}</h1>
  <mat-dialog-content>
    <div>{{'planning.dialogs.planDelete.header' | translate }}</div>
    <div class="c4-file-info"><span>{{name}}</span></div>
    <div>{{'planning.dialogs.planDelete.subheader' | translate }}</div>
    <div class="c4-radio-group">
      <mat-radio-group [(ngModel)]="deleteAll">
        <mat-radio-button value="current">{{'planning.dialogs.planDelete.current' | translate }}</mat-radio-button>
        <mat-radio-button value="all">{{'planning.dialogs.planDelete.history' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="dlg-plan-list-holder" *ngIf="deleteAll==='all'">
      <ul class="dlg-plan-list">
        <li class='dlg-plan-list-item' *ngFor="let plan of map"><span>{{plan.name}}</span></li>
      </ul>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
    <button mat-flat-button color="primary" (click)="ok(id)" type="button">{{ 'general.ok' | translate }}</button>
  </mat-dialog-actions>
</div>