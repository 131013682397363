<div class="c4-dialog-wrapper multi-select-dialog">
	<h1 mat-dialog-title>{{ 'dialogs.simulation.export.title' | translate }}</h1>
	<mat-dialog-content class="content flex-container column">
		<div class="description">{{ 'dialogs.simulation.export.description' | translate }}</div>

		<div *ngIf="status$ | async as status; else filterControlsTemplate" class="flex-container column">
			<div class="flex-container slim-gap">
				<span class="mdi mdi-information-outline"></span>
				<span>{{ status.step | translate:status.params }}</span>
			</div>
			<mat-progress-bar [mode]="status.progress > 0 ? 'determine' : 'query'"
												[value]="status.progress"></mat-progress-bar>
		</div>

		<ng-template #filterControlsTemplate>
			<div class="flex-container">
				<app-simple-paginator class="grow" (changePage)="fireWeekChange(startWeek$, $event)">
					<span *ngIf="startWeek$ | async as week" class="grow text-center">
						{{ 'general.date.calenderWeekNr' | translate:{number:week.number} }} / {{ week.endOf | date:'yy' }}
					</span>
				</app-simple-paginator>
				<span class="date-seperator">-</span>
				<app-simple-paginator class="grow" (changePage)="fireWeekChange(endWeek$, $event)">
					<span *ngIf="endWeek$ | async as week" class="grow text-center">
						{{ 'general.date.calenderWeekNr' | translate:{number:week.number} }} / {{ week.endOf | date:'yy' }}
					</span>
				</app-simple-paginator>
			</div>

			<p-dropdown class="phase-select" [options]="phases" (onChange)="firePhaseChange($event.value)"
									[placeholder]="'scheduler.phase' | translate" [filter]="true" optionLabel="name" filterBy="name"
									[showClear]="true" [filterPlaceholder]="'general.actions.filter' | translate"
									[autoDisplayFirst]="false" appendTo="body">
			</p-dropdown>
		</ng-template>

		<div class="simulation-print-bg">
			<div class="simulation-print-container">
				<div class="simulation-print" id="simulation-print-source">
					<ng-container *ngIf="selectedPlanConversion$ | async as selectedPlanConversion">
						<ng-container *ngIf="selectedPlanConversion.projection as projection">
							<app-geojson-editor #editor [markers]="selectedPlanMarkers$ | async">
								<app-geojson-editor-map-layer [renderAsDiv]="true" [zoom]="projection.zoom - 1"
																							[url]="projection.url"></app-geojson-editor-map-layer>
							</app-geojson-editor>
						</ng-container>
						<ng-container *ngIf="selectedPlanConversion.worker as worker">
							<div class="plan-conversion-container">
								<mat-progress-bar mode="{{ worker.progress > 0 ? 'determine' : 'query'}}"
																	[value]="worker.progress"></mat-progress-bar>
							</div>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="fireClose()" type="button" [disabled]="isBusy">
			{{ 'general.cancel' | translate }}
		</button>
		<button mat-flat-button color="primary" (click)="fireExport()" [disabled]="isBusy">
			<span>{{ 'dialogs.simulation.export.actionExport' | translate }}</span>
		</button>
	</mat-dialog-actions>
</div>