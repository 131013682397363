export class ApiCallAbortedException extends Error {
  constructor(reason: string) {
    super(`API call aborted: ${reason}`);
  }

  protected isApiCallAbortedException = true;

  static isApiCallAbortedException(obj: any): obj is ApiCallAbortedException {
    return obj?.isApiCallAbortedException === true;
  }
}
