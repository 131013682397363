import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localDe from '@angular/common/locales/de';

import { DateAdapter } from '@angular/material/core';
import { Utils } from '@app/core/utils';
import { AppConfigService } from '../app-config';

registerLocaleData(localDe, 'de');

@Injectable({
  providedIn: 'root'
})
export class TranslationManagementService {
  defaultLanguage: string;
  supportedLanguages: string[];
  onLangChange = this.ngxTranslateService.onLangChange;

  //https://github.com/angular/angular/issues/15039
  //https://angular.io/guide/i18n

  constructor(private ngxTranslateService: TranslateService, private adapter: DateAdapter<any>) { }

  static languageCookie() {
    return `${AppConfigService.settings.cookiePrefix}appLanguage`;
  }

  set language(desiredLanguage: string) {
    desiredLanguage = desiredLanguage || this.ngxTranslateService.getBrowserCultureLang();

    let usedLanguage = TranslationManagementService.findBestMatchingLanguage(desiredLanguage, this.supportedLanguages);
    if (!usedLanguage) {
      usedLanguage = this.defaultLanguage;
    }

    Utils.setCookie(TranslationManagementService.languageCookie(), usedLanguage, 360);
    this.adapter.setLocale(usedLanguage);
    this.ngxTranslateService.use(usedLanguage);
  }

  get language(): string {
    return this.ngxTranslateService.currentLang;
  }

  static findBestMatchingLanguage(language: string, supportedLanguages: string[]) {
    if (!language) {
      language = '';
    }

    language = language.toLowerCase();
    const shortLanguage = language.split('-')[0];

    //try to find exact match
    let result = supportedLanguages.find(sl => sl.toLowerCase() === language);

    //try to remove "-" of target language (en-US -> en)
    if (!result) {
      result = supportedLanguages.find(sl => sl.toLowerCase() === shortLanguage);
    }

    //try to remove "-" of supported languages
    if (!result) {
      result = supportedLanguages.find(sl => sl.toLowerCase().split('-')[0] === shortLanguage);
    }

    return result;
  }

  init(appLanguages: string[]) {
    if (appLanguages.length < 1) throw new Error('Init Languages Error: No Language found in settings');
    this.supportedLanguages = appLanguages;
    const defaultLanguage = appLanguages[0];
    this.ngxTranslateService.setDefaultLang(defaultLanguage);
    this.defaultLanguage = defaultLanguage;
    const persistedLang = Utils.getCookie(TranslationManagementService.languageCookie());
    this.language = persistedLang;
  }

  instant(key: string): string {
    return this.ngxTranslateService.instant(key);
  }
}
