<div class="header-tabs-container" *ngIf="tabs?.length">
	<mat-tab-group class="no-content" (selectedTabChange)="selectedTabChanged($event.index)"
								 [selectedIndex]="selectedIndex">
		<mat-tab *ngFor="let tab of tabs; let i = index; track">
			<ng-template mat-tab-label>
				<div class="tab-label-container">
					<span>{{ tab.label | translate }}</span>
					<ng-container *ngIf="canEdit && !tab.isReadonly && selectedIndex == i">
						<span tabindex="-1" class="mdi mdi-pencil" (click)="editTab(i, $event)"></span>
					</ng-container>
				</div>
			</ng-template>
		</mat-tab>
	</mat-tab-group>
</div>