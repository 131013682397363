import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { FileGroup } from '@app/core/enumerations';
import { UserNotificationService } from '@app/shared/services';

@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UploadImageComponent,
    },
  ],
})
export class UploadImageComponent implements OnInit, ControlValueAccessor {
  @Input() title: string = 'general.imageUpload';
  @Input() canChangeImageCropMode: boolean;
  @Input() shouldImageCover: boolean;
  @Input() set previewImageHeightInRem(height: number) {
    this.previewImageHeight = height;
    this.setRelativeImageWidth();
  }
  /**
   * Needed to calculate ratio even if resizeImage === true
   */
  @Input() set imageWidthInPx(width: number) {
    this.imageWidth = width;
    this.setRelativeImageWidth();
  }
  /**
   * Needed to calculate ratio even if resizeImage === true
   */
  @Input() set imageHeightInPx(height: number) {
    this.imageHeight = height;
    this.setRelativeImageWidth();
  }
  @Input() resizeImage: boolean = true;

  isDisabled: boolean;
  validTypes = [FileGroup.image];
  imageFile: File;
  image: string;

  onChange = image => {};
  onTouched = () => {};

  previewImageWidth: number = 0;
  previewImageHeight: number = 0;
  private imageWidth: number = 0;
  private imageHeight: number = 0;

  constructor(private userNotification: UserNotificationService) {}

  setRelativeImageWidth() {
    this.previewImageWidth = this.imageHeight > 0 ? this.previewImageHeight * (this.imageWidth / this.imageHeight) : 0;
  }

  ngOnInit(): void {}

  onImageCoverChange(event: MatSlideToggleChange) {
    if (event.checked != this.shouldImageCover) {
      this.shouldImageCover = event.checked;
      this.calculateAndUpdateImage();
    }
  }

  async onFileChange(file: File) {
    this.imageFile = file;
    await this.calculateAndUpdateImage();
  }

  unsetImage() {
    this.imageFile = null;
    this.image = null;
    this.onChange(null);
  }

  private async calculateAndUpdateImage() {
    if (this.imageFile) {
      if (this.isIE() && this.imageFile.size > 20971520) {
        await this.userNotification.notify('setup.customization.errorUploadIE');
      }

      let reader = new FileReader();
      reader.onload = evt => {
        if (this.resizeImage) {
          let img = new Image();

          img.onload = async () => {
            let canvas = document.createElement('canvas');
            canvas.setAttribute('height', this.imageHeight + 'px');
            canvas.setAttribute('width', this.imageWidth + 'px');

            const canvasContext = canvas.getContext('2d');
            let dx: number = 0,
              dy: number = 0,
              imageHeight: number = (img.height / img.width) * canvas.width,
              imageWidth: number = canvas.width;

            const shouldFitToHeight =
              (this.shouldImageCover && imageHeight < canvas.height) || (!this.shouldImageCover && imageHeight > canvas.height);

            if (shouldFitToHeight) {
              // fit to height, center horizontally
              imageWidth = (img.width / img.height) * canvas.height;
              imageHeight = canvas.height;
              dx = (canvas.width - imageWidth) / 2;
            } else {
              // fit to width, center vertically
              dy = (canvas.height - imageHeight) / 2;
            }

            canvasContext.drawImage(img, dx, dy, imageWidth, imageHeight);

            let dataUrl = canvas.toDataURL('image/png');
            let strippedDataUrl = dataUrl.replace(/^.*,/, '');

            this.image = strippedDataUrl;
            this.markAsTouched();
            this.onChange(this.image);
          };

          img.src = <any>evt.target.result;
        } else {
          this.image = (evt.target.result as string)?.replace(/^.*,/, '');
          this.markAsTouched();
          this.onChange(this.image);
        }
      };

      reader.readAsDataURL(this.imageFile);
    }
  }

  private isIE(): boolean {
    let ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
    return is_ie;
  }

  // ----- Reactive Forms Methods -----

  writeValue(imageBase64Encoded: string): void {
    this.image = imageBase64Encoded;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  touched: boolean;
  private markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
