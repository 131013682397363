import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UserModel, UserSessionModel } from '@app/api';
import { ApiUrlService, AppConfigService, BaseSubscriptionComponent, createHybridImageUrl, LogService } from '@app/core';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import { BehaviorSubject, distinctUntilChanged, filter, map, Observable, of, pairwise, share, switchMap } from 'rxjs';

interface UserData {
  id?: string;
  modifiedOn?: Date;
}

@Component({
  selector: 'app-user-image',
  templateUrl: './user-image.component.html',
  styleUrls: ['./user-image.component.scss'],
})
export class UserImageComponent extends BaseSubscriptionComponent implements OnDestroy {
  @Input() sizeRem: number;
  @Input() tooltip: string;

  @Input() set user(user: UserModel) {
    this.userData$.next(user);
  }

  @Input() set userSession(userSession: UserSessionModel) {
    this.userData$.next({ id: userSession.userId, modifiedOn: userSession.userModifiedOn });
  }

  @Input() set userId(id: string) {
    this.userData$.next({ id });
  }

  @Output() imageClicked = new EventEmitter();

  userImageUrl$: Observable<string>;
  private userData$: BehaviorSubject<UserData>;

  constructor(public apiUrlService: ApiUrlService, public httpClient: HttpClient, public log: LogService) {
    super();

    this.userData$ = new BehaviorSubject<UserData>(null);
    this.userImageUrl$ = createHybridImageUrl(this.userData$, this);
  }

  canCreateImage(userData: UserData) {
    return userData?.id != null;
  }

  getImageUrl(userData: UserData) {
    const { id, modifiedOn } = userData;
    return `${AppConfigService.settings.api.url}/user/${id}/image${modifiedOn ? `?ts=${modifiedOn.getTime()}` : ''}`;
  }
}
