import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Utils } from '@app/core';
import { FileUtils } from '@app/core/utils/file-utils';
import { UserNotificationService } from '@app/shared/services';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  /**
   * Valid filetypes. Will be shown on systems upload dialog.
   *
   * Can be file type(s) ('application/pdf' etc.) or FileGroup(s). @see enum FileGroup
   */
  @Input()
  validTypes: string[] = [];
  @Input()
  multiple: boolean = false;
  @Input()
  clickAction: () => void;
  @Output()
  onFileUpload = new EventEmitter<File>();

  isDragDrop: boolean;

  constructor(private userNotification: UserNotificationService) {}

  ngOnInit(): void {}

  async onDrop(event: DragEvent) {
    this.enableDragDrop(event, false);
    this.fileChanged(event.dataTransfer.files);
  }

  onDragLeave(event: DragEvent) {
    this.enableDragDrop(event, false);
  }

  onDragEnd(event: DragEvent) {
    this.enableDragDrop(event, false);
  }

  onDragOver(event: DragEvent) {
    this.enableDragDrop(event, true);
  }

  onDragEnter(event: DragEvent) {
    this.enableDragDrop(event, true);
  }

  fileChanged(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      if (this.isValidFileType(file.type)) {
        this.onFileUpload.emit(file);
      } else {
        this.userNotification.notify(`general.${files.length > 1 ? 'errorFileTypeOneOf' : 'errorFileType'}`);
      }

      if (!this.multiple) {
        i = files.length;
      }
    }
  }

  private enableDragDrop(event: DragEvent, isDragDrop: boolean) {
    this.isDragDrop = isDragDrop;
    const files = event.dataTransfer.items;
    if (isDragDrop && files.length > 0) {
      let areValid = this.multiple || files.length === 1;
      for (let i = 0; i < files.length; i++) {
        if (areValid && !this.isValidFileType(files[i].type)) {
          areValid = false;
          i = files.length;
        }
      }

      if (areValid) {
        event.dataTransfer.dropEffect = 'copy';
        event.dataTransfer.effectAllowed = 'copy';
      } else {
        event.dataTransfer.dropEffect = 'none';
        event.dataTransfer.effectAllowed = 'none';
      }
    }
    event.stopPropagation();
    event.preventDefault();
  }

  private isValidFileType(type: string) {
    return FileUtils.isValidType(type, this.validTypes);
  }
}
