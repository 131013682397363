import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';

import { BehaviorSubject, firstValueFrom, from, Observable, throwError } from 'rxjs';

import { AppConfigService, LogService, AuthenticationService, OfflineService } from '@app/core/services';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ApiCallAbortedException, GlobalsService } from '..';
import { catchError, filter, switchMap, take, finalize } from 'rxjs/operators';
import { UserNotificationService } from '@app/shared/services';
import { TeamsUrlParams } from '@app/C4CustomerPortal/msteams/msteams-enums';
import { ApiUrlService } from '../services/api/api-url.service';
import { CapacitorUtils } from '../utils/capacitor-utils';
import { Dialog as CapacitorDialog } from '@capacitor/dialog';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private apiUrlService: ApiUrlService,
    private authenticationService: AuthenticationService,
    private userNotificationService: UserNotificationService,
    private globals: GlobalsService,
    private log: LogService,
    private offlineService: OfflineService,
    private router: Router,
    private translate: TranslateService
  ) {}

  intercept(originalRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isApiCall(originalRequest)) {
      //not an API call - do nothing
      return next.handle(originalRequest);
    }

    const request = originalRequest.clone({
      url: this.apiUrlService.replaceApiFQDN(originalRequest.url),
      setHeaders: {
        'x-ms-version': environment.version,
      },
    });

    if (this.log.debugEnabled()) {
      this.log.debug(`${request.method}: ${request.urlWithParams}`, request);
    }

    const accessTokenPromise = this.authenticationService.getCurrentAccessToken();
    return from(accessTokenPromise).pipe(
      switchMap(accessToken =>
        next.handle(this.addAuthenticationToken(request, accessToken)).pipe(
          catchError(error => {
            const handleError = !this.isLogoutCall(request) && error instanceof HttpErrorResponse;
            if (handleError && error.status == 401) return this.handle401Error(request, next);

            return from(
              new Promise<HttpEvent<any>>(async (_, reject) => {
                if (handleError) {
                  switch (error.status) {
                    case 0:
                      if (CapacitorUtils.isApp()) await this.offlineService.switchOfflineDueToError();
                      break;
                    case 426:
                      await this.handle426Error();
                      break;
                  }
                }

                reject(error);
              })
            );
          })
        )
      )
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return from(this.authenticationService.refreshToken()).pipe(
        switchMap(result => {
          if (result) {
            this.refreshTokenSubject.next(result.accessToken);
            return next.handle(this.addAuthenticationToken(request, result.accessToken));
          }

          let queryParamMap = this.router.routerState.snapshot.root.queryParamMap;

          if (this.globals.isTeams && this.globals.currentTeamsContentUrl) {
            // custom logic for teams, if the refreshtoken fails, redirect to logout is insufficent as we need to automatically log in again
            const redirectURI = new URL(this.globals.currentTeamsContentUrl);
            const redirectParams = redirectURI.searchParams;

            redirectParams.set(TeamsUrlParams.TeamsRedirect, 'true');

            this.authenticationService.logout().then(() => {
              window.location.assign(redirectURI.toString());
            });
          } else if (this.globals.isOAuth || queryParamMap.has('OAuth')) {
            let queryParams = this.router.routerState.snapshot.root.queryParams;
            this.router.navigate(['logout'], { queryParams: { ...queryParams, OAuth: true } });
          } else {
            this.router.navigate(['logout']);
          }

          return throwError(new ApiCallAbortedException('Not authenticated'));
        }),
        catchError(error => {
          this.userNotificationService.notify('general.errorMsg.tokenExpired', { error: error });
          return throwError(error);
        }),
        finalize(() => {
          this.isRefreshing = false;
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => {
          return next.handle(this.addAuthenticationToken(request, token));
        })
      );
    }
  }

  private async handle426Error() {
    const title = await firstValueFrom(this.translate.get('app.update.title'));
    const message = await firstValueFrom(this.translate.get('app.update.message'));

    if (!CapacitorUtils.isApp()) {
      this.userNotificationService.notify(message);
      return;
    }

    try {
      // Country needed if app is not availalbe in each country (FR for instance)
      const appUpdateInfo = await AppUpdate.getAppUpdateInfo({ country: 'DE' });

      if (appUpdateInfo.updateAvailability == AppUpdateAvailability.UPDATE_NOT_AVAILABLE) {
        this.log.warn('Server Code Update Required with no available update');
        return;
      }

      const { value: redirectToAppStore } = await CapacitorDialog.confirm({
        title,
        message,
        cancelButtonTitle: await firstValueFrom(this.translate.get('app.update.delayUpdate')),
        okButtonTitle: await firstValueFrom(this.translate.get('app.update.navigateToAppStore')),
      });

      if (redirectToAppStore) await AppUpdate.openAppStore({ country: 'DE' });
    } catch {
      await CapacitorDialog.alert({
        title,
        message,
      });
    }
  }

  private addAuthenticationToken(request: HttpRequest<any>, accessToken: string) {
    return request.clone({
      setHeaders: {
        'ngsw-bypass': 'true',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }

  private isApiCall(request: HttpRequest<any>): boolean {
    if (!AppConfigService.settings) {
      return false;
    }
    return request.url.startsWith(AppConfigService.settings.api.url);
  }

  private isAuthUserCall(request: HttpRequest<any>): boolean {
    if (!this.isApiCall(request)) {
      return;
    }

    return request.url.toLowerCase().indexOf('/authuser') >= 0;
  }

  private isLogoutCall(request: HttpRequest<any>): boolean {
    if (!this.isApiCall(request)) {
      return;
    }

    return request.url.toLowerCase().indexOf('/session/logout') >= 0;
  }
}
