import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { StandardTextModel } from '@app/api';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'app-standard-text-selection-dialog',
  templateUrl: './standard-text-selection-dialog.component.html',
  styleUrls: ['./standard-text-selection-dialog.component.scss'],
})
export class StandardTextSelectionDialogComponent implements OnInit {
  standardTexts: StandardTextModel[];
  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<StandardTextSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private formBuilder: UntypedFormBuilder
  ) {
    this.standardTexts = data?.standardTexts ?? [];
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      text: [null, [Validators.required]],
    });
  }

  close() {
    this.dialogRef.close(this.form.controls.text.value);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
