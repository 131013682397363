import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { GlobalsService } from '@app/core';

@Component({
  selector: 'app-multi-value-input',
  templateUrl: './multi-value-input.component.html',
  styleUrls: ['./multi-value-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: MultiValueInputComponent,
    },
  ],
})
export class MultiValueInputComponent implements OnInit, ControlValueAccessor {
  @Input() title: string;
  @Input() fieldName: string;
  @Input() valueAccessor: string;
  values: any[];
  isDisabled: boolean;
  isTouched: boolean;

  onChange = _ => {};
  onTouched = () => {};

  constructor(public globals: GlobalsService) {}

  ngOnInit(): void {}

  addValue() {
    this.values.push({ [this.valueAccessor]: '' });
  }

  removeValue(index: number) {
    const value = this.values.splice(index, 1)[0];
    if (value[this.valueAccessor]) this.valueChanged();
  }

  valueChanged() {
    this.markAsTouched();
    this.onChange(this.values.filter(v => v[this.valueAccessor]));
  }

  // ----- Reactive Forms Methods -----

  writeValue(values: any[]): void {
    this.values = values;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private markAsTouched() {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
  }
}
