<div class="workpackage-template-tile c4-busy-content" [style.background]="craftHexColor"
		 [style.color]="craftHexColor | contrastColor:'color'" [appTooltip]="tooltip">
	<span>{{ name }}</span>
</div>

<ng-template #tooltip>
	<div class="workpackage-template-tile-tooltip">
		<b>{{name}}</b>
		<p>{{description}}</p>
	</div>
</ng-template>