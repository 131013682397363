<div class="tree-selection-container">
	<div class="arrow-container" [class.error]="hasErrors">
		<div class="mat-select-arrow"></div>
	</div>
	<div class="placeholder" [class.selected]="treeSelect.overlayEl || hasValue" [class.error]="hasErrors">
		{{ title | translate}}
	</div>
	<p-treeSelect #treeSelect (onHide)="onTouched()" [class.error]="hasErrors" appendTo="body" [options]="tree"
								[(ngModel)]="model" [selectionMode]="selectionMode" [showTransitionOptions]="'0ms'" filter="true"
								[emptyMessage]="'general.noDataText' | translate" [propagateSelectionUp]="propagateSelection"
								[propagateSelectionDown]="propagateSelection" [metaKeySelection]="false">
	</p-treeSelect>
	<div *ngIf="hasErrors" class="custom-error">
		<span>{{ 'general.errorForms.' + error | translate }}</span>
	</div>
</div>