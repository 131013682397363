export interface IMigrationStep {}

export interface IMigration {
  steps: IMigrationStep[];

  start(db: IDBDatabase, transaction: IDBTransaction): Promise<boolean>;
  after(db: IDBDatabase, transaction: IDBTransaction): Promise<boolean>;
}

export class CreateTableMigrationStep implements IMigrationStep {
  constructor(public table: string, public keys?: string | string[]) {}
}

export class DeleteTableMigrationStep implements IMigrationStep {
  constructor(public table: string) {}
}

export class CreateTableIndexMigrationStep implements IMigrationStep {
  constructor(public table: string, public index, public keys: string | string[], public unique = false) {}
}

export class DeleteTableIndexMigrationStep implements IMigrationStep {
  constructor(public table: string, public index) {}
}

declare global {
  interface IDBRequest<T = any> {
    wait(): Promise<T>;
  }
}

IDBRequest.prototype.wait = function <T>() {
  return new Promise<T>((resolve, reject) => {
    this.onsuccess = () => {
      resolve(this.result);
    };
    this.onerror = () => {
      reject(this.result);
    };
  });
};

export function ensureMigrations(request: IDBOpenDBRequest, migrations: IMigration[]) {
  request.onupgradeneeded = async ev => {
    const db = request.result;
    const transaction = request.transaction;

    for (const migration of migrations.slice(ev.oldVersion)) {
      await migration.start(db, transaction);

      for (const step of migration.steps) {
        if (step instanceof CreateTableMigrationStep) {
          db.createObjectStore(step.table, {
            keyPath: step.keys,
          });
          continue;
        }
        if (step instanceof DeleteTableMigrationStep) {
          db.deleteObjectStore(step.table);
          continue;
        }
        if (step instanceof CreateTableIndexMigrationStep) {
          const table = transaction.objectStore(step.table);

          table.createIndex(step.index, step.keys, {
            unique: step.unique,
          });
          continue;
        }
        if (step instanceof DeleteTableIndexMigrationStep) {
          const table = transaction.objectStore(step.table);

          table.deleteIndex(step.index);
          continue;
        }

        throw new Error('Unkown Migration step');
      }

      await migration.after(db, transaction);
    }
  };
}
