import { CdkMenuItem } from '@angular/cdk/menu';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-context-menu-button',
  templateUrl: './context-menu-button.component.html',
  styleUrls: ['./context-menu-button.component.scss'],
  hostDirectives: [
    {
      directive: CdkMenuItem,
      outputs: ['cdkMenuItemTriggered: triggered'],
    },
  ],
})
export class ContextMenuButtonComponent {
  @Input() set disabled(isDisabled: boolean | string) {
    this.isDisabled = isDisabled === '' || isDisabled === true;
  }

  isDisabled = false;
}
