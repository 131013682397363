import { FileNameFormat } from '@app/core/utils/file-name-format';

export class RoomFileNameFormat extends FileNameFormat {
  roomNumber: string;
  projectNumber: string;

  constructor(format: string) {
    super(format);
  }

  public getFormattedString(parameter: string[], extension: string): string {
    if (this.roomNumber) {
      parameter.unshift(`_${this.roomNumber}`);
    }
    if (this.projectNumber) {
      parameter.unshift(`_${this.projectNumber}`);
    }

    return super.getFormattedString(parameter, extension);
  }
}
