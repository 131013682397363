import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

export enum ConflictResolveAction {
  Abort,
  KeepBoth,
  Replace
}

@Component({
  selector: 'app-name-conflict-dialog',
  templateUrl: './name-conflict-dialog.component.html',
  styleUrls: ['./name-conflict-dialog.component.scss']
})
export class NameConflictDialogComponent implements OnInit {
  fileName: string;

  constructor(public confirmRef: MatDialogRef<NameConflictDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.fileName = (data.fileName) ? data.fileName : 'FILENAME_NOT_SET';
  }

  ngOnInit() { }

  abort() {
    this.confirmRef.close(ConflictResolveAction.Abort);
  }

  keepBoth() {
    this.confirmRef.close(ConflictResolveAction.KeepBoth);
  }

  replace() {
    this.confirmRef.close(ConflictResolveAction.Replace);
  }
}
