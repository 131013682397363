import { PipeTransform, TemplateRef } from '@angular/core';
import { SortMeta } from 'primeng/api/sortmeta';
export enum C4GridFilterType {
  text = 'text',
  date = 'date',
  select = 'select',
  multiselect = 'multiselect',
}
export enum C4GridMatchMode {
  startsWith = 'startsWith',
  endsWidth = 'endsWidth',
  contains = 'contains',
  exact = 'exact',
  lt = 'lt',
  lte = 'lte',
  gt = 'gt',
  gte = 'gte',
  equals = 'equals',
  in = 'in',
}
export interface C4SubCol {
  field: string;
  cssClass?: string;
  pipe?: PipeTransform;
  pipeArg?: string;
}
export interface C4GridSelectOptions {
  label: string;
  value?: any;
  range?: number[];
  visible?: boolean;
}

export interface C4GridColumn {
  field: string;
  header?: string;
  noTranslate?: boolean;
  width: string;
  minWidth?: string;
  sub?: Array<C4SubCol>;
  sortable?: boolean;
  priority?: number;
  cssClass?: string;
  filterType?: C4GridFilterType;
  filterMatchMode?: C4GridMatchMode;
  options?: Array<C4GridSelectOptions>;
  pipe?: PipeTransform;
  pipeArg?: string;
  isRange?: boolean;
  template?: TemplateRef<any>;
  headerTemplate?: TemplateRef<any>;
  mobileTemplate?: TemplateRef<any>;
  hidden?: boolean;
}
export interface C4GridRow {
  link?: boolean;
  path?: string;
  param?: string;
}
export interface C4Grid {
  filterRow?: boolean;
  globalFilter?: boolean;
  responsive?: boolean;
  mobileExpanded?: boolean;
  rowExpand?: boolean;
  paging?: boolean;
  rows: number;
  rowsOptions: number[];
  lazy: boolean;
  lazyInit: boolean;
  rowCount?: number;
  scrollable?: boolean;
  scrollableHeight?: string;
  prefix?: string;
}

export interface C4GridDef {
  grid: C4Grid;
  row: C4GridRow;
  cols: Array<C4GridColumn>;
  initialSorting: SortMeta[];
}

export interface LazySort {
  field: string;
  order: number;
}

export interface LazyRequest {
  filters: any;
  first: number;
  globalFilter: any;
  multiSortMeta: LazySort[];
  rows: number;
  sortField: string;
  sortOrder: number;
}
