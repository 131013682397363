import { LRTaskState } from '@app/api';
import { ApiService } from '@app/core/services';
import { concat, from, merge, Observable, of, OperatorFunction, pipe } from 'rxjs';
import { concatMap, map, mergeMap, switchMap, takeWhile } from 'rxjs/operators';
import { LongRunningTaskService } from './long-running-task.service';

export function convertPlan(apiService: ApiService, lrTaskService: LongRunningTaskService) {
  return switchMap(function (source: PlanSource | null): Observable<PlanConversionStatus> {
    if (!source) {
      return of(null);
    }

    const start: PlanConversionStatus = {
      state: false,
      source: source,
    };

    const first = of(start);
    const conversion = first.pipe(
      mergeMap(async function (obs: PlanConversionStatus): Promise<PlanConversionStatus> {
        const { source } = obs;

        const response = await apiService.getLatestOrQueueConversion(source.metadataId).toPromise();
        if (response.taskState == LRTaskState.Success) {
          return {
            state: true,
            source: source,
          };
        }

        return {
          state: false,
          source: obs.source,
          worker: {
            progress: -1,
            converter: response.taskId,
          },
        };
      }),
      switchMap(function (obs: PlanConversionStatus): Observable<PlanConversionStatus> {
        if (obs.state) {
          return of(obs);
        }

        const stats = new Estimation();
        const source = obs.source;
        const { converter } = obs.worker;

        return lrTaskService.getObservableForTask(obs.worker.converter).pipe(
          takeWhile(function (snapshot) {
            return snapshot.state == LRTaskState.Pending || snapshot.state == LRTaskState.Working;
          }, true),
          map(function (obs) {
            const state = obs.state;
            const progressInPrecent = obs.progress?.percent;
            const startedOn = obs.startedOn || new Date();

            if (state == LRTaskState.Failure) {
              throw new Error('Plan conversion failed');
            }

            const date = new Date();
            const diff = stats.next(date.getTime() - startedOn.getTime(), progressInPrecent);

            return {
              state: state == LRTaskState.Success,
              source: source,
              worker: {
                estimate: diff <= 0 ? null : new Date(startedOn.getTime() + diff),
                progress: progressInPrecent ? Math.floor(progressInPrecent * 100) : -1,
                converter: converter,
              },
            };
          })
        );
      })
    );

    return concat(first, conversion).pipe(
      mergeMap(async function (obs: PlanConversionStatus): Promise<PlanConversionStatus> {
        if (obs.state == false) {
          return obs;
        }

        const specs = await apiService.specsGroundPlan(source.metadataId).toPromise();

        return {
          state: true,
          source: source,
          projection: {
            url: `/api/projects/${source.projectId}/plans/${source.metadataId}/tile/{z}/{x}/{y}`,
            name: specs.name,
            depth: specs.depth,
          },
        };
      })
    );
  });
}

export interface PlanSource {
  projectId: string;
  metadataId: string;
}

export interface PlanConversionStatus {
  state: boolean;
  source: PlanSource;
  worker?: {
    estimate?: Date;
    progress: number;
    converter: string;
  };
  projection?: {
    url: string;
    name: string;
    depth: number;
  };
}

export class Estimation {
  static high = 6;
  static base = 2;

  private delta = 0;

  public next(value: number, progress: number) {
    if (progress) {
      return this.accumluate(value / progress);
    }

    return this.delta;
  }

  private accumluate(value: number) {
    const maximum = Estimation.base + Estimation.high;

    if (value < this.delta) {
      return (this.delta = (this.delta * Estimation.high + value * Estimation.base) / maximum);
    } else {
      return (this.delta = (this.delta * Estimation.base + value * Estimation.high) / maximum);
    }
  }
}
