import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FormComponent } from '@app/core/utils/form-component';
import { DependencyType } from '../interfaces';
import { ApiService, LogService, Utils } from '@app/core';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { UserNotificationService } from '@app/shared/services';
import { LeanPhaseModel, LeanSwimlaneModel, LeanWorkpackageModel } from '@app/api';

@Component({
  selector: 'app-add-dependency-dialog',
  templateUrl: './add-dependency-dialog.component.html',
  styleUrls: ['./add-dependency-dialog.component.scss'],
})
export class AddDependencyDialogComponent extends FormComponent implements Busy, OnInit {
  isBusy: boolean;
  dependencyTypes: KeyValue[] = [];
  DependencyType = DependencyType;

  workpackages: LeanWorkpackageModel[] = [];
  phasesWithSwimlanes: LeanPhaseModel[] = [];

  private existingWorkpackageIds: string[] = [];
  private existingSwimlaneIds: string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private apiService: ApiService,
    private dialogRef: MatDialogRef<AddDependencyDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private log: LogService,
    private userNotification: UserNotificationService
  ) {
    super();

    if (data?.existingWorkpackageIds) this.existingWorkpackageIds = data.existingWorkpackageIds;
    if (data?.existingSwimlaneIds) this.existingSwimlaneIds = data.existingSwimlaneIds;
  }

  async ngOnInit() {
    this.dependencyTypes = Object.values(DependencyType).map(type => ({
      key: type,
      value: 'workpackages.dependencyType.' + type,
    }));

    this.initForm();

    await this.loadData();
  }

  cancel() {
    this.dialogRef.close(null);
  }

  confirm() {
    const dependencyType = this.f.dependencyType.value;
    let dependency: LeanWorkpackageModel | LeanSwimlaneModel;
    switch (dependencyType) {
      case DependencyType.predecessor:
      case DependencyType.successor:
        dependency = this.f.dependency.value;
        break;
      case DependencyType.foreignSwimlane:
        const swimlaneId = this.f.dependency.value;
        dependency = this.phasesWithSwimlanes.flatMap(phase => phase.swimlanes).find(swimlane => swimlane.id == swimlaneId);
        break;
      default:
        this.log.error('DependencyType not implemented');
        return;
    }

    this.dialogRef.close({
      dependencyType,
      dependency,
    });
  }

  private initForm() {
    this.form = this.formBuilder.group({
      dependencyType: [DependencyType.foreignSwimlane, [Validators.required]],
      dependency: [null, [Validators.required]],
    });

    this.f.dependencyType.valueChanges.subscribe(_ => this.f.dependency.setValue(null));
  }

  async loadData() {
    using(new BusyScope(this), async _ => {
      const [workpackages, phases] = await Promise.all([
        Utils.enhanceException(this.apiService.getWorkpackages(), 'workpackages'),
        Utils.enhanceException(this.apiService.getPhases(), 'phases'),
      ]);

      this.workpackages = workpackages.filter(workpackage => !this.existingWorkpackageIds.contains(workpackage.id));

      const phasesWithSwimlanes = [];
      for (const phase of phases) {
        if (phase.swimlanes) {
          phase.swimlanes = phase.swimlanes.filter(swimlane => !this.existingSwimlaneIds.contains(swimlane.id));

          if (phase.swimlanes.length > 0) phasesWithSwimlanes.push(phase);
        }
      }

      this.phasesWithSwimlanes = phasesWithSwimlanes;
    }).catch(e => {
      this.userNotification.notifyFailedToLoadDataAndLog(
        'general.errorFailedToLoadDataKeys.' + e.translationKey ?? 'workpackages',
        e
      );

      this.dialogRef.close(null);
    });
  }
}
