<div [formGroup]="geoLocationForm" class="geo-location-form">
	<ng-container [ngTemplateOutlet]="decimalFormField" [ngTemplateOutletContext]="{ fieldName: 'latitude' }">
	</ng-container>
	<!-- Default == Submit == Enter will trigger button == not good -->
	<button mat-icon-button type="button" (click)="selectLocation()"
					[matTooltip]="'geoLocation.mapsLink.tooltip' | translate">
		<span class="mdi mdi-map-marker"></span>
	</button>
	<ng-container [ngTemplateOutlet]="decimalFormField" [ngTemplateOutletContext]="{ fieldName: 'longitude' }">
	</ng-container>
</div>

<ng-template #decimalFormField let-fieldName="fieldName" [formGroup]="geoLocationForm">
	<mat-form-field>
		<mat-label>{{ 'geoLocation.' + fieldName | translate }}</mat-label>
		<input matInput [formControlName]="fieldName">
		<mat-error *ngIf="f[fieldName].invalid">
			{{ 'general.errorForms.' + getError(fieldName) | translate }}
		</mat-error>
	</mat-form-field>
</ng-template>