<div class="comment">
	<app-user-image [userId]="comment.editorId" sizeRem="3.6">
	</app-user-image>

	<div class="comment-bubble" [class.edit]="isEdit" [class.c4-busy]="isBusy">
		<div class="content-row center">
			<span class="c4-busy-content user">{{ comment.createdBy }}</span>
			<span class="grow"></span>
			<ng-container *ngIf="!isEdit && comment.editorId === userId">
				<span class="action mdi mdi-pencil c4-busy-hidden" (click)="editComment()"></span>
				<span class="action mdi mdi-trash-can-outline c4-busy-hidden" (click)="deleteComment()"></span>
			</ng-container>
			<span class="light c4-busy-content">{{ comment.createdOn | localizedDate:'relative' }}</span>
			<span *ngIf="comment.createdOn.getTime() != comment.modifiedOn.getTime()" class="light c4-busy-content"
						[title]="comment.modifiedOn | localizedDate:'short'">
				({{ 'general.edited' | translate }})
			</span>
		</div>

		<div *ngIf="!isEdit; else editCommentTemplate" class="content-row">
			<div class="multiline-comment grow c4-busy-content">
				{{ comment.text }}
			</div>

			<app-bim-viewpoint *ngIf="comment.viewpoint" [canAddViewpoint]="false" [mainViewpoint]="comment.viewpoint"
												 (viewpointClicked)="service.selectViewpoint($event)"></app-bim-viewpoint>
		</div>

		<ng-template #editCommentTemplate>
			<div class="content-row">
				<textarea matInput class="comment-input grow c4-busy-content" [(ngModel)]="comment.text"
									[ngModelOptions]="{ standalone: true }" (keydown.control.enter)="onEnter($event)">
				</textarea>

				<app-bim-viewpoint *ngIf="viewpointsLoader != null" class="comment-viewpoint"
													 [canAddViewpoint]="canAddViewpoint" [viewpointCount]="viewpointCount"
													 [showViewpointCount]="false" [mainViewpoint]="comment.viewpoint"
													 [viewpointsLoader]="viewpointsLoader" deletionMode="main" (newViewpoint)="addViewpoint()"
													 (viewpointClicked)="viewpointClicked($event)"
													 (deleteViewpoint)="removeViewpoint()"></app-bim-viewpoint>
			</div>

			<div class="content-row center">
				<span class="grow"></span>
				<button mat-stroked-button (click)="cancelEditComment()">
					<span>{{ 'general.cancel' | translate }}</span>
				</button>
				<button mat-stroked-button (click)="updateComment()">
					<span>{{ 'general.save' | translate }}</span>
				</button>
			</div>
		</ng-template>
	</div>
</div>