import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Inject } from '@angular/core';
import { Busy } from '@app/shared/utils/busy';
import { FormComponent } from '@app/core/utils/form-component';

export abstract class DialogBase<T> extends FormComponent implements Busy {
  public isBusy: boolean;
  public title: string;
  public params: any;

  constructor(public dialogRef: MatDialogRef<T>, @Inject(MAT_DIALOG_DATA) data: any) {
    super();

    this.readData(data);
    this.initialize();
  }

  protected readData(data: any) {
    this.title = data.title ? data.title : 'TITLE_NOT_SET';
    this.params = data.params ? data.params : undefined;
  }
  protected initialize() {}

  public confirm() {
    this.dialogRef.close(true);
  }
  public cancel() {
    this.dialogRef.close(false);
  }
}
