import { UntypedFormBuilder, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { IUserModel, TeamConfigPrivilegeModel } from '@app/api';
import { FormUtils } from '@app/core';
import { Busy } from '@app/shared/utils/busy';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';

export interface PrivilegeRowData {
  name: string;
  description?: string;
  isSystemRole?: boolean;
}

export interface Role extends PrivilegeRowData {
  id: string;
  roles: string[];
}

export interface IndividualPrivilege {
  id: string;
  number: number;
  privileges: SingleIndividualPrivilege[];
}

export interface SingleIndividualPrivilege {
  number: number;
  isSet: boolean;
}

export class IndividualPrivilegeService implements Busy {
  isBusy: boolean = true;

  private _gridTemplateColumnsStyle: string;

  private _individualPrivileges: UntypedFormArray;
  private _singlePrivileges: TeamConfigPrivilegeModel[] = [];

  private _privilegeNames: string[] = [];
  private _multiPrivileges: TeamConfigPrivilegeModel[] = [];
  private _roleAndUserData: Record<string, PrivilegeRowData> = {};

  private _systemRoleForms: UntypedFormGroup[] = [];
  private _roleForms: UntypedFormGroup[] = [];
  private _userForms: UntypedFormGroup[] = [];

  private _allRoles: Role[] = [];
  private _allUsers: IUserModel[] = [];
  private _availableRoles: Role[] = [];
  private _availableUsers: IUserModel[] = [];

  private static readonly isInheritedControlName = 'isInherited';

  constructor(private dialog: MatDialog, private formBuilder: UntypedFormBuilder, private translate: TranslateService) {
    this.initData();
  }

  get readOnly(): boolean {
    return this._individualPrivileges?.disabled ?? true;
  }

  get gridTemplateColumnsStyle(): string {
    return this._gridTemplateColumnsStyle;
  }

  get privilegeNames(): readonly string[] {
    return this._privilegeNames;
  }

  get multiPrivileges(): readonly TeamConfigPrivilegeModel[] {
    return this._multiPrivileges;
  }

  get systemRoleForms(): readonly UntypedFormGroup[] {
    return this._systemRoleForms;
  }

  get roleForms(): readonly UntypedFormGroup[] {
    return this._roleForms;
  }

  get availableRoles(): readonly Role[] {
    return this._availableRoles;
  }

  get userForms(): readonly UntypedFormGroup[] {
    return this._userForms;
  }

  get availableUsers(): readonly IUserModel[] {
    return this._availableUsers;
  }

  get roleAndUserData(): Record<string, PrivilegeRowData> {
    return this._roleAndUserData;
  }

  disable() {
    const allForms = this.systemRoleForms.concat(this.roleForms.concat(this.userForms));
    for (const form of allForms) form.disable({ emitEvent: false });
    this._availableRoles = null;
    this._availableUsers = null;
  }

  setData(
    singlePrivileges: TeamConfigPrivilegeModel[],
    multiPrivileges: TeamConfigPrivilegeModel[],
    individualPrivileges: UntypedFormArray,
    parentValues: Record<string, number>,
    allRoles: Role[],
    allUsers: IUserModel[],
    roleAndUserData: Record<string, PrivilegeRowData> = null
  ) {
    this._allRoles = allRoles ?? [];
    this._allUsers = allUsers ?? [];
    this._roleAndUserData = roleAndUserData ?? this.getRoleAndUserData(allRoles, allUsers);

    this._privilegeNames = singlePrivileges.map(p => `individualPrivileges.privilege.${p.displayName}`);
    this._singlePrivileges = singlePrivileges;
    this._multiPrivileges = multiPrivileges;

    this.setGridStyle();

    this._individualPrivileges = individualPrivileges;
    this._systemRoleForms = [];
    this._roleForms = [];
    this._userForms = [];

    const missingParentIds = Object.keys(parentValues);
    for (const individualPrivilege of individualPrivileges.controls as UntypedFormGroup[]) {
      const id = FormUtils.getFormValue<IndividualPrivilege>(individualPrivilege, 'id');

      const parentIndex = missingParentIds.indexOf(id);
      if (parentIndex >= 0) missingParentIds.splice(parentIndex, 1);

      const parent = parentValues[id];
      this.addEnhancedIndividualPrivilege(individualPrivileges, individualPrivilege, parent);
    }

    for (const missingParentId of missingParentIds) {
      const inheritedForm = IndividualPrivilegeService.getIndividualPrivilegeGroup(
        this.formBuilder,
        missingParentId,
        parentValues[missingParentId],
        singlePrivileges,
        true
      );

      if (this.readOnly) inheritedForm.disable({ emitEvent: false });

      this.addEnhancedIndividualPrivilege(individualPrivileges, inheritedForm, parentValues[missingParentId], true);
    }

    this.isBusy = false;
  }

  addIndividualPrivilege(entityId: string) {
    const individualPrivilege = IndividualPrivilegeService.getIndividualPrivilegeGroup(
      this.formBuilder,
      entityId,
      0,
      this._singlePrivileges
    );
    this._individualPrivileges.push(individualPrivilege);
    this._individualPrivileges.markAsDirty();
    this.addEnhancedIndividualPrivilege(this._individualPrivileges, individualPrivilege);

    this.setAvailableEntities();
  }

  async removeIndividualPrivilege(individualPrivilege: UntypedFormGroup) {
    const isSystemRole = this.systemRoleForms.indexOf(individualPrivilege) >= 0;
    if (isSystemRole) throw 'Cannot remove system role!';

    const formIndex = this._individualPrivileges.controls.indexOf(individualPrivilege);
    if (formIndex < 0) throw 'Individual Privilege not found!';

    const id = FormUtils.getFormValue<IndividualPrivilege>(individualPrivilege, 'id');
    const name = this.roleAndUserData[id]?.name ?? '';
    const hasConfirmed = await this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'general.confirmation.deleteCaption',
          description: 'general.confirmation.deleteDescription',
          params: {
            element: await this.translate.get('projectConfig.structure.individualPrivilege').toPromise(),
            name,
          },
        },
      })
      .afterClosed()
      .toPromise();

    if (!hasConfirmed) return;

    this._individualPrivileges.removeAt(formIndex);
    this._individualPrivileges.markAsDirty();

    let internalFormArray = this._roleForms;
    let internalIndex = internalFormArray.indexOf(individualPrivilege);
    if (internalIndex < 0) {
      internalFormArray = this._userForms;
      internalIndex = internalFormArray.indexOf(individualPrivilege);
    }

    if (internalIndex >= 0) internalFormArray.splice(internalIndex, 1);

    this.setAvailableEntities();
  }

  dispose() {
    const individualPrivileges = (this._individualPrivileges?.controls ?? []) as UntypedFormGroup[];
    for (const individualPrivilege of individualPrivileges)
      individualPrivilege.removeControl(IndividualPrivilegeService.isInheritedControlName);
  }

  private initData() {
    const fakeId = 'fakeId';
    this._roleAndUserData = {
      [fakeId]: {
        name: 'fakeData',
        description: 'fakeData',
      },
    };

    this._privilegeNames = [];
    this._systemRoleForms = [];
    this._roleForms = [];
    this._userForms = [];

    const fakeIndividualPrivilegeForm = this.formBuilder.group({
      id: [fakeId],
      number: [0],
      privileges: this.formBuilder.array([]),
    });

    for (let i = 0; i < 5; i++) {
      this._privilegeNames.push('fakeData');

      const array = fakeIndividualPrivilegeForm.controls.privileges as UntypedFormArray;
      array.push(this.formBuilder.group({ isSet: [false] }));
    }

    this._systemRoleForms.push(fakeIndividualPrivilegeForm);
    this._roleForms.push(fakeIndividualPrivilegeForm);
    this._userForms.push(fakeIndividualPrivilegeForm);

    this.setGridStyle();
  }

  private getRoleAndUserData(allRoles: Role[], allUsers: IUserModel[]) {
    const roleAndUserData: Record<string, PrivilegeRowData> = {};

    for (const role of allRoles) {
      if (!role?.id) continue;

      roleAndUserData[role.id] = {
        name: role.name,
        description: role.description,
        isSystemRole: role.isSystemRole,
      };
    }

    for (const user of allUsers) {
      if (!user?.id) continue;

      roleAndUserData[user.id] = {
        name: user?.username,
        description: user?.emailAddress,
      };
    }

    return roleAndUserData;
  }

  private addEnhancedIndividualPrivilege(
    individualPrivileges: UntypedFormArray,
    individualPrivilegeForm: UntypedFormGroup,
    parentValue: number = undefined,
    isInherited: boolean = false
  ) {
    if (parentValue != undefined) {
      const inheritedControl = this.formBuilder.control({ value: isInherited, disabled: individualPrivilegeForm.disabled });
      inheritedControl.valueChanges.subscribe(
        this.getInheritedChangedCallback(individualPrivileges, individualPrivilegeForm, parentValue)
      );
      individualPrivilegeForm.addControl(IndividualPrivilegeService.isInheritedControlName, inheritedControl);
    } else if (isInherited) {
      throw 'Must provide parent value for inherited privilege';
    }

    const individualPrivilegeId = FormUtils.getFormValue<IndividualPrivilege>(individualPrivilegeForm, 'id');
    const role = this._allRoles.find(r => r.id == individualPrivilegeId);
    if (!role) {
      const index = this.getPositionForIndividualPrivilege(individualPrivilegeForm, this._userForms);
      this._userForms.splice(index, 0, individualPrivilegeForm);
    } else if (role.isSystemRole) {
      const index = this.getPositionForIndividualPrivilege(individualPrivilegeForm, this._systemRoleForms);
      this._systemRoleForms.splice(index, 0, individualPrivilegeForm);
    } else {
      const index = this.getPositionForIndividualPrivilege(individualPrivilegeForm, this._roleForms);
      this._roleForms.splice(index, 0, individualPrivilegeForm);
    }

    this.setAvailableEntities();
  }

  private getInheritedChangedCallback(
    individualPrivileges: UntypedFormArray,
    individualPrivilege: UntypedFormGroup,
    parentValue: number
  ) {
    // const individualPrivileges = FormUtils.getFormControl<PcfDirectory, UntypedFormArray>(this.form, 'individualPrivileges');

    return (isInherited: boolean) => {
      const numberControl = FormUtils.getFormControl<IndividualPrivilege>(individualPrivilege, 'number');
      const privilegesControl = FormUtils.getFormControl<IndividualPrivilege>(individualPrivilege, 'privileges');

      if (isInherited) {
        const individualPrivilegeIndex = individualPrivileges.controls.indexOf(individualPrivilege);
        if (individualPrivilegeIndex >= 0) individualPrivileges.removeAt(individualPrivilegeIndex);

        numberControl.setValue(parentValue);
        numberControl.disable({ emitEvent: false });

        privilegesControl.disable({ emitEvent: false });
      } else {
        numberControl.enable({ emitEvent: false });
        privilegesControl.enable({ emitEvent: false });

        const individualPrivilegeIndex = this.getPositionForIndividualPrivilege(
          individualPrivilege,
          individualPrivileges.controls as UntypedFormGroup[]
        );
        if (individualPrivilegeIndex < 0) individualPrivileges.push(individualPrivilege);
        else individualPrivileges.insert(individualPrivilegeIndex, individualPrivilege);
      }

      individualPrivileges.markAsDirty();
    };
  }

  private getPositionForIndividualPrivilege(
    individualPrivilege: UntypedFormGroup,
    otherIndividualPrivileges: UntypedFormGroup[]
  ) {
    const individualPrivilegeId = FormUtils.getFormValue<IndividualPrivilege>(individualPrivilege, 'id');
    const individualPrivilegeName = this.roleAndUserData[individualPrivilegeId]?.name;
    const predecessorIndex = otherIndividualPrivileges.findIndex(other => {
      const otherId = FormUtils.getFormValue<IndividualPrivilege>(other, 'id');
      return individualPrivilegeName < this.roleAndUserData[otherId]?.name;
    });

    return predecessorIndex < 0 ? otherIndividualPrivileges.length : predecessorIndex;
  }

  private setAvailableEntities() {
    const setRoleIds = this.roleForms.map(roleControl => FormUtils.getFormValue<IndividualPrivilege>(roleControl, 'id'));
    this._availableRoles = this._allRoles
      .filter(r => !r.isSystemRole && !r.roles.length && !setRoleIds.includes(r.id))
      .map(r => ({ displayName: r.name, ...r }));

    const setUserIds = this.userForms.map(userControl => FormUtils.getFormValue<IndividualPrivilege>(userControl, 'id'));
    this._availableUsers = this._allUsers.filter(u => !setUserIds.includes(u.id)).map(u => ({ displayName: u.username, ...u }));
  }

  private setGridStyle() {
    this._gridTemplateColumnsStyle = `auto repeat(${this.privilegeNames.length}, 1fr) auto`;
  }

  static getIndividualPrivilegeGroup(
    formBuilder: UntypedFormBuilder,
    id: string,
    number: number,
    privileges: TeamConfigPrivilegeModel[],
    isInherited: boolean = false
  ) {
    const individualDirectoryPrivilegeGroup: DictWithKeysOf<IndividualPrivilege> = {
      id: [id],
      number: [{ value: number, disabled: isInherited }],
      privileges: formBuilder.array(
        privileges.map(p => {
          // build form
          const privilegeGroup: DictWithKeysOf<SingleIndividualPrivilege> = {
            isSet: [{ value: (number & p.number) == p.number, disabled: isInherited }],
            number: [p.number],
          };

          return formBuilder.group(privilegeGroup);
        })
      ),
    };

    const formGroup = formBuilder.group(individualDirectoryPrivilegeGroup);

    const numberControl = FormUtils.getFormControl<IndividualPrivilege>(formGroup, 'number');
    numberControl.valueChanges.subscribe(n => {
      const privilegesFormArray = FormUtils.getFormControl<IndividualPrivilege, UntypedFormArray>(formGroup, 'privileges');
      for (const privilegeGroup of privilegesFormArray.controls as UntypedFormGroup[]) {
        const singlePrivilegeNumber = FormUtils.getFormValue<SingleIndividualPrivilege>(privilegeGroup, 'number');
        FormUtils.getFormControl<SingleIndividualPrivilege>(privilegeGroup, 'isSet').setValue(
          (n & singlePrivilegeNumber) == singlePrivilegeNumber,
          {
            emitEvent: false,
          }
        );
      }
    });

    FormUtils.getFormControl<IndividualPrivilege>(formGroup, 'privileges').valueChanges.subscribe(privileges => {
      numberControl.setValue(
        privileges.reduce(
          (previousNumber, privilege) => (privilege.isSet ? previousNumber | privilege.number : previousNumber),
          0
        ),
        { emitEvent: false }
      );
    });

    return formGroup;
  }

  static parseIndividualPrivileges(individualPrivileges: UntypedFormArray): Record<string, number> {
    return individualPrivileges.controls.reduce((privilege: Record<string, number>, individualPrivilege: UntypedFormGroup) => {
      const id = FormUtils.getFormValue<IndividualPrivilege>(individualPrivilege, 'id');
      const number = FormUtils.getFormValue<IndividualPrivilege>(individualPrivilege, 'number');

      privilege[id] = number;

      return privilege;
    }, {});
  }
}
