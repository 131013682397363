<div class="dialog-container">
	<h1 mat-dialog-title>{{ 'settings.user.title' | translate }}</h1>
	<mat-dialog-content class="user-settings-container">
		<h3>{{ 'settings.user.consent.title' | translate }}</h3>
		<span *ngIf="!consents?.length">{{ 'settings.user.consent.notFound' | translate }}</span>
		<mat-accordion class="consent-accordion">
			<mat-expansion-panel *ngFor="let consent of consents; let i = index" [expanded]="openConsent === i"
													 (opened)="openConsent = i" hideToggle>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<h3>
							{{ 'settings.user.consent.header' | translate:{ clientId: consent.clientId } }}
						</h3>
						<span class="mdi"
									[class]="consent.isBusy ? 'mdi-loading' : consent.isRevoked ? 'mdi-window-close revoked' : 'mdi-undo-variant'"
									[class.mdi-spin]="consent.isBusy" (click)="revokeConsent($event, consent)"
									[matTooltip]="'general.' + (consent.isBusy ? 'loading' : consent.isRevoked ? 'revoked' : 'revoke') | translate">
						</span>
					</mat-panel-title>
				</mat-expansion-panel-header>

				<ul class="consent-scope-list">
					<li *ngFor="let right of consent.scopes">
						<div>{{'consent.scope.' + right | translate }}</div>
					</li>
				</ul>
			</mat-expansion-panel>
		</mat-accordion>
	</mat-dialog-content>
	<mat-dialog-actions>
		<!-- <button mat-flat-button (click)="cancel()" type="button">
			{{ 'general.cancel' | translate }}
		</button> -->
		<button mat-flat-button color="primary" (click)="dialogRef.close()">
			{{ 'general.ok' | translate }}
		</button>
	</mat-dialog-actions>
</div>