import { Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';
import { FileGroup } from '@app/core/enumerations';
import { FileUtils } from '@app/core/utils/file-utils';
import { UserNotificationService } from '@app/shared/services';

@Component({
  selector: 'app-crop-image-dialog',
  templateUrl: './crop-image-dialog.component.html',
  styleUrls: ['./crop-image-dialog.component.scss'],
})
export class CropImageDialogComponent implements OnInit {
  validTypes: string[] = [FileGroup.image];

  title: string;
  description: string;

  isColumnLayout: boolean;
  fallbackTemplate: TemplateRef<any>;

  image: string;
  imageFile: File;
  imageWidth: number;
  imageHeight: number;

  previewImageWidth: number;
  previewImageHeight: number;
  shouldImageCover: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<CropImageDialogComponent>,
    private userNotification: UserNotificationService
  ) {
    this.title = data?.title ?? 'general.actions.uploadImage';
    this.description = data?.description;
    this.image = data?.image;
    this.imageWidth = data?.imageWidth ?? 512;
    this.imageHeight = data?.imageHeight ?? 512;
    this.previewImageWidth = data?.previewImageWidth ?? 36;
    this.fallbackTemplate = data?.fallbackTemplate;
    this.isColumnLayout = !!data?.isColumnLayout;
  }

  ngOnInit() {
    this.previewImageHeight = (this.previewImageWidth / this.imageWidth) * this.imageHeight;
  }

  confirm() {
    this.dialogRef.close(this.getDialogResult(true));
  }

  cancel() {
    this.dialogRef.close(this.getDialogResult());
  }

  async onImageCoverChange(event: MatSlideToggleChange) {
    if (event.checked != this.shouldImageCover) {
      this.shouldImageCover = event.checked;
      await this.calculateAndUpdateImage();
    }
  }

  async selectImage(file: File) {
    if (FileUtils.isValidType(file.type, this.validTypes)) {
      this.imageFile = file;
      await this.calculateAndUpdateImage();
    } else {
      this.userNotification.notify('general.errorFileType');
    }
  }

  async calculateAndUpdateImage() {
    try {
      this.image = await FileUtils.getEncodedImage(this.imageFile, {
        imageHeight: this.imageHeight,
        imageWidth: this.imageWidth,
        shouldImageCover: this.shouldImageCover,
      });
    } catch (e) {
      this.userNotification.notify(e, { error: e });
    }
  }

  private getDialogResult(confirmed: boolean = false) {
    return {
      confirmed,
      image: confirmed ? this.image : null,
    };
  }
}
