<div [formGroup]="addressForm">
    <div class="form">
        <ng-container [ngTemplateOutlet]="addressFieldTemplate"
            [ngTemplateOutletContext]="{fieldName: 'street', classes: 'c4-full-width'}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="addressFieldTemplate"
            [ngTemplateOutletContext]="{fieldName: 'zipCode', classes: 'c4-half-width'}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="addressFieldTemplate"
            [ngTemplateOutletContext]="{fieldName: 'city', classes: 'c4-half-width c4-half-width-right'}">
        </ng-container>
        <ng-container [ngTemplateOutlet]="addressFieldTemplate"
            [ngTemplateOutletContext]="{fieldName: 'country', classes: 'c4-full-width'}">
        </ng-container>
    </div>
</div>

<ng-template #addressFieldTemplate let-fieldName="fieldName" let-classes="classes"
    let-translationPrefix="translationPrefix" [formGroup]="addressForm">
    <mat-form-field [ngClass]="classes">
        <mat-label>{{ 'general.address.' + fieldName | translate }}{{ addressRequired ? ' *' : ''}}</mat-label>
        <input matInput [formControlName]="fieldName">
        <mat-error *ngIf="f[fieldName].invalid">
            {{ 'general.errorForms.' + getError(fieldName) | translate }}
        </mat-error>
    </mat-form-field>
</ng-template>