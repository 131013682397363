import { Component, Input } from '@angular/core';
import { LegacyProgressBarMode as ProgressBarMode } from '@angular/material/legacy-progress-bar';

export enum ProgressType {
  determinate = 'determinate',
  indeterminate = 'indeterminate',
  buffer = 'buffer',
  query = 'query',
}

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss'],
})
export class ProgressBarComponent {
  @Input() type: ProgressBarMode = ProgressType.indeterminate;
  @Input() progress: number = -1;
  @Input() bufferProgress: number = 0;
  @Input() isVisible: boolean = true;

  constructor() {}
}
