<div class="c4-dialog-wrapper maps-link-dialog">
	<h2>{{ 'geoLocation.mapsLink.title' | translate }}</h2>
	<mat-dialog-content>
		<p>{{ 'geoLocation.mapsLink.description' | translate }}</p>
		<mat-form-field>
			<mat-label>{{ 'general.link' | translate }}</mat-label>
			<input #linkInput matInput (input)="tryParseLink(linkInput.value)">
		</mat-form-field>
		<div class="view-container">
			<span>{{ 'geoLocation.latitude' | translate }}: </span>
			<span>{{ latitude }}</span>
		</div>
		<div class="view-container">
			<span>{{ 'geoLocation.longitude' | translate }}: </span>
			<span>{{ longitude }}</span>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
		<button mat-flat-button color="primary" (click)="close()" type="button">{{ 'general.ok' | translate }}</button>
	</mat-dialog-actions>
</div>