import { CdkOverlayOrigin, ConnectionPositionPair } from '@angular/cdk/overlay';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AdditionalDataLoader, BaseSubscriptionComponent } from '@app/core';
import { ViewpointWithThumbnail } from '@app/shared/services';

export enum BimViewpointDeletionMode {
  none = 'none',
  all = 'all',
  main = 'main',
  additional = 'additional',
}

@Component({
  selector: 'app-bim-viewpoint',
  templateUrl: './bim-viewpoint.component.html',
  styleUrls: ['./bim-viewpoint.component.scss'],
  hostDirectives: [CdkOverlayOrigin],
})
export class BimViewpointComponent extends BaseSubscriptionComponent {
  @Input() showViewpointCount = true;
  @Input() canAddViewpoint = true;
  @Input() set deletionMode(mode: BimViewpointDeletionMode) {
    switch (mode) {
      case BimViewpointDeletionMode.none:
        this.canDeleteMainViewpoint = false;
        this.canDeleteAdditionalViewpoints = false;
        break;
      case BimViewpointDeletionMode.all:
        this.canDeleteMainViewpoint = true;
        this.canDeleteAdditionalViewpoints = true;
        break;
      case BimViewpointDeletionMode.main:
        this.canDeleteMainViewpoint = true;
        this.canDeleteAdditionalViewpoints = false;
        break;
      case BimViewpointDeletionMode.additional:
        this.canDeleteMainViewpoint = false;
        this.canDeleteAdditionalViewpoints = true;
        break;
    }
  }

  @Input() set viewpointCount(count: number) {
    this._viewpointCount = count;
    this.setLoadingViewpoints();
  }

  @Input() set mainViewpoint(viewpoint: ViewpointWithThumbnail) {
    this._mainViewpoint = viewpoint;
    this.setLoadingViewpoints();
  }

  @Input() set viewpointsLoader(loader: AdditionalDataLoader<ViewpointWithThumbnail[]>) {
    this._viewpointsLoader = loader;
    if (loader) {
      this.subscribe(loader.data$, viewpoints => {
        this.additionalViewpoints =
          viewpoints?.filter(viewpoint => !this.mainViewpoint || viewpoint.viewpointId !== this.mainViewpoint.viewpointId) ??
          [];
      });
    }
  }

  @Output() viewpointClicked = new EventEmitter<ViewpointWithThumbnail>();
  @Output() newViewpoint = new EventEmitter<void>();
  @Output() deleteViewpoint = new EventEmitter<ViewpointWithThumbnail>();

  BimViewpointDeletionMode = BimViewpointDeletionMode;
  canDeleteAdditionalViewpoints = false;
  canDeleteMainViewpoint = false;
  additionalViewpoints: ViewpointWithThumbnail[] = [];
  loadingViewpoints: number[];

  popupPositions: ConnectionPositionPair[] = [
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
    },
  ];

  constructor(public cdkOverlayOrigin: CdkOverlayOrigin) {
    super();
  }

  private _viewpointCount: number;
  get viewpointCount(): number {
    return this._viewpointCount;
  }

  private _mainViewpoint: ViewpointWithThumbnail;
  get mainViewpoint(): ViewpointWithThumbnail {
    return this._mainViewpoint;
  }

  private _viewpointsLoader: AdditionalDataLoader<ViewpointWithThumbnail[]>;
  get viewpointsLoader(): AdditionalDataLoader<ViewpointWithThumbnail[]> {
    return this._viewpointsLoader;
  }

  get canLoadViewpoints(): boolean {
    return this.viewpointsLoader != null && !this.mainViewpoint ? this.viewpointCount > 0 : this.viewpointCount > 1;
  }

  get isLoadingViewpoints(): boolean {
    return this.viewpointsLoader?.isBusy ?? false;
  }

  onNewViewpoint(): void {
    if (this.canAddViewpoint) this.newViewpoint.emit();
  }

  onDeleteViewpoint(event: PointerEvent, viewpoint: ViewpointWithThumbnail): void {
    event.stopPropagation();
    this.deleteViewpoint.emit(viewpoint);
  }

  onViewpointClicked(event: PointerEvent, viewpoint: ViewpointWithThumbnail): void {
    if (viewpoint != null) {
      event.stopPropagation();
      this.viewpointClicked.emit(viewpoint);
    }
  }

  loadAdditionalViewpoints(): void {
    if (this.canLoadViewpoints) this.viewpointsLoader?.load();
  }

  cancelLoadAdditionalViewpoints(): void {
    if (this.canLoadViewpoints) this.viewpointsLoader?.cancel();
  }

  private setLoadingViewpoints() {
    const length = !this.mainViewpoint ? this.viewpointCount : Math.max(this.viewpointCount - 1, 0);
    this.loadingViewpoints = Array.from({ length }, (_, i) => i); // [0, 1, 2, ... length]
  }
}
