import { Injectable, ChangeDetectorRef } from '@angular/core';
import { C4EventHandler } from '@app/core/utils/c4-event-handler';
import { MediaMatcher } from '@angular/cdk/layout';

export enum ResponsivenessState {
    Desktop,
    Laptop,
    Tablet,
    Mobile
}

@Injectable({
    providedIn: 'root'
})
export class ResponsivenessService {
    private eventHandler: C4EventHandler;
    private changeDetectorRef: ChangeDetectorRef;
    private responsivenessState: ResponsivenessState;
    public get state(): ResponsivenessState {
        return this.responsivenessState;
    }
    private setState(v: ResponsivenessState) {
        if (this.responsivenessState !== v) {
            this.responsivenessState = v;
            this.eventHandler.raiseEvent('stateChanged', this.responsivenessState);
        }
    }

    constructor(
        private media: MediaMatcher) {
        this.eventHandler = new C4EventHandler('ResponsivenessService', ['stateChanged']);
    }

    public init(changeDetector: ChangeDetectorRef) {
        this.changeDetectorRef = changeDetector;
        this.registerQuery('(max-width: 767px)', (matches: boolean) => {
            if (matches) {
                this.setState(ResponsivenessState.Mobile);
            }
        });
        this.registerQuery('(max-width: 991px) and (min-width: 768px)', (matches: boolean) => {
            if (matches) {
                this.setState(ResponsivenessState.Tablet);
            }
        });
        this.registerQuery('(max-width: 1199px) and (min-width: 992px)', (matches: boolean) => {
            if (matches) {
                this.setState(ResponsivenessState.Laptop);
            }
        });
        this.registerQuery('(min-width: 1200px)', (matches: boolean) => {
            if (matches) {
                this.setState(ResponsivenessState.Desktop);
            }
        });
    }

    public registerQuery(query: string, callback: (matches: boolean) => void, skipInitCall: boolean = false) {
        const mediaQuery = this.media.matchMedia(query);
        const self = this;
        // IE 11 does not support addEventListener
        // tslint:disable-next-line
        mediaQuery.addListener((event: MediaQueryListEvent) => {
            this.changeDetectorRef.detectChanges();
            callback.call(self, event.matches);
        });
        if (!skipInitCall) {
            callback.call(self, mediaQuery.matches);
        }
    }

}
