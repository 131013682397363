import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router, Routes } from '@angular/router';
import { PrivilegeEnum } from '@app/api';
import {
  ApiService,
  AppRoutingData,
  BaseSubscriptionComponent,
  FormUtils,
  GlobalsService,
  pathFragmentsToAdmin,
} from '@app/core';
import { SideBarService } from '@app/core/services/globals/side-bar.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { GeneralConfigComponent } from './general/general-config.component';
import { MetadataConfigComponent } from './metadata/metadata-config.component';
import { RolesConfigComponent } from './roles/roles-config.component';
import { SecurityConfigComponent } from './security/security-config.component';
import { StructureConfigComponent } from './structure/structure-config.component';
import { DefectConfigComponent } from './defect/defect-config.component';
import { DiaryConfigComponent } from './diary/diary-config.component';
import { LeanConfigComponent } from './lean/lean-config.component';
import { PlanConfigComponent } from './plan/plan-config.component';
import { BimConfigComponent } from './bim/bim-config.component';
import { MsTeamsConfigComponent } from './ms-teams/ms-teams-config.component';
import { PcfGeneral, ProjectConfigService } from '@app/shared/services/project-config';

interface PageGroup {
  title: string;
  items: Page[];
}

interface Page {
  title: string;
  key: string;
  link: string;
}

const enum ProjectSettingsPageType {
  bim = 'bim',
  defect = 'defect',
  diary = 'diary',
  general = 'general',
  lean = 'lean',
  metadata = 'metadata',
  msTeams = 'msTeams',
  plan = 'plan',
  roles = 'roles',
  security = 'security',
  structure = 'structure',
}

export const PROJECT_CONFIG_TEMPLATE_ROUTES: Routes = [
  { path: ProjectSettingsPageType.general, component: GeneralConfigComponent },
  {
    path: ProjectSettingsPageType.msTeams,
    component: MsTeamsConfigComponent,
    data: { privileges: [PrivilegeEnum.DriveProvisioning] },
  },
  {
    path: ProjectSettingsPageType.roles,
    component: RolesConfigComponent,
    data: { privileges: [PrivilegeEnum.DriveProvisioning] },
  },
  {
    path: ProjectSettingsPageType.security,
    component: SecurityConfigComponent,
    data: { privileges: [PrivilegeEnum.DriveProvisioning] },
  },
  {
    path: ProjectSettingsPageType.structure,
    component: StructureConfigComponent,
    data: { privileges: [PrivilegeEnum.DriveProvisioning] },
  },
  { path: ProjectSettingsPageType.plan, component: PlanConfigComponent },
  { path: ProjectSettingsPageType.bim, component: BimConfigComponent },
  { path: ProjectSettingsPageType.defect, component: DefectConfigComponent },
  { path: ProjectSettingsPageType.diary, component: DiaryConfigComponent },
  { path: ProjectSettingsPageType.lean, component: LeanConfigComponent },
  { path: '**', redirectTo: ProjectSettingsPageType.general },
];

export const PROJECT_CONFIG_ROUTES: Routes = [
  { path: ProjectSettingsPageType.metadata, component: MetadataConfigComponent },
  ...PROJECT_CONFIG_TEMPLATE_ROUTES,
];

@Component({
  selector: 'app-project-config',
  templateUrl: './project-config.component.html',
  styleUrls: ['./project-config.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectConfigComponent extends BaseSubscriptionComponent implements OnInit, OnDestroy {
  @Input() projectNameFallback: string = '';
  @ViewChild('navBar', { static: true }) navBar: ElementRef<HTMLElement>;

  settingPageGroups: PageGroup[] = [];
  settingsPagePath: string = '';
  public readonly navBarShowClass: string = 'nav-bar-visible';
  public hideNavBar: boolean = false;
  public structureTreeEvent: Subscription;

  private hasSaveTemplatePrivilege: boolean = false;

  constructor(
    private router: Router,
    private apiService: ApiService,
    public globals: GlobalsService,
    // private sideBar: SideBarService,
    private configService: ProjectConfigService
  ) {
    super();
    this.structureTreeEvent = this.globals.showProjectMenu$.subscribe(val => {
      this.hideNavBar = !val;
    });
  }

  get isBusy(): boolean {
    return this.configService.isBusy;
  }

  get projectName(): string {
    return this.configService.computedName ?? this.projectNameFallback;
  }

  get canSave(): boolean {
    return this.configService.isDirty && this.configService.isValid;
  }

  get showSaveAsTemplate(): boolean {
    return this.hasSaveTemplatePrivilege;
  }

  get canSaveAsTemplate(): boolean {
    return this.configService.isValid;
  }

  get isNew(): boolean {
    return this.configService.isNew;
  }

  get isTemplate(): boolean {
    return this.configService.isTemplate;
  }

  async ngOnInit() {
    const modulePages: Page[] = [
      {
        title: 'planning.title',
        key: ProjectSettingsPageType.plan,
        link: ProjectSettingsPageType.plan,
      },
      {
        title: 'ifc.group.title',
        key: ProjectSettingsPageType.bim,
        link: ProjectSettingsPageType.bim,
      },
    ];

    const configPages: Page[] = [
      {
        title: 'projectConfig.general.title',
        key: ProjectSettingsPageType.general,
        link: ProjectSettingsPageType.general,
      },
    ];

    this.settingPageGroups = [
      {
        title: 'projectConfig.configGroupTitle',
        items: configPages,
      },
      {
        title: 'projectConfig.moduleGroupTitle',
        items: modulePages,
      },
    ];

    modulePages.push({
      title: 'defects.title',
      key: ProjectSettingsPageType.defect,
      link: ProjectSettingsPageType.defect,
    });

    if (!this.isTemplate) {
      configPages.splice(1, 0, {
        title: 'projectConfig.metadata.title',
        key: ProjectSettingsPageType.metadata,
        link: ProjectSettingsPageType.metadata,
      });
    }

    modulePages.push({
      title: 'diary.title',
      key: ProjectSettingsPageType.diary,
      link: ProjectSettingsPageType.diary,
    });

    modulePages.push({
      title: 'lean.title',
      key: ProjectSettingsPageType.lean,
      link: ProjectSettingsPageType.lean,
    });

    const privileges = await this.apiService.getUserPrivileges();
    this.hasSaveTemplatePrivilege = privileges.contains(PrivilegeEnum.ReadWriteTeamConfig);

    if (privileges.contains(PrivilegeEnum.DriveProvisioning)) {
      configPages.push(
        {
          title: 'projectConfig.msTeams.title',
          key: ProjectSettingsPageType.msTeams,
          link: ProjectSettingsPageType.msTeams,
        },
        {
          title: 'projectConfig.roles.title',
          key: ProjectSettingsPageType.roles,
          link: ProjectSettingsPageType.roles,
        },
        {
          title: 'projectConfig.security.title',
          key: ProjectSettingsPageType.security,
          link: ProjectSettingsPageType.security,
        },
        {
          title: 'projectConfig.structure.title',
          key: ProjectSettingsPageType.structure,
          link: ProjectSettingsPageType.structure,
        }
      );
    }

    this.setSettingsPagePath(this.router.url);

    this.subscribe(this.router.events.pipe(filter(event => event instanceof NavigationEnd)), (event: NavigationEnd) => {
      this.setSettingsPagePath(event.urlAfterRedirects);
      this.navBar.nativeElement.classList.remove(this.navBarShowClass);
      this.hideNavBar = true;
    });

    // this.sideBar.minify();
  }

  isPageInvalid(pageType: ProjectSettingsPageType): boolean {
    const form = this.getFormForPage(pageType);
    return form?.invalid ?? false;
  }

  isPageDisabled(pageType: ProjectSettingsPageType): boolean {
    switch (pageType) {
      case ProjectSettingsPageType.bim:
        return !FormUtils.getFormValue<PcfGeneral>(this.configService.generalForm, 'isBimModuleEnabled');
      case ProjectSettingsPageType.defect:
        return !FormUtils.getFormValue<PcfGeneral>(this.configService.generalForm, 'isDefectModuleEnabled');
      case ProjectSettingsPageType.diary:
        return !FormUtils.getFormValue<PcfGeneral>(this.configService.generalForm, 'isDiaryModuleEnabled');
      // case ProjectSettingsPageType.gallery:
      //   return !FormUtils.getFormValue<PcfGeneral>(this.configService.generalForm, 'isGalleryModuleEnabled');
      case ProjectSettingsPageType.lean:
        return !FormUtils.getFormValue<PcfGeneral>(this.configService.generalForm, 'isLeanModuleEnabled');
      case ProjectSettingsPageType.plan:
        return !FormUtils.getFormValue<PcfGeneral>(this.configService.generalForm, 'isPlanModuleEnabled');
      // case ProjectSettingsPageType.roomBook:
      //   return !FormUtils.getFormValue<PcfGeneral>(this.configService.generalForm, 'isRoomBookModuleEnabled');
      default:
        const form = this.getFormForPage(pageType);
        return form?.disabled ?? true;
    }
  }

  async save() {
    await this.configService.save();
  }

  async saveAsNewTemplate() {
    if (!this.hasSaveTemplatePrivilege) return;

    const id = await this.configService.saveAsNewTemplate();
    if (id != null) this.router.navigate(pathFragmentsToAdmin(AppRoutingData.templates.path).concat(id, 'edit'));
  }

  ngOnDestroy() {
    if (this.structureTreeEvent) this.structureTreeEvent.unsubscribe();
    return super.ngOnDestroy();
  }

  // ngOnDestroy() {
  //   this.sideBar.restoreUserSidebarMinifiedState();
  //   return super.ngOnDestroy();
  // }

  private setSettingsPagePath(url: string) {
    this.settingsPagePath = url.split('/').pop();
  }

  private getFormForPage(pageType: ProjectSettingsPageType) {
    switch (pageType) {
      case ProjectSettingsPageType.general:
        return this.configService.generalForm;
      case ProjectSettingsPageType.metadata:
        return this.configService.metadataForm;
      case ProjectSettingsPageType.roles:
        return this.configService.rolesForm;
      case ProjectSettingsPageType.msTeams:
        return this.configService.msTeamsForm;
      case ProjectSettingsPageType.security:
        return this.configService.rolesForm;
      case ProjectSettingsPageType.structure:
        return this.configService.structureForm;
      case ProjectSettingsPageType.plan:
        return this.configService.planForm;
      case ProjectSettingsPageType.bim:
        return this.configService.bimForm;
      case ProjectSettingsPageType.defect:
        return this.configService.defectForm;
      case ProjectSettingsPageType.defect:
        return this.configService.defectForm;
      case ProjectSettingsPageType.diary:
        return this.configService.diaryForm;
      case ProjectSettingsPageType.lean:
        return this.configService.leanForm;
      default:
        return null;
    }
  }
}
