import { Component } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ConfigType } from '@app/api';
import { ProjectConfigService } from '@app/shared/services/project-config';

@Component({
  selector: 'app-plan-config',
  templateUrl: './plan-config.component.html',
  styleUrls: ['./plan-config.component.scss'],
})
export class PlanConfigComponent {
  constructor(private configService: ProjectConfigService) {}

  get form() {
    return this.configService.planForm;
  }

  get f() {
    return this.form.controls;
  }

  get planSchemas() {
    return this.configService.planSchemes;
  }

  get canEditSchema() {
    return this.configService.canEditPlanSchema;
  }

  get canProvision() {
    return this.configService.canProvision;
  }

  getError(control: AbstractControl) {
    return Object.keys(control.errors)[0];
  }

  async editPlanSchema() {
    this.configService.editPlanSchema(ConfigType.PlanSchema);
  }
}
