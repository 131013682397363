import { ConcurrentAsyncFunction } from './concurrent-async-function';

interface DataCacheEntry {
  date: Date;
  data: any;
}

class DataCacheRegistration {
  fetchFunction: () => Promise<any>;
  ttlInMilliseconds: number;

  constructor(fetchFunction: () => Promise<any>, ttlInMilliseconds: number) {
    this.fetchFunction = fetchFunction;
    this.ttlInMilliseconds = ttlInMilliseconds;
  }

  public isUnlimited(): boolean {
    return this.ttlInMilliseconds === null || this.ttlInMilliseconds === undefined || this.ttlInMilliseconds <= 0;
  }

  public isExpired(entry: DataCacheEntry): boolean {
    if (this.isUnlimited()) {
      return false;
    }
    const entryAgeInMs = new Date().valueOf() - entry.date.valueOf();
    return this.ttlInMilliseconds < entryAgeInMs;
  }
}

export class DataCache {
  private cache = new Map<string, DataCacheEntry>();
  private registry = new Map<string, DataCacheRegistration>();
  private concurrency = new ConcurrentAsyncFunction();

  constructor() {}

  async get<T>(key: string, forceRefresh: boolean = false): Promise<T> {
    if (!this.registry.has(key)) {
      throw new Error(`${key} is not registered`);
    }
    const registration = this.registry.get(key);

    return await this.concurrency.run<any>(key, async () => {
      //try to return existing entry; delete existing entry if expired
      if (this.cache.has(key)) {
        const existingEntry = this.cache.get(key);
        if (!registration.isExpired(existingEntry) && !forceRefresh) {
          return existingEntry.data;
        }
        this.cache.delete(key);
      }
      //fetch new entry and cache it
      const data = await registration.fetchFunction();
      this.cache.set(key, {
        data,
        date: new Date(),
      });
      return data;
    });
  }

  registerIfNeeded<T>(
    key: string,
    fetchFunction: () => Promise<T>,
    ttlInMilliseconds: number = 0,
    initialValue: T = null
  ): void {
    if (this.registry.has(key)) {
      // udate expire time - by navigation
      const registered = this.registry.get(key);
      const currentCache = this.cache.get(key);
      if (currentCache) {
        if (!registered.isExpired(currentCache)) {
          currentCache.date = new Date();
          this.cache.set(key, currentCache);
        }
      }
      return;
    }

    this.registry.set(key, new DataCacheRegistration(fetchFunction, ttlInMilliseconds));

    if (initialValue) {
      this.cache.set(key, {
        data: initialValue,
        date: new Date(),
      });
    }
  }

  remove(key: string) {
    this.cache.delete(key);
  }

  removeWhereKeyStartsWith(keyStartsWith: string) {
    const keys = Array.from(this.cache.keys());
    for (const key of keys) {
      if (key.startsWith(keyStartsWith)) {
        this.remove(key);
      }
    }
  }

  clear() {
    this.concurrency.clear();
    this.cache.clear();
  }
}
