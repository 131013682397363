<div class="estimation-progress">
	<div>
		{{ description | translate }}: {{ progress >= 0 ? (progress + '%') : (pendingProgress | translate) }}
	</div>
	<div *ngIf="estimate">
		{{ 'general.estimate' | translate }}: {{
		estimate
		? (estimate | localizedDate:'short')
		: ('general.unknown' | translate)
		}}
	</div>
	<mat-progress-bar [mode]="progress >= 0 ? 'determine' : 'query'" [value]="progress"></mat-progress-bar>
</div>