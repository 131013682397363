<div class="c4-dialog-wrapper">
	<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
	<h1 mat-dialog-title>{{ 'roomBook.roomToTemplate.title' | translate }}</h1>
	<mat-dialog-content class="room-to-template-content">
		<div class="description">{{ 'roomBook.roomToTemplate.description' | translate }}</div>
		<ng-container *ngIf="!isBusy">
			<ng-container [ngTemplateOutlet]="inputFieldTemplate" [ngTemplateOutletContext]="{
				fieldName: 'nameTemplate'
			}"></ng-container>
			<app-search-select [title]="'roomBook.room.title'" [class.isValid]="!f.room.errors || f.room.untouched"
												 [selectList]="rooms" [selectFormGroup]="form" selectFormControlName="room"
												 [addSelectNone]="true" [triggerTemplate]="roomNameTemplate"
												 [optionTemplate]="roomNameOptionTemplate" [displayFields]="['name','internalNumber']"
												 [error]="f.room.invalid ? 'general.errorForms.' + getError('room') : null">
			</app-search-select>
		</ng-container>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="cancel()" type="button">
			{{ 'general.cancel' | translate }}
		</button>
		<button mat-flat-button color="primary" (click)="confirm()" [disabled]="form.invalid">
			{{ 'general.ok' | translate }}
		</button>
	</mat-dialog-actions>
</div>

<ng-template #inputFieldTemplate [formGroup]="form" let-fieldName="fieldName">
	<mat-form-field>
		<mat-label>
			<span>{{ 'roomBook.fieldName.' + fieldName | translate }}</span>
		</mat-label>
		<input matInput [formControlName]="fieldName" appSetFocus>
		<mat-error *ngIf="f[fieldName].invalid">
			{{ 'general.errorForms.' + getError(fieldName) | translate }}
		</mat-error>
	</mat-form-field>
</ng-template>

<ng-template #roomNameTemplate>
	<span>{{f.room.value?.internalNumber}}&nbsp;- {{f.room.value?.name }}</span>
	<span *ngIf="f.room.value?.regionName">&nbsp;({{ f.room.value?.regionName }})</span>
</ng-template>

<ng-template #roomNameOptionTemplate let-value='item'>
	<div class="c4-select-option">
		<span>{{value?.internalNumber}}&nbsp;- {{value?.name }}</span>
		<span *ngIf="value?.regionName">&nbsp;({{ value.regionName }})</span>
	</div>
</ng-template>