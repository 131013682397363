<form class="config-content-container lean-config" [formGroup]="form">
	<div class="config-content content-sticky">
		<div class="config-group slim">
			<div class="config-title">{{ 'lean.title' | translate }}</div>

			<mat-form-field class="gap-top">
				<mat-label>{{ 'lean.fieldName.weekCount' | translate }}</mat-label>
				<input matInput formControlName="weekCount"
							 [placeholder]="'projectConfig.lean.previewWeeks.placeholder' | translate">
				<mat-error *ngIf="f.weekCount.invalid">
					{{ 'general.errorForms.' + getError(f.weekCount) | translate }}
				</mat-error>
			</mat-form-field>

			<div class="config-field-description">{{ 'projectConfig.lean.previewWeeks.description' | translate }}</div>
		</div>
	</div>

	<div *ngIf="canProvision" class="config-content">
		<div class="config-group gap">
			<div class="config-title sticky with-action">
				<div class="config-title sticky">{{ 'projectConfig.lean.states' | translate }}</div>
				<button class="sticky-right" mat-icon-button (click)="stateSchemaService.addOrEditState()"
								[disabled]="states.disabled">
					<span class="mdi mdi-plus-box"></span>
				</button>
			</div>

			<div *ngIf="stateSchemaService.hasStates" class="table-grid-component-container">
				<app-state-matrix class="table-grid-component" [service]="stateSchemaService"></app-state-matrix>
			</div>
		</div>
	</div>
</form>