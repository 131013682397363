import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-estimation-progress',
  templateUrl: './estimation-progress.component.html',
  styleUrls: ['./estimation-progress.component.scss'],
})
export class EstimationProgressComponent {
  @Input() estimate: Date;
  @Input() progress: number = -1;
  @Input() description: string = 'general.progress';
  @Input() pendingProgress: string = 'general.unknown';
}
