import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { DriveItemIdentifierModel, GenerateZipDownloadUrlModel } from '@app/api';
import { ApiService } from '@app/core';
import { FileSharingInfo, UserNotificationService } from '@app/shared/services';
import { BusyScope, using } from '@app/shared/utils/busy';

@Component({
  selector: 'app-zip-download-url-dialog',
  templateUrl: './zip-download-url-dialog.component.html',
  styleUrls: ['./zip-download-url-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ZipDownloadUrlDialogComponent implements OnInit {
  isBusy: boolean;
  url: string;
  private documentIds: string[];
  private resource: string;
  private fileSharingInfo: FileSharingInfo;

  constructor(
    private apiService: ApiService,
    private userNotification: UserNotificationService,
    public dialogRef: MatDialogRef<ZipDownloadUrlDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.documentIds = data?.documentIds ?? [];
    this.resource = data?.resource;
    this.fileSharingInfo = data?.fileSharingInfo;
  }

  ngOnInit(): void {
    this.dialogRef.backdropClick().subscribe(event => {
      event.stopPropagation();
      this.close(false);
    });

    this.generateUrl();
  }

  async generateUrl() {
    if (!this.url) {
      try {
        await using(new BusyScope(this), async () => {
          const driveItems = new Array<DriveItemIdentifierModel>();
          this.documentIds.forEach(x => {
            driveItems.push(
              new DriveItemIdentifierModel({
                driveItemId: x,
                resource: this.resource,
              })
            );
          });

          const response = await this.apiService.generateZipDownloadUrl(
            new GenerateZipDownloadUrlModel({
              driveItems: driveItems,
              validUntil: this.fileSharingInfo?.validUntil,
            })
          );

          this.url = response.url;
        });
      } catch (e) {
        this.userNotification.notify('dialogs.zipDownloadUrl.generationFailed', { error: e });
        this.close(false);
      }
    }
  }

  notifyUser() {
    this.userNotification.notify('dialogs.zipDownloadUrl.copySuccess');
  }

  close(success: boolean) {
    this.dialogRef.close(success);
  }
}
