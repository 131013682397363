import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface IconToggleOption<T> {
  value: T;
  icon: string;
  activeLabel?: string;
  activateLabel?: string;
}

@Component({
  selector: 'app-icon-toggle',
  templateUrl: './icon-toggle.component.html',
  styleUrls: ['./icon-toggle.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: IconToggleComponent,
    },
  ],
})
export class IconToggleComponent<T> implements ControlValueAccessor {
  @Input() toggleStyle: 'icon' | 'button' = 'icon';
  @Input() options: IconToggleOption<T>[] = [];

  isDisabled: boolean = false;

  onChange = (_: T) => {};
  private onTouched = () => {};
  private isTouched: boolean = false;

  private _selectedOption: IconToggleOption<T>;
  private _nextOption: IconToggleOption<T>;

  constructor() {}

  get selectedOption(): IconToggleOption<T> {
    return this._selectedOption || this.options[0];
  }

  set selectedOption(option: IconToggleOption<T>) {
    this._selectedOption = option;
    this._nextOption = this.getNextOption(option);
  }

  get tooltip() {
    return this.isDisabled ? this.selectedOption?.activeLabel : this.nextOption?.activateLabel;
  }

  private get nextOption() {
    return this._nextOption || this.options[1];
  }

  selectNextOption(event: MouseEvent) {
    event.stopPropagation();

    if (this.isDisabled) return;

    this.selectedOption = this.nextOption;
    this.onChange(this.selectedOption.value);
  }

  private getNextOption(currentOption: IconToggleOption<T>) {
    let nextOptionIndex = this.options.indexOf(currentOption) + 1;
    if (nextOptionIndex >= this.options.length) nextOptionIndex = 0;
    return this.options[nextOptionIndex];
  }

  // ----- Reactive Forms Methods -----

  writeValue(value: T): void {
    this.selectedOption = this.options.find(o => o.value == value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
  }
}
