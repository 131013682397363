import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { CommentModel } from '@app/api';
import { ICommentsApiService } from './comments.service';
import { BaseCommentsApi } from './comments-api.service';

@Injectable({
  providedIn: 'root',
})
export class WorkpackageCommentsApiService extends BaseCommentsApi implements ICommentsApiService {
  constructor(protected apiService: ApiService) {
    super(apiService);
  }

  add = (workpackageId: string, model: CommentModel) => this.apiService.addWorkpackageComment(workpackageId, model);
  update = (workpackageId: string, model: CommentModel) => this.apiService.editWorkpackageComment(workpackageId, model);
  delete = (workpackageId: string, commentId: string) => this.apiService.deleteWorkpackageComment(workpackageId, commentId);
}
