import { Injectable } from '@angular/core';
import { DriveItemModel, DriveItemType } from '@app/api';
import { BehaviorObservable, FormState } from '@app/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '@app/shared/components';
import { DocumentsService } from '@app/shared/services';

type T = DriveItemModel;

export interface FileSharingInfo {
  name: string;
  validUntil: Date;
}

const DRIVE_ITEM_SORTER = (driveItem: DriveItemModel, otherDriveItem: DriveItemModel) => {
  if (driveItem.type != otherDriveItem.type) return driveItem.type == DriveItemType.Folder ? -1 : 1;

  return driveItem.name.localeCompare(otherDriveItem.name);
};

@Injectable()
export class FileSharingService implements FormState {
  private _sharedDriveItem$ = new BehaviorSubject<T[]>([]);
  private _isSidebarHidden = false;

  constructor(private dialog: MatDialog, private documentsService: DocumentsService) {}

  get isDirty(): boolean {
    return this.sharedDriveItems$.value.length > 0;
  }

  get sharedDriveItems$(): BehaviorObservable<T[]> {
    return this._sharedDriveItem$;
  }

  get isSidebarHidden() {
    return this._isSidebarHidden;
  }

  async canLeave() {
    if (this.isDirty) {
      const hasConfirmed = await this.getConfirmation();

      if (hasConfirmed) this.removeAllDriveItems();
    }

    return !this.isDirty;
  }

  addDriveItem(...driveItems: T[]) {
    const sharedDriveItems = this._sharedDriveItem$.value;

    this.sortAndPublishDriveItems([
      ...sharedDriveItems,
      ...driveItems.filter(item => sharedDriveItems.every(existingItem => existingItem.id != item.id)),
    ]);
  }

  addSelectedDriveItems() {
    this.addDriveItem(...this.documentsService.selectedDriveItems);
    this.documentsService.deselectDriveItems();
  }

  removeDriveItem(driveItem: T) {
    this.sortAndPublishDriveItems(this._sharedDriveItem$.value.filter(f => f !== driveItem));
  }

  async removeAllDriveItems(needConfirmation = false) {
    const hasConfirmed = needConfirmation ? await this.getConfirmation() : true;

    if (hasConfirmed) this.sortAndPublishDriveItems([]);
  }

  async shareItemsByEmail(info: FileSharingInfo) {
    // todo add sharing infos
    const success = await this.documentsService.openMailNotificationDialog(this._sharedDriveItem$.value, true);

    if (success) this.removeAllDriveItems();
  }

  async shareItemsByLink(info: FileSharingInfo) {
    const success = await this.documentsService.generateZipDownloadUrl(this._sharedDriveItem$.value, info);

    if (success) this.removeAllDriveItems();
  }

  private getConfirmation() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'documents.cancelSharingTitle',
        description: 'documents.cancelSharingDescription',
      },
    });

    return firstValueFrom(confirmDialog.afterClosed());
  }

  private sortAndPublishDriveItems(driveItems: DriveItemModel[]) {
    this._sharedDriveItem$.next(driveItems.sort(DRIVE_ITEM_SORTER));
  }
}
