<div class="title-container">
	<span class="title">{{ 'documents.share' | translate }}</span>

	<div class="button-container">
		<button mat-icon-button (click)="clearFileSharing()">
			<span class="mdi mdi-close"></span>
		</button>
	</div>
</div>

<!-- currently not used -->
<!-- <div class="vertical-container" [formGroup]="form">
	<mat-form-field>
		<mat-label>{{ 'general.name' | translate }}</mat-label>
		<input matInput formControlName="name">
		<mat-error *ngIf="f.name.invalid">
			{{ 'general.errorForms.' + getError('name') | translate }}
		</mat-error>
	</mat-form-field>

	<mat-form-field>
		<mat-label>{{ 'documents.validUntil' | translate }}</mat-label>
		<input matInput formControlName="validUntil" [matDatepicker]="validUntilPicker">
		<mat-datepicker-toggle matSuffix [for]="validUntilPicker"></mat-datepicker-toggle>
		<mat-datepicker #validUntilPicker></mat-datepicker>
		<mat-error *ngIf="f.validUntil.invalid">
			{{ 'general.errorForms.' + getError('validUntil') | translate }}
		</mat-error>
	</mat-form-field>
</div> -->

<div class="drive-items vertical-container">
	<div class="title-container">
		<span class="title small">{{ 'documents.title' | translate }}</span>

		<div class="button-container">
			<button mat-icon-button class="mobile-button" (click)="onAddItem.emit()">
				<span class="mdi mdi-plus-box-outline"></span>
			</button>
		</div>
	</div>

	<div *ngFor="let driveItem of driveItems" class="drive-item"
			 [matTooltip]="driveItem.resourceIdentifier.key.name + driveItem.path">
		<app-drive-item-icon [driveItem]="driveItem"></app-drive-item-icon>
		<span class="filename">{{ driveItem.name }}</span>
		<button mat-icon-button (click)="removeDriveItem(driveItem)">
			<span class="mdi mdi-close"></span>
		</button>
	</div>
</div>

<div class="button-container">
	<button mat-flat-button color="primary" (click)="sendMail()" [disabled]="form.invalid">
		<span>{{ 'general.notify' | translate }}</span>
	</button>
	<button mat-stroked-button (click)="createLink()" [matTooltip]="'documents.generateUrlTooltip' | translate"
					[disabled]="form.invalid">
		<span>{{ 'documents.generateUrl' | translate }}</span>
	</button>
</div>