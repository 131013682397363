import { AfterViewInit, Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ActivityStepError, ActivityStepWarning } from '../../../../core/services/offline/stepWithProgress';
import { Utils } from '@app/core';

export enum SyncErrorAction {
  cancel = 'cancel',
  deleteStorage = 'deleteStorage',
  rerun = 'rerun',
  continue = 'continue',
}

@Component({
  selector: 'app-sync-error-dialog',
  templateUrl: './sync-error-dialog.component.html',
  styleUrls: ['./sync-error-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SyncErrorDialogComponent implements OnInit, AfterViewInit {
  single: boolean;
  allowContinue: boolean = false;
  errors: ActivityStepError[];
  warnings: ActivityStepWarning[] = [];
  companyName: string;
  // workaround for https://github.com/angular/components/issues/13870
  viewInitialized: boolean = false;

  constructor(public dialogRef: MatDialogRef<SyncErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.companyName = data?.companyName;
    this.errors = data?.errors ?? [];
    this.warnings = data?.warnings ?? [];
    this.single = data?.single == true;
    this.allowContinue = Utils.isNullOrUndefined(this.errors) || this.errors.length <= 0;
  }

  ngAfterViewInit() {
    // Avoid 'ExpressionChangedAfterItHasBeenCheckedError'
    setTimeout(() => (this.viewInitialized = true), 0);
  }

  ngOnInit() {}

  cancel() {
    this.dialogRef.close(SyncErrorAction.cancel);
  }

  deleteStorage() {
    this.dialogRef.close(SyncErrorAction.deleteStorage);
  }

  rerun() {
    this.dialogRef.close(SyncErrorAction.rerun);
  }

  continue() {
    this.dialogRef.close(SyncErrorAction.continue);
  }
}
