import { Injectable } from '@angular/core';
import { ApiService } from '@app/core';
import { AddIssueViewpointModel, AddOrUpdateIssueCommentModel, BcfVersion, CommentModel, IssueCommentModel } from '@app/api';
import { ICommentWithViewpoint, ICommentsApiService } from './comments.service';
import { BaseCommentsApi } from './comments-api.service';

@Injectable({
  providedIn: 'root',
})
export class IssueCommentsApiService extends BaseCommentsApi implements ICommentsApiService {
  constructor(protected apiService: ApiService) {
    super(apiService);
  }

  add = (issueId: string, model: ICommentWithViewpoint) => this.apiService.addIssueComment(issueId, this.mapComment(model));
  update = (issueId: string, model: ICommentWithViewpoint) => this.apiService.editIssueComment(issueId, this.mapComment(model));
  delete = (issueId: string, commentId: string) => this.apiService.deleteIssueComment(issueId, commentId);

  private mapComment(model: ICommentWithViewpoint): AddOrUpdateIssueCommentModel {
    return new AddOrUpdateIssueCommentModel({
      ...model,
      viewpoint: !model.viewpoint
        ? null
        : new AddIssueViewpointModel({
            id: model.viewpoint.viewpointId,
            type: BcfVersion.BCFApi21,
            bcfViewpoint: model.viewpoint.bcfViewpoint,
          }),
    });
  }
}
