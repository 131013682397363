import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { ApiService, AuthenticationService, GlobalsService, LogService } from '@app/core/services';
import { Observable } from 'rxjs';
import { AppRoutingData } from '..';
import { CapacitorUtils } from '../utils/capacitor-utils';

@Injectable()
export class NotAuthenticatedGuard {
  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private apiService: ApiService,
    private log: LogService,
    private globals: GlobalsService
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.log.debug(`NotAuthenticatedGuard: ${state.url}`);
    const authenticated: boolean = await this.authenticationService.isAuthenticated();
    const isTeams = next.queryParams.teams === 'true' || next.queryParams.teamsauth === 'true';

    if ((next.data.logout && authenticated) || (isTeams && authenticated)) {
      // permit as we just loged out
      await this.authenticationService.logout();
      return true;
    }

    if (!authenticated) {
      // permit if not authenticated
      return true;
    }

    if (this.globals.isOAuth) {
      let queryParams = {};

      this.globals.oAuthQueryParams.forEach(p => {
        if (p in next.queryParams) queryParams[p] = next.queryParams[p];
      });

      await this.router.navigate(['consent'], {
        queryParams,
      });

      return;
    }

    // navigate to project page if teams
    let returnUrl = next.queryParams.returnUrl || '';
    if (isTeams && this.globals.currentTeamsGroupId) {
      const projectId = await this.apiService.getProjectIdForGroupId(this.globals.currentTeamsGroupId);
      if (!returnUrl.match(/\/projects\/[0-9A-Fa-f]{8}-([0-9A-Fa-f]{4}-){3}[0-9A-Fa-f]{12}/)) {
        returnUrl = `/projects/${projectId}${returnUrl}`;
      }
    }

    if (CapacitorUtils.isApp()) {
      const capacitorSlug = this.apiService.getAppLinkSlugCookie();
      this.apiService.removeAppLinkSlugCookie();
      if (capacitorSlug) returnUrl = capacitorSlug;
    }
    // navigate somewhere else
    if (returnUrl) {
      await this.router.navigateByUrl(returnUrl);
    } else {
      this.log.info('Route not allowed, redirect to dashboard', next);
      await this.router.navigate([AppRoutingData.dashboard.path]);
    }
    return false;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }
}
