<div class="c4-footer-wrapper">
  <div class="c4-content-footer">
    <div class="c4-footer-pad">
      <div class="c4-footer-content">
        <!-- Commented out in case some1 decideds its needed again
          <div class="c4-footer-header">
          <span *ngIf="tenant?.name">
            {{ tenant.name }}
          </span>
          <span>
            {{ "footer.title" | translate }}
          </span>
        </div> -->
        <div class="c4-footer-text">
          <div>
            <a *ngIf="highlightedFooterLink" target="_blank" [href]="highlightedFooterLink.url">
              {{ highlightedFooterLink.text }}
            </a>
          </div>
        </div>
        <!-- <div class="c4-footer-text" [innerHTML]=" 'custom.footer.content' | translate | safeHtml">
        </div> -->
      </div>
      <div class="c4-footer-links">
        <a *ngFor="let footerLink of footerLinks" href="{{ footerLink.url }}" target="_blank"
           rel="noopener noreferrer">{{ footerLink.text }}</a>
      </div>
    </div>
  </div>
</div>