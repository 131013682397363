<form *ngIf="form" class="config-content-container plan-config" [formGroup]="form">
	<div class="config-content">
		<div *ngIf="canProvision" class="config-group slim">
			<div class="config-title">{{ 'projectConfig.plan.schemaTitle' | translate }}</div>

			<div class="plan-schema-container gap-top">
				<mat-form-field>
					<mat-label>{{ 'projectConfig.planSchema' | translate }}</mat-label>
					<mat-select formControlName="selectedSchema">
						<mat-option *ngFor="let schema of planSchemas" [value]="schema">
							<div class="option-select">{{ schema.name }}</div>
						</mat-option>
					</mat-select>
					<mat-error *ngIf="f.selectedSchema.invalid">
						{{ 'general.errorForms.' + getError(f.selectedSchema) | translate }}
					</mat-error>
				</mat-form-field>

				<button mat-icon-button color="primary" (click)="editPlanSchema()"
								[disabled]="!canEditSchema || !f.selectedSchema.value">
					<span class="mdi mdi-pencil"></span>
				</button>
			</div>
		</div>

		<div class="config-group slim">
			<app-toggle-field class="title" formControlName="qrPrintEnabled"
												[label]="'projectConfig.plan.qr.title' | translate"
												[description]="'projectConfig.plan.qr.description' | translate">
			</app-toggle-field>

			<div class="document-position-container gap-top">
				<div class="label">
					<span class="title">{{ 'projectConfig.plan.qr.position' | translate }}</span>
					<span class="description">{{ 'projectConfig.plan.qr.positionDescription' | translate }}</span>
				</div>

				<app-document-position class="document-position" formControlName="qrPosition"></app-document-position>
			</div>

			<mat-form-field class="gap-top">
				<input matInput formControlName="qrSize" [placeholder]="'projectConfig.plan.qr.size' | translate">
			</mat-form-field>

			<mat-form-field>
				<input matInput formControlName="qrOffsetVertical"
							 [placeholder]="'projectConfig.plan.qr.offsetVertical' | translate">
			</mat-form-field>

			<mat-form-field>
				<input matInput formControlName="qrOffsetHorizontal"
							 [placeholder]="'projectConfig.plan.qr.offsetHorizontal' | translate">
			</mat-form-field>

			<!-- temporary disabled -->
			<!-- <app-toggle-field formControlName="qrScaling" [label]="'projectConfig.plan.qr.scaling' | translate"
												[description]="'projectConfig.plan.qr.scalingDescription' | translate">
			</app-toggle-field> -->
		</div>
	</div>
</form>