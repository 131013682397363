import { Component, Inject } from '@angular/core';
import { HeaderTab, ITabService, TAB_SERVICE } from '@app/shared/services';

@Component({
  selector: 'app-header-tabs',
  templateUrl: './header-tabs.component.html',
  styleUrls: ['./header-tabs.component.scss'],
})
export class HeaderTabsComponent {
  constructor(@Inject(TAB_SERVICE) private tabService: ITabService) {}

  get tabs(): readonly HeaderTab[] {
    return this.tabService.tabs;
  }

  get selectedIndex(): number {
    return this.tabService.activeTabIndex;
  }

  get canEdit(): boolean {
    return this.tabService.canEditTab;
  }

  editTab(index: number, event: MouseEvent) {
    event.stopPropagation();
    this.tabService.editTab(index);
  }

  selectedTabChanged(index: number) {
    this.tabService.selectTab(index);
  }
}
