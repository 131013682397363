import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewEncapsulation } from '@angular/core';
import { AddedNode, Node } from './selection-tree.interfaces';

interface Parent {
  children?: TreeNode[];
}

export interface TreeNode extends Node {
  collapsed?: boolean;
  hidden?: boolean;
  filtered?: boolean;
  selected?: boolean;
  children?: TreeNode[];
}

@Component({
  selector: 'app-selection-tree',
  templateUrl: './selection-tree.component.html',
  styleUrls: ['./selection-tree.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectionTreeComponent implements OnInit {
  @Output() selectionChanged = new EventEmitter<Node>();
  @Output() nodeAdded = new EventEmitter<AddedNode>();
  @Input() supportsAddNode: boolean;
  @Input() addNodeText: string = 'general.addElement';
  @Input() nodes: TreeNode[];

  isBusy: boolean;

  constructor() { }

  ngOnInit() { }

  selectFirst() {
    this.selectNode(this.nodes[0]);
  }

  collapseAll() {
    // this.nodes?.forEach(node => {
    //   if (node.collapsed)
    //     this.toggleNode(node);
    // })
  }

  selectNode(node: TreeNode) {
    if (node && !node.selected) {
      this.forAllDescendants(this.nodes, n => n.selected = false);
      node.selected = true;
      setTimeout(() => {
        const el = document.getElementById('selected');
        el.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }, 100);
      this.selectionChanged.emit(node);
    }
  }

  toggleNode(node: TreeNode, event: Event = null) {
    if (node.children) {
      event?.stopPropagation();
      if (node.collapsed) {
        node.children.forEach(c => c.hidden = false);
      } else {
        this.forAllDescendants(node.children, c => {
          c.hidden = true;
          c.collapsed = true;
        });
      }
      node.collapsed = !node.collapsed;
    }
  }

  addRootNode() {
    this.addNode();
  }

  addNode(node?: TreeNode, event: Event = null) {
    event?.stopPropagation();

    const addedNode: TreeNode = {
      label: 'Neues Element',
      context: null,
      type: null,
      collapsed: true
    };

    if (node) {
      if (!node.children)
        node.children = [];

      if (node.collapsed)
        this.toggleNode(node);

      node.children.push(addedNode);
    } else {
      this.nodes.push(addedNode);
    }

    this.selectNode(addedNode);

    this.nodeAdded.emit({
      node: addedNode,
      parent: node
    });
  }

  findAndDelete(searched: Node) {
    return this.findAndDeleteRecursive(searched, this.nodes);
  }

  filterTreeEvent(inputEvent: any) {
    const filter = inputEvent?.target?.value;

    if (!filter?.length) {
      this.forAllDescendants(this.nodes, node => {
        node.filtered = false;
        node.hidden = false;
        node.collapsed = false;
      });
      this.collapseAll();
    } else {
      const found = this.filterTreeList(this.nodes, inputEvent.target.value.toLowerCase());
    }
  }

  findNodeByNameRecursive(searched: string, nodes: Node[]): Node {
    let index = nodes?.findIndex((node) => node.label === searched) ?? -1;
    let foundNode;
    if (index < 0) {
      nodes?.forEach(node => {
        if (!foundNode) {
          foundNode = this.findNodeByNameRecursive(searched, node.children);
        }
      });
    } else {
      return nodes[index];
    }
    return foundNode;
  }

  private filterTreeList(list: TreeNode[], filter: string): boolean {
    let found = false; //childNodlist has (min1) hit
    let compare = false; //element meets filter criteria
    let hasHit = false; // current nodlist has (min1) hit

    list.forEach(node => {
      compare = node.label.toLowerCase().indexOf(filter)!==-1;          

      if (compare) {
        hasHit = true;
        node.collapsed = true;
        node.hidden = false;
        node.filtered = false;
        if (node.children && node.children.length > 0) {
          this.forAllDescendants(node.children, c => {
            c.filtered = false;
            c.hidden = true;
            c.collapsed = true;
          });
        }
      } else {
        if (node.children && node.children.length > 0) {
          const foundInChild = this.filterTreeList(node.children, filter);
          if (foundInChild) found = true;
          node.filtered = !(compare || foundInChild);
          node.hidden = !(compare || foundInChild);
          node.collapsed = !(compare || foundInChild);
        } else {
          node.filtered = true;
          node.hidden = true;
          node.collapsed = true;
        }
      }
    });

    found = hasHit || found;

    return (compare || found);
  }

  private forAllDescendants(children: TreeNode[], callback: (node: TreeNode) => void) {
    children?.forEach(child => {
      callback(child);
      this.forAllDescendants(child.children, callback);
    });
  }

  private createTree(parent: Parent, children: Node[],) {
    children?.forEach(child => {
      const treeNode = this.createFromNode(child, !!child.children);
      this.createTree(treeNode, child.children);
      parent.children.push(treeNode);
    });
  }

  private createFromNode(node: Node, hasChildren: boolean): TreeNode {
    return {
      context: node.context,
      type: node.type,
      label: node.label,
      collapsed: true,
      hidden: true,
      selected: false,
      children: hasChildren ? [] : null,
    };
  }

  private findAndDeleteRecursive(searched: Node, nodes: Node[]) {
    let index = nodes?.indexOf(searched) ?? -1;
    let deleted = false;
    if (index < 0) {
      nodes?.forEach(node => {
        if (!deleted) {
          deleted = this.findAndDeleteRecursive(searched, node.children);
        }
      });
    } else {
      deleted = true;
      nodes.splice(index, 1);
    }
    return deleted;
  }
}