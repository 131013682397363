import { Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import {
  MatLegacyTab as MatTab,
  MatLegacyTabChangeEvent as MatTabChangeEvent,
  MatLegacyTabGroup as MatTabGroup,
} from '@angular/material/legacy-tabs';
import {
  AreaModel,
  AttributeModel,
  AttributeValueType,
  CategoryModel,
  ControlType,
  FloorModel,
  RegionModel,
  ZoneGroupModel,
  RoomModel,
  RoomTemplateModel,
  UserSessionModel,
} from '@app/api';
import { AppRoutingData, doubleRegex, ExtendedCategoryModel, GlobalsService, intRegex, Utils } from '@app/core';
import { TaskLikeDialogTabType } from '@app/core/enumerations';
import { debounceTime } from 'rxjs/operators';
import { RoomEditDetailComponent } from './room-edit-detail/room-edit-detail.component';
import { ICommentsService } from '@app/shared/services';

interface ComparableCategory {
  sequence: number;
  number: number;
  name: string;
}

function toComparableCategory(category: ExtendedCategoryModel): ComparableCategory {
  return {
    sequence: category.sequence,
    number: category.number,
    name: category.name,
  };
}

@Component({
  selector: 'app-room-edit',
  templateUrl: './room-edit.component.html',
  styleUrls: ['./room-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoomEditComponent {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChild('detailsTab') detailsTab: MatTab;
  @ViewChild('historyTab') historyTab: MatTab;
  @ViewChild('fileTab') fileTab: MatTab;
  @ViewChild('roomEditDetail') roomEditDetail: RoomEditDetailComponent;

  @Input() form: UntypedFormGroup;
  @Input() model: RoomModel | RoomTemplateModel;
  @Input() isTemplate: boolean;
  @Input() isInProject: boolean;
  @Input() roomTemplates: RoomTemplateModel[];
  @Input() areas: AreaModel[];
  @Input() regions: RegionModel[];
  @Input() floors: FloorModel[];
  @Input() categories: CategoryModel[];
  @Input() implicitZoneIds: string[] = [];
  @Input() zoneGroups: ZoneGroupModel[] = [];
  @Input() user: UserSessionModel;
  @Input() commentsService: ICommentsService;
  @Input() filesFolderExists: boolean;
  @Input() cameraCheck: boolean;
  @Input() showHistory: boolean;
  @Output() cameraCheckChange = new EventEmitter<boolean>();
  @Input() attachedFiles: File[];
  @Output() attachedFilesChanged = new EventEmitter<File[]>();

  isBusy: boolean;
  roomTemplateIcon = AppRoutingData.roomTemplates.icon;
  wasFileTabActivatedWithRoom: boolean = false;

  selectedTabIndex: number = 0;

  constructor(public globals: GlobalsService, private formBuilder: UntypedFormBuilder) {}

  get f() {
    return this.form.controls;
  }

  get isNew(): boolean {
    return !this.model?.id;
  }

  setSelectedTab(tabType: TaskLikeDialogTabType) {
    let tabIndex = 0;

    switch (tabType) {
      case TaskLikeDialogTabType.details:
        tabIndex = this.getTabIndex(this.detailsTab);
        break;
      case TaskLikeDialogTabType.history:
        tabIndex = this.getTabIndex(this.historyTab);
        break;
      case TaskLikeDialogTabType.files:
        tabIndex = this.getTabIndex(this.fileTab);
        break;
    }

    this.selectedTabIndex = tabIndex;
  }

  getAttributes() {
    const attributes: AttributeModel[] = [];
    this.fillAttributesRecursive(this.f.categories as UntypedFormArray, attributes);
    return attributes;
  }

  addAttribute(attribute: AttributeModel, markAsDirty: boolean = true) {
    const category = this.getCategory(attribute.category.id, this.categories);
    const formCategory = this.getFormCategory(category);
    const formAttributes = formCategory.controls.attributes as UntypedFormArray;

    // stop adding the same attribute twice
    if (formAttributes.controls.some((a: UntypedFormGroup) => a.controls.id.value == attribute.id)) return;

    const sequence = !attribute.sequence ? formAttributes.length + 1 : attribute.sequence;
    const formAttribute = this.formBuilder.group({
      id: [{ value: attribute.id, disabled: true }],
      name: [{ value: attribute.name, disabled: true }],
      description: [{ value: attribute.description, disabled: true }],
      valueType: [{ value: attribute.valueType, disabled: true }],
      definition: [{ value: attribute.definition, disabled: true }],
      sequence: [{ value: sequence, disabled: this.form.disabled }],
      type: [{ value: attribute.type, disabled: this.form.disabled }],
      value: [
        {
          value: attribute.valueType != AttributeValueType.Checkbox ? attribute.value : attribute.value == 'true',
          disabled: this.form.disabled,
        },
      ],

      // needed for autocomplete
      filteredDefinition: [{ value: attribute.definition, disabled: true }],
    });

    formAttribute.controls.type.valueChanges.subscribe(value => {
      const validators = this.getValidatorsForValueType(attribute.valueType);
      if (this.isTemplate) {
        if (value === ControlType.Readonly) validators.push(Validators.required);
      }
      if (!this.isTemplate) {
        if (value === ControlType.Required) validators.push(Validators.required);

        if (value === ControlType.Readonly) formAttribute.controls.value.disable();
        else if (formAttribute.controls.value.disabled) formAttribute.controls.value.enable();
      }

      formAttribute.controls.value.setValidators(validators);
      formAttribute.controls.value.updateValueAndValidity();
    });

    formAttribute.controls.type.setValue(attribute.type ?? ControlType.Optional);

    if (attribute.valueType === AttributeValueType.Autocomplete) {
      formAttribute.controls.value.valueChanges.pipe(debounceTime(300)).subscribe(value => {
        const definition = formAttribute.controls.definition.value.listItems ?? [];
        const normalized = value?.trim().toLowerCase() ?? '';
        const filterd = { listItems: [] };
        filterd.listItems = definition.filter((item: string) => item.toLowerCase().includes(normalized));
        formAttribute.controls.filteredDefinition.patchValue(filterd);
      });
    }

    formAttribute.valueChanges.subscribe(_ => this.form.markAsDirty());

    formAttributes.push(formAttribute);
    formAttributes.controls.sort(
      (a: UntypedFormGroup, b: UntypedFormGroup) => a.controls.sequence.value - b.controls.sequence.value
    );

    if (markAsDirty) {
      this.form.markAsDirty();
    }
  }

  createReport() {
    this.roomEditDetail.createReport();
  }

  private fillAttributesRecursive(categories: UntypedFormArray, attributes: AttributeModel[]) {
    for (const control of categories.controls) {
      const categoryGroup = control as UntypedFormGroup;
      this.fillAttributesRecursive(categoryGroup.controls.categories as UntypedFormArray, attributes);

      const attributesArray: UntypedFormArray = categoryGroup.controls.attributes as UntypedFormArray;
      for (const control of attributesArray.controls) {
        const attributeGroup = control as UntypedFormGroup;

        const attribute = new AttributeModel({
          id: attributeGroup.controls.id.value,
          sequence: attributeGroup.controls.sequence.value,
          type: attributeGroup.controls.type.value,
          value: attributeGroup.controls.value.value,
          category: new CategoryModel({
            id: categoryGroup.controls.categoryId.value,
          }),
        });

        // replace comma by period
        if (attributeGroup.controls.valueType.value === AttributeValueType.Double) {
          attribute.value = attribute.value?.replace(',', '.');
        }

        attributes.push(attribute);
      }
    }
  }

  private getCategory(id: string, categories: ExtendedCategoryModel[]) {
    for (const category of categories) {
      const foundCategory = category.id == id ? category : this.getCategory(id, category.children);
      if (foundCategory) return foundCategory;
    }

    return null;
  }

  private getFormCategory(categoryWithParentReference: ExtendedCategoryModel): UntypedFormGroup {
    const categories = (
      !categoryWithParentReference.parent
        ? this.f.categories
        : this.getFormCategory(categoryWithParentReference.parent).controls.categories
    ) as UntypedFormArray;

    // const categories = this.f.categories as UntypedFormArray;
    let category = categories.controls.find(
      (c: UntypedFormGroup) => c.controls.categoryId.value == categoryWithParentReference.id
    ) as UntypedFormGroup;
    if (!category) {
      const categoryLabel = categoryWithParentReference.number
        ? `${categoryWithParentReference.number} - ${categoryWithParentReference.name}`
        : categoryWithParentReference.name;

      category = this.formBuilder.group({
        categoryId: [{ value: categoryWithParentReference.id, disabled: true }],
        categoryLabel: [{ value: categoryLabel, disabled: true }],
        categorySequence: [
          {
            value: toComparableCategory(categoryWithParentReference),
            disabled: true,
          },
        ],
        categories: this.formBuilder.array([]),
        attributes: this.formBuilder.array([]),
      });

      category.controls.attributes.valueChanges.subscribe(value => {
        if (value.length == 0 && category.controls.categories.value.length == 0) {
          categories.controls.remove(category);
        }
      });

      category.controls.categories.valueChanges.subscribe(value => {
        if (value.length == 0 && category.controls.attributes.value.length == 0) {
          categories.controls.remove(category);
        }
      });

      categories.push(category);
      categories.controls.sort((a: UntypedFormGroup, b: UntypedFormGroup) =>
        this.compareCategories(a.controls.categorySequence.value, b.controls.categorySequence.value)
      );
    }

    return category;
  }

  private compareCategories(category: ComparableCategory, other: ComparableCategory): number {
    if (category.sequence != null || other.sequence != null) {
      return category.sequence == null ? 1 : other.sequence == null ? -1 : category.sequence - other.sequence;
    }

    if (category.number != null || other.number != null) {
      return category.number == null ? 1 : other.number == null ? -1 : category.number - other.number;
    }

    return category.name == null
      ? other.name == null
        ? 0
        : 1
      : other.name == null
      ? -1
      : category.name.localeCompare(other.name);
  }

  private getValidatorsForValueType(type: AttributeValueType): ValidatorFn[] {
    switch (type) {
      case AttributeValueType.Integer:
        return [Validators.pattern(intRegex)];
      case AttributeValueType.Double:
        return [Validators.pattern(doubleRegex)];
      default:
        return [];
    }
  }

  async tabChanged(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
    // document.getElementById('dialogScrollContentPhone').scrollTop = 0;

    // needed to load grid in tab after enough space is aquired
    // else it would be mobile view
    if (!this.wasFileTabActivatedWithRoom && event.tab === this.fileTab && event.tab.isActive) {
      this.wasFileTabActivatedWithRoom = true;
    } else if (this.isNew && event.tab !== this.fileTab) {
      this.wasFileTabActivatedWithRoom = false;
    }
  }

  private getTabIndex(tab: MatTab) {
    return Math.max(this.tabGroup._tabs.toArray().indexOf(tab), 0);
  }
}
