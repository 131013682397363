import { InjectionToken, Injectable, OnDestroy } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { PropertyBagModel, PropertyBagType } from '@app/api';
import { ApiService, BehaviorObservable, ProjectService, Utils } from '@app/core';
import { InputActionDialogComponent, InputActionDialogResult } from '@app/shared/components';
import { SortMeta, FilterMetadata } from 'primeng/api';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { UserNotificationService } from '../user-notification/user-notification.service';
import { NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export interface HeaderTab {
  id?: string;
  label?: string;
  projectId?: string;
}

export enum TabIdentifier {
  overview = 'overview',
  active = 'active',
}

export interface FilterHeaderTab extends HeaderTab {
  additionalData?: HeaderTabData;
  defaultViewIdentifier?: TabIdentifier;
}

export interface RoutingHeaderTab extends HeaderTab {
  route: string;
}

export interface Route {
  title: string;
  path: string;
}

export interface HeaderTabData {
  tableQuery?: TableQuery;
  formFilterQuery?: FormFilterQuery;
}

export interface UpdateTabData extends HeaderTabData {
  label?: string;
}

export interface TableQuery {
  sorting?: SortMeta[];
  filters?: {
    [s: string]: FilterMetadata | FilterMetadata[];
  };
  columns?: string[];
  standardCols?: boolean;
}

export interface FormFilterQuery {
  [x: string]: any;
}

interface ViewResult {
  id?: string;
  newName?: string;
  isDeleted?: boolean;
}

export interface IViewManager {
  getCurrentHeaderData(): HeaderTabData;
  getViewContent(tab: FilterHeaderTab): string;
  parseView(view: PropertyBagModel): FilterHeaderTab;
}

export const TAB_SERVICE = new InjectionToken<string>('TAB_SERVICE');
export interface ITabService {
  readonly activeTab: HeaderTab;
  readonly activeTabIndex: number;
  readonly canEditTab: boolean;
  readonly tabs: readonly HeaderTab[];

  selectTab(index: number): void;
  editTab(index: number): Promise<void>;
}

export interface ITabFilterService extends ITabService {
  readonly filter$: Observable<HeaderTabData>;
}

abstract class TabService<T extends HeaderTab> implements ITabService {
  protected _activeTabIndex: number = 0;
  protected _tabs: T[] = [];

  get activeTab(): T {
    return this._tabs[this._activeTabIndex];
  }

  get activeTabIndex(): number {
    return this._activeTabIndex;
  }

  get canEditTab(): boolean {
    return false;
  }

  get tabs(): readonly T[] {
    return this._tabs;
  }

  selectTab(index: number) {
    this._activeTabIndex = index;
  }

  editTab(_: number): Promise<void> {
    throw new Error('Method not implemented.');
  }
}

@Injectable()
export class RoutingTabService extends TabService<RoutingHeaderTab> implements OnDestroy {
  private subscription: Subscription = null;

  constructor(private router: Router) {
    super();

    this.subscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.selectTabForRouteIfExists(event.url);
      } else if (event instanceof NavigationCancel) {
        // happens when a leave guard says "no"
        this.selectTab(0);
      }
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  setRoutes(...routes: Route[]) {
    const routingTabs: RoutingHeaderTab[] = routes.map(r => ({ label: r.title, route: r.path }));
    this._tabs.splice(0, this._tabs.length, ...routingTabs);
    this.selectTabForRouteIfExists(this.router.url);
  }

  override selectTab(index: number) {
    super.selectTab(index);

    this.router.navigate(
      [this.activeTab.route]
      // , {
      //   queryParamsHandling,
      //   queryParams: tab.queryParams,
      // }
    );
  }

  private selectTabForRouteIfExists(route: string) {
    const matchingTabIndex = this.getTabIndexForRoute(route);
    if (matchingTabIndex >= 0) this.selectTab(matchingTabIndex);
  }

  private getTabIndexForRoute(route: string) {
    let matchLength = 0;
    let matchingTabIndex = -1;

    for (let i = 0; i < this.tabs.length; i++) {
      const tab = this.tabs[i];
      const isMatch = route.indexOf(tab.route) >= 0;
      if (isMatch && tab.route.length > matchLength) {
        matchLength = tab.route.length;
        matchingTabIndex = i;
      }
    }

    return matchingTabIndex;
  }
}

abstract class DefaultTabServiceBase<T extends HeaderTab> extends TabService<T> {
  protected _defaultTabs: T[];

  constructor() {
    super();
    this._defaultTabs = this.getDefaultTabs();
    this._tabs.push(...this._defaultTabs);
  }

  updateDefaultTabLabel(label: string, index: number = 0) {
    const defaultTab = this._defaultTabs[index];
    if (!defaultTab) throw 'Default tab not found.';
    defaultTab.label = label;
  }

  protected abstract getDefaultTabs(): T[];
}

@Injectable()
export class DefaultTabService extends DefaultTabServiceBase<HeaderTab> {
  constructor() {
    super();
  }

  protected getDefaultTabs(): HeaderTab[] {
    return [
      {
        label: 'general.overview',
      },
    ];
  }
}

@Injectable()
export class CustomViewTabService extends DefaultTabServiceBase<FilterHeaderTab> implements ITabFilterService {
  private _originalDefaultTabData: Partial<Record<TabIdentifier, HeaderTabData>> = {};
  private _filterSubject = new BehaviorSubject<HeaderTabData>({});
  private _type: PropertyBagType;
  private _viewManager: IViewManager;

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private userNotification: UserNotificationService,
    private projectService: ProjectService
  ) {
    super();
  }

  get canEditTab(): boolean {
    return !!this._type;
  }

  get filter$(): BehaviorObservable<HeaderTabData> {
    return this._filterSubject;
  }

  override selectTab(index: number) {
    super.selectTab(index);
    this._filterSubject.next(this.activeTab.additionalData);
  }

  updateDefaultTab(data: UpdateTabData, index: number = 0) {
    const defaultTab = this._defaultTabs[index];
    if (!defaultTab) throw 'Default tab not found.';

    defaultTab.label = data.label ?? defaultTab.label;

    const additionalData = defaultTab.additionalData;
    if (data.tableQuery) {
      const tableQuery = (additionalData.tableQuery = additionalData.tableQuery ?? {});

      tableQuery.sorting = data.tableQuery.sorting ?? tableQuery.sorting;
      tableQuery.filters = data.tableQuery.filters ?? tableQuery.filters;
      tableQuery.columns = data.tableQuery.columns ?? tableQuery.columns;

      if (data.tableQuery?.standardCols != null) tableQuery.standardCols = data.tableQuery.standardCols;
    }

    additionalData.formFilterQuery = data.formFilterQuery ?? additionalData.formFilterQuery;
  }

  addDefaultTab(data: UpdateTabData, identifier: TabIdentifier) {
    if (this._defaultTabs.some(t => t.defaultViewIdentifier === identifier))
      throw 'Default tab with same identifier already exists.';

    const oldDefaultTabsLength = this._defaultTabs.length;
    this._defaultTabs.push({
      label: data.label,
      defaultViewIdentifier: identifier,
      additionalData: {
        formFilterQuery: data?.formFilterQuery,
        tableQuery: data?.tableQuery,
      },
    });

    this._tabs.splice(0, oldDefaultTabsLength, ...this._defaultTabs);
  }

  async editTab(index: number): Promise<void> {
    this.createOrEditView(this._tabs[index]);
  }

  async loadViews(type: PropertyBagType, viewManager: IViewManager) {
    this._type = type;
    this._viewManager = viewManager ?? this._viewManager;

    try {
      const userViews = await this.apiService.getViews(type, null, this.projectService.projectId);

      this._tabs.splice(0, this._tabs.length, ...this._defaultTabs);
      for (const view of userViews ?? []) {
        const tab = this._viewManager.parseView(view);

        if (tab.defaultViewIdentifier != undefined) {
          const matchingDefaultTab = this._defaultTabs.find(t => t.defaultViewIdentifier === tab.defaultViewIdentifier);
          this._originalDefaultTabData[matchingDefaultTab.defaultViewIdentifier] = matchingDefaultTab.additionalData;
          if (matchingDefaultTab != null) {
            matchingDefaultTab.id = tab.id;
            matchingDefaultTab.additionalData = tab.additionalData;
          }
        } else {
          this._tabs.push(tab);
        }
      }

      this.selectTab(0);
    } catch (e) {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorMsg.loadView', e);
    }
  }

  async createOrEditView(existingTab: FilterHeaderTab = null) {
    if (!this._type) throw 'Cannot save tab. Property type not set. Load views first.';

    const isNew = !existingTab;
    const tab = Object.assign({}, existingTab ?? {});
    tab.additionalData = this._viewManager.getCurrentHeaderData() ?? tab.additionalData;

    const result: ViewResult = {};
    const createOrUpdateView = async (newName: string, saveAcrossProjects: boolean) => {
      const trimmedName = newName.trim();
      const view = new PropertyBagModel();
      tab.label = trimmedName;
      view.projectId = saveAcrossProjects === true ? null : this.projectService.projectId;
      view.id = tab.id;
      view.content = this._viewManager.getViewContent(tab);
      view.type = this._type;

      try {
        const propertyId = await this.apiService.createOrUpdateView(view);
        result.id = propertyId;
        result.newName = trimmedName;
        this.userNotification.notify('general.successMsg.saveView');
        return true;
      } catch (e) {
        this.userNotification.notify('general.errorMsg.saveView', { error: e });
        return false;
      }
    };

    const isDefaultView = !!existingTab?.defaultViewIdentifier;
    const deleteView =
      isNew || (isDefaultView && !tab.id)
        ? null
        : async () => {
            try {
              await this.apiService.deleteView(tab.id, this._type);
              result.isDeleted = true;
              this.userNotification.notify('general.successMsg.deleteView');
              return true;
            } catch (e) {
              this.userNotification.notify('general.errorMsg.deleteView', {
                error: e,
              });
              return false;
            }
          };

    const text = isDefaultView ? await this.translate.get(tab.label).toPromise() : tab.label;
    const dialogResult: InputActionDialogResult = await this.dialog
      .open(InputActionDialogComponent, {
        data: {
          title: 'customView.' + (isNew ? 'titleNew' : 'titleEdit'),
          description: 'customView.' + (isNew ? 'descriptionNew' : 'descriptionEdit'),
          text,
          isInputDisabled: isDefaultView,
          validate: (newName: string) => !Utils.isNullOrWhitespace(newName),
          action: createOrUpdateView,
          alternativeActionLabel: 'general.' + (isDefaultView ? 'reset' : 'delete'),
          alternativeAction: deleteView,
          toggleDefault: Utils.isNullOrUndefined(tab.projectId),
          toggleLabel: 'general.saveAcrossProjects',
        },
      })
      .afterClosed()
      .toPromise();

    if (dialogResult.wasCanceled) return;

    tab.id = result.id;
    tab.label = result.newName;
    if (isNew) {
      this._tabs.push(tab);
      this.selectTab(this._tabs.length - 1);
    } else if (result.isDeleted) {
      const index = this._tabs.indexOf(existingTab);

      if (isDefaultView) {
        existingTab.id = null;
        existingTab.additionalData = this._originalDefaultTabData[existingTab.defaultViewIdentifier];
        delete this._originalDefaultTabData[existingTab.defaultViewIdentifier];
        this._filterSubject.next(existingTab.additionalData);
      } else {
        this._tabs.splice(index, 1);
      }
    } else {
      if (isDefaultView) {
        this._originalDefaultTabData[existingTab.defaultViewIdentifier] = existingTab.additionalData;
      }

      existingTab.id = tab.id;
      existingTab.label = tab.label;
      existingTab.additionalData = tab.additionalData;
    }
  }

  protected override getDefaultTabs(): FilterHeaderTab[] {
    return [
      {
        label: 'general.overview',
        defaultViewIdentifier: TabIdentifier.overview,
        additionalData: {
          tableQuery: {
            standardCols: true,
          },
        },
      },
    ];
  }

  static getUpdateDataSortAsc<T>(field: keyof T & string): UpdateTabData {
    return {
      tableQuery: {
        sorting: [{ field, order: 1 }],
      },
    };
  }
}
