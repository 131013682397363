<div class="c4-p-table-container" #gridcontainer [ngClass]="[isBusy?'table-busy':'']"
     [attr.paginator]="gridDefModel.grid.rowCount>gridDefModel.grid.rows ? 'shown': 'hidden'">
  <p-table #dt [ngClass]="{'c4-grid-scrollable': absoluteUnits}" (onFilter)="onFilter($event)" sortMode="multiple"
           [multiSortMeta]="multiSortMeta" [columns]="gridDefModel.cols" [lazy]="gridDefModel.grid.lazy"
           [lazyLoadOnInit]="gridDefModel.grid.lazyInit" (onLazyLoad)="fetch($event)" [value]="source"
           (onSort)="c4Sort($event)" [scrollable]="gridDefModel.grid.scrollable" scrollHeight="flex"
           [resizableColumns]="true" columnResizeMode="expand"
           [paginator]="gridDefModel.grid.rowCount>gridDefModel.grid.rows" [rows]="gridDefModel.grid.rows"
           [rowsPerPageOptions]="gridDefModel.grid.rowsOptions" [totalRecords]="gridDefModel.grid.rowCount"
           [metaKeySelection]='false' [selectionMode]="selectionMode" [(selection)]="selection"
           [showCurrentPageReport]="true"
           [currentPageReportTemplate]="'planning.grid.paginator.pageReportTemplate' | translate">
    <ng-template pTemplate="caption" *ngIf="gridDefModel.grid.globalFilter">
      <div>
        <mat-form-field>
          <input matInput (input)="dt.filterGlobal($event.target.value, 'contains')"
                 placeholder="{{ globalFilterPlaceholder | translate }}">
        </mat-form-field>
      </div>
    </ng-template>
    <ng-template pTemplate="header" let-columns>
      <tr>
        <ng-container *ngFor="let col of columns">
          <th *ngIf="col.show && !col.hidden" [pSortableColumn]="col.sortable? col.field:null"
              [ngStyle]="{'width': col.calcWidth}" [ngClass]="col.cssClass ? col.cssClass :''"
              [ngSwitch]="col.headerRenderer" pResizableColumn>
            <ng-container *ngSwitchCase="'template'">
              <ng-container [ngTemplateOutlet]="col.headerTemplate" [ngTemplateOutletContext]="{col:col}">
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <span *ngIf="!col.noTranslate" class="c4-colheader">{{resourcePrefix + col.header | translate}}</span>
              <span *ngIf="col.noTranslate" class="c4-colheader">{{col.header}}</span>
              <p-sortIcon *ngIf="col.sortable" [field]="col.field" ariaLabel="Activate to sort"
                          ariaLabelDesc="Activate to sort in descending order"
                          ariaLabelAsc="Activate to sort in ascending order">
              </p-sortIcon>
            </ng-container>
          </th>
        </ng-container>
        <ng-container *ngIf="gridDefModel.grid.rowExpand && hiddenCols.length>0">
          <th [ngStyle]="{'width': ctrlWidth}"></th>
        </ng-container>
      </tr>
      <tr *ngIf="gridDefModel.grid.filterRow">
        <ng-container *ngFor="let col of columns">
          <th *ngIf="col.show && !col.hidden" class="c4-filter-row" [ngSwitch]="col.filterType"
              [ngClass]="col.filterType == undefined || col.filterType == null ? 'c4-no-filter':''">
            <p-dropdown *ngSwitchCase="'select'" placeholder="Alle" [options]="col.currentOptions" [style.width.%]="100"
                        [showClear]="true" (onChange)="dt.filter($event.value, col.field, 'equals')"
                        [ngModel]="col.filterVal" appendTo="body"></p-dropdown>
            <p-multiSelect *ngSwitchCase="'multiselect'" [style.width.%]="100" [options]="col.currentOptions"
                           [selectedItemsLabel]="multistring" [placeholder]="'grid.filter.all' | translate"
                           (onChange)="dt.filter($event.value, col.field, 'in')" [ngModel]="col.filterVal"
                           appendTo="body" resetFilterOnHide="true" optionLabel="label" optionValue="value"
                           [emptyFilterMessage]="'general.noDataText' | translate">
              <ng-template let-itemop pTemplate="item">
                <div class="option-item" [ngClass]="itemop.visible ? 'default' : 'hidden'">
                  <div>{{itemop.label}}</div>
                </div>
              </ng-template>
            </p-multiSelect>
            <input *ngSwitchCase="'text'" pInputText type="text" [style.width.%]="100" [ngModel]="col.filterVal"
                   (input)="dt.filter($event.target.value, col.field, col.filterMatchMode)">
            <div class="date-filter" *ngSwitchCase="'date'">
              <input matInput [matDatepicker]="picker"
                     (dateChange)="dt.filter($event.value?.toDate(), col.field, 'dateIs')">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
            </div>
            <div *ngSwitchDefault></div>
          </th>
        </ng-container>
        <ng-container *ngIf="gridDefModel.grid.rowExpand && hiddenCols.length>0">
          <th [ngStyle]="{'width': ctrlWidth}"></th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-columns="columns">
      <tr #mainRow [pSelectableRow]="row"
          [ngClass]="['c4-main-row', gridDefModel.row.link ? 'c4-link-row': '', row.expand && gridDefModel.hiddenVal ? 'c4-expanded':'',
        row.selected ? 'c4-selected-row': '', row.pending ? 'row-state-pending' :'' , row.rowClass ? row.rowClass:'', row.verify?'verify':'']">
        <ng-container *ngFor="let col of columns; let i = index">
          <td *ngIf="col.show && !col.hidden" [ngStyle]="{'width': col.calcWidth}"
              [ngClass]="col.cssClass ? col.cssClass :''" [ngSwitch]="col.renderer">
            <div class="c4-grid-cell" attr.data-field="{{col.field}}" attr.data-colindex="{{i}}">
              <a *ngIf="gridDefModel.row.link" class="c4-grid-row-link"
                 [routerLink]="[gridDefModel.row.path, row[gridDefModel.row.param]]"></a>
              <ng-container *ngSwitchCase="'template'">
                <ng-container [ngTemplateOutlet]="col.template" [ngTemplateOutletContext]="{row:row , col:col}">
                </ng-container>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <ng-container *ngIf="row[rangePrefix+col.field];else colDefault">
                  <span class="c4-topline">{{ row[col.field] }}</span>
                </ng-container>
                <ng-template #colDefault>
                  <span class="c4-topline">{{ col.pipe ? col.pipeArg? col.pipe.transform(row[col.field],
                    col.pipeArg):col.pipe.transform(row[col.field]) : row[col.field] }}</span>
                  <span *ngFor="let sub of col.sub" [ngClass]="sub.cssClass ? sub.cssClass :''">{{ sub.pipe ?
                    sub.pipeArg? sub.pipe.transform(row[sub.field], sub.pipeArg):sub.pipe.transform(row[sub.field]) :
                    row[sub.field] }}</span>
                </ng-template>
              </ng-container>
            </div>
          </td>
        </ng-container>
        <ng-container *ngIf="gridDefModel.grid.rowExpand && hiddenCols.length>0">
          <td *ngIf="canExpand(row)" [ngStyle]="{'width': ctrlWidth}" (click)="showHide(row, $event)"
              class="c4-ctrl-td td-visibility-control">
            <div class="c4-visibility-control">
              <span class="mdi mdi-chevron-down"></span>
            </div>
          </td>
          <td *ngIf="!canExpand(row)" [ngStyle]="{'width': ctrlWidth}" class="c4-ctrl-td">
            <!--Just a placeholder, we do not want that the table stretes when canExpand finally is active-->
            <div class="c4-visibility-control c4-disabled">
              <span class="mdi mdi-chevron-down"></span>
            </div>
          </td>
        </ng-container>
      </tr>
      <ng-container *ngIf="gridDefModel.grid.rowExpand && hiddenCols.length>0">
        <tr class="c4-subrow" [class.c4-selected-row]="row.selected" (click)="mainRow.click()">
          <!-- + 1 == expander -->
          <td [attr.colspan]="visibleCols + 1">
            <div class="c4-grid-cell">
              <a *ngIf="gridDefModel.row.link" class="c4-grid-row-link"
                 [routerLink]="[gridDefModel.row.path, row[gridDefModel.row.param]]"></a>
              <ng-container *ngFor="let col of hiddenCols" [ngSwitch]="col.mobileRenderer">
                <ng-container *ngSwitchCase="'template'">
                  <ng-container [ngTemplateOutlet]="col.mobileTemplate"
                                [ngTemplateOutletContext]="{row:row , col:col, prefix:resourcePrefix}">
                  </ng-container>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <div *ngIf="row[col.field]">
                    <span *ngIf="!col.noTranslate" class="c4-cell-col c4-label">{{resourcePrefix + col.header |
                      translate}}</span>
                    <span *ngIf="col.noTranslate" class="c4-cell-col c4-label">{{col.header}}</span>
                    <span class="c4-cell-col c4-value">{{ col.pipe ? col.pipeArg ? col.pipe.transform(row[col.field],
                      col.pipeArg) : col.pipe.transform(row[col.field]) : row[col.field] }}</span>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </td>
        </tr>
      </ng-container>
    </ng-template>
    <ng-template pTemplate="emptymessage" let-columns>
      <ng-container *ngIf="!initready">
        <tr *ngFor="let idlewrow of idlerows">
          <ng-container *ngFor="let col of columns">
            <td *ngIf="col.show" [ngStyle]="{'width': col.calcWidth}">
              <div class="c4-grid-cell"><span class="init-idle-cell">{{col.field}}</span></div>
            </td>
          </ng-container>
        </tr>
      </ng-container>
      <tr *ngIf="initready" class="c4-grid-empty-warning c4-main-row">
        <td [attr.colspan]="visibleCols">
          <div [style.minWidth.px]="minGridWidth"><span class="c4-grid-empty-ico mdi mdi-blur-off"></span><span>{{
              noDataText | translate }}</span></div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>