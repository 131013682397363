import { IMigration, Statement } from '../definitions';

export const Migration: IMigration = {
  name: 'M211013_Initial',
  getStatements() {
    const createMetadataTable = Statement`
      CREATE TABLE metadata (
        name TEXT NOT NULL,
        data TEXT NOT NULL,
        PRIMARY KEY (name)
      );
    `;
    const createMetadataRecords = Statement`
      INSERT INTO metadata
      SELECT 'INITIAL_DATE', ${() => `${Date.now()}`}
    `;
    const createUploadTable = Statement`
      CREATE TABLE uploads (
        id TEXT NOT NULL,
        type TEXT NOT NULL,
        result TEXT NOT NULL,

        PRIMARY KEY (id, type)
      );
    `;

    const createProjectsTable = Statement`
      CREATE TABLE projects (
        id TEXT NOT NULL,

        offline INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        [sync_checksum] TEXT,
        [sync_datetime] DATETIME,

        PRIMARY KEY (id)
      );
    `;

    const createAreaTable = Statement`
      CREATE TABLE areas (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createDefectsTable = Statement`
      CREATE TABLE defects (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        json TEXT NOT NULL,
        mode TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createDefectTypesTable = Statement`
      CREATE TABLE defectTypes (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        code TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createDefectCommentsTable = Statement`
      CREATE TABLE defectComments (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        defectId TEXT NOT NULL,
        commentId TEXT NOT NULL,

        mode TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createFloorsTable = Statement`
      CREATE TABLE floors (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        areaId TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createRegionsTable = Statement`
      CREATE TABLE regions (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        areaId TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createRoomsTable = Statement`
      CREATE TABLE rooms (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        floorId TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createUsersTable = Statement`
      CREATE TABLE users (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createCommentsTable = Statement`
      CREATE TABLE comments (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        text TEXT,
        editorId TEXT NOT NULL,
        replyToCommentId TEXT,

        mode TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createCompanyUsersTable = Statement`
      CREATE TABLE companyUsers (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        userId TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createDefectReasonTable = Statement`
      CREATE TABLE defectReasons (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        code TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createCraftsTable = Statement`
      CREATE TABLE crafts (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createOrganizationsTable = Statement`
      CREATE TABLE organizations (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createSchemaTable = Statement`
      CREATE TABLE schema (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        type TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createCompanyOrganizationsTable = Statement`
      CREATE TABLE companyOrganizations (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        organizationId TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createCompanyOrganizationCraftsTable = Statement`
      CREATE TABLE companyOrganizationCrafts (
        id TEXT NOT NULL,
        project TEXT NOT NULL,

        companyOrganizationId TEXT NOT NULL,

        json TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project)
      );
    `;

    const createDriveItemsTable = Statement`
      CREATE TABLE driveItems (
        id TEXT NOT NULL,
        project TEXT NOT NULL,
        relatedEntityId TEXT,

        type TEXT NOT NULL,
        path TEXT NOT NULL,
        name TEXT NOT NULL,
        mimeType TEXT NOT NULL,
        resource TEXT NOT NULL,

        json TEXT NOT NULL,
        mode TEXT NOT NULL,
        deleted INTEGER NOT NULL,
        modifiedOn DATETIME NOT NULL,

        PRIMARY KEY (id, project, resource)
      );
    `;

    const createErrorTable = Statement`
        CREATE TABLE syncErrors (
            id TEXT NOT NULL,
            project TEXT NOT NULL,

            createdOn DATETIME NOT NULL,
            parameters TEXT NOT NULL,

            PRIMARY KEY (id, project)
        );
    `;

    return [
      createMetadataTable,
      createProjectsTable,
      createUploadTable,
      createErrorTable,

      createAreaTable,
      createUsersTable,
      createRoomsTable,
      createCraftsTable,
      createFloorsTable,
      createRegionsTable,
      createCommentsTable,
      createOrganizationsTable,

      createDefectsTable,
      createDefectTypesTable,
      createDefectReasonTable,
      createDefectCommentsTable,

      createSchemaTable,

      createDriveItemsTable,

      createCompanyUsersTable,
      createCompanyOrganizationsTable,
      createCompanyOrganizationCraftsTable,

      createMetadataRecords,
    ];
  },
};
