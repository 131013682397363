<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
<app-entity-detail-dialog [formGroup]="form" [dialogRef]="dialogRef" [title]="dialogTitle" (saveClicked)="save()"
													(cancelClicked)="cancel()" (resetClicked)="reset()" class="workpackage-edit-dialog"
													[class.template]="isTemplate" [canSave]="form?.dirty && form?.valid"
													[fieldError]="form.touched && form.invalid ? getFirstError('workpackages.fieldName') : null">
	<div header class="header-container" [class.c4-phone-hidden]="selectedTabIndex != 0">
		<div class="entity-edit-title-container">
			<span class="number">{{ number }}</span>
			<input class="entity-edit-title-input" [class.error]="f.name.errors" formControlName="name"
						 [placeholder]="'workpackages.fieldName.name' | translate">
		</div>

		<div class="header-row">
			<div class="select-with-icon" [title]="'workpackages.fieldName.craft' | translate">
				<span class="mdi mdi-hammer-wrench"></span>
				<app-search-select class="slim-form-field" [class.isValid]="!f.craft.errors || f.craft.untouched"
													 [selectList]="crafts" [selectFormGroup]="form" selectFormControlName="craft"
													 [addSelectNone]="true">
				</app-search-select>
			</div>
			<div *ngIf="!isTemplate" class="select-with-icon" [title]="'workpackages.fieldName.organization' | translate">
				<span class="mdi mdi-office-building"></span>
				<app-search-select class="slim-form-field" [class.isValid]="!f.organization.errors || f.organization.untouched"
													 [selectList]="organizations" [selectFormGroup]="form" selectFormControlName="organization"
													 [addSelectNone]="true">
				</app-search-select>
			</div>
		</div>
	</div>

	<div subHeader class="sub-header-container" [class.c4-phone-hidden]="selectedTabIndex != 0">
		<div class="form-grid" [class.odd-header-row-item-count]="isTemplate">
			<div *ngIf="!isTemplate" class="grid-cell">
				<span class="label">{{ 'workpackages.fieldName.state' | translate }}</span>
				<div class="slim-form-field" [class.isValid]="!f.state.errors || f.state.untouched">
					<mat-form-field>
						<mat-select formControlName="state">
							<mat-option *ngFor="let option of stateOptions" [value]="option.state">
								<div class="c4-select-option with-icon icon-right">
									<span>
										<span>{{ option.state.code }} </span>
										<span>{{ option.state.title }}</span>
									</span>
									<span *ngIf="option.iconClass" class="mdi" [class]="option.iconClass"></span>
								</div>
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>

			<div *ngIf="!isTemplate" class="grid-cell">
				<span class="label">{{ 'workpackages.fieldName.swimlane' | translate }}</span>
				<app-tree-selection class="slim-tree-select" formControlName="mainSwimlaneId" [canSelectLeafsOnly]="true"
														childrenSelector="swimlanes" [models]="phasesWithSwimlanes">
				</app-tree-selection>
			</div>

			<div *ngIf="isTemplate" class="grid-cell">
				<span class="label">{{ 'workpackages.fieldName.phase' | translate }}</span>
				<app-search-select class="slim-form-field" [class.isValid]="!f.phase.errors || f.phase.untouched"
													 [selectList]="phases" [selectFormGroup]="form" selectFormControlName="phase"
													 [addSelectNone]="true">
				</app-search-select>
			</div>

			<div *ngIf="!isTemplate" class="grid-cell">
				<span class="label">{{ 'workpackages.fieldName.startdate' | translate }}</span>
				<div class="slim-form-field" [class.isValid]="!f.startdate.errors || f.startdate.untouched">
					<mat-form-field>
						<span class="mdi mdi-calendar date-picker-toggle" (click)="startPicker.open()"></span>
						<input matInput formControlName="startdate" [matDatepicker]="startPicker" (focus)="startPicker.open()">
						<mat-datepicker #startPicker></mat-datepicker>
					</mat-form-field>
				</div>
			</div>

			<div *ngIf="!isTemplate" class="grid-cell">
				<span class="label">{{ 'workpackages.fieldName.enddate' | translate }}</span>
				<div class="slim-form-field" [class.isValid]="!f.enddate.errors || f.enddate.untouched">
					<mat-form-field>
						<span class="mdi mdi-calendar date-picker-toggle" (click)="endPicker.open()"></span>
						<input matInput formControlName="enddate" [matDatepicker]="endPicker" (focus)="endPicker.open()">
						<mat-datepicker #endPicker></mat-datepicker>
					</mat-form-field>
				</div>
			</div>

			<div class="grid-cell">
				<span class="label">{{ 'workpackages.fieldName.code' | translate }}</span>
				<div class="slim-form-field" [class.isValid]="!f.code.errors || f.code.untouched">
					<mat-form-field>
						<input matInput formControlName="code">
					</mat-form-field>
				</div>
			</div>
		</div>

		<div *ngIf="!isTemplate && !isNew" class="tab-overlay">
			<div class="author">
				<span class="mdi mdi-account-circle"></span>
				<span>{{ model?.createdBy }}</span>
				<span> - </span>
				<span>{{ model?.createdOn | localizedDate:'short' }}</span>
			</div>
			<div></div>
		</div>
	</div>

	<mat-tab-group id="taskLikeTabGroup" [selectedIndex]="selectedTabIndex" animationDuration="0ms" mat-align-tabs="end"
								 #tabGroup (selectedTabChange)="tabChanged($event)">
		<!-- [selectedIndex]="selectedTabIndex" (selectedTabChange)="tabChanged($event, fileTab)" -->
		<mat-tab #detailsTab>
			<ng-template mat-tab-label>
				<span class="tab-label-text">{{ 'general.details' | translate }}</span>
			</ng-template>
			<div class="tab-content-container details">
				<div class="main vertical-content">
					<div class="expansion-panel-header-like">{{ 'workpackages.fieldName.description' | translate }}</div>
					<app-text-input formControlName="description" inputType="textarea"
													[placeholder]="'general.placeholder.description' | translate" minRows="7" maxRows="7">
					</app-text-input>
					<app-comments *ngIf="!isTemplate" formControlName="comment" [service]="commentsService"></app-comments>
				</div>
				<div class="secondary">
					<mat-expansion-panel class="slim-expansion-panel" expanded>
						<mat-expansion-panel-header>
							<mat-panel-title>
								<div class="panel-title">
									{{ 'workpackages.properties' | translate }}
								</div>
							</mat-panel-title>
						</mat-expansion-panel-header>

						<div class="vertical-content">
							<mat-form-field *ngIf="!isTemplate" class="with-suffix">
								<mat-label>{{ 'workpackages.fieldName.cost' | translate }}</mat-label>
								<input matInput numberInput formControlName="cost" placeholder="2000">
								<span matSuffix class="mdi mdi-currency-eur"></span>
							</mat-form-field>
							<mat-form-field class="with-suffix">
								<mat-label>{{ 'workpackages.fieldName.costfactor' | translate }}</mat-label>
								<input matInput numberInput formControlName="costFactor" placeholder="2000">
								<span matSuffix class="mdi mdi-currency-eur"></span>
							</mat-form-field>
							<mat-form-field class="with-suffix">
								<mat-label>{{ 'workpackages.fieldName.costvalue' | translate }}</mat-label>
								<input matInput numberInput formControlName="costValue" placeholder="8">
								<span matSuffix class="mdi mdi-clock-time-four-outline"></span>
							</mat-form-field>
							<mat-form-field class="with-suffix">
								<mat-label>{{ 'workpackages.fieldName.capacity' | translate }}</mat-label>
								<input matInput numberInput formControlName="capacity" placeholder="2">
								<span matSuffix class="mdi mdi-account-multiple"></span>
							</mat-form-field>
						</div>
					</mat-expansion-panel>

					<mat-expansion-panel *ngIf="!isTemplate" class="slim-expansion-panel" expanded>
						<mat-expansion-panel-header>
							<mat-panel-title>
								<span>{{ 'scheduler.details.constraintsTitle' | translate }}</span>
								<span class="mdi mdi-information-outline"
											[matTooltip]="'scheduler.details.constraintsTooltip' | translate"></span>
							</mat-panel-title>
						</mat-expansion-panel-header>

						<app-checkbox-form class="content-margin" translationPrefix="workpackages.fieldName.constraint"
															 [formGroup]="f.constraints"></app-checkbox-form>

					</mat-expansion-panel>

					<mat-expansion-panel *ngIf="!isTemplate" class="slim-expansion-panel" expanded>
						<mat-expansion-panel-header>
							<mat-panel-title>
								<span>{{ 'scheduler.details.disturbancesTitle' | translate }}</span>
								<span class="mdi mdi-information-outline"
											[matTooltip]="'scheduler.details.disturbancesTooltip' | translate"></span>
							</mat-panel-title>
						</mat-expansion-panel-header>

						<app-checkbox-form class="content-margin" translationPrefix="workpackages.fieldName.disturbance"
															 [formGroup]="f.disturbances"></app-checkbox-form>

					</mat-expansion-panel>

					<mat-expansion-panel *ngIf="!isTemplate" class="slim-expansion-panel" expanded>
						<mat-expansion-panel-header>
							<mat-panel-title>
								<div class="panel-title">
									{{ 'workpackages.dependencies' | translate }}
								</div>
							</mat-panel-title>
						</mat-expansion-panel-header>

						<div class="vertical-content">
							<button class="add-dependency-button" mat-stroked-button color="primary" (click)="addDependency()">
								<span class="mdi mdi-plus"></span>
								<span>{{ 'workpackages.addDependency' | translate }}</span>
							</button>

							<div class="dependency-container" *ngIf="f.predecessors.value?.length">
								<div>{{ 'workpackages.fieldName.predecessors' | translate }}</div>
								<div class="dependency-list">
									<ng-container *ngFor="let predecessor of f.predecessors.value"
																[ngTemplateOutlet]="dependencyItemTemplate" [ngTemplateOutletContext]="{
										item: predecessor,
										icon: 'mdi mdi-clipboard-outline'
									}"></ng-container>
								</div>
							</div>

							<div class="dependency-container" *ngIf="f.successors.value?.length">
								<div>{{ 'workpackages.fieldName.successors' | translate }}</div>
								<div class="dependency-list">
									<ng-container *ngFor="let successor of f.successors.value" [ngTemplateOutlet]="dependencyItemTemplate"
																[ngTemplateOutletContext]="{
										item: successor,
										icon: 'mdi mdi-clipboard-outline'
									}"></ng-container>
								</div>
							</div>

							<div class="dependency-container" *ngIf="f.foreignSwimlanes.value?.length">
								<div>{{ 'workpackages.fieldName.foreignSwimlanes' | translate }}</div>
								<div class="dependency-list">
									<ng-container *ngFor="let swimlane of f.foreignSwimlanes.value"
																[ngTemplateOutlet]="dependencyItemTemplate" [ngTemplateOutletContext]="{
										item: swimlane,
										icon: 'blue-dot'
									}"></ng-container>
								</div>
							</div>

							<ng-template #dependencyItemTemplate let-item="item" let-icon="icon">
								<div class="dependency-item">
									<span [class]="icon"></span>
									<div class="main">
										<div class="row">
											<span *ngIf="item.number">{{ item.number }}</span>
											<a class="title" [routerLink]="item.route.routerLink" [queryParams]="item.route.queryParams">
												{{ item.name }}
											</a>
										</div>
										<div class="row" [matTooltip]="item.createdOn ? ('general.updatedOnDetail' | translate:{
													date: (item.createdOn | localizedDate:'short'),
													user: item.createdBy
												}) : null">
											<span *ngIf="item.createdOn">
												{{ 'general.updatedOn' | translate:{date: item.createdOn | localizedDate} }},
											</span>
											<span *ngIf="item.stateTitle" class="state">
												<span *ngIf="item.stateIconClass" class="mdi" [class]="item.stateIconClass"></span>
												<span>{{ item.stateTitle }}</span>
											</span>
										</div>
									</div>
									<span class="mdi mdi-close remove-button" (click)="removeDependency(item)"></span>
								</div>
							</ng-template>
						</div>
					</mat-expansion-panel>
				</div>
			</div>
		</mat-tab>
		<!-- *ngIf="defect.privilege == UIPrivilegeEnum.Write" -->
		<mat-tab *ngIf="!isTemplate && showHistory" #historyTab>
			<ng-template mat-tab-label>
				<span class="tab-label-ico mdi mdi-history"></span>
			</ng-template>
			<div class="tab-content-container history">
				<app-history [changeSets]="changeSets" [stateChanges]="stateChanges">
				</app-history>
			</div>
		</mat-tab>
		<mat-tab *ngIf="!isTemplate" #fileTab>
			<ng-template mat-tab-label>
				<span class="tab-label-ico mdi mdi-attachment"></span>
			</ng-template>
			<div class="tab-content-container files">
				<div *ngIf="isNew || wasFileTabActivatedWithWorkpackage; else fileTabLoading"
						 class="entity-detail-files-content">
					<div class="expansion-panel-header-like">{{ 'workpackages.attachments' | translate }}
					</div>
					<div class="fixed-content-container">
						<!-- <div class="fixed-content-container" *ngIf="canAddFile"> -->
						<button mat-flat-button color="primary" (click)="fileInput.click()">
							<span class="mdi mdi-plus-box upload-ico"></span>
							<span class="c4-ctrl-label">{{ 'documents.upload' | translate }}</span>
						</button>
						<input type="file" (click)="fileInput.value=null" (change)="uploadFiles($event.target.files)"
									 style="display: none" multiple #fileInput />
					</div>
					<app-document-list selectionMode="none" enablePreview="true"></app-document-list>
				</div>
				<ng-template #fileTabLoading>
					<div class="loading-container">
						<ngx-loading [show]="true" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
					</div>
				</ng-template>
			</div>
		</mat-tab>
	</mat-tab-group>
</app-entity-detail-dialog>