import { Component, OnInit, Inject, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UntypedFormBuilder, FormControl, UntypedFormGroup } from '@angular/forms';
import { DocumentUploadData, DocumentUploadService } from '@app/shared/services';
import { ExtendedPlanFieldModel, PlanValidation, PlanValidationError, _planSeparator } from '../schema-list.interfaces';
import { PlanUtils } from '../planningUtils';
import { NumberFormatPipe } from '@app/shared/pipes';
import { ModuleType, PlanOptionModel } from '@app/api';

interface DialogUploadData extends DocumentUploadData {
  selected?: boolean;
  valid?: boolean;
  validatedName?: string;
  schema?: PlanValidation[];
}

export interface UploadDialogResponseData {
  valid?: DialogUploadData[];
  invalid?: DialogUploadData[];
}

@Component({
  selector: 'app-plan-upload-dialog',
  templateUrl: './plan-upload-dialog.component.html',
  styleUrls: ['./plan-upload-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PlanUploadDialogComponent implements OnInit, AfterViewInit {
  title: string = 'planning.dialogs.planUpload.caption';
  uploadFiles: DialogUploadData[];
  selectedFile: DialogUploadData = null;
  seperator: string = _planSeparator;
  filenameDef: ExtendedPlanFieldModel[];
  mappedPlans: {};
  isDragDrop = false;
  oneValid = false;
  resource: string;
  module: ModuleType;
  uploadPath: string = '';
  versionField: string = '';
  calOptions: any[] = [
    { value: 1, label: 'eins' },
    { value: 2, label: 'zwei' },
  ];
  forbiddenChars: RegExp = /[\\\/:\*\?\"<>|]/gm;
  metaIndex: number;
  blurMe: boolean = true;
  inputRule: RegExp;
  // Workaround for angular component issue #13870
  disableAnimation: boolean = true;
  fieldOptionsForm: UntypedFormGroup;
  useAutoIndex: boolean = false;

  get dragDropClass(): string {
    if (this.isDragDrop) {
      return 'drag-drop-active';
    }
    return '';
  }

  get firstErrorOfSelected(): PlanValidationError | null {
    return this.selectedFile?.schema.find(field => field.error != null)?.error;
  }

  get filteredFilenameDef(): ExtendedPlanFieldModel[] {
    return this.filenameDef.filter(d => !d.isSeparator);
  }

  constructor(
    private documentUpload: DocumentUploadService,
    private dialogRef: MatDialogRef<PlanUploadDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private numberFormatPipe: NumberFormatPipe,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.seperator = data.seperator ?? _planSeparator;
    this.filenameDef = JSON.parse(JSON.stringify(data.def));
    this.initForm();
    this.extendNameDef();
    this.uploadFiles = data.items;
    this.mappedPlans = data.map;
    this.resource = data.resource;
    this.module = data.module;
    if (data.module === ModuleType.Bim) this.title = 'ifc.dialogs.upload.caption';
    this.uploadFiles.forEach(f => {
      f.selected = false;
      f.schema = [];
      f.validatedName = '';
      const { fileNameParts, fileExtension } = PlanUtils.parseFileName(f.fileName, this.filenameDef, this.seperator);
      let keyIndex: any = {};
      this.filenameDef.forEach((d, index) => {
        const fieldDef: PlanValidation = {
          field: d.field,
          value: fileNameParts[index] ? fileNameParts[index] : '',
          valid: false,
          isSeparator: d.isSeparator,
        };
        if (this.filenameDef[index].isVersion) keyIndex = { def: fieldDef, index: index };
        f.schema.push(fieldDef);
      });
      if (fileExtension) {
        f.schema[f.schema.length - 1].value = fileExtension;
      }
      //init validation
      f.schema.forEach((part, index) => {
        if (!this.filenameDef[index].isVersion) {
          this.validateInput(part.value, f, index, this.filenameDef[index]);
        }
      });
      this.updateFile(f);
      this.validateInput(keyIndex.def.value, f, keyIndex.index, this.filenameDef[keyIndex.index]);
    });
    if (this.uploadFiles.length > 0) this.selectFile(this.uploadFiles[0]);
    this.uploadPath = this.module === ModuleType.Bim ? 'IFC' : '';
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    setTimeout(() => (this.disableAnimation = false));
  }

  confirm() {
    //upload valid file only
    const validFiles: DialogUploadData[] = [];
    const validIndexFiles: DialogUploadData[] = [];
    const invalidFiles: DialogUploadData[] = [];
    this.uploadFiles.forEach(f => {
      if (f.valid) {
        validFiles.push(f);
      } else {
        invalidFiles.push(f);
      }
    });
    const indexObj: any = {};
    // check for same indizes on upload
    validFiles.forEach(f => {
      f.uploadPath = this.uploadPath;
      f.fileName = f.validatedName;
      const { fullKey } = PlanUtils.getPlanProperties(f.validatedName, this.filenameDef, this.seperator);
      if (!indexObj[fullKey]) {
        //key first occurrence
        indexObj[fullKey] = [];
        indexObj[fullKey].push(f.schema[this.metaIndex].value); // update key - index list
        validIndexFiles.push(f);
      } else {
        const currentIndex = f.schema[this.metaIndex].value;
        if (indexObj[fullKey].indexOf(currentIndex) === -1) {
          indexObj[fullKey].push(currentIndex); //  update key - index list
          validIndexFiles.push(f);
        } else {
          invalidFiles.push(f); //move doublette to invalid files
        }
      }
    });

    //this.sendNotification ? { to: this.selectedUsers } : false;
    const dlgResponse: UploadDialogResponseData = {
      valid: validIndexFiles,
      invalid: invalidFiles,
    };
    this.dialogRef.close(dlgResponse);
  }

  abort() {
    this.dialogRef.close();
  }

  metaTextChange(event, key) {
    this.selectedFile.schema.forEach(f => {
      if (f.field === key) {
        f.value = event.target.value;
      }
    });
    this.updateFile(this.selectedFile);
  }

  selectFile(file: DialogUploadData, resetOptions: boolean = false, index?: number, meta?: ExtendedPlanFieldModel) {
    this.uploadFiles.forEach(f => {
      f.selected = false;
    });
    file.selected = true;
    this.selectedFile = file;

    file.schema.forEach(validation => {
      this.updateFieldOption(
        this.filenameDef.find(fileMeta => fileMeta.field == validation.field),
        validation.value
      );
    });

    if (resetOptions) {
      const val = file.schema[index].value;
      if (meta.options && !meta.isVersion) {
        this.calOptions = [];
        const lval = val.toString().toLowerCase();
        meta.options.forEach(o => {
          if (o.range) {
            const intVal: number = ~~val;
            if (val && intVal >= o.range[0] && intVal <= o.range[1]) {
              const setVal = '00' + intVal;
            }
          } else if (!meta.isVersion && o.value.toLowerCase().indexOf(lval) >= 0) {
            this.calOptions.push(o);
          }
        });
        if (this.calOptions.length > 0) {
          meta.acOptions = this.calOptions;
        } else {
          meta.acOptions = meta.options.slice();
        }
      }
      if (this.metaIndex) {
        // validate index on (every) change
        this.validateInput(file.schema[this.metaIndex].value, file, this.metaIndex, this.filenameDef[this.metaIndex], false);
      }
    }
  }

  removeFile(event: any, file: DialogUploadData, index: number) {
    event.stopPropagation();
    const selectNext = file.selected;
    this.uploadFiles.splice(index, 1);
    if (this.uploadFiles.length <= 0) {
      this.selectedFile = null;
      return;
    }
    if (selectNext) this.selectFile(this.uploadFiles[0]);
    this.validateForm();
  }

  updateFile(item: DialogUploadData) {
    let name = '';
    let isValid = true;

    for (let i = 0; i < item.schema.length; i++) {
      const element: PlanValidation = item.schema[i];
      const prevElement: PlanValidation | undefined = item.schema[i - 1];

      if (i === 0) name = element.value;
      else if (i === item.schema.length - 1) name = `${name}.${element.value}`;
      else if (element.isSeparator || prevElement?.isSeparator) name = `${name}${element.value}`;
      else name = `${name}${this.seperator}${element.value}`;

      if (!element.valid) isValid = false;
    }

    item.validatedName = name;
    item.valid = isValid;
    this.validateForm();
  }

  validateForm() {
    //check all
    let areValid = false;
    this.uploadFiles.forEach(f => {
      if (f.valid) areValid = true;
    });
    this.oneValid = areValid;
  }

  validateAutoInput(
    Value: any,
    file: DialogUploadData,
    index: number,
    meta: ExtendedPlanFieldModel,
    updateOptions: boolean = true
  ) {
    this.validateInput(Value, file, index, this.filenameDef[index], false, true);
    if (!meta.isVersion) {
      this.validateInput(file.schema[this.metaIndex].value, file, this.metaIndex, this.filenameDef[this.metaIndex], false); // update index validatiion
    }
  }

  restrictCharacters(event: any) {
    const key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (this.seperator.indexOf(key) !== -1) {
      event.preventDefault();
      return false;
    }
  }

  validateInput(
    Value: any,
    file: DialogUploadData,
    index: number,
    meta: ExtendedPlanFieldModel,
    updateOptions: boolean = true,
    resetAutoIndex: boolean = false
  ) {
    file.schema[index].valid = false;
    file.schema[index].error = null;
    const val = Value;
    let lowestValidIndex: string = null;
    if (meta.isSeparator) {
      file.schema[index].valid = true;
      file.schema[index].error = null;
      file.schema[index].value = meta.field;
    } else if (meta.options && !meta.isVersion) {
      // meta.isVersion == is index field
      this.calOptions = [];
      const lval = val.toString().toLowerCase();
      const result = [];
      meta.options.forEach(o => {
        if (o.range) {
          const intVal: number = ~~val;
          if ((val || val === 0) && intVal >= o.range[0] && intVal <= o.range[1]) {
            const maxNumberLength = meta.options
              .map(o => o.range[1])
              .sort()
              .reverse()[0]
              .toString().length;
            result.push(this.numberFormatPipe.transform(intVal, maxNumberLength, ''));
          }
        } else if (o.value.toLowerCase().indexOf(lval) >= 0) {
          if (o.value.length == lval.length) result.push(o.value);
          this.calOptions.push(o);
        }
      });
      if (this.calOptions.length > 0 && updateOptions) meta.acOptions = this.calOptions;
      // multiple options for suffix possible
      if (result.length > 0) {
        file.schema[index].value = result[0];
        file.schema[index].valid = true;
      }
    } else {
      if (meta.options && meta.isVersion && file.validatedName) {
        if (resetAutoIndex) file.schema[index].autoIndexed = false;
        //index validation NEW (from index options)
        this.metaIndex = index;
        // index(version) Validation
        const { fullKey } = PlanUtils.getPlanProperties(file.validatedName, this.filenameDef, this.seperator);
        const hasHistory = this.mappedPlans[fullKey] ? true : false;
        let currentHighestIndex = hasHistory ? this.mappedPlans[fullKey].lastIndex : '';
        const indexOptions = [];
        const optionsLength = meta.options.length;
        for (let i = 0; i < optionsLength; i++) {
          // Option has range
          if (meta.options[i].range) {
            currentHighestIndex = ~~currentHighestIndex;
            const minimumValue = meta.options[i].range[0];
            const maximumValue = meta.options[i].range[1];
            const intVal: number = ~~val;
            if ((val || val === 0) && intVal >= minimumValue && intVal <= maximumValue) {
              if (
                currentHighestIndex &&
                currentHighestIndex >= minimumValue &&
                currentHighestIndex <= maximumValue &&
                currentHighestIndex >= intVal
              ) {
                // selected value is lower than current maximum
                file.schema[index].valid = false;
                file.schema[index].error = PlanValidationError.indexTooLow;

                if (currentHighestIndex < maximumValue) {
                  const possibleRangeOptions = JSON.parse(JSON.stringify(meta.options[i]));
                  possibleRangeOptions.value = ~~currentHighestIndex + 1;
                  indexOptions.push(possibleRangeOptions);
                  //auto select next possible index
                  lowestValidIndex = (currentHighestIndex + 1).toString();
                }
                break;
              }
              file.schema[index].valid = true;
              file.schema[index].error = null;
              file.schema[index].value = val;
              // indexOptions.push(meta.options[i]);
              break;
            }
            if (currentHighestIndex && currentHighestIndex >= minimumValue && currentHighestIndex <= maximumValue) {
              // selected value is lower than current maximum
              file.schema[index].valid = false;
              file.schema[index].error = PlanValidationError.indexTooLow;
              if (currentHighestIndex < maximumValue) {
                const possibleRangeOptions = JSON.parse(JSON.stringify(meta.options[i]));
                possibleRangeOptions.value = ~~currentHighestIndex + 1;
                indexOptions.push(possibleRangeOptions);
                //auto select next possible index
                lowestValidIndex = (currentHighestIndex + 1).toString();
              }
              break;
            }
            indexOptions.push(meta.options[i]);
            file.schema[index].valid = false; // false if not in index values
            file.schema[index].error = PlanValidationError.valueNotFound;
          } else {
            // Option has value
            const optionsValue = meta.options[i].value;
            if (currentHighestIndex && optionsValue === currentHighestIndex) {
              // selected value is lower than current maximum
              file.schema[index].valid = false;
              file.schema[index].error = PlanValidationError.indexTooLow;
              break;
            }
            if (optionsValue === val) {
              // Found matching index value with input value before reaching maximum
              file.schema[index].valid = true;
              file.schema[index].error = null;
              file.schema[index].value = val;
              indexOptions.push(meta.options[i]);
              break;
            }
            indexOptions.push(meta.options[i]);
            lowestValidIndex = optionsValue;
            file.schema[index].valid = false; // false if not in index values
            file.schema[index].error = PlanValidationError.valueNotFound;
          }
        }
        //auto select lowest valid index
        if (lowestValidIndex && this.useAutoIndex && !file.schema[index].valid) {
          file.schema[index].value = lowestValidIndex;
          file.schema[index].autoIndexed = true;
          file.schema[index].valid = true;
          file.schema[index].error = null;
        }

        meta.acOptions = indexOptions;
      } else {
        file.schema[index].value = val.replace(this.forbiddenChars, '');
        if (meta.maxLength && val.length > meta.maxLength) {
          file.schema[index].error = PlanValidationError.inputTooLong;
        } else {
          file.schema[index].valid = true;
        }
      }
    }
    this.updateFieldOption(meta, file.schema[index].value);
    this.updateFile(file);
    if (this.metaIndex && index !== this.metaIndex) {
      // validate index on (every) change
      this.validateInput(file.schema[this.metaIndex].value, file, this.metaIndex, this.filenameDef[this.metaIndex], false);
    }
  }

  myVal(current: any, options: any) {
    let response = current;
    options.forEach(o => {
      if (current && o.range) {
        if (current >= o.range[0] && current <= o.range[1]) response = o.range[0];
      }
    });
    return response;
  }

  setBlurState() {
    this.blurMe = false;
  }

  updateInput(event: any, file: DialogUploadData, index: number) {
    if (!this.blurMe) {
      this.blurMe = true;
      return;
    }
    const replacedVal = file.schema[index].value.toString().replace(this.seperator, '');
    event.target.value = replacedVal;
    file.schema[index].value = replacedVal;
    this.validateInput(event.target.value, file, index, this.filenameDef[index], false);
  }

  onDragLeave(event) {
    this.enableDragDrop(event, false);
  }

  onDragEnd(event) {
    this.enableDragDrop(event, false);
  }

  onDragOver(event) {
    this.enableDragDrop(event, true);
  }

  onDragEnter(event) {
    this.enableDragDrop(event, true);
  }

  private enableDragDrop(event, isDragDrop: boolean) {
    this.isDragDrop = isDragDrop;
    if (isDragDrop && event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy';
      event.dataTransfer.effectAllowed = 'copy';
    }
    event.stopPropagation();
    event.preventDefault();
  }

  async onDrop(event) {
    this.enableDragDrop(event, false);
    const newItems: DialogUploadData[] = await this.documentUpload.decodeDropEvent(event, this.uploadPath, this.resource);
    this.updateFileList(newItems);
  }

  async onFileChange(event) {
    const uploadItems: DialogUploadData[] = await this.documentUpload.decodeFiles(
      event.target.files,
      this.uploadPath,
      this.resource
    );
    this.updateFileList(uploadItems);
  }

  updateFileList(items) {
    items.forEach(f => {
      f.selected = false;
      f.schema = [];
      f.validatedName = '';
      const { fileNameParts, fileExtension } = PlanUtils.parseFileName(f.fileName, this.filenameDef, this.seperator);
      this.filenameDef.forEach((d, index) => {
        const fieldDef: PlanValidation = {
          field: d.field,
          value: fileNameParts[index] ? fileNameParts[index] : d.isVersion ? this.getFirstValueForField(d.field) : '',
          valid: !!d.isVersion,
          isSeparator: d.isSeparator,
        };
        f.schema.push(fieldDef);
      });
      if (fileExtension) {
        f.schema[f.schema.length - 1].value = fileExtension;
      }
      //on updateList validation
      f.schema.forEach((part, index) => {
        this.validateInput(part.value, f, index, this.filenameDef[index]);
      });
      this.updateFile(f);
      this.uploadFiles.push(f);
      this.validateForm();
    });
    if (!this.selectedFile && this.uploadFiles.length > 0) this.selectFile(this.uploadFiles[0]);
  }
  extendNameDef() {
    this.filenameDef.forEach(fnd => {
      if (fnd.options) {
        const unlocked = fnd.options.filter(o => !o.isLocked);
        fnd.options = [...unlocked];
        fnd.acOptions = [...unlocked];
      }
    });
  }

  private initForm() {
    const group: { [key: string]: any } = {};
    this.filenameDef.forEach(meta => {
      const key = meta.field;
      const control = this.formBuilder.control(null);

      control.valueChanges.subscribe((option: PlanOptionModel) => {
        this.selectedFile.schema.forEach(validation => {
          if (validation.field === key) {
            validation.value = option.value ?? option.range[0].toString();
            validation.valid = true;
          }
        });
        this.updateFile(this.selectedFile);
        this.validateInput(
          this.selectedFile.schema[this.metaIndex].value,
          this.selectedFile,
          this.metaIndex,
          this.filenameDef[this.metaIndex],
          false
        ); // update index validatiion
      });

      group[key] = control;
    });

    this.fieldOptionsForm = this.formBuilder.group(group);
  }

  private updateFieldOption(meta: ExtendedPlanFieldModel, value: string | number, emitEvent: boolean = false) {
    this.fieldOptionsForm.controls[meta.field].setValue(
      meta.options?.find(o =>
        o.value ? o.value == value : this.isBetween(Number.parseInt(value.toString()), o.range[0], o.range[1])
      ),
      { emitEvent }
    );
  }

  private isBetween(value: number, min: number, max: number) {
    return value >= min && value <= max;
  }

  private getFirstValueForField(field: string) {
    const entry = this.filenameDef.find(x => x.field == field);
    let result = '';

    if (entry && entry.acOptions?.length > 0) {
      result = entry.acOptions.reverse()[0].label;
    }
    return result;
  }
}
