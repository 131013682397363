<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:true }"></ngx-loading>
<div class="c4-dialog-wrapper large-width drive-item-action-dialog">
	<mat-dialog-content>
		<div class="sidebar">
			<div class="title">
				{{ title | translate }}
			</div>
			<div class="title-icon">
				<span class="mdi" [class]="icon"></span>
			</div>
			<mat-nav-list class="c4-sidenav-list">
				<app-sidebar-navigation-item *ngFor="let module of singleItemModules" [active]="selectedModule == module"
																		 [expanded]="isExpanded"
																		 (click)="selectModule(module, moduleData[module].resource)">
					<img class="module-image" [src]="moduleData[module].iconUrl" />
					<span label>{{ 'general.modules.' + module | translate }}</span>
				</app-sidebar-navigation-item>

				<div class="item-group" *ngFor="let module of groupItemModules">
					<app-sidebar-navigation-item [expanded]="isExpanded"
																			 (click)="selectModule(module, moduleData[module].items[0].resource)">
						<img class="module-image" [src]="moduleData[module].iconUrl" />
						<span label>{{ 'general.modules.' + module | translate }}</span>
					</app-sidebar-navigation-item>
					<app-sidebar-navigation-item *ngFor="let item of moduleData[module].items"
																			 [active]="selectedModule == module && selectedResource == item.resource"
																			 [expanded]="isExpanded" (click)="selectModule(module, item.resource)">
						<span class="mdi" [class]="item.icon"></span>
						<span label>{{ item.resource }}</span>
					</app-sidebar-navigation-item>
				</div>
			</mat-nav-list>
		</div>
		<div class="main-container">
			<div class="content">
				<ng-container [ngSwitch]="selectedModule">
					<ng-container *ngSwitchCase="ModuleType.Document">
						<div class="document-navigation-container">
							<div class="document-navigation">
								<app-document-path></app-document-path>
								<button *ngIf="documentsService.canAddFolder" mat-button (click)="documentsService.createFolder()">
									<span class="mdi mdi-folder-plus"></span>
									<span class="c4-ctrl-label c4-xs-hide">{{ 'documents.createFolder' | translate }}</span>
								</button>
							</div>
						</div>

						<app-document-list selectionMode="single">
						</app-document-list>
					</ng-container>

					<app-defects-list *ngSwitchCase="ModuleType.Defect" [defects]="defects" mode="singleSelectIntegrated"
														[(selection)]="selectedEntity" [rows]="8" [rowsOptions]="null">
					</app-defects-list>

					<app-diary-list *ngSwitchCase="ModuleType.ConstructionDiary" [diaries]="diaries" mode="singleSelectIntegrated"
													[(selection)]="selectedEntity" [rows]="8" [rowsOptions]="null">
					</app-diary-list>

					<app-issue-list *ngSwitchCase="ModuleType.Bim" [issues]="issues" mode="singleSelectIntegrated"
													[(selection)]="selectedEntity" [rows]="8" [rowsOptions]="null">
					</app-issue-list>

					<app-room-list *ngSwitchCase="ModuleType.RoomBook" [rooms]="rooms" mode="singleSelectIntegrated"
												 [(selection)]="selectedEntity" [rows]="8" [rowsOptions]="null">
					</app-room-list>

					<div class="centered-large-icon" *ngSwitchCase="ModuleType.Gallery">
						<span class="mdi" [class]="galleryIcon"></span>
						<span class="label">{{ 'gallery.addToolTip' | translate }}</span>
					</div>
				</ng-container>
			</div>
			<div class="actions">
				<button mat-stroked-button (click)="close()">{{ cancelLabel | translate }}</button>
				<button *ngIf="skipLabel" mat-stroked-button (click)="skip()">{{ skipLabel | translate }}</button>
				<button mat-flat-button color="primary" (click)="confirm()"
								[disabled]="!(selectedModule == ModuleType.Gallery || (selectedModule == ModuleType.Document && documentsService.canAddFile) || !!selectedEntity)">
					{{ confirmLabel | translate }}
				</button>
			</div>
		</div>
	</mat-dialog-content>
</div>