import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config';
import { environment } from '@env/environment';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';

@Injectable({
  providedIn: 'root',
})
export class ApiUrlService {
  private _fqdn: string;

  constructor() {}

  get fqdn(): string {
    return this._fqdn;
  }

  set fqdn(fqdn: string) {
    this._fqdn = fqdn;
  }

  get apiUrl() {
    return this.replaceApiFQDN(AppConfigService.settings.api.url);
  }

  get isLocalDevEnvironment() {
    return !CapacitorUtils.isApp() && !environment.production && location.hostname == 'localhost';
  }

  replaceApiFQDN(url: string) {
    return url.replace('$protocol', `http${this.isLocalDevEnvironment ? '' : 's'}`).replace('$host', this.fqdn);
  }

  setFallbackFQDN() {
    this._fqdn = this.isLocalDevEnvironment
      ? 'localhost:4440'
      : [AppConfigService.settings.fallbackSubdomain, AppConfigService.settings.publicDomain].filter(x => !!x).join('.');
  }
}
