import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-overflow-extend',
  templateUrl: './overflow-extend.component.html',
  styleUrls: ['./overflow-extend.component.scss'],
})
export class OverflowExtendComponent implements OnDestroy, OnInit {
  @ViewChild('root', { static: true }) root: ElementRef<HTMLDivElement>;
  @ViewChild('content', { static: true }) content: ElementRef<HTMLDivElement>;

  @Input() heightInRem: number;
  @Input() extendTitle: string = 'general.showMore';
  @Input() isExtended: boolean = false;
  @Output() isExtendedChange = new EventEmitter<boolean>();

  showExtender: boolean = false;

  // @ts-ignore
  private resizeObserver: ResizeObserver;

  constructor() {}

  ngOnInit() {
    // @ts-ignore
    this.resizeObserver = new ResizeObserver(entries => {
      const contentContainer = entries[0];
      this.showExtender = contentContainer.contentRect.height > this.root.nativeElement.clientHeight;
    });

    this.resizeObserver.observe(this.content.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObserver.unobserve(this.content.nativeElement);
  }

  extend() {
    this.isExtended = true;
    this.isExtendedChange.emit(true);
  }
}
