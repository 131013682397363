import { Injectable } from '@angular/core';

export enum PrintSize {
  auto = 'auto',
  landscapeA3 = 'a3 landscape',
  landscapeA4 = 'a4 landscape',
  landscapeA5 = 'a5 landscape',
  portraitA3 = 'a3 portrait',
  portraitA4 = 'a4 portrait',
  portraitA5 = 'a5 portrait',
}

export interface PrintConfig {
  size?: PrintSize;
  margin?: string;
}

@Injectable({
  providedIn: 'root',
})
export class PrintService {
  constructor() {}

  print(config: PrintConfig) {
    const styleSheet = document.createElement('style');
    styleSheet.innerText = this.getStyle(config);

    document.head.appendChild(styleSheet);
    window.print();
    document.head.removeChild(styleSheet);
  }

  private getStyle(config: PrintConfig) {
    const size = config.size ? `size: ${config.size};` : '';
    const margin = config.margin ? `margin: ${config.margin};` : '';

    return `
      @media print {
        @page {
          ${size}
          ${margin}
        }
      }
    `;
  }
}
