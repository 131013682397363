import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { AbstractControl, UntypedFormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { FormComponent } from '@app/core/utils/form-component';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';

export interface TextInputDialogParams {
  title?: string;
  placeholder?: string;
  textInput?: string;
  suffix?: string;
  validators?: ((control: AbstractControl) => ValidationErrors)[];
  serverRequestAction?: (textInput: string) => Promise<boolean>;
}

@Component({
  selector: 'app-text-input-dialog',
  templateUrl: './text-input-dialog.component.html',
  styleUrls: ['./text-input-dialog.component.scss'],
})
export class TextInputDialogComponent extends FormComponent implements OnInit, Busy {
  title: string;
  placeholder: string;
  suffix: string;
  isBusy: boolean;

  private validators = [Validators.required];
  private presetName: string;
  private serverRequestAction?: (textInput: string) => Promise<boolean>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: TextInputDialogParams,
    private dialogRef: MatDialogRef<TextInputDialogComponent>,
    private formBuilder: UntypedFormBuilder
  ) {
    super();

    this.title = data?.title ?? 'general.actions.enterName';
    this.placeholder = data?.placeholder ?? 'general.name';
    this.presetName = data?.textInput ?? '';
    this.suffix = data?.suffix;
    this.serverRequestAction = data?.serverRequestAction;

    const validators = data?.validators ?? [];
    this.validators.push(...validators);
  }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      name: [this.presetName, { validators: this.validators }],
    });

    this.f.name.valueChanges.subscribe((name: string) => {
      this.f.name.setValue(name.trimStart(), { emitEvent: false });
    });
  }

  async confirm() {
    if (this.form.valid) {
      const newName = this.f.name.value?.trim();

      if (newName == this.presetName) {
        this.dialogRef.close();
        return;
      }

      let result = true;
      if (this.serverRequestAction) {
        result = await using(new BusyScope(this), async _ => {
          return await this.serverRequestAction(newName);
        }).catch(() => {
          return false;
        });
      }

      if (result) this.dialogRef.close(newName);
    }
  }

  abort() {
    this.dialogRef.close();
  }
}
