<mat-form-field class="chips-autocomplete-container" [class.disabled]="isDisabled">
  <mat-chip-list #chipList>
    <mat-chip *ngFor="let value of selectedValues; let i = index" [class]="value?.cls" (click)="onClick(i)"
              [selectable]="false" [removable]="true" [disabled]="isDisabled" (removed)="removeValue(i)"
              [matTooltip]="value?.tooltip | translate:value">
      {{ value[displayField] }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
    <input #userInput placeholder="{{ label | translate }} {{ isRequired ? '*' : '' }}"
           #trigger="matAutocompleteTrigger" [matAutocomplete]="auto" [matChipInputFor]="chipList"
           (input)="filterAutoValues($event.target.value)" (focus)="onFocus()" (blur)="onBlur()"
           [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addValue($event.value)"
           [disabled]="isDisabled">
  </mat-chip-list>
  <div *ngIf="isDisabled" class="c4-disabled-formfield-underline"></div>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addValue($event.option.value)">
    <mat-option *ngFor="let item of autoValues" [value]="item">
      <span>{{ item[displayField] }}</span>
    </mat-option>
  </mat-autocomplete>
</mat-form-field>