export class ConcurrentAsyncFunction {
    private pending: Map<string, Promise<any>> = new Map<string, Promise<any>>();

    public run<TResult>(key: string, func: () => Promise<TResult>): Promise<TResult> {
        if (!this.pending.has(key)) {
            const promise = func();
            promise.finally(() => {
                this.pending.delete(key);
            });
            this.pending.set(key, promise);
            return promise;
        } else {
            return this.pending.get(key);
        }
    }

    public clear() {
        this.pending.clear();
    }
}