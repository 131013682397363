<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
<div class="c4-dialog-wrapper">
	<h1 mat-dialog-title>{{ 'dialogs.teamAssign.title' | translate }}</h1>
	<mat-dialog-content [formGroup]="form" class="dialog-content">
		<div class="add-user-row">
			<app-async-search-autocomplete formControlName="selectedUser"
																		 (blur)="validateSelectedUser($event == cancelButton._elementRef.nativeElement)"
																		 [displayField]="'emailAddress'"
																		 [placeholder]="('dialogs.teamAssign.email' | translate) + ' *'"
																		 [fetchOptions]="fetchUser" [optionTemplate]="userOptionTemplate"
																		 [errors]="f.email.errors">
			</app-async-search-autocomplete>
			<ng-template #userOptionTemplate let-user="item">
				<div class="option-content-with-subtitle">
					<span class="title">{{ user.emailAddress }}</span>
					<span class="subtitle">{{ user.firstname }} {{ user.lastname }}</span>
				</div>
			</ng-template>

			<mat-form-field>
				<mat-select formControlName="gender" [placeholder]="'dialogs.teamAssign.genderLabel' | translate">
					<mat-option *ngFor="let gender of genders" [value]="gender.key">
						{{ gender.value | translate }}
					</mat-option>
				</mat-select>
			</mat-form-field>
		</div>

		<div class="add-user-row">
			<ng-container [ngTemplateOutlet]="inputFieldTemplate" [ngTemplateOutletContext]="{
				fieldName: 'firstname'
			}"></ng-container>

			<ng-container [ngTemplateOutlet]="inputFieldTemplate" [ngTemplateOutletContext]="{
				fieldName: 'lastname'
			}"></ng-container>
		</div>

		<div class="add-user-row">
			<app-search-select [title]="'dialogs.teamAssign.organizationTitle'" [selectList]="organizations"
												 [selectFormGroup]="form" selectFormControlName="organization" [addSelectNew]="true"
												 (addClicked)="addOrganization()" [optionTemplate]="organizationOptionTemplate">
			</app-search-select>
			<ng-template #organizationOptionTemplate let-organization="item">
				<div class="option-content-with-subtitle in-search-select">
					<span class="title">{{ organization.name }}</span>
					<span class="subtitle">{{ organization.address.zipCode }} {{ organization.address.city }}</span>
				</div>
			</ng-template>

			<mat-slide-toggle formControlName="inviteToTeam" class="min-width">
				{{ 'dialogs.teamAssign.inviteToTeam' | translate }}
			</mat-slide-toggle>
		</div>

		<div class="add-user-row">
			<app-chips-autocomplete class="roles" formControlName="roles" [values]="roles" [label]="'team.userRoles'">
			</app-chips-autocomplete>
		</div>

		<div class="add-user-row">
			<button mat-button [disabled]="form.invalid || isSelectedUserInProject" (click)="addUser()"
							[matTooltip]="(isSelectedUserInProject ? 'team.allreadyAssigned' : 'dialogs.teamAssign.assignUser') | translate">
				<span class="mdi mdi-plus-box"></span>
				<span>{{ 'general.add' | translate }}</span>
			</button>
		</div>

		<h3>{{ 'dialogs.teamAssign.assignedUsers' | translate }}</h3>
		<div class="grid-container">
			<div class="added-users">
				<div class="c4-table-grid-cell c4-table-grid-header-top">
					{{ 'dialogs.teamAssign.email' | translate }}
				</div>
				<div class="c4-table-grid-cell c4-table-grid-header-top">
					{{ 'dialogs.teamAssign.organizationTitle' | translate }}
				</div>
				<div class="c4-table-grid-cell c4-table-grid-header-top">
					{{ 'team.userRoles' | translate }}
				</div>
				<div class="c4-table-grid-cell c4-table-grid-header-top centered">
					{{ 'dialogs.teamAssign.msTeams' | translate }}
				</div>
				<div class="c4-table-grid-cell c4-table-grid-header-top centered"></div>

				<ng-container *ngFor="let user of addedUsers; let i = index">
					<div class="c4-table-grid-cell">
						<div class="user-details">
							<span class="title" [matTooltip]="user.emailAddress">{{ user.emailAddress }}</span>
							<span class="subtitle" [matTooltip]="user.fullname">{{ user.fullname }}</span>
						</div>
					</div>
					<div class="c4-table-grid-cell">
						<span [matTooltip]="user.organization?.name">
							{{ user.organization?.name }}
						</span>
					</div>
					<div class="c4-table-grid-cell">
						<mat-chip-list>
							<mat-chip *ngFor="let role of user.roles" class="abridged-chip" [matTooltip]="role.name">
								<span>{{ role.name }}</span>
							</mat-chip>
						</mat-chip-list>
					</div>
					<div class="c4-table-grid-cell centered">
						<span [class.mdi-checkbox-blank-circle-outline]="user.inviteToTeam === false"
									[class.mdi-checkbox-marked-circle-outline]="user.inviteToTeam === true" class="mdi"></span>
					</div>
					<div class="c4-table-grid-cell centered">
						<span class="mdi mdi-trash-can-outline action" (click)="removeAddedUser(i)"></span>
					</div>
				</ng-container>
			</div>
			<span *ngIf="!addedUsers.length" class="no-data mdi mdi-blur-off">{{ 'dialogs.teamAssign.noDataText' | translate
				}}</span>
		</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button #cancelButton mat-flat-button (click)="close()">{{ 'general.cancel' | translate }}</button>
		<button mat-flat-button color="primary" (click)="confirm()" [disabled]="!addedUsers.length">
			{{ 'general.ok' | translate }}
		</button>
	</mat-dialog-actions>
</div>

<ng-template #inputFieldTemplate [formGroup]="form" let-fieldName="fieldName">
	<mat-form-field>
		<mat-label>
			<span>{{ 'dialogs.teamAssign.' + fieldName | translate }}</span>
		</mat-label>
		<input matInput [formControlName]="fieldName">
		<mat-error *ngIf="f[fieldName].invalid">
			{{ 'general.errorForms.' + getError(fieldName) | translate }}
		</mat-error>
	</mat-form-field>
</ng-template>