import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';

type InputAction = (input: string, toggleValue?: boolean) => Promise<boolean>;
export interface InputActionDialogResult {
  wasCanceled?: boolean;
  toggleValue?: boolean;
}

@Component({
  selector: 'app-input-action-dialog',
  templateUrl: './input-action-dialog.component.html',
  styleUrls: ['./input-action-dialog.component.scss'],
})
export class InputActionDialogComponent implements OnInit, Busy {
  isBusy: boolean;
  title: string = 'TITLE_IS_MISSING';
  newName: string;
  action: InputAction;
  alternativeAction: InputAction;
  alternativeActionLabel: string;
  validate: InputAction;
  isValid: boolean;
  isInputDisabled: boolean;
  description: string;
  toggleLabel: string;

  showToggle: boolean = false;
  toggleValue: boolean;

  constructor(
    public dialogRef: MatDialogRef<InputActionDialogComponent, InputActionDialogResult>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data?.title;
    this.newName = data?.text ?? '';
    this.validate = data?.validate;
    this.action = data?.action;
    this.alternativeActionLabel = data?.alternativeActionLabel;
    this.alternativeAction = data?.alternativeAction;
    this.isInputDisabled = !!data?.isInputDisabled;
    this.description = data?.description;
    this.showToggle = data?.toggleDefault !== undefined;
    this.toggleValue = data?.toggleDefault;
    this.toggleLabel = data?.toggleLabel;
  }

  ngOnInit() {
    this.validateInput(this.newName);
  }

  async performAction(useAlternative: boolean = false) {
    if (useAlternative && !this.alternativeAction) throw 'Alternative action not set';

    if (!useAlternative && !this.action) throw 'Action not set';

    using(new BusyScope(this), async _ => {
      const success = useAlternative
        ? await this.alternativeAction(this.newName, this.toggleValue)
        : await this.action(this.newName, this.toggleValue);

      if (success) this.dialogRef.close({ toggleValue: this.toggleValue });
    });
  }

  cancel() {
    this.dialogRef.close({ wasCanceled: true });
  }

  async validateInput(newName: string) {
    this.isValid = !this.validate || (await this.validate(newName));
  }
}
