import { Injectable } from '@angular/core';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import { Zoho, ZohoEvent } from '@app/core/utils/zoho-types';

@Injectable({
  providedIn: 'root',
})
export class ZohoService {
  private isReady: boolean = false;
  // private isApp: boolean = false;
  private zoho: Zoho;
  private backdrop: HTMLDivElement;

  constructor() {
    // this.isApp = CapacitorUtils.isApp();

    // if (this.isApp) return;

    window.ZohoHCAsapSettings = {
      hideLauncherIcon: true,
    };

    window.ZohoHCAsapReady = () => {
      if ('ZohoHCAsap' in window) {
        this.zoho = window.ZohoHCAsap;
        this.isReady = true;

        this.zoho.Events.UnSubscribe(ZohoEvent.open);
        this.zoho.Events.Subscribe(ZohoEvent.open, () => {
          const div = document.createElement('div');

          div.className = 'zoho-backdrop';
          div.onclick = this.zoho.Actions.Close;
          document.body.appendChild(div);

          this.backdrop = div;
        });

        this.zoho.Events.UnSubscribe(ZohoEvent.close);
        this.zoho.Events.Subscribe(ZohoEvent.close, () => {
          if (this.backdrop != null) {
            document.body.removeChild(this.backdrop);
            this.backdrop = null;
          }
        });
      }
    };

    this.addZoho();
  }

  get isAvailable(): boolean {
    return this.isReady; // && !this.isApp;
  }

  open() {
    // if (this.isApp) throw 'Zoho not supported in App';

    if (this.isReady) {
      this.zoho?.Actions.Open();
    }
  }

  close() {
    if (this.isReady) {
      this.zoho?.Actions.Close();
    }
  }

  private addZoho() {
    const zohoScript = document.createElement('script');
    zohoScript.setAttribute('type', 'text/javascript');
    // zohoScript.setAttribute('nonce', 'My4clp0ifh0R25B8rp7yJQ==');
    zohoScript.setAttribute('src', 'https://desk.zoho.eu/portal/api/web/inapp/83461000000147009?orgId=20079874580');
    // zohoScript.setAttribute('defer', 'defer');
    document.body.appendChild(zohoScript);
  }
}
