import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { RoomModel } from '@app/api';
import { TaskLikeDialogTabType } from '@app/core/enumerations';
import { DocumentsService } from '@app/shared/services';
import { ConfirmDialogComponent } from '../../../dialogs/confirm-dialog/confirm-dialog.component';
import { MultiImageCaptureDialogComponent } from '../../../dialogs/multi-image-capture-dialog/multi-image-capture-dialog.component';
import { UploadComponent } from '../../../upload/upload.component';

@Component({
  selector: 'app-room-edit-files',
  templateUrl: './room-edit-files.component.html',
  styleUrls: ['./room-edit-files.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoomEditFilesComponent implements OnInit {
  @Input() room: RoomModel;
  @Input() filesFolderExists: boolean;
  @Input() attachedFiles: File[];
  @Output() attachedFilesChanged = new EventEmitter<File[]>();
  @Input() cameraCheck: boolean;
  @Output() cameraCheckChange = new EventEmitter<boolean>();
  @Output() tabSelectedChanged = new EventEmitter<TaskLikeDialogTabType>();

  @ViewChild(UploadComponent) uploadComponent: UploadComponent;

  constructor(private dialog: MatDialog, private documentsService: DocumentsService) {}

  get canAddFile(): boolean {
    return this.documentsService.canAddFile;
  }

  async ngOnInit() {
    if (this.cameraCheck) {
      this.cameraCheck = false;
      this.cameraCheckChange.emit(this.cameraCheck);

      let hasUploadStarted = false;
      const hasConfirmed = await this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            title: 'general.confirmation.cameracheckCaption',
            description: 'general.confirmation.cameracheckDescription',
          },
        })
        .afterClosed()
        .toPromise();
      if (hasConfirmed) {
        const takenImages = await this.dialog
          .open(MultiImageCaptureDialogComponent, { panelClass: 'c4-mobile-full-height', width: '100%', maxWidth: '100vw' })
          .afterClosed()
          .toPromise();
        if (takenImages.length > 0) {
          hasUploadStarted = true;
          if (!this.filesFolderExists) this.uploadComponent.fileChanged(takenImages);
          else this.documentsService.uploadFiles(takenImages);
        }
      }

      if (!hasUploadStarted) this.tabSelectedChanged.emit(TaskLikeDialogTabType.details);
    }
  }

  uploadFiles(files: File[]) {
    this.documentsService.uploadFiles(files);
  }
  fileAdded(file: File) {
    const fileNameFormat = this.documentsService.fileNameFormat;
    const shouldRename = this.documentsService.shouldRenameFile(file) && fileNameFormat != null;
    const fileNameSplit = file.name.split('.');
    const fileExtension = fileNameSplit.pop();
    const fileName = fileNameSplit.join('.');

    let newFileName = shouldRename ? fileNameFormat.getFormattedString([], fileExtension) : file.name;

    let i = 0;
    while (this.attachedFiles.some(u => u.name === newFileName)) {
      newFileName = shouldRename
        ? fileNameFormat.getFormattedString([` (${++i})`], fileExtension)
        : `${fileName} (${++i}).${fileExtension}`;
    }

    file = this.renameFile(file, newFileName);
    this.attachedFiles.push(file);
    this.attachedFilesChanged.emit(this.attachedFiles);
  }

  remove(index: number) {
    this.attachedFiles.splice(index, 1);
    this.attachedFilesChanged.emit(this.attachedFiles);
  }

  private renameFile(originalFile: File, newName: string) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }
}
