<div>
	<div class="upload-image-header">
		<div class="title">{{ title | translate }}</div>
		<div class="upload-image-actions">
			<button *ngIf="image && image.length > 0" mat-icon-button (click)="unsetImage()">
				<span class="mdi mdi-trash-can-outline"></span>
			</button>
			<mat-slide-toggle *ngIf="canChangeImageCropMode" color="primary" [checked]="shouldImageCover"
												(change)="onImageCoverChange($event)">
				{{ 'setup.customization.logoCut' | translate }}
			</mat-slide-toggle>
		</div>
	</div>
	<div class="upload-image-body" [style.min-height.rem]="previewImageHeight">
		<app-upload class="upload" [validTypes]="validTypes" (onFileUpload)="onFileChange($event)"></app-upload>
		<div *ngIf="image && image.length > 0" class="image" [style.height.rem]="previewImageHeight"
				 [style.width.rem]="previewImageWidth" [style.background-image]="'url(data:image/png;base64,'+ image +')'">
		</div>
	</div>
</div>