import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-chips-autocomplete',
  templateUrl: './chips-autocomplete.component.html',
  styleUrls: ['./chips-autocomplete.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ChipsAutocompleteComponent,
    },
  ],
})
export class ChipsAutocompleteComponent implements OnInit, ControlValueAccessor {
  @Input() label = '';
  @Input() isRequired: boolean = false;
  @Input() preserveValueOnBlur: boolean = false;
  @Input() context: any = null;
  @Input() displayField: string = 'name';
  @Input() compare: (a: any, b: any) => number = this.compareByDisplayField;
  @Input() fromString: (str: string, context: any) => Promise<any> = null;
  @Input() tryAddValue: (value: any, context: any) => Promise<boolean> = null;
  @Input() tryClickValue: (value: any, context: any) => Promise<boolean> = null;
  @Input() tryRemoveValue: (value: any, context: any) => Promise<boolean> = null;
  @Input() set values(values: any[]) {
    this._values = values ?? [];
    this.setSelectableValues();
  }
  @Input() set selectedValues(values: any[]) {
    this._selectedValues = values ?? [];
    this.setSelectableValues();
  }

  @Input()
  isDisabled: boolean = false;

  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;

  isInitialized: boolean;
  isTouched: boolean;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  selectableValues: any[] = [];
  autoValues: any[] = [];
  areCustomValuesAllowed: boolean = false;

  onChange = (changes: any[]) => {};
  onTouched = () => {};

  private _values: any[] = [];
  private _selectedValues: any[] = [];
  private equals = (a: any, b: any) => this.compare(a, b) === 0;

  constructor() {}

  get values(): any[] {
    return this._values;
  }

  get selectedValues(): any[] {
    return this._selectedValues;
  }

  async ngOnInit() {
    this.areCustomValuesAllowed = !!this.fromString;
    this.isInitialized = true;
  }

  setSelectableValues() {
    this.selectableValues = this.values.filter(
      value => !this.selectedValues.some(selectedValue => this.equals(value, selectedValue))
    );

    this.selectableValues.sort(this.compare);
  }

  filterAutoValues(filter: string) {
    if (this.preserveValueOnBlur) {
      this.markAsTouched();
    }

    filter = (filter ?? '').trim().toLowerCase();

    this.autoValues = this.selectableValues.filter(
      value => (value[this.displayField]?.toLowerCase()?.indexOf(filter) ?? -1) >= 0
    );
  }

  onFocus() {
    this.autoValues = [...this.selectableValues];
  }

  onBlur() {
    if (!this.preserveValueOnBlur) {
      this.userInput.nativeElement.value = '';
    }
  }

  async tryAddPendingValue() {
    const pendingValue = this.userInput.nativeElement.value;
    if (pendingValue) {
      await this.addValue(pendingValue);
    }
  }

  async addValue(value: any | string) {
    if (!value) return;

    if (typeof value === 'string') {
      if (!this.areCustomValuesAllowed) return;

      const fromString = await this.fromString(value, this.context);
      const isInSelected = this.selectedValues.some(v => this.equals(v, fromString));

      if (isInSelected) return;

      const existingValue = this.selectableValues.filter(v => this.equals(v, fromString))[0];

      value = existingValue ?? fromString;
    }

    const index = this.selectableValues.indexOf(value);

    if (index >= 0 || this.areCustomValuesAllowed) {
      const isCallbackSuccess = !this.tryAddValue || (await this.tryAddValue(value, this.context));

      if (isCallbackSuccess) {
        if (index >= 0) {
          this.selectableValues.splice(index, 1);
        }

        this.selectedValues.push(value);
      }
    }

    this.userInput.nativeElement.value = '';
    this.filterAutoValues('');
    this.markAsTouched();
    this.onChange(this.selectedValues);
  }

  async onClick(index: number) {
    if (index >= 0 && this.tryClickValue) {
      const value = this.selectedValues[index];

      await this.tryClickValue(value, this.context);
    }
  }

  async removeValue(index: number) {
    if (index >= 0) {
      const value = this.selectedValues[index];
      const isCallbackSuccess = !this.tryRemoveValue || (await this.tryRemoveValue(value, this.context));
      if (isCallbackSuccess) {
        this.selectedValues.splice(index, 1);

        if (this.values.some(v => this.equals(v, value))) {
          this.selectableValues.push(value);
          this.selectableValues.sort(this.compare);
        }
      }

      this.onChange(this.selectedValues);
    }
  }

  private compareByDisplayField(value: any, other: any) {
    const displayField = this?.displayField;
    if (displayField) {
      const name = value ? value[displayField]?.toLowerCase() : null;
      const otherName = other ? other[displayField]?.toLowerCase() : null;
      return name == otherName ? 0 : name > otherName ? 1 : -1;
    }
  }

  // ----- Reactive Forms Methods -----

  writeValue(values: any[]): void {
    this.selectedValues = values;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private markAsTouched() {
    if (!this.isTouched) {
      this.onTouched();
      this.isTouched = true;
    }
  }
}
