<div class="image-details-container c4-busy">
	<div class="button-container">
		<button mat-mini-fab color="primary" class="back-button" (click)="closeItem()">
			<span class="mdi mdi-close"></span>
		</button>
	</div>

	<img class="image"
			 *ngIf="image.previewSignedUrl | imagePreview:image.id:image.resourceIdentifier.key.name:'Preview':image.lastModifiedDateTime:{bright:true,onlyImage:true} | async as imageUrl; else loadingImage"
			 [src]="imageUrl" (click)="previewImage()">
	<ng-template #loadingImage>
		<div class="image c4-busy-content" style="height: 22.5rem;">
		</div>
	</ng-template>

	<div class="image-name-container">
		<span class="image-name">{{ image.name }}</span>
		<button mat-icon-button [matMenuTriggerFor]="imageMenu">
			<span class="mdi mdi-dots-vertical"></span>
		</button>

		<mat-menu #imageMenu="matMenu">
			<button mat-menu-item (click)="downloadImage()">
				<span class="mdi mdi-download"></span>
				<span>{{ 'general.download' | translate }}</span>
			</button>
			<button mat-menu-item (click)="moveImage()" [disabled]="!image.isDeleteAllowed">
				<span class="mdi mdi-image-move"></span>
				<span>{{ 'general.move' | translate }}</span>
			</button>
			<button mat-menu-item (click)="shareImage()" [disabled]="!canShare">
				<span class="mdi mdi-email-outline"></span>
				<span>{{ 'general.notify' | translate }}</span>
			</button>
		</mat-menu>
	</div>
	<span class="image-size">{{ image.size | filesize:locale }}</span>
	<div class="properties">
		<span class="header">
			{{ 'general.information' | translate }}
		</span>
		<div *ngIf="image.predictedCreationDate">
			<span>{{ 'general.createdOn' | translate }}</span>
			<span class="value">{{ image.predictedCreationDate | localizedDate }}</span>
		</div>
		<div>
			<span>{{ 'general.modifiedBy' | translate }}</span>
			<span *ngIf="image.lastModifiedBy?.user?.displayName" class="value">{{ image.lastModifiedBy.user.displayName
				}}</span>
		</div>
		<div>
			<span>{{ 'general.modifiedOn' | translate }}</span>
			<span *ngIf="image.lastModifiedDateTime" class="value">{{ image.lastModifiedDateTime | localizedDate }}</span>
		</div>
		<div>
			<span>{{ 'general.module' | translate }}</span>
			<span class="value">{{ ('general.modules.' + image.resourceIdentifier.moduleType) | translate }}</span>
		</div>
		<div *ngFor="let property of additionalProperties">
			<span>{{ (fieldTranslationPrefix + (property.fieldName | startLower)) | translate }}</span>
			<span class="value">{{ property.value | translate }}</span>
		</div>
	</div>
	<div class="actions">
		<button mat-flat-button color="primary" (click)="navigateToImage()"
						[disabled]="image.resourceIdentifier.moduleType == moduleType.Gallery">
			{{ 'general.actions.openDetails' | translate }}
		</button>
		<button mat-stroked-button color="warn" (click)="deleteItem()" [disabled]="!image.isDeleteAllowed">
			{{ 'general.delete' | translate }}
		</button>
	</div>
	<div class="image-tags">
		<span class="header">
			{{ 'general.tags' | translate }}
		</span>
		<app-image-tag-editor [selectedTags]="image.tags | imageTagFromServer"
													(tagsChanged)="fireTagChange($event)"></app-image-tag-editor>
	</div>
</div>