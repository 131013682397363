<div class="c4-dialog-wrapper">
	<h1 mat-dialog-title>{{ title | translate:params }}</h1>
	<mat-dialog-content>
		<app-image-tag-editor (tagsChanged)="fireChange($event)" [selectedTags]="selectedTags"></app-image-tag-editor>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="fireCancel()" type="button">{{ 'general.cancel' | translate }}</button>
		<button mat-flat-button color="primary" (click)="fireSubmit()" [disabled]="isBusy || !hasChanges" type="button">{{
			'general.save' | translate
			}}</button>
	</mat-dialog-actions>
</div>
<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:false }"></ngx-loading>