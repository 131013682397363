import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CraftModel, StandardTextModel } from '@app/api';
import { ApiService, OfflineService } from '@app/core';
import { TextareaResizeType } from '@app/core/enumerations';
import { ISnapshotLoginState } from '@app/core/ISnapshotLoginState';
import { FormComponent } from '@app/core/utils/form-component';
import { UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { StandardTextSelectionDialogComponent } from './standard-text-selection-dialog/standard-text-selection-dialog.component';

@Component({
  selector: 'app-standard-text-input',
  templateUrl: './standard-text-input.component.html',
  styleUrls: ['./standard-text-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: StandardTextInputComponent,
    },
  ],
})
export class StandardTextInputComponent extends FormComponent implements OnInit, Busy, ControlValueAccessor {
  @Input() title: string;
  @Input() placeholder: string;
  @Input() minRows: number;
  @Input() maxRows: number;
  @Input() resize: TextareaResizeType = TextareaResizeType.none;
  @Input() set selectedCrafts(value: CraftModel[] | CraftModel) {
    const models = value instanceof Array ? value : value ? [value] : [];
    this.selectedCraftIds = models && models.length > 0 ? models.map(model => model.id) : [];
  }

  disableStandardTextButton = false;

  isBusy: boolean;
  onChange = _ => {};
  onTouched = () => {};

  private selectedCraftIds: string[] = [];

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private userNotification: UserNotificationService,
    private offlineService: OfflineService
  ) {
    super();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      text: [null],
    });

    this.f.text.valueChanges.subscribe(value => {
      this.onChange(value);
    });

    this.subscribe(this.offlineService.projectStatus$, _ => {
      this.disableStandardTextButton = this.offlineService.isProjectOffline();
    });
  }

  async addStandardText(textarea: HTMLTextAreaElement) {
    if (this.form.enabled && !this.disableStandardTextButton) {
      const selectionStart = textarea.selectionStart;
      const selectionEnd = textarea.selectionEnd;
      const value: string = this.f.text.value ?? '';
      let standardTexts: StandardTextModel[] = [];
      let success = false;
      await using(new BusyScope(this), async busy => {
        try {
          const texts = await this.apiService.getStandardTexts();
          standardTexts =
            this.selectedCraftIds.length > 0
              ? texts.filter(t => t.crafts.some(c => this.selectedCraftIds.contains(c.id)) || t.crafts.length === 0)
              : texts;

          if (standardTexts.length > 0) {
            success = true;
          } else {
            await this.userNotification.notify('standardTexts.error.empty');
          }
        } catch (e) {
          await this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.standardTexts', e);
        }
      });

      if (success) {
        const standardText = (await this.dialog
          .open(StandardTextSelectionDialogComponent, {
            data: {
              standardTexts,
            },
          })
          .afterClosed()
          .toPromise()) as StandardTextModel;

        if (standardText) {
          this.f.text.patchValue(value.slice(0, selectionStart) + standardText.text + value.slice(selectionEnd));
        }
      }
    }
  }

  // ===== Reactive Forms Controls =====

  writeValue(value: any): void {
    this.f.text.patchValue(value, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }
}
