import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserNotificationService } from '@app/shared/services';

@Component({
  selector: 'app-multi-select-dialog',
  templateUrl: './multi-select-dialog.component.html',
  styleUrls: ['./multi-select-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MultiSelectDialogComponent implements OnInit {
  mustSelect: boolean;
  filteredItems: Selectable[] = [];
  // selectedItems: Selectable[] = [];
  title: string;
  description: string;
  canCancel: boolean;
  areNoItemsSelected: boolean = true;
  areAllItemsSelected: boolean;

  private items: Selectable[] = [];

  constructor(
    public dialogRef: MatDialogRef<MultiSelectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private userNotification: UserNotificationService
  ) {
    this.items = data.items ?? [];
    this.filteredItems = this.items;
    this.updateSelectionState();
    this.title = data?.title ?? 'general.select';
    this.description = data?.description;
    this.mustSelect = data?.mustSelect ?? false;
    this.canCancel = data?.canCancel;
    dialogRef.disableClose = !this.canCancel;
  }

  ngOnInit(): void {
    if (!this.items.length) {
      this.userNotification.notify('general.errorMsg.noElements');
      this.cancel();
    }
  }

  confirm() {
    this.dialogRef.close(this.items.filter(i => i.isSelected));
  }

  cancel() {
    this.dialogRef.close(null);
  }

  filter(filter: string) {
    this.filteredItems = !filter
      ? [...this.items]
      : this.items.filter(i => i.displayName.toLowerCase().includes(filter.toLowerCase()));

    this.updateSelectionState();
  }

  itemClicked(item: Selectable) {
    item.isSelected = !item.isSelected;

    this.updateSelectionState();
  }

  setSelectStateForItems(isSelected: boolean) {
    this.areNoItemsSelected = !isSelected;
    this.areAllItemsSelected = isSelected;

    for (const item of this.filteredItems ?? []) {
      item.isSelected = isSelected;
    }
  }

  private updateSelectionState() {
    const filteredItemsCount = this.filteredItems.length;
    const selectedItemsCount = this.filteredItems.filter(i => i.isSelected).length;
    this.areNoItemsSelected = selectedItemsCount == 0;
    this.areAllItemsSelected = filteredItemsCount == selectedItemsCount;
  }
}
