import { Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-template-tooltip',
  templateUrl: './template-tooltip.component.html',
  styleUrls: ['./template-tooltip.component.scss'],
})
export class TemplateTooltipComponent {
  @Input() template: TemplateRef<any>;
}
