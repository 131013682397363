import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-iframe-dialog',
  templateUrl: './iframe-dialog.component.html',
  styleUrls: ['./iframe-dialog.component.scss']
})
export class IFrameDialogComponent implements OnInit {
  title: string;
  contentUrl: SafeResourceUrl;
  params: any;

  constructor(public confirmRef: MatDialogRef<IFrameDialogComponent>, @Inject(MAT_DIALOG_DATA) data,
  public sanitizer: DomSanitizer) {
    this.title = (data.title) ? data.title : 'TITLE_NOT_SET';
    this.contentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(data.contentUrl);
    this.params = (data.params) ? data.params : undefined;
  }

  ngOnInit() { }

  ok() {
    this.confirmRef.close(true);
  }

  cancel() {
    this.confirmRef.close(false);
  }
}
