import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CapacitorUtils } from '../utils/capacitor-utils';

@Injectable()
export class CapacitorAppGuard {
  constructor(private router: Router) {}

  async canActivateChild() {
    if (CapacitorUtils.isApp()) return true;

    return this.router.createUrlTree([]);
  }

  async canActivate() {
    if (CapacitorUtils.isApp()) return true;

    return this.router.createUrlTree([]);
  }
}
