import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { ZoneGroupModel, RoomTemplateModel } from '@app/api';
import {
  ApiService,
  AppRoutingData,
  GlobalFormStateTrackerService,
  CustomValidators,
  doubleRegex,
  ExtendedCategoryModel,
  FormState,
  GlobalsService,
  LogService,
  StructureType,
} from '@app/core';
import { FormComponent } from '@app/core/utils/form-component';
import { UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { ConfirmDialogComponent } from '../../../dialogs/confirm-dialog/confirm-dialog.component';
import { RoomEditComponent } from '../../room-edit/room-edit.component';

const templateSpecificNames = ['name'];

@Component({
  selector: 'app-room-template-edit-dialog',
  templateUrl: './room-template-edit-dialog.component.html',
  styleUrls: ['./room-template-edit-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoomTemplateEditDialogComponent extends FormComponent implements OnInit, OnDestroy, Busy, FormState {
  @ViewChild('roomEdit') roomEditComponent: RoomEditComponent;
  isBusy: boolean;
  icon = AppRoutingData.roomTemplates.icon;
  fullscreen: boolean;

  isDisabled: boolean;
  isInProject: boolean;
  roomTemplate: RoomTemplateModel;
  roomTemplateId: string;
  categories: ExtendedCategoryModel[];
  zoneGroups: ZoneGroupModel[] = [];

  private existingTemplateNames: string[] = [];
  private wasChanged: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private apiService: ApiService,
    private changeDetector: ChangeDetectorRef,
    private dialog: MatDialog,
    private formBuilder: UntypedFormBuilder,
    private log: LogService,
    private globalFormStateTracker: GlobalFormStateTrackerService,
    private userNotification: UserNotificationService,
    public dialogRef: MatDialogRef<RoomTemplateEditDialogComponent>,
    public globals: GlobalsService
  ) {
    super();

    this.roomTemplateId = data.roomTemplateId;
    this.isInProject = !!data.isInProject;
  }

  get isValid(): boolean {
    return this.form?.valid ?? false;
  }

  get isDirty(): boolean {
    return this.form.dirty || this.f.categories.dirty;
  }

  async ngOnInit() {
    this.globalFormStateTracker.trackFormState(this);
    this.isDisabled = false;
    this.initFormGroup();

    await this.loadSelections();
    await this.loadRoomTemplate();
  }

  ngOnDestroy() {
    this.globalFormStateTracker.untrackFormState(this);
    return super.ngOnDestroy();
  }

  async canLeave() {
    if (this.isDirty) {
      const hasConfirmed = await this.getConfirmation();
      if (hasConfirmed) {
        this.form.markAsPristine();
      }
    }
    return !this.isDirty;
  }

  async save() {
    const result = this.parseForm();

    await using(new BusyScope(this), async _ => {
      result.id = await this.apiService.saveRoomTemplate(result);
      this.wasChanged = true;
      this.userNotification.notify('general.successMsg.save');
      this.dialogRef.close(result);
    }).catch(e => {
      this.log.error('Room template save failed', e);
      this.userNotification.notify('roomBook.roomTemplate.error.save', { error: e });
    });
  }

  async cancel() {
    if (await this.canLeave()) {
      this.dialogRef.close(this.wasChanged);
    }
  }

  async reset() {
    if (await this.canLeave()) {
      await this.loadRoomTemplate();
    }
  }

  toggleFullscreen(dlgElem: any) {
    const dlgDom = document.getElementsByClassName('tasklike-dialog');
    if (dlgDom.length >= 0) {
      const dialogPanel = dlgDom.item(0);
      if (dialogPanel.classList.contains('fullscreen')) {
        dlgDom.item(0).classList.remove('fullscreen');
        dlgElem.classList.remove('fullscreen');
      } else {
        dlgDom.item(0).classList.add('fullscreen');
        dlgElem.classList.add('fullscreen');
      }
    }
    this.roomEditComponent.tabGroup.realignInkBar();
  }

  getFirstError(): FieldError {
    const firstKey = Object.keys(this.f).find(k => this.f[k].errors);
    return firstKey
      ? {
          fieldName: firstKey + (templateSpecificNames.includes(firstKey) ? 'Template' : ''),
          error: Object.keys(this.f[firstKey].errors)[0],
        }
      : null;
  }

  private initFormGroup() {
    this.form = this.formBuilder.group({
      name: [
        { value: null, disabled: this.isDisabled },
        [Validators.required, CustomValidators.unique(this.existingTemplateNames)],
      ],
      internalNumber: [{ value: null, disabled: this.isDisabled }],
      externalNumber: [{ value: null, disabled: this.isDisabled }],
      use: [{ value: null, disabled: this.isDisabled }],
      use2: [{ value: null, disabled: this.isDisabled }],
      type: [{ value: null, disabled: this.isDisabled }],
      shellHeight: [{ value: null, disabled: this.isDisabled }],
      clearanceHeight: [{ value: null, disabled: this.isDisabled }],
      floorArea: [{ value: null, disabled: this.isDisabled }],
      volume: [{ value: null, disabled: this.isDisabled }],
      perimeter: [{ value: null, disabled: this.isDisabled }],
      isTagged: [{ value: null, disabled: this.isDisabled }],
      categories: this.formBuilder.array([]),
      zones: [{ value: [], disabled: this.isDisabled }],
    });
  }

  private parseForm(): RoomTemplateModel {
    const zones = this.zoneGroups.flatMap(group => group.zones);

    return new RoomTemplateModel({
      id: this.roomTemplateId,
      name: this.f.name.value,
      internalNumber: this.f.internalNumber.value,
      externalNumber: this.f.externalNumber.value,
      use: this.f.use.value,
      use2: this.f.use2.value,
      type: this.f.type.value,
      shellHeight: this.f.shellHeight.value,
      clearanceHeight: this.f.clearanceHeight.value,
      floorArea: this.f.floorArea.value,
      volume: this.f.volume.value,
      perimeter: this.f.perimeter.value,
      isDefault: this.f.isTagged.value,
      attributes: this.roomEditComponent.getAttributes(),
      zones: this.f.zones.value.map(zoneId => zones.find(zone => zone.id == zoneId)),
    });
  }

  private async loadRoomTemplate() {
    if (this.roomTemplateId) {
      await using(new BusyScope(this), async _ => {
        const roomTemplate = await this.apiService.getRoomTemplate(this.roomTemplateId);
        // if (this.makeCopy) {
        //   this.roomTemplate = this.copyTemplate(roomTemplate);
        // } else {
        this.roomTemplate = roomTemplate;
        // }
      }).catch(e => {
        this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.roomTemplate', e);
        this.dialogRef.close(false);
      });
    } else if (!this.roomTemplate) {
      this.roomTemplate = new RoomTemplateModel({
        zones: [],
      });
    }

    // init room edit: needed for addAttributes
    this.changeDetector.detectChanges();
    this.resetFormGroup();
  }

  private async loadSelections() {
    await using(new BusyScope(this), async _ => {
      let [roomTemplates, categories, zoneGroups] = await Promise.all([
        this.apiService.getRoomTemplates(),
        this.apiService.getCategories(StructureType.treeWithParents),
        this.isInProject ? this.apiService.getZoneGroupsForProject() : Promise.resolve([]),
      ]);

      for (const roomTemplate of roomTemplates) {
        if (roomTemplate.id != this.roomTemplateId) this.existingTemplateNames.push(roomTemplate.name);
      }

      this.categories = categories;
      this.zoneGroups = zoneGroups;
    }).catch(e => {
      const failedKey = !this.existingTemplateNames.length ? 'roomTemplates' : 'categories';
      this.userNotification.notifyFailedToLoadDataAndLog(`general.errorFailedToLoadDataKeys.${failedKey}`, e);
    });
  }

  private async getConfirmation() {
    const dialogResult = await this.dialog
      .open(ConfirmDialogComponent, {
        data: { title: 'general.warning', description: 'general.unsavedChangesConfirmation' },
        disableClose: true,
      })
      .afterClosed()
      .toPromise();

    return dialogResult;
  }

  private resetFormGroup() {
    this.f.name.patchValue(this.roomTemplate?.name);
    this.f.internalNumber.patchValue(this.roomTemplate?.internalNumber);
    this.f.externalNumber.patchValue(this.roomTemplate?.externalNumber);
    this.f.use.patchValue(this.roomTemplate?.use);
    this.f.use2.patchValue(this.roomTemplate?.use2);
    this.f.type.patchValue(this.roomTemplate?.type);
    this.f.shellHeight.patchValue(this.roomTemplate?.shellHeight);
    this.f.clearanceHeight.patchValue(this.roomTemplate?.clearanceHeight);
    this.f.floorArea.patchValue(this.roomTemplate?.floorArea);
    this.f.volume.patchValue(this.roomTemplate?.volume);
    this.f.perimeter.patchValue(this.roomTemplate?.perimeter);
    this.f.isTagged.patchValue(this.roomTemplate?.isDefault);
    this.f.zones.patchValue(this.roomTemplate?.zones?.map(zone => zone.id));

    const categories = this.f.categories as UntypedFormArray;
    categories.clear();
    for (const attribute of this.roomTemplate?.attributes ?? []) {
      this.roomEditComponent.addAttribute(attribute, false);
    }

    this.form.markAllAsTouched();
  }

  // private copyRoomTemplate(template: RoomTemplateModel): RoomTemplateModel {
  //   const copyLabel = this.translate.instant('roomTemplates.copyLabel');
  //   const templateCopy = new RoomTemplateModel();
  //   //mapping
  //   templateCopy.name = template.name + copyLabel;
  //   templateCopy.companyId = template.companyId;
  //   // ToDo
  //   return templateCopy;
  // }
}
