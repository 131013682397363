import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'folderPath' })
export class FolderPathPipe implements PipeTransform {
  constructor() { }
  transform(value: string) {
    if (value && value.startsWith('/')) {
        return value.substr(1);
    }
    return value;
  }
}