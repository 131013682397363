import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-content-group',
  templateUrl: './content-group.component.html',
  styleUrls: ['./content-group.component.scss'],
})
export class ContentGroupComponent {
  @Input() set expanded(isExpanded: boolean | string) {
    this.isExpanded = isExpanded === '' || isExpanded === 'true' || isExpanded === true;
  }

  @Input() set expander(showExpander: boolean | string) {
    this.showExpander = showExpander === '' || showExpander === 'true' || showExpander === true;
  }

  isExpanded = true;
  showExpander = false;

  headerClicked() {
    if (this.showExpander) this.isExpanded = !this.isExpanded;
  }
}
