/* tslint:disable */
import { C4LinqExtensions } from './c4-linq-extensions';

export class C4EventHandler {
    //#region fields
    private _eventContext: any;
    private _allowedEvents: string[];
    private _componentName: string;
    private _eventCallbacks: C4EventGroup[];
    //#endregion
    //#region properties
    public get componentName(): string {
        return this._componentName;
    }
    public set componentName(v: string) {
        this._componentName = v;
    }
    public get allowedEvents(): string[] {
        return this._allowedEvents;
    }
    public set allowedEvents(v: string[]) {
        this._allowedEvents = v;
    }
    //#endregion
    //#region constructor
    constructor(componentName: string, allowedEvents: string[]) {
        this._componentName = componentName;
        this._allowedEvents = allowedEvents;
        this._eventCallbacks = [];
        C4LinqExtensions.init();
    }
    //#endregion
    //#region internal methods
    private getEventGroup(event: string): C4EventGroup {
        let result: C4EventGroup = this._eventCallbacks.firstOrDefault((g: C4EventGroup) => g.event === event);
        if (!result) {
            const group = new C4EventGroup(event);
            this._eventCallbacks.push(group);
            result = group;
        } else {
            if (!this._allowedEvents.contains(event)) {
                let msg = 'The event \'' + event + '\' is not supported';
                if (this._componentName) {
                    msg += ' for ' + this._componentName;
                }
                console.error(msg + '.');
                result = null;
            }
        }
        return result;
    }
    private addCallback(event: string, callback: Function, once: boolean) {
        const eventGroup = this.getEventGroup(event);
        if (typeof callback === 'function') {
            eventGroup.handlers.push(new C4EventCallback(callback, once));
        }
    }
    //#endregion
    //#region public methods
    public raiseEvent(event: string, ...args: any[]) {
        const eventGroup = this.getEventGroup(event);
        if (eventGroup) {
            const callbacks = eventGroup.handlers;
            const remove = [];
            for (let idx = 0; idx < callbacks.length; idx++) {
                const callback = callbacks[idx];
                callback.function.apply(this._eventContext, args);
                if (callback.once) {
                    remove.push(callback);
                }
            }
            for (let idx = 0; idx < remove.length; idx++) {
                const callback = remove[idx];
                eventGroup.handlers.remove(callback);
            }
        }
    }
    public one(event: string, callback: Function): C4EventHandler {
        this.addCallback(event, callback, true);
        return this;
    }
    public on(event: string, callback: Function): C4EventHandler {
        this.addCallback(event, callback, false);
        return this;
    }
    public context(context: any): C4EventHandler {
        this._eventContext = context;
        return this;
    }
    //#endregion
}

class C4EventGroup {
    private _event: string;
    public get event(): string {
        return this._event;
    }
    public set event(v: string) {
        this._event = v;
    }

    private _handlers: C4EventCallback[];
    public get handlers(): C4EventCallback[] {
        return this._handlers;
    }
    public set handlers(v: C4EventCallback[]) {
        this._handlers = v;
    }


    constructor(event: string) {
        this._event = event;
        this._handlers = [];
    }
}

class C4EventCallback {
    private _function: Function;
    private _once: boolean;

    public get once(): boolean {
        return this._once;
    }
    public set once(v: boolean) {
        this._once = v;
    }
    public get function(): Function {
        return this._function;
    }
    public set function(v: Function) {
        this._function = v;
    }

    constructor(callback: Function, once: boolean) {
        this._function = callback;
        this._once = once;
    }
}
