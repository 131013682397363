<div class="c4-dialog-wrapper plan-verify-wrapper">
	<h1 class="center-self center-text" mat-dialog-title>{{ title | translate}}</h1>
	<mat-dialog-content class="content">
		<div class="plan-verify-icon mdi" [class]="iconClasses"></div>
		<span class="center-text larger">{{ description | translate }}</span>
	</mat-dialog-content>
	<mat-dialog-actions class="center-self">
		<button class="confirm-button" mat-flat-button color="primary" (click)="ok()" type="button">
			<span class="larger">{{ 'general.ok' | translate }}</span>
		</button>
	</mat-dialog-actions>
</div>