import { UserNotificationService } from '@app/shared/services/user-notification/user-notification.service';
import jsPDF from 'jspdf';
import { FileExtension } from '../enumerations';
import { CapacitorUtils } from './capacitor-utils';
import * as moment from 'moment';
import { Week } from '@app/shared/services/scheduler/scheduler.interfaces';
import { Observable, take } from 'rxjs';

declare global {
  interface Navigator {
    msSaveOrOpenBlob(blob: any, defaultName?: string): boolean;
  }
}

declare module 'moment' {
  interface Moment {
    toLocalDate(): string;
    toLocalTime(): string;
  }
}

(moment as any).fn.toLocalDate = function (): string {
  return this.format(LOCAL_DATE_FORMAT);
};

(moment as any).fn.toLocalTime = function (): string {
  return this.format(LOCAL_TIME_FORMAT);
};

export const EmptyGuid = '00000000-0000-0000-0000-000000000000';

export const intRegex = /^\d+$/;
export const doubleRegex = /^\d+((\.|,)\d+)?$/;
export const mailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,24}$/;
export const guidRegex = /^[0-9A-F]{8}-(?:[0-9A-F]{4}-){3}[0-9A-F]{12}$/i; // /^[{(]?[0-9A-F]{8}[-]?(?:[0-9A-F]{4}[-]?){3}[0-9A-F]{12}[)}]?$/i;
export const sharepointNameRegex = /^[^\/\\\[\]:|<>\+\=\;\,\?\*\'\@"]+$/;
// longest existing toplevel domain has 24 chars

// \p{L} is not supported for unicode letter set
export const allCharacters = 'a-zA-ZßáàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ';

export const nameof = <T>(name: Extract<keyof T, string>): string => name;

export const LOCAL_DATE_FORMAT = 'YYYY-MM-DD';
export const LOCAL_TIME_FORMAT = 'HH:mm:ss';

export class Utils {
  static tenantNameStorageId = 'tenantName';
  static appDeepLinkSlugCookieId = 'appDeepLinkSlug';

  static rangePrefix = 'r_';

  static isUUID(text) {
    if (typeof text == 'string' && text.length > 0) {
      return /^[0-9a-f]{8}-[0-9a-f]{4}-[4][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(text);
    }

    return false;
  }

  static getTextWithSeperatorsRegex(seperators: string[], allowPeriods: boolean = false) {
    const period = allowPeriods ? '.' : '';
    // negative lookbehind not supported by safari -.- (?<! |-)
    return new RegExp(`^([${allCharacters}${period}]+)([${seperators.map(s => s[0])}][${allCharacters}${period}]+)*$`);
  }

  static isEmail(str: string) {
    return str && str.match(mailRegex);
  }

  static isNullOrWhitespace(str: string): boolean {
    return str === null || str === undefined || str.match(/^ *$/) !== null;
  }

  static isNullOrUndefined(val: any): boolean {
    return val === null || val === undefined;
  }

  static oneOrMoreOrFallback<T1, T2>(items: T1[], fallback: T2): T1[] | T2 {
    if (items?.length == 0) {
      return fallback;
    }
    return items;
  }

  static nullIfEmptyText(text?: string): string | null {
    if (text?.length > 0) {
      return text;
    }
    return null;
  }

  static nullIfEmpty<T>(phaseIds: T[]): T[] | null {
    if (phaseIds.length > 0) {
      return phaseIds;
    }
    return null;
  }

  static intersectsWith(arrayOne: string[], arrayTwo: string[]): boolean {
    if (arrayOne == null || arrayTwo == null) {
      return false;
    }

    return arrayOne.some(one => {
      return arrayTwo.some(two => one == two);
    });
  }

  static sameList<T>(arrayOne: T[], arrayTwo: T[]) {
    if (arrayOne == arrayTwo) {
      // ref check
      return true;
    }

    if (arrayOne == null) {
      return false;
    }

    if (arrayTwo == null) {
      return false;
    }

    if (arrayOne.length != arrayTwo.length) {
      return false;
    }

    const count = arrayOne.length;

    for (let index = 0; index < count; ++index) {
      if (arrayOne[index] != arrayTwo[index]) {
        return false;
      }
    }

    return true;
  }

  static setCookie(key: string, value: string, days: number, domain: string = '') {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = ';expires=' + date.toUTCString();
    }

    if (domain.length > 0) {
      domain = ';domain=' + domain;
    }

    document.cookie = `${key}=${value || ''}${expires}${domain}; path=/`;
  }

  static getCookie(key: string) {
    const cookieKey = key + '=';
    const cookieParts = document.cookie.split(';');
    for (let cpart of cookieParts) {
      while (cpart.charAt(0) === ' ') cpart = cpart.substring(1, cpart.length);
      if (cpart.indexOf(cookieKey) === 0) return cpart.substring(cookieKey.length, cpart.length);
    }
    return null;
  }

  static deleteCookie(key: string, domain: string = '') {
    this.setCookie(key, '', -1, domain);
  }

  static isFunction(fun: any): boolean {
    return typeof fun === 'function';
  }

  static decodeToken(str: string): IToken {
    const parts = str.split('.');

    return JSON.parse(atob(parts[1]));
  }

  static createUUID() {
    const hex = '0123456789ABCDEF';

    const buffer = new Uint8Array(16);

    const crypto = window.crypto || window.msCrypto;

    crypto.getRandomValues(buffer);

    buffer[6] = 0x40 | (buffer[6] & 0xf);
    buffer[8] = 0x80 | (buffer[8] & 0xf);

    const segments = [];

    for (let i = 0; i < 16; ++i) {
      segments.push(hex[(buffer[i] >> 4) & 0xf]);
      segments.push(hex[(buffer[i] >> 0) & 0xf]);

      if (i == 3 || i == 5 || i == 7 || i == 9) {
        segments.push('-');
      }
    }

    return segments.join('');
  }

  static async saveCSVLocal(data: any, filename: string) {
    const BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
    const blobOut = new Blob([BOM, data], { type: 'text/csv; charset=utf-8' });

    if (CapacitorUtils.isApp()) {
      await CapacitorUtils.blobFileHandler(blobOut, filename, true);
    } else {
      const blobUrl = window.URL.createObjectURL(blobOut);
      const element = document.createElement('a');
      element.setAttribute('href', blobUrl);
      element.setAttribute('download', filename);
      element.style.display = 'none';
      document.body.appendChild(element);
      if (window.navigator.msSaveOrOpenBlob) {
        element.addEventListener('click', function (e) {
          e.preventDefault();
          window.navigator.msSaveOrOpenBlob(blobOut, filename);
        });
      }
      element.click();
      window.URL.revokeObjectURL(blobUrl);
      document.body.removeChild(element);
    }
  }

  static getFileName(project: string, subject: string, fileExtension: FileExtension) {
    const date = new Date();

    return (
      [
        date.toISOString().split('T')[0].replace(/-/g, ''), // date
        project,
        subject,
      ]
        .filter(str => !!str)
        .join('_') + fileExtension
    );
  }

  static async saveJsPDFLocal(doc: jsPDF, filename: string) {
    if (CapacitorUtils.isApp()) {
      var blob = doc.output('blob');
      await CapacitorUtils.blobFileHandler(blob, filename, true);
    } else doc.save(filename);
  }

  // static getWeekNumber(date: Date) {
  //     var jannuaryFirst = new Date(date.getFullYear(), 0, 1);
  //     var numberOfDays = Math.floor((date.getTime() - jannuaryFirst.getTime()) / (24 * 60 * 60 * 1000));
  //     return Math.ceil((jannuaryFirst.getDay() + 1 + numberOfDays) / 7);
  // }

  static getWeek(date: moment.Moment): Week {
    return {
      startOf: date.clone().startOf('week'),
      endOf: date.clone().endOf('week'),
      number: date.week(),
    };
  }

  static routeToExternalUrl(url: string, path: string = '', queryParams: {} = null) {
    const redirectUrl = new URL(window.location.origin);
    const redirectParams = redirectUrl.searchParams;
    for (let key in queryParams) {
      redirectParams.set(key, queryParams[key]);
    }
    redirectUrl.hostname = CapacitorUtils.isApp() ? 'localhost' : url;
    redirectUrl.pathname = path;
    window.location.assign(redirectUrl.toString());
  }

  static async decodeDropEvent(
    event: any,
    userNotification: UserNotificationService,
    supportedExtensions: string[] = null
  ): Promise<File[]> {
    const uploadFiles: File[] = [];

    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < event.dataTransfer.items.length; i++) {
        let item = event.dataTransfer.items[i];
        // If dropped items aren't files, reject them
        if (item.kind === 'file') {
          //unfortunately, 'file' also in case of directories
          if (item.webkitGetAsEntry) {
            //Chrome, FF, Edge; see https://developer.mozilla.org/en-US/docs/Web/API/DataTransferItem/webkitGetAsEntry
            const entry = item.webkitGetAsEntry();
            if (entry.isDirectory) {
              //uploading directories is currently not supported
              await userNotification.notify('documents.directoryUploadNotSuppored');
              return [];
            }
          }
          const file = item.getAsFile();

          //check if file extension is supported
          if (
            supportedExtensions &&
            supportedExtensions.length > 0 &&
            supportedExtensions.indexOf(file.name.split('.').pop()) === -1
          ) {
            await userNotification.notify('documents.unsupportedFileType');
            return [];
          }

          uploadFiles.push(file);
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < event.dataTransfer.files.length; i++) {
        const file = event.dataTransfer.files[i];
        //check if file extension is supported
        if (
          supportedExtensions &&
          supportedExtensions.length > 0 &&
          supportedExtensions.indexOf(file.name.split('.').pop()) === -1
        ) {
          await userNotification.notify('documents.unsupportedFileType');
          return [];
        }

        uploadFiles.push(file);
      }
    }

    return uploadFiles;
  }

  static filterObject(raw: { [key: string]: any }, isAllowed: (key: string) => boolean): { [key: string]: any } {
    return Object.keys(raw)
      .filter(key => isAllowed(key))
      .reduce((obj, key) => {
        obj[key] = raw[key];
        return obj;
      }, {});
  }

  static paramsToString(params: { [key: string]: string }) {
    const esc = encodeURIComponent;
    const paramString = Object.keys(params)
      .filter(key => !!params[key])
      .map(key => `${esc(key)}=${esc(params[key])}`)
      .join('&');

    return paramString ? `?${paramString}` : '';
  }

  static async enhanceException<T>(promise: Promise<T>, translationKey: string): Promise<T> {
    try {
      return await promise;
    } catch (e) {
      if (e.translationKey == undefined) e.translationKey = translationKey;
      throw e;
    }
  }

  static propertySort<T>(selector: (t: T) => string) {
    const compare = (a: any, b: any) => {
      return a < b ? -1 : a > b ? 1 : 0;
    };

    return (a: T, b: T) => compare(a != null ? selector(a) : null, b != null ? selector(b) : null);
  }

  static isSorted<T>(array: T[], compare: (a: T, b: T) => number = null) {
    const isFirstLarger = (a: T, b: T) => (compare != null ? compare(a, b) == 1 : a > b);

    for (let i = 0; i < array.length - 1; i++) {
      if (isFirstLarger(array[i], array[i + 1])) return false;
    }

    return true;
  }

  static cssEvalCssValue(input: string, element: Element = document.body, i = 0) {
    if (i > 10) {
      console.error('cssEvalCssValue, infinite loop', input);
      return 'transparent';
    }

    const match = input.match(/var\((--[a-zA-Z0-9-]+)\)/i);
    if (match) {
      const [text, group] = match;

      const style = getComputedStyle(element);
      const value = style.getPropertyValue(group);

      return Utils.cssEvalCssValue(value, element, i + 1);
    }

    return input;
  }

  static isIE(): boolean {
    let ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    var is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;
    return is_ie;
  }

  static addHtmlListener<K extends keyof HTMLElementEventMap>(
    element: HTMLElement,
    type: K,
    listener: (this: HTMLElement, ev: HTMLElementEventMap[K]) => any,
    remove$: Observable<void>,
    options?: boolean | AddEventListenerOptions
  ) {
    element.addEventListener(type, listener, options);
    remove$.pipe(take(1)).subscribe(() => element.removeEventListener(type, listener));
  }

  static addDocListener<K extends keyof DocumentEventMap>(
    element: Document,
    type: K,
    listener: (this: Document, ev: DocumentEventMap[K]) => any,
    remove$: Observable<void>,
    options?: boolean | AddEventListenerOptions
  ) {
    element.addEventListener(type, listener, options);
    remove$.pipe(take(1)).subscribe(() => element.removeEventListener(type, listener));
  }

  static getPropertyValueIfAllTheSame<E, P extends keyof E>(
    entities: E[],
    field: P,
    compareFunction = (value: E[P], other: E[P]) => value === other
  ) {
    if (!entities?.length) return null;

    const [firstEntity] = entities;
    const firstValue = firstEntity[field];
    if (entities.length === 1) return firstValue;

    const areAllTheSame = entities.every(entity => compareFunction(entity[field], firstValue));
    return areAllTheSame ? firstValue : null;
  }
}

export interface IToken {
  exp: number;
  iss: string;
  aud: string;

  [key: string]: any;
}

export interface IPosition {
  x: number;
  y: number;
}

export interface ISize {
  w: number;
  h: number;
}
