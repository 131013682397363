<div class="c4-dialog-wrapper">
	<div class="dialog-header">
		<h1 mat-dialog-title>{{ title | translate }}</h1>
		<div class="filler"></div>
		<span *ngIf="form.errors?.unique" class="mdi mdi-information-outline"
					[matTooltip]="'general.errorForms.unique' | translate"></span>
	</div>
	<mat-dialog-content>
		<form *ngIf="form" class="attribute-form" [formGroup]="form">
			<ng-container [ngTemplateOutlet]="inputFieldTemplate" [ngTemplateOutletContext]="{
				fieldName: 'name'
			}"></ng-container>

			<ng-container [ngTemplateOutlet]="inputFieldTemplate" [ngTemplateOutletContext]="{
				fieldName: 'description'
			}"></ng-container>

			<mat-form-field>
				<mat-label>{{ 'attributes.fieldName.valueType' | translate }}</mat-label>
				<mat-select formControlName="valueType">
					<mat-option *ngFor="let type of attributeValueTypes" [value]="type">
						{{ 'attributes.valueTypes.' + attributeValueType[type] | translate }}
					</mat-option>
				</mat-select>
			</mat-form-field>

			<ng-container *ngIf="selectedValueType == attributeValueType.Text || selectedValueType == attributeValueType.Integer || selectedValueType == attributeValueType.Double"
										[ngTemplateOutlet]="inputFieldTemplate" [ngTemplateOutletContext]="{
				fieldName: 'defaultValue'
			}"></ng-container>

			<mat-checkbox *ngIf="selectedValueType == attributeValueType.Checkbox" formControlName="defaultValue">
				{{ 'attributes.fieldName.defaultValue' | translate }}
			</mat-checkbox>

			<ng-container *ngIf="f.definition.enabled">
				<mat-form-field>
					<mat-label>{{ 'attributes.fieldName.defaultValue' | translate }}</mat-label>
					<mat-select #defaultValueDropdown formControlName="defaultValue">
						<mat-option [value]="null">{{ 'general.selectNone' | translate}}</mat-option>
						<mat-option *ngFor="let option of f.definition.value?.listItems" [value]="option">
							{{ option }}
						</mat-option>
					</mat-select>
				</mat-form-field>

				<div>
					<app-multi-value-input class="value-options" formControlName="valueOptions"
																 [title]="'attributes.fieldName.definition'" [fieldName]="'general.name'"
																 [valueAccessor]="'name'">
					</app-multi-value-input>

					<div class="definition-error" [style.visibility]="f.definition.valid ? 'hidden' : 'visible'">
						<span>{{ 'general.errorForms.valuesRequired' | translate }}</span>
					</div>

					<div class="definition-error" [style.visibility]="f.valueOptions.valid ? 'hidden' : 'visible'">
						<span>{{ 'general.errorForms.valuesDuplicates' | translate }}</span>
					</div>
				</div>
			</ng-container>

			<app-tree-selection formControlName="categoryId" [title]="'attributes.fieldName.categoryName'"
													[models]="categories" [selectLabel]="categoryLabel" [errors]="f.categoryId.errors">
			</app-tree-selection>
		</form>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
		<button mat-flat-button color="primary" (click)="confirm()" [disabled]="isBusy || form.invalid" type="button">{{
			'general.save' | translate }}</button>
	</mat-dialog-actions>
</div>
<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:false }"></ngx-loading>

<ng-template #inputFieldTemplate [formGroup]="form" let-fieldName="fieldName">
	<mat-form-field>
		<mat-label>
			<span>{{ 'attributes.fieldName.' + fieldName | translate }}</span>
		</mat-label>
		<input matInput [formControlName]="fieldName">
		<mat-error *ngIf="f[fieldName].invalid">
			{{ 'general.errorForms.' + getError(fieldName) | translate }}
		</mat-error>
	</mat-form-field>
</ng-template>