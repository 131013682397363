import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CheckboxState } from '../ternary-checkbox/ternary-checkbox.component';

@Component({
  selector: 'app-checkbox-form',
  templateUrl: './checkbox-form.component.html',
  styleUrls: ['./checkbox-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CheckboxFormComponent implements OnInit {
  @Input() title: string;
  @Input() translationPrefix: string = '';
  @Input() set formGroup(formGroup: UntypedFormGroup) {
    this._formGroup = formGroup;
    this.formControlNames = Object.keys(formGroup.controls);
  }

  formControlNames: string[];
  CheckboxState = CheckboxState;

  constructor() {}

  private _formGroup: UntypedFormGroup;
  get formGroup(): UntypedFormGroup {
    return this._formGroup;
  }

  get f() {
    return this.formGroup?.controls;
  }

  ngOnInit(): void {}

  checkboxClicked(event: MouseEvent, formControlName: string) {
    event.preventDefault();

    const formControl = this.f[formControlName];
    const currentState = formControl.value;
    if (this.isBinaryState(currentState)) {
      formControl.setValue(!currentState);
    } else {
      formControl.setValue(currentState == CheckboxState.checked ? CheckboxState.unchecked : CheckboxState.checked);
    }

    this.formGroup.markAsDirty();
  }

  isChecked(state: boolean | CheckboxState) {
    return this.isBinaryState(state) ? state : state == CheckboxState.checked;
  }

  private isBinaryState(state: boolean | CheckboxState) {
    return typeof state == 'boolean';
  }
}
