import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserNotificationService } from '@app/shared/services';
import { GeoLocationValue } from '../interfaces';

@Component({
  selector: 'app-maps-link-decode-dialog',
  templateUrl: './maps-link-decode-dialog.component.html',
  styleUrls: ['./maps-link-decode-dialog.component.scss']
})
export class MapsLinkDecodeDialogComponent {
  latitude: string;
  longitude: string;

  constructor(
    public dialogRef: MatDialogRef<MapsLinkDecodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private userNotification: UserNotificationService
  ) {}

  tryParseLink(link: string) {
    if (link) {
      let success = false;
      const location = link.split('@')[1];
      if (location) {
        const result = location.split(',');
        if (result && result.length >= 2) {
          [this.latitude, this.longitude] = result;
          success = true;
        }
      }

      this.userNotification.notify(`general.${success ? 'successMsg' : 'errorMsg'}.parseLink`);
    }
  }

  close() {
    const result: GeoLocationValue = {
      latitude: this.latitude,
      longitude: this.longitude
    };

    this.dialogRef.close(result);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
