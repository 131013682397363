import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { NavigationClient } from "@azure/msal-browser";
import { ApiAuthenticationService } from './api-authentication.service';

export class InAppBrowserNavigationClient extends NavigationClient {
  constructor(private iab: InAppBrowser, private apiAuthService: ApiAuthenticationService) {
    super();
  }

  async navigateExternal(url: string, options: any) {
    if (CapacitorUtils.isApp()) {
      const browser = this.iab.create(url, '_blank', {
        location: 'yes',
        clearcache: 'yes',
        clearsessioncache: 'yes',
        hidenavigationbuttons: 'yes',
        hideurlbar: 'yes',
        fullscreen: 'yes'
      });

      let wasCanceled = true;
      browser.on('exit').subscribe(e => {
        if (wasCanceled) this.apiAuthService.handleMsalRedirect();
      });

      browser.on('loadstart').subscribe(event => {
        if (event.url.includes('logoutsession')) {
          wasCanceled = false;
          this.apiAuthService.handleMsalRedirect();
                  } else if (event.url.includes('#code')) {
          wasCanceled = false;
          browser.close();

          const url = new URL(event.url);
          this.apiAuthService.handleMsalRedirect(url.hash);
        }
      });
    } else {
      if (options.noHistory) {
        window.location.replace(url);
      } else {
        window.location.assign(url);
      }
    }

    return true;
  }
}