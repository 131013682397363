import { Injectable } from '@angular/core';
import { AppConfigService } from '../app-config/app-config.service';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  constructor() {}

  debugEnabled(): boolean {
    if (!AppConfigService.settings) {
      return true;
    }
    return AppConfigService.settings.log.debug;
  }

  debug(message: string, detail?: any) {
    if (this.debugEnabled()) {
      this.info(message, detail);
    }
  }

  info(message: string, detail?: any) {
    // note: disable rule here instead of tslint.json, because we only allow console in this class
    // tslint:disable-next-line:no-console
    if (this.isDefined(detail)) {
      // tslint:disable-next-line:no-console
      console.info(message, detail);
    } else {
      // tslint:disable-next-line:no-console
      console.info(message);
    }
  }

  warn(message: string, detail?: any) {
    // note: disable rule here instead of tslint.json, because we only allow console in this class
    // tslint:disable-next-line:no-console
    if (this.isDefined(detail)) {
      // tslint:disable-next-line:no-console
      console.warn(message, detail);
    } else {
      // tslint:disable-next-line:no-console
      console.warn(message);
    }
  }

  error(message: string, detail?: any) {
    // note: disable rule here instead of tslint.json, because we only allow console in this class
    if (this.isDefined(detail)) {
      // tslint:disable-next-line:no-console
      console.error(message, detail);
    } else {
      // tslint:disable-next-line:no-console
      console.error(message);
    }
  }

  private isDefined(detail?: any): boolean {
    return detail !== null && detail !== undefined;
  }
}
