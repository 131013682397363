import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { BaseSubscriptionComponent, FormUtils } from '@app/core';
import { PcfLean, PcfRole, PcfRoles, ProjectConfigService } from '@app/shared/services/project-config';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { WorkpackageState } from '@app/api';
import { StateSchemaService } from '../../state-matrix';

@Component({
  selector: 'app-lean-config',
  templateUrl: './lean-config.component.html',
  styleUrls: ['./lean-config.component.scss'],
})
export class LeanConfigComponent extends BaseSubscriptionComponent implements OnInit {
  stateSchemaService: StateSchemaService<WorkpackageState>;

  constructor(
    private configService: ProjectConfigService,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {
    super();
  }

  get form() {
    return this.configService.leanForm;
  }

  get f() {
    return this.form.controls;
  }

  get canProvision() {
    return this.configService.canProvision;
  }

  get states() {
    return FormUtils.getFormControl<PcfLean, UntypedFormArray>(this.form, 'states');
  }

  ngOnInit() {
    const workpackageStates = Object.values(WorkpackageState);
    const mapRoles = (roles: PcfRole[]) => roles.map(r => this.configService.roleAndUserData[r.id] ?? r);
    const rolesForm = FormUtils.getFormControl<PcfRoles, UntypedFormArray>(this.configService.rolesForm, 'roles');
    const roles = rolesForm.value ?? [];

    this.stateSchemaService = new StateSchemaService<WorkpackageState>(
      this.states,
      workpackageStates,
      this.configService.workpackageStatePredefinedRoles.concat(mapRoles(roles)),
      this.configService.workpackageStatePermissions,
      'workpackages.state.',
      this.dialog,
      this.translateService
    );

    this.subscribe(rolesForm.valueChanges, (updatedRoles: PcfRole[]) => {
      this.stateSchemaService.setRoles(this.configService.workpackageStatePredefinedRoles.concat(mapRoles(updatedRoles)));
      this.stateSchemaService.permissions = this.configService.workpackageStatePermissions;
    });
  }

  getError(control: UntypedFormControl) {
    return Object.keys(control.errors)[0];
  }
}
