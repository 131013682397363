import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-divider',
  template: '',
  styleUrls: ['./divider.component.scss'],
})
export class DividerComponent {
  @HostBinding('class.vertical') hasVerticalClass = false;

  @Input() set vertical(value: boolean | string) {
    this.hasVerticalClass = typeof value === 'string' ? value != null && `${value}` !== 'false' : value;
  }
}
