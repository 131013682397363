import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

const FULL_SCREEN_CLASS = 'full-screen';

export interface EntityDialogTitle {
  icon?: string;
  labelKey: string;
  routerLink?: string[];
  queryParams?: Record<string, string | number | boolean>;
}

@Component({
  selector: 'app-entity-detail-dialog',
  templateUrl: './entity-detail-dialog.component.html',
  styleUrls: ['./entity-detail-dialog.component.scss'],
})
export class EntityDetailDialogComponent implements OnInit {
  @Input() dialogRef: MatDialogRef<any>;
  @Input() title: EntityDialogTitle = { labelKey: '' };
  @Input() canSave: boolean = true;
  @Input() fieldError: FieldError;
  @Output() saveClicked = new EventEmitter<void>();
  @Output() resetClicked = new EventEmitter<void>();
  @Output() cancelClicked = new EventEmitter<void>();

  private isInFullScreen: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.dialogRef.addPanelClass('entity-detail');
  }

  toggleFullscreen() {
    if (this.isInFullScreen) this.dialogRef.removePanelClass(FULL_SCREEN_CLASS);
    else this.dialogRef.addPanelClass(FULL_SCREEN_CLASS);

    this.isInFullScreen = !this.isInFullScreen;
  }
}
