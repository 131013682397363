import { CommentModel, UserSessionModel } from '@app/api';
import { ApiService } from '@app/core';
import { ICommentWithViewpoint } from './comments.service';

export interface ICommentsApiService {
  getUserSession(): Promise<UserSessionModel>;
  add(entityId: string, comment: CommentModel): Promise<string>;
  update(entityId: string, comment: ICommentWithViewpoint): Promise<void | string>;
  delete(entityId: string, commentId: string): Promise<void>;
}

export abstract class BaseCommentsApi implements ICommentsApiService {
  constructor(protected apiService: ApiService) {}
  getUserSession = () => this.apiService.getUserSession();

  add(entityId: string, model: CommentModel): Promise<string> {
    throw new Error('Method not implemented.');
  }

  abstract update(entityId: string, comment: ICommentWithViewpoint): Promise<void | string>;
  abstract delete(entityId: string, commentId: string): Promise<void>;
}
