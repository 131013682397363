<div #rootContainer class="c4-bread-crumbs">
  <ng-container *ngIf="visibleCrumbs?.length">
    <ng-container [ngTemplateOutlet]="crumbTemplate" [ngTemplateOutletContext]="{
      $implicit: visibleCrumbs[0]
    }"></ng-container>
    <ng-container *ngIf="hiddenCrumbs?.length">
      <button mat-button class="mat-menu-button" [matMenuTriggerFor]="hiddenCrumbsMenu">
        <span class="mdi mdi-dots-horizontal"></span>
      </button>
      <span class="mdi mdi-chevron-right crumb-icon"></span>

      <mat-menu #hiddenCrumbsMenu="matMenu">
        <ng-container *ngFor="let crumb of hiddenCrumbs">
          <button mat-menu-item class="crumb-button"
                  [matTooltip]="crumb.label || crumb.prefix ? ((crumb.label || crumb.prefix) | translate) : undefined"
                  [routerLink]="crumb.routerLink" (click)="crumbClicked(crumb)"
                  [disabled]="(!crumb?.routerLink && !crumb.action) || disabled">
            <ng-container [ngTemplateOutlet]="crumbLabelTemplate" [ngTemplateOutletContext]="{
              $implicit: crumb
            }"></ng-container>
          </button>
        </ng-container>
      </mat-menu>
    </ng-container>
    <ng-container *ngFor="let crumb of visibleCrumbs | slice:1">
      <ng-container [ngTemplateOutlet]="crumbTemplate" [ngTemplateOutletContext]="{
        $implicit: crumb
      }"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #crumbTemplate let-crumb>
  <ng-container *ngIf="crumb.prefix || crumb.label; else iconButtonTemplate">
    <!-- if router link is set - even if null - it will trigger parameter subscription with empty parameters -->
    <button *ngIf="crumb.routerLink" mat-button class="crumb-button"
            [matTooltip]="(crumb.label || crumb.prefix) | translate" [routerLink]="crumb.routerLink"
            (click)="crumbClicked(crumb)" [disabled]="(!crumb?.routerLink && !crumb.action) || disabled">
      <ng-container [ngTemplateOutlet]="crumbLabelTemplate" [ngTemplateOutletContext]="{
        $implicit: crumb
      }"></ng-container>
    </button>

    <button *ngIf="!crumb.routerLink" mat-button class="crumb-button"
            [matTooltip]="(crumb.label || crumb.prefix) | translate" (click)="crumbClicked(crumb)"
            [disabled]="(!crumb?.routerLink && !crumb.action) || disabled">
      <ng-container [ngTemplateOutlet]="crumbLabelTemplate" [ngTemplateOutletContext]="{
        $implicit: crumb
      }"></ng-container>
    </button>
  </ng-container>

  <ng-template #iconButtonTemplate>
    <button *ngIf="crumb.routerLink" class="crumb-button" mat-icon-button [routerLink]="crumb.routerLink"
            (click)="crumbClicked(crumb)" [disabled]="(!crumb?.routerLink && !crumb.action) || disabled">
      <span class="mdi" [class]="crumb.icon"></span>
    </button>

    <button *ngIf="!crumb.routerLink" class="crumb-button" mat-icon-button (click)="crumbClicked(crumb)"
            [disabled]="(!crumb?.routerLink && !crumb.action) || disabled">
      <span class="mdi" [class]="crumb.icon"></span>
    </button>
  </ng-template>

  <span *ngIf="crumb.prefix || crumb.label || crumb.icon" class="mdi mdi-chevron-right crumb-icon"></span>
</ng-template>

<ng-template #crumbLabelTemplate let-crumb>
  <span *ngIf="crumb.prefix">{{ crumb.prefix | translate }}</span>
  <span *ngIf="crumb.icon" class="mdi" [class]="crumb.icon"></span>
  <span *ngIf="crumb.label">{{ crumb.label | translate }}</span>
</ng-template>