<div class="c4-dialog-wrapper standard-text-selection-dialog">
	<h1 mat-dialog-title>{{ 'standardTexts.select' | translate }}</h1>
	<mat-dialog-content class="content" [formGroup]="form">
		<p-dropdown #dropdown formControlName="text" class="dropdown-form-field" [options]="standardTexts"
								optionLabel="briefDescription" [filter]="true" filterBy="briefDescription" appendTo="body"
								[autoDisplayFirst]="false" (keydown.enter)="close()">
		</p-dropdown>

	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="cancel()" type="button">{{ 'general.cancel' | translate }}</button>
		<button mat-flat-button color="primary" [disabled]="form.invalid" (click)="close()" type="button">
			{{ 'general.ok' | translate }}
		</button>
	</mat-dialog-actions>
</div>